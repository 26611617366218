import React, { Fragment, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

const ConfirmModal = ({ header, content, onConfirm, trigger }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      {React.cloneElement(trigger, { onClick: () => setOpen(true) })}
      <Modal
        size="tiny"
        open={open}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <p>{content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button compact content="Non" onClick={() => setOpen(false)} />
          <Button
            compact
            primary
            onClick={() => {
              onConfirm();
              setOpen(false);
            }}
            content="Oui"
          />
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

export default ConfirmModal;
