import 'moment/locale/fr';

import {
  Container,
  Icon,
  Menu,
  Message,
  Responsive,
  Sidebar,
} from 'semantic-ui-react';
import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';

import { ApolloConsumer } from 'react-apollo';
import { BgWhite } from 'components/Bg/Bg';
import { default as MenuBrandItem } from '../MenuBrandItem/MenuBrandItem';
import Routes from 'routes';
import SelectProViewContainer from 'containers/SelectProViewContainer/SelectProViewContainer';
import env from 'env';
import styled from 'styled-components';
import withCurrentAccount from 'containers/WithCurrentAccount';
import { useKeycloak } from '@react-keycloak/web';
import './AppLayout.css';
import { keycloak } from 'util/keycloak';

const BurgerButton = styled.button`
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 4px;
  font-size: 22px;
  color: #fff;
  padding: 5px 11px;
  outline: 0;
`;

const AppLayout = ({ selectedProView, currentAccount }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState(null);
  const { keycloak } = useKeycloak();

  return (
    <Router>
      <HandleRedirectWithRouter setActivePage={setActivePage} />
      <Sidebar.Pushable>
        {/* Sidebar */}
        <Sidebar
          vertical
          as={Menu}
          onHide={() => setSidebarOpen(false)}
          animation="push"
          direction="left"
          visible={sidebarOpen}
          width="thin"
        >
          <Menu.Item header style={{ paddingLeft: '1.68em' }}>
            Navigation
            <Icon
              name="times"
              floated="right"
              onClick={() => {
                setSidebarOpen(false);
              }}
            />
          </Menu.Item>
          <Links setSidebarOpen={setSidebarOpen} sidebar />
          {activePage !== '/relances' && (
            <SelectProViewContainer
              setSidebarOpen={setSidebarOpen}
              accountId={currentAccount.id}
              selectedProView={selectedProView}
              text="Mes contrats"
            />
          )}
        </Sidebar>
        <Sidebar.Pusher dimmed={sidebarOpen}>
          {/* Mobile menu */}
          <Responsive
            as={Menu}
            maxWidth={Responsive.onlyTablet.maxWidth}
            size="huge"
          >
            <MenuBrandItem />
            <Menu.Menu position="right">
              <Menu.Item>
                <BurgerButton onClick={() => setSidebarOpen(true)}>
                  <Icon style={{ margin: 0 }} name="bars" />
                </BurgerButton>
              </Menu.Item>
            </Menu.Menu>
          </Responsive>

          {/* Computer menu */}
          <Responsive
            as={Menu}
            {...Responsive.onlyComputer}
            stackable
            size="huge"
          >
            <Container>
              <MenuBrandItem />
              <Links setSidebarOpen={setSidebarOpen} />
              <Menu.Menu position="right">
                {activePage !== '/relances' && (
                  <SelectProViewContainer
                    display="dropdown"
                    accountId={currentAccount.id}
                    selectedProView={selectedProView}
                    text="Mes contrats"
                  />
                )}
                <ApolloConsumer>
                  {(client) => (
                    <Menu.Item
                      onClick={() => {
                        keycloak.logout();
                      }}
                      icon="power off"
                    />
                  )}
                </ApolloConsumer>
              </Menu.Menu>
            </Container>
          </Responsive>
          {selectedProView ? (
            <Fragment>
              <Container textAlign="center">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${env.SITE_URL}/${selectedProView.bilikZone.slug}/${selectedProView.proPresentation.mainTrade.slug}/${selectedProView.proPresentation.slug}.html`}
                >
                  Voir la fiche sur Bilik
                </a>
              </Container>
              <Routes
                selectedProView={selectedProView}
                currentAccount={currentAccount}
              />
            </Fragment>
          ) : (
            <BgWhite section>
              <Container>
                <Message style={{ marginTop: '54px' }}>
                  <strong>Aucune information disponible.</strong>
                  <br />
                  N&apos;hésitez pas à nous contacter pour plus
                  d&apos;informations.
                </Message>
              </Container>
            </BgWhite>
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Router>
  );
};

const Links = withRouter(
  ({ history, location, setSidebarOpen, sidebar = false }) => (
    <Fragment>
      {sidebar ? (
        <Menu.Item
          onClick={(event) => {
            history.push('/');
            setSidebarOpen(false);
          }}
        >
          <Icon name="chart line" />
          Statistiques
        </Menu.Item>
      ) : null}
      <Menu.Item
        onClick={(event) => {
          history.push('/appels');
          setSidebarOpen(false);
        }}
        active={!sidebar && location.pathname === '/appels'}
      >
        <Icon name="phone" />
        Appels
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          history.push('/sms');
          setSidebarOpen(false);
        }}
        active={!sidebar && location.pathname === '/sms'}
      >
        <Icon name="comment alternate" />
        SMS
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          history.push('/mails');
          setSidebarOpen(false);
        }}
        active={!sidebar && location.pathname === '/mails'}
      >
        <Icon name="envelope" />
        Mails
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          history.push('/avis');
          setSidebarOpen(false);
        }}
        active={!sidebar && location.pathname === '/avis'}
        className="stacked-icon-container"
      >
        <Icon>
          <span className="fa-stack fa-2x stacked-icon">
            <i className="fas fa-comment fa-stack-2x"></i>
            <i className="fas fa-question fa-stack-1x fa-inverse question-icon"></i>
          </span>
        </Icon>
        Avis
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          history.push('/relances');
          setSidebarOpen(false);
        }}
        active={!sidebar && location.pathname === '/relances'}
        className="stacked-icon-container"
      >
        <Icon>
          <span className="fa-stack fa-2x stacked-icon">
            <i className="fas fa-comment fa-stack-2x"></i>
            <i className="fas fa-question fa-stack-1x fa-inverse question-icon"></i>
          </span>
        </Icon>
        Demande d&apos;avis
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.push('/conges');
          setSidebarOpen(false);
        }}
        active={!sidebar && location.pathname === '/conges'}
      >
        <Icon name="wait" />
        Congés
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          history.push('/factures');
          setSidebarOpen(false);
        }}
        active={!sidebar && location.pathname === '/factures'}
      >
        <Icon name="file" />
        Factures
      </Menu.Item>
      <Menu.Item
        onClick={(event) => {
          history.push('/widgets');
          setSidebarOpen(false);
        }}
        active={!sidebar && location.pathname === '/widgets'}
      >
        <Icon name="setting" />
        Widgets
      </Menu.Item>

      {sidebar ? (
        <ApolloConsumer>
          {(client) => (
            <Menu.Item
              onClick={() => {
                keycloak.logout();
              }}
            >
              <Icon name="power off" />
              Déconnexion
            </Menu.Item>
          )}
        </ApolloConsumer>
      ) : null}
    </Fragment>
  ),
);

const HandleRedirect = ({ setActivePage, location }) => {
  useEffect(() => setActivePage(location.pathname), [location.pathname]);
  return null;
};

const HandleRedirectWithRouter = withRouter(HandleRedirect);
const AppLayoutWithCurrentAccount = withCurrentAccount(AppLayout);

export default AppLayoutWithCurrentAccount;
