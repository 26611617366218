import { apiClient } from 'api-client';
import React, { Fragment, useState } from 'react';
import { Button, Icon, Responsive } from 'semantic-ui-react';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';

const Confirm = ({ trigger, onConfirm }) => (
  <ConfirmModal
    header="Confirmation"
    content="Voulez vous vraiment supprimer ce congé ?"
    trigger={trigger}
    onConfirm={onConfirm}
  />
);
const deleteProHoliday = (proHolidayId) =>
  apiClient.post(`/form/pro-holiday`, {
    proHolidayId: proHolidayId,
    action: 'delete',
    namespace: 'pro',
  });

const DeleteProHolidayButtonContainer = ({
  proHolidayId,
  onSuccess,
  style,
  compact,
  icon,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const onConfirm = async () => {
    setSubmitting(true);
    deleteProHoliday(proHolidayId).then(() => {
      setSubmitting(false);
      onSuccess();
    });
  };

  return (
    <Fragment>
      <Responsive
        {...Responsive.onlyMobile}
        as={Confirm}
        onConfirm={onConfirm}
        trigger={
          <Button
            type="button"
            style={style}
            disabled={isSubmitting}
            fluid={!icon}
            icon={icon}
            compact
          >
            {!icon ? 'Supprimer' : <Icon name="trash" />}
          </Button>
        }
      />
      <Responsive
        minWidth={Responsive.onlyTablet.minWidth}
        as={Confirm}
        onConfirm={onConfirm}
        trigger={
          <Button
            type="button"
            icon
            style={style}
            disabled={isSubmitting}
            compact={compact}
          >
            Supprimer
          </Button>
        }
      />
    </Fragment>
  );
};

export default DeleteProHolidayButtonContainer;
