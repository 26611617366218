import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  timestamptz: any;
  geography: any;
  geometry: any;
  jsonb: any;
  numeric: any;
  bigint: any;
  smallint: any;
  name: any;
  float8: any;
};

/** columns and relationships of "account" */
export type Account = {
  __typename?: 'Account';
  /** An array relationship */
  bilikPeople: Array<BilikPerson>;
  /** An aggregate relationship */
  bilikPeopleAggregate: BilikPersonAggregate;
  /** An array relationship */
  devices: Array<AccountDevice>;
  /** An aggregate relationship */
  devicesAggregate: AccountDeviceAggregate;
  email: Scalars['String'];
  id: Scalars['Int'];
  /** An array relationship */
  mainProViews: Array<ProView>;
  /** An aggregate relationship */
  mainProViewsAggregate: ProViewAggregate;
  /** An array relationship */
  proViews: Array<AccountProView>;
  /** An aggregate relationship */
  proViewsAggregate: AccountProViewAggregate;
};


/** columns and relationships of "account" */
export type AccountBilikPeopleArgs = {
  distinctOn?: Maybe<Array<BilikPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikPersonOrderBy>>;
  where?: Maybe<BilikPersonBoolExp>;
};


/** columns and relationships of "account" */
export type AccountBilikPeopleAggregateArgs = {
  distinctOn?: Maybe<Array<BilikPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikPersonOrderBy>>;
  where?: Maybe<BilikPersonBoolExp>;
};


/** columns and relationships of "account" */
export type AccountDevicesArgs = {
  distinctOn?: Maybe<Array<AccountDeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountDeviceOrderBy>>;
  where?: Maybe<AccountDeviceBoolExp>;
};


/** columns and relationships of "account" */
export type AccountDevicesAggregateArgs = {
  distinctOn?: Maybe<Array<AccountDeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountDeviceOrderBy>>;
  where?: Maybe<AccountDeviceBoolExp>;
};


/** columns and relationships of "account" */
export type AccountMainProViewsArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


/** columns and relationships of "account" */
export type AccountMainProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


/** columns and relationships of "account" */
export type AccountProViewsArgs = {
  distinctOn?: Maybe<Array<AccountProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountProViewOrderBy>>;
  where?: Maybe<AccountProViewBoolExp>;
};


/** columns and relationships of "account" */
export type AccountProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<AccountProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountProViewOrderBy>>;
  where?: Maybe<AccountProViewBoolExp>;
};

/** aggregated selection of "account" */
export type AccountAggregate = {
  __typename?: 'AccountAggregate';
  aggregate?: Maybe<AccountAggregateFields>;
  nodes: Array<Account>;
};

/** aggregate fields of "account" */
export type AccountAggregateFields = {
  __typename?: 'AccountAggregateFields';
  avg?: Maybe<AccountAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountMaxFields>;
  min?: Maybe<AccountMinFields>;
  stddev?: Maybe<AccountStddevFields>;
  stddevPop?: Maybe<AccountStddevPopFields>;
  stddevSamp?: Maybe<AccountStddevSampFields>;
  sum?: Maybe<AccountSumFields>;
  varPop?: Maybe<AccountVarPopFields>;
  varSamp?: Maybe<AccountVarSampFields>;
  variance?: Maybe<AccountVarianceFields>;
};


/** aggregate fields of "account" */
export type AccountAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AccountAvgFields = {
  __typename?: 'AccountAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type AccountBoolExp = {
  _and?: Maybe<Array<AccountBoolExp>>;
  _not?: Maybe<AccountBoolExp>;
  _or?: Maybe<Array<AccountBoolExp>>;
  bilikPeople?: Maybe<BilikPersonBoolExp>;
  bilikPeopleAggregate?: Maybe<BilikPersonAggregateBoolExp>;
  devices?: Maybe<AccountDeviceBoolExp>;
  devicesAggregate?: Maybe<AccountDeviceAggregateBoolExp>;
  email?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  mainProViews?: Maybe<ProViewBoolExp>;
  mainProViewsAggregate?: Maybe<ProViewAggregateBoolExp>;
  proViews?: Maybe<AccountProViewBoolExp>;
  proViewsAggregate?: Maybe<AccountProViewAggregateBoolExp>;
};

/** unique or primary key constraints on table "account" */
export enum AccountConstraint {
  /** unique or primary key constraint on columns "id" */
  AccountPkey = 'account_pkey',
  /** unique or primary key constraint on columns "email" */
  Uniq_7d3656a4e7927c74 = 'uniq_7d3656a4e7927c74'
}

/** columns and relationships of "account_device" */
export type AccountDevice = {
  __typename?: 'AccountDevice';
  /** An object relationship */
  account: Account;
  accountId: Scalars['Int'];
  /** An object relationship */
  device: Device;
  deviceId: Scalars['Int'];
};

/** aggregated selection of "account_device" */
export type AccountDeviceAggregate = {
  __typename?: 'AccountDeviceAggregate';
  aggregate?: Maybe<AccountDeviceAggregateFields>;
  nodes: Array<AccountDevice>;
};

export type AccountDeviceAggregateBoolExp = {
  count?: Maybe<AccountDeviceAggregateBoolExpCount>;
};

export type AccountDeviceAggregateBoolExpCount = {
  arguments?: Maybe<Array<AccountDeviceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AccountDeviceBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "account_device" */
export type AccountDeviceAggregateFields = {
  __typename?: 'AccountDeviceAggregateFields';
  avg?: Maybe<AccountDeviceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountDeviceMaxFields>;
  min?: Maybe<AccountDeviceMinFields>;
  stddev?: Maybe<AccountDeviceStddevFields>;
  stddevPop?: Maybe<AccountDeviceStddevPopFields>;
  stddevSamp?: Maybe<AccountDeviceStddevSampFields>;
  sum?: Maybe<AccountDeviceSumFields>;
  varPop?: Maybe<AccountDeviceVarPopFields>;
  varSamp?: Maybe<AccountDeviceVarSampFields>;
  variance?: Maybe<AccountDeviceVarianceFields>;
};


/** aggregate fields of "account_device" */
export type AccountDeviceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountDeviceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_device" */
export type AccountDeviceAggregateOrderBy = {
  avg?: Maybe<AccountDeviceAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountDeviceMaxOrderBy>;
  min?: Maybe<AccountDeviceMinOrderBy>;
  stddev?: Maybe<AccountDeviceStddevOrderBy>;
  stddevPop?: Maybe<AccountDeviceStddevPopOrderBy>;
  stddevSamp?: Maybe<AccountDeviceStddevSampOrderBy>;
  sum?: Maybe<AccountDeviceSumOrderBy>;
  varPop?: Maybe<AccountDeviceVarPopOrderBy>;
  varSamp?: Maybe<AccountDeviceVarSampOrderBy>;
  variance?: Maybe<AccountDeviceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account_device" */
export type AccountDeviceArrRelInsertInput = {
  data: Array<AccountDeviceInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<AccountDeviceOnConflict>;
};

/** aggregate avg on columns */
export type AccountDeviceAvgFields = {
  __typename?: 'AccountDeviceAvgFields';
  accountId?: Maybe<Scalars['Float']>;
  deviceId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_device" */
export type AccountDeviceAvgOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account_device". All fields are combined with a logical 'AND'. */
export type AccountDeviceBoolExp = {
  _and?: Maybe<Array<AccountDeviceBoolExp>>;
  _not?: Maybe<AccountDeviceBoolExp>;
  _or?: Maybe<Array<AccountDeviceBoolExp>>;
  account?: Maybe<AccountBoolExp>;
  accountId?: Maybe<IntComparisonExp>;
  device?: Maybe<DeviceBoolExp>;
  deviceId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "account_device" */
export enum AccountDeviceConstraint {
  /** unique or primary key constraint on columns "account_id", "device_id" */
  AccountDevicePkey = 'account_device_pkey'
}

/** input type for incrementing numeric columns in table "account_device" */
export type AccountDeviceIncInput = {
  accountId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account_device" */
export type AccountDeviceInsertInput = {
  account?: Maybe<AccountObjRelInsertInput>;
  accountId?: Maybe<Scalars['Int']>;
  device?: Maybe<DeviceObjRelInsertInput>;
  deviceId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type AccountDeviceMaxFields = {
  __typename?: 'AccountDeviceMaxFields';
  accountId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "account_device" */
export type AccountDeviceMaxOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountDeviceMinFields = {
  __typename?: 'AccountDeviceMinFields';
  accountId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "account_device" */
export type AccountDeviceMinOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account_device" */
export type AccountDeviceMutationResponse = {
  __typename?: 'AccountDeviceMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountDevice>;
};

/** on_conflict condition type for table "account_device" */
export type AccountDeviceOnConflict = {
  constraint: AccountDeviceConstraint;
  updateColumns: Array<AccountDeviceUpdateColumn>;
  where?: Maybe<AccountDeviceBoolExp>;
};

/** Ordering options when selecting data from "account_device". */
export type AccountDeviceOrderBy = {
  account?: Maybe<AccountOrderBy>;
  accountId?: Maybe<OrderBy>;
  device?: Maybe<DeviceOrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_device */
export type AccountDevicePkColumnsInput = {
  accountId: Scalars['Int'];
  deviceId: Scalars['Int'];
};

/** select columns of table "account_device" */
export enum AccountDeviceSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  DeviceId = 'deviceId'
}

/** input type for updating data in table "account_device" */
export type AccountDeviceSetInput = {
  accountId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AccountDeviceStddevFields = {
  __typename?: 'AccountDeviceStddevFields';
  accountId?: Maybe<Scalars['Float']>;
  deviceId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_device" */
export type AccountDeviceStddevOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type AccountDeviceStddevPopFields = {
  __typename?: 'AccountDeviceStddevPopFields';
  accountId?: Maybe<Scalars['Float']>;
  deviceId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "account_device" */
export type AccountDeviceStddevPopOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type AccountDeviceStddevSampFields = {
  __typename?: 'AccountDeviceStddevSampFields';
  accountId?: Maybe<Scalars['Float']>;
  deviceId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "account_device" */
export type AccountDeviceStddevSampOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "account_device" */
export type AccountDeviceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountDeviceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountDeviceStreamCursorValueInput = {
  accountId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type AccountDeviceSumFields = {
  __typename?: 'AccountDeviceSumFields';
  accountId?: Maybe<Scalars['Int']>;
  deviceId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account_device" */
export type AccountDeviceSumOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** update columns of table "account_device" */
export enum AccountDeviceUpdateColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  DeviceId = 'deviceId'
}

export type AccountDeviceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<AccountDeviceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AccountDeviceSetInput>;
  /** filter the rows which have to be updated */
  where: AccountDeviceBoolExp;
};

/** aggregate variance on columns */
export type AccountDeviceVarianceFields = {
  __typename?: 'AccountDeviceVarianceFields';
  accountId?: Maybe<Scalars['Float']>;
  deviceId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_device" */
export type AccountDeviceVarianceOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type AccountDeviceVarPopFields = {
  __typename?: 'AccountDeviceVarPopFields';
  accountId?: Maybe<Scalars['Float']>;
  deviceId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "account_device" */
export type AccountDeviceVarPopOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type AccountDeviceVarSampFields = {
  __typename?: 'AccountDeviceVarSampFields';
  accountId?: Maybe<Scalars['Float']>;
  deviceId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "account_device" */
export type AccountDeviceVarSampOrderBy = {
  accountId?: Maybe<OrderBy>;
  deviceId?: Maybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "account" */
export type AccountIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account" */
export type AccountInsertInput = {
  bilikPeople?: Maybe<BilikPersonArrRelInsertInput>;
  devices?: Maybe<AccountDeviceArrRelInsertInput>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mainProViews?: Maybe<ProViewArrRelInsertInput>;
  proViews?: Maybe<AccountProViewArrRelInsertInput>;
};

/** aggregate max on columns */
export type AccountMaxFields = {
  __typename?: 'AccountMaxFields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type AccountMinFields = {
  __typename?: 'AccountMinFields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "account" */
export type AccountMutationResponse = {
  __typename?: 'AccountMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account>;
};

/** input type for inserting object relation for remote table "account" */
export type AccountObjRelInsertInput = {
  data: AccountInsertInput;
  /** upsert condition */
  onConflict?: Maybe<AccountOnConflict>;
};

/** on_conflict condition type for table "account" */
export type AccountOnConflict = {
  constraint: AccountConstraint;
  updateColumns: Array<AccountUpdateColumn>;
  where?: Maybe<AccountBoolExp>;
};

/** Ordering options when selecting data from "account". */
export type AccountOrderBy = {
  bilikPeopleAggregate?: Maybe<BilikPersonAggregateOrderBy>;
  devicesAggregate?: Maybe<AccountDeviceAggregateOrderBy>;
  email?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainProViewsAggregate?: Maybe<ProViewAggregateOrderBy>;
  proViewsAggregate?: Maybe<AccountProViewAggregateOrderBy>;
};

/** primary key columns input for table: account */
export type AccountPkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "account_pro_view" */
export type AccountProView = {
  __typename?: 'AccountProView';
  /** An object relationship */
  account: Account;
  accountId: Scalars['Int'];
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
};

/** aggregated selection of "account_pro_view" */
export type AccountProViewAggregate = {
  __typename?: 'AccountProViewAggregate';
  aggregate?: Maybe<AccountProViewAggregateFields>;
  nodes: Array<AccountProView>;
};

export type AccountProViewAggregateBoolExp = {
  count?: Maybe<AccountProViewAggregateBoolExpCount>;
};

export type AccountProViewAggregateBoolExpCount = {
  arguments?: Maybe<Array<AccountProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<AccountProViewBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "account_pro_view" */
export type AccountProViewAggregateFields = {
  __typename?: 'AccountProViewAggregateFields';
  avg?: Maybe<AccountProViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AccountProViewMaxFields>;
  min?: Maybe<AccountProViewMinFields>;
  stddev?: Maybe<AccountProViewStddevFields>;
  stddevPop?: Maybe<AccountProViewStddevPopFields>;
  stddevSamp?: Maybe<AccountProViewStddevSampFields>;
  sum?: Maybe<AccountProViewSumFields>;
  varPop?: Maybe<AccountProViewVarPopFields>;
  varSamp?: Maybe<AccountProViewVarSampFields>;
  variance?: Maybe<AccountProViewVarianceFields>;
};


/** aggregate fields of "account_pro_view" */
export type AccountProViewAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AccountProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_pro_view" */
export type AccountProViewAggregateOrderBy = {
  avg?: Maybe<AccountProViewAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AccountProViewMaxOrderBy>;
  min?: Maybe<AccountProViewMinOrderBy>;
  stddev?: Maybe<AccountProViewStddevOrderBy>;
  stddevPop?: Maybe<AccountProViewStddevPopOrderBy>;
  stddevSamp?: Maybe<AccountProViewStddevSampOrderBy>;
  sum?: Maybe<AccountProViewSumOrderBy>;
  varPop?: Maybe<AccountProViewVarPopOrderBy>;
  varSamp?: Maybe<AccountProViewVarSampOrderBy>;
  variance?: Maybe<AccountProViewVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "account_pro_view" */
export type AccountProViewArrRelInsertInput = {
  data: Array<AccountProViewInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<AccountProViewOnConflict>;
};

/** aggregate avg on columns */
export type AccountProViewAvgFields = {
  __typename?: 'AccountProViewAvgFields';
  accountId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_pro_view" */
export type AccountProViewAvgOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "account_pro_view". All fields are combined with a logical 'AND'. */
export type AccountProViewBoolExp = {
  _and?: Maybe<Array<AccountProViewBoolExp>>;
  _not?: Maybe<AccountProViewBoolExp>;
  _or?: Maybe<Array<AccountProViewBoolExp>>;
  account?: Maybe<AccountBoolExp>;
  accountId?: Maybe<IntComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "account_pro_view" */
export enum AccountProViewConstraint {
  /** unique or primary key constraint on columns "pro_view_id", "account_id" */
  AccountProViewPkey = 'account_pro_view_pkey'
}

/** input type for incrementing numeric columns in table "account_pro_view" */
export type AccountProViewIncInput = {
  accountId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "account_pro_view" */
export type AccountProViewInsertInput = {
  account?: Maybe<AccountObjRelInsertInput>;
  accountId?: Maybe<Scalars['Int']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type AccountProViewMaxFields = {
  __typename?: 'AccountProViewMaxFields';
  accountId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "account_pro_view" */
export type AccountProViewMaxOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AccountProViewMinFields = {
  __typename?: 'AccountProViewMinFields';
  accountId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "account_pro_view" */
export type AccountProViewMinOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "account_pro_view" */
export type AccountProViewMutationResponse = {
  __typename?: 'AccountProViewMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountProView>;
};

/** on_conflict condition type for table "account_pro_view" */
export type AccountProViewOnConflict = {
  constraint: AccountProViewConstraint;
  updateColumns: Array<AccountProViewUpdateColumn>;
  where?: Maybe<AccountProViewBoolExp>;
};

/** Ordering options when selecting data from "account_pro_view". */
export type AccountProViewOrderBy = {
  account?: Maybe<AccountOrderBy>;
  accountId?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** primary key columns input for table: account_pro_view */
export type AccountProViewPkColumnsInput = {
  accountId: Scalars['Int'];
  proViewId: Scalars['Int'];
};

/** select columns of table "account_pro_view" */
export enum AccountProViewSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ProViewId = 'proViewId'
}

/** input type for updating data in table "account_pro_view" */
export type AccountProViewSetInput = {
  accountId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AccountProViewStddevFields = {
  __typename?: 'AccountProViewStddevFields';
  accountId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_pro_view" */
export type AccountProViewStddevOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type AccountProViewStddevPopFields = {
  __typename?: 'AccountProViewStddevPopFields';
  accountId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "account_pro_view" */
export type AccountProViewStddevPopOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type AccountProViewStddevSampFields = {
  __typename?: 'AccountProViewStddevSampFields';
  accountId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "account_pro_view" */
export type AccountProViewStddevSampOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "account_pro_view" */
export type AccountProViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountProViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountProViewStreamCursorValueInput = {
  accountId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type AccountProViewSumFields = {
  __typename?: 'AccountProViewSumFields';
  accountId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "account_pro_view" */
export type AccountProViewSumOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "account_pro_view" */
export enum AccountProViewUpdateColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ProViewId = 'proViewId'
}

export type AccountProViewUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<AccountProViewIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AccountProViewSetInput>;
  /** filter the rows which have to be updated */
  where: AccountProViewBoolExp;
};

/** aggregate variance on columns */
export type AccountProViewVarianceFields = {
  __typename?: 'AccountProViewVarianceFields';
  accountId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_pro_view" */
export type AccountProViewVarianceOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type AccountProViewVarPopFields = {
  __typename?: 'AccountProViewVarPopFields';
  accountId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "account_pro_view" */
export type AccountProViewVarPopOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type AccountProViewVarSampFields = {
  __typename?: 'AccountProViewVarSampFields';
  accountId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "account_pro_view" */
export type AccountProViewVarSampOrderBy = {
  accountId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** select columns of table "account" */
export enum AccountSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "account" */
export type AccountSetInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type AccountStddevFields = {
  __typename?: 'AccountStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type AccountStddevPopFields = {
  __typename?: 'AccountStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type AccountStddevSampFields = {
  __typename?: 'AccountStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "account" */
export type AccountStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountStreamCursorValueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type AccountSumFields = {
  __typename?: 'AccountSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "account" */
export enum AccountUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id'
}

export type AccountUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<AccountIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AccountSetInput>;
  /** filter the rows which have to be updated */
  where: AccountBoolExp;
};

/** aggregate variance on columns */
export type AccountVarianceFields = {
  __typename?: 'AccountVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type AccountVarPopFields = {
  __typename?: 'AccountVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type AccountVarSampFields = {
  __typename?: 'AccountVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "action_token" */
export type ActionToken = {
  __typename?: 'ActionToken';
  dateCreated: Scalars['timestamptz'];
  id: Scalars['Int'];
  shortLink: Scalars['String'];
  token: Scalars['String'];
};

/** aggregated selection of "action_token" */
export type ActionTokenAggregate = {
  __typename?: 'ActionTokenAggregate';
  aggregate?: Maybe<ActionTokenAggregateFields>;
  nodes: Array<ActionToken>;
};

/** aggregate fields of "action_token" */
export type ActionTokenAggregateFields = {
  __typename?: 'ActionTokenAggregateFields';
  avg?: Maybe<ActionTokenAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ActionTokenMaxFields>;
  min?: Maybe<ActionTokenMinFields>;
  stddev?: Maybe<ActionTokenStddevFields>;
  stddevPop?: Maybe<ActionTokenStddevPopFields>;
  stddevSamp?: Maybe<ActionTokenStddevSampFields>;
  sum?: Maybe<ActionTokenSumFields>;
  varPop?: Maybe<ActionTokenVarPopFields>;
  varSamp?: Maybe<ActionTokenVarSampFields>;
  variance?: Maybe<ActionTokenVarianceFields>;
};


/** aggregate fields of "action_token" */
export type ActionTokenAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ActionTokenSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ActionTokenAvgFields = {
  __typename?: 'ActionTokenAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "action_token". All fields are combined with a logical 'AND'. */
export type ActionTokenBoolExp = {
  _and?: Maybe<Array<ActionTokenBoolExp>>;
  _not?: Maybe<ActionTokenBoolExp>;
  _or?: Maybe<Array<ActionTokenBoolExp>>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  shortLink?: Maybe<StringComparisonExp>;
  token?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "action_token" */
export enum ActionTokenConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22813Primary = 'idx_22813_primary'
}

/** input type for incrementing numeric columns in table "action_token" */
export type ActionTokenIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "action_token" */
export type ActionTokenInsertInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shortLink?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ActionTokenMaxFields = {
  __typename?: 'ActionTokenMaxFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shortLink?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ActionTokenMinFields = {
  __typename?: 'ActionTokenMinFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shortLink?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "action_token" */
export type ActionTokenMutationResponse = {
  __typename?: 'ActionTokenMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ActionToken>;
};

/** on_conflict condition type for table "action_token" */
export type ActionTokenOnConflict = {
  constraint: ActionTokenConstraint;
  updateColumns: Array<ActionTokenUpdateColumn>;
  where?: Maybe<ActionTokenBoolExp>;
};

/** Ordering options when selecting data from "action_token". */
export type ActionTokenOrderBy = {
  dateCreated?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  shortLink?: Maybe<OrderBy>;
  token?: Maybe<OrderBy>;
};

/** primary key columns input for table: action_token */
export type ActionTokenPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "action_token" */
export enum ActionTokenSelectColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Id = 'id',
  /** column name */
  ShortLink = 'shortLink',
  /** column name */
  Token = 'token'
}

/** input type for updating data in table "action_token" */
export type ActionTokenSetInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shortLink?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ActionTokenStddevFields = {
  __typename?: 'ActionTokenStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ActionTokenStddevPopFields = {
  __typename?: 'ActionTokenStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ActionTokenStddevSampFields = {
  __typename?: 'ActionTokenStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "action_token" */
export type ActionTokenStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ActionTokenStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionTokenStreamCursorValueInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shortLink?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ActionTokenSumFields = {
  __typename?: 'ActionTokenSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "action_token" */
export enum ActionTokenUpdateColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Id = 'id',
  /** column name */
  ShortLink = 'shortLink',
  /** column name */
  Token = 'token'
}

export type ActionTokenUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ActionTokenIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ActionTokenSetInput>;
  /** filter the rows which have to be updated */
  where: ActionTokenBoolExp;
};

/** aggregate variance on columns */
export type ActionTokenVarianceFields = {
  __typename?: 'ActionTokenVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type ActionTokenVarPopFields = {
  __typename?: 'ActionTokenVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ActionTokenVarSampFields = {
  __typename?: 'ActionTokenVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "autorespond" */
export type Autorespond = {
  __typename?: 'Autorespond';
  emailTemplate: Scalars['String'];
  entityUuid: Scalars['String'];
  id: Scalars['Int'];
  sendDate: Scalars['timestamptz'];
  status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "autorespond" */
export type AutorespondAggregate = {
  __typename?: 'AutorespondAggregate';
  aggregate?: Maybe<AutorespondAggregateFields>;
  nodes: Array<Autorespond>;
};

/** aggregate fields of "autorespond" */
export type AutorespondAggregateFields = {
  __typename?: 'AutorespondAggregateFields';
  avg?: Maybe<AutorespondAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<AutorespondMaxFields>;
  min?: Maybe<AutorespondMinFields>;
  stddev?: Maybe<AutorespondStddevFields>;
  stddevPop?: Maybe<AutorespondStddevPopFields>;
  stddevSamp?: Maybe<AutorespondStddevSampFields>;
  sum?: Maybe<AutorespondSumFields>;
  varPop?: Maybe<AutorespondVarPopFields>;
  varSamp?: Maybe<AutorespondVarSampFields>;
  variance?: Maybe<AutorespondVarianceFields>;
};


/** aggregate fields of "autorespond" */
export type AutorespondAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AutorespondSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type AutorespondAvgFields = {
  __typename?: 'AutorespondAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "autorespond". All fields are combined with a logical 'AND'. */
export type AutorespondBoolExp = {
  _and?: Maybe<Array<AutorespondBoolExp>>;
  _not?: Maybe<AutorespondBoolExp>;
  _or?: Maybe<Array<AutorespondBoolExp>>;
  emailTemplate?: Maybe<StringComparisonExp>;
  entityUuid?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  sendDate?: Maybe<TimestamptzComparisonExp>;
  status?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "autorespond" */
export enum AutorespondConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22822Primary = 'idx_22822_primary'
}

/** input type for incrementing numeric columns in table "autorespond" */
export type AutorespondIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "autorespond" */
export type AutorespondInsertInput = {
  emailTemplate?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AutorespondMaxFields = {
  __typename?: 'AutorespondMaxFields';
  emailTemplate?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AutorespondMinFields = {
  __typename?: 'AutorespondMinFields';
  emailTemplate?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "autorespond" */
export type AutorespondMutationResponse = {
  __typename?: 'AutorespondMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Autorespond>;
};

/** on_conflict condition type for table "autorespond" */
export type AutorespondOnConflict = {
  constraint: AutorespondConstraint;
  updateColumns: Array<AutorespondUpdateColumn>;
  where?: Maybe<AutorespondBoolExp>;
};

/** Ordering options when selecting data from "autorespond". */
export type AutorespondOrderBy = {
  emailTemplate?: Maybe<OrderBy>;
  entityUuid?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sendDate?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** primary key columns input for table: autorespond */
export type AutorespondPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "autorespond" */
export enum AutorespondSelectColumn {
  /** column name */
  EmailTemplate = 'emailTemplate',
  /** column name */
  EntityUuid = 'entityUuid',
  /** column name */
  Id = 'id',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "autorespond" */
export type AutorespondSetInput = {
  emailTemplate?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type AutorespondStddevFields = {
  __typename?: 'AutorespondStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type AutorespondStddevPopFields = {
  __typename?: 'AutorespondStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type AutorespondStddevSampFields = {
  __typename?: 'AutorespondStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "autorespond" */
export type AutorespondStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AutorespondStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AutorespondStreamCursorValueInput = {
  emailTemplate?: Maybe<Scalars['String']>;
  entityUuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type AutorespondSumFields = {
  __typename?: 'AutorespondSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "autorespond" */
export enum AutorespondUpdateColumn {
  /** column name */
  EmailTemplate = 'emailTemplate',
  /** column name */
  EntityUuid = 'entityUuid',
  /** column name */
  Id = 'id',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  Status = 'status'
}

export type AutorespondUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<AutorespondIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<AutorespondSetInput>;
  /** filter the rows which have to be updated */
  where: AutorespondBoolExp;
};

/** aggregate variance on columns */
export type AutorespondVarianceFields = {
  __typename?: 'AutorespondVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type AutorespondVarPopFields = {
  __typename?: 'AutorespondVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type AutorespondVarSampFields = {
  __typename?: 'AutorespondVarSampFields';
  id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "bilik_person" */
export type BilikPerson = {
  __typename?: 'BilikPerson';
  /** An object relationship */
  account: Account;
  accountId: Scalars['Int'];
  avatarHash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentDate: Scalars['timestamptz'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Int'];
  /** An array relationship */
  managedBilikZones: Array<ManagerBilikPersonBilikZone>;
  /** An aggregate relationship */
  managedBilikZonesAggregate: ManagerBilikPersonBilikZoneAggregate;
  /** An array relationship */
  memberBilikZones: Array<MemberBilikPersonBilikZone>;
  /** An aggregate relationship */
  memberBilikZonesAggregate: MemberBilikPersonBilikZoneAggregate;
  /** An array relationship */
  proReviews: Array<ProReview>;
  /** An aggregate relationship */
  proReviewsAggregate: ProReviewAggregate;
  roleName: Scalars['String'];
  status: Scalars['String'];
  telephone: Scalars['String'];
};


/** columns and relationships of "bilik_person" */
export type BilikPersonManagedBilikZonesArgs = {
  distinctOn?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ManagerBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_person" */
export type BilikPersonManagedBilikZonesAggregateArgs = {
  distinctOn?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ManagerBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_person" */
export type BilikPersonMemberBilikZonesArgs = {
  distinctOn?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MemberBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_person" */
export type BilikPersonMemberBilikZonesAggregateArgs = {
  distinctOn?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MemberBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_person" */
export type BilikPersonProReviewsArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


/** columns and relationships of "bilik_person" */
export type BilikPersonProReviewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};

/** aggregated selection of "bilik_person" */
export type BilikPersonAggregate = {
  __typename?: 'BilikPersonAggregate';
  aggregate?: Maybe<BilikPersonAggregateFields>;
  nodes: Array<BilikPerson>;
};

export type BilikPersonAggregateBoolExp = {
  count?: Maybe<BilikPersonAggregateBoolExpCount>;
};

export type BilikPersonAggregateBoolExpCount = {
  arguments?: Maybe<Array<BilikPersonSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<BilikPersonBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "bilik_person" */
export type BilikPersonAggregateFields = {
  __typename?: 'BilikPersonAggregateFields';
  avg?: Maybe<BilikPersonAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BilikPersonMaxFields>;
  min?: Maybe<BilikPersonMinFields>;
  stddev?: Maybe<BilikPersonStddevFields>;
  stddevPop?: Maybe<BilikPersonStddevPopFields>;
  stddevSamp?: Maybe<BilikPersonStddevSampFields>;
  sum?: Maybe<BilikPersonSumFields>;
  varPop?: Maybe<BilikPersonVarPopFields>;
  varSamp?: Maybe<BilikPersonVarSampFields>;
  variance?: Maybe<BilikPersonVarianceFields>;
};


/** aggregate fields of "bilik_person" */
export type BilikPersonAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BilikPersonSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bilik_person" */
export type BilikPersonAggregateOrderBy = {
  avg?: Maybe<BilikPersonAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<BilikPersonMaxOrderBy>;
  min?: Maybe<BilikPersonMinOrderBy>;
  stddev?: Maybe<BilikPersonStddevOrderBy>;
  stddevPop?: Maybe<BilikPersonStddevPopOrderBy>;
  stddevSamp?: Maybe<BilikPersonStddevSampOrderBy>;
  sum?: Maybe<BilikPersonSumOrderBy>;
  varPop?: Maybe<BilikPersonVarPopOrderBy>;
  varSamp?: Maybe<BilikPersonVarSampOrderBy>;
  variance?: Maybe<BilikPersonVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "bilik_person" */
export type BilikPersonArrRelInsertInput = {
  data: Array<BilikPersonInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<BilikPersonOnConflict>;
};

/** aggregate avg on columns */
export type BilikPersonAvgFields = {
  __typename?: 'BilikPersonAvgFields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bilik_person" */
export type BilikPersonAvgOrderBy = {
  accountId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "bilik_person". All fields are combined with a logical 'AND'. */
export type BilikPersonBoolExp = {
  _and?: Maybe<Array<BilikPersonBoolExp>>;
  _not?: Maybe<BilikPersonBoolExp>;
  _or?: Maybe<Array<BilikPersonBoolExp>>;
  account?: Maybe<AccountBoolExp>;
  accountId?: Maybe<IntComparisonExp>;
  avatarHash?: Maybe<StringComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  employmentDate?: Maybe<TimestamptzComparisonExp>;
  familyName?: Maybe<StringComparisonExp>;
  givenName?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  managedBilikZones?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
  managedBilikZonesAggregate?: Maybe<ManagerBilikPersonBilikZoneAggregateBoolExp>;
  memberBilikZones?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
  memberBilikZonesAggregate?: Maybe<MemberBilikPersonBilikZoneAggregateBoolExp>;
  proReviews?: Maybe<ProReviewBoolExp>;
  proReviewsAggregate?: Maybe<ProReviewAggregateBoolExp>;
  roleName?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  telephone?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "bilik_person" */
export enum BilikPersonConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22831Primary = 'idx_22831_primary'
}

/** input type for incrementing numeric columns in table "bilik_person" */
export type BilikPersonIncInput = {
  accountId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "bilik_person" */
export type BilikPersonInsertInput = {
  account?: Maybe<AccountObjRelInsertInput>;
  accountId?: Maybe<Scalars['Int']>;
  avatarHash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  managedBilikZones?: Maybe<ManagerBilikPersonBilikZoneArrRelInsertInput>;
  memberBilikZones?: Maybe<MemberBilikPersonBilikZoneArrRelInsertInput>;
  proReviews?: Maybe<ProReviewArrRelInsertInput>;
  roleName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BilikPersonMaxFields = {
  __typename?: 'BilikPersonMaxFields';
  accountId?: Maybe<Scalars['Int']>;
  avatarHash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  roleName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "bilik_person" */
export type BilikPersonMaxOrderBy = {
  accountId?: Maybe<OrderBy>;
  avatarHash?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  employmentDate?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  roleName?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BilikPersonMinFields = {
  __typename?: 'BilikPersonMinFields';
  accountId?: Maybe<Scalars['Int']>;
  avatarHash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  roleName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "bilik_person" */
export type BilikPersonMinOrderBy = {
  accountId?: Maybe<OrderBy>;
  avatarHash?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  employmentDate?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  roleName?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** response of any mutation on the table "bilik_person" */
export type BilikPersonMutationResponse = {
  __typename?: 'BilikPersonMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BilikPerson>;
};

/** input type for inserting object relation for remote table "bilik_person" */
export type BilikPersonObjRelInsertInput = {
  data: BilikPersonInsertInput;
  /** upsert condition */
  onConflict?: Maybe<BilikPersonOnConflict>;
};

/** on_conflict condition type for table "bilik_person" */
export type BilikPersonOnConflict = {
  constraint: BilikPersonConstraint;
  updateColumns: Array<BilikPersonUpdateColumn>;
  where?: Maybe<BilikPersonBoolExp>;
};

/** Ordering options when selecting data from "bilik_person". */
export type BilikPersonOrderBy = {
  account?: Maybe<AccountOrderBy>;
  accountId?: Maybe<OrderBy>;
  avatarHash?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  employmentDate?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  managedBilikZonesAggregate?: Maybe<ManagerBilikPersonBilikZoneAggregateOrderBy>;
  memberBilikZonesAggregate?: Maybe<MemberBilikPersonBilikZoneAggregateOrderBy>;
  proReviewsAggregate?: Maybe<ProReviewAggregateOrderBy>;
  roleName?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** primary key columns input for table: bilik_person */
export type BilikPersonPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "bilik_person" */
export enum BilikPersonSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AvatarHash = 'avatarHash',
  /** column name */
  Description = 'description',
  /** column name */
  EmploymentDate = 'employmentDate',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  RoleName = 'roleName',
  /** column name */
  Status = 'status',
  /** column name */
  Telephone = 'telephone'
}

/** input type for updating data in table "bilik_person" */
export type BilikPersonSetInput = {
  accountId?: Maybe<Scalars['Int']>;
  avatarHash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  roleName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type BilikPersonStddevFields = {
  __typename?: 'BilikPersonStddevFields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bilik_person" */
export type BilikPersonStddevOrderBy = {
  accountId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type BilikPersonStddevPopFields = {
  __typename?: 'BilikPersonStddevPopFields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "bilik_person" */
export type BilikPersonStddevPopOrderBy = {
  accountId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type BilikPersonStddevSampFields = {
  __typename?: 'BilikPersonStddevSampFields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "bilik_person" */
export type BilikPersonStddevSampOrderBy = {
  accountId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "bilik_person" */
export type BilikPersonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BilikPersonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BilikPersonStreamCursorValueInput = {
  accountId?: Maybe<Scalars['Int']>;
  avatarHash?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentDate?: Maybe<Scalars['timestamptz']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  roleName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type BilikPersonSumFields = {
  __typename?: 'BilikPersonSumFields';
  accountId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bilik_person" */
export type BilikPersonSumOrderBy = {
  accountId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "bilik_person" */
export enum BilikPersonUpdateColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AvatarHash = 'avatarHash',
  /** column name */
  Description = 'description',
  /** column name */
  EmploymentDate = 'employmentDate',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  RoleName = 'roleName',
  /** column name */
  Status = 'status',
  /** column name */
  Telephone = 'telephone'
}

export type BilikPersonUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<BilikPersonIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<BilikPersonSetInput>;
  /** filter the rows which have to be updated */
  where: BilikPersonBoolExp;
};

/** aggregate variance on columns */
export type BilikPersonVarianceFields = {
  __typename?: 'BilikPersonVarianceFields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bilik_person" */
export type BilikPersonVarianceOrderBy = {
  accountId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type BilikPersonVarPopFields = {
  __typename?: 'BilikPersonVarPopFields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "bilik_person" */
export type BilikPersonVarPopOrderBy = {
  accountId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type BilikPersonVarSampFields = {
  __typename?: 'BilikPersonVarSampFields';
  accountId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "bilik_person" */
export type BilikPersonVarSampOrderBy = {
  accountId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "bilik_solicitation" */
export type BilikSolicitation = {
  __typename?: 'BilikSolicitation';
  addressLocality: Scalars['String'];
  bilikZoneId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  bilik_zone?: Maybe<BilikZone>;
  dateCreated: Scalars['timestamptz'];
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Int'];
  ip?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  telephone: Scalars['String'];
};

/** aggregated selection of "bilik_solicitation" */
export type BilikSolicitationAggregate = {
  __typename?: 'BilikSolicitationAggregate';
  aggregate?: Maybe<BilikSolicitationAggregateFields>;
  nodes: Array<BilikSolicitation>;
};

export type BilikSolicitationAggregateBoolExp = {
  count?: Maybe<BilikSolicitationAggregateBoolExpCount>;
};

export type BilikSolicitationAggregateBoolExpCount = {
  arguments?: Maybe<Array<BilikSolicitationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<BilikSolicitationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "bilik_solicitation" */
export type BilikSolicitationAggregateFields = {
  __typename?: 'BilikSolicitationAggregateFields';
  avg?: Maybe<BilikSolicitationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BilikSolicitationMaxFields>;
  min?: Maybe<BilikSolicitationMinFields>;
  stddev?: Maybe<BilikSolicitationStddevFields>;
  stddevPop?: Maybe<BilikSolicitationStddevPopFields>;
  stddevSamp?: Maybe<BilikSolicitationStddevSampFields>;
  sum?: Maybe<BilikSolicitationSumFields>;
  varPop?: Maybe<BilikSolicitationVarPopFields>;
  varSamp?: Maybe<BilikSolicitationVarSampFields>;
  variance?: Maybe<BilikSolicitationVarianceFields>;
};


/** aggregate fields of "bilik_solicitation" */
export type BilikSolicitationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BilikSolicitationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bilik_solicitation" */
export type BilikSolicitationAggregateOrderBy = {
  avg?: Maybe<BilikSolicitationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<BilikSolicitationMaxOrderBy>;
  min?: Maybe<BilikSolicitationMinOrderBy>;
  stddev?: Maybe<BilikSolicitationStddevOrderBy>;
  stddevPop?: Maybe<BilikSolicitationStddevPopOrderBy>;
  stddevSamp?: Maybe<BilikSolicitationStddevSampOrderBy>;
  sum?: Maybe<BilikSolicitationSumOrderBy>;
  varPop?: Maybe<BilikSolicitationVarPopOrderBy>;
  varSamp?: Maybe<BilikSolicitationVarSampOrderBy>;
  variance?: Maybe<BilikSolicitationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "bilik_solicitation" */
export type BilikSolicitationArrRelInsertInput = {
  data: Array<BilikSolicitationInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<BilikSolicitationOnConflict>;
};

/** aggregate avg on columns */
export type BilikSolicitationAvgFields = {
  __typename?: 'BilikSolicitationAvgFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bilik_solicitation" */
export type BilikSolicitationAvgOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "bilik_solicitation". All fields are combined with a logical 'AND'. */
export type BilikSolicitationBoolExp = {
  _and?: Maybe<Array<BilikSolicitationBoolExp>>;
  _not?: Maybe<BilikSolicitationBoolExp>;
  _or?: Maybe<Array<BilikSolicitationBoolExp>>;
  addressLocality?: Maybe<StringComparisonExp>;
  bilikZoneId?: Maybe<IntComparisonExp>;
  bilik_zone?: Maybe<BilikZoneBoolExp>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  familyName?: Maybe<StringComparisonExp>;
  givenName?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  ip?: Maybe<StringComparisonExp>;
  message?: Maybe<StringComparisonExp>;
  telephone?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "bilik_solicitation" */
export enum BilikSolicitationConstraint {
  /** unique or primary key constraint on columns "id" */
  BilikSolicitationPkey = 'bilik_solicitation_pkey'
}

/** input type for incrementing numeric columns in table "bilik_solicitation" */
export type BilikSolicitationIncInput = {
  bilikZoneId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "bilik_solicitation" */
export type BilikSolicitationInsertInput = {
  addressLocality?: Maybe<Scalars['String']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  bilik_zone?: Maybe<BilikZoneObjRelInsertInput>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type BilikSolicitationMaxFields = {
  __typename?: 'BilikSolicitationMaxFields';
  addressLocality?: Maybe<Scalars['String']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "bilik_solicitation" */
export type BilikSolicitationMaxOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BilikSolicitationMinFields = {
  __typename?: 'BilikSolicitationMinFields';
  addressLocality?: Maybe<Scalars['String']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "bilik_solicitation" */
export type BilikSolicitationMinOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** response of any mutation on the table "bilik_solicitation" */
export type BilikSolicitationMutationResponse = {
  __typename?: 'BilikSolicitationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BilikSolicitation>;
};

/** on_conflict condition type for table "bilik_solicitation" */
export type BilikSolicitationOnConflict = {
  constraint: BilikSolicitationConstraint;
  updateColumns: Array<BilikSolicitationUpdateColumn>;
  where?: Maybe<BilikSolicitationBoolExp>;
};

/** Ordering options when selecting data from "bilik_solicitation". */
export type BilikSolicitationOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  bilik_zone?: Maybe<BilikZoneOrderBy>;
  dateCreated?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** primary key columns input for table: bilik_solicitation */
export type BilikSolicitationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "bilik_solicitation" */
export enum BilikSolicitationSelectColumn {
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Message = 'message',
  /** column name */
  Telephone = 'telephone'
}

/** input type for updating data in table "bilik_solicitation" */
export type BilikSolicitationSetInput = {
  addressLocality?: Maybe<Scalars['String']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type BilikSolicitationStddevFields = {
  __typename?: 'BilikSolicitationStddevFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bilik_solicitation" */
export type BilikSolicitationStddevOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type BilikSolicitationStddevPopFields = {
  __typename?: 'BilikSolicitationStddevPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "bilik_solicitation" */
export type BilikSolicitationStddevPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type BilikSolicitationStddevSampFields = {
  __typename?: 'BilikSolicitationStddevSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "bilik_solicitation" */
export type BilikSolicitationStddevSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "bilik_solicitation" */
export type BilikSolicitationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BilikSolicitationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BilikSolicitationStreamCursorValueInput = {
  addressLocality?: Maybe<Scalars['String']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type BilikSolicitationSumFields = {
  __typename?: 'BilikSolicitationSumFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bilik_solicitation" */
export type BilikSolicitationSumOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** update columns of table "bilik_solicitation" */
export enum BilikSolicitationUpdateColumn {
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Message = 'message',
  /** column name */
  Telephone = 'telephone'
}

export type BilikSolicitationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<BilikSolicitationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<BilikSolicitationSetInput>;
  /** filter the rows which have to be updated */
  where: BilikSolicitationBoolExp;
};

/** aggregate variance on columns */
export type BilikSolicitationVarianceFields = {
  __typename?: 'BilikSolicitationVarianceFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bilik_solicitation" */
export type BilikSolicitationVarianceOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type BilikSolicitationVarPopFields = {
  __typename?: 'BilikSolicitationVarPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "bilik_solicitation" */
export type BilikSolicitationVarPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type BilikSolicitationVarSampFields = {
  __typename?: 'BilikSolicitationVarSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "bilik_solicitation" */
export type BilikSolicitationVarSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
};

/** columns and relationships of "bilik_zone" */
export type BilikZone = {
  __typename?: 'BilikZone';
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality: Scalars['String'];
  addressRegion?: Maybe<Scalars['String']>;
  area: Scalars['geometry'];
  /** An aggregate relationship */
  bilikSolicitationsAggregate: BilikSolicitationAggregate;
  /** An array relationship */
  bilik_solicitations: Array<BilikSolicitation>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  cities: Array<City>;
  /** An aggregate relationship */
  citiesAggregate: CityAggregate;
  /** An array relationship */
  citySitemap: Array<SitemapCity>;
  /** An aggregate relationship */
  citySitemapAggregate: SitemapCityAggregate;
  genericEmail: Scalars['String'];
  guideName: Scalars['String'];
  hasDistrict: Scalars['Boolean'];
  hasMultiplePostalCode: Scalars['Boolean'];
  id: Scalars['Int'];
  /** An object relationship */
  mainCity?: Maybe<City>;
  mainCityCode: Scalars['String'];
  mainCityId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  managers: Array<ManagerBilikPersonBilikZone>;
  /** An aggregate relationship */
  managersAggregate: ManagerBilikPersonBilikZoneAggregate;
  /** An array relationship */
  members: Array<MemberBilikPersonBilikZone>;
  /** An aggregate relationship */
  membersAggregate: MemberBilikPersonBilikZoneAggregate;
  name: Scalars['String'];
  postOfficeBoxNumber?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  /** An array relationship */
  proViews: Array<ProView>;
  /** An aggregate relationship */
  proViewsAggregate: ProViewAggregate;
  regionPostalCode: Scalars['String'];
  /** An array relationship */
  sitemap: Array<SitemapBilikZone>;
  /** An aggregate relationship */
  sitemapAggregate: SitemapBilikZoneAggregate;
  slug: Scalars['String'];
  status: Scalars['String'];
  streetAddress: Scalars['String'];
  teamMessage: Scalars['String'];
  telephone: Scalars['String'];
  telephoneSms: Scalars['String'];
  /** An array relationship */
  tradeMainProCount: Array<BilikZoneTradeMainProCount>;
  /** An aggregate relationship */
  tradeMainProCountAggregate: BilikZoneTradeMainProCountAggregate;
  twilioAddressSid?: Maybe<Scalars['String']>;
  twilioBundleSid?: Maybe<Scalars['String']>;
  /** An object relationship */
  zohoOrganization?: Maybe<ZohoOrganization>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneBilikSolicitationsAggregateArgs = {
  distinctOn?: Maybe<Array<BilikSolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikSolicitationOrderBy>>;
  where?: Maybe<BilikSolicitationBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneBilikSolicitationsArgs = {
  distinctOn?: Maybe<Array<BilikSolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikSolicitationOrderBy>>;
  where?: Maybe<BilikSolicitationBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneCallTrackingTargetArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneCitiesArgs = {
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneCitiesAggregateArgs = {
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneCitySitemapArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneCitySitemapAggregateArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneManagersArgs = {
  distinctOn?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ManagerBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneManagersAggregateArgs = {
  distinctOn?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ManagerBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneMembersArgs = {
  distinctOn?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MemberBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneMembersAggregateArgs = {
  distinctOn?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MemberBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneProViewsArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneSitemapArgs = {
  distinctOn?: Maybe<Array<SitemapBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapBilikZoneOrderBy>>;
  where?: Maybe<SitemapBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneSitemapAggregateArgs = {
  distinctOn?: Maybe<Array<SitemapBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapBilikZoneOrderBy>>;
  where?: Maybe<SitemapBilikZoneBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneTradeMainProCountArgs = {
  distinctOn?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneTradeMainProCountOrderBy>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};


/** columns and relationships of "bilik_zone" */
export type BilikZoneTradeMainProCountAggregateArgs = {
  distinctOn?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneTradeMainProCountOrderBy>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};

/** aggregated selection of "bilik_zone" */
export type BilikZoneAggregate = {
  __typename?: 'BilikZoneAggregate';
  aggregate?: Maybe<BilikZoneAggregateFields>;
  nodes: Array<BilikZone>;
};

export type BilikZoneAggregateBoolExp = {
  bool_and?: Maybe<BilikZoneAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<BilikZoneAggregateBoolExpBoolOr>;
  count?: Maybe<BilikZoneAggregateBoolExpCount>;
};

export type BilikZoneAggregateBoolExpBoolAnd = {
  arguments: BilikZoneSelectColumnBilikZoneAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<BilikZoneBoolExp>;
  predicate: BooleanComparisonExp;
};

export type BilikZoneAggregateBoolExpBoolOr = {
  arguments: BilikZoneSelectColumnBilikZoneAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<BilikZoneBoolExp>;
  predicate: BooleanComparisonExp;
};

export type BilikZoneAggregateBoolExpCount = {
  arguments?: Maybe<Array<BilikZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<BilikZoneBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "bilik_zone" */
export type BilikZoneAggregateFields = {
  __typename?: 'BilikZoneAggregateFields';
  avg?: Maybe<BilikZoneAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BilikZoneMaxFields>;
  min?: Maybe<BilikZoneMinFields>;
  stddev?: Maybe<BilikZoneStddevFields>;
  stddevPop?: Maybe<BilikZoneStddevPopFields>;
  stddevSamp?: Maybe<BilikZoneStddevSampFields>;
  sum?: Maybe<BilikZoneSumFields>;
  varPop?: Maybe<BilikZoneVarPopFields>;
  varSamp?: Maybe<BilikZoneVarSampFields>;
  variance?: Maybe<BilikZoneVarianceFields>;
};


/** aggregate fields of "bilik_zone" */
export type BilikZoneAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BilikZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bilik_zone" */
export type BilikZoneAggregateOrderBy = {
  avg?: Maybe<BilikZoneAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<BilikZoneMaxOrderBy>;
  min?: Maybe<BilikZoneMinOrderBy>;
  stddev?: Maybe<BilikZoneStddevOrderBy>;
  stddevPop?: Maybe<BilikZoneStddevPopOrderBy>;
  stddevSamp?: Maybe<BilikZoneStddevSampOrderBy>;
  sum?: Maybe<BilikZoneSumOrderBy>;
  varPop?: Maybe<BilikZoneVarPopOrderBy>;
  varSamp?: Maybe<BilikZoneVarSampOrderBy>;
  variance?: Maybe<BilikZoneVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type BilikZoneAppendInput = {
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "bilik_zone" */
export type BilikZoneArrRelInsertInput = {
  data: Array<BilikZoneInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<BilikZoneOnConflict>;
};

/** aggregate avg on columns */
export type BilikZoneAvgFields = {
  __typename?: 'BilikZoneAvgFields';
  id?: Maybe<Scalars['Float']>;
  mainCityId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bilik_zone" */
export type BilikZoneAvgOrderBy = {
  id?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "bilik_zone". All fields are combined with a logical 'AND'. */
export type BilikZoneBoolExp = {
  _and?: Maybe<Array<BilikZoneBoolExp>>;
  _not?: Maybe<BilikZoneBoolExp>;
  _or?: Maybe<Array<BilikZoneBoolExp>>;
  addressCountry?: Maybe<StringComparisonExp>;
  addressLocality?: Maybe<StringComparisonExp>;
  addressRegion?: Maybe<StringComparisonExp>;
  area?: Maybe<GeometryComparisonExp>;
  bilik_solicitations?: Maybe<BilikSolicitationBoolExp>;
  bilik_solicitationsAggregate?: Maybe<BilikSolicitationAggregateBoolExp>;
  callTrackingTarget?: Maybe<JsonbComparisonExp>;
  cities?: Maybe<CityBoolExp>;
  citiesAggregate?: Maybe<CityAggregateBoolExp>;
  citySitemap?: Maybe<SitemapCityBoolExp>;
  citySitemapAggregate?: Maybe<SitemapCityAggregateBoolExp>;
  genericEmail?: Maybe<StringComparisonExp>;
  guideName?: Maybe<StringComparisonExp>;
  hasDistrict?: Maybe<BooleanComparisonExp>;
  hasMultiplePostalCode?: Maybe<BooleanComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  mainCity?: Maybe<CityBoolExp>;
  mainCityCode?: Maybe<StringComparisonExp>;
  mainCityId?: Maybe<IntComparisonExp>;
  managers?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
  managersAggregate?: Maybe<ManagerBilikPersonBilikZoneAggregateBoolExp>;
  members?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
  membersAggregate?: Maybe<MemberBilikPersonBilikZoneAggregateBoolExp>;
  name?: Maybe<StringComparisonExp>;
  postOfficeBoxNumber?: Maybe<StringComparisonExp>;
  postalCode?: Maybe<StringComparisonExp>;
  proViews?: Maybe<ProViewBoolExp>;
  proViewsAggregate?: Maybe<ProViewAggregateBoolExp>;
  regionPostalCode?: Maybe<StringComparisonExp>;
  sitemap?: Maybe<SitemapBilikZoneBoolExp>;
  sitemapAggregate?: Maybe<SitemapBilikZoneAggregateBoolExp>;
  slug?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  streetAddress?: Maybe<StringComparisonExp>;
  teamMessage?: Maybe<StringComparisonExp>;
  telephone?: Maybe<StringComparisonExp>;
  telephoneSms?: Maybe<StringComparisonExp>;
  tradeMainProCount?: Maybe<BilikZoneTradeMainProCountBoolExp>;
  tradeMainProCountAggregate?: Maybe<BilikZoneTradeMainProCountAggregateBoolExp>;
  twilioAddressSid?: Maybe<StringComparisonExp>;
  twilioBundleSid?: Maybe<StringComparisonExp>;
  zohoOrganization?: Maybe<ZohoOrganizationBoolExp>;
  zohoOrganizationId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "bilik_zone" */
export enum BilikZoneConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22843Primary = 'idx_22843_primary',
  /** unique or primary key constraint on columns "slug" */
  Idx_22843Uniq_6ed8f9e989d9b62 = 'idx_22843_uniq_6ed8f9e989d9b62',
  /** unique or primary key constraint on columns "telephone_sms" */
  Idx_22843Uniq_6ed8f9e9931ae12 = 'idx_22843_uniq_6ed8f9e9931ae12'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type BilikZoneDeleteAtPathInput = {
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type BilikZoneDeleteElemInput = {
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type BilikZoneDeleteKeyInput = {
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "bilik_zone" */
export type BilikZoneIncInput = {
  id?: Maybe<Scalars['Int']>;
  mainCityId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "bilik_zone" */
export type BilikZoneInsertInput = {
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['geometry']>;
  bilik_solicitations?: Maybe<BilikSolicitationArrRelInsertInput>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  cities?: Maybe<CityArrRelInsertInput>;
  citySitemap?: Maybe<SitemapCityArrRelInsertInput>;
  genericEmail?: Maybe<Scalars['String']>;
  guideName?: Maybe<Scalars['String']>;
  hasDistrict?: Maybe<Scalars['Boolean']>;
  hasMultiplePostalCode?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  mainCity?: Maybe<CityObjRelInsertInput>;
  mainCityCode?: Maybe<Scalars['String']>;
  mainCityId?: Maybe<Scalars['Int']>;
  managers?: Maybe<ManagerBilikPersonBilikZoneArrRelInsertInput>;
  members?: Maybe<MemberBilikPersonBilikZoneArrRelInsertInput>;
  name?: Maybe<Scalars['String']>;
  postOfficeBoxNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  proViews?: Maybe<ProViewArrRelInsertInput>;
  regionPostalCode?: Maybe<Scalars['String']>;
  sitemap?: Maybe<SitemapBilikZoneArrRelInsertInput>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  teamMessage?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  telephoneSms?: Maybe<Scalars['String']>;
  tradeMainProCount?: Maybe<BilikZoneTradeMainProCountArrRelInsertInput>;
  twilioAddressSid?: Maybe<Scalars['String']>;
  twilioBundleSid?: Maybe<Scalars['String']>;
  zohoOrganization?: Maybe<ZohoOrganizationObjRelInsertInput>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type BilikZoneMaxFields = {
  __typename?: 'BilikZoneMaxFields';
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  genericEmail?: Maybe<Scalars['String']>;
  guideName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mainCityCode?: Maybe<Scalars['String']>;
  mainCityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  postOfficeBoxNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  regionPostalCode?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  teamMessage?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  telephoneSms?: Maybe<Scalars['String']>;
  twilioAddressSid?: Maybe<Scalars['String']>;
  twilioBundleSid?: Maybe<Scalars['String']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bilik_zone" */
export type BilikZoneMaxOrderBy = {
  addressCountry?: Maybe<OrderBy>;
  addressLocality?: Maybe<OrderBy>;
  addressRegion?: Maybe<OrderBy>;
  genericEmail?: Maybe<OrderBy>;
  guideName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainCityCode?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  postOfficeBoxNumber?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  regionPostalCode?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  streetAddress?: Maybe<OrderBy>;
  teamMessage?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
  telephoneSms?: Maybe<OrderBy>;
  twilioAddressSid?: Maybe<OrderBy>;
  twilioBundleSid?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BilikZoneMinFields = {
  __typename?: 'BilikZoneMinFields';
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  genericEmail?: Maybe<Scalars['String']>;
  guideName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mainCityCode?: Maybe<Scalars['String']>;
  mainCityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  postOfficeBoxNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  regionPostalCode?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  teamMessage?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  telephoneSms?: Maybe<Scalars['String']>;
  twilioAddressSid?: Maybe<Scalars['String']>;
  twilioBundleSid?: Maybe<Scalars['String']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "bilik_zone" */
export type BilikZoneMinOrderBy = {
  addressCountry?: Maybe<OrderBy>;
  addressLocality?: Maybe<OrderBy>;
  addressRegion?: Maybe<OrderBy>;
  genericEmail?: Maybe<OrderBy>;
  guideName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainCityCode?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  postOfficeBoxNumber?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  regionPostalCode?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  streetAddress?: Maybe<OrderBy>;
  teamMessage?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
  telephoneSms?: Maybe<OrderBy>;
  twilioAddressSid?: Maybe<OrderBy>;
  twilioBundleSid?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "bilik_zone" */
export type BilikZoneMutationResponse = {
  __typename?: 'BilikZoneMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<BilikZone>;
};

/** input type for inserting object relation for remote table "bilik_zone" */
export type BilikZoneObjRelInsertInput = {
  data: BilikZoneInsertInput;
  /** upsert condition */
  onConflict?: Maybe<BilikZoneOnConflict>;
};

/** on_conflict condition type for table "bilik_zone" */
export type BilikZoneOnConflict = {
  constraint: BilikZoneConstraint;
  updateColumns: Array<BilikZoneUpdateColumn>;
  where?: Maybe<BilikZoneBoolExp>;
};

/** Ordering options when selecting data from "bilik_zone". */
export type BilikZoneOrderBy = {
  addressCountry?: Maybe<OrderBy>;
  addressLocality?: Maybe<OrderBy>;
  addressRegion?: Maybe<OrderBy>;
  area?: Maybe<OrderBy>;
  bilik_solicitationsAggregate?: Maybe<BilikSolicitationAggregateOrderBy>;
  callTrackingTarget?: Maybe<OrderBy>;
  citiesAggregate?: Maybe<CityAggregateOrderBy>;
  citySitemapAggregate?: Maybe<SitemapCityAggregateOrderBy>;
  genericEmail?: Maybe<OrderBy>;
  guideName?: Maybe<OrderBy>;
  hasDistrict?: Maybe<OrderBy>;
  hasMultiplePostalCode?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainCity?: Maybe<CityOrderBy>;
  mainCityCode?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  managersAggregate?: Maybe<ManagerBilikPersonBilikZoneAggregateOrderBy>;
  membersAggregate?: Maybe<MemberBilikPersonBilikZoneAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  postOfficeBoxNumber?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  proViewsAggregate?: Maybe<ProViewAggregateOrderBy>;
  regionPostalCode?: Maybe<OrderBy>;
  sitemapAggregate?: Maybe<SitemapBilikZoneAggregateOrderBy>;
  slug?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  streetAddress?: Maybe<OrderBy>;
  teamMessage?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
  telephoneSms?: Maybe<OrderBy>;
  tradeMainProCountAggregate?: Maybe<BilikZoneTradeMainProCountAggregateOrderBy>;
  twilioAddressSid?: Maybe<OrderBy>;
  twilioBundleSid?: Maybe<OrderBy>;
  zohoOrganization?: Maybe<ZohoOrganizationOrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** primary key columns input for table: bilik_zone */
export type BilikZonePkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type BilikZonePrependInput = {
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "bilik_zone" */
export enum BilikZoneSelectColumn {
  /** column name */
  AddressCountry = 'addressCountry',
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  AddressRegion = 'addressRegion',
  /** column name */
  Area = 'area',
  /** column name */
  CallTrackingTarget = 'callTrackingTarget',
  /** column name */
  GenericEmail = 'genericEmail',
  /** column name */
  GuideName = 'guideName',
  /** column name */
  HasDistrict = 'hasDistrict',
  /** column name */
  HasMultiplePostalCode = 'hasMultiplePostalCode',
  /** column name */
  Id = 'id',
  /** column name */
  MainCityCode = 'mainCityCode',
  /** column name */
  MainCityId = 'mainCityId',
  /** column name */
  Name = 'name',
  /** column name */
  PostOfficeBoxNumber = 'postOfficeBoxNumber',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  RegionPostalCode = 'regionPostalCode',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  StreetAddress = 'streetAddress',
  /** column name */
  TeamMessage = 'teamMessage',
  /** column name */
  Telephone = 'telephone',
  /** column name */
  TelephoneSms = 'telephoneSms',
  /** column name */
  TwilioAddressSid = 'twilioAddressSid',
  /** column name */
  TwilioBundleSid = 'twilioBundleSid',
  /** column name */
  ZohoOrganizationId = 'zohoOrganizationId'
}

/** select "bilikZoneAggregateBoolExpBool_andArgumentsColumns" columns of table "bilik_zone" */
export enum BilikZoneSelectColumnBilikZoneAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasDistrict = 'hasDistrict',
  /** column name */
  HasMultiplePostalCode = 'hasMultiplePostalCode'
}

/** select "bilikZoneAggregateBoolExpBool_orArgumentsColumns" columns of table "bilik_zone" */
export enum BilikZoneSelectColumnBilikZoneAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasDistrict = 'hasDistrict',
  /** column name */
  HasMultiplePostalCode = 'hasMultiplePostalCode'
}

/** input type for updating data in table "bilik_zone" */
export type BilikZoneSetInput = {
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['geometry']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  genericEmail?: Maybe<Scalars['String']>;
  guideName?: Maybe<Scalars['String']>;
  hasDistrict?: Maybe<Scalars['Boolean']>;
  hasMultiplePostalCode?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  mainCityCode?: Maybe<Scalars['String']>;
  mainCityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  postOfficeBoxNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  regionPostalCode?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  teamMessage?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  telephoneSms?: Maybe<Scalars['String']>;
  twilioAddressSid?: Maybe<Scalars['String']>;
  twilioBundleSid?: Maybe<Scalars['String']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type BilikZoneStddevFields = {
  __typename?: 'BilikZoneStddevFields';
  id?: Maybe<Scalars['Float']>;
  mainCityId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bilik_zone" */
export type BilikZoneStddevOrderBy = {
  id?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type BilikZoneStddevPopFields = {
  __typename?: 'BilikZoneStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  mainCityId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "bilik_zone" */
export type BilikZoneStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type BilikZoneStddevSampFields = {
  __typename?: 'BilikZoneStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  mainCityId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "bilik_zone" */
export type BilikZoneStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "bilik_zone" */
export type BilikZoneStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BilikZoneStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BilikZoneStreamCursorValueInput = {
  addressCountry?: Maybe<Scalars['String']>;
  addressLocality?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['geometry']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  genericEmail?: Maybe<Scalars['String']>;
  guideName?: Maybe<Scalars['String']>;
  hasDistrict?: Maybe<Scalars['Boolean']>;
  hasMultiplePostalCode?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  mainCityCode?: Maybe<Scalars['String']>;
  mainCityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  postOfficeBoxNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  regionPostalCode?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  teamMessage?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  telephoneSms?: Maybe<Scalars['String']>;
  twilioAddressSid?: Maybe<Scalars['String']>;
  twilioBundleSid?: Maybe<Scalars['String']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type BilikZoneSumFields = {
  __typename?: 'BilikZoneSumFields';
  id?: Maybe<Scalars['Int']>;
  mainCityId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bilik_zone" */
export type BilikZoneSumOrderBy = {
  id?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** columns and relationships of "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCount = {
  __typename?: 'BilikZoneTradeMainProCount';
  /** An object relationship */
  bilikZone?: Maybe<BilikZone>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  trade?: Maybe<Trade>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountAggregate = {
  __typename?: 'BilikZoneTradeMainProCountAggregate';
  aggregate?: Maybe<BilikZoneTradeMainProCountAggregateFields>;
  nodes: Array<BilikZoneTradeMainProCount>;
};

export type BilikZoneTradeMainProCountAggregateBoolExp = {
  count?: Maybe<BilikZoneTradeMainProCountAggregateBoolExpCount>;
};

export type BilikZoneTradeMainProCountAggregateBoolExpCount = {
  arguments?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<BilikZoneTradeMainProCountBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountAggregateFields = {
  __typename?: 'BilikZoneTradeMainProCountAggregateFields';
  avg?: Maybe<BilikZoneTradeMainProCountAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<BilikZoneTradeMainProCountMaxFields>;
  min?: Maybe<BilikZoneTradeMainProCountMinFields>;
  stddev?: Maybe<BilikZoneTradeMainProCountStddevFields>;
  stddevPop?: Maybe<BilikZoneTradeMainProCountStddevPopFields>;
  stddevSamp?: Maybe<BilikZoneTradeMainProCountStddevSampFields>;
  sum?: Maybe<BilikZoneTradeMainProCountSumFields>;
  varPop?: Maybe<BilikZoneTradeMainProCountVarPopFields>;
  varSamp?: Maybe<BilikZoneTradeMainProCountVarSampFields>;
  variance?: Maybe<BilikZoneTradeMainProCountVarianceFields>;
};


/** aggregate fields of "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountAggregateOrderBy = {
  avg?: Maybe<BilikZoneTradeMainProCountAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<BilikZoneTradeMainProCountMaxOrderBy>;
  min?: Maybe<BilikZoneTradeMainProCountMinOrderBy>;
  stddev?: Maybe<BilikZoneTradeMainProCountStddevOrderBy>;
  stddevPop?: Maybe<BilikZoneTradeMainProCountStddevPopOrderBy>;
  stddevSamp?: Maybe<BilikZoneTradeMainProCountStddevSampOrderBy>;
  sum?: Maybe<BilikZoneTradeMainProCountSumOrderBy>;
  varPop?: Maybe<BilikZoneTradeMainProCountVarPopOrderBy>;
  varSamp?: Maybe<BilikZoneTradeMainProCountVarSampOrderBy>;
  variance?: Maybe<BilikZoneTradeMainProCountVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountArrRelInsertInput = {
  data: Array<BilikZoneTradeMainProCountInsertInput>;
};

/** aggregate avg on columns */
export type BilikZoneTradeMainProCountAvgFields = {
  __typename?: 'BilikZoneTradeMainProCountAvgFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountAvgOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "bilik_zone_trade_main_pro_count". All fields are combined with a logical 'AND'.
 */
export type BilikZoneTradeMainProCountBoolExp = {
  _and?: Maybe<Array<BilikZoneTradeMainProCountBoolExp>>;
  _not?: Maybe<BilikZoneTradeMainProCountBoolExp>;
  _or?: Maybe<Array<BilikZoneTradeMainProCountBoolExp>>;
  bilikZone?: Maybe<BilikZoneBoolExp>;
  bilikZoneId?: Maybe<IntComparisonExp>;
  count?: Maybe<BigintComparisonExp>;
  trade?: Maybe<TradeBoolExp>;
  tradeId?: Maybe<IntComparisonExp>;
};

/** input type for inserting data into table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountInsertInput = {
  bilikZone?: Maybe<BilikZoneObjRelInsertInput>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  trade?: Maybe<TradeObjRelInsertInput>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type BilikZoneTradeMainProCountMaxFields = {
  __typename?: 'BilikZoneTradeMainProCountMaxFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountMaxOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BilikZoneTradeMainProCountMinFields = {
  __typename?: 'BilikZoneTradeMainProCountMinFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountMinOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "bilik_zone_trade_main_pro_count". */
export type BilikZoneTradeMainProCountOrderBy = {
  bilikZone?: Maybe<BilikZoneOrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  trade?: Maybe<TradeOrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** select columns of table "bilik_zone_trade_main_pro_count" */
export enum BilikZoneTradeMainProCountSelectColumn {
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  Count = 'count',
  /** column name */
  TradeId = 'tradeId'
}

/** aggregate stddev on columns */
export type BilikZoneTradeMainProCountStddevFields = {
  __typename?: 'BilikZoneTradeMainProCountStddevFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountStddevOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type BilikZoneTradeMainProCountStddevPopFields = {
  __typename?: 'BilikZoneTradeMainProCountStddevPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountStddevPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type BilikZoneTradeMainProCountStddevSampFields = {
  __typename?: 'BilikZoneTradeMainProCountStddevSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountStddevSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BilikZoneTradeMainProCountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BilikZoneTradeMainProCountStreamCursorValueInput = {
  bilikZoneId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type BilikZoneTradeMainProCountSumFields = {
  __typename?: 'BilikZoneTradeMainProCountSumFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountSumOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type BilikZoneTradeMainProCountVarianceFields = {
  __typename?: 'BilikZoneTradeMainProCountVarianceFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountVarianceOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type BilikZoneTradeMainProCountVarPopFields = {
  __typename?: 'BilikZoneTradeMainProCountVarPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountVarPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type BilikZoneTradeMainProCountVarSampFields = {
  __typename?: 'BilikZoneTradeMainProCountVarSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "bilik_zone_trade_main_pro_count" */
export type BilikZoneTradeMainProCountVarSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** update columns of table "bilik_zone" */
export enum BilikZoneUpdateColumn {
  /** column name */
  AddressCountry = 'addressCountry',
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  AddressRegion = 'addressRegion',
  /** column name */
  Area = 'area',
  /** column name */
  CallTrackingTarget = 'callTrackingTarget',
  /** column name */
  GenericEmail = 'genericEmail',
  /** column name */
  GuideName = 'guideName',
  /** column name */
  HasDistrict = 'hasDistrict',
  /** column name */
  HasMultiplePostalCode = 'hasMultiplePostalCode',
  /** column name */
  Id = 'id',
  /** column name */
  MainCityCode = 'mainCityCode',
  /** column name */
  MainCityId = 'mainCityId',
  /** column name */
  Name = 'name',
  /** column name */
  PostOfficeBoxNumber = 'postOfficeBoxNumber',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  RegionPostalCode = 'regionPostalCode',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  StreetAddress = 'streetAddress',
  /** column name */
  TeamMessage = 'teamMessage',
  /** column name */
  Telephone = 'telephone',
  /** column name */
  TelephoneSms = 'telephoneSms',
  /** column name */
  TwilioAddressSid = 'twilioAddressSid',
  /** column name */
  TwilioBundleSid = 'twilioBundleSid',
  /** column name */
  ZohoOrganizationId = 'zohoOrganizationId'
}

export type BilikZoneUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<BilikZoneAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: Maybe<BilikZoneDeleteAtPathInput>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _deleteElem?: Maybe<BilikZoneDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: Maybe<BilikZoneDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<BilikZoneIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<BilikZonePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<BilikZoneSetInput>;
  /** filter the rows which have to be updated */
  where: BilikZoneBoolExp;
};

/** aggregate variance on columns */
export type BilikZoneVarianceFields = {
  __typename?: 'BilikZoneVarianceFields';
  id?: Maybe<Scalars['Float']>;
  mainCityId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bilik_zone" */
export type BilikZoneVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type BilikZoneVarPopFields = {
  __typename?: 'BilikZoneVarPopFields';
  id?: Maybe<Scalars['Float']>;
  mainCityId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "bilik_zone" */
export type BilikZoneVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type BilikZoneVarSampFields = {
  __typename?: 'BilikZoneVarSampFields';
  id?: Maybe<Scalars['Float']>;
  mainCityId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "bilik_zone" */
export type BilikZoneVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  mainCityId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "certification" */
export type Certification = {
  __typename?: 'Certification';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  proDescription?: Maybe<Scalars['String']>;
  /** An array relationship */
  proPresentations: Array<ProCertification>;
  /** An aggregate relationship */
  proPresentationsAggregate: ProCertificationAggregate;
  slug: Scalars['String'];
};


/** columns and relationships of "certification" */
export type CertificationProPresentationsArgs = {
  distinctOn?: Maybe<Array<ProCertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProCertificationOrderBy>>;
  where?: Maybe<ProCertificationBoolExp>;
};


/** columns and relationships of "certification" */
export type CertificationProPresentationsAggregateArgs = {
  distinctOn?: Maybe<Array<ProCertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProCertificationOrderBy>>;
  where?: Maybe<ProCertificationBoolExp>;
};

/** aggregated selection of "certification" */
export type CertificationAggregate = {
  __typename?: 'CertificationAggregate';
  aggregate?: Maybe<CertificationAggregateFields>;
  nodes: Array<Certification>;
};

/** aggregate fields of "certification" */
export type CertificationAggregateFields = {
  __typename?: 'CertificationAggregateFields';
  avg?: Maybe<CertificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CertificationMaxFields>;
  min?: Maybe<CertificationMinFields>;
  stddev?: Maybe<CertificationStddevFields>;
  stddevPop?: Maybe<CertificationStddevPopFields>;
  stddevSamp?: Maybe<CertificationStddevSampFields>;
  sum?: Maybe<CertificationSumFields>;
  varPop?: Maybe<CertificationVarPopFields>;
  varSamp?: Maybe<CertificationVarSampFields>;
  variance?: Maybe<CertificationVarianceFields>;
};


/** aggregate fields of "certification" */
export type CertificationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CertificationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CertificationAvgFields = {
  __typename?: 'CertificationAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "certification". All fields are combined with a logical 'AND'. */
export type CertificationBoolExp = {
  _and?: Maybe<Array<CertificationBoolExp>>;
  _not?: Maybe<CertificationBoolExp>;
  _or?: Maybe<Array<CertificationBoolExp>>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  proDescription?: Maybe<StringComparisonExp>;
  proPresentations?: Maybe<ProCertificationBoolExp>;
  proPresentationsAggregate?: Maybe<ProCertificationAggregateBoolExp>;
  slug?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "certification" */
export enum CertificationConstraint {
  /** unique or primary key constraint on columns "id" */
  CertificationPkey = 'certification_pkey'
}

/** input type for incrementing numeric columns in table "certification" */
export type CertificationIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "certification" */
export type CertificationInsertInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proDescription?: Maybe<Scalars['String']>;
  proPresentations?: Maybe<ProCertificationArrRelInsertInput>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CertificationMaxFields = {
  __typename?: 'CertificationMaxFields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CertificationMinFields = {
  __typename?: 'CertificationMinFields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "certification" */
export type CertificationMutationResponse = {
  __typename?: 'CertificationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Certification>;
};

/** input type for inserting object relation for remote table "certification" */
export type CertificationObjRelInsertInput = {
  data: CertificationInsertInput;
  /** upsert condition */
  onConflict?: Maybe<CertificationOnConflict>;
};

/** on_conflict condition type for table "certification" */
export type CertificationOnConflict = {
  constraint: CertificationConstraint;
  updateColumns: Array<CertificationUpdateColumn>;
  where?: Maybe<CertificationBoolExp>;
};

/** Ordering options when selecting data from "certification". */
export type CertificationOrderBy = {
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  proDescription?: Maybe<OrderBy>;
  proPresentationsAggregate?: Maybe<ProCertificationAggregateOrderBy>;
  slug?: Maybe<OrderBy>;
};

/** primary key columns input for table: certification */
export type CertificationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "certification" */
export enum CertificationSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProDescription = 'proDescription',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "certification" */
export type CertificationSetInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CertificationStddevFields = {
  __typename?: 'CertificationStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type CertificationStddevPopFields = {
  __typename?: 'CertificationStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type CertificationStddevSampFields = {
  __typename?: 'CertificationStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "certification" */
export type CertificationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CertificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CertificationStreamCursorValueInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type CertificationSumFields = {
  __typename?: 'CertificationSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "certification" */
export enum CertificationUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProDescription = 'proDescription',
  /** column name */
  Slug = 'slug'
}

export type CertificationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<CertificationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CertificationSetInput>;
  /** filter the rows which have to be updated */
  where: CertificationBoolExp;
};

/** aggregate variance on columns */
export type CertificationVarianceFields = {
  __typename?: 'CertificationVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type CertificationVarPopFields = {
  __typename?: 'CertificationVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type CertificationVarSampFields = {
  __typename?: 'CertificationVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "city" */
export type City = {
  __typename?: 'City';
  area?: Maybe<Scalars['geometry']>;
  /** An object relationship */
  bilikZone?: Maybe<BilikZone>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  cityProViews: Array<CityProView>;
  /** An aggregate relationship */
  cityProViewsAggregate: CityProViewAggregate;
  geoNameId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  population: Scalars['Int'];
  /** An array relationship */
  proViewsByDistance: Array<ProViewCityDistance>;
  /** An aggregate relationship */
  proViewsByDistanceAggregate: ProViewCityDistanceAggregate;
  /** An array relationship */
  sitemap: Array<SitemapCity>;
  /** An aggregate relationship */
  sitemapAggregate: SitemapCityAggregate;
  slug: Scalars['String'];
};


/** columns and relationships of "city" */
export type CityCityProViewsArgs = {
  distinctOn?: Maybe<Array<CityProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityProViewOrderBy>>;
  where?: Maybe<CityProViewBoolExp>;
};


/** columns and relationships of "city" */
export type CityCityProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<CityProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityProViewOrderBy>>;
  where?: Maybe<CityProViewBoolExp>;
};


/** columns and relationships of "city" */
export type CityProViewsByDistanceArgs = {
  distinctOn?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewCityDistanceOrderBy>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


/** columns and relationships of "city" */
export type CityProViewsByDistanceAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewCityDistanceOrderBy>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


/** columns and relationships of "city" */
export type CitySitemapArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


/** columns and relationships of "city" */
export type CitySitemapAggregateArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};

/** aggregated selection of "city" */
export type CityAggregate = {
  __typename?: 'CityAggregate';
  aggregate?: Maybe<CityAggregateFields>;
  nodes: Array<City>;
};

export type CityAggregateBoolExp = {
  count?: Maybe<CityAggregateBoolExpCount>;
};

export type CityAggregateBoolExpCount = {
  arguments?: Maybe<Array<CitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CityBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "city" */
export type CityAggregateFields = {
  __typename?: 'CityAggregateFields';
  avg?: Maybe<CityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CityMaxFields>;
  min?: Maybe<CityMinFields>;
  stddev?: Maybe<CityStddevFields>;
  stddevPop?: Maybe<CityStddevPopFields>;
  stddevSamp?: Maybe<CityStddevSampFields>;
  sum?: Maybe<CitySumFields>;
  varPop?: Maybe<CityVarPopFields>;
  varSamp?: Maybe<CityVarSampFields>;
  variance?: Maybe<CityVarianceFields>;
};


/** aggregate fields of "city" */
export type CityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "city" */
export type CityAggregateOrderBy = {
  avg?: Maybe<CityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<CityMaxOrderBy>;
  min?: Maybe<CityMinOrderBy>;
  stddev?: Maybe<CityStddevOrderBy>;
  stddevPop?: Maybe<CityStddevPopOrderBy>;
  stddevSamp?: Maybe<CityStddevSampOrderBy>;
  sum?: Maybe<CitySumOrderBy>;
  varPop?: Maybe<CityVarPopOrderBy>;
  varSamp?: Maybe<CityVarSampOrderBy>;
  variance?: Maybe<CityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "city" */
export type CityArrRelInsertInput = {
  data: Array<CityInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<CityOnConflict>;
};

/** aggregate avg on columns */
export type CityAvgFields = {
  __typename?: 'CityAvgFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  geoNameId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "city" */
export type CityAvgOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "city". All fields are combined with a logical 'AND'. */
export type CityBoolExp = {
  _and?: Maybe<Array<CityBoolExp>>;
  _not?: Maybe<CityBoolExp>;
  _or?: Maybe<Array<CityBoolExp>>;
  area?: Maybe<GeometryComparisonExp>;
  bilikZone?: Maybe<BilikZoneBoolExp>;
  bilikZoneId?: Maybe<IntComparisonExp>;
  cityProViews?: Maybe<CityProViewBoolExp>;
  cityProViewsAggregate?: Maybe<CityProViewAggregateBoolExp>;
  geoNameId?: Maybe<IntComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  population?: Maybe<IntComparisonExp>;
  proViewsByDistance?: Maybe<ProViewCityDistanceBoolExp>;
  proViewsByDistanceAggregate?: Maybe<ProViewCityDistanceAggregateBoolExp>;
  sitemap?: Maybe<SitemapCityBoolExp>;
  sitemapAggregate?: Maybe<SitemapCityAggregateBoolExp>;
  slug?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "city" */
export enum CityConstraint {
  /** unique or primary key constraint on columns "slug" */
  CitySlugUniqKey = 'city_slug_uniq_key',
  /** unique or primary key constraint on columns "id" */
  Idx_22870Primary = 'idx_22870_primary',
  /** unique or primary key constraint on columns "slug" */
  Idx_22870Uniq_2d5b0234989d9b62 = 'idx_22870_uniq_2d5b0234989d9b62'
}

/** input type for incrementing numeric columns in table "city" */
export type CityIncInput = {
  bilikZoneId?: Maybe<Scalars['Int']>;
  geoNameId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  population?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "city" */
export type CityInsertInput = {
  area?: Maybe<Scalars['geometry']>;
  bilikZone?: Maybe<BilikZoneObjRelInsertInput>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  cityProViews?: Maybe<CityProViewArrRelInsertInput>;
  geoNameId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
  proViewsByDistance?: Maybe<ProViewCityDistanceArrRelInsertInput>;
  sitemap?: Maybe<SitemapCityArrRelInsertInput>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CityMaxFields = {
  __typename?: 'CityMaxFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  geoNameId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "city" */
export type CityMaxOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CityMinFields = {
  __typename?: 'CityMinFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  geoNameId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "city" */
export type CityMinOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** response of any mutation on the table "city" */
export type CityMutationResponse = {
  __typename?: 'CityMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<City>;
};

/** input type for inserting object relation for remote table "city" */
export type CityObjRelInsertInput = {
  data: CityInsertInput;
  /** upsert condition */
  onConflict?: Maybe<CityOnConflict>;
};

/** on_conflict condition type for table "city" */
export type CityOnConflict = {
  constraint: CityConstraint;
  updateColumns: Array<CityUpdateColumn>;
  where?: Maybe<CityBoolExp>;
};

/** Ordering options when selecting data from "city". */
export type CityOrderBy = {
  area?: Maybe<OrderBy>;
  bilikZone?: Maybe<BilikZoneOrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  cityProViewsAggregate?: Maybe<CityProViewAggregateOrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
  proViewsByDistanceAggregate?: Maybe<ProViewCityDistanceAggregateOrderBy>;
  sitemapAggregate?: Maybe<SitemapCityAggregateOrderBy>;
  slug?: Maybe<OrderBy>;
};

/** primary key columns input for table: city */
export type CityPkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "city_pro_view" */
export type CityProView = {
  __typename?: 'CityProView';
  /** An object relationship */
  city: City;
  cityId: Scalars['Int'];
  distance?: Maybe<Scalars['Int']>;
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
};

/** aggregated selection of "city_pro_view" */
export type CityProViewAggregate = {
  __typename?: 'CityProViewAggregate';
  aggregate?: Maybe<CityProViewAggregateFields>;
  nodes: Array<CityProView>;
};

export type CityProViewAggregateBoolExp = {
  count?: Maybe<CityProViewAggregateBoolExpCount>;
};

export type CityProViewAggregateBoolExpCount = {
  arguments?: Maybe<Array<CityProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<CityProViewBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "city_pro_view" */
export type CityProViewAggregateFields = {
  __typename?: 'CityProViewAggregateFields';
  avg?: Maybe<CityProViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CityProViewMaxFields>;
  min?: Maybe<CityProViewMinFields>;
  stddev?: Maybe<CityProViewStddevFields>;
  stddevPop?: Maybe<CityProViewStddevPopFields>;
  stddevSamp?: Maybe<CityProViewStddevSampFields>;
  sum?: Maybe<CityProViewSumFields>;
  varPop?: Maybe<CityProViewVarPopFields>;
  varSamp?: Maybe<CityProViewVarSampFields>;
  variance?: Maybe<CityProViewVarianceFields>;
};


/** aggregate fields of "city_pro_view" */
export type CityProViewAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CityProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "city_pro_view" */
export type CityProViewAggregateOrderBy = {
  avg?: Maybe<CityProViewAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<CityProViewMaxOrderBy>;
  min?: Maybe<CityProViewMinOrderBy>;
  stddev?: Maybe<CityProViewStddevOrderBy>;
  stddevPop?: Maybe<CityProViewStddevPopOrderBy>;
  stddevSamp?: Maybe<CityProViewStddevSampOrderBy>;
  sum?: Maybe<CityProViewSumOrderBy>;
  varPop?: Maybe<CityProViewVarPopOrderBy>;
  varSamp?: Maybe<CityProViewVarSampOrderBy>;
  variance?: Maybe<CityProViewVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "city_pro_view" */
export type CityProViewArrRelInsertInput = {
  data: Array<CityProViewInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<CityProViewOnConflict>;
};

/** aggregate avg on columns */
export type CityProViewAvgFields = {
  __typename?: 'CityProViewAvgFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "city_pro_view" */
export type CityProViewAvgOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "city_pro_view". All fields are combined with a logical 'AND'. */
export type CityProViewBoolExp = {
  _and?: Maybe<Array<CityProViewBoolExp>>;
  _not?: Maybe<CityProViewBoolExp>;
  _or?: Maybe<Array<CityProViewBoolExp>>;
  city?: Maybe<CityBoolExp>;
  cityId?: Maybe<IntComparisonExp>;
  distance?: Maybe<IntComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "city_pro_view" */
export enum CityProViewConstraint {
  /** unique or primary key constraint on columns "pro_view_id", "city_id" */
  Idx_22874Primary = 'idx_22874_primary'
}

/** input type for incrementing numeric columns in table "city_pro_view" */
export type CityProViewIncInput = {
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "city_pro_view" */
export type CityProViewInsertInput = {
  city?: Maybe<CityObjRelInsertInput>;
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type CityProViewMaxFields = {
  __typename?: 'CityProViewMaxFields';
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "city_pro_view" */
export type CityProViewMaxOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CityProViewMinFields = {
  __typename?: 'CityProViewMinFields';
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "city_pro_view" */
export type CityProViewMinOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "city_pro_view" */
export type CityProViewMutationResponse = {
  __typename?: 'CityProViewMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CityProView>;
};

/** on_conflict condition type for table "city_pro_view" */
export type CityProViewOnConflict = {
  constraint: CityProViewConstraint;
  updateColumns: Array<CityProViewUpdateColumn>;
  where?: Maybe<CityProViewBoolExp>;
};

/** Ordering options when selecting data from "city_pro_view". */
export type CityProViewOrderBy = {
  city?: Maybe<CityOrderBy>;
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** primary key columns input for table: city_pro_view */
export type CityProViewPkColumnsInput = {
  cityId: Scalars['Int'];
  proViewId: Scalars['Int'];
};

/** select columns of table "city_pro_view" */
export enum CityProViewSelectColumn {
  /** column name */
  CityId = 'cityId',
  /** column name */
  Distance = 'distance',
  /** column name */
  ProViewId = 'proViewId'
}

/** input type for updating data in table "city_pro_view" */
export type CityProViewSetInput = {
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type CityProViewStddevFields = {
  __typename?: 'CityProViewStddevFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "city_pro_view" */
export type CityProViewStddevOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CityProViewStddevPopFields = {
  __typename?: 'CityProViewStddevPopFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "city_pro_view" */
export type CityProViewStddevPopOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CityProViewStddevSampFields = {
  __typename?: 'CityProViewStddevSampFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "city_pro_view" */
export type CityProViewStddevSampOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "city_pro_view" */
export type CityProViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CityProViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CityProViewStreamCursorValueInput = {
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CityProViewSumFields = {
  __typename?: 'CityProViewSumFields';
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "city_pro_view" */
export type CityProViewSumOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "city_pro_view" */
export enum CityProViewUpdateColumn {
  /** column name */
  CityId = 'cityId',
  /** column name */
  Distance = 'distance',
  /** column name */
  ProViewId = 'proViewId'
}

export type CityProViewUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<CityProViewIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CityProViewSetInput>;
  /** filter the rows which have to be updated */
  where: CityProViewBoolExp;
};

/** aggregate variance on columns */
export type CityProViewVarianceFields = {
  __typename?: 'CityProViewVarianceFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "city_pro_view" */
export type CityProViewVarianceOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CityProViewVarPopFields = {
  __typename?: 'CityProViewVarPopFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "city_pro_view" */
export type CityProViewVarPopOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CityProViewVarSampFields = {
  __typename?: 'CityProViewVarSampFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "city_pro_view" */
export type CityProViewVarSampOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** select columns of table "city" */
export enum CitySelectColumn {
  /** column name */
  Area = 'area',
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  GeoNameId = 'geoNameId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Population = 'population',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "city" */
export type CitySetInput = {
  area?: Maybe<Scalars['geometry']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  geoNameId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CityStddevFields = {
  __typename?: 'CityStddevFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  geoNameId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "city" */
export type CityStddevOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type CityStddevPopFields = {
  __typename?: 'CityStddevPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  geoNameId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "city" */
export type CityStddevPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type CityStddevSampFields = {
  __typename?: 'CityStddevSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  geoNameId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "city" */
export type CityStddevSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "city" */
export type CityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CityStreamCursorValueInput = {
  area?: Maybe<Scalars['geometry']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  geoNameId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type CitySumFields = {
  __typename?: 'CitySumFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  geoNameId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  population?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "city" */
export type CitySumOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
};

/** update columns of table "city" */
export enum CityUpdateColumn {
  /** column name */
  Area = 'area',
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  GeoNameId = 'geoNameId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Population = 'population',
  /** column name */
  Slug = 'slug'
}

export type CityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<CityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<CitySetInput>;
  /** filter the rows which have to be updated */
  where: CityBoolExp;
};

/** aggregate variance on columns */
export type CityVarianceFields = {
  __typename?: 'CityVarianceFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  geoNameId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "city" */
export type CityVarianceOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type CityVarPopFields = {
  __typename?: 'CityVarPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  geoNameId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "city" */
export type CityVarPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type CityVarSampFields = {
  __typename?: 'CityVarSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  geoNameId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "city" */
export type CityVarSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  geoNameId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  population?: Maybe<OrderBy>;
};

/** columns and relationships of "count_pro_review_by_status" */
export type CountProReviewByStatus = {
  __typename?: 'CountProReviewByStatus';
  count?: Maybe<Scalars['bigint']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "count_pro_review_by_status" */
export type CountProReviewByStatusAggregate = {
  __typename?: 'CountProReviewByStatusAggregate';
  aggregate?: Maybe<CountProReviewByStatusAggregateFields>;
  nodes: Array<CountProReviewByStatus>;
};

/** aggregate fields of "count_pro_review_by_status" */
export type CountProReviewByStatusAggregateFields = {
  __typename?: 'CountProReviewByStatusAggregateFields';
  avg?: Maybe<CountProReviewByStatusAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CountProReviewByStatusMaxFields>;
  min?: Maybe<CountProReviewByStatusMinFields>;
  stddev?: Maybe<CountProReviewByStatusStddevFields>;
  stddevPop?: Maybe<CountProReviewByStatusStddevPopFields>;
  stddevSamp?: Maybe<CountProReviewByStatusStddevSampFields>;
  sum?: Maybe<CountProReviewByStatusSumFields>;
  varPop?: Maybe<CountProReviewByStatusVarPopFields>;
  varSamp?: Maybe<CountProReviewByStatusVarSampFields>;
  variance?: Maybe<CountProReviewByStatusVarianceFields>;
};


/** aggregate fields of "count_pro_review_by_status" */
export type CountProReviewByStatusAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CountProReviewByStatusSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CountProReviewByStatusAvgFields = {
  __typename?: 'CountProReviewByStatusAvgFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_pro_review_by_status". All fields are combined with a logical 'AND'. */
export type CountProReviewByStatusBoolExp = {
  _and?: Maybe<Array<CountProReviewByStatusBoolExp>>;
  _not?: Maybe<CountProReviewByStatusBoolExp>;
  _or?: Maybe<Array<CountProReviewByStatusBoolExp>>;
  count?: Maybe<BigintComparisonExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
  status?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type CountProReviewByStatusMaxFields = {
  __typename?: 'CountProReviewByStatusMaxFields';
  count?: Maybe<Scalars['bigint']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CountProReviewByStatusMinFields = {
  __typename?: 'CountProReviewByStatusMinFields';
  count?: Maybe<Scalars['bigint']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "count_pro_review_by_status". */
export type CountProReviewByStatusOrderBy = {
  count?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** select columns of table "count_pro_review_by_status" */
export enum CountProReviewByStatusSelectColumn {
  /** column name */
  Count = 'count',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  Status = 'status'
}

/** aggregate stddev on columns */
export type CountProReviewByStatusStddevFields = {
  __typename?: 'CountProReviewByStatusStddevFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type CountProReviewByStatusStddevPopFields = {
  __typename?: 'CountProReviewByStatusStddevPopFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type CountProReviewByStatusStddevSampFields = {
  __typename?: 'CountProReviewByStatusStddevSampFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "count_pro_review_by_status" */
export type CountProReviewByStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CountProReviewByStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CountProReviewByStatusStreamCursorValueInput = {
  count?: Maybe<Scalars['bigint']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type CountProReviewByStatusSumFields = {
  __typename?: 'CountProReviewByStatusSumFields';
  count?: Maybe<Scalars['bigint']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type CountProReviewByStatusVarianceFields = {
  __typename?: 'CountProReviewByStatusVarianceFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type CountProReviewByStatusVarPopFields = {
  __typename?: 'CountProReviewByStatusVarPopFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type CountProReviewByStatusVarSampFields = {
  __typename?: 'CountProReviewByStatusVarSampFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_pro_review_invitations_by_month_by_pro_presentation" */
export type CountProReviewInvitationsByMonthByProPresentation = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentation';
  count?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  proPresentation?: Maybe<ProPresentation>;
  proPresentationId?: Maybe<Scalars['Int']>;
  replyProReviewCount?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "count_pro_review_invitations_by_month_by_pro_presentation" */
export type CountProReviewInvitationsByMonthByProPresentationAggregate = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationAggregate';
  aggregate?: Maybe<CountProReviewInvitationsByMonthByProPresentationAggregateFields>;
  nodes: Array<CountProReviewInvitationsByMonthByProPresentation>;
};

/** aggregate fields of "count_pro_review_invitations_by_month_by_pro_presentation" */
export type CountProReviewInvitationsByMonthByProPresentationAggregateFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationAggregateFields';
  avg?: Maybe<CountProReviewInvitationsByMonthByProPresentationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CountProReviewInvitationsByMonthByProPresentationMaxFields>;
  min?: Maybe<CountProReviewInvitationsByMonthByProPresentationMinFields>;
  stddev?: Maybe<CountProReviewInvitationsByMonthByProPresentationStddevFields>;
  stddevPop?: Maybe<CountProReviewInvitationsByMonthByProPresentationStddevPopFields>;
  stddevSamp?: Maybe<CountProReviewInvitationsByMonthByProPresentationStddevSampFields>;
  sum?: Maybe<CountProReviewInvitationsByMonthByProPresentationSumFields>;
  varPop?: Maybe<CountProReviewInvitationsByMonthByProPresentationVarPopFields>;
  varSamp?: Maybe<CountProReviewInvitationsByMonthByProPresentationVarSampFields>;
  variance?: Maybe<CountProReviewInvitationsByMonthByProPresentationVarianceFields>;
};


/** aggregate fields of "count_pro_review_invitations_by_month_by_pro_presentation" */
export type CountProReviewInvitationsByMonthByProPresentationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CountProReviewInvitationsByMonthByProPresentationAvgFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationAvgFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  replyProReviewCount?: Maybe<Scalars['Float']>;
};

/**
 * Boolean expression to filter rows from the table
 * "count_pro_review_invitations_by_month_by_pro_presentation". All fields are
 * combined with a logical 'AND'.
 */
export type CountProReviewInvitationsByMonthByProPresentationBoolExp = {
  _and?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationBoolExp>>;
  _not?: Maybe<CountProReviewInvitationsByMonthByProPresentationBoolExp>;
  _or?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationBoolExp>>;
  count?: Maybe<BigintComparisonExp>;
  month?: Maybe<TimestamptzComparisonExp>;
  proPresentation?: Maybe<ProPresentationBoolExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
  replyProReviewCount?: Maybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type CountProReviewInvitationsByMonthByProPresentationMaxFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationMaxFields';
  count?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['timestamptz']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  replyProReviewCount?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type CountProReviewInvitationsByMonthByProPresentationMinFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationMinFields';
  count?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['timestamptz']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  replyProReviewCount?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "count_pro_review_invitations_by_month_by_pro_presentation". */
export type CountProReviewInvitationsByMonthByProPresentationOrderBy = {
  count?: Maybe<OrderBy>;
  month?: Maybe<OrderBy>;
  proPresentation?: Maybe<ProPresentationOrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  replyProReviewCount?: Maybe<OrderBy>;
};

/** select columns of table "count_pro_review_invitations_by_month_by_pro_presentation" */
export enum CountProReviewInvitationsByMonthByProPresentationSelectColumn {
  /** column name */
  Count = 'count',
  /** column name */
  Month = 'month',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  ReplyProReviewCount = 'replyProReviewCount'
}

/** aggregate stddev on columns */
export type CountProReviewInvitationsByMonthByProPresentationStddevFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationStddevFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  replyProReviewCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type CountProReviewInvitationsByMonthByProPresentationStddevPopFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationStddevPopFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  replyProReviewCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type CountProReviewInvitationsByMonthByProPresentationStddevSampFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationStddevSampFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  replyProReviewCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "count_pro_review_invitations_by_month_by_pro_presentation" */
export type CountProReviewInvitationsByMonthByProPresentationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CountProReviewInvitationsByMonthByProPresentationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CountProReviewInvitationsByMonthByProPresentationStreamCursorValueInput = {
  count?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['timestamptz']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  replyProReviewCount?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type CountProReviewInvitationsByMonthByProPresentationSumFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationSumFields';
  count?: Maybe<Scalars['bigint']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  replyProReviewCount?: Maybe<Scalars['bigint']>;
};

/** aggregate variance on columns */
export type CountProReviewInvitationsByMonthByProPresentationVarianceFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationVarianceFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  replyProReviewCount?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type CountProReviewInvitationsByMonthByProPresentationVarPopFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationVarPopFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  replyProReviewCount?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type CountProReviewInvitationsByMonthByProPresentationVarSampFields = {
  __typename?: 'CountProReviewInvitationsByMonthByProPresentationVarSampFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  replyProReviewCount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
export type CountProReviewsWithStatusOkByMonthByProPresentation = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentation';
  count?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  proPresentation?: Maybe<ProPresentation>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
export type CountProReviewsWithStatusOkByMonthByProPresentationAggregate = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationAggregate';
  aggregate?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationAggregateFields>;
  nodes: Array<CountProReviewsWithStatusOkByMonthByProPresentation>;
};

/** aggregate fields of "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
export type CountProReviewsWithStatusOkByMonthByProPresentationAggregateFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationAggregateFields';
  avg?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationMaxFields>;
  min?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationMinFields>;
  stddev?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationStddevFields>;
  stddevPop?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationStddevPopFields>;
  stddevSamp?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationStddevSampFields>;
  sum?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationSumFields>;
  varPop?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationVarPopFields>;
  varSamp?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationVarSampFields>;
  variance?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationVarianceFields>;
};


/** aggregate fields of "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
export type CountProReviewsWithStatusOkByMonthByProPresentationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationAvgFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationAvgFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/**
 * Boolean expression to filter rows from the table
 * "count_pro_reviews_with_status_ok_by_month_by_pro_presentation". All fields are
 * combined with a logical 'AND'.
 */
export type CountProReviewsWithStatusOkByMonthByProPresentationBoolExp = {
  _and?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationBoolExp>>;
  _not?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationBoolExp>;
  _or?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationBoolExp>>;
  count?: Maybe<BigintComparisonExp>;
  month?: Maybe<TimestamptzComparisonExp>;
  proPresentation?: Maybe<ProPresentationBoolExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationMaxFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationMaxFields';
  count?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['timestamptz']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationMinFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationMinFields';
  count?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['timestamptz']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "count_pro_reviews_with_status_ok_by_month_by_pro_presentation". */
export type CountProReviewsWithStatusOkByMonthByProPresentationOrderBy = {
  count?: Maybe<OrderBy>;
  month?: Maybe<OrderBy>;
  proPresentation?: Maybe<ProPresentationOrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** select columns of table "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
export enum CountProReviewsWithStatusOkByMonthByProPresentationSelectColumn {
  /** column name */
  Count = 'count',
  /** column name */
  Month = 'month',
  /** column name */
  ProPresentationId = 'proPresentationId'
}

/** aggregate stddev on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationStddevFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationStddevFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationStddevPopFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationStddevPopFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationStddevSampFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationStddevSampFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
export type CountProReviewsWithStatusOkByMonthByProPresentationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CountProReviewsWithStatusOkByMonthByProPresentationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CountProReviewsWithStatusOkByMonthByProPresentationStreamCursorValueInput = {
  count?: Maybe<Scalars['bigint']>;
  month?: Maybe<Scalars['timestamptz']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationSumFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationSumFields';
  count?: Maybe<Scalars['bigint']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationVarianceFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationVarianceFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationVarPopFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationVarPopFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type CountProReviewsWithStatusOkByMonthByProPresentationVarSampFields = {
  __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentationVarSampFields';
  count?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "device" */
export type Device = {
  __typename?: 'Device';
  /** An array relationship */
  accounts: Array<AccountDevice>;
  /** An aggregate relationship */
  accountsAggregate: AccountDeviceAggregate;
  fcmToken: Scalars['String'];
  fcmTokenDate: Scalars['timestamptz'];
  id: Scalars['Int'];
  info?: Maybe<Scalars['String']>;
  platform: Scalars['String'];
};


/** columns and relationships of "device" */
export type DeviceAccountsArgs = {
  distinctOn?: Maybe<Array<AccountDeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountDeviceOrderBy>>;
  where?: Maybe<AccountDeviceBoolExp>;
};


/** columns and relationships of "device" */
export type DeviceAccountsAggregateArgs = {
  distinctOn?: Maybe<Array<AccountDeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountDeviceOrderBy>>;
  where?: Maybe<AccountDeviceBoolExp>;
};

/** aggregated selection of "device" */
export type DeviceAggregate = {
  __typename?: 'DeviceAggregate';
  aggregate?: Maybe<DeviceAggregateFields>;
  nodes: Array<Device>;
};

/** aggregate fields of "device" */
export type DeviceAggregateFields = {
  __typename?: 'DeviceAggregateFields';
  avg?: Maybe<DeviceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DeviceMaxFields>;
  min?: Maybe<DeviceMinFields>;
  stddev?: Maybe<DeviceStddevFields>;
  stddevPop?: Maybe<DeviceStddevPopFields>;
  stddevSamp?: Maybe<DeviceStddevSampFields>;
  sum?: Maybe<DeviceSumFields>;
  varPop?: Maybe<DeviceVarPopFields>;
  varSamp?: Maybe<DeviceVarSampFields>;
  variance?: Maybe<DeviceVarianceFields>;
};


/** aggregate fields of "device" */
export type DeviceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<DeviceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DeviceAvgFields = {
  __typename?: 'DeviceAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "device". All fields are combined with a logical 'AND'. */
export type DeviceBoolExp = {
  _and?: Maybe<Array<DeviceBoolExp>>;
  _not?: Maybe<DeviceBoolExp>;
  _or?: Maybe<Array<DeviceBoolExp>>;
  accounts?: Maybe<AccountDeviceBoolExp>;
  accountsAggregate?: Maybe<AccountDeviceAggregateBoolExp>;
  fcmToken?: Maybe<StringComparisonExp>;
  fcmTokenDate?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  info?: Maybe<StringComparisonExp>;
  platform?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "device" */
export enum DeviceConstraint {
  /** unique or primary key constraint on columns "id" */
  DevicePkey = 'device_pkey',
  /** unique or primary key constraint on columns "fcm_token" */
  Uniq_92fb68e19b88af9 = 'uniq_92fb68e19b88af9'
}

/** input type for incrementing numeric columns in table "device" */
export type DeviceIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "device" */
export type DeviceInsertInput = {
  accounts?: Maybe<AccountDeviceArrRelInsertInput>;
  fcmToken?: Maybe<Scalars['String']>;
  fcmTokenDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DeviceMaxFields = {
  __typename?: 'DeviceMaxFields';
  fcmToken?: Maybe<Scalars['String']>;
  fcmTokenDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DeviceMinFields = {
  __typename?: 'DeviceMinFields';
  fcmToken?: Maybe<Scalars['String']>;
  fcmTokenDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "device" */
export type DeviceMutationResponse = {
  __typename?: 'DeviceMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Device>;
};

/** input type for inserting object relation for remote table "device" */
export type DeviceObjRelInsertInput = {
  data: DeviceInsertInput;
  /** upsert condition */
  onConflict?: Maybe<DeviceOnConflict>;
};

/** on_conflict condition type for table "device" */
export type DeviceOnConflict = {
  constraint: DeviceConstraint;
  updateColumns: Array<DeviceUpdateColumn>;
  where?: Maybe<DeviceBoolExp>;
};

/** Ordering options when selecting data from "device". */
export type DeviceOrderBy = {
  accountsAggregate?: Maybe<AccountDeviceAggregateOrderBy>;
  fcmToken?: Maybe<OrderBy>;
  fcmTokenDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  info?: Maybe<OrderBy>;
  platform?: Maybe<OrderBy>;
};

/** primary key columns input for table: device */
export type DevicePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "device" */
export enum DeviceSelectColumn {
  /** column name */
  FcmToken = 'fcmToken',
  /** column name */
  FcmTokenDate = 'fcmTokenDate',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Platform = 'platform'
}

/** input type for updating data in table "device" */
export type DeviceSetInput = {
  fcmToken?: Maybe<Scalars['String']>;
  fcmTokenDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DeviceStddevFields = {
  __typename?: 'DeviceStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DeviceStddevPopFields = {
  __typename?: 'DeviceStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DeviceStddevSampFields = {
  __typename?: 'DeviceStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "device" */
export type DeviceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DeviceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DeviceStreamCursorValueInput = {
  fcmToken?: Maybe<Scalars['String']>;
  fcmTokenDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  info?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DeviceSumFields = {
  __typename?: 'DeviceSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "device" */
export enum DeviceUpdateColumn {
  /** column name */
  FcmToken = 'fcmToken',
  /** column name */
  FcmTokenDate = 'fcmTokenDate',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Platform = 'platform'
}

export type DeviceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<DeviceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<DeviceSetInput>;
  /** filter the rows which have to be updated */
  where: DeviceBoolExp;
};

/** aggregate variance on columns */
export type DeviceVarianceFields = {
  __typename?: 'DeviceVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type DeviceVarPopFields = {
  __typename?: 'DeviceVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DeviceVarSampFields = {
  __typename?: 'DeviceVarSampFields';
  id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};


export type GeographyCastExp = {
  geometry?: Maybe<GeometryComparisonExp>;
};

/** columns and relationships of "geography_columns" */
export type GeographyColumns = {
  __typename?: 'GeographyColumns';
  coordDimension?: Maybe<Scalars['Int']>;
  fGeographyColumn?: Maybe<Scalars['name']>;
  fTableCatalog?: Maybe<Scalars['name']>;
  fTableName?: Maybe<Scalars['name']>;
  fTableSchema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geography_columns" */
export type GeographyColumnsAggregate = {
  __typename?: 'GeographyColumnsAggregate';
  aggregate?: Maybe<GeographyColumnsAggregateFields>;
  nodes: Array<GeographyColumns>;
};

/** aggregate fields of "geography_columns" */
export type GeographyColumnsAggregateFields = {
  __typename?: 'GeographyColumnsAggregateFields';
  avg?: Maybe<GeographyColumnsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<GeographyColumnsMaxFields>;
  min?: Maybe<GeographyColumnsMinFields>;
  stddev?: Maybe<GeographyColumnsStddevFields>;
  stddevPop?: Maybe<GeographyColumnsStddevPopFields>;
  stddevSamp?: Maybe<GeographyColumnsStddevSampFields>;
  sum?: Maybe<GeographyColumnsSumFields>;
  varPop?: Maybe<GeographyColumnsVarPopFields>;
  varSamp?: Maybe<GeographyColumnsVarSampFields>;
  variance?: Maybe<GeographyColumnsVarianceFields>;
};


/** aggregate fields of "geography_columns" */
export type GeographyColumnsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<GeographyColumnsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GeographyColumnsAvgFields = {
  __typename?: 'GeographyColumnsAvgFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type GeographyColumnsBoolExp = {
  _and?: Maybe<Array<GeographyColumnsBoolExp>>;
  _not?: Maybe<GeographyColumnsBoolExp>;
  _or?: Maybe<Array<GeographyColumnsBoolExp>>;
  coordDimension?: Maybe<IntComparisonExp>;
  fGeographyColumn?: Maybe<NameComparisonExp>;
  fTableCatalog?: Maybe<NameComparisonExp>;
  fTableName?: Maybe<NameComparisonExp>;
  fTableSchema?: Maybe<NameComparisonExp>;
  srid?: Maybe<IntComparisonExp>;
  type?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type GeographyColumnsMaxFields = {
  __typename?: 'GeographyColumnsMaxFields';
  coordDimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type GeographyColumnsMinFields = {
  __typename?: 'GeographyColumnsMinFields';
  coordDimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "geography_columns". */
export type GeographyColumnsOrderBy = {
  coordDimension?: Maybe<OrderBy>;
  fGeographyColumn?: Maybe<OrderBy>;
  fTableCatalog?: Maybe<OrderBy>;
  fTableName?: Maybe<OrderBy>;
  fTableSchema?: Maybe<OrderBy>;
  srid?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
};

/** select columns of table "geography_columns" */
export enum GeographyColumnsSelectColumn {
  /** column name */
  CoordDimension = 'coordDimension',
  /** column name */
  FGeographyColumn = 'fGeographyColumn',
  /** column name */
  FTableCatalog = 'fTableCatalog',
  /** column name */
  FTableName = 'fTableName',
  /** column name */
  FTableSchema = 'fTableSchema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type GeographyColumnsStddevFields = {
  __typename?: 'GeographyColumnsStddevFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type GeographyColumnsStddevPopFields = {
  __typename?: 'GeographyColumnsStddevPopFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type GeographyColumnsStddevSampFields = {
  __typename?: 'GeographyColumnsStddevSampFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "geography_columns" */
export type GeographyColumnsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GeographyColumnsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GeographyColumnsStreamCursorValueInput = {
  coordDimension?: Maybe<Scalars['Int']>;
  fGeographyColumn?: Maybe<Scalars['name']>;
  fTableCatalog?: Maybe<Scalars['name']>;
  fTableName?: Maybe<Scalars['name']>;
  fTableSchema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type GeographyColumnsSumFields = {
  __typename?: 'GeographyColumnsSumFields';
  coordDimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type GeographyColumnsVarianceFields = {
  __typename?: 'GeographyColumnsVarianceFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type GeographyColumnsVarPopFields = {
  __typename?: 'GeographyColumnsVarPopFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type GeographyColumnsVarSampFields = {
  __typename?: 'GeographyColumnsVarSampFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type GeographyComparisonExp = {
  _cast?: Maybe<GeographyCastExp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _stDWithin?: Maybe<StDWithinGeographyInput>;
  /** does the column spatially intersect the given geography value */
  _stIntersects?: Maybe<Scalars['geography']>;
};


export type GeometryCastExp = {
  geography?: Maybe<GeographyComparisonExp>;
};

/** columns and relationships of "geometry_columns" */
export type GeometryColumns = {
  __typename?: 'GeometryColumns';
  coordDimension?: Maybe<Scalars['Int']>;
  fGeometryColumn?: Maybe<Scalars['name']>;
  fTableCatalog?: Maybe<Scalars['String']>;
  fTableName?: Maybe<Scalars['name']>;
  fTableSchema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "geometry_columns" */
export type GeometryColumnsAggregate = {
  __typename?: 'GeometryColumnsAggregate';
  aggregate?: Maybe<GeometryColumnsAggregateFields>;
  nodes: Array<GeometryColumns>;
};

/** aggregate fields of "geometry_columns" */
export type GeometryColumnsAggregateFields = {
  __typename?: 'GeometryColumnsAggregateFields';
  avg?: Maybe<GeometryColumnsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<GeometryColumnsMaxFields>;
  min?: Maybe<GeometryColumnsMinFields>;
  stddev?: Maybe<GeometryColumnsStddevFields>;
  stddevPop?: Maybe<GeometryColumnsStddevPopFields>;
  stddevSamp?: Maybe<GeometryColumnsStddevSampFields>;
  sum?: Maybe<GeometryColumnsSumFields>;
  varPop?: Maybe<GeometryColumnsVarPopFields>;
  varSamp?: Maybe<GeometryColumnsVarSampFields>;
  variance?: Maybe<GeometryColumnsVarianceFields>;
};


/** aggregate fields of "geometry_columns" */
export type GeometryColumnsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<GeometryColumnsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GeometryColumnsAvgFields = {
  __typename?: 'GeometryColumnsAvgFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type GeometryColumnsBoolExp = {
  _and?: Maybe<Array<GeometryColumnsBoolExp>>;
  _not?: Maybe<GeometryColumnsBoolExp>;
  _or?: Maybe<Array<GeometryColumnsBoolExp>>;
  coordDimension?: Maybe<IntComparisonExp>;
  fGeometryColumn?: Maybe<NameComparisonExp>;
  fTableCatalog?: Maybe<StringComparisonExp>;
  fTableName?: Maybe<NameComparisonExp>;
  fTableSchema?: Maybe<NameComparisonExp>;
  srid?: Maybe<IntComparisonExp>;
  type?: Maybe<StringComparisonExp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type GeometryColumnsIncInput = {
  coordDimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "geometry_columns" */
export type GeometryColumnsInsertInput = {
  coordDimension?: Maybe<Scalars['Int']>;
  fGeometryColumn?: Maybe<Scalars['name']>;
  fTableCatalog?: Maybe<Scalars['String']>;
  fTableName?: Maybe<Scalars['name']>;
  fTableSchema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GeometryColumnsMaxFields = {
  __typename?: 'GeometryColumnsMaxFields';
  coordDimension?: Maybe<Scalars['Int']>;
  fTableCatalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type GeometryColumnsMinFields = {
  __typename?: 'GeometryColumnsMinFields';
  coordDimension?: Maybe<Scalars['Int']>;
  fTableCatalog?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "geometry_columns" */
export type GeometryColumnsMutationResponse = {
  __typename?: 'GeometryColumnsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GeometryColumns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type GeometryColumnsOrderBy = {
  coordDimension?: Maybe<OrderBy>;
  fGeometryColumn?: Maybe<OrderBy>;
  fTableCatalog?: Maybe<OrderBy>;
  fTableName?: Maybe<OrderBy>;
  fTableSchema?: Maybe<OrderBy>;
  srid?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
};

/** select columns of table "geometry_columns" */
export enum GeometryColumnsSelectColumn {
  /** column name */
  CoordDimension = 'coordDimension',
  /** column name */
  FGeometryColumn = 'fGeometryColumn',
  /** column name */
  FTableCatalog = 'fTableCatalog',
  /** column name */
  FTableName = 'fTableName',
  /** column name */
  FTableSchema = 'fTableSchema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "geometry_columns" */
export type GeometryColumnsSetInput = {
  coordDimension?: Maybe<Scalars['Int']>;
  fGeometryColumn?: Maybe<Scalars['name']>;
  fTableCatalog?: Maybe<Scalars['String']>;
  fTableName?: Maybe<Scalars['name']>;
  fTableSchema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type GeometryColumnsStddevFields = {
  __typename?: 'GeometryColumnsStddevFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type GeometryColumnsStddevPopFields = {
  __typename?: 'GeometryColumnsStddevPopFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type GeometryColumnsStddevSampFields = {
  __typename?: 'GeometryColumnsStddevSampFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "geometry_columns" */
export type GeometryColumnsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GeometryColumnsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GeometryColumnsStreamCursorValueInput = {
  coordDimension?: Maybe<Scalars['Int']>;
  fGeometryColumn?: Maybe<Scalars['name']>;
  fTableCatalog?: Maybe<Scalars['String']>;
  fTableName?: Maybe<Scalars['name']>;
  fTableSchema?: Maybe<Scalars['name']>;
  srid?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type GeometryColumnsSumFields = {
  __typename?: 'GeometryColumnsSumFields';
  coordDimension?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

export type GeometryColumnsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<GeometryColumnsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<GeometryColumnsSetInput>;
  /** filter the rows which have to be updated */
  where: GeometryColumnsBoolExp;
};

/** aggregate variance on columns */
export type GeometryColumnsVarianceFields = {
  __typename?: 'GeometryColumnsVarianceFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type GeometryColumnsVarPopFields = {
  __typename?: 'GeometryColumnsVarPopFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type GeometryColumnsVarSampFields = {
  __typename?: 'GeometryColumnsVarSampFields';
  coordDimension?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type GeometryComparisonExp = {
  _cast?: Maybe<GeometryCastExp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st3dDWithin?: Maybe<StDWithinInput>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st3dIntersects?: Maybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _stContains?: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _stCrosses?: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _stDWithin?: Maybe<StDWithinInput>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _stEquals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _stIntersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _stOverlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _stTouches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _stWithin?: Maybe<Scalars['geometry']>;
};

/** columns and relationships of "globally_blocked_identity" */
export type GloballyBlockedIdentity = {
  __typename?: 'GloballyBlockedIdentity';
  dateCreated: Scalars['timestamptz'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  reason: Scalars['String'];
};

/** aggregated selection of "globally_blocked_identity" */
export type GloballyBlockedIdentityAggregate = {
  __typename?: 'GloballyBlockedIdentityAggregate';
  aggregate?: Maybe<GloballyBlockedIdentityAggregateFields>;
  nodes: Array<GloballyBlockedIdentity>;
};

/** aggregate fields of "globally_blocked_identity" */
export type GloballyBlockedIdentityAggregateFields = {
  __typename?: 'GloballyBlockedIdentityAggregateFields';
  avg?: Maybe<GloballyBlockedIdentityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<GloballyBlockedIdentityMaxFields>;
  min?: Maybe<GloballyBlockedIdentityMinFields>;
  stddev?: Maybe<GloballyBlockedIdentityStddevFields>;
  stddevPop?: Maybe<GloballyBlockedIdentityStddevPopFields>;
  stddevSamp?: Maybe<GloballyBlockedIdentityStddevSampFields>;
  sum?: Maybe<GloballyBlockedIdentitySumFields>;
  varPop?: Maybe<GloballyBlockedIdentityVarPopFields>;
  varSamp?: Maybe<GloballyBlockedIdentityVarSampFields>;
  variance?: Maybe<GloballyBlockedIdentityVarianceFields>;
};


/** aggregate fields of "globally_blocked_identity" */
export type GloballyBlockedIdentityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<GloballyBlockedIdentitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GloballyBlockedIdentityAvgFields = {
  __typename?: 'GloballyBlockedIdentityAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "globally_blocked_identity". All fields are combined with a logical 'AND'. */
export type GloballyBlockedIdentityBoolExp = {
  _and?: Maybe<Array<GloballyBlockedIdentityBoolExp>>;
  _not?: Maybe<GloballyBlockedIdentityBoolExp>;
  _or?: Maybe<Array<GloballyBlockedIdentityBoolExp>>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  identifier?: Maybe<StringComparisonExp>;
  reason?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "globally_blocked_identity" */
export enum GloballyBlockedIdentityConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22879Primary = 'idx_22879_primary',
  /** unique or primary key constraint on columns "identifier" */
  Idx_22879UniqB600ffbb772e836a = 'idx_22879_uniq_b600ffbb772e836a'
}

/** input type for incrementing numeric columns in table "globally_blocked_identity" */
export type GloballyBlockedIdentityIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "globally_blocked_identity" */
export type GloballyBlockedIdentityInsertInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GloballyBlockedIdentityMaxFields = {
  __typename?: 'GloballyBlockedIdentityMaxFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type GloballyBlockedIdentityMinFields = {
  __typename?: 'GloballyBlockedIdentityMinFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "globally_blocked_identity" */
export type GloballyBlockedIdentityMutationResponse = {
  __typename?: 'GloballyBlockedIdentityMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GloballyBlockedIdentity>;
};

/** on_conflict condition type for table "globally_blocked_identity" */
export type GloballyBlockedIdentityOnConflict = {
  constraint: GloballyBlockedIdentityConstraint;
  updateColumns: Array<GloballyBlockedIdentityUpdateColumn>;
  where?: Maybe<GloballyBlockedIdentityBoolExp>;
};

/** Ordering options when selecting data from "globally_blocked_identity". */
export type GloballyBlockedIdentityOrderBy = {
  dateCreated?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  reason?: Maybe<OrderBy>;
};

/** primary key columns input for table: globally_blocked_identity */
export type GloballyBlockedIdentityPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "globally_blocked_identity" */
export enum GloballyBlockedIdentitySelectColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Reason = 'reason'
}

/** input type for updating data in table "globally_blocked_identity" */
export type GloballyBlockedIdentitySetInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type GloballyBlockedIdentityStddevFields = {
  __typename?: 'GloballyBlockedIdentityStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type GloballyBlockedIdentityStddevPopFields = {
  __typename?: 'GloballyBlockedIdentityStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type GloballyBlockedIdentityStddevSampFields = {
  __typename?: 'GloballyBlockedIdentityStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "globally_blocked_identity" */
export type GloballyBlockedIdentityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GloballyBlockedIdentityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GloballyBlockedIdentityStreamCursorValueInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type GloballyBlockedIdentitySumFields = {
  __typename?: 'GloballyBlockedIdentitySumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "globally_blocked_identity" */
export enum GloballyBlockedIdentityUpdateColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Reason = 'reason'
}

export type GloballyBlockedIdentityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<GloballyBlockedIdentityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<GloballyBlockedIdentitySetInput>;
  /** filter the rows which have to be updated */
  where: GloballyBlockedIdentityBoolExp;
};

/** aggregate variance on columns */
export type GloballyBlockedIdentityVarianceFields = {
  __typename?: 'GloballyBlockedIdentityVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type GloballyBlockedIdentityVarPopFields = {
  __typename?: 'GloballyBlockedIdentityVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type GloballyBlockedIdentityVarSampFields = {
  __typename?: 'GloballyBlockedIdentityVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "google_review_link" */
export type GoogleReviewLink = {
  __typename?: 'GoogleReviewLink';
  id: Scalars['Int'];
  /** An object relationship */
  proPresentation: ProPresentation;
  proPresentationId: Scalars['Int'];
  /** An array relationship */
  proViews: Array<ProView>;
  /** An aggregate relationship */
  proViewsAggregate: ProViewAggregate;
  url: Scalars['String'];
};


/** columns and relationships of "google_review_link" */
export type GoogleReviewLinkProViewsArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


/** columns and relationships of "google_review_link" */
export type GoogleReviewLinkProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};

/** aggregated selection of "google_review_link" */
export type GoogleReviewLinkAggregate = {
  __typename?: 'GoogleReviewLinkAggregate';
  aggregate?: Maybe<GoogleReviewLinkAggregateFields>;
  nodes: Array<GoogleReviewLink>;
};

export type GoogleReviewLinkAggregateBoolExp = {
  count?: Maybe<GoogleReviewLinkAggregateBoolExpCount>;
};

export type GoogleReviewLinkAggregateBoolExpCount = {
  arguments?: Maybe<Array<GoogleReviewLinkSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<GoogleReviewLinkBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "google_review_link" */
export type GoogleReviewLinkAggregateFields = {
  __typename?: 'GoogleReviewLinkAggregateFields';
  avg?: Maybe<GoogleReviewLinkAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<GoogleReviewLinkMaxFields>;
  min?: Maybe<GoogleReviewLinkMinFields>;
  stddev?: Maybe<GoogleReviewLinkStddevFields>;
  stddevPop?: Maybe<GoogleReviewLinkStddevPopFields>;
  stddevSamp?: Maybe<GoogleReviewLinkStddevSampFields>;
  sum?: Maybe<GoogleReviewLinkSumFields>;
  varPop?: Maybe<GoogleReviewLinkVarPopFields>;
  varSamp?: Maybe<GoogleReviewLinkVarSampFields>;
  variance?: Maybe<GoogleReviewLinkVarianceFields>;
};


/** aggregate fields of "google_review_link" */
export type GoogleReviewLinkAggregateFieldsCountArgs = {
  columns?: Maybe<Array<GoogleReviewLinkSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "google_review_link" */
export type GoogleReviewLinkAggregateOrderBy = {
  avg?: Maybe<GoogleReviewLinkAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<GoogleReviewLinkMaxOrderBy>;
  min?: Maybe<GoogleReviewLinkMinOrderBy>;
  stddev?: Maybe<GoogleReviewLinkStddevOrderBy>;
  stddevPop?: Maybe<GoogleReviewLinkStddevPopOrderBy>;
  stddevSamp?: Maybe<GoogleReviewLinkStddevSampOrderBy>;
  sum?: Maybe<GoogleReviewLinkSumOrderBy>;
  varPop?: Maybe<GoogleReviewLinkVarPopOrderBy>;
  varSamp?: Maybe<GoogleReviewLinkVarSampOrderBy>;
  variance?: Maybe<GoogleReviewLinkVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "google_review_link" */
export type GoogleReviewLinkArrRelInsertInput = {
  data: Array<GoogleReviewLinkInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<GoogleReviewLinkOnConflict>;
};

/** aggregate avg on columns */
export type GoogleReviewLinkAvgFields = {
  __typename?: 'GoogleReviewLinkAvgFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "google_review_link" */
export type GoogleReviewLinkAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "google_review_link". All fields are combined with a logical 'AND'. */
export type GoogleReviewLinkBoolExp = {
  _and?: Maybe<Array<GoogleReviewLinkBoolExp>>;
  _not?: Maybe<GoogleReviewLinkBoolExp>;
  _or?: Maybe<Array<GoogleReviewLinkBoolExp>>;
  id?: Maybe<IntComparisonExp>;
  proPresentation?: Maybe<ProPresentationBoolExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
  proViews?: Maybe<ProViewBoolExp>;
  proViewsAggregate?: Maybe<ProViewAggregateBoolExp>;
  url?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "google_review_link" */
export enum GoogleReviewLinkConstraint {
  /** unique or primary key constraint on columns "id" */
  GoogleReviewLinkPkey = 'google_review_link_pkey'
}

/** input type for incrementing numeric columns in table "google_review_link" */
export type GoogleReviewLinkIncInput = {
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "google_review_link" */
export type GoogleReviewLinkInsertInput = {
  id?: Maybe<Scalars['Int']>;
  proPresentation?: Maybe<ProPresentationObjRelInsertInput>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proViews?: Maybe<ProViewArrRelInsertInput>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type GoogleReviewLinkMaxFields = {
  __typename?: 'GoogleReviewLinkMaxFields';
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "google_review_link" */
export type GoogleReviewLinkMaxOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type GoogleReviewLinkMinFields = {
  __typename?: 'GoogleReviewLinkMinFields';
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "google_review_link" */
export type GoogleReviewLinkMinOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
};

/** response of any mutation on the table "google_review_link" */
export type GoogleReviewLinkMutationResponse = {
  __typename?: 'GoogleReviewLinkMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<GoogleReviewLink>;
};

/** input type for inserting object relation for remote table "google_review_link" */
export type GoogleReviewLinkObjRelInsertInput = {
  data: GoogleReviewLinkInsertInput;
  /** upsert condition */
  onConflict?: Maybe<GoogleReviewLinkOnConflict>;
};

/** on_conflict condition type for table "google_review_link" */
export type GoogleReviewLinkOnConflict = {
  constraint: GoogleReviewLinkConstraint;
  updateColumns: Array<GoogleReviewLinkUpdateColumn>;
  where?: Maybe<GoogleReviewLinkBoolExp>;
};

/** Ordering options when selecting data from "google_review_link". */
export type GoogleReviewLinkOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentation?: Maybe<ProPresentationOrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proViewsAggregate?: Maybe<ProViewAggregateOrderBy>;
  url?: Maybe<OrderBy>;
};

/** primary key columns input for table: google_review_link */
export type GoogleReviewLinkPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "google_review_link" */
export enum GoogleReviewLinkSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "google_review_link" */
export type GoogleReviewLinkSetInput = {
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type GoogleReviewLinkStddevFields = {
  __typename?: 'GoogleReviewLinkStddevFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "google_review_link" */
export type GoogleReviewLinkStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type GoogleReviewLinkStddevPopFields = {
  __typename?: 'GoogleReviewLinkStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "google_review_link" */
export type GoogleReviewLinkStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type GoogleReviewLinkStddevSampFields = {
  __typename?: 'GoogleReviewLinkStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "google_review_link" */
export type GoogleReviewLinkStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "google_review_link" */
export type GoogleReviewLinkStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GoogleReviewLinkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GoogleReviewLinkStreamCursorValueInput = {
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type GoogleReviewLinkSumFields = {
  __typename?: 'GoogleReviewLinkSumFields';
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "google_review_link" */
export type GoogleReviewLinkSumOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** update columns of table "google_review_link" */
export enum GoogleReviewLinkUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  Url = 'url'
}

export type GoogleReviewLinkUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<GoogleReviewLinkIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<GoogleReviewLinkSetInput>;
  /** filter the rows which have to be updated */
  where: GoogleReviewLinkBoolExp;
};

/** aggregate variance on columns */
export type GoogleReviewLinkVarianceFields = {
  __typename?: 'GoogleReviewLinkVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "google_review_link" */
export type GoogleReviewLinkVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type GoogleReviewLinkVarPopFields = {
  __typename?: 'GoogleReviewLinkVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "google_review_link" */
export type GoogleReviewLinkVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type GoogleReviewLinkVarSampFields = {
  __typename?: 'GoogleReviewLinkVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "google_review_link" */
export type GoogleReviewLinkVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** columns and relationships of "grouped_pro_intervention" */
export type GroupedProIntervention = {
  __typename?: 'GroupedProIntervention';
  exclude?: Maybe<Scalars['geometry']>;
  include?: Maybe<Scalars['geometry']>;
  /** An object relationship */
  proView?: Maybe<ProView>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "grouped_pro_intervention" */
export type GroupedProInterventionAggregate = {
  __typename?: 'GroupedProInterventionAggregate';
  aggregate?: Maybe<GroupedProInterventionAggregateFields>;
  nodes: Array<GroupedProIntervention>;
};

/** aggregate fields of "grouped_pro_intervention" */
export type GroupedProInterventionAggregateFields = {
  __typename?: 'GroupedProInterventionAggregateFields';
  avg?: Maybe<GroupedProInterventionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<GroupedProInterventionMaxFields>;
  min?: Maybe<GroupedProInterventionMinFields>;
  stddev?: Maybe<GroupedProInterventionStddevFields>;
  stddevPop?: Maybe<GroupedProInterventionStddevPopFields>;
  stddevSamp?: Maybe<GroupedProInterventionStddevSampFields>;
  sum?: Maybe<GroupedProInterventionSumFields>;
  varPop?: Maybe<GroupedProInterventionVarPopFields>;
  varSamp?: Maybe<GroupedProInterventionVarSampFields>;
  variance?: Maybe<GroupedProInterventionVarianceFields>;
};


/** aggregate fields of "grouped_pro_intervention" */
export type GroupedProInterventionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<GroupedProInterventionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type GroupedProInterventionAvgFields = {
  __typename?: 'GroupedProInterventionAvgFields';
  proViewId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "grouped_pro_intervention". All fields are combined with a logical 'AND'. */
export type GroupedProInterventionBoolExp = {
  _and?: Maybe<Array<GroupedProInterventionBoolExp>>;
  _not?: Maybe<GroupedProInterventionBoolExp>;
  _or?: Maybe<Array<GroupedProInterventionBoolExp>>;
  exclude?: Maybe<GeometryComparisonExp>;
  include?: Maybe<GeometryComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
};

/** input type for inserting data into table "grouped_pro_intervention" */
export type GroupedProInterventionInsertInput = {
  exclude?: Maybe<Scalars['geometry']>;
  include?: Maybe<Scalars['geometry']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type GroupedProInterventionMaxFields = {
  __typename?: 'GroupedProInterventionMaxFields';
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type GroupedProInterventionMinFields = {
  __typename?: 'GroupedProInterventionMinFields';
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "grouped_pro_intervention" */
export type GroupedProInterventionObjRelInsertInput = {
  data: GroupedProInterventionInsertInput;
};

/** Ordering options when selecting data from "grouped_pro_intervention". */
export type GroupedProInterventionOrderBy = {
  exclude?: Maybe<OrderBy>;
  include?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** select columns of table "grouped_pro_intervention" */
export enum GroupedProInterventionSelectColumn {
  /** column name */
  Exclude = 'exclude',
  /** column name */
  Include = 'include',
  /** column name */
  ProViewId = 'proViewId'
}

/** aggregate stddev on columns */
export type GroupedProInterventionStddevFields = {
  __typename?: 'GroupedProInterventionStddevFields';
  proViewId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type GroupedProInterventionStddevPopFields = {
  __typename?: 'GroupedProInterventionStddevPopFields';
  proViewId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type GroupedProInterventionStddevSampFields = {
  __typename?: 'GroupedProInterventionStddevSampFields';
  proViewId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "grouped_pro_intervention" */
export type GroupedProInterventionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: GroupedProInterventionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GroupedProInterventionStreamCursorValueInput = {
  exclude?: Maybe<Scalars['geometry']>;
  include?: Maybe<Scalars['geometry']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type GroupedProInterventionSumFields = {
  __typename?: 'GroupedProInterventionSumFields';
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type GroupedProInterventionVarianceFields = {
  __typename?: 'GroupedProInterventionVarianceFields';
  proViewId?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type GroupedProInterventionVarPopFields = {
  __typename?: 'GroupedProInterventionVarPopFields';
  proViewId?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type GroupedProInterventionVarSampFields = {
  __typename?: 'GroupedProInterventionVarSampFields';
  proViewId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


export type JsonbCastExp = {
  String?: Maybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: Maybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZone = {
  __typename?: 'ManagerBilikPersonBilikZone';
  /** An object relationship */
  bilikPerson: BilikPerson;
  bilikPersonId: Scalars['Int'];
  /** An object relationship */
  bilikZone: BilikZone;
  bilikZoneId: Scalars['Int'];
};

/** aggregated selection of "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneAggregate = {
  __typename?: 'ManagerBilikPersonBilikZoneAggregate';
  aggregate?: Maybe<ManagerBilikPersonBilikZoneAggregateFields>;
  nodes: Array<ManagerBilikPersonBilikZone>;
};

export type ManagerBilikPersonBilikZoneAggregateBoolExp = {
  count?: Maybe<ManagerBilikPersonBilikZoneAggregateBoolExpCount>;
};

export type ManagerBilikPersonBilikZoneAggregateBoolExpCount = {
  arguments?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneAggregateFields = {
  __typename?: 'ManagerBilikPersonBilikZoneAggregateFields';
  avg?: Maybe<ManagerBilikPersonBilikZoneAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ManagerBilikPersonBilikZoneMaxFields>;
  min?: Maybe<ManagerBilikPersonBilikZoneMinFields>;
  stddev?: Maybe<ManagerBilikPersonBilikZoneStddevFields>;
  stddevPop?: Maybe<ManagerBilikPersonBilikZoneStddevPopFields>;
  stddevSamp?: Maybe<ManagerBilikPersonBilikZoneStddevSampFields>;
  sum?: Maybe<ManagerBilikPersonBilikZoneSumFields>;
  varPop?: Maybe<ManagerBilikPersonBilikZoneVarPopFields>;
  varSamp?: Maybe<ManagerBilikPersonBilikZoneVarSampFields>;
  variance?: Maybe<ManagerBilikPersonBilikZoneVarianceFields>;
};


/** aggregate fields of "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneAggregateOrderBy = {
  avg?: Maybe<ManagerBilikPersonBilikZoneAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ManagerBilikPersonBilikZoneMaxOrderBy>;
  min?: Maybe<ManagerBilikPersonBilikZoneMinOrderBy>;
  stddev?: Maybe<ManagerBilikPersonBilikZoneStddevOrderBy>;
  stddevPop?: Maybe<ManagerBilikPersonBilikZoneStddevPopOrderBy>;
  stddevSamp?: Maybe<ManagerBilikPersonBilikZoneStddevSampOrderBy>;
  sum?: Maybe<ManagerBilikPersonBilikZoneSumOrderBy>;
  varPop?: Maybe<ManagerBilikPersonBilikZoneVarPopOrderBy>;
  varSamp?: Maybe<ManagerBilikPersonBilikZoneVarSampOrderBy>;
  variance?: Maybe<ManagerBilikPersonBilikZoneVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneArrRelInsertInput = {
  data: Array<ManagerBilikPersonBilikZoneInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ManagerBilikPersonBilikZoneOnConflict>;
};

/** aggregate avg on columns */
export type ManagerBilikPersonBilikZoneAvgFields = {
  __typename?: 'ManagerBilikPersonBilikZoneAvgFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneAvgOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "manager_bilik_person_bilik_zone". All fields are combined with a logical 'AND'.
 */
export type ManagerBilikPersonBilikZoneBoolExp = {
  _and?: Maybe<Array<ManagerBilikPersonBilikZoneBoolExp>>;
  _not?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
  _or?: Maybe<Array<ManagerBilikPersonBilikZoneBoolExp>>;
  bilikPerson?: Maybe<BilikPersonBoolExp>;
  bilikPersonId?: Maybe<IntComparisonExp>;
  bilikZone?: Maybe<BilikZoneBoolExp>;
  bilikZoneId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "manager_bilik_person_bilik_zone" */
export enum ManagerBilikPersonBilikZoneConstraint {
  /** unique or primary key constraint on columns "bilik_person_id", "bilik_zone_id" */
  ManagerBilikPersonBilikZonePkey = 'manager_bilik_person_bilik_zone_pkey'
}

/** input type for incrementing numeric columns in table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneIncInput = {
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneInsertInput = {
  bilikPerson?: Maybe<BilikPersonObjRelInsertInput>;
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZone?: Maybe<BilikZoneObjRelInsertInput>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ManagerBilikPersonBilikZoneMaxFields = {
  __typename?: 'ManagerBilikPersonBilikZoneMaxFields';
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneMaxOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ManagerBilikPersonBilikZoneMinFields = {
  __typename?: 'ManagerBilikPersonBilikZoneMinFields';
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneMinOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneMutationResponse = {
  __typename?: 'ManagerBilikPersonBilikZoneMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ManagerBilikPersonBilikZone>;
};

/** on_conflict condition type for table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneOnConflict = {
  constraint: ManagerBilikPersonBilikZoneConstraint;
  updateColumns: Array<ManagerBilikPersonBilikZoneUpdateColumn>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};

/** Ordering options when selecting data from "manager_bilik_person_bilik_zone". */
export type ManagerBilikPersonBilikZoneOrderBy = {
  bilikPerson?: Maybe<BilikPersonOrderBy>;
  bilikPersonId?: Maybe<OrderBy>;
  bilikZone?: Maybe<BilikZoneOrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** primary key columns input for table: manager_bilik_person_bilik_zone */
export type ManagerBilikPersonBilikZonePkColumnsInput = {
  bilikPersonId: Scalars['Int'];
  bilikZoneId: Scalars['Int'];
};

/** select columns of table "manager_bilik_person_bilik_zone" */
export enum ManagerBilikPersonBilikZoneSelectColumn {
  /** column name */
  BilikPersonId = 'bilikPersonId',
  /** column name */
  BilikZoneId = 'bilikZoneId'
}

/** input type for updating data in table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneSetInput = {
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ManagerBilikPersonBilikZoneStddevFields = {
  __typename?: 'ManagerBilikPersonBilikZoneStddevFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneStddevOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ManagerBilikPersonBilikZoneStddevPopFields = {
  __typename?: 'ManagerBilikPersonBilikZoneStddevPopFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneStddevPopOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ManagerBilikPersonBilikZoneStddevSampFields = {
  __typename?: 'ManagerBilikPersonBilikZoneStddevSampFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneStddevSampOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ManagerBilikPersonBilikZoneStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ManagerBilikPersonBilikZoneStreamCursorValueInput = {
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ManagerBilikPersonBilikZoneSumFields = {
  __typename?: 'ManagerBilikPersonBilikZoneSumFields';
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneSumOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** update columns of table "manager_bilik_person_bilik_zone" */
export enum ManagerBilikPersonBilikZoneUpdateColumn {
  /** column name */
  BilikPersonId = 'bilikPersonId',
  /** column name */
  BilikZoneId = 'bilikZoneId'
}

export type ManagerBilikPersonBilikZoneUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ManagerBilikPersonBilikZoneIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ManagerBilikPersonBilikZoneSetInput>;
  /** filter the rows which have to be updated */
  where: ManagerBilikPersonBilikZoneBoolExp;
};

/** aggregate variance on columns */
export type ManagerBilikPersonBilikZoneVarianceFields = {
  __typename?: 'ManagerBilikPersonBilikZoneVarianceFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneVarianceOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ManagerBilikPersonBilikZoneVarPopFields = {
  __typename?: 'ManagerBilikPersonBilikZoneVarPopFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneVarPopOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ManagerBilikPersonBilikZoneVarSampFields = {
  __typename?: 'ManagerBilikPersonBilikZoneVarSampFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "manager_bilik_person_bilik_zone" */
export type ManagerBilikPersonBilikZoneVarSampOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** columns and relationships of "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZone = {
  __typename?: 'MemberBilikPersonBilikZone';
  /** An object relationship */
  bilikPerson: BilikPerson;
  bilikPersonId: Scalars['Int'];
  /** An object relationship */
  bilikZone: BilikZone;
  bilikZoneId: Scalars['Int'];
};

/** aggregated selection of "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneAggregate = {
  __typename?: 'MemberBilikPersonBilikZoneAggregate';
  aggregate?: Maybe<MemberBilikPersonBilikZoneAggregateFields>;
  nodes: Array<MemberBilikPersonBilikZone>;
};

export type MemberBilikPersonBilikZoneAggregateBoolExp = {
  count?: Maybe<MemberBilikPersonBilikZoneAggregateBoolExpCount>;
};

export type MemberBilikPersonBilikZoneAggregateBoolExpCount = {
  arguments?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneAggregateFields = {
  __typename?: 'MemberBilikPersonBilikZoneAggregateFields';
  avg?: Maybe<MemberBilikPersonBilikZoneAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MemberBilikPersonBilikZoneMaxFields>;
  min?: Maybe<MemberBilikPersonBilikZoneMinFields>;
  stddev?: Maybe<MemberBilikPersonBilikZoneStddevFields>;
  stddevPop?: Maybe<MemberBilikPersonBilikZoneStddevPopFields>;
  stddevSamp?: Maybe<MemberBilikPersonBilikZoneStddevSampFields>;
  sum?: Maybe<MemberBilikPersonBilikZoneSumFields>;
  varPop?: Maybe<MemberBilikPersonBilikZoneVarPopFields>;
  varSamp?: Maybe<MemberBilikPersonBilikZoneVarSampFields>;
  variance?: Maybe<MemberBilikPersonBilikZoneVarianceFields>;
};


/** aggregate fields of "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneAggregateOrderBy = {
  avg?: Maybe<MemberBilikPersonBilikZoneAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<MemberBilikPersonBilikZoneMaxOrderBy>;
  min?: Maybe<MemberBilikPersonBilikZoneMinOrderBy>;
  stddev?: Maybe<MemberBilikPersonBilikZoneStddevOrderBy>;
  stddevPop?: Maybe<MemberBilikPersonBilikZoneStddevPopOrderBy>;
  stddevSamp?: Maybe<MemberBilikPersonBilikZoneStddevSampOrderBy>;
  sum?: Maybe<MemberBilikPersonBilikZoneSumOrderBy>;
  varPop?: Maybe<MemberBilikPersonBilikZoneVarPopOrderBy>;
  varSamp?: Maybe<MemberBilikPersonBilikZoneVarSampOrderBy>;
  variance?: Maybe<MemberBilikPersonBilikZoneVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneArrRelInsertInput = {
  data: Array<MemberBilikPersonBilikZoneInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<MemberBilikPersonBilikZoneOnConflict>;
};

/** aggregate avg on columns */
export type MemberBilikPersonBilikZoneAvgFields = {
  __typename?: 'MemberBilikPersonBilikZoneAvgFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneAvgOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/**
 * Boolean expression to filter rows from the table
 * "member_bilik_person_bilik_zone". All fields are combined with a logical 'AND'.
 */
export type MemberBilikPersonBilikZoneBoolExp = {
  _and?: Maybe<Array<MemberBilikPersonBilikZoneBoolExp>>;
  _not?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
  _or?: Maybe<Array<MemberBilikPersonBilikZoneBoolExp>>;
  bilikPerson?: Maybe<BilikPersonBoolExp>;
  bilikPersonId?: Maybe<IntComparisonExp>;
  bilikZone?: Maybe<BilikZoneBoolExp>;
  bilikZoneId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "member_bilik_person_bilik_zone" */
export enum MemberBilikPersonBilikZoneConstraint {
  /** unique or primary key constraint on columns "bilik_person_id", "bilik_zone_id" */
  MemberBilikPersonBilikZonePkey = 'member_bilik_person_bilik_zone_pkey'
}

/** input type for incrementing numeric columns in table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneIncInput = {
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneInsertInput = {
  bilikPerson?: Maybe<BilikPersonObjRelInsertInput>;
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZone?: Maybe<BilikZoneObjRelInsertInput>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type MemberBilikPersonBilikZoneMaxFields = {
  __typename?: 'MemberBilikPersonBilikZoneMaxFields';
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneMaxOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MemberBilikPersonBilikZoneMinFields = {
  __typename?: 'MemberBilikPersonBilikZoneMinFields';
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneMinOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneMutationResponse = {
  __typename?: 'MemberBilikPersonBilikZoneMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MemberBilikPersonBilikZone>;
};

/** on_conflict condition type for table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneOnConflict = {
  constraint: MemberBilikPersonBilikZoneConstraint;
  updateColumns: Array<MemberBilikPersonBilikZoneUpdateColumn>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};

/** Ordering options when selecting data from "member_bilik_person_bilik_zone". */
export type MemberBilikPersonBilikZoneOrderBy = {
  bilikPerson?: Maybe<BilikPersonOrderBy>;
  bilikPersonId?: Maybe<OrderBy>;
  bilikZone?: Maybe<BilikZoneOrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** primary key columns input for table: member_bilik_person_bilik_zone */
export type MemberBilikPersonBilikZonePkColumnsInput = {
  bilikPersonId: Scalars['Int'];
  bilikZoneId: Scalars['Int'];
};

/** select columns of table "member_bilik_person_bilik_zone" */
export enum MemberBilikPersonBilikZoneSelectColumn {
  /** column name */
  BilikPersonId = 'bilikPersonId',
  /** column name */
  BilikZoneId = 'bilikZoneId'
}

/** input type for updating data in table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneSetInput = {
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type MemberBilikPersonBilikZoneStddevFields = {
  __typename?: 'MemberBilikPersonBilikZoneStddevFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneStddevOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type MemberBilikPersonBilikZoneStddevPopFields = {
  __typename?: 'MemberBilikPersonBilikZoneStddevPopFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneStddevPopOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type MemberBilikPersonBilikZoneStddevSampFields = {
  __typename?: 'MemberBilikPersonBilikZoneStddevSampFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneStddevSampOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MemberBilikPersonBilikZoneStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MemberBilikPersonBilikZoneStreamCursorValueInput = {
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type MemberBilikPersonBilikZoneSumFields = {
  __typename?: 'MemberBilikPersonBilikZoneSumFields';
  bilikPersonId?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneSumOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** update columns of table "member_bilik_person_bilik_zone" */
export enum MemberBilikPersonBilikZoneUpdateColumn {
  /** column name */
  BilikPersonId = 'bilikPersonId',
  /** column name */
  BilikZoneId = 'bilikZoneId'
}

export type MemberBilikPersonBilikZoneUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<MemberBilikPersonBilikZoneIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<MemberBilikPersonBilikZoneSetInput>;
  /** filter the rows which have to be updated */
  where: MemberBilikPersonBilikZoneBoolExp;
};

/** aggregate variance on columns */
export type MemberBilikPersonBilikZoneVarianceFields = {
  __typename?: 'MemberBilikPersonBilikZoneVarianceFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneVarianceOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type MemberBilikPersonBilikZoneVarPopFields = {
  __typename?: 'MemberBilikPersonBilikZoneVarPopFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneVarPopOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type MemberBilikPersonBilikZoneVarSampFields = {
  __typename?: 'MemberBilikPersonBilikZoneVarSampFields';
  bilikPersonId?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "member_bilik_person_bilik_zone" */
export type MemberBilikPersonBilikZoneVarSampOrderBy = {
  bilikPersonId?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete data from the table: "account" */
  deleteAccount?: Maybe<AccountMutationResponse>;
  /** delete single row from the table: "account" */
  deleteAccountByPk?: Maybe<Account>;
  /** delete data from the table: "account_device" */
  deleteAccountDevice?: Maybe<AccountDeviceMutationResponse>;
  /** delete single row from the table: "account_device" */
  deleteAccountDeviceByPk?: Maybe<AccountDevice>;
  /** delete data from the table: "account_pro_view" */
  deleteAccountProView?: Maybe<AccountProViewMutationResponse>;
  /** delete single row from the table: "account_pro_view" */
  deleteAccountProViewByPk?: Maybe<AccountProView>;
  /** delete data from the table: "action_token" */
  deleteActionToken?: Maybe<ActionTokenMutationResponse>;
  /** delete single row from the table: "action_token" */
  deleteActionTokenByPk?: Maybe<ActionToken>;
  /** delete data from the table: "autorespond" */
  deleteAutorespond?: Maybe<AutorespondMutationResponse>;
  /** delete single row from the table: "autorespond" */
  deleteAutorespondByPk?: Maybe<Autorespond>;
  /** delete data from the table: "bilik_person" */
  deleteBilikPerson?: Maybe<BilikPersonMutationResponse>;
  /** delete single row from the table: "bilik_person" */
  deleteBilikPersonByPk?: Maybe<BilikPerson>;
  /** delete data from the table: "bilik_solicitation" */
  deleteBilikSolicitation?: Maybe<BilikSolicitationMutationResponse>;
  /** delete single row from the table: "bilik_solicitation" */
  deleteBilikSolicitationByPk?: Maybe<BilikSolicitation>;
  /** delete data from the table: "bilik_zone" */
  deleteBilikZone?: Maybe<BilikZoneMutationResponse>;
  /** delete single row from the table: "bilik_zone" */
  deleteBilikZoneByPk?: Maybe<BilikZone>;
  /** delete data from the table: "certification" */
  deleteCertification?: Maybe<CertificationMutationResponse>;
  /** delete single row from the table: "certification" */
  deleteCertificationByPk?: Maybe<Certification>;
  /** delete data from the table: "city" */
  deleteCity?: Maybe<CityMutationResponse>;
  /** delete single row from the table: "city" */
  deleteCityByPk?: Maybe<City>;
  /** delete data from the table: "city_pro_view" */
  deleteCityProView?: Maybe<CityProViewMutationResponse>;
  /** delete single row from the table: "city_pro_view" */
  deleteCityProViewByPk?: Maybe<CityProView>;
  /** delete data from the table: "device" */
  deleteDevice?: Maybe<DeviceMutationResponse>;
  /** delete single row from the table: "device" */
  deleteDeviceByPk?: Maybe<Device>;
  /** delete data from the table: "geometry_columns" */
  deleteGeometryColumns?: Maybe<GeometryColumnsMutationResponse>;
  /** delete data from the table: "globally_blocked_identity" */
  deleteGloballyBlockedIdentity?: Maybe<GloballyBlockedIdentityMutationResponse>;
  /** delete single row from the table: "globally_blocked_identity" */
  deleteGloballyBlockedIdentityByPk?: Maybe<GloballyBlockedIdentity>;
  /** delete data from the table: "google_review_link" */
  deleteGoogleReviewLink?: Maybe<GoogleReviewLinkMutationResponse>;
  /** delete single row from the table: "google_review_link" */
  deleteGoogleReviewLinkByPk?: Maybe<GoogleReviewLink>;
  /** delete data from the table: "manager_bilik_person_bilik_zone" */
  deleteManagerBilikPersonBilikZone?: Maybe<ManagerBilikPersonBilikZoneMutationResponse>;
  /** delete single row from the table: "manager_bilik_person_bilik_zone" */
  deleteManagerBilikPersonBilikZoneByPk?: Maybe<ManagerBilikPersonBilikZone>;
  /** delete data from the table: "member_bilik_person_bilik_zone" */
  deleteMemberBilikPersonBilikZone?: Maybe<MemberBilikPersonBilikZoneMutationResponse>;
  /** delete single row from the table: "member_bilik_person_bilik_zone" */
  deleteMemberBilikPersonBilikZoneByPk?: Maybe<MemberBilikPersonBilikZone>;
  /** delete data from the table: "note_identity" */
  deleteNoteIdentity?: Maybe<NoteIdentityMutationResponse>;
  /** delete single row from the table: "note_identity" */
  deleteNoteIdentityByPk?: Maybe<NoteIdentity>;
  /** delete data from the table: "open_data_city" */
  deleteOpenDataCity?: Maybe<OpenDataCityMutationResponse>;
  /** delete single row from the table: "open_data_city" */
  deleteOpenDataCityByPk?: Maybe<OpenDataCity>;
  /** delete data from the table: "open_data_department" */
  deleteOpenDataDepartment?: Maybe<OpenDataDepartmentMutationResponse>;
  /** delete single row from the table: "open_data_department" */
  deleteOpenDataDepartmentByPk?: Maybe<OpenDataDepartment>;
  /** delete data from the table: "open_data_region" */
  deleteOpenDataRegion?: Maybe<OpenDataRegionMutationResponse>;
  /** delete single row from the table: "open_data_region" */
  deleteOpenDataRegionByPk?: Maybe<OpenDataRegion>;
  /** delete data from the table: "pro_certification" */
  deleteProCertification?: Maybe<ProCertificationMutationResponse>;
  /** delete single row from the table: "pro_certification" */
  deleteProCertificationByPk?: Maybe<ProCertification>;
  /** delete data from the table: "pro_holiday" */
  deleteProHoliday?: Maybe<ProHolidayMutationResponse>;
  /** delete single row from the table: "pro_holiday" */
  deleteProHolidayByPk?: Maybe<ProHoliday>;
  /** delete data from the table: "pro_intervention" */
  deleteProIntervention?: Maybe<ProInterventionMutationResponse>;
  /** delete single row from the table: "pro_intervention" */
  deleteProInterventionByPk?: Maybe<ProIntervention>;
  /** delete data from the table: "pro_label" */
  deleteProLabel?: Maybe<ProLabelMutationResponse>;
  /** delete single row from the table: "pro_label" */
  deleteProLabelByPk?: Maybe<ProLabel>;
  /** delete data from the table: "pro_mandate" */
  deleteProMandate?: Maybe<ProMandateMutationResponse>;
  /** delete single row from the table: "pro_mandate" */
  deleteProMandateByPk?: Maybe<ProMandate>;
  /** delete data from the table: "pro_media_object" */
  deleteProMediaObject?: Maybe<ProMediaObjectMutationResponse>;
  /** delete single row from the table: "pro_media_object" */
  deleteProMediaObjectByPk?: Maybe<ProMediaObject>;
  /** delete data from the table: "pro_message" */
  deleteProMessage?: Maybe<ProMessageMutationResponse>;
  /** delete single row from the table: "pro_message" */
  deleteProMessageByPk?: Maybe<ProMessage>;
  /** delete data from the table: "pro_organization" */
  deleteProOrganization?: Maybe<ProOrganizationMutationResponse>;
  /** delete single row from the table: "pro_organization" */
  deleteProOrganizationByPk?: Maybe<ProOrganization>;
  /** delete data from the table: "pro_person" */
  deleteProPerson?: Maybe<ProPersonMutationResponse>;
  /** delete single row from the table: "pro_person" */
  deleteProPersonByPk?: Maybe<ProPerson>;
  /** delete data from the table: "pro_postal_address" */
  deleteProPostalAddress?: Maybe<ProPostalAddressMutationResponse>;
  /** delete single row from the table: "pro_postal_address" */
  deleteProPostalAddressByPk?: Maybe<ProPostalAddress>;
  /** delete data from the table: "pro_presentation" */
  deleteProPresentation?: Maybe<ProPresentationMutationResponse>;
  /** delete single row from the table: "pro_presentation" */
  deleteProPresentationByPk?: Maybe<ProPresentation>;
  /** delete data from the table: "pro_price" */
  deleteProPrice?: Maybe<ProPriceMutationResponse>;
  /** delete single row from the table: "pro_price" */
  deleteProPriceByPk?: Maybe<ProPrice>;
  /** delete data from the table: "pro_review" */
  deleteProReview?: Maybe<ProReviewMutationResponse>;
  /** delete single row from the table: "pro_review" */
  deleteProReviewByPk?: Maybe<ProReview>;
  /** delete data from the table: "pro_review_invitation" */
  deleteProReviewInvitation?: Maybe<ProReviewInvitationMutationResponse>;
  /** delete single row from the table: "pro_review_invitation" */
  deleteProReviewInvitationByPk?: Maybe<ProReviewInvitation>;
  /** delete data from the table: "pro_view" */
  deleteProView?: Maybe<ProViewMutationResponse>;
  /** delete single row from the table: "pro_view" */
  deleteProViewByPk?: Maybe<ProView>;
  /** delete data from the table: "pro_view_pro_person" */
  deleteProViewProPerson?: Maybe<ProViewProPersonMutationResponse>;
  /** delete single row from the table: "pro_view_pro_person" */
  deleteProViewProPersonByPk?: Maybe<ProViewProPerson>;
  /** delete data from the table: "pro_view_pro_view" */
  deleteProViewProView?: Maybe<ProViewProViewMutationResponse>;
  /** delete single row from the table: "pro_view_pro_view" */
  deleteProViewProViewByPk?: Maybe<ProViewProView>;
  /** delete data from the table: "queued_sms" */
  deleteQueuedSms?: Maybe<QueuedSmsMutationResponse>;
  /** delete single row from the table: "queued_sms" */
  deleteQueuedSmsByPk?: Maybe<QueuedSms>;
  /** delete data from the table: "slug_redirect" */
  deleteSlugRedirect?: Maybe<SlugRedirectMutationResponse>;
  /** delete single row from the table: "slug_redirect" */
  deleteSlugRedirectByPk?: Maybe<SlugRedirect>;
  /** delete data from the table: "solicitation" */
  deleteSolicitation?: Maybe<SolicitationMutationResponse>;
  /** delete single row from the table: "solicitation" */
  deleteSolicitationByPk?: Maybe<Solicitation>;
  /** delete data from the table: "spatial_ref_sys" */
  deleteSpatialRefSys?: Maybe<SpatialRefSysMutationResponse>;
  /** delete single row from the table: "spatial_ref_sys" */
  deleteSpatialRefSysByPk?: Maybe<SpatialRefSys>;
  /** delete data from the table: "stop_sms" */
  deleteStopSms?: Maybe<StopSmsMutationResponse>;
  /** delete single row from the table: "stop_sms" */
  deleteStopSmsByPk?: Maybe<StopSms>;
  /** delete data from the table: "topology.layer" */
  deleteTopologyLayer?: Maybe<TopologyLayerMutationResponse>;
  /** delete single row from the table: "topology.layer" */
  deleteTopologyLayerByPk?: Maybe<TopologyLayer>;
  /** delete data from the table: "topology.topology" */
  deleteTopologyTopology?: Maybe<TopologyTopologyMutationResponse>;
  /** delete single row from the table: "topology.topology" */
  deleteTopologyTopologyByPk?: Maybe<TopologyTopology>;
  /** delete data from the table: "trade" */
  deleteTrade?: Maybe<TradeMutationResponse>;
  /** delete single row from the table: "trade" */
  deleteTradeByPk?: Maybe<Trade>;
  /** delete data from the table: "trade_group" */
  deleteTradeGroup?: Maybe<TradeGroupMutationResponse>;
  /** delete single row from the table: "trade_group" */
  deleteTradeGroupByPk?: Maybe<TradeGroup>;
  /** delete data from the table: "trade_guide" */
  deleteTradeGuide?: Maybe<TradeGuideMutationResponse>;
  /** delete single row from the table: "trade_guide" */
  deleteTradeGuideByPk?: Maybe<TradeGuide>;
  /** delete data from the table: "trade_keyword" */
  deleteTradeKeyword?: Maybe<TradeKeywordMutationResponse>;
  /** delete single row from the table: "trade_keyword" */
  deleteTradeKeywordByPk?: Maybe<TradeKeyword>;
  /** delete data from the table: "trade_pro_view" */
  deleteTradeProView?: Maybe<TradeProViewMutationResponse>;
  /** delete single row from the table: "trade_pro_view" */
  deleteTradeProViewByPk?: Maybe<TradeProView>;
  /** delete data from the table: "trade_question" */
  deleteTradeQuestion?: Maybe<TradeQuestionMutationResponse>;
  /** delete single row from the table: "trade_question" */
  deleteTradeQuestionByPk?: Maybe<TradeQuestion>;
  /** delete data from the table: "trade_trade_group" */
  deleteTradeTradeGroup?: Maybe<TradeTradeGroupMutationResponse>;
  /** delete single row from the table: "trade_trade_group" */
  deleteTradeTradeGroupByPk?: Maybe<TradeTradeGroup>;
  /** delete data from the table: "zoho_consumer" */
  deleteZohoConsumer?: Maybe<ZohoConsumerMutationResponse>;
  /** delete single row from the table: "zoho_consumer" */
  deleteZohoConsumerByPk?: Maybe<ZohoConsumer>;
  /** delete data from the table: "zoho_organization" */
  deleteZohoOrganization?: Maybe<ZohoOrganizationMutationResponse>;
  /** delete single row from the table: "zoho_organization" */
  deleteZohoOrganizationByPk?: Maybe<ZohoOrganization>;
  /** delete data from the table: "zoho_token" */
  deleteZohoToken?: Maybe<ZohoTokenMutationResponse>;
  /** delete single row from the table: "zoho_token" */
  deleteZohoTokenByPk?: Maybe<ZohoToken>;
  /** insert data into the table: "account" */
  insertAccount?: Maybe<AccountMutationResponse>;
  /** insert data into the table: "account_device" */
  insertAccountDevice?: Maybe<AccountDeviceMutationResponse>;
  /** insert a single row into the table: "account_device" */
  insertAccountDeviceOne?: Maybe<AccountDevice>;
  /** insert a single row into the table: "account" */
  insertAccountOne?: Maybe<Account>;
  /** insert data into the table: "account_pro_view" */
  insertAccountProView?: Maybe<AccountProViewMutationResponse>;
  /** insert a single row into the table: "account_pro_view" */
  insertAccountProViewOne?: Maybe<AccountProView>;
  /** insert data into the table: "action_token" */
  insertActionToken?: Maybe<ActionTokenMutationResponse>;
  /** insert a single row into the table: "action_token" */
  insertActionTokenOne?: Maybe<ActionToken>;
  /** insert data into the table: "autorespond" */
  insertAutorespond?: Maybe<AutorespondMutationResponse>;
  /** insert a single row into the table: "autorespond" */
  insertAutorespondOne?: Maybe<Autorespond>;
  /** insert data into the table: "bilik_person" */
  insertBilikPerson?: Maybe<BilikPersonMutationResponse>;
  /** insert a single row into the table: "bilik_person" */
  insertBilikPersonOne?: Maybe<BilikPerson>;
  /** insert data into the table: "bilik_solicitation" */
  insertBilikSolicitation?: Maybe<BilikSolicitationMutationResponse>;
  /** insert a single row into the table: "bilik_solicitation" */
  insertBilikSolicitationOne?: Maybe<BilikSolicitation>;
  /** insert data into the table: "bilik_zone" */
  insertBilikZone?: Maybe<BilikZoneMutationResponse>;
  /** insert a single row into the table: "bilik_zone" */
  insertBilikZoneOne?: Maybe<BilikZone>;
  /** insert data into the table: "certification" */
  insertCertification?: Maybe<CertificationMutationResponse>;
  /** insert a single row into the table: "certification" */
  insertCertificationOne?: Maybe<Certification>;
  /** insert data into the table: "city" */
  insertCity?: Maybe<CityMutationResponse>;
  /** insert a single row into the table: "city" */
  insertCityOne?: Maybe<City>;
  /** insert data into the table: "city_pro_view" */
  insertCityProView?: Maybe<CityProViewMutationResponse>;
  /** insert a single row into the table: "city_pro_view" */
  insertCityProViewOne?: Maybe<CityProView>;
  /** insert data into the table: "device" */
  insertDevice?: Maybe<DeviceMutationResponse>;
  /** insert a single row into the table: "device" */
  insertDeviceOne?: Maybe<Device>;
  /** insert data into the table: "geometry_columns" */
  insertGeometryColumns?: Maybe<GeometryColumnsMutationResponse>;
  /** insert a single row into the table: "geometry_columns" */
  insertGeometryColumnsOne?: Maybe<GeometryColumns>;
  /** insert data into the table: "globally_blocked_identity" */
  insertGloballyBlockedIdentity?: Maybe<GloballyBlockedIdentityMutationResponse>;
  /** insert a single row into the table: "globally_blocked_identity" */
  insertGloballyBlockedIdentityOne?: Maybe<GloballyBlockedIdentity>;
  /** insert data into the table: "google_review_link" */
  insertGoogleReviewLink?: Maybe<GoogleReviewLinkMutationResponse>;
  /** insert a single row into the table: "google_review_link" */
  insertGoogleReviewLinkOne?: Maybe<GoogleReviewLink>;
  /** insert data into the table: "manager_bilik_person_bilik_zone" */
  insertManagerBilikPersonBilikZone?: Maybe<ManagerBilikPersonBilikZoneMutationResponse>;
  /** insert a single row into the table: "manager_bilik_person_bilik_zone" */
  insertManagerBilikPersonBilikZoneOne?: Maybe<ManagerBilikPersonBilikZone>;
  /** insert data into the table: "member_bilik_person_bilik_zone" */
  insertMemberBilikPersonBilikZone?: Maybe<MemberBilikPersonBilikZoneMutationResponse>;
  /** insert a single row into the table: "member_bilik_person_bilik_zone" */
  insertMemberBilikPersonBilikZoneOne?: Maybe<MemberBilikPersonBilikZone>;
  /** insert data into the table: "note_identity" */
  insertNoteIdentity?: Maybe<NoteIdentityMutationResponse>;
  /** insert a single row into the table: "note_identity" */
  insertNoteIdentityOne?: Maybe<NoteIdentity>;
  /** insert data into the table: "open_data_city" */
  insertOpenDataCity?: Maybe<OpenDataCityMutationResponse>;
  /** insert a single row into the table: "open_data_city" */
  insertOpenDataCityOne?: Maybe<OpenDataCity>;
  /** insert data into the table: "open_data_department" */
  insertOpenDataDepartment?: Maybe<OpenDataDepartmentMutationResponse>;
  /** insert a single row into the table: "open_data_department" */
  insertOpenDataDepartmentOne?: Maybe<OpenDataDepartment>;
  /** insert data into the table: "open_data_region" */
  insertOpenDataRegion?: Maybe<OpenDataRegionMutationResponse>;
  /** insert a single row into the table: "open_data_region" */
  insertOpenDataRegionOne?: Maybe<OpenDataRegion>;
  /** insert data into the table: "pro_certification" */
  insertProCertification?: Maybe<ProCertificationMutationResponse>;
  /** insert a single row into the table: "pro_certification" */
  insertProCertificationOne?: Maybe<ProCertification>;
  /** insert data into the table: "pro_holiday" */
  insertProHoliday?: Maybe<ProHolidayMutationResponse>;
  /** insert a single row into the table: "pro_holiday" */
  insertProHolidayOne?: Maybe<ProHoliday>;
  /** insert data into the table: "pro_intervention" */
  insertProIntervention?: Maybe<ProInterventionMutationResponse>;
  /** insert a single row into the table: "pro_intervention" */
  insertProInterventionOne?: Maybe<ProIntervention>;
  /** insert data into the table: "pro_label" */
  insertProLabel?: Maybe<ProLabelMutationResponse>;
  /** insert a single row into the table: "pro_label" */
  insertProLabelOne?: Maybe<ProLabel>;
  /** insert data into the table: "pro_mandate" */
  insertProMandate?: Maybe<ProMandateMutationResponse>;
  /** insert a single row into the table: "pro_mandate" */
  insertProMandateOne?: Maybe<ProMandate>;
  /** insert data into the table: "pro_media_object" */
  insertProMediaObject?: Maybe<ProMediaObjectMutationResponse>;
  /** insert a single row into the table: "pro_media_object" */
  insertProMediaObjectOne?: Maybe<ProMediaObject>;
  /** insert data into the table: "pro_message" */
  insertProMessage?: Maybe<ProMessageMutationResponse>;
  /** insert a single row into the table: "pro_message" */
  insertProMessageOne?: Maybe<ProMessage>;
  /** insert data into the table: "pro_organization" */
  insertProOrganization?: Maybe<ProOrganizationMutationResponse>;
  /** insert a single row into the table: "pro_organization" */
  insertProOrganizationOne?: Maybe<ProOrganization>;
  /** insert data into the table: "pro_person" */
  insertProPerson?: Maybe<ProPersonMutationResponse>;
  /** insert a single row into the table: "pro_person" */
  insertProPersonOne?: Maybe<ProPerson>;
  /** insert data into the table: "pro_postal_address" */
  insertProPostalAddress?: Maybe<ProPostalAddressMutationResponse>;
  /** insert a single row into the table: "pro_postal_address" */
  insertProPostalAddressOne?: Maybe<ProPostalAddress>;
  /** insert data into the table: "pro_presentation" */
  insertProPresentation?: Maybe<ProPresentationMutationResponse>;
  /** insert a single row into the table: "pro_presentation" */
  insertProPresentationOne?: Maybe<ProPresentation>;
  /** insert data into the table: "pro_price" */
  insertProPrice?: Maybe<ProPriceMutationResponse>;
  /** insert a single row into the table: "pro_price" */
  insertProPriceOne?: Maybe<ProPrice>;
  /** insert data into the table: "pro_review" */
  insertProReview?: Maybe<ProReviewMutationResponse>;
  /** insert data into the table: "pro_review_invitation" */
  insertProReviewInvitation?: Maybe<ProReviewInvitationMutationResponse>;
  /** insert a single row into the table: "pro_review_invitation" */
  insertProReviewInvitationOne?: Maybe<ProReviewInvitation>;
  /** insert a single row into the table: "pro_review" */
  insertProReviewOne?: Maybe<ProReview>;
  /** insert data into the table: "pro_view" */
  insertProView?: Maybe<ProViewMutationResponse>;
  /** insert a single row into the table: "pro_view" */
  insertProViewOne?: Maybe<ProView>;
  /** insert data into the table: "pro_view_pro_person" */
  insertProViewProPerson?: Maybe<ProViewProPersonMutationResponse>;
  /** insert a single row into the table: "pro_view_pro_person" */
  insertProViewProPersonOne?: Maybe<ProViewProPerson>;
  /** insert data into the table: "pro_view_pro_view" */
  insertProViewProView?: Maybe<ProViewProViewMutationResponse>;
  /** insert a single row into the table: "pro_view_pro_view" */
  insertProViewProViewOne?: Maybe<ProViewProView>;
  /** insert data into the table: "queued_sms" */
  insertQueuedSms?: Maybe<QueuedSmsMutationResponse>;
  /** insert a single row into the table: "queued_sms" */
  insertQueuedSmsOne?: Maybe<QueuedSms>;
  /** insert data into the table: "slug_redirect" */
  insertSlugRedirect?: Maybe<SlugRedirectMutationResponse>;
  /** insert a single row into the table: "slug_redirect" */
  insertSlugRedirectOne?: Maybe<SlugRedirect>;
  /** insert data into the table: "solicitation" */
  insertSolicitation?: Maybe<SolicitationMutationResponse>;
  /** insert a single row into the table: "solicitation" */
  insertSolicitationOne?: Maybe<Solicitation>;
  /** insert data into the table: "spatial_ref_sys" */
  insertSpatialRefSys?: Maybe<SpatialRefSysMutationResponse>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insertSpatialRefSysOne?: Maybe<SpatialRefSys>;
  /** insert data into the table: "stop_sms" */
  insertStopSms?: Maybe<StopSmsMutationResponse>;
  /** insert a single row into the table: "stop_sms" */
  insertStopSmsOne?: Maybe<StopSms>;
  /** insert data into the table: "topology.layer" */
  insertTopologyLayer?: Maybe<TopologyLayerMutationResponse>;
  /** insert a single row into the table: "topology.layer" */
  insertTopologyLayerOne?: Maybe<TopologyLayer>;
  /** insert data into the table: "topology.topology" */
  insertTopologyTopology?: Maybe<TopologyTopologyMutationResponse>;
  /** insert a single row into the table: "topology.topology" */
  insertTopologyTopologyOne?: Maybe<TopologyTopology>;
  /** insert data into the table: "trade" */
  insertTrade?: Maybe<TradeMutationResponse>;
  /** insert data into the table: "trade_group" */
  insertTradeGroup?: Maybe<TradeGroupMutationResponse>;
  /** insert a single row into the table: "trade_group" */
  insertTradeGroupOne?: Maybe<TradeGroup>;
  /** insert data into the table: "trade_guide" */
  insertTradeGuide?: Maybe<TradeGuideMutationResponse>;
  /** insert a single row into the table: "trade_guide" */
  insertTradeGuideOne?: Maybe<TradeGuide>;
  /** insert data into the table: "trade_keyword" */
  insertTradeKeyword?: Maybe<TradeKeywordMutationResponse>;
  /** insert a single row into the table: "trade_keyword" */
  insertTradeKeywordOne?: Maybe<TradeKeyword>;
  /** insert a single row into the table: "trade" */
  insertTradeOne?: Maybe<Trade>;
  /** insert data into the table: "trade_pro_view" */
  insertTradeProView?: Maybe<TradeProViewMutationResponse>;
  /** insert a single row into the table: "trade_pro_view" */
  insertTradeProViewOne?: Maybe<TradeProView>;
  /** insert data into the table: "trade_question" */
  insertTradeQuestion?: Maybe<TradeQuestionMutationResponse>;
  /** insert a single row into the table: "trade_question" */
  insertTradeQuestionOne?: Maybe<TradeQuestion>;
  /** insert data into the table: "trade_trade_group" */
  insertTradeTradeGroup?: Maybe<TradeTradeGroupMutationResponse>;
  /** insert a single row into the table: "trade_trade_group" */
  insertTradeTradeGroupOne?: Maybe<TradeTradeGroup>;
  /** insert data into the table: "zoho_consumer" */
  insertZohoConsumer?: Maybe<ZohoConsumerMutationResponse>;
  /** insert a single row into the table: "zoho_consumer" */
  insertZohoConsumerOne?: Maybe<ZohoConsumer>;
  /** insert data into the table: "zoho_organization" */
  insertZohoOrganization?: Maybe<ZohoOrganizationMutationResponse>;
  /** insert a single row into the table: "zoho_organization" */
  insertZohoOrganizationOne?: Maybe<ZohoOrganization>;
  /** insert data into the table: "zoho_token" */
  insertZohoToken?: Maybe<ZohoTokenMutationResponse>;
  /** insert a single row into the table: "zoho_token" */
  insertZohoTokenOne?: Maybe<ZohoToken>;
  /** update data of the table: "account" */
  updateAccount?: Maybe<AccountMutationResponse>;
  /** update single row of the table: "account" */
  updateAccountByPk?: Maybe<Account>;
  /** update data of the table: "account_device" */
  updateAccountDevice?: Maybe<AccountDeviceMutationResponse>;
  /** update single row of the table: "account_device" */
  updateAccountDeviceByPk?: Maybe<AccountDevice>;
  /** update multiples rows of table: "account_device" */
  updateAccountDeviceMany?: Maybe<Array<Maybe<AccountDeviceMutationResponse>>>;
  /** update multiples rows of table: "account" */
  updateAccountMany?: Maybe<Array<Maybe<AccountMutationResponse>>>;
  /** update data of the table: "account_pro_view" */
  updateAccountProView?: Maybe<AccountProViewMutationResponse>;
  /** update single row of the table: "account_pro_view" */
  updateAccountProViewByPk?: Maybe<AccountProView>;
  /** update multiples rows of table: "account_pro_view" */
  updateAccountProViewMany?: Maybe<Array<Maybe<AccountProViewMutationResponse>>>;
  /** update data of the table: "action_token" */
  updateActionToken?: Maybe<ActionTokenMutationResponse>;
  /** update single row of the table: "action_token" */
  updateActionTokenByPk?: Maybe<ActionToken>;
  /** update multiples rows of table: "action_token" */
  updateActionTokenMany?: Maybe<Array<Maybe<ActionTokenMutationResponse>>>;
  /** update data of the table: "autorespond" */
  updateAutorespond?: Maybe<AutorespondMutationResponse>;
  /** update single row of the table: "autorespond" */
  updateAutorespondByPk?: Maybe<Autorespond>;
  /** update multiples rows of table: "autorespond" */
  updateAutorespondMany?: Maybe<Array<Maybe<AutorespondMutationResponse>>>;
  /** update data of the table: "bilik_person" */
  updateBilikPerson?: Maybe<BilikPersonMutationResponse>;
  /** update single row of the table: "bilik_person" */
  updateBilikPersonByPk?: Maybe<BilikPerson>;
  /** update multiples rows of table: "bilik_person" */
  updateBilikPersonMany?: Maybe<Array<Maybe<BilikPersonMutationResponse>>>;
  /** update data of the table: "bilik_solicitation" */
  updateBilikSolicitation?: Maybe<BilikSolicitationMutationResponse>;
  /** update single row of the table: "bilik_solicitation" */
  updateBilikSolicitationByPk?: Maybe<BilikSolicitation>;
  /** update multiples rows of table: "bilik_solicitation" */
  updateBilikSolicitationMany?: Maybe<Array<Maybe<BilikSolicitationMutationResponse>>>;
  /** update data of the table: "bilik_zone" */
  updateBilikZone?: Maybe<BilikZoneMutationResponse>;
  /** update single row of the table: "bilik_zone" */
  updateBilikZoneByPk?: Maybe<BilikZone>;
  /** update multiples rows of table: "bilik_zone" */
  updateBilikZoneMany?: Maybe<Array<Maybe<BilikZoneMutationResponse>>>;
  /** update data of the table: "certification" */
  updateCertification?: Maybe<CertificationMutationResponse>;
  /** update single row of the table: "certification" */
  updateCertificationByPk?: Maybe<Certification>;
  /** update multiples rows of table: "certification" */
  updateCertificationMany?: Maybe<Array<Maybe<CertificationMutationResponse>>>;
  /** update data of the table: "city" */
  updateCity?: Maybe<CityMutationResponse>;
  /** update single row of the table: "city" */
  updateCityByPk?: Maybe<City>;
  /** update multiples rows of table: "city" */
  updateCityMany?: Maybe<Array<Maybe<CityMutationResponse>>>;
  /** update data of the table: "city_pro_view" */
  updateCityProView?: Maybe<CityProViewMutationResponse>;
  /** update single row of the table: "city_pro_view" */
  updateCityProViewByPk?: Maybe<CityProView>;
  /** update multiples rows of table: "city_pro_view" */
  updateCityProViewMany?: Maybe<Array<Maybe<CityProViewMutationResponse>>>;
  /** update data of the table: "device" */
  updateDevice?: Maybe<DeviceMutationResponse>;
  /** update single row of the table: "device" */
  updateDeviceByPk?: Maybe<Device>;
  /** update multiples rows of table: "device" */
  updateDeviceMany?: Maybe<Array<Maybe<DeviceMutationResponse>>>;
  /** update data of the table: "geometry_columns" */
  updateGeometryColumns?: Maybe<GeometryColumnsMutationResponse>;
  /** update multiples rows of table: "geometry_columns" */
  updateGeometryColumnsMany?: Maybe<Array<Maybe<GeometryColumnsMutationResponse>>>;
  /** update data of the table: "globally_blocked_identity" */
  updateGloballyBlockedIdentity?: Maybe<GloballyBlockedIdentityMutationResponse>;
  /** update single row of the table: "globally_blocked_identity" */
  updateGloballyBlockedIdentityByPk?: Maybe<GloballyBlockedIdentity>;
  /** update multiples rows of table: "globally_blocked_identity" */
  updateGloballyBlockedIdentityMany?: Maybe<Array<Maybe<GloballyBlockedIdentityMutationResponse>>>;
  /** update data of the table: "google_review_link" */
  updateGoogleReviewLink?: Maybe<GoogleReviewLinkMutationResponse>;
  /** update single row of the table: "google_review_link" */
  updateGoogleReviewLinkByPk?: Maybe<GoogleReviewLink>;
  /** update multiples rows of table: "google_review_link" */
  updateGoogleReviewLinkMany?: Maybe<Array<Maybe<GoogleReviewLinkMutationResponse>>>;
  /** update data of the table: "manager_bilik_person_bilik_zone" */
  updateManagerBilikPersonBilikZone?: Maybe<ManagerBilikPersonBilikZoneMutationResponse>;
  /** update single row of the table: "manager_bilik_person_bilik_zone" */
  updateManagerBilikPersonBilikZoneByPk?: Maybe<ManagerBilikPersonBilikZone>;
  /** update multiples rows of table: "manager_bilik_person_bilik_zone" */
  updateManagerBilikPersonBilikZoneMany?: Maybe<Array<Maybe<ManagerBilikPersonBilikZoneMutationResponse>>>;
  /** update data of the table: "member_bilik_person_bilik_zone" */
  updateMemberBilikPersonBilikZone?: Maybe<MemberBilikPersonBilikZoneMutationResponse>;
  /** update single row of the table: "member_bilik_person_bilik_zone" */
  updateMemberBilikPersonBilikZoneByPk?: Maybe<MemberBilikPersonBilikZone>;
  /** update multiples rows of table: "member_bilik_person_bilik_zone" */
  updateMemberBilikPersonBilikZoneMany?: Maybe<Array<Maybe<MemberBilikPersonBilikZoneMutationResponse>>>;
  /** update data of the table: "note_identity" */
  updateNoteIdentity?: Maybe<NoteIdentityMutationResponse>;
  /** update single row of the table: "note_identity" */
  updateNoteIdentityByPk?: Maybe<NoteIdentity>;
  /** update multiples rows of table: "note_identity" */
  updateNoteIdentityMany?: Maybe<Array<Maybe<NoteIdentityMutationResponse>>>;
  /** update data of the table: "open_data_city" */
  updateOpenDataCity?: Maybe<OpenDataCityMutationResponse>;
  /** update single row of the table: "open_data_city" */
  updateOpenDataCityByPk?: Maybe<OpenDataCity>;
  /** update multiples rows of table: "open_data_city" */
  updateOpenDataCityMany?: Maybe<Array<Maybe<OpenDataCityMutationResponse>>>;
  /** update data of the table: "open_data_department" */
  updateOpenDataDepartment?: Maybe<OpenDataDepartmentMutationResponse>;
  /** update single row of the table: "open_data_department" */
  updateOpenDataDepartmentByPk?: Maybe<OpenDataDepartment>;
  /** update multiples rows of table: "open_data_department" */
  updateOpenDataDepartmentMany?: Maybe<Array<Maybe<OpenDataDepartmentMutationResponse>>>;
  /** update data of the table: "open_data_region" */
  updateOpenDataRegion?: Maybe<OpenDataRegionMutationResponse>;
  /** update single row of the table: "open_data_region" */
  updateOpenDataRegionByPk?: Maybe<OpenDataRegion>;
  /** update multiples rows of table: "open_data_region" */
  updateOpenDataRegionMany?: Maybe<Array<Maybe<OpenDataRegionMutationResponse>>>;
  /** update data of the table: "pro_certification" */
  updateProCertification?: Maybe<ProCertificationMutationResponse>;
  /** update single row of the table: "pro_certification" */
  updateProCertificationByPk?: Maybe<ProCertification>;
  /** update multiples rows of table: "pro_certification" */
  updateProCertificationMany?: Maybe<Array<Maybe<ProCertificationMutationResponse>>>;
  /** update data of the table: "pro_holiday" */
  updateProHoliday?: Maybe<ProHolidayMutationResponse>;
  /** update single row of the table: "pro_holiday" */
  updateProHolidayByPk?: Maybe<ProHoliday>;
  /** update multiples rows of table: "pro_holiday" */
  updateProHolidayMany?: Maybe<Array<Maybe<ProHolidayMutationResponse>>>;
  /** update data of the table: "pro_intervention" */
  updateProIntervention?: Maybe<ProInterventionMutationResponse>;
  /** update single row of the table: "pro_intervention" */
  updateProInterventionByPk?: Maybe<ProIntervention>;
  /** update multiples rows of table: "pro_intervention" */
  updateProInterventionMany?: Maybe<Array<Maybe<ProInterventionMutationResponse>>>;
  /** update data of the table: "pro_label" */
  updateProLabel?: Maybe<ProLabelMutationResponse>;
  /** update single row of the table: "pro_label" */
  updateProLabelByPk?: Maybe<ProLabel>;
  /** update multiples rows of table: "pro_label" */
  updateProLabelMany?: Maybe<Array<Maybe<ProLabelMutationResponse>>>;
  /** update data of the table: "pro_mandate" */
  updateProMandate?: Maybe<ProMandateMutationResponse>;
  /** update single row of the table: "pro_mandate" */
  updateProMandateByPk?: Maybe<ProMandate>;
  /** update multiples rows of table: "pro_mandate" */
  updateProMandateMany?: Maybe<Array<Maybe<ProMandateMutationResponse>>>;
  /** update data of the table: "pro_media_object" */
  updateProMediaObject?: Maybe<ProMediaObjectMutationResponse>;
  /** update single row of the table: "pro_media_object" */
  updateProMediaObjectByPk?: Maybe<ProMediaObject>;
  /** update multiples rows of table: "pro_media_object" */
  updateProMediaObjectMany?: Maybe<Array<Maybe<ProMediaObjectMutationResponse>>>;
  /** update data of the table: "pro_message" */
  updateProMessage?: Maybe<ProMessageMutationResponse>;
  /** update single row of the table: "pro_message" */
  updateProMessageByPk?: Maybe<ProMessage>;
  /** update multiples rows of table: "pro_message" */
  updateProMessageMany?: Maybe<Array<Maybe<ProMessageMutationResponse>>>;
  /** update data of the table: "pro_organization" */
  updateProOrganization?: Maybe<ProOrganizationMutationResponse>;
  /** update single row of the table: "pro_organization" */
  updateProOrganizationByPk?: Maybe<ProOrganization>;
  /** update multiples rows of table: "pro_organization" */
  updateProOrganizationMany?: Maybe<Array<Maybe<ProOrganizationMutationResponse>>>;
  /** update data of the table: "pro_person" */
  updateProPerson?: Maybe<ProPersonMutationResponse>;
  /** update single row of the table: "pro_person" */
  updateProPersonByPk?: Maybe<ProPerson>;
  /** update multiples rows of table: "pro_person" */
  updateProPersonMany?: Maybe<Array<Maybe<ProPersonMutationResponse>>>;
  /** update data of the table: "pro_postal_address" */
  updateProPostalAddress?: Maybe<ProPostalAddressMutationResponse>;
  /** update single row of the table: "pro_postal_address" */
  updateProPostalAddressByPk?: Maybe<ProPostalAddress>;
  /** update multiples rows of table: "pro_postal_address" */
  updateProPostalAddressMany?: Maybe<Array<Maybe<ProPostalAddressMutationResponse>>>;
  /** update data of the table: "pro_presentation" */
  updateProPresentation?: Maybe<ProPresentationMutationResponse>;
  /** update single row of the table: "pro_presentation" */
  updateProPresentationByPk?: Maybe<ProPresentation>;
  /** update multiples rows of table: "pro_presentation" */
  updateProPresentationMany?: Maybe<Array<Maybe<ProPresentationMutationResponse>>>;
  /** update data of the table: "pro_price" */
  updateProPrice?: Maybe<ProPriceMutationResponse>;
  /** update single row of the table: "pro_price" */
  updateProPriceByPk?: Maybe<ProPrice>;
  /** update multiples rows of table: "pro_price" */
  updateProPriceMany?: Maybe<Array<Maybe<ProPriceMutationResponse>>>;
  /** update data of the table: "pro_review" */
  updateProReview?: Maybe<ProReviewMutationResponse>;
  /** update single row of the table: "pro_review" */
  updateProReviewByPk?: Maybe<ProReview>;
  /** update data of the table: "pro_review_invitation" */
  updateProReviewInvitation?: Maybe<ProReviewInvitationMutationResponse>;
  /** update single row of the table: "pro_review_invitation" */
  updateProReviewInvitationByPk?: Maybe<ProReviewInvitation>;
  /** update multiples rows of table: "pro_review_invitation" */
  updateProReviewInvitationMany?: Maybe<Array<Maybe<ProReviewInvitationMutationResponse>>>;
  /** update multiples rows of table: "pro_review" */
  updateProReviewMany?: Maybe<Array<Maybe<ProReviewMutationResponse>>>;
  /** update data of the table: "pro_view" */
  updateProView?: Maybe<ProViewMutationResponse>;
  /** update single row of the table: "pro_view" */
  updateProViewByPk?: Maybe<ProView>;
  /** update multiples rows of table: "pro_view" */
  updateProViewMany?: Maybe<Array<Maybe<ProViewMutationResponse>>>;
  /** update data of the table: "pro_view_pro_person" */
  updateProViewProPerson?: Maybe<ProViewProPersonMutationResponse>;
  /** update single row of the table: "pro_view_pro_person" */
  updateProViewProPersonByPk?: Maybe<ProViewProPerson>;
  /** update multiples rows of table: "pro_view_pro_person" */
  updateProViewProPersonMany?: Maybe<Array<Maybe<ProViewProPersonMutationResponse>>>;
  /** update data of the table: "pro_view_pro_view" */
  updateProViewProView?: Maybe<ProViewProViewMutationResponse>;
  /** update single row of the table: "pro_view_pro_view" */
  updateProViewProViewByPk?: Maybe<ProViewProView>;
  /** update multiples rows of table: "pro_view_pro_view" */
  updateProViewProViewMany?: Maybe<Array<Maybe<ProViewProViewMutationResponse>>>;
  /** update data of the table: "queued_sms" */
  updateQueuedSms?: Maybe<QueuedSmsMutationResponse>;
  /** update single row of the table: "queued_sms" */
  updateQueuedSmsByPk?: Maybe<QueuedSms>;
  /** update multiples rows of table: "queued_sms" */
  updateQueuedSmsMany?: Maybe<Array<Maybe<QueuedSmsMutationResponse>>>;
  /** update data of the table: "slug_redirect" */
  updateSlugRedirect?: Maybe<SlugRedirectMutationResponse>;
  /** update single row of the table: "slug_redirect" */
  updateSlugRedirectByPk?: Maybe<SlugRedirect>;
  /** update multiples rows of table: "slug_redirect" */
  updateSlugRedirectMany?: Maybe<Array<Maybe<SlugRedirectMutationResponse>>>;
  /** update data of the table: "solicitation" */
  updateSolicitation?: Maybe<SolicitationMutationResponse>;
  /** update single row of the table: "solicitation" */
  updateSolicitationByPk?: Maybe<Solicitation>;
  /** update multiples rows of table: "solicitation" */
  updateSolicitationMany?: Maybe<Array<Maybe<SolicitationMutationResponse>>>;
  /** update data of the table: "spatial_ref_sys" */
  updateSpatialRefSys?: Maybe<SpatialRefSysMutationResponse>;
  /** update single row of the table: "spatial_ref_sys" */
  updateSpatialRefSysByPk?: Maybe<SpatialRefSys>;
  /** update multiples rows of table: "spatial_ref_sys" */
  updateSpatialRefSysMany?: Maybe<Array<Maybe<SpatialRefSysMutationResponse>>>;
  /** update data of the table: "stop_sms" */
  updateStopSms?: Maybe<StopSmsMutationResponse>;
  /** update single row of the table: "stop_sms" */
  updateStopSmsByPk?: Maybe<StopSms>;
  /** update multiples rows of table: "stop_sms" */
  updateStopSmsMany?: Maybe<Array<Maybe<StopSmsMutationResponse>>>;
  /** update data of the table: "topology.layer" */
  updateTopologyLayer?: Maybe<TopologyLayerMutationResponse>;
  /** update single row of the table: "topology.layer" */
  updateTopologyLayerByPk?: Maybe<TopologyLayer>;
  /** update multiples rows of table: "topology.layer" */
  updateTopologyLayerMany?: Maybe<Array<Maybe<TopologyLayerMutationResponse>>>;
  /** update data of the table: "topology.topology" */
  updateTopologyTopology?: Maybe<TopologyTopologyMutationResponse>;
  /** update single row of the table: "topology.topology" */
  updateTopologyTopologyByPk?: Maybe<TopologyTopology>;
  /** update multiples rows of table: "topology.topology" */
  updateTopologyTopologyMany?: Maybe<Array<Maybe<TopologyTopologyMutationResponse>>>;
  /** update data of the table: "trade" */
  updateTrade?: Maybe<TradeMutationResponse>;
  /** update single row of the table: "trade" */
  updateTradeByPk?: Maybe<Trade>;
  /** update data of the table: "trade_group" */
  updateTradeGroup?: Maybe<TradeGroupMutationResponse>;
  /** update single row of the table: "trade_group" */
  updateTradeGroupByPk?: Maybe<TradeGroup>;
  /** update multiples rows of table: "trade_group" */
  updateTradeGroupMany?: Maybe<Array<Maybe<TradeGroupMutationResponse>>>;
  /** update data of the table: "trade_guide" */
  updateTradeGuide?: Maybe<TradeGuideMutationResponse>;
  /** update single row of the table: "trade_guide" */
  updateTradeGuideByPk?: Maybe<TradeGuide>;
  /** update multiples rows of table: "trade_guide" */
  updateTradeGuideMany?: Maybe<Array<Maybe<TradeGuideMutationResponse>>>;
  /** update data of the table: "trade_keyword" */
  updateTradeKeyword?: Maybe<TradeKeywordMutationResponse>;
  /** update single row of the table: "trade_keyword" */
  updateTradeKeywordByPk?: Maybe<TradeKeyword>;
  /** update multiples rows of table: "trade_keyword" */
  updateTradeKeywordMany?: Maybe<Array<Maybe<TradeKeywordMutationResponse>>>;
  /** update multiples rows of table: "trade" */
  updateTradeMany?: Maybe<Array<Maybe<TradeMutationResponse>>>;
  /** update data of the table: "trade_pro_view" */
  updateTradeProView?: Maybe<TradeProViewMutationResponse>;
  /** update single row of the table: "trade_pro_view" */
  updateTradeProViewByPk?: Maybe<TradeProView>;
  /** update multiples rows of table: "trade_pro_view" */
  updateTradeProViewMany?: Maybe<Array<Maybe<TradeProViewMutationResponse>>>;
  /** update data of the table: "trade_question" */
  updateTradeQuestion?: Maybe<TradeQuestionMutationResponse>;
  /** update single row of the table: "trade_question" */
  updateTradeQuestionByPk?: Maybe<TradeQuestion>;
  /** update multiples rows of table: "trade_question" */
  updateTradeQuestionMany?: Maybe<Array<Maybe<TradeQuestionMutationResponse>>>;
  /** update data of the table: "trade_trade_group" */
  updateTradeTradeGroup?: Maybe<TradeTradeGroupMutationResponse>;
  /** update single row of the table: "trade_trade_group" */
  updateTradeTradeGroupByPk?: Maybe<TradeTradeGroup>;
  /** update multiples rows of table: "trade_trade_group" */
  updateTradeTradeGroupMany?: Maybe<Array<Maybe<TradeTradeGroupMutationResponse>>>;
  /** update data of the table: "zoho_consumer" */
  updateZohoConsumer?: Maybe<ZohoConsumerMutationResponse>;
  /** update single row of the table: "zoho_consumer" */
  updateZohoConsumerByPk?: Maybe<ZohoConsumer>;
  /** update multiples rows of table: "zoho_consumer" */
  updateZohoConsumerMany?: Maybe<Array<Maybe<ZohoConsumerMutationResponse>>>;
  /** update data of the table: "zoho_organization" */
  updateZohoOrganization?: Maybe<ZohoOrganizationMutationResponse>;
  /** update single row of the table: "zoho_organization" */
  updateZohoOrganizationByPk?: Maybe<ZohoOrganization>;
  /** update multiples rows of table: "zoho_organization" */
  updateZohoOrganizationMany?: Maybe<Array<Maybe<ZohoOrganizationMutationResponse>>>;
  /** update data of the table: "zoho_token" */
  updateZohoToken?: Maybe<ZohoTokenMutationResponse>;
  /** update single row of the table: "zoho_token" */
  updateZohoTokenByPk?: Maybe<ZohoToken>;
  /** update multiples rows of table: "zoho_token" */
  updateZohoTokenMany?: Maybe<Array<Maybe<ZohoTokenMutationResponse>>>;
};


/** mutation root */
export type MutationRootDeleteAccountArgs = {
  where: AccountBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteAccountDeviceArgs = {
  where: AccountDeviceBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountDeviceByPkArgs = {
  accountId: Scalars['Int'];
  deviceId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteAccountProViewArgs = {
  where: AccountProViewBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccountProViewByPkArgs = {
  accountId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteActionTokenArgs = {
  where: ActionTokenBoolExp;
};


/** mutation root */
export type MutationRootDeleteActionTokenByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteAutorespondArgs = {
  where: AutorespondBoolExp;
};


/** mutation root */
export type MutationRootDeleteAutorespondByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteBilikPersonArgs = {
  where: BilikPersonBoolExp;
};


/** mutation root */
export type MutationRootDeleteBilikPersonByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteBilikSolicitationArgs = {
  where: BilikSolicitationBoolExp;
};


/** mutation root */
export type MutationRootDeleteBilikSolicitationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteBilikZoneArgs = {
  where: BilikZoneBoolExp;
};


/** mutation root */
export type MutationRootDeleteBilikZoneByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteCertificationArgs = {
  where: CertificationBoolExp;
};


/** mutation root */
export type MutationRootDeleteCertificationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteCityArgs = {
  where: CityBoolExp;
};


/** mutation root */
export type MutationRootDeleteCityByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteCityProViewArgs = {
  where: CityProViewBoolExp;
};


/** mutation root */
export type MutationRootDeleteCityProViewByPkArgs = {
  cityId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteDeviceArgs = {
  where: DeviceBoolExp;
};


/** mutation root */
export type MutationRootDeleteDeviceByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteGeometryColumnsArgs = {
  where: GeometryColumnsBoolExp;
};


/** mutation root */
export type MutationRootDeleteGloballyBlockedIdentityArgs = {
  where: GloballyBlockedIdentityBoolExp;
};


/** mutation root */
export type MutationRootDeleteGloballyBlockedIdentityByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteGoogleReviewLinkArgs = {
  where: GoogleReviewLinkBoolExp;
};


/** mutation root */
export type MutationRootDeleteGoogleReviewLinkByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteManagerBilikPersonBilikZoneArgs = {
  where: ManagerBilikPersonBilikZoneBoolExp;
};


/** mutation root */
export type MutationRootDeleteManagerBilikPersonBilikZoneByPkArgs = {
  bilikPersonId: Scalars['Int'];
  bilikZoneId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteMemberBilikPersonBilikZoneArgs = {
  where: MemberBilikPersonBilikZoneBoolExp;
};


/** mutation root */
export type MutationRootDeleteMemberBilikPersonBilikZoneByPkArgs = {
  bilikPersonId: Scalars['Int'];
  bilikZoneId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteNoteIdentityArgs = {
  where: NoteIdentityBoolExp;
};


/** mutation root */
export type MutationRootDeleteNoteIdentityByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteOpenDataCityArgs = {
  where: OpenDataCityBoolExp;
};


/** mutation root */
export type MutationRootDeleteOpenDataCityByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteOpenDataDepartmentArgs = {
  where: OpenDataDepartmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteOpenDataDepartmentByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteOpenDataRegionArgs = {
  where: OpenDataRegionBoolExp;
};


/** mutation root */
export type MutationRootDeleteOpenDataRegionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProCertificationArgs = {
  where: ProCertificationBoolExp;
};


/** mutation root */
export type MutationRootDeleteProCertificationByPkArgs = {
  certificationId: Scalars['Int'];
  proPresentationId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProHolidayArgs = {
  where: ProHolidayBoolExp;
};


/** mutation root */
export type MutationRootDeleteProHolidayByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProInterventionArgs = {
  where: ProInterventionBoolExp;
};


/** mutation root */
export type MutationRootDeleteProInterventionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProLabelArgs = {
  where: ProLabelBoolExp;
};


/** mutation root */
export type MutationRootDeleteProLabelByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProMandateArgs = {
  where: ProMandateBoolExp;
};


/** mutation root */
export type MutationRootDeleteProMandateByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProMediaObjectArgs = {
  where: ProMediaObjectBoolExp;
};


/** mutation root */
export type MutationRootDeleteProMediaObjectByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProMessageArgs = {
  where: ProMessageBoolExp;
};


/** mutation root */
export type MutationRootDeleteProMessageByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProOrganizationArgs = {
  where: ProOrganizationBoolExp;
};


/** mutation root */
export type MutationRootDeleteProOrganizationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProPersonArgs = {
  where: ProPersonBoolExp;
};


/** mutation root */
export type MutationRootDeleteProPersonByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProPostalAddressArgs = {
  where: ProPostalAddressBoolExp;
};


/** mutation root */
export type MutationRootDeleteProPostalAddressByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProPresentationArgs = {
  where: ProPresentationBoolExp;
};


/** mutation root */
export type MutationRootDeleteProPresentationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProPriceArgs = {
  where: ProPriceBoolExp;
};


/** mutation root */
export type MutationRootDeleteProPriceByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProReviewArgs = {
  where: ProReviewBoolExp;
};


/** mutation root */
export type MutationRootDeleteProReviewByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProReviewInvitationArgs = {
  where: ProReviewInvitationBoolExp;
};


/** mutation root */
export type MutationRootDeleteProReviewInvitationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProViewArgs = {
  where: ProViewBoolExp;
};


/** mutation root */
export type MutationRootDeleteProViewByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProViewProPersonArgs = {
  where: ProViewProPersonBoolExp;
};


/** mutation root */
export type MutationRootDeleteProViewProPersonByPkArgs = {
  proPersonId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteProViewProViewArgs = {
  where: ProViewProViewBoolExp;
};


/** mutation root */
export type MutationRootDeleteProViewProViewByPkArgs = {
  proViewSource: Scalars['Int'];
  proViewTarget: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteQueuedSmsArgs = {
  where: QueuedSmsBoolExp;
};


/** mutation root */
export type MutationRootDeleteQueuedSmsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteSlugRedirectArgs = {
  where: SlugRedirectBoolExp;
};


/** mutation root */
export type MutationRootDeleteSlugRedirectByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteSolicitationArgs = {
  where: SolicitationBoolExp;
};


/** mutation root */
export type MutationRootDeleteSolicitationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteSpatialRefSysArgs = {
  where: SpatialRefSysBoolExp;
};


/** mutation root */
export type MutationRootDeleteSpatialRefSysByPkArgs = {
  srid: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteStopSmsArgs = {
  where: StopSmsBoolExp;
};


/** mutation root */
export type MutationRootDeleteStopSmsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTopologyLayerArgs = {
  where: TopologyLayerBoolExp;
};


/** mutation root */
export type MutationRootDeleteTopologyLayerByPkArgs = {
  layerId: Scalars['Int'];
  topologyId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTopologyTopologyArgs = {
  where: TopologyTopologyBoolExp;
};


/** mutation root */
export type MutationRootDeleteTopologyTopologyByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTradeArgs = {
  where: TradeBoolExp;
};


/** mutation root */
export type MutationRootDeleteTradeByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTradeGroupArgs = {
  where: TradeGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteTradeGroupByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTradeGuideArgs = {
  where: TradeGuideBoolExp;
};


/** mutation root */
export type MutationRootDeleteTradeGuideByPkArgs = {
  firstTradeId: Scalars['Int'];
  secondTradeId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTradeKeywordArgs = {
  where: TradeKeywordBoolExp;
};


/** mutation root */
export type MutationRootDeleteTradeKeywordByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTradeProViewArgs = {
  where: TradeProViewBoolExp;
};


/** mutation root */
export type MutationRootDeleteTradeProViewByPkArgs = {
  proViewId: Scalars['Int'];
  tradeId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTradeQuestionArgs = {
  where: TradeQuestionBoolExp;
};


/** mutation root */
export type MutationRootDeleteTradeQuestionByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteTradeTradeGroupArgs = {
  where: TradeTradeGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteTradeTradeGroupByPkArgs = {
  tradeGroupId: Scalars['Int'];
  tradeId: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteZohoConsumerArgs = {
  where: ZohoConsumerBoolExp;
};


/** mutation root */
export type MutationRootDeleteZohoConsumerByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteZohoOrganizationArgs = {
  where: ZohoOrganizationBoolExp;
};


/** mutation root */
export type MutationRootDeleteZohoOrganizationByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootDeleteZohoTokenArgs = {
  where: ZohoTokenBoolExp;
};


/** mutation root */
export type MutationRootDeleteZohoTokenByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type MutationRootInsertAccountArgs = {
  objects: Array<AccountInsertInput>;
  onConflict?: Maybe<AccountOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountDeviceArgs = {
  objects: Array<AccountDeviceInsertInput>;
  onConflict?: Maybe<AccountDeviceOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountDeviceOneArgs = {
  object: AccountDeviceInsertInput;
  onConflict?: Maybe<AccountDeviceOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountOneArgs = {
  object: AccountInsertInput;
  onConflict?: Maybe<AccountOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountProViewArgs = {
  objects: Array<AccountProViewInsertInput>;
  onConflict?: Maybe<AccountProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccountProViewOneArgs = {
  object: AccountProViewInsertInput;
  onConflict?: Maybe<AccountProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionTokenArgs = {
  objects: Array<ActionTokenInsertInput>;
  onConflict?: Maybe<ActionTokenOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionTokenOneArgs = {
  object: ActionTokenInsertInput;
  onConflict?: Maybe<ActionTokenOnConflict>;
};


/** mutation root */
export type MutationRootInsertAutorespondArgs = {
  objects: Array<AutorespondInsertInput>;
  onConflict?: Maybe<AutorespondOnConflict>;
};


/** mutation root */
export type MutationRootInsertAutorespondOneArgs = {
  object: AutorespondInsertInput;
  onConflict?: Maybe<AutorespondOnConflict>;
};


/** mutation root */
export type MutationRootInsertBilikPersonArgs = {
  objects: Array<BilikPersonInsertInput>;
  onConflict?: Maybe<BilikPersonOnConflict>;
};


/** mutation root */
export type MutationRootInsertBilikPersonOneArgs = {
  object: BilikPersonInsertInput;
  onConflict?: Maybe<BilikPersonOnConflict>;
};


/** mutation root */
export type MutationRootInsertBilikSolicitationArgs = {
  objects: Array<BilikSolicitationInsertInput>;
  onConflict?: Maybe<BilikSolicitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertBilikSolicitationOneArgs = {
  object: BilikSolicitationInsertInput;
  onConflict?: Maybe<BilikSolicitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertBilikZoneArgs = {
  objects: Array<BilikZoneInsertInput>;
  onConflict?: Maybe<BilikZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertBilikZoneOneArgs = {
  object: BilikZoneInsertInput;
  onConflict?: Maybe<BilikZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertCertificationArgs = {
  objects: Array<CertificationInsertInput>;
  onConflict?: Maybe<CertificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCertificationOneArgs = {
  object: CertificationInsertInput;
  onConflict?: Maybe<CertificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCityArgs = {
  objects: Array<CityInsertInput>;
  onConflict?: Maybe<CityOnConflict>;
};


/** mutation root */
export type MutationRootInsertCityOneArgs = {
  object: CityInsertInput;
  onConflict?: Maybe<CityOnConflict>;
};


/** mutation root */
export type MutationRootInsertCityProViewArgs = {
  objects: Array<CityProViewInsertInput>;
  onConflict?: Maybe<CityProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertCityProViewOneArgs = {
  object: CityProViewInsertInput;
  onConflict?: Maybe<CityProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertDeviceArgs = {
  objects: Array<DeviceInsertInput>;
  onConflict?: Maybe<DeviceOnConflict>;
};


/** mutation root */
export type MutationRootInsertDeviceOneArgs = {
  object: DeviceInsertInput;
  onConflict?: Maybe<DeviceOnConflict>;
};


/** mutation root */
export type MutationRootInsertGeometryColumnsArgs = {
  objects: Array<GeometryColumnsInsertInput>;
};


/** mutation root */
export type MutationRootInsertGeometryColumnsOneArgs = {
  object: GeometryColumnsInsertInput;
};


/** mutation root */
export type MutationRootInsertGloballyBlockedIdentityArgs = {
  objects: Array<GloballyBlockedIdentityInsertInput>;
  onConflict?: Maybe<GloballyBlockedIdentityOnConflict>;
};


/** mutation root */
export type MutationRootInsertGloballyBlockedIdentityOneArgs = {
  object: GloballyBlockedIdentityInsertInput;
  onConflict?: Maybe<GloballyBlockedIdentityOnConflict>;
};


/** mutation root */
export type MutationRootInsertGoogleReviewLinkArgs = {
  objects: Array<GoogleReviewLinkInsertInput>;
  onConflict?: Maybe<GoogleReviewLinkOnConflict>;
};


/** mutation root */
export type MutationRootInsertGoogleReviewLinkOneArgs = {
  object: GoogleReviewLinkInsertInput;
  onConflict?: Maybe<GoogleReviewLinkOnConflict>;
};


/** mutation root */
export type MutationRootInsertManagerBilikPersonBilikZoneArgs = {
  objects: Array<ManagerBilikPersonBilikZoneInsertInput>;
  onConflict?: Maybe<ManagerBilikPersonBilikZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertManagerBilikPersonBilikZoneOneArgs = {
  object: ManagerBilikPersonBilikZoneInsertInput;
  onConflict?: Maybe<ManagerBilikPersonBilikZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertMemberBilikPersonBilikZoneArgs = {
  objects: Array<MemberBilikPersonBilikZoneInsertInput>;
  onConflict?: Maybe<MemberBilikPersonBilikZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertMemberBilikPersonBilikZoneOneArgs = {
  object: MemberBilikPersonBilikZoneInsertInput;
  onConflict?: Maybe<MemberBilikPersonBilikZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertNoteIdentityArgs = {
  objects: Array<NoteIdentityInsertInput>;
  onConflict?: Maybe<NoteIdentityOnConflict>;
};


/** mutation root */
export type MutationRootInsertNoteIdentityOneArgs = {
  object: NoteIdentityInsertInput;
  onConflict?: Maybe<NoteIdentityOnConflict>;
};


/** mutation root */
export type MutationRootInsertOpenDataCityArgs = {
  objects: Array<OpenDataCityInsertInput>;
  onConflict?: Maybe<OpenDataCityOnConflict>;
};


/** mutation root */
export type MutationRootInsertOpenDataCityOneArgs = {
  object: OpenDataCityInsertInput;
  onConflict?: Maybe<OpenDataCityOnConflict>;
};


/** mutation root */
export type MutationRootInsertOpenDataDepartmentArgs = {
  objects: Array<OpenDataDepartmentInsertInput>;
  onConflict?: Maybe<OpenDataDepartmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertOpenDataDepartmentOneArgs = {
  object: OpenDataDepartmentInsertInput;
  onConflict?: Maybe<OpenDataDepartmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertOpenDataRegionArgs = {
  objects: Array<OpenDataRegionInsertInput>;
  onConflict?: Maybe<OpenDataRegionOnConflict>;
};


/** mutation root */
export type MutationRootInsertOpenDataRegionOneArgs = {
  object: OpenDataRegionInsertInput;
  onConflict?: Maybe<OpenDataRegionOnConflict>;
};


/** mutation root */
export type MutationRootInsertProCertificationArgs = {
  objects: Array<ProCertificationInsertInput>;
  onConflict?: Maybe<ProCertificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertProCertificationOneArgs = {
  object: ProCertificationInsertInput;
  onConflict?: Maybe<ProCertificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertProHolidayArgs = {
  objects: Array<ProHolidayInsertInput>;
  onConflict?: Maybe<ProHolidayOnConflict>;
};


/** mutation root */
export type MutationRootInsertProHolidayOneArgs = {
  object: ProHolidayInsertInput;
  onConflict?: Maybe<ProHolidayOnConflict>;
};


/** mutation root */
export type MutationRootInsertProInterventionArgs = {
  objects: Array<ProInterventionInsertInput>;
  onConflict?: Maybe<ProInterventionOnConflict>;
};


/** mutation root */
export type MutationRootInsertProInterventionOneArgs = {
  object: ProInterventionInsertInput;
  onConflict?: Maybe<ProInterventionOnConflict>;
};


/** mutation root */
export type MutationRootInsertProLabelArgs = {
  objects: Array<ProLabelInsertInput>;
  onConflict?: Maybe<ProLabelOnConflict>;
};


/** mutation root */
export type MutationRootInsertProLabelOneArgs = {
  object: ProLabelInsertInput;
  onConflict?: Maybe<ProLabelOnConflict>;
};


/** mutation root */
export type MutationRootInsertProMandateArgs = {
  objects: Array<ProMandateInsertInput>;
  onConflict?: Maybe<ProMandateOnConflict>;
};


/** mutation root */
export type MutationRootInsertProMandateOneArgs = {
  object: ProMandateInsertInput;
  onConflict?: Maybe<ProMandateOnConflict>;
};


/** mutation root */
export type MutationRootInsertProMediaObjectArgs = {
  objects: Array<ProMediaObjectInsertInput>;
  onConflict?: Maybe<ProMediaObjectOnConflict>;
};


/** mutation root */
export type MutationRootInsertProMediaObjectOneArgs = {
  object: ProMediaObjectInsertInput;
  onConflict?: Maybe<ProMediaObjectOnConflict>;
};


/** mutation root */
export type MutationRootInsertProMessageArgs = {
  objects: Array<ProMessageInsertInput>;
  onConflict?: Maybe<ProMessageOnConflict>;
};


/** mutation root */
export type MutationRootInsertProMessageOneArgs = {
  object: ProMessageInsertInput;
  onConflict?: Maybe<ProMessageOnConflict>;
};


/** mutation root */
export type MutationRootInsertProOrganizationArgs = {
  objects: Array<ProOrganizationInsertInput>;
  onConflict?: Maybe<ProOrganizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertProOrganizationOneArgs = {
  object: ProOrganizationInsertInput;
  onConflict?: Maybe<ProOrganizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertProPersonArgs = {
  objects: Array<ProPersonInsertInput>;
  onConflict?: Maybe<ProPersonOnConflict>;
};


/** mutation root */
export type MutationRootInsertProPersonOneArgs = {
  object: ProPersonInsertInput;
  onConflict?: Maybe<ProPersonOnConflict>;
};


/** mutation root */
export type MutationRootInsertProPostalAddressArgs = {
  objects: Array<ProPostalAddressInsertInput>;
  onConflict?: Maybe<ProPostalAddressOnConflict>;
};


/** mutation root */
export type MutationRootInsertProPostalAddressOneArgs = {
  object: ProPostalAddressInsertInput;
  onConflict?: Maybe<ProPostalAddressOnConflict>;
};


/** mutation root */
export type MutationRootInsertProPresentationArgs = {
  objects: Array<ProPresentationInsertInput>;
  onConflict?: Maybe<ProPresentationOnConflict>;
};


/** mutation root */
export type MutationRootInsertProPresentationOneArgs = {
  object: ProPresentationInsertInput;
  onConflict?: Maybe<ProPresentationOnConflict>;
};


/** mutation root */
export type MutationRootInsertProPriceArgs = {
  objects: Array<ProPriceInsertInput>;
  onConflict?: Maybe<ProPriceOnConflict>;
};


/** mutation root */
export type MutationRootInsertProPriceOneArgs = {
  object: ProPriceInsertInput;
  onConflict?: Maybe<ProPriceOnConflict>;
};


/** mutation root */
export type MutationRootInsertProReviewArgs = {
  objects: Array<ProReviewInsertInput>;
  onConflict?: Maybe<ProReviewOnConflict>;
};


/** mutation root */
export type MutationRootInsertProReviewInvitationArgs = {
  objects: Array<ProReviewInvitationInsertInput>;
  onConflict?: Maybe<ProReviewInvitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertProReviewInvitationOneArgs = {
  object: ProReviewInvitationInsertInput;
  onConflict?: Maybe<ProReviewInvitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertProReviewOneArgs = {
  object: ProReviewInsertInput;
  onConflict?: Maybe<ProReviewOnConflict>;
};


/** mutation root */
export type MutationRootInsertProViewArgs = {
  objects: Array<ProViewInsertInput>;
  onConflict?: Maybe<ProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertProViewOneArgs = {
  object: ProViewInsertInput;
  onConflict?: Maybe<ProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertProViewProPersonArgs = {
  objects: Array<ProViewProPersonInsertInput>;
  onConflict?: Maybe<ProViewProPersonOnConflict>;
};


/** mutation root */
export type MutationRootInsertProViewProPersonOneArgs = {
  object: ProViewProPersonInsertInput;
  onConflict?: Maybe<ProViewProPersonOnConflict>;
};


/** mutation root */
export type MutationRootInsertProViewProViewArgs = {
  objects: Array<ProViewProViewInsertInput>;
  onConflict?: Maybe<ProViewProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertProViewProViewOneArgs = {
  object: ProViewProViewInsertInput;
  onConflict?: Maybe<ProViewProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertQueuedSmsArgs = {
  objects: Array<QueuedSmsInsertInput>;
  onConflict?: Maybe<QueuedSmsOnConflict>;
};


/** mutation root */
export type MutationRootInsertQueuedSmsOneArgs = {
  object: QueuedSmsInsertInput;
  onConflict?: Maybe<QueuedSmsOnConflict>;
};


/** mutation root */
export type MutationRootInsertSlugRedirectArgs = {
  objects: Array<SlugRedirectInsertInput>;
  onConflict?: Maybe<SlugRedirectOnConflict>;
};


/** mutation root */
export type MutationRootInsertSlugRedirectOneArgs = {
  object: SlugRedirectInsertInput;
  onConflict?: Maybe<SlugRedirectOnConflict>;
};


/** mutation root */
export type MutationRootInsertSolicitationArgs = {
  objects: Array<SolicitationInsertInput>;
  onConflict?: Maybe<SolicitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertSolicitationOneArgs = {
  object: SolicitationInsertInput;
  onConflict?: Maybe<SolicitationOnConflict>;
};


/** mutation root */
export type MutationRootInsertSpatialRefSysArgs = {
  objects: Array<SpatialRefSysInsertInput>;
  onConflict?: Maybe<SpatialRefSysOnConflict>;
};


/** mutation root */
export type MutationRootInsertSpatialRefSysOneArgs = {
  object: SpatialRefSysInsertInput;
  onConflict?: Maybe<SpatialRefSysOnConflict>;
};


/** mutation root */
export type MutationRootInsertStopSmsArgs = {
  objects: Array<StopSmsInsertInput>;
  onConflict?: Maybe<StopSmsOnConflict>;
};


/** mutation root */
export type MutationRootInsertStopSmsOneArgs = {
  object: StopSmsInsertInput;
  onConflict?: Maybe<StopSmsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTopologyLayerArgs = {
  objects: Array<TopologyLayerInsertInput>;
  onConflict?: Maybe<TopologyLayerOnConflict>;
};


/** mutation root */
export type MutationRootInsertTopologyLayerOneArgs = {
  object: TopologyLayerInsertInput;
  onConflict?: Maybe<TopologyLayerOnConflict>;
};


/** mutation root */
export type MutationRootInsertTopologyTopologyArgs = {
  objects: Array<TopologyTopologyInsertInput>;
  onConflict?: Maybe<TopologyTopologyOnConflict>;
};


/** mutation root */
export type MutationRootInsertTopologyTopologyOneArgs = {
  object: TopologyTopologyInsertInput;
  onConflict?: Maybe<TopologyTopologyOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeArgs = {
  objects: Array<TradeInsertInput>;
  onConflict?: Maybe<TradeOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeGroupArgs = {
  objects: Array<TradeGroupInsertInput>;
  onConflict?: Maybe<TradeGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeGroupOneArgs = {
  object: TradeGroupInsertInput;
  onConflict?: Maybe<TradeGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeGuideArgs = {
  objects: Array<TradeGuideInsertInput>;
  onConflict?: Maybe<TradeGuideOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeGuideOneArgs = {
  object: TradeGuideInsertInput;
  onConflict?: Maybe<TradeGuideOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeKeywordArgs = {
  objects: Array<TradeKeywordInsertInput>;
  onConflict?: Maybe<TradeKeywordOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeKeywordOneArgs = {
  object: TradeKeywordInsertInput;
  onConflict?: Maybe<TradeKeywordOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeOneArgs = {
  object: TradeInsertInput;
  onConflict?: Maybe<TradeOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeProViewArgs = {
  objects: Array<TradeProViewInsertInput>;
  onConflict?: Maybe<TradeProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeProViewOneArgs = {
  object: TradeProViewInsertInput;
  onConflict?: Maybe<TradeProViewOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeQuestionArgs = {
  objects: Array<TradeQuestionInsertInput>;
  onConflict?: Maybe<TradeQuestionOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeQuestionOneArgs = {
  object: TradeQuestionInsertInput;
  onConflict?: Maybe<TradeQuestionOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeTradeGroupArgs = {
  objects: Array<TradeTradeGroupInsertInput>;
  onConflict?: Maybe<TradeTradeGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertTradeTradeGroupOneArgs = {
  object: TradeTradeGroupInsertInput;
  onConflict?: Maybe<TradeTradeGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertZohoConsumerArgs = {
  objects: Array<ZohoConsumerInsertInput>;
  onConflict?: Maybe<ZohoConsumerOnConflict>;
};


/** mutation root */
export type MutationRootInsertZohoConsumerOneArgs = {
  object: ZohoConsumerInsertInput;
  onConflict?: Maybe<ZohoConsumerOnConflict>;
};


/** mutation root */
export type MutationRootInsertZohoOrganizationArgs = {
  objects: Array<ZohoOrganizationInsertInput>;
  onConflict?: Maybe<ZohoOrganizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertZohoOrganizationOneArgs = {
  object: ZohoOrganizationInsertInput;
  onConflict?: Maybe<ZohoOrganizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertZohoTokenArgs = {
  objects: Array<ZohoTokenInsertInput>;
  onConflict?: Maybe<ZohoTokenOnConflict>;
};


/** mutation root */
export type MutationRootInsertZohoTokenOneArgs = {
  object: ZohoTokenInsertInput;
  onConflict?: Maybe<ZohoTokenOnConflict>;
};


/** mutation root */
export type MutationRootUpdateAccountArgs = {
  _inc?: Maybe<AccountIncInput>;
  _set?: Maybe<AccountSetInput>;
  where: AccountBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountByPkArgs = {
  _inc?: Maybe<AccountIncInput>;
  _set?: Maybe<AccountSetInput>;
  pkColumns: AccountPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountDeviceArgs = {
  _inc?: Maybe<AccountDeviceIncInput>;
  _set?: Maybe<AccountDeviceSetInput>;
  where: AccountDeviceBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountDeviceByPkArgs = {
  _inc?: Maybe<AccountDeviceIncInput>;
  _set?: Maybe<AccountDeviceSetInput>;
  pkColumns: AccountDevicePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountDeviceManyArgs = {
  updates: Array<AccountDeviceUpdates>;
};


/** mutation root */
export type MutationRootUpdateAccountManyArgs = {
  updates: Array<AccountUpdates>;
};


/** mutation root */
export type MutationRootUpdateAccountProViewArgs = {
  _inc?: Maybe<AccountProViewIncInput>;
  _set?: Maybe<AccountProViewSetInput>;
  where: AccountProViewBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccountProViewByPkArgs = {
  _inc?: Maybe<AccountProViewIncInput>;
  _set?: Maybe<AccountProViewSetInput>;
  pkColumns: AccountProViewPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccountProViewManyArgs = {
  updates: Array<AccountProViewUpdates>;
};


/** mutation root */
export type MutationRootUpdateActionTokenArgs = {
  _inc?: Maybe<ActionTokenIncInput>;
  _set?: Maybe<ActionTokenSetInput>;
  where: ActionTokenBoolExp;
};


/** mutation root */
export type MutationRootUpdateActionTokenByPkArgs = {
  _inc?: Maybe<ActionTokenIncInput>;
  _set?: Maybe<ActionTokenSetInput>;
  pkColumns: ActionTokenPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActionTokenManyArgs = {
  updates: Array<ActionTokenUpdates>;
};


/** mutation root */
export type MutationRootUpdateAutorespondArgs = {
  _inc?: Maybe<AutorespondIncInput>;
  _set?: Maybe<AutorespondSetInput>;
  where: AutorespondBoolExp;
};


/** mutation root */
export type MutationRootUpdateAutorespondByPkArgs = {
  _inc?: Maybe<AutorespondIncInput>;
  _set?: Maybe<AutorespondSetInput>;
  pkColumns: AutorespondPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAutorespondManyArgs = {
  updates: Array<AutorespondUpdates>;
};


/** mutation root */
export type MutationRootUpdateBilikPersonArgs = {
  _inc?: Maybe<BilikPersonIncInput>;
  _set?: Maybe<BilikPersonSetInput>;
  where: BilikPersonBoolExp;
};


/** mutation root */
export type MutationRootUpdateBilikPersonByPkArgs = {
  _inc?: Maybe<BilikPersonIncInput>;
  _set?: Maybe<BilikPersonSetInput>;
  pkColumns: BilikPersonPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateBilikPersonManyArgs = {
  updates: Array<BilikPersonUpdates>;
};


/** mutation root */
export type MutationRootUpdateBilikSolicitationArgs = {
  _inc?: Maybe<BilikSolicitationIncInput>;
  _set?: Maybe<BilikSolicitationSetInput>;
  where: BilikSolicitationBoolExp;
};


/** mutation root */
export type MutationRootUpdateBilikSolicitationByPkArgs = {
  _inc?: Maybe<BilikSolicitationIncInput>;
  _set?: Maybe<BilikSolicitationSetInput>;
  pkColumns: BilikSolicitationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateBilikSolicitationManyArgs = {
  updates: Array<BilikSolicitationUpdates>;
};


/** mutation root */
export type MutationRootUpdateBilikZoneArgs = {
  _append?: Maybe<BilikZoneAppendInput>;
  _deleteAtPath?: Maybe<BilikZoneDeleteAtPathInput>;
  _deleteElem?: Maybe<BilikZoneDeleteElemInput>;
  _deleteKey?: Maybe<BilikZoneDeleteKeyInput>;
  _inc?: Maybe<BilikZoneIncInput>;
  _prepend?: Maybe<BilikZonePrependInput>;
  _set?: Maybe<BilikZoneSetInput>;
  where: BilikZoneBoolExp;
};


/** mutation root */
export type MutationRootUpdateBilikZoneByPkArgs = {
  _append?: Maybe<BilikZoneAppendInput>;
  _deleteAtPath?: Maybe<BilikZoneDeleteAtPathInput>;
  _deleteElem?: Maybe<BilikZoneDeleteElemInput>;
  _deleteKey?: Maybe<BilikZoneDeleteKeyInput>;
  _inc?: Maybe<BilikZoneIncInput>;
  _prepend?: Maybe<BilikZonePrependInput>;
  _set?: Maybe<BilikZoneSetInput>;
  pkColumns: BilikZonePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateBilikZoneManyArgs = {
  updates: Array<BilikZoneUpdates>;
};


/** mutation root */
export type MutationRootUpdateCertificationArgs = {
  _inc?: Maybe<CertificationIncInput>;
  _set?: Maybe<CertificationSetInput>;
  where: CertificationBoolExp;
};


/** mutation root */
export type MutationRootUpdateCertificationByPkArgs = {
  _inc?: Maybe<CertificationIncInput>;
  _set?: Maybe<CertificationSetInput>;
  pkColumns: CertificationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCertificationManyArgs = {
  updates: Array<CertificationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCityArgs = {
  _inc?: Maybe<CityIncInput>;
  _set?: Maybe<CitySetInput>;
  where: CityBoolExp;
};


/** mutation root */
export type MutationRootUpdateCityByPkArgs = {
  _inc?: Maybe<CityIncInput>;
  _set?: Maybe<CitySetInput>;
  pkColumns: CityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCityManyArgs = {
  updates: Array<CityUpdates>;
};


/** mutation root */
export type MutationRootUpdateCityProViewArgs = {
  _inc?: Maybe<CityProViewIncInput>;
  _set?: Maybe<CityProViewSetInput>;
  where: CityProViewBoolExp;
};


/** mutation root */
export type MutationRootUpdateCityProViewByPkArgs = {
  _inc?: Maybe<CityProViewIncInput>;
  _set?: Maybe<CityProViewSetInput>;
  pkColumns: CityProViewPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCityProViewManyArgs = {
  updates: Array<CityProViewUpdates>;
};


/** mutation root */
export type MutationRootUpdateDeviceArgs = {
  _inc?: Maybe<DeviceIncInput>;
  _set?: Maybe<DeviceSetInput>;
  where: DeviceBoolExp;
};


/** mutation root */
export type MutationRootUpdateDeviceByPkArgs = {
  _inc?: Maybe<DeviceIncInput>;
  _set?: Maybe<DeviceSetInput>;
  pkColumns: DevicePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDeviceManyArgs = {
  updates: Array<DeviceUpdates>;
};


/** mutation root */
export type MutationRootUpdateGeometryColumnsArgs = {
  _inc?: Maybe<GeometryColumnsIncInput>;
  _set?: Maybe<GeometryColumnsSetInput>;
  where: GeometryColumnsBoolExp;
};


/** mutation root */
export type MutationRootUpdateGeometryColumnsManyArgs = {
  updates: Array<GeometryColumnsUpdates>;
};


/** mutation root */
export type MutationRootUpdateGloballyBlockedIdentityArgs = {
  _inc?: Maybe<GloballyBlockedIdentityIncInput>;
  _set?: Maybe<GloballyBlockedIdentitySetInput>;
  where: GloballyBlockedIdentityBoolExp;
};


/** mutation root */
export type MutationRootUpdateGloballyBlockedIdentityByPkArgs = {
  _inc?: Maybe<GloballyBlockedIdentityIncInput>;
  _set?: Maybe<GloballyBlockedIdentitySetInput>;
  pkColumns: GloballyBlockedIdentityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGloballyBlockedIdentityManyArgs = {
  updates: Array<GloballyBlockedIdentityUpdates>;
};


/** mutation root */
export type MutationRootUpdateGoogleReviewLinkArgs = {
  _inc?: Maybe<GoogleReviewLinkIncInput>;
  _set?: Maybe<GoogleReviewLinkSetInput>;
  where: GoogleReviewLinkBoolExp;
};


/** mutation root */
export type MutationRootUpdateGoogleReviewLinkByPkArgs = {
  _inc?: Maybe<GoogleReviewLinkIncInput>;
  _set?: Maybe<GoogleReviewLinkSetInput>;
  pkColumns: GoogleReviewLinkPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGoogleReviewLinkManyArgs = {
  updates: Array<GoogleReviewLinkUpdates>;
};


/** mutation root */
export type MutationRootUpdateManagerBilikPersonBilikZoneArgs = {
  _inc?: Maybe<ManagerBilikPersonBilikZoneIncInput>;
  _set?: Maybe<ManagerBilikPersonBilikZoneSetInput>;
  where: ManagerBilikPersonBilikZoneBoolExp;
};


/** mutation root */
export type MutationRootUpdateManagerBilikPersonBilikZoneByPkArgs = {
  _inc?: Maybe<ManagerBilikPersonBilikZoneIncInput>;
  _set?: Maybe<ManagerBilikPersonBilikZoneSetInput>;
  pkColumns: ManagerBilikPersonBilikZonePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateManagerBilikPersonBilikZoneManyArgs = {
  updates: Array<ManagerBilikPersonBilikZoneUpdates>;
};


/** mutation root */
export type MutationRootUpdateMemberBilikPersonBilikZoneArgs = {
  _inc?: Maybe<MemberBilikPersonBilikZoneIncInput>;
  _set?: Maybe<MemberBilikPersonBilikZoneSetInput>;
  where: MemberBilikPersonBilikZoneBoolExp;
};


/** mutation root */
export type MutationRootUpdateMemberBilikPersonBilikZoneByPkArgs = {
  _inc?: Maybe<MemberBilikPersonBilikZoneIncInput>;
  _set?: Maybe<MemberBilikPersonBilikZoneSetInput>;
  pkColumns: MemberBilikPersonBilikZonePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMemberBilikPersonBilikZoneManyArgs = {
  updates: Array<MemberBilikPersonBilikZoneUpdates>;
};


/** mutation root */
export type MutationRootUpdateNoteIdentityArgs = {
  _inc?: Maybe<NoteIdentityIncInput>;
  _set?: Maybe<NoteIdentitySetInput>;
  where: NoteIdentityBoolExp;
};


/** mutation root */
export type MutationRootUpdateNoteIdentityByPkArgs = {
  _inc?: Maybe<NoteIdentityIncInput>;
  _set?: Maybe<NoteIdentitySetInput>;
  pkColumns: NoteIdentityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateNoteIdentityManyArgs = {
  updates: Array<NoteIdentityUpdates>;
};


/** mutation root */
export type MutationRootUpdateOpenDataCityArgs = {
  _inc?: Maybe<OpenDataCityIncInput>;
  _set?: Maybe<OpenDataCitySetInput>;
  where: OpenDataCityBoolExp;
};


/** mutation root */
export type MutationRootUpdateOpenDataCityByPkArgs = {
  _inc?: Maybe<OpenDataCityIncInput>;
  _set?: Maybe<OpenDataCitySetInput>;
  pkColumns: OpenDataCityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOpenDataCityManyArgs = {
  updates: Array<OpenDataCityUpdates>;
};


/** mutation root */
export type MutationRootUpdateOpenDataDepartmentArgs = {
  _inc?: Maybe<OpenDataDepartmentIncInput>;
  _set?: Maybe<OpenDataDepartmentSetInput>;
  where: OpenDataDepartmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateOpenDataDepartmentByPkArgs = {
  _inc?: Maybe<OpenDataDepartmentIncInput>;
  _set?: Maybe<OpenDataDepartmentSetInput>;
  pkColumns: OpenDataDepartmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOpenDataDepartmentManyArgs = {
  updates: Array<OpenDataDepartmentUpdates>;
};


/** mutation root */
export type MutationRootUpdateOpenDataRegionArgs = {
  _inc?: Maybe<OpenDataRegionIncInput>;
  _set?: Maybe<OpenDataRegionSetInput>;
  where: OpenDataRegionBoolExp;
};


/** mutation root */
export type MutationRootUpdateOpenDataRegionByPkArgs = {
  _inc?: Maybe<OpenDataRegionIncInput>;
  _set?: Maybe<OpenDataRegionSetInput>;
  pkColumns: OpenDataRegionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOpenDataRegionManyArgs = {
  updates: Array<OpenDataRegionUpdates>;
};


/** mutation root */
export type MutationRootUpdateProCertificationArgs = {
  _inc?: Maybe<ProCertificationIncInput>;
  _set?: Maybe<ProCertificationSetInput>;
  where: ProCertificationBoolExp;
};


/** mutation root */
export type MutationRootUpdateProCertificationByPkArgs = {
  _inc?: Maybe<ProCertificationIncInput>;
  _set?: Maybe<ProCertificationSetInput>;
  pkColumns: ProCertificationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProCertificationManyArgs = {
  updates: Array<ProCertificationUpdates>;
};


/** mutation root */
export type MutationRootUpdateProHolidayArgs = {
  _inc?: Maybe<ProHolidayIncInput>;
  _set?: Maybe<ProHolidaySetInput>;
  where: ProHolidayBoolExp;
};


/** mutation root */
export type MutationRootUpdateProHolidayByPkArgs = {
  _inc?: Maybe<ProHolidayIncInput>;
  _set?: Maybe<ProHolidaySetInput>;
  pkColumns: ProHolidayPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProHolidayManyArgs = {
  updates: Array<ProHolidayUpdates>;
};


/** mutation root */
export type MutationRootUpdateProInterventionArgs = {
  _inc?: Maybe<ProInterventionIncInput>;
  _set?: Maybe<ProInterventionSetInput>;
  where: ProInterventionBoolExp;
};


/** mutation root */
export type MutationRootUpdateProInterventionByPkArgs = {
  _inc?: Maybe<ProInterventionIncInput>;
  _set?: Maybe<ProInterventionSetInput>;
  pkColumns: ProInterventionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProInterventionManyArgs = {
  updates: Array<ProInterventionUpdates>;
};


/** mutation root */
export type MutationRootUpdateProLabelArgs = {
  _inc?: Maybe<ProLabelIncInput>;
  _set?: Maybe<ProLabelSetInput>;
  where: ProLabelBoolExp;
};


/** mutation root */
export type MutationRootUpdateProLabelByPkArgs = {
  _inc?: Maybe<ProLabelIncInput>;
  _set?: Maybe<ProLabelSetInput>;
  pkColumns: ProLabelPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProLabelManyArgs = {
  updates: Array<ProLabelUpdates>;
};


/** mutation root */
export type MutationRootUpdateProMandateArgs = {
  _append?: Maybe<ProMandateAppendInput>;
  _deleteAtPath?: Maybe<ProMandateDeleteAtPathInput>;
  _deleteElem?: Maybe<ProMandateDeleteElemInput>;
  _deleteKey?: Maybe<ProMandateDeleteKeyInput>;
  _inc?: Maybe<ProMandateIncInput>;
  _prepend?: Maybe<ProMandatePrependInput>;
  _set?: Maybe<ProMandateSetInput>;
  where: ProMandateBoolExp;
};


/** mutation root */
export type MutationRootUpdateProMandateByPkArgs = {
  _append?: Maybe<ProMandateAppendInput>;
  _deleteAtPath?: Maybe<ProMandateDeleteAtPathInput>;
  _deleteElem?: Maybe<ProMandateDeleteElemInput>;
  _deleteKey?: Maybe<ProMandateDeleteKeyInput>;
  _inc?: Maybe<ProMandateIncInput>;
  _prepend?: Maybe<ProMandatePrependInput>;
  _set?: Maybe<ProMandateSetInput>;
  pkColumns: ProMandatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProMandateManyArgs = {
  updates: Array<ProMandateUpdates>;
};


/** mutation root */
export type MutationRootUpdateProMediaObjectArgs = {
  _inc?: Maybe<ProMediaObjectIncInput>;
  _set?: Maybe<ProMediaObjectSetInput>;
  where: ProMediaObjectBoolExp;
};


/** mutation root */
export type MutationRootUpdateProMediaObjectByPkArgs = {
  _inc?: Maybe<ProMediaObjectIncInput>;
  _set?: Maybe<ProMediaObjectSetInput>;
  pkColumns: ProMediaObjectPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProMediaObjectManyArgs = {
  updates: Array<ProMediaObjectUpdates>;
};


/** mutation root */
export type MutationRootUpdateProMessageArgs = {
  _inc?: Maybe<ProMessageIncInput>;
  _set?: Maybe<ProMessageSetInput>;
  where: ProMessageBoolExp;
};


/** mutation root */
export type MutationRootUpdateProMessageByPkArgs = {
  _inc?: Maybe<ProMessageIncInput>;
  _set?: Maybe<ProMessageSetInput>;
  pkColumns: ProMessagePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProMessageManyArgs = {
  updates: Array<ProMessageUpdates>;
};


/** mutation root */
export type MutationRootUpdateProOrganizationArgs = {
  _inc?: Maybe<ProOrganizationIncInput>;
  _set?: Maybe<ProOrganizationSetInput>;
  where: ProOrganizationBoolExp;
};


/** mutation root */
export type MutationRootUpdateProOrganizationByPkArgs = {
  _inc?: Maybe<ProOrganizationIncInput>;
  _set?: Maybe<ProOrganizationSetInput>;
  pkColumns: ProOrganizationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProOrganizationManyArgs = {
  updates: Array<ProOrganizationUpdates>;
};


/** mutation root */
export type MutationRootUpdateProPersonArgs = {
  _inc?: Maybe<ProPersonIncInput>;
  _set?: Maybe<ProPersonSetInput>;
  where: ProPersonBoolExp;
};


/** mutation root */
export type MutationRootUpdateProPersonByPkArgs = {
  _inc?: Maybe<ProPersonIncInput>;
  _set?: Maybe<ProPersonSetInput>;
  pkColumns: ProPersonPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProPersonManyArgs = {
  updates: Array<ProPersonUpdates>;
};


/** mutation root */
export type MutationRootUpdateProPostalAddressArgs = {
  _inc?: Maybe<ProPostalAddressIncInput>;
  _set?: Maybe<ProPostalAddressSetInput>;
  where: ProPostalAddressBoolExp;
};


/** mutation root */
export type MutationRootUpdateProPostalAddressByPkArgs = {
  _inc?: Maybe<ProPostalAddressIncInput>;
  _set?: Maybe<ProPostalAddressSetInput>;
  pkColumns: ProPostalAddressPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProPostalAddressManyArgs = {
  updates: Array<ProPostalAddressUpdates>;
};


/** mutation root */
export type MutationRootUpdateProPresentationArgs = {
  _inc?: Maybe<ProPresentationIncInput>;
  _set?: Maybe<ProPresentationSetInput>;
  where: ProPresentationBoolExp;
};


/** mutation root */
export type MutationRootUpdateProPresentationByPkArgs = {
  _inc?: Maybe<ProPresentationIncInput>;
  _set?: Maybe<ProPresentationSetInput>;
  pkColumns: ProPresentationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProPresentationManyArgs = {
  updates: Array<ProPresentationUpdates>;
};


/** mutation root */
export type MutationRootUpdateProPriceArgs = {
  _inc?: Maybe<ProPriceIncInput>;
  _set?: Maybe<ProPriceSetInput>;
  where: ProPriceBoolExp;
};


/** mutation root */
export type MutationRootUpdateProPriceByPkArgs = {
  _inc?: Maybe<ProPriceIncInput>;
  _set?: Maybe<ProPriceSetInput>;
  pkColumns: ProPricePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProPriceManyArgs = {
  updates: Array<ProPriceUpdates>;
};


/** mutation root */
export type MutationRootUpdateProReviewArgs = {
  _inc?: Maybe<ProReviewIncInput>;
  _set?: Maybe<ProReviewSetInput>;
  where: ProReviewBoolExp;
};


/** mutation root */
export type MutationRootUpdateProReviewByPkArgs = {
  _inc?: Maybe<ProReviewIncInput>;
  _set?: Maybe<ProReviewSetInput>;
  pkColumns: ProReviewPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProReviewInvitationArgs = {
  _inc?: Maybe<ProReviewInvitationIncInput>;
  _set?: Maybe<ProReviewInvitationSetInput>;
  where: ProReviewInvitationBoolExp;
};


/** mutation root */
export type MutationRootUpdateProReviewInvitationByPkArgs = {
  _inc?: Maybe<ProReviewInvitationIncInput>;
  _set?: Maybe<ProReviewInvitationSetInput>;
  pkColumns: ProReviewInvitationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProReviewInvitationManyArgs = {
  updates: Array<ProReviewInvitationUpdates>;
};


/** mutation root */
export type MutationRootUpdateProReviewManyArgs = {
  updates: Array<ProReviewUpdates>;
};


/** mutation root */
export type MutationRootUpdateProViewArgs = {
  _append?: Maybe<ProViewAppendInput>;
  _deleteAtPath?: Maybe<ProViewDeleteAtPathInput>;
  _deleteElem?: Maybe<ProViewDeleteElemInput>;
  _deleteKey?: Maybe<ProViewDeleteKeyInput>;
  _inc?: Maybe<ProViewIncInput>;
  _prepend?: Maybe<ProViewPrependInput>;
  _set?: Maybe<ProViewSetInput>;
  where: ProViewBoolExp;
};


/** mutation root */
export type MutationRootUpdateProViewByPkArgs = {
  _append?: Maybe<ProViewAppendInput>;
  _deleteAtPath?: Maybe<ProViewDeleteAtPathInput>;
  _deleteElem?: Maybe<ProViewDeleteElemInput>;
  _deleteKey?: Maybe<ProViewDeleteKeyInput>;
  _inc?: Maybe<ProViewIncInput>;
  _prepend?: Maybe<ProViewPrependInput>;
  _set?: Maybe<ProViewSetInput>;
  pkColumns: ProViewPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProViewManyArgs = {
  updates: Array<ProViewUpdates>;
};


/** mutation root */
export type MutationRootUpdateProViewProPersonArgs = {
  _inc?: Maybe<ProViewProPersonIncInput>;
  _set?: Maybe<ProViewProPersonSetInput>;
  where: ProViewProPersonBoolExp;
};


/** mutation root */
export type MutationRootUpdateProViewProPersonByPkArgs = {
  _inc?: Maybe<ProViewProPersonIncInput>;
  _set?: Maybe<ProViewProPersonSetInput>;
  pkColumns: ProViewProPersonPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProViewProPersonManyArgs = {
  updates: Array<ProViewProPersonUpdates>;
};


/** mutation root */
export type MutationRootUpdateProViewProViewArgs = {
  _inc?: Maybe<ProViewProViewIncInput>;
  _set?: Maybe<ProViewProViewSetInput>;
  where: ProViewProViewBoolExp;
};


/** mutation root */
export type MutationRootUpdateProViewProViewByPkArgs = {
  _inc?: Maybe<ProViewProViewIncInput>;
  _set?: Maybe<ProViewProViewSetInput>;
  pkColumns: ProViewProViewPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProViewProViewManyArgs = {
  updates: Array<ProViewProViewUpdates>;
};


/** mutation root */
export type MutationRootUpdateQueuedSmsArgs = {
  _inc?: Maybe<QueuedSmsIncInput>;
  _set?: Maybe<QueuedSmsSetInput>;
  where: QueuedSmsBoolExp;
};


/** mutation root */
export type MutationRootUpdateQueuedSmsByPkArgs = {
  _inc?: Maybe<QueuedSmsIncInput>;
  _set?: Maybe<QueuedSmsSetInput>;
  pkColumns: QueuedSmsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQueuedSmsManyArgs = {
  updates: Array<QueuedSmsUpdates>;
};


/** mutation root */
export type MutationRootUpdateSlugRedirectArgs = {
  _inc?: Maybe<SlugRedirectIncInput>;
  _set?: Maybe<SlugRedirectSetInput>;
  where: SlugRedirectBoolExp;
};


/** mutation root */
export type MutationRootUpdateSlugRedirectByPkArgs = {
  _inc?: Maybe<SlugRedirectIncInput>;
  _set?: Maybe<SlugRedirectSetInput>;
  pkColumns: SlugRedirectPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSlugRedirectManyArgs = {
  updates: Array<SlugRedirectUpdates>;
};


/** mutation root */
export type MutationRootUpdateSolicitationArgs = {
  _append?: Maybe<SolicitationAppendInput>;
  _deleteAtPath?: Maybe<SolicitationDeleteAtPathInput>;
  _deleteElem?: Maybe<SolicitationDeleteElemInput>;
  _deleteKey?: Maybe<SolicitationDeleteKeyInput>;
  _inc?: Maybe<SolicitationIncInput>;
  _prepend?: Maybe<SolicitationPrependInput>;
  _set?: Maybe<SolicitationSetInput>;
  where: SolicitationBoolExp;
};


/** mutation root */
export type MutationRootUpdateSolicitationByPkArgs = {
  _append?: Maybe<SolicitationAppendInput>;
  _deleteAtPath?: Maybe<SolicitationDeleteAtPathInput>;
  _deleteElem?: Maybe<SolicitationDeleteElemInput>;
  _deleteKey?: Maybe<SolicitationDeleteKeyInput>;
  _inc?: Maybe<SolicitationIncInput>;
  _prepend?: Maybe<SolicitationPrependInput>;
  _set?: Maybe<SolicitationSetInput>;
  pkColumns: SolicitationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSolicitationManyArgs = {
  updates: Array<SolicitationUpdates>;
};


/** mutation root */
export type MutationRootUpdateSpatialRefSysArgs = {
  _inc?: Maybe<SpatialRefSysIncInput>;
  _set?: Maybe<SpatialRefSysSetInput>;
  where: SpatialRefSysBoolExp;
};


/** mutation root */
export type MutationRootUpdateSpatialRefSysByPkArgs = {
  _inc?: Maybe<SpatialRefSysIncInput>;
  _set?: Maybe<SpatialRefSysSetInput>;
  pkColumns: SpatialRefSysPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSpatialRefSysManyArgs = {
  updates: Array<SpatialRefSysUpdates>;
};


/** mutation root */
export type MutationRootUpdateStopSmsArgs = {
  _inc?: Maybe<StopSmsIncInput>;
  _set?: Maybe<StopSmsSetInput>;
  where: StopSmsBoolExp;
};


/** mutation root */
export type MutationRootUpdateStopSmsByPkArgs = {
  _inc?: Maybe<StopSmsIncInput>;
  _set?: Maybe<StopSmsSetInput>;
  pkColumns: StopSmsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateStopSmsManyArgs = {
  updates: Array<StopSmsUpdates>;
};


/** mutation root */
export type MutationRootUpdateTopologyLayerArgs = {
  _inc?: Maybe<TopologyLayerIncInput>;
  _set?: Maybe<TopologyLayerSetInput>;
  where: TopologyLayerBoolExp;
};


/** mutation root */
export type MutationRootUpdateTopologyLayerByPkArgs = {
  _inc?: Maybe<TopologyLayerIncInput>;
  _set?: Maybe<TopologyLayerSetInput>;
  pkColumns: TopologyLayerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTopologyLayerManyArgs = {
  updates: Array<TopologyLayerUpdates>;
};


/** mutation root */
export type MutationRootUpdateTopologyTopologyArgs = {
  _inc?: Maybe<TopologyTopologyIncInput>;
  _set?: Maybe<TopologyTopologySetInput>;
  where: TopologyTopologyBoolExp;
};


/** mutation root */
export type MutationRootUpdateTopologyTopologyByPkArgs = {
  _inc?: Maybe<TopologyTopologyIncInput>;
  _set?: Maybe<TopologyTopologySetInput>;
  pkColumns: TopologyTopologyPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTopologyTopologyManyArgs = {
  updates: Array<TopologyTopologyUpdates>;
};


/** mutation root */
export type MutationRootUpdateTradeArgs = {
  _inc?: Maybe<TradeIncInput>;
  _set?: Maybe<TradeSetInput>;
  where: TradeBoolExp;
};


/** mutation root */
export type MutationRootUpdateTradeByPkArgs = {
  _inc?: Maybe<TradeIncInput>;
  _set?: Maybe<TradeSetInput>;
  pkColumns: TradePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTradeGroupArgs = {
  _inc?: Maybe<TradeGroupIncInput>;
  _set?: Maybe<TradeGroupSetInput>;
  where: TradeGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateTradeGroupByPkArgs = {
  _inc?: Maybe<TradeGroupIncInput>;
  _set?: Maybe<TradeGroupSetInput>;
  pkColumns: TradeGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTradeGroupManyArgs = {
  updates: Array<TradeGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateTradeGuideArgs = {
  _inc?: Maybe<TradeGuideIncInput>;
  _set?: Maybe<TradeGuideSetInput>;
  where: TradeGuideBoolExp;
};


/** mutation root */
export type MutationRootUpdateTradeGuideByPkArgs = {
  _inc?: Maybe<TradeGuideIncInput>;
  _set?: Maybe<TradeGuideSetInput>;
  pkColumns: TradeGuidePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTradeGuideManyArgs = {
  updates: Array<TradeGuideUpdates>;
};


/** mutation root */
export type MutationRootUpdateTradeKeywordArgs = {
  _inc?: Maybe<TradeKeywordIncInput>;
  _set?: Maybe<TradeKeywordSetInput>;
  where: TradeKeywordBoolExp;
};


/** mutation root */
export type MutationRootUpdateTradeKeywordByPkArgs = {
  _inc?: Maybe<TradeKeywordIncInput>;
  _set?: Maybe<TradeKeywordSetInput>;
  pkColumns: TradeKeywordPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTradeKeywordManyArgs = {
  updates: Array<TradeKeywordUpdates>;
};


/** mutation root */
export type MutationRootUpdateTradeManyArgs = {
  updates: Array<TradeUpdates>;
};


/** mutation root */
export type MutationRootUpdateTradeProViewArgs = {
  _inc?: Maybe<TradeProViewIncInput>;
  _set?: Maybe<TradeProViewSetInput>;
  where: TradeProViewBoolExp;
};


/** mutation root */
export type MutationRootUpdateTradeProViewByPkArgs = {
  _inc?: Maybe<TradeProViewIncInput>;
  _set?: Maybe<TradeProViewSetInput>;
  pkColumns: TradeProViewPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTradeProViewManyArgs = {
  updates: Array<TradeProViewUpdates>;
};


/** mutation root */
export type MutationRootUpdateTradeQuestionArgs = {
  _inc?: Maybe<TradeQuestionIncInput>;
  _set?: Maybe<TradeQuestionSetInput>;
  where: TradeQuestionBoolExp;
};


/** mutation root */
export type MutationRootUpdateTradeQuestionByPkArgs = {
  _inc?: Maybe<TradeQuestionIncInput>;
  _set?: Maybe<TradeQuestionSetInput>;
  pkColumns: TradeQuestionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTradeQuestionManyArgs = {
  updates: Array<TradeQuestionUpdates>;
};


/** mutation root */
export type MutationRootUpdateTradeTradeGroupArgs = {
  _inc?: Maybe<TradeTradeGroupIncInput>;
  _set?: Maybe<TradeTradeGroupSetInput>;
  where: TradeTradeGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateTradeTradeGroupByPkArgs = {
  _inc?: Maybe<TradeTradeGroupIncInput>;
  _set?: Maybe<TradeTradeGroupSetInput>;
  pkColumns: TradeTradeGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTradeTradeGroupManyArgs = {
  updates: Array<TradeTradeGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateZohoConsumerArgs = {
  _inc?: Maybe<ZohoConsumerIncInput>;
  _set?: Maybe<ZohoConsumerSetInput>;
  where: ZohoConsumerBoolExp;
};


/** mutation root */
export type MutationRootUpdateZohoConsumerByPkArgs = {
  _inc?: Maybe<ZohoConsumerIncInput>;
  _set?: Maybe<ZohoConsumerSetInput>;
  pkColumns: ZohoConsumerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateZohoConsumerManyArgs = {
  updates: Array<ZohoConsumerUpdates>;
};


/** mutation root */
export type MutationRootUpdateZohoOrganizationArgs = {
  _append?: Maybe<ZohoOrganizationAppendInput>;
  _deleteAtPath?: Maybe<ZohoOrganizationDeleteAtPathInput>;
  _deleteElem?: Maybe<ZohoOrganizationDeleteElemInput>;
  _deleteKey?: Maybe<ZohoOrganizationDeleteKeyInput>;
  _inc?: Maybe<ZohoOrganizationIncInput>;
  _prepend?: Maybe<ZohoOrganizationPrependInput>;
  _set?: Maybe<ZohoOrganizationSetInput>;
  where: ZohoOrganizationBoolExp;
};


/** mutation root */
export type MutationRootUpdateZohoOrganizationByPkArgs = {
  _append?: Maybe<ZohoOrganizationAppendInput>;
  _deleteAtPath?: Maybe<ZohoOrganizationDeleteAtPathInput>;
  _deleteElem?: Maybe<ZohoOrganizationDeleteElemInput>;
  _deleteKey?: Maybe<ZohoOrganizationDeleteKeyInput>;
  _inc?: Maybe<ZohoOrganizationIncInput>;
  _prepend?: Maybe<ZohoOrganizationPrependInput>;
  _set?: Maybe<ZohoOrganizationSetInput>;
  pkColumns: ZohoOrganizationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateZohoOrganizationManyArgs = {
  updates: Array<ZohoOrganizationUpdates>;
};


/** mutation root */
export type MutationRootUpdateZohoTokenArgs = {
  _inc?: Maybe<ZohoTokenIncInput>;
  _set?: Maybe<ZohoTokenSetInput>;
  where: ZohoTokenBoolExp;
};


/** mutation root */
export type MutationRootUpdateZohoTokenByPkArgs = {
  _inc?: Maybe<ZohoTokenIncInput>;
  _set?: Maybe<ZohoTokenSetInput>;
  pkColumns: ZohoTokenPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateZohoTokenManyArgs = {
  updates: Array<ZohoTokenUpdates>;
};


/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type NameComparisonExp = {
  _eq?: Maybe<Scalars['name']>;
  _gt?: Maybe<Scalars['name']>;
  _gte?: Maybe<Scalars['name']>;
  _in?: Maybe<Array<Scalars['name']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['name']>;
  _lte?: Maybe<Scalars['name']>;
  _neq?: Maybe<Scalars['name']>;
  _nin?: Maybe<Array<Scalars['name']>>;
};

/** columns and relationships of "note_identity" */
export type NoteIdentity = {
  __typename?: 'NoteIdentity';
  /** An object relationship */
  bilikPerson: BilikPerson;
  id: Scalars['Int'];
  identifier: Scalars['String'];
  lastEditorId: Scalars['Int'];
  lastUpdatedDate: Scalars['timestamptz'];
  message: Scalars['String'];
};

/** aggregated selection of "note_identity" */
export type NoteIdentityAggregate = {
  __typename?: 'NoteIdentityAggregate';
  aggregate?: Maybe<NoteIdentityAggregateFields>;
  nodes: Array<NoteIdentity>;
};

/** aggregate fields of "note_identity" */
export type NoteIdentityAggregateFields = {
  __typename?: 'NoteIdentityAggregateFields';
  avg?: Maybe<NoteIdentityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<NoteIdentityMaxFields>;
  min?: Maybe<NoteIdentityMinFields>;
  stddev?: Maybe<NoteIdentityStddevFields>;
  stddevPop?: Maybe<NoteIdentityStddevPopFields>;
  stddevSamp?: Maybe<NoteIdentityStddevSampFields>;
  sum?: Maybe<NoteIdentitySumFields>;
  varPop?: Maybe<NoteIdentityVarPopFields>;
  varSamp?: Maybe<NoteIdentityVarSampFields>;
  variance?: Maybe<NoteIdentityVarianceFields>;
};


/** aggregate fields of "note_identity" */
export type NoteIdentityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<NoteIdentitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type NoteIdentityAvgFields = {
  __typename?: 'NoteIdentityAvgFields';
  id?: Maybe<Scalars['Float']>;
  lastEditorId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "note_identity". All fields are combined with a logical 'AND'. */
export type NoteIdentityBoolExp = {
  _and?: Maybe<Array<NoteIdentityBoolExp>>;
  _not?: Maybe<NoteIdentityBoolExp>;
  _or?: Maybe<Array<NoteIdentityBoolExp>>;
  bilikPerson?: Maybe<BilikPersonBoolExp>;
  id?: Maybe<IntComparisonExp>;
  identifier?: Maybe<StringComparisonExp>;
  lastEditorId?: Maybe<IntComparisonExp>;
  lastUpdatedDate?: Maybe<TimestamptzComparisonExp>;
  message?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "note_identity" */
export enum NoteIdentityConstraint {
  /** unique or primary key constraint on columns "identifier" */
  NoteIdentityIdentifierKey = 'note_identity_identifier_key',
  /** unique or primary key constraint on columns "id" */
  NoteIdentityPkey = 'note_identity_pkey'
}

/** input type for incrementing numeric columns in table "note_identity" */
export type NoteIdentityIncInput = {
  id?: Maybe<Scalars['Int']>;
  lastEditorId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "note_identity" */
export type NoteIdentityInsertInput = {
  bilikPerson?: Maybe<BilikPersonObjRelInsertInput>;
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  lastEditorId?: Maybe<Scalars['Int']>;
  lastUpdatedDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type NoteIdentityMaxFields = {
  __typename?: 'NoteIdentityMaxFields';
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  lastEditorId?: Maybe<Scalars['Int']>;
  lastUpdatedDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type NoteIdentityMinFields = {
  __typename?: 'NoteIdentityMinFields';
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  lastEditorId?: Maybe<Scalars['Int']>;
  lastUpdatedDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "note_identity" */
export type NoteIdentityMutationResponse = {
  __typename?: 'NoteIdentityMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<NoteIdentity>;
};

/** on_conflict condition type for table "note_identity" */
export type NoteIdentityOnConflict = {
  constraint: NoteIdentityConstraint;
  updateColumns: Array<NoteIdentityUpdateColumn>;
  where?: Maybe<NoteIdentityBoolExp>;
};

/** Ordering options when selecting data from "note_identity". */
export type NoteIdentityOrderBy = {
  bilikPerson?: Maybe<BilikPersonOrderBy>;
  id?: Maybe<OrderBy>;
  identifier?: Maybe<OrderBy>;
  lastEditorId?: Maybe<OrderBy>;
  lastUpdatedDate?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
};

/** primary key columns input for table: note_identity */
export type NoteIdentityPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "note_identity" */
export enum NoteIdentitySelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  LastEditorId = 'lastEditorId',
  /** column name */
  LastUpdatedDate = 'lastUpdatedDate',
  /** column name */
  Message = 'message'
}

/** input type for updating data in table "note_identity" */
export type NoteIdentitySetInput = {
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  lastEditorId?: Maybe<Scalars['Int']>;
  lastUpdatedDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type NoteIdentityStddevFields = {
  __typename?: 'NoteIdentityStddevFields';
  id?: Maybe<Scalars['Float']>;
  lastEditorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type NoteIdentityStddevPopFields = {
  __typename?: 'NoteIdentityStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  lastEditorId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type NoteIdentityStddevSampFields = {
  __typename?: 'NoteIdentityStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  lastEditorId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "note_identity" */
export type NoteIdentityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: NoteIdentityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NoteIdentityStreamCursorValueInput = {
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
  lastEditorId?: Maybe<Scalars['Int']>;
  lastUpdatedDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type NoteIdentitySumFields = {
  __typename?: 'NoteIdentitySumFields';
  id?: Maybe<Scalars['Int']>;
  lastEditorId?: Maybe<Scalars['Int']>;
};

/** update columns of table "note_identity" */
export enum NoteIdentityUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  LastEditorId = 'lastEditorId',
  /** column name */
  LastUpdatedDate = 'lastUpdatedDate',
  /** column name */
  Message = 'message'
}

export type NoteIdentityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<NoteIdentityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<NoteIdentitySetInput>;
  /** filter the rows which have to be updated */
  where: NoteIdentityBoolExp;
};

/** aggregate variance on columns */
export type NoteIdentityVarianceFields = {
  __typename?: 'NoteIdentityVarianceFields';
  id?: Maybe<Scalars['Float']>;
  lastEditorId?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type NoteIdentityVarPopFields = {
  __typename?: 'NoteIdentityVarPopFields';
  id?: Maybe<Scalars['Float']>;
  lastEditorId?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type NoteIdentityVarSampFields = {
  __typename?: 'NoteIdentityVarSampFields';
  id?: Maybe<Scalars['Float']>;
  lastEditorId?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** import OpenDataSoft de toutes les communes de France (2023) */
export type OpenDataCity = {
  __typename?: 'OpenDataCity';
  area: Scalars['geometry'];
  code: Scalars['String'];
  /** An object relationship */
  department?: Maybe<OpenDataDepartment>;
  departmentId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  region?: Maybe<OpenDataRegion>;
  regionId: Scalars['Int'];
  siren?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

/** aggregated selection of "open_data_city" */
export type OpenDataCityAggregate = {
  __typename?: 'OpenDataCityAggregate';
  aggregate?: Maybe<OpenDataCityAggregateFields>;
  nodes: Array<OpenDataCity>;
};

export type OpenDataCityAggregateBoolExp = {
  count?: Maybe<OpenDataCityAggregateBoolExpCount>;
};

export type OpenDataCityAggregateBoolExpCount = {
  arguments?: Maybe<Array<OpenDataCitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<OpenDataCityBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "open_data_city" */
export type OpenDataCityAggregateFields = {
  __typename?: 'OpenDataCityAggregateFields';
  avg?: Maybe<OpenDataCityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OpenDataCityMaxFields>;
  min?: Maybe<OpenDataCityMinFields>;
  stddev?: Maybe<OpenDataCityStddevFields>;
  stddevPop?: Maybe<OpenDataCityStddevPopFields>;
  stddevSamp?: Maybe<OpenDataCityStddevSampFields>;
  sum?: Maybe<OpenDataCitySumFields>;
  varPop?: Maybe<OpenDataCityVarPopFields>;
  varSamp?: Maybe<OpenDataCityVarSampFields>;
  variance?: Maybe<OpenDataCityVarianceFields>;
};


/** aggregate fields of "open_data_city" */
export type OpenDataCityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OpenDataCitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "open_data_city" */
export type OpenDataCityAggregateOrderBy = {
  avg?: Maybe<OpenDataCityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<OpenDataCityMaxOrderBy>;
  min?: Maybe<OpenDataCityMinOrderBy>;
  stddev?: Maybe<OpenDataCityStddevOrderBy>;
  stddevPop?: Maybe<OpenDataCityStddevPopOrderBy>;
  stddevSamp?: Maybe<OpenDataCityStddevSampOrderBy>;
  sum?: Maybe<OpenDataCitySumOrderBy>;
  varPop?: Maybe<OpenDataCityVarPopOrderBy>;
  varSamp?: Maybe<OpenDataCityVarSampOrderBy>;
  variance?: Maybe<OpenDataCityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "open_data_city" */
export type OpenDataCityArrRelInsertInput = {
  data: Array<OpenDataCityInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<OpenDataCityOnConflict>;
};

/** aggregate avg on columns */
export type OpenDataCityAvgFields = {
  __typename?: 'OpenDataCityAvgFields';
  departmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "open_data_city" */
export type OpenDataCityAvgOrderBy = {
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "open_data_city". All fields are combined with a logical 'AND'. */
export type OpenDataCityBoolExp = {
  _and?: Maybe<Array<OpenDataCityBoolExp>>;
  _not?: Maybe<OpenDataCityBoolExp>;
  _or?: Maybe<Array<OpenDataCityBoolExp>>;
  area?: Maybe<GeometryComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  department?: Maybe<OpenDataDepartmentBoolExp>;
  departmentId?: Maybe<IntComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  region?: Maybe<OpenDataRegionBoolExp>;
  regionId?: Maybe<IntComparisonExp>;
  siren?: Maybe<StringComparisonExp>;
  slug?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "open_data_city" */
export enum OpenDataCityConstraint {
  /** unique or primary key constraint on columns "id" */
  OpenDataCityPkey = 'open_data_city_pkey'
}

/** input type for incrementing numeric columns in table "open_data_city" */
export type OpenDataCityIncInput = {
  departmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  regionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "open_data_city" */
export type OpenDataCityInsertInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  department?: Maybe<OpenDataDepartmentObjRelInsertInput>;
  departmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<OpenDataRegionObjRelInsertInput>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OpenDataCityMaxFields = {
  __typename?: 'OpenDataCityMaxFields';
  code?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "open_data_city" */
export type OpenDataCityMaxOrderBy = {
  code?: Maybe<OrderBy>;
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
  siren?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OpenDataCityMinFields = {
  __typename?: 'OpenDataCityMinFields';
  code?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "open_data_city" */
export type OpenDataCityMinOrderBy = {
  code?: Maybe<OrderBy>;
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
  siren?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** response of any mutation on the table "open_data_city" */
export type OpenDataCityMutationResponse = {
  __typename?: 'OpenDataCityMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OpenDataCity>;
};

/** on_conflict condition type for table "open_data_city" */
export type OpenDataCityOnConflict = {
  constraint: OpenDataCityConstraint;
  updateColumns: Array<OpenDataCityUpdateColumn>;
  where?: Maybe<OpenDataCityBoolExp>;
};

/** Ordering options when selecting data from "open_data_city". */
export type OpenDataCityOrderBy = {
  area?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  department?: Maybe<OpenDataDepartmentOrderBy>;
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  region?: Maybe<OpenDataRegionOrderBy>;
  regionId?: Maybe<OrderBy>;
  siren?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** primary key columns input for table: open_data_city */
export type OpenDataCityPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "open_data_city" */
export enum OpenDataCitySelectColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Code = 'code',
  /** column name */
  DepartmentId = 'departmentId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'regionId',
  /** column name */
  Siren = 'siren',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "open_data_city" */
export type OpenDataCitySetInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OpenDataCityStddevFields = {
  __typename?: 'OpenDataCityStddevFields';
  departmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "open_data_city" */
export type OpenDataCityStddevOrderBy = {
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type OpenDataCityStddevPopFields = {
  __typename?: 'OpenDataCityStddevPopFields';
  departmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "open_data_city" */
export type OpenDataCityStddevPopOrderBy = {
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type OpenDataCityStddevSampFields = {
  __typename?: 'OpenDataCityStddevSampFields';
  departmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "open_data_city" */
export type OpenDataCityStddevSampOrderBy = {
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "open_data_city" */
export type OpenDataCityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OpenDataCityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OpenDataCityStreamCursorValueInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type OpenDataCitySumFields = {
  __typename?: 'OpenDataCitySumFields';
  departmentId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  regionId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "open_data_city" */
export type OpenDataCitySumOrderBy = {
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** update columns of table "open_data_city" */
export enum OpenDataCityUpdateColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Code = 'code',
  /** column name */
  DepartmentId = 'departmentId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'regionId',
  /** column name */
  Siren = 'siren',
  /** column name */
  Slug = 'slug'
}

export type OpenDataCityUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<OpenDataCityIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<OpenDataCitySetInput>;
  /** filter the rows which have to be updated */
  where: OpenDataCityBoolExp;
};

/** aggregate variance on columns */
export type OpenDataCityVarianceFields = {
  __typename?: 'OpenDataCityVarianceFields';
  departmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "open_data_city" */
export type OpenDataCityVarianceOrderBy = {
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type OpenDataCityVarPopFields = {
  __typename?: 'OpenDataCityVarPopFields';
  departmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "open_data_city" */
export type OpenDataCityVarPopOrderBy = {
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type OpenDataCityVarSampFields = {
  __typename?: 'OpenDataCityVarSampFields';
  departmentId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "open_data_city" */
export type OpenDataCityVarSampOrderBy = {
  departmentId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** import OpenDataSoft de toutes les régions de France (2023) */
export type OpenDataDepartment = {
  __typename?: 'OpenDataDepartment';
  area: Scalars['geometry'];
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  openDataCities: Array<OpenDataCity>;
  /** An aggregate relationship */
  openDataCitiesAggregate: OpenDataCityAggregate;
  /** An object relationship */
  region?: Maybe<OpenDataRegion>;
  regionId: Scalars['Int'];
  siren?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};


/** import OpenDataSoft de toutes les régions de France (2023) */
export type OpenDataDepartmentOpenDataCitiesArgs = {
  distinctOn?: Maybe<Array<OpenDataCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataCityOrderBy>>;
  where?: Maybe<OpenDataCityBoolExp>;
};


/** import OpenDataSoft de toutes les régions de France (2023) */
export type OpenDataDepartmentOpenDataCitiesAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataCityOrderBy>>;
  where?: Maybe<OpenDataCityBoolExp>;
};

/** aggregated selection of "open_data_department" */
export type OpenDataDepartmentAggregate = {
  __typename?: 'OpenDataDepartmentAggregate';
  aggregate?: Maybe<OpenDataDepartmentAggregateFields>;
  nodes: Array<OpenDataDepartment>;
};

export type OpenDataDepartmentAggregateBoolExp = {
  count?: Maybe<OpenDataDepartmentAggregateBoolExpCount>;
};

export type OpenDataDepartmentAggregateBoolExpCount = {
  arguments?: Maybe<Array<OpenDataDepartmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<OpenDataDepartmentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "open_data_department" */
export type OpenDataDepartmentAggregateFields = {
  __typename?: 'OpenDataDepartmentAggregateFields';
  avg?: Maybe<OpenDataDepartmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OpenDataDepartmentMaxFields>;
  min?: Maybe<OpenDataDepartmentMinFields>;
  stddev?: Maybe<OpenDataDepartmentStddevFields>;
  stddevPop?: Maybe<OpenDataDepartmentStddevPopFields>;
  stddevSamp?: Maybe<OpenDataDepartmentStddevSampFields>;
  sum?: Maybe<OpenDataDepartmentSumFields>;
  varPop?: Maybe<OpenDataDepartmentVarPopFields>;
  varSamp?: Maybe<OpenDataDepartmentVarSampFields>;
  variance?: Maybe<OpenDataDepartmentVarianceFields>;
};


/** aggregate fields of "open_data_department" */
export type OpenDataDepartmentAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OpenDataDepartmentSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "open_data_department" */
export type OpenDataDepartmentAggregateOrderBy = {
  avg?: Maybe<OpenDataDepartmentAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<OpenDataDepartmentMaxOrderBy>;
  min?: Maybe<OpenDataDepartmentMinOrderBy>;
  stddev?: Maybe<OpenDataDepartmentStddevOrderBy>;
  stddevPop?: Maybe<OpenDataDepartmentStddevPopOrderBy>;
  stddevSamp?: Maybe<OpenDataDepartmentStddevSampOrderBy>;
  sum?: Maybe<OpenDataDepartmentSumOrderBy>;
  varPop?: Maybe<OpenDataDepartmentVarPopOrderBy>;
  varSamp?: Maybe<OpenDataDepartmentVarSampOrderBy>;
  variance?: Maybe<OpenDataDepartmentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "open_data_department" */
export type OpenDataDepartmentArrRelInsertInput = {
  data: Array<OpenDataDepartmentInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<OpenDataDepartmentOnConflict>;
};

/** aggregate avg on columns */
export type OpenDataDepartmentAvgFields = {
  __typename?: 'OpenDataDepartmentAvgFields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "open_data_department" */
export type OpenDataDepartmentAvgOrderBy = {
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "open_data_department". All fields are combined with a logical 'AND'. */
export type OpenDataDepartmentBoolExp = {
  _and?: Maybe<Array<OpenDataDepartmentBoolExp>>;
  _not?: Maybe<OpenDataDepartmentBoolExp>;
  _or?: Maybe<Array<OpenDataDepartmentBoolExp>>;
  area?: Maybe<GeometryComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  openDataCities?: Maybe<OpenDataCityBoolExp>;
  openDataCitiesAggregate?: Maybe<OpenDataCityAggregateBoolExp>;
  region?: Maybe<OpenDataRegionBoolExp>;
  regionId?: Maybe<IntComparisonExp>;
  siren?: Maybe<StringComparisonExp>;
  slug?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "open_data_department" */
export enum OpenDataDepartmentConstraint {
  /** unique or primary key constraint on columns "id" */
  OpenDataDepartmentPkey = 'open_data_department_pkey'
}

/** input type for incrementing numeric columns in table "open_data_department" */
export type OpenDataDepartmentIncInput = {
  id?: Maybe<Scalars['Int']>;
  regionId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "open_data_department" */
export type OpenDataDepartmentInsertInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  openDataCities?: Maybe<OpenDataCityArrRelInsertInput>;
  region?: Maybe<OpenDataRegionObjRelInsertInput>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OpenDataDepartmentMaxFields = {
  __typename?: 'OpenDataDepartmentMaxFields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "open_data_department" */
export type OpenDataDepartmentMaxOrderBy = {
  code?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
  siren?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OpenDataDepartmentMinFields = {
  __typename?: 'OpenDataDepartmentMinFields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "open_data_department" */
export type OpenDataDepartmentMinOrderBy = {
  code?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
  siren?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** response of any mutation on the table "open_data_department" */
export type OpenDataDepartmentMutationResponse = {
  __typename?: 'OpenDataDepartmentMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OpenDataDepartment>;
};

/** input type for inserting object relation for remote table "open_data_department" */
export type OpenDataDepartmentObjRelInsertInput = {
  data: OpenDataDepartmentInsertInput;
  /** upsert condition */
  onConflict?: Maybe<OpenDataDepartmentOnConflict>;
};

/** on_conflict condition type for table "open_data_department" */
export type OpenDataDepartmentOnConflict = {
  constraint: OpenDataDepartmentConstraint;
  updateColumns: Array<OpenDataDepartmentUpdateColumn>;
  where?: Maybe<OpenDataDepartmentBoolExp>;
};

/** Ordering options when selecting data from "open_data_department". */
export type OpenDataDepartmentOrderBy = {
  area?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  openDataCitiesAggregate?: Maybe<OpenDataCityAggregateOrderBy>;
  region?: Maybe<OpenDataRegionOrderBy>;
  regionId?: Maybe<OrderBy>;
  siren?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** primary key columns input for table: open_data_department */
export type OpenDataDepartmentPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "open_data_department" */
export enum OpenDataDepartmentSelectColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'regionId',
  /** column name */
  Siren = 'siren',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "open_data_department" */
export type OpenDataDepartmentSetInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OpenDataDepartmentStddevFields = {
  __typename?: 'OpenDataDepartmentStddevFields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "open_data_department" */
export type OpenDataDepartmentStddevOrderBy = {
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type OpenDataDepartmentStddevPopFields = {
  __typename?: 'OpenDataDepartmentStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "open_data_department" */
export type OpenDataDepartmentStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type OpenDataDepartmentStddevSampFields = {
  __typename?: 'OpenDataDepartmentStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "open_data_department" */
export type OpenDataDepartmentStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "open_data_department" */
export type OpenDataDepartmentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OpenDataDepartmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OpenDataDepartmentStreamCursorValueInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type OpenDataDepartmentSumFields = {
  __typename?: 'OpenDataDepartmentSumFields';
  id?: Maybe<Scalars['Int']>;
  regionId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "open_data_department" */
export type OpenDataDepartmentSumOrderBy = {
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** update columns of table "open_data_department" */
export enum OpenDataDepartmentUpdateColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'regionId',
  /** column name */
  Siren = 'siren',
  /** column name */
  Slug = 'slug'
}

export type OpenDataDepartmentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<OpenDataDepartmentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<OpenDataDepartmentSetInput>;
  /** filter the rows which have to be updated */
  where: OpenDataDepartmentBoolExp;
};

/** aggregate variance on columns */
export type OpenDataDepartmentVarianceFields = {
  __typename?: 'OpenDataDepartmentVarianceFields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "open_data_department" */
export type OpenDataDepartmentVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type OpenDataDepartmentVarPopFields = {
  __typename?: 'OpenDataDepartmentVarPopFields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "open_data_department" */
export type OpenDataDepartmentVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type OpenDataDepartmentVarSampFields = {
  __typename?: 'OpenDataDepartmentVarSampFields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "open_data_department" */
export type OpenDataDepartmentVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  regionId?: Maybe<OrderBy>;
};

/** import OpenDataSoft de toutes les régions de France (2023) */
export type OpenDataRegion = {
  __typename?: 'OpenDataRegion';
  area: Scalars['geometry'];
  code: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  openDataCities: Array<OpenDataCity>;
  /** An aggregate relationship */
  openDataCitiesAggregate: OpenDataCityAggregate;
  /** An array relationship */
  openDataDepartments: Array<OpenDataDepartment>;
  /** An aggregate relationship */
  openDataDepartmentsAggregate: OpenDataDepartmentAggregate;
  siren?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};


/** import OpenDataSoft de toutes les régions de France (2023) */
export type OpenDataRegionOpenDataCitiesArgs = {
  distinctOn?: Maybe<Array<OpenDataCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataCityOrderBy>>;
  where?: Maybe<OpenDataCityBoolExp>;
};


/** import OpenDataSoft de toutes les régions de France (2023) */
export type OpenDataRegionOpenDataCitiesAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataCityOrderBy>>;
  where?: Maybe<OpenDataCityBoolExp>;
};


/** import OpenDataSoft de toutes les régions de France (2023) */
export type OpenDataRegionOpenDataDepartmentsArgs = {
  distinctOn?: Maybe<Array<OpenDataDepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataDepartmentOrderBy>>;
  where?: Maybe<OpenDataDepartmentBoolExp>;
};


/** import OpenDataSoft de toutes les régions de France (2023) */
export type OpenDataRegionOpenDataDepartmentsAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataDepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataDepartmentOrderBy>>;
  where?: Maybe<OpenDataDepartmentBoolExp>;
};

/** aggregated selection of "open_data_region" */
export type OpenDataRegionAggregate = {
  __typename?: 'OpenDataRegionAggregate';
  aggregate?: Maybe<OpenDataRegionAggregateFields>;
  nodes: Array<OpenDataRegion>;
};

/** aggregate fields of "open_data_region" */
export type OpenDataRegionAggregateFields = {
  __typename?: 'OpenDataRegionAggregateFields';
  avg?: Maybe<OpenDataRegionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OpenDataRegionMaxFields>;
  min?: Maybe<OpenDataRegionMinFields>;
  stddev?: Maybe<OpenDataRegionStddevFields>;
  stddevPop?: Maybe<OpenDataRegionStddevPopFields>;
  stddevSamp?: Maybe<OpenDataRegionStddevSampFields>;
  sum?: Maybe<OpenDataRegionSumFields>;
  varPop?: Maybe<OpenDataRegionVarPopFields>;
  varSamp?: Maybe<OpenDataRegionVarSampFields>;
  variance?: Maybe<OpenDataRegionVarianceFields>;
};


/** aggregate fields of "open_data_region" */
export type OpenDataRegionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OpenDataRegionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OpenDataRegionAvgFields = {
  __typename?: 'OpenDataRegionAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "open_data_region". All fields are combined with a logical 'AND'. */
export type OpenDataRegionBoolExp = {
  _and?: Maybe<Array<OpenDataRegionBoolExp>>;
  _not?: Maybe<OpenDataRegionBoolExp>;
  _or?: Maybe<Array<OpenDataRegionBoolExp>>;
  area?: Maybe<GeometryComparisonExp>;
  code?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  openDataCities?: Maybe<OpenDataCityBoolExp>;
  openDataCitiesAggregate?: Maybe<OpenDataCityAggregateBoolExp>;
  openDataDepartments?: Maybe<OpenDataDepartmentBoolExp>;
  openDataDepartmentsAggregate?: Maybe<OpenDataDepartmentAggregateBoolExp>;
  siren?: Maybe<StringComparisonExp>;
  slug?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "open_data_region" */
export enum OpenDataRegionConstraint {
  /** unique or primary key constraint on columns "id" */
  OpenDataRegionPkey = 'open_data_region_pkey'
}

/** input type for incrementing numeric columns in table "open_data_region" */
export type OpenDataRegionIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "open_data_region" */
export type OpenDataRegionInsertInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  openDataCities?: Maybe<OpenDataCityArrRelInsertInput>;
  openDataDepartments?: Maybe<OpenDataDepartmentArrRelInsertInput>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OpenDataRegionMaxFields = {
  __typename?: 'OpenDataRegionMaxFields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OpenDataRegionMinFields = {
  __typename?: 'OpenDataRegionMinFields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "open_data_region" */
export type OpenDataRegionMutationResponse = {
  __typename?: 'OpenDataRegionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OpenDataRegion>;
};

/** input type for inserting object relation for remote table "open_data_region" */
export type OpenDataRegionObjRelInsertInput = {
  data: OpenDataRegionInsertInput;
  /** upsert condition */
  onConflict?: Maybe<OpenDataRegionOnConflict>;
};

/** on_conflict condition type for table "open_data_region" */
export type OpenDataRegionOnConflict = {
  constraint: OpenDataRegionConstraint;
  updateColumns: Array<OpenDataRegionUpdateColumn>;
  where?: Maybe<OpenDataRegionBoolExp>;
};

/** Ordering options when selecting data from "open_data_region". */
export type OpenDataRegionOrderBy = {
  area?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  openDataCitiesAggregate?: Maybe<OpenDataCityAggregateOrderBy>;
  openDataDepartmentsAggregate?: Maybe<OpenDataDepartmentAggregateOrderBy>;
  siren?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** primary key columns input for table: open_data_region */
export type OpenDataRegionPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "open_data_region" */
export enum OpenDataRegionSelectColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Siren = 'siren',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "open_data_region" */
export type OpenDataRegionSetInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type OpenDataRegionStddevFields = {
  __typename?: 'OpenDataRegionStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type OpenDataRegionStddevPopFields = {
  __typename?: 'OpenDataRegionStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type OpenDataRegionStddevSampFields = {
  __typename?: 'OpenDataRegionStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "open_data_region" */
export type OpenDataRegionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OpenDataRegionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OpenDataRegionStreamCursorValueInput = {
  area?: Maybe<Scalars['geometry']>;
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  siren?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type OpenDataRegionSumFields = {
  __typename?: 'OpenDataRegionSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "open_data_region" */
export enum OpenDataRegionUpdateColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Siren = 'siren',
  /** column name */
  Slug = 'slug'
}

export type OpenDataRegionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<OpenDataRegionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<OpenDataRegionSetInput>;
  /** filter the rows which have to be updated */
  where: OpenDataRegionBoolExp;
};

/** aggregate variance on columns */
export type OpenDataRegionVarianceFields = {
  __typename?: 'OpenDataRegionVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type OpenDataRegionVarPopFields = {
  __typename?: 'OpenDataRegionVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type OpenDataRegionVarSampFields = {
  __typename?: 'OpenDataRegionVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST'
}

/** columns and relationships of "private_individual" */
export type PrivateIndividual = {
  __typename?: 'PrivateIndividual';
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "private_individual" */
export type PrivateIndividualAggregate = {
  __typename?: 'PrivateIndividualAggregate';
  aggregate?: Maybe<PrivateIndividualAggregateFields>;
  nodes: Array<PrivateIndividual>;
};

/** aggregate fields of "private_individual" */
export type PrivateIndividualAggregateFields = {
  __typename?: 'PrivateIndividualAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<PrivateIndividualMaxFields>;
  min?: Maybe<PrivateIndividualMinFields>;
};


/** aggregate fields of "private_individual" */
export type PrivateIndividualAggregateFieldsCountArgs = {
  columns?: Maybe<Array<PrivateIndividualSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "private_individual". All fields are combined with a logical 'AND'. */
export type PrivateIndividualBoolExp = {
  _and?: Maybe<Array<PrivateIndividualBoolExp>>;
  _not?: Maybe<PrivateIndividualBoolExp>;
  _or?: Maybe<Array<PrivateIndividualBoolExp>>;
  email?: Maybe<StringComparisonExp>;
  familyName?: Maybe<StringComparisonExp>;
  givenName?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type PrivateIndividualMaxFields = {
  __typename?: 'PrivateIndividualMaxFields';
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PrivateIndividualMinFields = {
  __typename?: 'PrivateIndividualMinFields';
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "private_individual". */
export type PrivateIndividualOrderBy = {
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
};

/** select columns of table "private_individual" */
export enum PrivateIndividualSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName'
}

/** Streaming cursor of the table "private_individual" */
export type PrivateIndividualStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PrivateIndividualStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PrivateIndividualStreamCursorValueInput = {
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
};

/** columns and relationships of "pro_certification" */
export type ProCertification = {
  __typename?: 'ProCertification';
  /** An object relationship */
  certification: Certification;
  certificationId: Scalars['Int'];
  /** An object relationship */
  proPresentation: ProPresentation;
  proPresentationId: Scalars['Int'];
};

/** aggregated selection of "pro_certification" */
export type ProCertificationAggregate = {
  __typename?: 'ProCertificationAggregate';
  aggregate?: Maybe<ProCertificationAggregateFields>;
  nodes: Array<ProCertification>;
};

export type ProCertificationAggregateBoolExp = {
  count?: Maybe<ProCertificationAggregateBoolExpCount>;
};

export type ProCertificationAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProCertificationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProCertificationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_certification" */
export type ProCertificationAggregateFields = {
  __typename?: 'ProCertificationAggregateFields';
  avg?: Maybe<ProCertificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProCertificationMaxFields>;
  min?: Maybe<ProCertificationMinFields>;
  stddev?: Maybe<ProCertificationStddevFields>;
  stddevPop?: Maybe<ProCertificationStddevPopFields>;
  stddevSamp?: Maybe<ProCertificationStddevSampFields>;
  sum?: Maybe<ProCertificationSumFields>;
  varPop?: Maybe<ProCertificationVarPopFields>;
  varSamp?: Maybe<ProCertificationVarSampFields>;
  variance?: Maybe<ProCertificationVarianceFields>;
};


/** aggregate fields of "pro_certification" */
export type ProCertificationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProCertificationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_certification" */
export type ProCertificationAggregateOrderBy = {
  avg?: Maybe<ProCertificationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProCertificationMaxOrderBy>;
  min?: Maybe<ProCertificationMinOrderBy>;
  stddev?: Maybe<ProCertificationStddevOrderBy>;
  stddevPop?: Maybe<ProCertificationStddevPopOrderBy>;
  stddevSamp?: Maybe<ProCertificationStddevSampOrderBy>;
  sum?: Maybe<ProCertificationSumOrderBy>;
  varPop?: Maybe<ProCertificationVarPopOrderBy>;
  varSamp?: Maybe<ProCertificationVarSampOrderBy>;
  variance?: Maybe<ProCertificationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_certification" */
export type ProCertificationArrRelInsertInput = {
  data: Array<ProCertificationInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProCertificationOnConflict>;
};

/** aggregate avg on columns */
export type ProCertificationAvgFields = {
  __typename?: 'ProCertificationAvgFields';
  certificationId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_certification" */
export type ProCertificationAvgOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_certification". All fields are combined with a logical 'AND'. */
export type ProCertificationBoolExp = {
  _and?: Maybe<Array<ProCertificationBoolExp>>;
  _not?: Maybe<ProCertificationBoolExp>;
  _or?: Maybe<Array<ProCertificationBoolExp>>;
  certification?: Maybe<CertificationBoolExp>;
  certificationId?: Maybe<IntComparisonExp>;
  proPresentation?: Maybe<ProPresentationBoolExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "pro_certification" */
export enum ProCertificationConstraint {
  /** unique or primary key constraint on columns "pro_presentation_id", "certification_id" */
  ProCertificationPkey = 'pro_certification_pkey'
}

/** input type for incrementing numeric columns in table "pro_certification" */
export type ProCertificationIncInput = {
  certificationId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_certification" */
export type ProCertificationInsertInput = {
  certification?: Maybe<CertificationObjRelInsertInput>;
  certificationId?: Maybe<Scalars['Int']>;
  proPresentation?: Maybe<ProPresentationObjRelInsertInput>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProCertificationMaxFields = {
  __typename?: 'ProCertificationMaxFields';
  certificationId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pro_certification" */
export type ProCertificationMaxOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProCertificationMinFields = {
  __typename?: 'ProCertificationMinFields';
  certificationId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pro_certification" */
export type ProCertificationMinOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_certification" */
export type ProCertificationMutationResponse = {
  __typename?: 'ProCertificationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProCertification>;
};

/** on_conflict condition type for table "pro_certification" */
export type ProCertificationOnConflict = {
  constraint: ProCertificationConstraint;
  updateColumns: Array<ProCertificationUpdateColumn>;
  where?: Maybe<ProCertificationBoolExp>;
};

/** Ordering options when selecting data from "pro_certification". */
export type ProCertificationOrderBy = {
  certification?: Maybe<CertificationOrderBy>;
  certificationId?: Maybe<OrderBy>;
  proPresentation?: Maybe<ProPresentationOrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_certification */
export type ProCertificationPkColumnsInput = {
  certificationId: Scalars['Int'];
  proPresentationId: Scalars['Int'];
};

/** select columns of table "pro_certification" */
export enum ProCertificationSelectColumn {
  /** column name */
  CertificationId = 'certificationId',
  /** column name */
  ProPresentationId = 'proPresentationId'
}

/** input type for updating data in table "pro_certification" */
export type ProCertificationSetInput = {
  certificationId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProCertificationStddevFields = {
  __typename?: 'ProCertificationStddevFields';
  certificationId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_certification" */
export type ProCertificationStddevOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProCertificationStddevPopFields = {
  __typename?: 'ProCertificationStddevPopFields';
  certificationId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_certification" */
export type ProCertificationStddevPopOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProCertificationStddevSampFields = {
  __typename?: 'ProCertificationStddevSampFields';
  certificationId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_certification" */
export type ProCertificationStddevSampOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_certification" */
export type ProCertificationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProCertificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProCertificationStreamCursorValueInput = {
  certificationId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProCertificationSumFields = {
  __typename?: 'ProCertificationSumFields';
  certificationId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_certification" */
export type ProCertificationSumOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** update columns of table "pro_certification" */
export enum ProCertificationUpdateColumn {
  /** column name */
  CertificationId = 'certificationId',
  /** column name */
  ProPresentationId = 'proPresentationId'
}

export type ProCertificationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProCertificationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProCertificationSetInput>;
  /** filter the rows which have to be updated */
  where: ProCertificationBoolExp;
};

/** aggregate variance on columns */
export type ProCertificationVarianceFields = {
  __typename?: 'ProCertificationVarianceFields';
  certificationId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_certification" */
export type ProCertificationVarianceOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProCertificationVarPopFields = {
  __typename?: 'ProCertificationVarPopFields';
  certificationId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_certification" */
export type ProCertificationVarPopOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProCertificationVarSampFields = {
  __typename?: 'ProCertificationVarSampFields';
  certificationId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_certification" */
export type ProCertificationVarSampOrderBy = {
  certificationId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_holiday" */
export type ProHoliday = {
  __typename?: 'ProHoliday';
  endDate: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
  startDate: Scalars['timestamptz'];
};

/** aggregated selection of "pro_holiday" */
export type ProHolidayAggregate = {
  __typename?: 'ProHolidayAggregate';
  aggregate?: Maybe<ProHolidayAggregateFields>;
  nodes: Array<ProHoliday>;
};

export type ProHolidayAggregateBoolExp = {
  count?: Maybe<ProHolidayAggregateBoolExpCount>;
};

export type ProHolidayAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProHolidaySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProHolidayBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_holiday" */
export type ProHolidayAggregateFields = {
  __typename?: 'ProHolidayAggregateFields';
  avg?: Maybe<ProHolidayAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProHolidayMaxFields>;
  min?: Maybe<ProHolidayMinFields>;
  stddev?: Maybe<ProHolidayStddevFields>;
  stddevPop?: Maybe<ProHolidayStddevPopFields>;
  stddevSamp?: Maybe<ProHolidayStddevSampFields>;
  sum?: Maybe<ProHolidaySumFields>;
  varPop?: Maybe<ProHolidayVarPopFields>;
  varSamp?: Maybe<ProHolidayVarSampFields>;
  variance?: Maybe<ProHolidayVarianceFields>;
};


/** aggregate fields of "pro_holiday" */
export type ProHolidayAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProHolidaySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_holiday" */
export type ProHolidayAggregateOrderBy = {
  avg?: Maybe<ProHolidayAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProHolidayMaxOrderBy>;
  min?: Maybe<ProHolidayMinOrderBy>;
  stddev?: Maybe<ProHolidayStddevOrderBy>;
  stddevPop?: Maybe<ProHolidayStddevPopOrderBy>;
  stddevSamp?: Maybe<ProHolidayStddevSampOrderBy>;
  sum?: Maybe<ProHolidaySumOrderBy>;
  varPop?: Maybe<ProHolidayVarPopOrderBy>;
  varSamp?: Maybe<ProHolidayVarSampOrderBy>;
  variance?: Maybe<ProHolidayVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_holiday" */
export type ProHolidayArrRelInsertInput = {
  data: Array<ProHolidayInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProHolidayOnConflict>;
};

/** aggregate avg on columns */
export type ProHolidayAvgFields = {
  __typename?: 'ProHolidayAvgFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_holiday" */
export type ProHolidayAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_holiday". All fields are combined with a logical 'AND'. */
export type ProHolidayBoolExp = {
  _and?: Maybe<Array<ProHolidayBoolExp>>;
  _not?: Maybe<ProHolidayBoolExp>;
  _or?: Maybe<Array<ProHolidayBoolExp>>;
  endDate?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
  startDate?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "pro_holiday" */
export enum ProHolidayConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22906Primary = 'idx_22906_primary'
}

/** input type for incrementing numeric columns in table "pro_holiday" */
export type ProHolidayIncInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_holiday" */
export type ProHolidayInsertInput = {
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProHolidayMaxFields = {
  __typename?: 'ProHolidayMaxFields';
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "pro_holiday" */
export type ProHolidayMaxOrderBy = {
  endDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProHolidayMinFields = {
  __typename?: 'ProHolidayMinFields';
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "pro_holiday" */
export type ProHolidayMinOrderBy = {
  endDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_holiday" */
export type ProHolidayMutationResponse = {
  __typename?: 'ProHolidayMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProHoliday>;
};

/** on_conflict condition type for table "pro_holiday" */
export type ProHolidayOnConflict = {
  constraint: ProHolidayConstraint;
  updateColumns: Array<ProHolidayUpdateColumn>;
  where?: Maybe<ProHolidayBoolExp>;
};

/** Ordering options when selecting data from "pro_holiday". */
export type ProHolidayOrderBy = {
  endDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_holiday */
export type ProHolidayPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_holiday" */
export enum ProHolidaySelectColumn {
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  StartDate = 'startDate'
}

/** input type for updating data in table "pro_holiday" */
export type ProHolidaySetInput = {
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProHolidayStddevFields = {
  __typename?: 'ProHolidayStddevFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_holiday" */
export type ProHolidayStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProHolidayStddevPopFields = {
  __typename?: 'ProHolidayStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_holiday" */
export type ProHolidayStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProHolidayStddevSampFields = {
  __typename?: 'ProHolidayStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_holiday" */
export type ProHolidayStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_holiday" */
export type ProHolidayStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProHolidayStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProHolidayStreamCursorValueInput = {
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ProHolidaySumFields = {
  __typename?: 'ProHolidaySumFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_holiday" */
export type ProHolidaySumOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "pro_holiday" */
export enum ProHolidayUpdateColumn {
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  StartDate = 'startDate'
}

export type ProHolidayUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProHolidayIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProHolidaySetInput>;
  /** filter the rows which have to be updated */
  where: ProHolidayBoolExp;
};

/** aggregate variance on columns */
export type ProHolidayVarianceFields = {
  __typename?: 'ProHolidayVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_holiday" */
export type ProHolidayVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProHolidayVarPopFields = {
  __typename?: 'ProHolidayVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_holiday" */
export type ProHolidayVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProHolidayVarSampFields = {
  __typename?: 'ProHolidayVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_holiday" */
export type ProHolidayVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_intervention" */
export type ProIntervention = {
  __typename?: 'ProIntervention';
  area?: Maybe<Scalars['geometry']>;
  id: Scalars['Int'];
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
  type: Scalars['String'];
};

/** aggregated selection of "pro_intervention" */
export type ProInterventionAggregate = {
  __typename?: 'ProInterventionAggregate';
  aggregate?: Maybe<ProInterventionAggregateFields>;
  nodes: Array<ProIntervention>;
};

export type ProInterventionAggregateBoolExp = {
  count?: Maybe<ProInterventionAggregateBoolExpCount>;
};

export type ProInterventionAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProInterventionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProInterventionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_intervention" */
export type ProInterventionAggregateFields = {
  __typename?: 'ProInterventionAggregateFields';
  avg?: Maybe<ProInterventionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProInterventionMaxFields>;
  min?: Maybe<ProInterventionMinFields>;
  stddev?: Maybe<ProInterventionStddevFields>;
  stddevPop?: Maybe<ProInterventionStddevPopFields>;
  stddevSamp?: Maybe<ProInterventionStddevSampFields>;
  sum?: Maybe<ProInterventionSumFields>;
  varPop?: Maybe<ProInterventionVarPopFields>;
  varSamp?: Maybe<ProInterventionVarSampFields>;
  variance?: Maybe<ProInterventionVarianceFields>;
};


/** aggregate fields of "pro_intervention" */
export type ProInterventionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProInterventionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_intervention" */
export type ProInterventionAggregateOrderBy = {
  avg?: Maybe<ProInterventionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProInterventionMaxOrderBy>;
  min?: Maybe<ProInterventionMinOrderBy>;
  stddev?: Maybe<ProInterventionStddevOrderBy>;
  stddevPop?: Maybe<ProInterventionStddevPopOrderBy>;
  stddevSamp?: Maybe<ProInterventionStddevSampOrderBy>;
  sum?: Maybe<ProInterventionSumOrderBy>;
  varPop?: Maybe<ProInterventionVarPopOrderBy>;
  varSamp?: Maybe<ProInterventionVarSampOrderBy>;
  variance?: Maybe<ProInterventionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_intervention" */
export type ProInterventionArrRelInsertInput = {
  data: Array<ProInterventionInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProInterventionOnConflict>;
};

/** aggregate avg on columns */
export type ProInterventionAvgFields = {
  __typename?: 'ProInterventionAvgFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_intervention" */
export type ProInterventionAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_intervention". All fields are combined with a logical 'AND'. */
export type ProInterventionBoolExp = {
  _and?: Maybe<Array<ProInterventionBoolExp>>;
  _not?: Maybe<ProInterventionBoolExp>;
  _or?: Maybe<Array<ProInterventionBoolExp>>;
  area?: Maybe<GeometryComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
  type?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pro_intervention" */
export enum ProInterventionConstraint {
  /** unique or primary key constraint on columns "id" */
  ProInterventionPkey = 'pro_intervention_pkey'
}

/** input type for incrementing numeric columns in table "pro_intervention" */
export type ProInterventionIncInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_intervention" */
export type ProInterventionInsertInput = {
  area?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['Int']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProInterventionMaxFields = {
  __typename?: 'ProInterventionMaxFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pro_intervention" */
export type ProInterventionMaxOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProInterventionMinFields = {
  __typename?: 'ProInterventionMinFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pro_intervention" */
export type ProInterventionMinOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_intervention" */
export type ProInterventionMutationResponse = {
  __typename?: 'ProInterventionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProIntervention>;
};

/** on_conflict condition type for table "pro_intervention" */
export type ProInterventionOnConflict = {
  constraint: ProInterventionConstraint;
  updateColumns: Array<ProInterventionUpdateColumn>;
  where?: Maybe<ProInterventionBoolExp>;
};

/** Ordering options when selecting data from "pro_intervention". */
export type ProInterventionOrderBy = {
  area?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_intervention */
export type ProInterventionPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_intervention" */
export enum ProInterventionSelectColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Id = 'id',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "pro_intervention" */
export type ProInterventionSetInput = {
  area?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProInterventionStddevFields = {
  __typename?: 'ProInterventionStddevFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_intervention" */
export type ProInterventionStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProInterventionStddevPopFields = {
  __typename?: 'ProInterventionStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_intervention" */
export type ProInterventionStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProInterventionStddevSampFields = {
  __typename?: 'ProInterventionStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_intervention" */
export type ProInterventionStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_intervention" */
export type ProInterventionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProInterventionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProInterventionStreamCursorValueInput = {
  area?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProInterventionSumFields = {
  __typename?: 'ProInterventionSumFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_intervention" */
export type ProInterventionSumOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "pro_intervention" */
export enum ProInterventionUpdateColumn {
  /** column name */
  Area = 'area',
  /** column name */
  Id = 'id',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  Type = 'type'
}

export type ProInterventionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProInterventionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProInterventionSetInput>;
  /** filter the rows which have to be updated */
  where: ProInterventionBoolExp;
};

/** aggregate variance on columns */
export type ProInterventionVarianceFields = {
  __typename?: 'ProInterventionVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_intervention" */
export type ProInterventionVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProInterventionVarPopFields = {
  __typename?: 'ProInterventionVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_intervention" */
export type ProInterventionVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProInterventionVarSampFields = {
  __typename?: 'ProInterventionVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_intervention" */
export type ProInterventionVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_label" */
export type ProLabel = {
  __typename?: 'ProLabel';
  certificateName: Scalars['String'];
  dateChecked: Scalars['timestamptz'];
  domain: Scalars['String'];
  id: Scalars['Int'];
  isCheckActive: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  proPresentationId: Scalars['Int'];
  /** An object relationship */
  proPresentations: ProPresentation;
  qualificationUrl: Scalars['String'];
};

/** aggregated selection of "pro_label" */
export type ProLabelAggregate = {
  __typename?: 'ProLabelAggregate';
  aggregate?: Maybe<ProLabelAggregateFields>;
  nodes: Array<ProLabel>;
};

export type ProLabelAggregateBoolExp = {
  bool_and?: Maybe<ProLabelAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<ProLabelAggregateBoolExpBoolOr>;
  count?: Maybe<ProLabelAggregateBoolExpCount>;
};

export type ProLabelAggregateBoolExpBoolAnd = {
  arguments: ProLabelSelectColumnProLabelAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProLabelBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProLabelAggregateBoolExpBoolOr = {
  arguments: ProLabelSelectColumnProLabelAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProLabelBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProLabelAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProLabelSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProLabelBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_label" */
export type ProLabelAggregateFields = {
  __typename?: 'ProLabelAggregateFields';
  avg?: Maybe<ProLabelAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProLabelMaxFields>;
  min?: Maybe<ProLabelMinFields>;
  stddev?: Maybe<ProLabelStddevFields>;
  stddevPop?: Maybe<ProLabelStddevPopFields>;
  stddevSamp?: Maybe<ProLabelStddevSampFields>;
  sum?: Maybe<ProLabelSumFields>;
  varPop?: Maybe<ProLabelVarPopFields>;
  varSamp?: Maybe<ProLabelVarSampFields>;
  variance?: Maybe<ProLabelVarianceFields>;
};


/** aggregate fields of "pro_label" */
export type ProLabelAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProLabelSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_label" */
export type ProLabelAggregateOrderBy = {
  avg?: Maybe<ProLabelAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProLabelMaxOrderBy>;
  min?: Maybe<ProLabelMinOrderBy>;
  stddev?: Maybe<ProLabelStddevOrderBy>;
  stddevPop?: Maybe<ProLabelStddevPopOrderBy>;
  stddevSamp?: Maybe<ProLabelStddevSampOrderBy>;
  sum?: Maybe<ProLabelSumOrderBy>;
  varPop?: Maybe<ProLabelVarPopOrderBy>;
  varSamp?: Maybe<ProLabelVarSampOrderBy>;
  variance?: Maybe<ProLabelVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_label" */
export type ProLabelArrRelInsertInput = {
  data: Array<ProLabelInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProLabelOnConflict>;
};

/** aggregate avg on columns */
export type ProLabelAvgFields = {
  __typename?: 'ProLabelAvgFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_label" */
export type ProLabelAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_label". All fields are combined with a logical 'AND'. */
export type ProLabelBoolExp = {
  _and?: Maybe<Array<ProLabelBoolExp>>;
  _not?: Maybe<ProLabelBoolExp>;
  _or?: Maybe<Array<ProLabelBoolExp>>;
  certificateName?: Maybe<StringComparisonExp>;
  dateChecked?: Maybe<TimestamptzComparisonExp>;
  domain?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  isCheckActive?: Maybe<BooleanComparisonExp>;
  isVisible?: Maybe<BooleanComparisonExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
  proPresentations?: Maybe<ProPresentationBoolExp>;
  qualificationUrl?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pro_label" */
export enum ProLabelConstraint {
  /** unique or primary key constraint on columns "id" */
  ProLabelPkey = 'pro_label_pkey'
}

/** input type for incrementing numeric columns in table "pro_label" */
export type ProLabelIncInput = {
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_label" */
export type ProLabelInsertInput = {
  certificateName?: Maybe<Scalars['String']>;
  dateChecked?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isCheckActive?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proPresentations?: Maybe<ProPresentationObjRelInsertInput>;
  qualificationUrl?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProLabelMaxFields = {
  __typename?: 'ProLabelMaxFields';
  certificateName?: Maybe<Scalars['String']>;
  dateChecked?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  qualificationUrl?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pro_label" */
export type ProLabelMaxOrderBy = {
  certificateName?: Maybe<OrderBy>;
  dateChecked?: Maybe<OrderBy>;
  domain?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  qualificationUrl?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProLabelMinFields = {
  __typename?: 'ProLabelMinFields';
  certificateName?: Maybe<Scalars['String']>;
  dateChecked?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  qualificationUrl?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pro_label" */
export type ProLabelMinOrderBy = {
  certificateName?: Maybe<OrderBy>;
  dateChecked?: Maybe<OrderBy>;
  domain?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  qualificationUrl?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_label" */
export type ProLabelMutationResponse = {
  __typename?: 'ProLabelMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProLabel>;
};

/** on_conflict condition type for table "pro_label" */
export type ProLabelOnConflict = {
  constraint: ProLabelConstraint;
  updateColumns: Array<ProLabelUpdateColumn>;
  where?: Maybe<ProLabelBoolExp>;
};

/** Ordering options when selecting data from "pro_label". */
export type ProLabelOrderBy = {
  certificateName?: Maybe<OrderBy>;
  dateChecked?: Maybe<OrderBy>;
  domain?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isCheckActive?: Maybe<OrderBy>;
  isVisible?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proPresentations?: Maybe<ProPresentationOrderBy>;
  qualificationUrl?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_label */
export type ProLabelPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_label" */
export enum ProLabelSelectColumn {
  /** column name */
  CertificateName = 'certificateName',
  /** column name */
  DateChecked = 'dateChecked',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IsCheckActive = 'isCheckActive',
  /** column name */
  IsVisible = 'isVisible',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  QualificationUrl = 'qualificationUrl'
}

/** select "proLabelAggregateBoolExpBool_andArgumentsColumns" columns of table "pro_label" */
export enum ProLabelSelectColumnProLabelAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsCheckActive = 'isCheckActive',
  /** column name */
  IsVisible = 'isVisible'
}

/** select "proLabelAggregateBoolExpBool_orArgumentsColumns" columns of table "pro_label" */
export enum ProLabelSelectColumnProLabelAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsCheckActive = 'isCheckActive',
  /** column name */
  IsVisible = 'isVisible'
}

/** input type for updating data in table "pro_label" */
export type ProLabelSetInput = {
  certificateName?: Maybe<Scalars['String']>;
  dateChecked?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isCheckActive?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  qualificationUrl?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProLabelStddevFields = {
  __typename?: 'ProLabelStddevFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_label" */
export type ProLabelStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProLabelStddevPopFields = {
  __typename?: 'ProLabelStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_label" */
export type ProLabelStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProLabelStddevSampFields = {
  __typename?: 'ProLabelStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_label" */
export type ProLabelStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_label" */
export type ProLabelStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProLabelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProLabelStreamCursorValueInput = {
  certificateName?: Maybe<Scalars['String']>;
  dateChecked?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isCheckActive?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  qualificationUrl?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProLabelSumFields = {
  __typename?: 'ProLabelSumFields';
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_label" */
export type ProLabelSumOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** update columns of table "pro_label" */
export enum ProLabelUpdateColumn {
  /** column name */
  CertificateName = 'certificateName',
  /** column name */
  DateChecked = 'dateChecked',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IsCheckActive = 'isCheckActive',
  /** column name */
  IsVisible = 'isVisible',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  QualificationUrl = 'qualificationUrl'
}

export type ProLabelUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProLabelIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProLabelSetInput>;
  /** filter the rows which have to be updated */
  where: ProLabelBoolExp;
};

/** aggregate variance on columns */
export type ProLabelVarianceFields = {
  __typename?: 'ProLabelVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_label" */
export type ProLabelVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProLabelVarPopFields = {
  __typename?: 'ProLabelVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_label" */
export type ProLabelVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProLabelVarSampFields = {
  __typename?: 'ProLabelVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_label" */
export type ProLabelVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_mandate" */
export type ProMandate = {
  __typename?: 'ProMandate';
  bic: Scalars['String'];
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated: Scalars['timestamptz'];
  dateSigned?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<Scalars['jsonb']>;
  fileName?: Maybe<Scalars['String']>;
  iban: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  proOrganization: ProOrganization;
  proOrganizationId: Scalars['Int'];
  rum: Scalars['String'];
  status: Scalars['String'];
  /** An object relationship */
  zohoOrganization: ZohoOrganization;
  zohoOrganizationId: Scalars['Int'];
};


/** columns and relationships of "pro_mandate" */
export type ProMandateEventsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pro_mandate" */
export type ProMandateAggregate = {
  __typename?: 'ProMandateAggregate';
  aggregate?: Maybe<ProMandateAggregateFields>;
  nodes: Array<ProMandate>;
};

export type ProMandateAggregateBoolExp = {
  count?: Maybe<ProMandateAggregateBoolExpCount>;
};

export type ProMandateAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProMandateSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProMandateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_mandate" */
export type ProMandateAggregateFields = {
  __typename?: 'ProMandateAggregateFields';
  avg?: Maybe<ProMandateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProMandateMaxFields>;
  min?: Maybe<ProMandateMinFields>;
  stddev?: Maybe<ProMandateStddevFields>;
  stddevPop?: Maybe<ProMandateStddevPopFields>;
  stddevSamp?: Maybe<ProMandateStddevSampFields>;
  sum?: Maybe<ProMandateSumFields>;
  varPop?: Maybe<ProMandateVarPopFields>;
  varSamp?: Maybe<ProMandateVarSampFields>;
  variance?: Maybe<ProMandateVarianceFields>;
};


/** aggregate fields of "pro_mandate" */
export type ProMandateAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProMandateSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_mandate" */
export type ProMandateAggregateOrderBy = {
  avg?: Maybe<ProMandateAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProMandateMaxOrderBy>;
  min?: Maybe<ProMandateMinOrderBy>;
  stddev?: Maybe<ProMandateStddevOrderBy>;
  stddevPop?: Maybe<ProMandateStddevPopOrderBy>;
  stddevSamp?: Maybe<ProMandateStddevSampOrderBy>;
  sum?: Maybe<ProMandateSumOrderBy>;
  varPop?: Maybe<ProMandateVarPopOrderBy>;
  varSamp?: Maybe<ProMandateVarSampOrderBy>;
  variance?: Maybe<ProMandateVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProMandateAppendInput = {
  events?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "pro_mandate" */
export type ProMandateArrRelInsertInput = {
  data: Array<ProMandateInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProMandateOnConflict>;
};

/** aggregate avg on columns */
export type ProMandateAvgFields = {
  __typename?: 'ProMandateAvgFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_mandate" */
export type ProMandateAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_mandate". All fields are combined with a logical 'AND'. */
export type ProMandateBoolExp = {
  _and?: Maybe<Array<ProMandateBoolExp>>;
  _not?: Maybe<ProMandateBoolExp>;
  _or?: Maybe<Array<ProMandateBoolExp>>;
  bic?: Maybe<StringComparisonExp>;
  dateArchived?: Maybe<TimestamptzComparisonExp>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  dateSigned?: Maybe<TimestamptzComparisonExp>;
  events?: Maybe<JsonbComparisonExp>;
  fileName?: Maybe<StringComparisonExp>;
  iban?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  proOrganization?: Maybe<ProOrganizationBoolExp>;
  proOrganizationId?: Maybe<IntComparisonExp>;
  rum?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  zohoOrganization?: Maybe<ZohoOrganizationBoolExp>;
  zohoOrganizationId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "pro_mandate" */
export enum ProMandateConstraint {
  /** unique or primary key constraint on columns "rum" */
  MandateRumKey = 'mandate_rum_key',
  /** unique or primary key constraint on columns "id" */
  ProMandatePkey = 'pro_mandate_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProMandateDeleteAtPathInput = {
  events?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type ProMandateDeleteElemInput = {
  events?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProMandateDeleteKeyInput = {
  events?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "pro_mandate" */
export type ProMandateIncInput = {
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_mandate" */
export type ProMandateInsertInput = {
  bic?: Maybe<Scalars['String']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateSigned?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<Scalars['jsonb']>;
  fileName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganization?: Maybe<ProOrganizationObjRelInsertInput>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  rum?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  zohoOrganization?: Maybe<ZohoOrganizationObjRelInsertInput>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProMandateMaxFields = {
  __typename?: 'ProMandateMaxFields';
  bic?: Maybe<Scalars['String']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateSigned?: Maybe<Scalars['timestamptz']>;
  fileName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  rum?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pro_mandate" */
export type ProMandateMaxOrderBy = {
  bic?: Maybe<OrderBy>;
  dateArchived?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  dateSigned?: Maybe<OrderBy>;
  fileName?: Maybe<OrderBy>;
  iban?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  rum?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProMandateMinFields = {
  __typename?: 'ProMandateMinFields';
  bic?: Maybe<Scalars['String']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateSigned?: Maybe<Scalars['timestamptz']>;
  fileName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  rum?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pro_mandate" */
export type ProMandateMinOrderBy = {
  bic?: Maybe<OrderBy>;
  dateArchived?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  dateSigned?: Maybe<OrderBy>;
  fileName?: Maybe<OrderBy>;
  iban?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  rum?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_mandate" */
export type ProMandateMutationResponse = {
  __typename?: 'ProMandateMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProMandate>;
};

/** on_conflict condition type for table "pro_mandate" */
export type ProMandateOnConflict = {
  constraint: ProMandateConstraint;
  updateColumns: Array<ProMandateUpdateColumn>;
  where?: Maybe<ProMandateBoolExp>;
};

/** Ordering options when selecting data from "pro_mandate". */
export type ProMandateOrderBy = {
  bic?: Maybe<OrderBy>;
  dateArchived?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  dateSigned?: Maybe<OrderBy>;
  events?: Maybe<OrderBy>;
  fileName?: Maybe<OrderBy>;
  iban?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proOrganization?: Maybe<ProOrganizationOrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  rum?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  zohoOrganization?: Maybe<ZohoOrganizationOrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_mandate */
export type ProMandatePkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProMandatePrependInput = {
  events?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "pro_mandate" */
export enum ProMandateSelectColumn {
  /** column name */
  Bic = 'bic',
  /** column name */
  DateArchived = 'dateArchived',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DateSigned = 'dateSigned',
  /** column name */
  Events = 'events',
  /** column name */
  FileName = 'fileName',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  ProOrganizationId = 'proOrganizationId',
  /** column name */
  Rum = 'rum',
  /** column name */
  Status = 'status',
  /** column name */
  ZohoOrganizationId = 'zohoOrganizationId'
}

/** input type for updating data in table "pro_mandate" */
export type ProMandateSetInput = {
  bic?: Maybe<Scalars['String']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateSigned?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<Scalars['jsonb']>;
  fileName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  rum?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProMandateStddevFields = {
  __typename?: 'ProMandateStddevFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_mandate" */
export type ProMandateStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProMandateStddevPopFields = {
  __typename?: 'ProMandateStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_mandate" */
export type ProMandateStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProMandateStddevSampFields = {
  __typename?: 'ProMandateStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_mandate" */
export type ProMandateStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_mandate" */
export type ProMandateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProMandateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProMandateStreamCursorValueInput = {
  bic?: Maybe<Scalars['String']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateSigned?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<Scalars['jsonb']>;
  fileName?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  rum?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProMandateSumFields = {
  __typename?: 'ProMandateSumFields';
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_mandate" */
export type ProMandateSumOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** update columns of table "pro_mandate" */
export enum ProMandateUpdateColumn {
  /** column name */
  Bic = 'bic',
  /** column name */
  DateArchived = 'dateArchived',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DateSigned = 'dateSigned',
  /** column name */
  Events = 'events',
  /** column name */
  FileName = 'fileName',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  ProOrganizationId = 'proOrganizationId',
  /** column name */
  Rum = 'rum',
  /** column name */
  Status = 'status',
  /** column name */
  ZohoOrganizationId = 'zohoOrganizationId'
}

export type ProMandateUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<ProMandateAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: Maybe<ProMandateDeleteAtPathInput>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _deleteElem?: Maybe<ProMandateDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: Maybe<ProMandateDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProMandateIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<ProMandatePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProMandateSetInput>;
  /** filter the rows which have to be updated */
  where: ProMandateBoolExp;
};

/** aggregate variance on columns */
export type ProMandateVarianceFields = {
  __typename?: 'ProMandateVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_mandate" */
export type ProMandateVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProMandateVarPopFields = {
  __typename?: 'ProMandateVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_mandate" */
export type ProMandateVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProMandateVarSampFields = {
  __typename?: 'ProMandateVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_mandate" */
export type ProMandateVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_media_object" */
export type ProMediaObject = {
  __typename?: 'ProMediaObject';
  hash: Scalars['String'];
  id: Scalars['Int'];
  position: Scalars['Int'];
  /** An object relationship */
  proPresentation: ProPresentation;
  proPresentationId: Scalars['Int'];
};

/** aggregated selection of "pro_media_object" */
export type ProMediaObjectAggregate = {
  __typename?: 'ProMediaObjectAggregate';
  aggregate?: Maybe<ProMediaObjectAggregateFields>;
  nodes: Array<ProMediaObject>;
};

export type ProMediaObjectAggregateBoolExp = {
  count?: Maybe<ProMediaObjectAggregateBoolExpCount>;
};

export type ProMediaObjectAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProMediaObjectSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProMediaObjectBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_media_object" */
export type ProMediaObjectAggregateFields = {
  __typename?: 'ProMediaObjectAggregateFields';
  avg?: Maybe<ProMediaObjectAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProMediaObjectMaxFields>;
  min?: Maybe<ProMediaObjectMinFields>;
  stddev?: Maybe<ProMediaObjectStddevFields>;
  stddevPop?: Maybe<ProMediaObjectStddevPopFields>;
  stddevSamp?: Maybe<ProMediaObjectStddevSampFields>;
  sum?: Maybe<ProMediaObjectSumFields>;
  varPop?: Maybe<ProMediaObjectVarPopFields>;
  varSamp?: Maybe<ProMediaObjectVarSampFields>;
  variance?: Maybe<ProMediaObjectVarianceFields>;
};


/** aggregate fields of "pro_media_object" */
export type ProMediaObjectAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProMediaObjectSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_media_object" */
export type ProMediaObjectAggregateOrderBy = {
  avg?: Maybe<ProMediaObjectAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProMediaObjectMaxOrderBy>;
  min?: Maybe<ProMediaObjectMinOrderBy>;
  stddev?: Maybe<ProMediaObjectStddevOrderBy>;
  stddevPop?: Maybe<ProMediaObjectStddevPopOrderBy>;
  stddevSamp?: Maybe<ProMediaObjectStddevSampOrderBy>;
  sum?: Maybe<ProMediaObjectSumOrderBy>;
  varPop?: Maybe<ProMediaObjectVarPopOrderBy>;
  varSamp?: Maybe<ProMediaObjectVarSampOrderBy>;
  variance?: Maybe<ProMediaObjectVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_media_object" */
export type ProMediaObjectArrRelInsertInput = {
  data: Array<ProMediaObjectInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProMediaObjectOnConflict>;
};

/** aggregate avg on columns */
export type ProMediaObjectAvgFields = {
  __typename?: 'ProMediaObjectAvgFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_media_object" */
export type ProMediaObjectAvgOrderBy = {
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_media_object". All fields are combined with a logical 'AND'. */
export type ProMediaObjectBoolExp = {
  _and?: Maybe<Array<ProMediaObjectBoolExp>>;
  _not?: Maybe<ProMediaObjectBoolExp>;
  _or?: Maybe<Array<ProMediaObjectBoolExp>>;
  hash?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  position?: Maybe<IntComparisonExp>;
  proPresentation?: Maybe<ProPresentationBoolExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "pro_media_object" */
export enum ProMediaObjectConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22921Primary = 'idx_22921_primary'
}

/** input type for incrementing numeric columns in table "pro_media_object" */
export type ProMediaObjectIncInput = {
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_media_object" */
export type ProMediaObjectInsertInput = {
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  proPresentation?: Maybe<ProPresentationObjRelInsertInput>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProMediaObjectMaxFields = {
  __typename?: 'ProMediaObjectMaxFields';
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pro_media_object" */
export type ProMediaObjectMaxOrderBy = {
  hash?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProMediaObjectMinFields = {
  __typename?: 'ProMediaObjectMinFields';
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pro_media_object" */
export type ProMediaObjectMinOrderBy = {
  hash?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_media_object" */
export type ProMediaObjectMutationResponse = {
  __typename?: 'ProMediaObjectMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProMediaObject>;
};

/** on_conflict condition type for table "pro_media_object" */
export type ProMediaObjectOnConflict = {
  constraint: ProMediaObjectConstraint;
  updateColumns: Array<ProMediaObjectUpdateColumn>;
  where?: Maybe<ProMediaObjectBoolExp>;
};

/** Ordering options when selecting data from "pro_media_object". */
export type ProMediaObjectOrderBy = {
  hash?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentation?: Maybe<ProPresentationOrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_media_object */
export type ProMediaObjectPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_media_object" */
export enum ProMediaObjectSelectColumn {
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  ProPresentationId = 'proPresentationId'
}

/** input type for updating data in table "pro_media_object" */
export type ProMediaObjectSetInput = {
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProMediaObjectStddevFields = {
  __typename?: 'ProMediaObjectStddevFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_media_object" */
export type ProMediaObjectStddevOrderBy = {
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProMediaObjectStddevPopFields = {
  __typename?: 'ProMediaObjectStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_media_object" */
export type ProMediaObjectStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProMediaObjectStddevSampFields = {
  __typename?: 'ProMediaObjectStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_media_object" */
export type ProMediaObjectStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_media_object" */
export type ProMediaObjectStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProMediaObjectStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProMediaObjectStreamCursorValueInput = {
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProMediaObjectSumFields = {
  __typename?: 'ProMediaObjectSumFields';
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_media_object" */
export type ProMediaObjectSumOrderBy = {
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** update columns of table "pro_media_object" */
export enum ProMediaObjectUpdateColumn {
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  ProPresentationId = 'proPresentationId'
}

export type ProMediaObjectUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProMediaObjectIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProMediaObjectSetInput>;
  /** filter the rows which have to be updated */
  where: ProMediaObjectBoolExp;
};

/** aggregate variance on columns */
export type ProMediaObjectVarianceFields = {
  __typename?: 'ProMediaObjectVarianceFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_media_object" */
export type ProMediaObjectVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProMediaObjectVarPopFields = {
  __typename?: 'ProMediaObjectVarPopFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_media_object" */
export type ProMediaObjectVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProMediaObjectVarSampFields = {
  __typename?: 'ProMediaObjectVarSampFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_media_object" */
export type ProMediaObjectVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_message" */
export type ProMessage = {
  __typename?: 'ProMessage';
  body: Scalars['String'];
  endDate?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  isPermanent: Scalars['Boolean'];
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "pro_message" */
export type ProMessageAggregate = {
  __typename?: 'ProMessageAggregate';
  aggregate?: Maybe<ProMessageAggregateFields>;
  nodes: Array<ProMessage>;
};

export type ProMessageAggregateBoolExp = {
  bool_and?: Maybe<ProMessageAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<ProMessageAggregateBoolExpBoolOr>;
  count?: Maybe<ProMessageAggregateBoolExpCount>;
};

export type ProMessageAggregateBoolExpBoolAnd = {
  arguments: ProMessageSelectColumnProMessageAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProMessageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProMessageAggregateBoolExpBoolOr = {
  arguments: ProMessageSelectColumnProMessageAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProMessageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProMessageAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProMessageSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProMessageBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_message" */
export type ProMessageAggregateFields = {
  __typename?: 'ProMessageAggregateFields';
  avg?: Maybe<ProMessageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProMessageMaxFields>;
  min?: Maybe<ProMessageMinFields>;
  stddev?: Maybe<ProMessageStddevFields>;
  stddevPop?: Maybe<ProMessageStddevPopFields>;
  stddevSamp?: Maybe<ProMessageStddevSampFields>;
  sum?: Maybe<ProMessageSumFields>;
  varPop?: Maybe<ProMessageVarPopFields>;
  varSamp?: Maybe<ProMessageVarSampFields>;
  variance?: Maybe<ProMessageVarianceFields>;
};


/** aggregate fields of "pro_message" */
export type ProMessageAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProMessageSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_message" */
export type ProMessageAggregateOrderBy = {
  avg?: Maybe<ProMessageAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProMessageMaxOrderBy>;
  min?: Maybe<ProMessageMinOrderBy>;
  stddev?: Maybe<ProMessageStddevOrderBy>;
  stddevPop?: Maybe<ProMessageStddevPopOrderBy>;
  stddevSamp?: Maybe<ProMessageStddevSampOrderBy>;
  sum?: Maybe<ProMessageSumOrderBy>;
  varPop?: Maybe<ProMessageVarPopOrderBy>;
  varSamp?: Maybe<ProMessageVarSampOrderBy>;
  variance?: Maybe<ProMessageVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_message" */
export type ProMessageArrRelInsertInput = {
  data: Array<ProMessageInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProMessageOnConflict>;
};

/** aggregate avg on columns */
export type ProMessageAvgFields = {
  __typename?: 'ProMessageAvgFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_message" */
export type ProMessageAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_message". All fields are combined with a logical 'AND'. */
export type ProMessageBoolExp = {
  _and?: Maybe<Array<ProMessageBoolExp>>;
  _not?: Maybe<ProMessageBoolExp>;
  _or?: Maybe<Array<ProMessageBoolExp>>;
  body?: Maybe<StringComparisonExp>;
  endDate?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  isPermanent?: Maybe<BooleanComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
  startDate?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "pro_message" */
export enum ProMessageConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22930Primary = 'idx_22930_primary'
}

/** input type for incrementing numeric columns in table "pro_message" */
export type ProMessageIncInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_message" */
export type ProMessageInsertInput = {
  body?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  isPermanent?: Maybe<Scalars['Boolean']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProMessageMaxFields = {
  __typename?: 'ProMessageMaxFields';
  body?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "pro_message" */
export type ProMessageMaxOrderBy = {
  body?: Maybe<OrderBy>;
  endDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProMessageMinFields = {
  __typename?: 'ProMessageMinFields';
  body?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "pro_message" */
export type ProMessageMinOrderBy = {
  body?: Maybe<OrderBy>;
  endDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_message" */
export type ProMessageMutationResponse = {
  __typename?: 'ProMessageMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProMessage>;
};

/** on_conflict condition type for table "pro_message" */
export type ProMessageOnConflict = {
  constraint: ProMessageConstraint;
  updateColumns: Array<ProMessageUpdateColumn>;
  where?: Maybe<ProMessageBoolExp>;
};

/** Ordering options when selecting data from "pro_message". */
export type ProMessageOrderBy = {
  body?: Maybe<OrderBy>;
  endDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isPermanent?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_message */
export type ProMessagePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_message" */
export enum ProMessageSelectColumn {
  /** column name */
  Body = 'body',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsPermanent = 'isPermanent',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  StartDate = 'startDate'
}

/** select "proMessageAggregateBoolExpBool_andArgumentsColumns" columns of table "pro_message" */
export enum ProMessageSelectColumnProMessageAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsPermanent = 'isPermanent'
}

/** select "proMessageAggregateBoolExpBool_orArgumentsColumns" columns of table "pro_message" */
export enum ProMessageSelectColumnProMessageAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsPermanent = 'isPermanent'
}

/** input type for updating data in table "pro_message" */
export type ProMessageSetInput = {
  body?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  isPermanent?: Maybe<Scalars['Boolean']>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProMessageStddevFields = {
  __typename?: 'ProMessageStddevFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_message" */
export type ProMessageStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProMessageStddevPopFields = {
  __typename?: 'ProMessageStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_message" */
export type ProMessageStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProMessageStddevSampFields = {
  __typename?: 'ProMessageStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_message" */
export type ProMessageStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_message" */
export type ProMessageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProMessageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProMessageStreamCursorValueInput = {
  body?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  isPermanent?: Maybe<Scalars['Boolean']>;
  proViewId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type ProMessageSumFields = {
  __typename?: 'ProMessageSumFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_message" */
export type ProMessageSumOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "pro_message" */
export enum ProMessageUpdateColumn {
  /** column name */
  Body = 'body',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsPermanent = 'isPermanent',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  StartDate = 'startDate'
}

export type ProMessageUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProMessageIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProMessageSetInput>;
  /** filter the rows which have to be updated */
  where: ProMessageBoolExp;
};

/** aggregate variance on columns */
export type ProMessageVarianceFields = {
  __typename?: 'ProMessageVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_message" */
export type ProMessageVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProMessageVarPopFields = {
  __typename?: 'ProMessageVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_message" */
export type ProMessageVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProMessageVarSampFields = {
  __typename?: 'ProMessageVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_message" */
export type ProMessageVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_organization" */
export type ProOrganization = {
  __typename?: 'ProOrganization';
  bic?: Maybe<Scalars['String']>;
  billingEmail: Scalars['String'];
  businessStatus?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dateOfMandate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  firstDatePublished?: Maybe<ProOrganizationFirstDatePublished>;
  iban?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isForeign: Scalars['Boolean'];
  isImportedFromTool?: Maybe<Scalars['Boolean']>;
  mandateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** An array relationship */
  proMandates: Array<ProMandate>;
  /** An aggregate relationship */
  proMandatesAggregate: ProMandateAggregate;
  /** An array relationship */
  proPresentations: Array<ProPresentation>;
  /** An aggregate relationship */
  proPresentationsAggregate: ProPresentationAggregate;
  siret?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tvaNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  zohoConsumers: Array<ZohoConsumer>;
  /** An aggregate relationship */
  zohoConsumersAggregate: ZohoConsumerAggregate;
};


/** columns and relationships of "pro_organization" */
export type ProOrganizationProMandatesArgs = {
  distinctOn?: Maybe<Array<ProMandateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMandateOrderBy>>;
  where?: Maybe<ProMandateBoolExp>;
};


/** columns and relationships of "pro_organization" */
export type ProOrganizationProMandatesAggregateArgs = {
  distinctOn?: Maybe<Array<ProMandateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMandateOrderBy>>;
  where?: Maybe<ProMandateBoolExp>;
};


/** columns and relationships of "pro_organization" */
export type ProOrganizationProPresentationsArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


/** columns and relationships of "pro_organization" */
export type ProOrganizationProPresentationsAggregateArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


/** columns and relationships of "pro_organization" */
export type ProOrganizationZohoConsumersArgs = {
  distinctOn?: Maybe<Array<ZohoConsumerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoConsumerOrderBy>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};


/** columns and relationships of "pro_organization" */
export type ProOrganizationZohoConsumersAggregateArgs = {
  distinctOn?: Maybe<Array<ZohoConsumerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoConsumerOrderBy>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};

/** aggregated selection of "pro_organization" */
export type ProOrganizationAggregate = {
  __typename?: 'ProOrganizationAggregate';
  aggregate?: Maybe<ProOrganizationAggregateFields>;
  nodes: Array<ProOrganization>;
};

/** aggregate fields of "pro_organization" */
export type ProOrganizationAggregateFields = {
  __typename?: 'ProOrganizationAggregateFields';
  avg?: Maybe<ProOrganizationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProOrganizationMaxFields>;
  min?: Maybe<ProOrganizationMinFields>;
  stddev?: Maybe<ProOrganizationStddevFields>;
  stddevPop?: Maybe<ProOrganizationStddevPopFields>;
  stddevSamp?: Maybe<ProOrganizationStddevSampFields>;
  sum?: Maybe<ProOrganizationSumFields>;
  varPop?: Maybe<ProOrganizationVarPopFields>;
  varSamp?: Maybe<ProOrganizationVarSampFields>;
  variance?: Maybe<ProOrganizationVarianceFields>;
};


/** aggregate fields of "pro_organization" */
export type ProOrganizationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProOrganizationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProOrganizationAvgFields = {
  __typename?: 'ProOrganizationAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pro_organization". All fields are combined with a logical 'AND'. */
export type ProOrganizationBoolExp = {
  _and?: Maybe<Array<ProOrganizationBoolExp>>;
  _not?: Maybe<ProOrganizationBoolExp>;
  _or?: Maybe<Array<ProOrganizationBoolExp>>;
  bic?: Maybe<StringComparisonExp>;
  billingEmail?: Maybe<StringComparisonExp>;
  businessStatus?: Maybe<StringComparisonExp>;
  city?: Maybe<StringComparisonExp>;
  dateOfMandate?: Maybe<TimestamptzComparisonExp>;
  firstDatePublished?: Maybe<ProOrganizationFirstDatePublishedBoolExp>;
  iban?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  isForeign?: Maybe<BooleanComparisonExp>;
  isImportedFromTool?: Maybe<BooleanComparisonExp>;
  mandateId?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  postalCode?: Maybe<StringComparisonExp>;
  proMandates?: Maybe<ProMandateBoolExp>;
  proMandatesAggregate?: Maybe<ProMandateAggregateBoolExp>;
  proPresentations?: Maybe<ProPresentationBoolExp>;
  proPresentationsAggregate?: Maybe<ProPresentationAggregateBoolExp>;
  siret?: Maybe<StringComparisonExp>;
  street1?: Maybe<StringComparisonExp>;
  street2?: Maybe<StringComparisonExp>;
  tvaNumber?: Maybe<StringComparisonExp>;
  zohoConsumers?: Maybe<ZohoConsumerBoolExp>;
  zohoConsumersAggregate?: Maybe<ZohoConsumerAggregateBoolExp>;
};

/** unique or primary key constraints on table "pro_organization" */
export enum ProOrganizationConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22939Primary = 'idx_22939_primary'
}

/** columns and relationships of "pro_organization_first_date_published" */
export type ProOrganizationFirstDatePublished = {
  __typename?: 'ProOrganizationFirstDatePublished';
  date?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "pro_organization_first_date_published" */
export type ProOrganizationFirstDatePublishedAggregate = {
  __typename?: 'ProOrganizationFirstDatePublishedAggregate';
  aggregate?: Maybe<ProOrganizationFirstDatePublishedAggregateFields>;
  nodes: Array<ProOrganizationFirstDatePublished>;
};

/** aggregate fields of "pro_organization_first_date_published" */
export type ProOrganizationFirstDatePublishedAggregateFields = {
  __typename?: 'ProOrganizationFirstDatePublishedAggregateFields';
  avg?: Maybe<ProOrganizationFirstDatePublishedAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProOrganizationFirstDatePublishedMaxFields>;
  min?: Maybe<ProOrganizationFirstDatePublishedMinFields>;
  stddev?: Maybe<ProOrganizationFirstDatePublishedStddevFields>;
  stddevPop?: Maybe<ProOrganizationFirstDatePublishedStddevPopFields>;
  stddevSamp?: Maybe<ProOrganizationFirstDatePublishedStddevSampFields>;
  sum?: Maybe<ProOrganizationFirstDatePublishedSumFields>;
  varPop?: Maybe<ProOrganizationFirstDatePublishedVarPopFields>;
  varSamp?: Maybe<ProOrganizationFirstDatePublishedVarSampFields>;
  variance?: Maybe<ProOrganizationFirstDatePublishedVarianceFields>;
};


/** aggregate fields of "pro_organization_first_date_published" */
export type ProOrganizationFirstDatePublishedAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProOrganizationFirstDatePublishedSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProOrganizationFirstDatePublishedAvgFields = {
  __typename?: 'ProOrganizationFirstDatePublishedAvgFields';
  organizationId?: Maybe<Scalars['Float']>;
};

/**
 * Boolean expression to filter rows from the table
 * "pro_organization_first_date_published". All fields are combined with a logical 'AND'.
 */
export type ProOrganizationFirstDatePublishedBoolExp = {
  _and?: Maybe<Array<ProOrganizationFirstDatePublishedBoolExp>>;
  _not?: Maybe<ProOrganizationFirstDatePublishedBoolExp>;
  _or?: Maybe<Array<ProOrganizationFirstDatePublishedBoolExp>>;
  date?: Maybe<TimestamptzComparisonExp>;
  organizationId?: Maybe<IntComparisonExp>;
};

/** input type for inserting data into table "pro_organization_first_date_published" */
export type ProOrganizationFirstDatePublishedInsertInput = {
  date?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProOrganizationFirstDatePublishedMaxFields = {
  __typename?: 'ProOrganizationFirstDatePublishedMaxFields';
  date?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type ProOrganizationFirstDatePublishedMinFields = {
  __typename?: 'ProOrganizationFirstDatePublishedMinFields';
  date?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "pro_organization_first_date_published" */
export type ProOrganizationFirstDatePublishedObjRelInsertInput = {
  data: ProOrganizationFirstDatePublishedInsertInput;
};

/** Ordering options when selecting data from "pro_organization_first_date_published". */
export type ProOrganizationFirstDatePublishedOrderBy = {
  date?: Maybe<OrderBy>;
  organizationId?: Maybe<OrderBy>;
};

/** select columns of table "pro_organization_first_date_published" */
export enum ProOrganizationFirstDatePublishedSelectColumn {
  /** column name */
  Date = 'date',
  /** column name */
  OrganizationId = 'organizationId'
}

/** aggregate stddev on columns */
export type ProOrganizationFirstDatePublishedStddevFields = {
  __typename?: 'ProOrganizationFirstDatePublishedStddevFields';
  organizationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ProOrganizationFirstDatePublishedStddevPopFields = {
  __typename?: 'ProOrganizationFirstDatePublishedStddevPopFields';
  organizationId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ProOrganizationFirstDatePublishedStddevSampFields = {
  __typename?: 'ProOrganizationFirstDatePublishedStddevSampFields';
  organizationId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "pro_organization_first_date_published" */
export type ProOrganizationFirstDatePublishedStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProOrganizationFirstDatePublishedStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProOrganizationFirstDatePublishedStreamCursorValueInput = {
  date?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProOrganizationFirstDatePublishedSumFields = {
  __typename?: 'ProOrganizationFirstDatePublishedSumFields';
  organizationId?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type ProOrganizationFirstDatePublishedVarianceFields = {
  __typename?: 'ProOrganizationFirstDatePublishedVarianceFields';
  organizationId?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type ProOrganizationFirstDatePublishedVarPopFields = {
  __typename?: 'ProOrganizationFirstDatePublishedVarPopFields';
  organizationId?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ProOrganizationFirstDatePublishedVarSampFields = {
  __typename?: 'ProOrganizationFirstDatePublishedVarSampFields';
  organizationId?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "pro_organization" */
export type ProOrganizationIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_organization" */
export type ProOrganizationInsertInput = {
  bic?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  businessStatus?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dateOfMandate?: Maybe<Scalars['timestamptz']>;
  firstDatePublished?: Maybe<ProOrganizationFirstDatePublishedObjRelInsertInput>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isForeign?: Maybe<Scalars['Boolean']>;
  isImportedFromTool?: Maybe<Scalars['Boolean']>;
  mandateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  proMandates?: Maybe<ProMandateArrRelInsertInput>;
  proPresentations?: Maybe<ProPresentationArrRelInsertInput>;
  siret?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tvaNumber?: Maybe<Scalars['String']>;
  zohoConsumers?: Maybe<ZohoConsumerArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProOrganizationMaxFields = {
  __typename?: 'ProOrganizationMaxFields';
  bic?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  businessStatus?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dateOfMandate?: Maybe<Scalars['timestamptz']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mandateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tvaNumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProOrganizationMinFields = {
  __typename?: 'ProOrganizationMinFields';
  bic?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  businessStatus?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dateOfMandate?: Maybe<Scalars['timestamptz']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mandateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tvaNumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pro_organization" */
export type ProOrganizationMutationResponse = {
  __typename?: 'ProOrganizationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProOrganization>;
};

/** input type for inserting object relation for remote table "pro_organization" */
export type ProOrganizationObjRelInsertInput = {
  data: ProOrganizationInsertInput;
  /** upsert condition */
  onConflict?: Maybe<ProOrganizationOnConflict>;
};

/** on_conflict condition type for table "pro_organization" */
export type ProOrganizationOnConflict = {
  constraint: ProOrganizationConstraint;
  updateColumns: Array<ProOrganizationUpdateColumn>;
  where?: Maybe<ProOrganizationBoolExp>;
};

/** Ordering options when selecting data from "pro_organization". */
export type ProOrganizationOrderBy = {
  bic?: Maybe<OrderBy>;
  billingEmail?: Maybe<OrderBy>;
  businessStatus?: Maybe<OrderBy>;
  city?: Maybe<OrderBy>;
  dateOfMandate?: Maybe<OrderBy>;
  firstDatePublished?: Maybe<ProOrganizationFirstDatePublishedOrderBy>;
  iban?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isForeign?: Maybe<OrderBy>;
  isImportedFromTool?: Maybe<OrderBy>;
  mandateId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  proMandatesAggregate?: Maybe<ProMandateAggregateOrderBy>;
  proPresentationsAggregate?: Maybe<ProPresentationAggregateOrderBy>;
  siret?: Maybe<OrderBy>;
  street1?: Maybe<OrderBy>;
  street2?: Maybe<OrderBy>;
  tvaNumber?: Maybe<OrderBy>;
  zohoConsumersAggregate?: Maybe<ZohoConsumerAggregateOrderBy>;
};

/** primary key columns input for table: pro_organization */
export type ProOrganizationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_organization" */
export enum ProOrganizationSelectColumn {
  /** column name */
  Bic = 'bic',
  /** column name */
  BillingEmail = 'billingEmail',
  /** column name */
  BusinessStatus = 'businessStatus',
  /** column name */
  City = 'city',
  /** column name */
  DateOfMandate = 'dateOfMandate',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  IsForeign = 'isForeign',
  /** column name */
  IsImportedFromTool = 'isImportedFromTool',
  /** column name */
  MandateId = 'mandateId',
  /** column name */
  Name = 'name',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  Siret = 'siret',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  TvaNumber = 'tvaNumber'
}

/** input type for updating data in table "pro_organization" */
export type ProOrganizationSetInput = {
  bic?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  businessStatus?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dateOfMandate?: Maybe<Scalars['timestamptz']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isForeign?: Maybe<Scalars['Boolean']>;
  isImportedFromTool?: Maybe<Scalars['Boolean']>;
  mandateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tvaNumber?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProOrganizationStddevFields = {
  __typename?: 'ProOrganizationStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ProOrganizationStddevPopFields = {
  __typename?: 'ProOrganizationStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ProOrganizationStddevSampFields = {
  __typename?: 'ProOrganizationStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "pro_organization" */
export type ProOrganizationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProOrganizationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProOrganizationStreamCursorValueInput = {
  bic?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  businessStatus?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  dateOfMandate?: Maybe<Scalars['timestamptz']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isForeign?: Maybe<Scalars['Boolean']>;
  isImportedFromTool?: Maybe<Scalars['Boolean']>;
  mandateId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  tvaNumber?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProOrganizationSumFields = {
  __typename?: 'ProOrganizationSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "pro_organization" */
export enum ProOrganizationUpdateColumn {
  /** column name */
  Bic = 'bic',
  /** column name */
  BillingEmail = 'billingEmail',
  /** column name */
  BusinessStatus = 'businessStatus',
  /** column name */
  City = 'city',
  /** column name */
  DateOfMandate = 'dateOfMandate',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  IsForeign = 'isForeign',
  /** column name */
  IsImportedFromTool = 'isImportedFromTool',
  /** column name */
  MandateId = 'mandateId',
  /** column name */
  Name = 'name',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  Siret = 'siret',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  TvaNumber = 'tvaNumber'
}

export type ProOrganizationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProOrganizationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProOrganizationSetInput>;
  /** filter the rows which have to be updated */
  where: ProOrganizationBoolExp;
};

/** aggregate variance on columns */
export type ProOrganizationVarianceFields = {
  __typename?: 'ProOrganizationVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type ProOrganizationVarPopFields = {
  __typename?: 'ProOrganizationVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ProOrganizationVarSampFields = {
  __typename?: 'ProOrganizationVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "pro_person" */
export type ProPerson = {
  __typename?: 'ProPerson';
  email?: Maybe<Scalars['String']>;
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Int'];
  /** An array relationship */
  proViews: Array<ProViewProPerson>;
  /** An aggregate relationship */
  proViewsAggregate: ProViewProPersonAggregate;
  telephone?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pro_person" */
export type ProPersonProViewsArgs = {
  distinctOn?: Maybe<Array<ProViewProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProPersonOrderBy>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};


/** columns and relationships of "pro_person" */
export type ProPersonProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProPersonOrderBy>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};

/** aggregated selection of "pro_person" */
export type ProPersonAggregate = {
  __typename?: 'ProPersonAggregate';
  aggregate?: Maybe<ProPersonAggregateFields>;
  nodes: Array<ProPerson>;
};

/** aggregate fields of "pro_person" */
export type ProPersonAggregateFields = {
  __typename?: 'ProPersonAggregateFields';
  avg?: Maybe<ProPersonAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProPersonMaxFields>;
  min?: Maybe<ProPersonMinFields>;
  stddev?: Maybe<ProPersonStddevFields>;
  stddevPop?: Maybe<ProPersonStddevPopFields>;
  stddevSamp?: Maybe<ProPersonStddevSampFields>;
  sum?: Maybe<ProPersonSumFields>;
  varPop?: Maybe<ProPersonVarPopFields>;
  varSamp?: Maybe<ProPersonVarSampFields>;
  variance?: Maybe<ProPersonVarianceFields>;
};


/** aggregate fields of "pro_person" */
export type ProPersonAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProPersonSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProPersonAvgFields = {
  __typename?: 'ProPersonAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pro_person". All fields are combined with a logical 'AND'. */
export type ProPersonBoolExp = {
  _and?: Maybe<Array<ProPersonBoolExp>>;
  _not?: Maybe<ProPersonBoolExp>;
  _or?: Maybe<Array<ProPersonBoolExp>>;
  email?: Maybe<StringComparisonExp>;
  familyName?: Maybe<StringComparisonExp>;
  givenName?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  proViews?: Maybe<ProViewProPersonBoolExp>;
  proViewsAggregate?: Maybe<ProViewProPersonAggregateBoolExp>;
  telephone?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pro_person" */
export enum ProPersonConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22948Primary = 'idx_22948_primary'
}

/** input type for incrementing numeric columns in table "pro_person" */
export type ProPersonIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_person" */
export type ProPersonInsertInput = {
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proViews?: Maybe<ProViewProPersonArrRelInsertInput>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProPersonMaxFields = {
  __typename?: 'ProPersonMaxFields';
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProPersonMinFields = {
  __typename?: 'ProPersonMinFields';
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pro_person" */
export type ProPersonMutationResponse = {
  __typename?: 'ProPersonMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProPerson>;
};

/** input type for inserting object relation for remote table "pro_person" */
export type ProPersonObjRelInsertInput = {
  data: ProPersonInsertInput;
  /** upsert condition */
  onConflict?: Maybe<ProPersonOnConflict>;
};

/** on_conflict condition type for table "pro_person" */
export type ProPersonOnConflict = {
  constraint: ProPersonConstraint;
  updateColumns: Array<ProPersonUpdateColumn>;
  where?: Maybe<ProPersonBoolExp>;
};

/** Ordering options when selecting data from "pro_person". */
export type ProPersonOrderBy = {
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proViewsAggregate?: Maybe<ProViewProPersonAggregateOrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_person */
export type ProPersonPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_person" */
export enum ProPersonSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  Telephone = 'telephone'
}

/** input type for updating data in table "pro_person" */
export type ProPersonSetInput = {
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProPersonStddevFields = {
  __typename?: 'ProPersonStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ProPersonStddevPopFields = {
  __typename?: 'ProPersonStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ProPersonStddevSampFields = {
  __typename?: 'ProPersonStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "pro_person" */
export type ProPersonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProPersonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProPersonStreamCursorValueInput = {
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProPersonSumFields = {
  __typename?: 'ProPersonSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "pro_person" */
export enum ProPersonUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  Telephone = 'telephone'
}

export type ProPersonUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProPersonIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProPersonSetInput>;
  /** filter the rows which have to be updated */
  where: ProPersonBoolExp;
};

/** aggregate variance on columns */
export type ProPersonVarianceFields = {
  __typename?: 'ProPersonVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type ProPersonVarPopFields = {
  __typename?: 'ProPersonVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ProPersonVarSampFields = {
  __typename?: 'ProPersonVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "pro_postal_address" */
export type ProPostalAddress = {
  __typename?: 'ProPostalAddress';
  city?: Maybe<Scalars['String']>;
  cityGeoCoordinates?: Maybe<Scalars['geometry']>;
  displayOption: Scalars['String'];
  geoCoordinates?: Maybe<Scalars['geometry']>;
  id: Scalars['Int'];
  postalCode?: Maybe<Scalars['String']>;
  /** An object relationship */
  proView?: Maybe<ProView>;
  proViewId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pro_postal_address" */
export type ProPostalAddressAggregate = {
  __typename?: 'ProPostalAddressAggregate';
  aggregate?: Maybe<ProPostalAddressAggregateFields>;
  nodes: Array<ProPostalAddress>;
};

/** aggregate fields of "pro_postal_address" */
export type ProPostalAddressAggregateFields = {
  __typename?: 'ProPostalAddressAggregateFields';
  avg?: Maybe<ProPostalAddressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProPostalAddressMaxFields>;
  min?: Maybe<ProPostalAddressMinFields>;
  stddev?: Maybe<ProPostalAddressStddevFields>;
  stddevPop?: Maybe<ProPostalAddressStddevPopFields>;
  stddevSamp?: Maybe<ProPostalAddressStddevSampFields>;
  sum?: Maybe<ProPostalAddressSumFields>;
  varPop?: Maybe<ProPostalAddressVarPopFields>;
  varSamp?: Maybe<ProPostalAddressVarSampFields>;
  variance?: Maybe<ProPostalAddressVarianceFields>;
};


/** aggregate fields of "pro_postal_address" */
export type ProPostalAddressAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProPostalAddressSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProPostalAddressAvgFields = {
  __typename?: 'ProPostalAddressAvgFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pro_postal_address". All fields are combined with a logical 'AND'. */
export type ProPostalAddressBoolExp = {
  _and?: Maybe<Array<ProPostalAddressBoolExp>>;
  _not?: Maybe<ProPostalAddressBoolExp>;
  _or?: Maybe<Array<ProPostalAddressBoolExp>>;
  city?: Maybe<StringComparisonExp>;
  cityGeoCoordinates?: Maybe<GeometryComparisonExp>;
  displayOption?: Maybe<StringComparisonExp>;
  geoCoordinates?: Maybe<GeometryComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  postalCode?: Maybe<StringComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
  street?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pro_postal_address" */
export enum ProPostalAddressConstraint {
  /** unique or primary key constraint on columns "id" */
  ProPostalAddressPkey = 'pro_postal_address_pkey',
  /** unique or primary key constraint on columns "pro_view_id" */
  ProPostalAddressUniqProViewId = 'pro_postal_address_uniq_pro_view_id'
}

/** input type for incrementing numeric columns in table "pro_postal_address" */
export type ProPostalAddressIncInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_postal_address" */
export type ProPostalAddressInsertInput = {
  city?: Maybe<Scalars['String']>;
  cityGeoCoordinates?: Maybe<Scalars['geometry']>;
  displayOption?: Maybe<Scalars['String']>;
  geoCoordinates?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProPostalAddressMaxFields = {
  __typename?: 'ProPostalAddressMaxFields';
  city?: Maybe<Scalars['String']>;
  displayOption?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  proViewId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ProPostalAddressMinFields = {
  __typename?: 'ProPostalAddressMinFields';
  city?: Maybe<Scalars['String']>;
  displayOption?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  proViewId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pro_postal_address" */
export type ProPostalAddressMutationResponse = {
  __typename?: 'ProPostalAddressMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProPostalAddress>;
};

/** input type for inserting object relation for remote table "pro_postal_address" */
export type ProPostalAddressObjRelInsertInput = {
  data: ProPostalAddressInsertInput;
  /** upsert condition */
  onConflict?: Maybe<ProPostalAddressOnConflict>;
};

/** on_conflict condition type for table "pro_postal_address" */
export type ProPostalAddressOnConflict = {
  constraint: ProPostalAddressConstraint;
  updateColumns: Array<ProPostalAddressUpdateColumn>;
  where?: Maybe<ProPostalAddressBoolExp>;
};

/** Ordering options when selecting data from "pro_postal_address". */
export type ProPostalAddressOrderBy = {
  city?: Maybe<OrderBy>;
  cityGeoCoordinates?: Maybe<OrderBy>;
  displayOption?: Maybe<OrderBy>;
  geoCoordinates?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
  street?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_postal_address */
export type ProPostalAddressPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_postal_address" */
export enum ProPostalAddressSelectColumn {
  /** column name */
  City = 'city',
  /** column name */
  CityGeoCoordinates = 'cityGeoCoordinates',
  /** column name */
  DisplayOption = 'displayOption',
  /** column name */
  GeoCoordinates = 'geoCoordinates',
  /** column name */
  Id = 'id',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  Street = 'street'
}

/** input type for updating data in table "pro_postal_address" */
export type ProPostalAddressSetInput = {
  city?: Maybe<Scalars['String']>;
  cityGeoCoordinates?: Maybe<Scalars['geometry']>;
  displayOption?: Maybe<Scalars['String']>;
  geoCoordinates?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  proViewId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProPostalAddressStddevFields = {
  __typename?: 'ProPostalAddressStddevFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ProPostalAddressStddevPopFields = {
  __typename?: 'ProPostalAddressStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ProPostalAddressStddevSampFields = {
  __typename?: 'ProPostalAddressStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "pro_postal_address" */
export type ProPostalAddressStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProPostalAddressStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProPostalAddressStreamCursorValueInput = {
  city?: Maybe<Scalars['String']>;
  cityGeoCoordinates?: Maybe<Scalars['geometry']>;
  displayOption?: Maybe<Scalars['String']>;
  geoCoordinates?: Maybe<Scalars['geometry']>;
  id?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  proViewId?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProPostalAddressSumFields = {
  __typename?: 'ProPostalAddressSumFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** update columns of table "pro_postal_address" */
export enum ProPostalAddressUpdateColumn {
  /** column name */
  City = 'city',
  /** column name */
  CityGeoCoordinates = 'cityGeoCoordinates',
  /** column name */
  DisplayOption = 'displayOption',
  /** column name */
  GeoCoordinates = 'geoCoordinates',
  /** column name */
  Id = 'id',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  Street = 'street'
}

export type ProPostalAddressUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProPostalAddressIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProPostalAddressSetInput>;
  /** filter the rows which have to be updated */
  where: ProPostalAddressBoolExp;
};

/** aggregate variance on columns */
export type ProPostalAddressVarianceFields = {
  __typename?: 'ProPostalAddressVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type ProPostalAddressVarPopFields = {
  __typename?: 'ProPostalAddressVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ProPostalAddressVarSampFields = {
  __typename?: 'ProPostalAddressVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "pro_presentation" */
export type ProPresentation = {
  __typename?: 'ProPresentation';
  /** An array relationship */
  certifications: Array<ProCertification>;
  /** An aggregate relationship */
  certificationsAggregate: ProCertificationAggregate;
  dateCreated: Scalars['timestamptz'];
  expertise1?: Maybe<Scalars['String']>;
  expertise11?: Maybe<Scalars['String']>;
  expertise12?: Maybe<Scalars['String']>;
  expertise13?: Maybe<Scalars['String']>;
  expertise14?: Maybe<Scalars['String']>;
  expertise15?: Maybe<Scalars['String']>;
  expertise16?: Maybe<Scalars['String']>;
  expertise2?: Maybe<Scalars['String']>;
  expertise21?: Maybe<Scalars['String']>;
  expertise22?: Maybe<Scalars['String']>;
  expertise23?: Maybe<Scalars['String']>;
  expertise24?: Maybe<Scalars['String']>;
  expertise25?: Maybe<Scalars['String']>;
  expertise26?: Maybe<Scalars['String']>;
  expertise3?: Maybe<Scalars['String']>;
  expertise31?: Maybe<Scalars['String']>;
  expertise32?: Maybe<Scalars['String']>;
  expertise33?: Maybe<Scalars['String']>;
  expertise34?: Maybe<Scalars['String']>;
  expertise35?: Maybe<Scalars['String']>;
  expertise36?: Maybe<Scalars['String']>;
  extra1?: Maybe<Scalars['String']>;
  extra2?: Maybe<Scalars['String']>;
  extra3?: Maybe<Scalars['String']>;
  extra4?: Maybe<Scalars['String']>;
  extra5?: Maybe<Scalars['String']>;
  /** An array relationship */
  googleReviewLinks: Array<GoogleReviewLink>;
  /** An aggregate relationship */
  googleReviewLinksAggregate: GoogleReviewLinkAggregate;
  hobbies?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A computed field, executes function "pro_presentation_is_rge" */
  isRge?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  mainTrade?: Maybe<Trade>;
  mainTradeId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** An array relationship */
  proLabels: Array<ProLabel>;
  /** An aggregate relationship */
  proLabelsAggregate: ProLabelAggregate;
  /** An array relationship */
  proMediaObjects: Array<ProMediaObject>;
  /** An aggregate relationship */
  proMediaObjectsAggregate: ProMediaObjectAggregate;
  /** An object relationship */
  proOrganization?: Maybe<ProOrganization>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  proReviewInvitations: Array<ProReviewInvitation>;
  /** An aggregate relationship */
  proReviewInvitationsAggregate: ProReviewInvitationAggregate;
  /** An array relationship */
  proReviews: Array<ProReview>;
  /** An aggregate relationship */
  proReviewsAggregate: ProReviewAggregate;
  /** An array relationship */
  proViews: Array<ProView>;
  /** An aggregate relationship */
  proViewsAggregate: ProViewAggregate;
  profile?: Maybe<Scalars['String']>;
  /** An object relationship */
  secondaryTrade?: Maybe<Trade>;
  secondaryTradeId?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationCertificationsArgs = {
  distinctOn?: Maybe<Array<ProCertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProCertificationOrderBy>>;
  where?: Maybe<ProCertificationBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationCertificationsAggregateArgs = {
  distinctOn?: Maybe<Array<ProCertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProCertificationOrderBy>>;
  where?: Maybe<ProCertificationBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationGoogleReviewLinksArgs = {
  distinctOn?: Maybe<Array<GoogleReviewLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GoogleReviewLinkOrderBy>>;
  where?: Maybe<GoogleReviewLinkBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationGoogleReviewLinksAggregateArgs = {
  distinctOn?: Maybe<Array<GoogleReviewLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GoogleReviewLinkOrderBy>>;
  where?: Maybe<GoogleReviewLinkBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProLabelsArgs = {
  distinctOn?: Maybe<Array<ProLabelSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProLabelOrderBy>>;
  where?: Maybe<ProLabelBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProLabelsAggregateArgs = {
  distinctOn?: Maybe<Array<ProLabelSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProLabelOrderBy>>;
  where?: Maybe<ProLabelBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProMediaObjectsArgs = {
  distinctOn?: Maybe<Array<ProMediaObjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMediaObjectOrderBy>>;
  where?: Maybe<ProMediaObjectBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProMediaObjectsAggregateArgs = {
  distinctOn?: Maybe<Array<ProMediaObjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMediaObjectOrderBy>>;
  where?: Maybe<ProMediaObjectBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProReviewInvitationsArgs = {
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProReviewInvitationsAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProReviewsArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProReviewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProViewsArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


/** columns and relationships of "pro_presentation" */
export type ProPresentationProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};

/** aggregated selection of "pro_presentation" */
export type ProPresentationAggregate = {
  __typename?: 'ProPresentationAggregate';
  aggregate?: Maybe<ProPresentationAggregateFields>;
  nodes: Array<ProPresentation>;
};

export type ProPresentationAggregateBoolExp = {
  count?: Maybe<ProPresentationAggregateBoolExpCount>;
};

export type ProPresentationAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProPresentationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProPresentationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_presentation" */
export type ProPresentationAggregateFields = {
  __typename?: 'ProPresentationAggregateFields';
  avg?: Maybe<ProPresentationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProPresentationMaxFields>;
  min?: Maybe<ProPresentationMinFields>;
  stddev?: Maybe<ProPresentationStddevFields>;
  stddevPop?: Maybe<ProPresentationStddevPopFields>;
  stddevSamp?: Maybe<ProPresentationStddevSampFields>;
  sum?: Maybe<ProPresentationSumFields>;
  varPop?: Maybe<ProPresentationVarPopFields>;
  varSamp?: Maybe<ProPresentationVarSampFields>;
  variance?: Maybe<ProPresentationVarianceFields>;
};


/** aggregate fields of "pro_presentation" */
export type ProPresentationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProPresentationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_presentation" */
export type ProPresentationAggregateOrderBy = {
  avg?: Maybe<ProPresentationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProPresentationMaxOrderBy>;
  min?: Maybe<ProPresentationMinOrderBy>;
  stddev?: Maybe<ProPresentationStddevOrderBy>;
  stddevPop?: Maybe<ProPresentationStddevPopOrderBy>;
  stddevSamp?: Maybe<ProPresentationStddevSampOrderBy>;
  sum?: Maybe<ProPresentationSumOrderBy>;
  varPop?: Maybe<ProPresentationVarPopOrderBy>;
  varSamp?: Maybe<ProPresentationVarSampOrderBy>;
  variance?: Maybe<ProPresentationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_presentation" */
export type ProPresentationArrRelInsertInput = {
  data: Array<ProPresentationInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProPresentationOnConflict>;
};

/** aggregate avg on columns */
export type ProPresentationAvgFields = {
  __typename?: 'ProPresentationAvgFields';
  id?: Maybe<Scalars['Float']>;
  mainTradeId?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  secondaryTradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_presentation" */
export type ProPresentationAvgOrderBy = {
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_presentation". All fields are combined with a logical 'AND'. */
export type ProPresentationBoolExp = {
  _and?: Maybe<Array<ProPresentationBoolExp>>;
  _not?: Maybe<ProPresentationBoolExp>;
  _or?: Maybe<Array<ProPresentationBoolExp>>;
  certifications?: Maybe<ProCertificationBoolExp>;
  certificationsAggregate?: Maybe<ProCertificationAggregateBoolExp>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  expertise1?: Maybe<StringComparisonExp>;
  expertise11?: Maybe<StringComparisonExp>;
  expertise12?: Maybe<StringComparisonExp>;
  expertise13?: Maybe<StringComparisonExp>;
  expertise14?: Maybe<StringComparisonExp>;
  expertise15?: Maybe<StringComparisonExp>;
  expertise16?: Maybe<StringComparisonExp>;
  expertise2?: Maybe<StringComparisonExp>;
  expertise21?: Maybe<StringComparisonExp>;
  expertise22?: Maybe<StringComparisonExp>;
  expertise23?: Maybe<StringComparisonExp>;
  expertise24?: Maybe<StringComparisonExp>;
  expertise25?: Maybe<StringComparisonExp>;
  expertise26?: Maybe<StringComparisonExp>;
  expertise3?: Maybe<StringComparisonExp>;
  expertise31?: Maybe<StringComparisonExp>;
  expertise32?: Maybe<StringComparisonExp>;
  expertise33?: Maybe<StringComparisonExp>;
  expertise34?: Maybe<StringComparisonExp>;
  expertise35?: Maybe<StringComparisonExp>;
  expertise36?: Maybe<StringComparisonExp>;
  extra1?: Maybe<StringComparisonExp>;
  extra2?: Maybe<StringComparisonExp>;
  extra3?: Maybe<StringComparisonExp>;
  extra4?: Maybe<StringComparisonExp>;
  extra5?: Maybe<StringComparisonExp>;
  googleReviewLinks?: Maybe<GoogleReviewLinkBoolExp>;
  googleReviewLinksAggregate?: Maybe<GoogleReviewLinkAggregateBoolExp>;
  hobbies?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  isRge?: Maybe<BooleanComparisonExp>;
  mainTrade?: Maybe<TradeBoolExp>;
  mainTradeId?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  proLabels?: Maybe<ProLabelBoolExp>;
  proLabelsAggregate?: Maybe<ProLabelAggregateBoolExp>;
  proMediaObjects?: Maybe<ProMediaObjectBoolExp>;
  proMediaObjectsAggregate?: Maybe<ProMediaObjectAggregateBoolExp>;
  proOrganization?: Maybe<ProOrganizationBoolExp>;
  proOrganizationId?: Maybe<IntComparisonExp>;
  proReviewInvitations?: Maybe<ProReviewInvitationBoolExp>;
  proReviewInvitationsAggregate?: Maybe<ProReviewInvitationAggregateBoolExp>;
  proReviews?: Maybe<ProReviewBoolExp>;
  proReviewsAggregate?: Maybe<ProReviewAggregateBoolExp>;
  proViews?: Maybe<ProViewBoolExp>;
  proViewsAggregate?: Maybe<ProViewAggregateBoolExp>;
  profile?: Maybe<StringComparisonExp>;
  secondaryTrade?: Maybe<TradeBoolExp>;
  secondaryTradeId?: Maybe<IntComparisonExp>;
  slug?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pro_presentation" */
export enum ProPresentationConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22966Primary = 'idx_22966_primary',
  /** unique or primary key constraint on columns "slug" */
  Idx_22966Uniq_89076416989d9b62 = 'idx_22966_uniq_89076416989d9b62'
}

/** input type for incrementing numeric columns in table "pro_presentation" */
export type ProPresentationIncInput = {
  id?: Maybe<Scalars['Int']>;
  mainTradeId?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  secondaryTradeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_presentation" */
export type ProPresentationInsertInput = {
  certifications?: Maybe<ProCertificationArrRelInsertInput>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  expertise1?: Maybe<Scalars['String']>;
  expertise11?: Maybe<Scalars['String']>;
  expertise12?: Maybe<Scalars['String']>;
  expertise13?: Maybe<Scalars['String']>;
  expertise14?: Maybe<Scalars['String']>;
  expertise15?: Maybe<Scalars['String']>;
  expertise16?: Maybe<Scalars['String']>;
  expertise2?: Maybe<Scalars['String']>;
  expertise21?: Maybe<Scalars['String']>;
  expertise22?: Maybe<Scalars['String']>;
  expertise23?: Maybe<Scalars['String']>;
  expertise24?: Maybe<Scalars['String']>;
  expertise25?: Maybe<Scalars['String']>;
  expertise26?: Maybe<Scalars['String']>;
  expertise3?: Maybe<Scalars['String']>;
  expertise31?: Maybe<Scalars['String']>;
  expertise32?: Maybe<Scalars['String']>;
  expertise33?: Maybe<Scalars['String']>;
  expertise34?: Maybe<Scalars['String']>;
  expertise35?: Maybe<Scalars['String']>;
  expertise36?: Maybe<Scalars['String']>;
  extra1?: Maybe<Scalars['String']>;
  extra2?: Maybe<Scalars['String']>;
  extra3?: Maybe<Scalars['String']>;
  extra4?: Maybe<Scalars['String']>;
  extra5?: Maybe<Scalars['String']>;
  googleReviewLinks?: Maybe<GoogleReviewLinkArrRelInsertInput>;
  hobbies?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mainTrade?: Maybe<TradeObjRelInsertInput>;
  mainTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proLabels?: Maybe<ProLabelArrRelInsertInput>;
  proMediaObjects?: Maybe<ProMediaObjectArrRelInsertInput>;
  proOrganization?: Maybe<ProOrganizationObjRelInsertInput>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  proReviewInvitations?: Maybe<ProReviewInvitationArrRelInsertInput>;
  proReviews?: Maybe<ProReviewArrRelInsertInput>;
  proViews?: Maybe<ProViewArrRelInsertInput>;
  profile?: Maybe<Scalars['String']>;
  secondaryTrade?: Maybe<TradeObjRelInsertInput>;
  secondaryTradeId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProPresentationMaxFields = {
  __typename?: 'ProPresentationMaxFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  expertise1?: Maybe<Scalars['String']>;
  expertise11?: Maybe<Scalars['String']>;
  expertise12?: Maybe<Scalars['String']>;
  expertise13?: Maybe<Scalars['String']>;
  expertise14?: Maybe<Scalars['String']>;
  expertise15?: Maybe<Scalars['String']>;
  expertise16?: Maybe<Scalars['String']>;
  expertise2?: Maybe<Scalars['String']>;
  expertise21?: Maybe<Scalars['String']>;
  expertise22?: Maybe<Scalars['String']>;
  expertise23?: Maybe<Scalars['String']>;
  expertise24?: Maybe<Scalars['String']>;
  expertise25?: Maybe<Scalars['String']>;
  expertise26?: Maybe<Scalars['String']>;
  expertise3?: Maybe<Scalars['String']>;
  expertise31?: Maybe<Scalars['String']>;
  expertise32?: Maybe<Scalars['String']>;
  expertise33?: Maybe<Scalars['String']>;
  expertise34?: Maybe<Scalars['String']>;
  expertise35?: Maybe<Scalars['String']>;
  expertise36?: Maybe<Scalars['String']>;
  extra1?: Maybe<Scalars['String']>;
  extra2?: Maybe<Scalars['String']>;
  extra3?: Maybe<Scalars['String']>;
  extra4?: Maybe<Scalars['String']>;
  extra5?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mainTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  profile?: Maybe<Scalars['String']>;
  secondaryTradeId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pro_presentation" */
export type ProPresentationMaxOrderBy = {
  dateCreated?: Maybe<OrderBy>;
  expertise1?: Maybe<OrderBy>;
  expertise11?: Maybe<OrderBy>;
  expertise12?: Maybe<OrderBy>;
  expertise13?: Maybe<OrderBy>;
  expertise14?: Maybe<OrderBy>;
  expertise15?: Maybe<OrderBy>;
  expertise16?: Maybe<OrderBy>;
  expertise2?: Maybe<OrderBy>;
  expertise21?: Maybe<OrderBy>;
  expertise22?: Maybe<OrderBy>;
  expertise23?: Maybe<OrderBy>;
  expertise24?: Maybe<OrderBy>;
  expertise25?: Maybe<OrderBy>;
  expertise26?: Maybe<OrderBy>;
  expertise3?: Maybe<OrderBy>;
  expertise31?: Maybe<OrderBy>;
  expertise32?: Maybe<OrderBy>;
  expertise33?: Maybe<OrderBy>;
  expertise34?: Maybe<OrderBy>;
  expertise35?: Maybe<OrderBy>;
  expertise36?: Maybe<OrderBy>;
  extra1?: Maybe<OrderBy>;
  extra2?: Maybe<OrderBy>;
  extra3?: Maybe<OrderBy>;
  extra4?: Maybe<OrderBy>;
  extra5?: Maybe<OrderBy>;
  hobbies?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  profile?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProPresentationMinFields = {
  __typename?: 'ProPresentationMinFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  expertise1?: Maybe<Scalars['String']>;
  expertise11?: Maybe<Scalars['String']>;
  expertise12?: Maybe<Scalars['String']>;
  expertise13?: Maybe<Scalars['String']>;
  expertise14?: Maybe<Scalars['String']>;
  expertise15?: Maybe<Scalars['String']>;
  expertise16?: Maybe<Scalars['String']>;
  expertise2?: Maybe<Scalars['String']>;
  expertise21?: Maybe<Scalars['String']>;
  expertise22?: Maybe<Scalars['String']>;
  expertise23?: Maybe<Scalars['String']>;
  expertise24?: Maybe<Scalars['String']>;
  expertise25?: Maybe<Scalars['String']>;
  expertise26?: Maybe<Scalars['String']>;
  expertise3?: Maybe<Scalars['String']>;
  expertise31?: Maybe<Scalars['String']>;
  expertise32?: Maybe<Scalars['String']>;
  expertise33?: Maybe<Scalars['String']>;
  expertise34?: Maybe<Scalars['String']>;
  expertise35?: Maybe<Scalars['String']>;
  expertise36?: Maybe<Scalars['String']>;
  extra1?: Maybe<Scalars['String']>;
  extra2?: Maybe<Scalars['String']>;
  extra3?: Maybe<Scalars['String']>;
  extra4?: Maybe<Scalars['String']>;
  extra5?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mainTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  profile?: Maybe<Scalars['String']>;
  secondaryTradeId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pro_presentation" */
export type ProPresentationMinOrderBy = {
  dateCreated?: Maybe<OrderBy>;
  expertise1?: Maybe<OrderBy>;
  expertise11?: Maybe<OrderBy>;
  expertise12?: Maybe<OrderBy>;
  expertise13?: Maybe<OrderBy>;
  expertise14?: Maybe<OrderBy>;
  expertise15?: Maybe<OrderBy>;
  expertise16?: Maybe<OrderBy>;
  expertise2?: Maybe<OrderBy>;
  expertise21?: Maybe<OrderBy>;
  expertise22?: Maybe<OrderBy>;
  expertise23?: Maybe<OrderBy>;
  expertise24?: Maybe<OrderBy>;
  expertise25?: Maybe<OrderBy>;
  expertise26?: Maybe<OrderBy>;
  expertise3?: Maybe<OrderBy>;
  expertise31?: Maybe<OrderBy>;
  expertise32?: Maybe<OrderBy>;
  expertise33?: Maybe<OrderBy>;
  expertise34?: Maybe<OrderBy>;
  expertise35?: Maybe<OrderBy>;
  expertise36?: Maybe<OrderBy>;
  extra1?: Maybe<OrderBy>;
  extra2?: Maybe<OrderBy>;
  extra3?: Maybe<OrderBy>;
  extra4?: Maybe<OrderBy>;
  extra5?: Maybe<OrderBy>;
  hobbies?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  profile?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_presentation" */
export type ProPresentationMutationResponse = {
  __typename?: 'ProPresentationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProPresentation>;
};

/** input type for inserting object relation for remote table "pro_presentation" */
export type ProPresentationObjRelInsertInput = {
  data: ProPresentationInsertInput;
  /** upsert condition */
  onConflict?: Maybe<ProPresentationOnConflict>;
};

/** on_conflict condition type for table "pro_presentation" */
export type ProPresentationOnConflict = {
  constraint: ProPresentationConstraint;
  updateColumns: Array<ProPresentationUpdateColumn>;
  where?: Maybe<ProPresentationBoolExp>;
};

/** Ordering options when selecting data from "pro_presentation". */
export type ProPresentationOrderBy = {
  certificationsAggregate?: Maybe<ProCertificationAggregateOrderBy>;
  dateCreated?: Maybe<OrderBy>;
  expertise1?: Maybe<OrderBy>;
  expertise11?: Maybe<OrderBy>;
  expertise12?: Maybe<OrderBy>;
  expertise13?: Maybe<OrderBy>;
  expertise14?: Maybe<OrderBy>;
  expertise15?: Maybe<OrderBy>;
  expertise16?: Maybe<OrderBy>;
  expertise2?: Maybe<OrderBy>;
  expertise21?: Maybe<OrderBy>;
  expertise22?: Maybe<OrderBy>;
  expertise23?: Maybe<OrderBy>;
  expertise24?: Maybe<OrderBy>;
  expertise25?: Maybe<OrderBy>;
  expertise26?: Maybe<OrderBy>;
  expertise3?: Maybe<OrderBy>;
  expertise31?: Maybe<OrderBy>;
  expertise32?: Maybe<OrderBy>;
  expertise33?: Maybe<OrderBy>;
  expertise34?: Maybe<OrderBy>;
  expertise35?: Maybe<OrderBy>;
  expertise36?: Maybe<OrderBy>;
  extra1?: Maybe<OrderBy>;
  extra2?: Maybe<OrderBy>;
  extra3?: Maybe<OrderBy>;
  extra4?: Maybe<OrderBy>;
  extra5?: Maybe<OrderBy>;
  googleReviewLinksAggregate?: Maybe<GoogleReviewLinkAggregateOrderBy>;
  hobbies?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isRge?: Maybe<OrderBy>;
  mainTrade?: Maybe<TradeOrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  proLabelsAggregate?: Maybe<ProLabelAggregateOrderBy>;
  proMediaObjectsAggregate?: Maybe<ProMediaObjectAggregateOrderBy>;
  proOrganization?: Maybe<ProOrganizationOrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  proReviewInvitationsAggregate?: Maybe<ProReviewInvitationAggregateOrderBy>;
  proReviewsAggregate?: Maybe<ProReviewAggregateOrderBy>;
  proViewsAggregate?: Maybe<ProViewAggregateOrderBy>;
  profile?: Maybe<OrderBy>;
  secondaryTrade?: Maybe<TradeOrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_presentation */
export type ProPresentationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_presentation" */
export enum ProPresentationSelectColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Expertise1 = 'expertise1',
  /** column name */
  Expertise11 = 'expertise11',
  /** column name */
  Expertise12 = 'expertise12',
  /** column name */
  Expertise13 = 'expertise13',
  /** column name */
  Expertise14 = 'expertise14',
  /** column name */
  Expertise15 = 'expertise15',
  /** column name */
  Expertise16 = 'expertise16',
  /** column name */
  Expertise2 = 'expertise2',
  /** column name */
  Expertise21 = 'expertise21',
  /** column name */
  Expertise22 = 'expertise22',
  /** column name */
  Expertise23 = 'expertise23',
  /** column name */
  Expertise24 = 'expertise24',
  /** column name */
  Expertise25 = 'expertise25',
  /** column name */
  Expertise26 = 'expertise26',
  /** column name */
  Expertise3 = 'expertise3',
  /** column name */
  Expertise31 = 'expertise31',
  /** column name */
  Expertise32 = 'expertise32',
  /** column name */
  Expertise33 = 'expertise33',
  /** column name */
  Expertise34 = 'expertise34',
  /** column name */
  Expertise35 = 'expertise35',
  /** column name */
  Expertise36 = 'expertise36',
  /** column name */
  Extra1 = 'extra1',
  /** column name */
  Extra2 = 'extra2',
  /** column name */
  Extra3 = 'extra3',
  /** column name */
  Extra4 = 'extra4',
  /** column name */
  Extra5 = 'extra5',
  /** column name */
  Hobbies = 'hobbies',
  /** column name */
  Id = 'id',
  /** column name */
  MainTradeId = 'mainTradeId',
  /** column name */
  Name = 'name',
  /** column name */
  ProOrganizationId = 'proOrganizationId',
  /** column name */
  Profile = 'profile',
  /** column name */
  SecondaryTradeId = 'secondaryTradeId',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "pro_presentation" */
export type ProPresentationSetInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  expertise1?: Maybe<Scalars['String']>;
  expertise11?: Maybe<Scalars['String']>;
  expertise12?: Maybe<Scalars['String']>;
  expertise13?: Maybe<Scalars['String']>;
  expertise14?: Maybe<Scalars['String']>;
  expertise15?: Maybe<Scalars['String']>;
  expertise16?: Maybe<Scalars['String']>;
  expertise2?: Maybe<Scalars['String']>;
  expertise21?: Maybe<Scalars['String']>;
  expertise22?: Maybe<Scalars['String']>;
  expertise23?: Maybe<Scalars['String']>;
  expertise24?: Maybe<Scalars['String']>;
  expertise25?: Maybe<Scalars['String']>;
  expertise26?: Maybe<Scalars['String']>;
  expertise3?: Maybe<Scalars['String']>;
  expertise31?: Maybe<Scalars['String']>;
  expertise32?: Maybe<Scalars['String']>;
  expertise33?: Maybe<Scalars['String']>;
  expertise34?: Maybe<Scalars['String']>;
  expertise35?: Maybe<Scalars['String']>;
  expertise36?: Maybe<Scalars['String']>;
  extra1?: Maybe<Scalars['String']>;
  extra2?: Maybe<Scalars['String']>;
  extra3?: Maybe<Scalars['String']>;
  extra4?: Maybe<Scalars['String']>;
  extra5?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mainTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  profile?: Maybe<Scalars['String']>;
  secondaryTradeId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProPresentationStddevFields = {
  __typename?: 'ProPresentationStddevFields';
  id?: Maybe<Scalars['Float']>;
  mainTradeId?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  secondaryTradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_presentation" */
export type ProPresentationStddevOrderBy = {
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProPresentationStddevPopFields = {
  __typename?: 'ProPresentationStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  mainTradeId?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  secondaryTradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_presentation" */
export type ProPresentationStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProPresentationStddevSampFields = {
  __typename?: 'ProPresentationStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  mainTradeId?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  secondaryTradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_presentation" */
export type ProPresentationStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_presentation" */
export type ProPresentationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProPresentationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProPresentationStreamCursorValueInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  expertise1?: Maybe<Scalars['String']>;
  expertise11?: Maybe<Scalars['String']>;
  expertise12?: Maybe<Scalars['String']>;
  expertise13?: Maybe<Scalars['String']>;
  expertise14?: Maybe<Scalars['String']>;
  expertise15?: Maybe<Scalars['String']>;
  expertise16?: Maybe<Scalars['String']>;
  expertise2?: Maybe<Scalars['String']>;
  expertise21?: Maybe<Scalars['String']>;
  expertise22?: Maybe<Scalars['String']>;
  expertise23?: Maybe<Scalars['String']>;
  expertise24?: Maybe<Scalars['String']>;
  expertise25?: Maybe<Scalars['String']>;
  expertise26?: Maybe<Scalars['String']>;
  expertise3?: Maybe<Scalars['String']>;
  expertise31?: Maybe<Scalars['String']>;
  expertise32?: Maybe<Scalars['String']>;
  expertise33?: Maybe<Scalars['String']>;
  expertise34?: Maybe<Scalars['String']>;
  expertise35?: Maybe<Scalars['String']>;
  expertise36?: Maybe<Scalars['String']>;
  extra1?: Maybe<Scalars['String']>;
  extra2?: Maybe<Scalars['String']>;
  extra3?: Maybe<Scalars['String']>;
  extra4?: Maybe<Scalars['String']>;
  extra5?: Maybe<Scalars['String']>;
  hobbies?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  mainTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  profile?: Maybe<Scalars['String']>;
  secondaryTradeId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProPresentationSumFields = {
  __typename?: 'ProPresentationSumFields';
  id?: Maybe<Scalars['Int']>;
  mainTradeId?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  secondaryTradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_presentation" */
export type ProPresentationSumOrderBy = {
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
};

/** update columns of table "pro_presentation" */
export enum ProPresentationUpdateColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Expertise1 = 'expertise1',
  /** column name */
  Expertise11 = 'expertise11',
  /** column name */
  Expertise12 = 'expertise12',
  /** column name */
  Expertise13 = 'expertise13',
  /** column name */
  Expertise14 = 'expertise14',
  /** column name */
  Expertise15 = 'expertise15',
  /** column name */
  Expertise16 = 'expertise16',
  /** column name */
  Expertise2 = 'expertise2',
  /** column name */
  Expertise21 = 'expertise21',
  /** column name */
  Expertise22 = 'expertise22',
  /** column name */
  Expertise23 = 'expertise23',
  /** column name */
  Expertise24 = 'expertise24',
  /** column name */
  Expertise25 = 'expertise25',
  /** column name */
  Expertise26 = 'expertise26',
  /** column name */
  Expertise3 = 'expertise3',
  /** column name */
  Expertise31 = 'expertise31',
  /** column name */
  Expertise32 = 'expertise32',
  /** column name */
  Expertise33 = 'expertise33',
  /** column name */
  Expertise34 = 'expertise34',
  /** column name */
  Expertise35 = 'expertise35',
  /** column name */
  Expertise36 = 'expertise36',
  /** column name */
  Extra1 = 'extra1',
  /** column name */
  Extra2 = 'extra2',
  /** column name */
  Extra3 = 'extra3',
  /** column name */
  Extra4 = 'extra4',
  /** column name */
  Extra5 = 'extra5',
  /** column name */
  Hobbies = 'hobbies',
  /** column name */
  Id = 'id',
  /** column name */
  MainTradeId = 'mainTradeId',
  /** column name */
  Name = 'name',
  /** column name */
  ProOrganizationId = 'proOrganizationId',
  /** column name */
  Profile = 'profile',
  /** column name */
  SecondaryTradeId = 'secondaryTradeId',
  /** column name */
  Slug = 'slug'
}

export type ProPresentationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProPresentationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProPresentationSetInput>;
  /** filter the rows which have to be updated */
  where: ProPresentationBoolExp;
};

/** aggregate variance on columns */
export type ProPresentationVarianceFields = {
  __typename?: 'ProPresentationVarianceFields';
  id?: Maybe<Scalars['Float']>;
  mainTradeId?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  secondaryTradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_presentation" */
export type ProPresentationVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProPresentationVarPopFields = {
  __typename?: 'ProPresentationVarPopFields';
  id?: Maybe<Scalars['Float']>;
  mainTradeId?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  secondaryTradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_presentation" */
export type ProPresentationVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProPresentationVarSampFields = {
  __typename?: 'ProPresentationVarSampFields';
  id?: Maybe<Scalars['Float']>;
  mainTradeId?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  secondaryTradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_presentation" */
export type ProPresentationVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  mainTradeId?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  secondaryTradeId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_price" */
export type ProPrice = {
  __typename?: 'ProPrice';
  content: Scalars['String'];
  id: Scalars['Int'];
  isRepair: Scalars['Boolean'];
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
  title: Scalars['String'];
};

/** aggregated selection of "pro_price" */
export type ProPriceAggregate = {
  __typename?: 'ProPriceAggregate';
  aggregate?: Maybe<ProPriceAggregateFields>;
  nodes: Array<ProPrice>;
};

export type ProPriceAggregateBoolExp = {
  bool_and?: Maybe<ProPriceAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<ProPriceAggregateBoolExpBoolOr>;
  count?: Maybe<ProPriceAggregateBoolExpCount>;
};

export type ProPriceAggregateBoolExpBoolAnd = {
  arguments: ProPriceSelectColumnProPriceAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProPriceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProPriceAggregateBoolExpBoolOr = {
  arguments: ProPriceSelectColumnProPriceAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProPriceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProPriceAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProPriceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProPriceBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_price" */
export type ProPriceAggregateFields = {
  __typename?: 'ProPriceAggregateFields';
  avg?: Maybe<ProPriceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProPriceMaxFields>;
  min?: Maybe<ProPriceMinFields>;
  stddev?: Maybe<ProPriceStddevFields>;
  stddevPop?: Maybe<ProPriceStddevPopFields>;
  stddevSamp?: Maybe<ProPriceStddevSampFields>;
  sum?: Maybe<ProPriceSumFields>;
  varPop?: Maybe<ProPriceVarPopFields>;
  varSamp?: Maybe<ProPriceVarSampFields>;
  variance?: Maybe<ProPriceVarianceFields>;
};


/** aggregate fields of "pro_price" */
export type ProPriceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProPriceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_price" */
export type ProPriceAggregateOrderBy = {
  avg?: Maybe<ProPriceAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProPriceMaxOrderBy>;
  min?: Maybe<ProPriceMinOrderBy>;
  stddev?: Maybe<ProPriceStddevOrderBy>;
  stddevPop?: Maybe<ProPriceStddevPopOrderBy>;
  stddevSamp?: Maybe<ProPriceStddevSampOrderBy>;
  sum?: Maybe<ProPriceSumOrderBy>;
  varPop?: Maybe<ProPriceVarPopOrderBy>;
  varSamp?: Maybe<ProPriceVarSampOrderBy>;
  variance?: Maybe<ProPriceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_price" */
export type ProPriceArrRelInsertInput = {
  data: Array<ProPriceInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProPriceOnConflict>;
};

/** aggregate avg on columns */
export type ProPriceAvgFields = {
  __typename?: 'ProPriceAvgFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_price" */
export type ProPriceAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_price". All fields are combined with a logical 'AND'. */
export type ProPriceBoolExp = {
  _and?: Maybe<Array<ProPriceBoolExp>>;
  _not?: Maybe<ProPriceBoolExp>;
  _or?: Maybe<Array<ProPriceBoolExp>>;
  content?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  isRepair?: Maybe<BooleanComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
  title?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pro_price" */
export enum ProPriceConstraint {
  /** unique or primary key constraint on columns "id" */
  ProPricePkey = 'pro_price_pkey'
}

/** input type for incrementing numeric columns in table "pro_price" */
export type ProPriceIncInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_price" */
export type ProPriceInsertInput = {
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isRepair?: Maybe<Scalars['Boolean']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProPriceMaxFields = {
  __typename?: 'ProPriceMaxFields';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pro_price" */
export type ProPriceMaxOrderBy = {
  content?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProPriceMinFields = {
  __typename?: 'ProPriceMinFields';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pro_price" */
export type ProPriceMinOrderBy = {
  content?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_price" */
export type ProPriceMutationResponse = {
  __typename?: 'ProPriceMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProPrice>;
};

/** on_conflict condition type for table "pro_price" */
export type ProPriceOnConflict = {
  constraint: ProPriceConstraint;
  updateColumns: Array<ProPriceUpdateColumn>;
  where?: Maybe<ProPriceBoolExp>;
};

/** Ordering options when selecting data from "pro_price". */
export type ProPriceOrderBy = {
  content?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isRepair?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
  title?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_price */
export type ProPricePkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_price" */
export enum ProPriceSelectColumn {
  /** column name */
  Content = 'content',
  /** column name */
  Id = 'id',
  /** column name */
  IsRepair = 'isRepair',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  Title = 'title'
}

/** select "proPriceAggregateBoolExpBool_andArgumentsColumns" columns of table "pro_price" */
export enum ProPriceSelectColumnProPriceAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsRepair = 'isRepair'
}

/** select "proPriceAggregateBoolExpBool_orArgumentsColumns" columns of table "pro_price" */
export enum ProPriceSelectColumnProPriceAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsRepair = 'isRepair'
}

/** input type for updating data in table "pro_price" */
export type ProPriceSetInput = {
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isRepair?: Maybe<Scalars['Boolean']>;
  proViewId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProPriceStddevFields = {
  __typename?: 'ProPriceStddevFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_price" */
export type ProPriceStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProPriceStddevPopFields = {
  __typename?: 'ProPriceStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_price" */
export type ProPriceStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProPriceStddevSampFields = {
  __typename?: 'ProPriceStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_price" */
export type ProPriceStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_price" */
export type ProPriceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProPriceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProPriceStreamCursorValueInput = {
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isRepair?: Maybe<Scalars['Boolean']>;
  proViewId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProPriceSumFields = {
  __typename?: 'ProPriceSumFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_price" */
export type ProPriceSumOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "pro_price" */
export enum ProPriceUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  Id = 'id',
  /** column name */
  IsRepair = 'isRepair',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  Title = 'title'
}

export type ProPriceUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProPriceIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProPriceSetInput>;
  /** filter the rows which have to be updated */
  where: ProPriceBoolExp;
};

/** aggregate variance on columns */
export type ProPriceVarianceFields = {
  __typename?: 'ProPriceVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_price" */
export type ProPriceVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProPriceVarPopFields = {
  __typename?: 'ProPriceVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_price" */
export type ProPriceVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProPriceVarSampFields = {
  __typename?: 'ProPriceVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_price" */
export type ProPriceVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_review" */
export type ProReview = {
  __typename?: 'ProReview';
  addressLocality: Scalars['String'];
  checkDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  checker?: Maybe<BilikPerson>;
  checkerId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  /** A computed field, executes function "dated_pro_reviews" */
  date?: Maybe<Scalars['timestamptz']>;
  dateCreated: Scalars['timestamptz'];
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  duplicateProReviews: Array<ProReview>;
  /** An aggregate relationship */
  duplicateProReviewsAggregate: ProReviewAggregate;
  email: Scalars['String'];
  familyName: Scalars['String'];
  geoCoordinates?: Maybe<Scalars['geometry']>;
  givenName: Scalars['String'];
  id: Scalars['Int'];
  ip?: Maybe<Scalars['String']>;
  isSpam: Scalars['Boolean'];
  lockDate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  locker?: Maybe<BilikPerson>;
  lockerId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  originalProReview?: Maybe<ProReview>;
  originalProReviewId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  otherReviews: Array<ProReview>;
  /** An aggregate relationship */
  otherReviewsAggregate: ProReviewAggregate;
  privateComment?: Maybe<Scalars['String']>;
  /** An object relationship */
  proPresentation: ProPresentation;
  proPresentationId: Scalars['Int'];
  /** An array relationship */
  proReviewInvitations: Array<ProReviewInvitation>;
  /** An aggregate relationship */
  proReviewInvitationsAggregate: ProReviewInvitationAggregate;
  rating?: Maybe<Scalars['smallint']>;
  ratingType: Scalars['String'];
  refuseReason?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
  replyDate?: Maybe<Scalars['timestamptz']>;
  replyStatus?: Maybe<Scalars['String']>;
  reviewAspect: Scalars['String'];
  reviewBody: Scalars['String'];
  /** An array relationship */
  solicitations: Array<Solicitation>;
  /** An aggregate relationship */
  solicitationsAggregate: SolicitationAggregate;
  source: Scalars['String'];
  status: Scalars['String'];
  telephone: Scalars['String'];
};


/** columns and relationships of "pro_review" */
export type ProReviewDuplicateProReviewsArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


/** columns and relationships of "pro_review" */
export type ProReviewDuplicateProReviewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


/** columns and relationships of "pro_review" */
export type ProReviewOtherReviewsArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


/** columns and relationships of "pro_review" */
export type ProReviewOtherReviewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


/** columns and relationships of "pro_review" */
export type ProReviewProReviewInvitationsArgs = {
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


/** columns and relationships of "pro_review" */
export type ProReviewProReviewInvitationsAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


/** columns and relationships of "pro_review" */
export type ProReviewSolicitationsArgs = {
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


/** columns and relationships of "pro_review" */
export type ProReviewSolicitationsAggregateArgs = {
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};

/** aggregated selection of "pro_review" */
export type ProReviewAggregate = {
  __typename?: 'ProReviewAggregate';
  aggregate?: Maybe<ProReviewAggregateFields>;
  nodes: Array<ProReview>;
};

export type ProReviewAggregateBoolExp = {
  bool_and?: Maybe<ProReviewAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<ProReviewAggregateBoolExpBoolOr>;
  count?: Maybe<ProReviewAggregateBoolExpCount>;
};

export type ProReviewAggregateBoolExpBoolAnd = {
  arguments: ProReviewSelectColumnProReviewAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProReviewBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProReviewAggregateBoolExpBoolOr = {
  arguments: ProReviewSelectColumnProReviewAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProReviewBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProReviewAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProReviewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProReviewBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_review" */
export type ProReviewAggregateFields = {
  __typename?: 'ProReviewAggregateFields';
  avg?: Maybe<ProReviewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProReviewMaxFields>;
  min?: Maybe<ProReviewMinFields>;
  stddev?: Maybe<ProReviewStddevFields>;
  stddevPop?: Maybe<ProReviewStddevPopFields>;
  stddevSamp?: Maybe<ProReviewStddevSampFields>;
  sum?: Maybe<ProReviewSumFields>;
  varPop?: Maybe<ProReviewVarPopFields>;
  varSamp?: Maybe<ProReviewVarSampFields>;
  variance?: Maybe<ProReviewVarianceFields>;
};


/** aggregate fields of "pro_review" */
export type ProReviewAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProReviewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_review" */
export type ProReviewAggregateOrderBy = {
  avg?: Maybe<ProReviewAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProReviewMaxOrderBy>;
  min?: Maybe<ProReviewMinOrderBy>;
  stddev?: Maybe<ProReviewStddevOrderBy>;
  stddevPop?: Maybe<ProReviewStddevPopOrderBy>;
  stddevSamp?: Maybe<ProReviewStddevSampOrderBy>;
  sum?: Maybe<ProReviewSumOrderBy>;
  varPop?: Maybe<ProReviewVarPopOrderBy>;
  varSamp?: Maybe<ProReviewVarSampOrderBy>;
  variance?: Maybe<ProReviewVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_review" */
export type ProReviewArrRelInsertInput = {
  data: Array<ProReviewInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProReviewOnConflict>;
};

/** aggregate avg on columns */
export type ProReviewAvgFields = {
  __typename?: 'ProReviewAvgFields';
  checkerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lockerId?: Maybe<Scalars['Float']>;
  originalProReviewId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_review" */
export type ProReviewAvgOrderBy = {
  checkerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_review". All fields are combined with a logical 'AND'. */
export type ProReviewBoolExp = {
  _and?: Maybe<Array<ProReviewBoolExp>>;
  _not?: Maybe<ProReviewBoolExp>;
  _or?: Maybe<Array<ProReviewBoolExp>>;
  addressLocality?: Maybe<StringComparisonExp>;
  checkDate?: Maybe<TimestamptzComparisonExp>;
  checker?: Maybe<BilikPersonBoolExp>;
  checkerId?: Maybe<IntComparisonExp>;
  comment?: Maybe<StringComparisonExp>;
  date?: Maybe<TimestamptzComparisonExp>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  dateOfWork?: Maybe<TimestamptzComparisonExp>;
  duplicateProReviews?: Maybe<ProReviewBoolExp>;
  duplicateProReviewsAggregate?: Maybe<ProReviewAggregateBoolExp>;
  email?: Maybe<StringComparisonExp>;
  familyName?: Maybe<StringComparisonExp>;
  geoCoordinates?: Maybe<GeometryComparisonExp>;
  givenName?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  ip?: Maybe<StringComparisonExp>;
  isSpam?: Maybe<BooleanComparisonExp>;
  lockDate?: Maybe<TimestamptzComparisonExp>;
  locker?: Maybe<BilikPersonBoolExp>;
  lockerId?: Maybe<IntComparisonExp>;
  originalProReview?: Maybe<ProReviewBoolExp>;
  originalProReviewId?: Maybe<IntComparisonExp>;
  otherReviews?: Maybe<ProReviewBoolExp>;
  otherReviewsAggregate?: Maybe<ProReviewAggregateBoolExp>;
  privateComment?: Maybe<StringComparisonExp>;
  proPresentation?: Maybe<ProPresentationBoolExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
  proReviewInvitations?: Maybe<ProReviewInvitationBoolExp>;
  proReviewInvitationsAggregate?: Maybe<ProReviewInvitationAggregateBoolExp>;
  rating?: Maybe<SmallintComparisonExp>;
  ratingType?: Maybe<StringComparisonExp>;
  refuseReason?: Maybe<StringComparisonExp>;
  reply?: Maybe<StringComparisonExp>;
  replyDate?: Maybe<TimestamptzComparisonExp>;
  replyStatus?: Maybe<StringComparisonExp>;
  reviewAspect?: Maybe<StringComparisonExp>;
  reviewBody?: Maybe<StringComparisonExp>;
  solicitations?: Maybe<SolicitationBoolExp>;
  solicitationsAggregate?: Maybe<SolicitationAggregateBoolExp>;
  source?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  telephone?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pro_review" */
export enum ProReviewConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22976Primary = 'idx_22976_primary'
}

/** input type for incrementing numeric columns in table "pro_review" */
export type ProReviewIncInput = {
  checkerId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lockerId?: Maybe<Scalars['Int']>;
  originalProReviewId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "pro_review" */
export type ProReviewInsertInput = {
  addressLocality?: Maybe<Scalars['String']>;
  checkDate?: Maybe<Scalars['timestamptz']>;
  checker?: Maybe<BilikPersonObjRelInsertInput>;
  checkerId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  duplicateProReviews?: Maybe<ProReviewArrRelInsertInput>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  geoCoordinates?: Maybe<Scalars['geometry']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  isSpam?: Maybe<Scalars['Boolean']>;
  lockDate?: Maybe<Scalars['timestamptz']>;
  locker?: Maybe<BilikPersonObjRelInsertInput>;
  lockerId?: Maybe<Scalars['Int']>;
  originalProReview?: Maybe<ProReviewObjRelInsertInput>;
  originalProReviewId?: Maybe<Scalars['Int']>;
  otherReviews?: Maybe<ProReviewArrRelInsertInput>;
  privateComment?: Maybe<Scalars['String']>;
  proPresentation?: Maybe<ProPresentationObjRelInsertInput>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proReviewInvitations?: Maybe<ProReviewInvitationArrRelInsertInput>;
  rating?: Maybe<Scalars['smallint']>;
  ratingType?: Maybe<Scalars['String']>;
  refuseReason?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
  replyDate?: Maybe<Scalars['timestamptz']>;
  replyStatus?: Maybe<Scalars['String']>;
  reviewAspect?: Maybe<Scalars['String']>;
  reviewBody?: Maybe<Scalars['String']>;
  solicitations?: Maybe<SolicitationArrRelInsertInput>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** columns and relationships of "pro_review_invitation" */
export type ProReviewInvitation = {
  __typename?: 'ProReviewInvitation';
  dateCreated: Scalars['timestamptz'];
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Int'];
  isAutorespondActive: Scalars['Boolean'];
  isBounce: Scalars['Boolean'];
  postmarkMessageId?: Maybe<Scalars['String']>;
  /** An object relationship */
  proPresentation?: Maybe<ProPresentation>;
  proPresentationId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  proReview?: Maybe<ProReview>;
  proReviewId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pro_review_invitation" */
export type ProReviewInvitationAggregate = {
  __typename?: 'ProReviewInvitationAggregate';
  aggregate?: Maybe<ProReviewInvitationAggregateFields>;
  nodes: Array<ProReviewInvitation>;
};

export type ProReviewInvitationAggregateBoolExp = {
  bool_and?: Maybe<ProReviewInvitationAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<ProReviewInvitationAggregateBoolExpBoolOr>;
  count?: Maybe<ProReviewInvitationAggregateBoolExpCount>;
};

export type ProReviewInvitationAggregateBoolExpBoolAnd = {
  arguments: ProReviewInvitationSelectColumnProReviewInvitationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProReviewInvitationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProReviewInvitationAggregateBoolExpBoolOr = {
  arguments: ProReviewInvitationSelectColumnProReviewInvitationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProReviewInvitationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProReviewInvitationAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProReviewInvitationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_review_invitation" */
export type ProReviewInvitationAggregateFields = {
  __typename?: 'ProReviewInvitationAggregateFields';
  avg?: Maybe<ProReviewInvitationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProReviewInvitationMaxFields>;
  min?: Maybe<ProReviewInvitationMinFields>;
  stddev?: Maybe<ProReviewInvitationStddevFields>;
  stddevPop?: Maybe<ProReviewInvitationStddevPopFields>;
  stddevSamp?: Maybe<ProReviewInvitationStddevSampFields>;
  sum?: Maybe<ProReviewInvitationSumFields>;
  varPop?: Maybe<ProReviewInvitationVarPopFields>;
  varSamp?: Maybe<ProReviewInvitationVarSampFields>;
  variance?: Maybe<ProReviewInvitationVarianceFields>;
};


/** aggregate fields of "pro_review_invitation" */
export type ProReviewInvitationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_review_invitation" */
export type ProReviewInvitationAggregateOrderBy = {
  avg?: Maybe<ProReviewInvitationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProReviewInvitationMaxOrderBy>;
  min?: Maybe<ProReviewInvitationMinOrderBy>;
  stddev?: Maybe<ProReviewInvitationStddevOrderBy>;
  stddevPop?: Maybe<ProReviewInvitationStddevPopOrderBy>;
  stddevSamp?: Maybe<ProReviewInvitationStddevSampOrderBy>;
  sum?: Maybe<ProReviewInvitationSumOrderBy>;
  varPop?: Maybe<ProReviewInvitationVarPopOrderBy>;
  varSamp?: Maybe<ProReviewInvitationVarSampOrderBy>;
  variance?: Maybe<ProReviewInvitationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_review_invitation" */
export type ProReviewInvitationArrRelInsertInput = {
  data: Array<ProReviewInvitationInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProReviewInvitationOnConflict>;
};

/** aggregate avg on columns */
export type ProReviewInvitationAvgFields = {
  __typename?: 'ProReviewInvitationAvgFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_review_invitation" */
export type ProReviewInvitationAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_review_invitation". All fields are combined with a logical 'AND'. */
export type ProReviewInvitationBoolExp = {
  _and?: Maybe<Array<ProReviewInvitationBoolExp>>;
  _not?: Maybe<ProReviewInvitationBoolExp>;
  _or?: Maybe<Array<ProReviewInvitationBoolExp>>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  dateOfWork?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  familyName?: Maybe<StringComparisonExp>;
  givenName?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  isAutorespondActive?: Maybe<BooleanComparisonExp>;
  isBounce?: Maybe<BooleanComparisonExp>;
  postmarkMessageId?: Maybe<StringComparisonExp>;
  proPresentation?: Maybe<ProPresentationBoolExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
  proReview?: Maybe<ProReviewBoolExp>;
  proReviewId?: Maybe<IntComparisonExp>;
  uuid?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pro_review_invitation" */
export enum ProReviewInvitationConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22985Primary = 'idx_22985_primary',
  /** unique or primary key constraint on columns "uuid" */
  Idx_22985Uniq_69e5470ad17f50a6 = 'idx_22985_uniq_69e5470ad17f50a6'
}

/** input type for incrementing numeric columns in table "pro_review_invitation" */
export type ProReviewInvitationIncInput = {
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proReviewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_review_invitation" */
export type ProReviewInvitationInsertInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isAutorespondActive?: Maybe<Scalars['Boolean']>;
  isBounce?: Maybe<Scalars['Boolean']>;
  postmarkMessageId?: Maybe<Scalars['String']>;
  proPresentation?: Maybe<ProPresentationObjRelInsertInput>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proReview?: Maybe<ProReviewObjRelInsertInput>;
  proReviewId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ProReviewInvitationMaxFields = {
  __typename?: 'ProReviewInvitationMaxFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  postmarkMessageId?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proReviewId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pro_review_invitation" */
export type ProReviewInvitationMaxOrderBy = {
  dateCreated?: Maybe<OrderBy>;
  dateOfWork?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  postmarkMessageId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProReviewInvitationMinFields = {
  __typename?: 'ProReviewInvitationMinFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  postmarkMessageId?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proReviewId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pro_review_invitation" */
export type ProReviewInvitationMinOrderBy = {
  dateCreated?: Maybe<OrderBy>;
  dateOfWork?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  postmarkMessageId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_review_invitation" */
export type ProReviewInvitationMutationResponse = {
  __typename?: 'ProReviewInvitationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProReviewInvitation>;
};

/** on_conflict condition type for table "pro_review_invitation" */
export type ProReviewInvitationOnConflict = {
  constraint: ProReviewInvitationConstraint;
  updateColumns: Array<ProReviewInvitationUpdateColumn>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};

/** Ordering options when selecting data from "pro_review_invitation". */
export type ProReviewInvitationOrderBy = {
  dateCreated?: Maybe<OrderBy>;
  dateOfWork?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isAutorespondActive?: Maybe<OrderBy>;
  isBounce?: Maybe<OrderBy>;
  postmarkMessageId?: Maybe<OrderBy>;
  proPresentation?: Maybe<ProPresentationOrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReview?: Maybe<ProReviewOrderBy>;
  proReviewId?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_review_invitation */
export type ProReviewInvitationPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_review_invitation" */
export enum ProReviewInvitationSelectColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DateOfWork = 'dateOfWork',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  IsAutorespondActive = 'isAutorespondActive',
  /** column name */
  IsBounce = 'isBounce',
  /** column name */
  PostmarkMessageId = 'postmarkMessageId',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  ProReviewId = 'proReviewId',
  /** column name */
  Uuid = 'uuid'
}

/** select "proReviewInvitationAggregateBoolExpBool_andArgumentsColumns" columns of table "pro_review_invitation" */
export enum ProReviewInvitationSelectColumnProReviewInvitationAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsAutorespondActive = 'isAutorespondActive',
  /** column name */
  IsBounce = 'isBounce'
}

/** select "proReviewInvitationAggregateBoolExpBool_orArgumentsColumns" columns of table "pro_review_invitation" */
export enum ProReviewInvitationSelectColumnProReviewInvitationAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsAutorespondActive = 'isAutorespondActive',
  /** column name */
  IsBounce = 'isBounce'
}

/** input type for updating data in table "pro_review_invitation" */
export type ProReviewInvitationSetInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isAutorespondActive?: Maybe<Scalars['Boolean']>;
  isBounce?: Maybe<Scalars['Boolean']>;
  postmarkMessageId?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proReviewId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProReviewInvitationStddevFields = {
  __typename?: 'ProReviewInvitationStddevFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_review_invitation" */
export type ProReviewInvitationStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProReviewInvitationStddevPopFields = {
  __typename?: 'ProReviewInvitationStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_review_invitation" */
export type ProReviewInvitationStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProReviewInvitationStddevSampFields = {
  __typename?: 'ProReviewInvitationStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_review_invitation" */
export type ProReviewInvitationStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_review_invitation" */
export type ProReviewInvitationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProReviewInvitationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProReviewInvitationStreamCursorValueInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isAutorespondActive?: Maybe<Scalars['Boolean']>;
  isBounce?: Maybe<Scalars['Boolean']>;
  postmarkMessageId?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proReviewId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProReviewInvitationSumFields = {
  __typename?: 'ProReviewInvitationSumFields';
  id?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proReviewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_review_invitation" */
export type ProReviewInvitationSumOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
};

/** update columns of table "pro_review_invitation" */
export enum ProReviewInvitationUpdateColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DateOfWork = 'dateOfWork',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  IsAutorespondActive = 'isAutorespondActive',
  /** column name */
  IsBounce = 'isBounce',
  /** column name */
  PostmarkMessageId = 'postmarkMessageId',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  ProReviewId = 'proReviewId',
  /** column name */
  Uuid = 'uuid'
}

export type ProReviewInvitationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProReviewInvitationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProReviewInvitationSetInput>;
  /** filter the rows which have to be updated */
  where: ProReviewInvitationBoolExp;
};

/** aggregate variance on columns */
export type ProReviewInvitationVarianceFields = {
  __typename?: 'ProReviewInvitationVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_review_invitation" */
export type ProReviewInvitationVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProReviewInvitationVarPopFields = {
  __typename?: 'ProReviewInvitationVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_review_invitation" */
export type ProReviewInvitationVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProReviewInvitationVarSampFields = {
  __typename?: 'ProReviewInvitationVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_review_invitation" */
export type ProReviewInvitationVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
};

/** aggregate max on columns */
export type ProReviewMaxFields = {
  __typename?: 'ProReviewMaxFields';
  addressLocality?: Maybe<Scalars['String']>;
  checkDate?: Maybe<Scalars['timestamptz']>;
  checkerId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  /** A computed field, executes function "dated_pro_reviews" */
  date?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  lockDate?: Maybe<Scalars['timestamptz']>;
  lockerId?: Maybe<Scalars['Int']>;
  originalProReviewId?: Maybe<Scalars['Int']>;
  privateComment?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['smallint']>;
  ratingType?: Maybe<Scalars['String']>;
  refuseReason?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
  replyDate?: Maybe<Scalars['timestamptz']>;
  replyStatus?: Maybe<Scalars['String']>;
  reviewAspect?: Maybe<Scalars['String']>;
  reviewBody?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pro_review" */
export type ProReviewMaxOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  checkDate?: Maybe<OrderBy>;
  checkerId?: Maybe<OrderBy>;
  comment?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  dateOfWork?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  lockDate?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  privateComment?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  ratingType?: Maybe<OrderBy>;
  refuseReason?: Maybe<OrderBy>;
  reply?: Maybe<OrderBy>;
  replyDate?: Maybe<OrderBy>;
  replyStatus?: Maybe<OrderBy>;
  reviewAspect?: Maybe<OrderBy>;
  reviewBody?: Maybe<OrderBy>;
  source?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProReviewMinFields = {
  __typename?: 'ProReviewMinFields';
  addressLocality?: Maybe<Scalars['String']>;
  checkDate?: Maybe<Scalars['timestamptz']>;
  checkerId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  /** A computed field, executes function "dated_pro_reviews" */
  date?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  lockDate?: Maybe<Scalars['timestamptz']>;
  lockerId?: Maybe<Scalars['Int']>;
  originalProReviewId?: Maybe<Scalars['Int']>;
  privateComment?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['smallint']>;
  ratingType?: Maybe<Scalars['String']>;
  refuseReason?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
  replyDate?: Maybe<Scalars['timestamptz']>;
  replyStatus?: Maybe<Scalars['String']>;
  reviewAspect?: Maybe<Scalars['String']>;
  reviewBody?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pro_review" */
export type ProReviewMinOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  checkDate?: Maybe<OrderBy>;
  checkerId?: Maybe<OrderBy>;
  comment?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  dateOfWork?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  lockDate?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  privateComment?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
  ratingType?: Maybe<OrderBy>;
  refuseReason?: Maybe<OrderBy>;
  reply?: Maybe<OrderBy>;
  replyDate?: Maybe<OrderBy>;
  replyStatus?: Maybe<OrderBy>;
  reviewAspect?: Maybe<OrderBy>;
  reviewBody?: Maybe<OrderBy>;
  source?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_review" */
export type ProReviewMutationResponse = {
  __typename?: 'ProReviewMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProReview>;
};

/** input type for inserting object relation for remote table "pro_review" */
export type ProReviewObjRelInsertInput = {
  data: ProReviewInsertInput;
  /** upsert condition */
  onConflict?: Maybe<ProReviewOnConflict>;
};

/** on_conflict condition type for table "pro_review" */
export type ProReviewOnConflict = {
  constraint: ProReviewConstraint;
  updateColumns: Array<ProReviewUpdateColumn>;
  where?: Maybe<ProReviewBoolExp>;
};

/** Ordering options when selecting data from "pro_review". */
export type ProReviewOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  checkDate?: Maybe<OrderBy>;
  checker?: Maybe<BilikPersonOrderBy>;
  checkerId?: Maybe<OrderBy>;
  comment?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  dateOfWork?: Maybe<OrderBy>;
  duplicateProReviewsAggregate?: Maybe<ProReviewAggregateOrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  geoCoordinates?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  isSpam?: Maybe<OrderBy>;
  lockDate?: Maybe<OrderBy>;
  locker?: Maybe<BilikPersonOrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReview?: Maybe<ProReviewOrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  otherReviewsAggregate?: Maybe<ProReviewAggregateOrderBy>;
  privateComment?: Maybe<OrderBy>;
  proPresentation?: Maybe<ProPresentationOrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proReviewInvitationsAggregate?: Maybe<ProReviewInvitationAggregateOrderBy>;
  rating?: Maybe<OrderBy>;
  ratingType?: Maybe<OrderBy>;
  refuseReason?: Maybe<OrderBy>;
  reply?: Maybe<OrderBy>;
  replyDate?: Maybe<OrderBy>;
  replyStatus?: Maybe<OrderBy>;
  reviewAspect?: Maybe<OrderBy>;
  reviewBody?: Maybe<OrderBy>;
  solicitationsAggregate?: Maybe<SolicitationAggregateOrderBy>;
  source?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_review */
export type ProReviewPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "pro_review" */
export enum ProReviewSelectColumn {
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  CheckDate = 'checkDate',
  /** column name */
  CheckerId = 'checkerId',
  /** column name */
  Comment = 'comment',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DateOfWork = 'dateOfWork',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GeoCoordinates = 'geoCoordinates',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  IsSpam = 'isSpam',
  /** column name */
  LockDate = 'lockDate',
  /** column name */
  LockerId = 'lockerId',
  /** column name */
  OriginalProReviewId = 'originalProReviewId',
  /** column name */
  PrivateComment = 'privateComment',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingType = 'ratingType',
  /** column name */
  RefuseReason = 'refuseReason',
  /** column name */
  Reply = 'reply',
  /** column name */
  ReplyDate = 'replyDate',
  /** column name */
  ReplyStatus = 'replyStatus',
  /** column name */
  ReviewAspect = 'reviewAspect',
  /** column name */
  ReviewBody = 'reviewBody',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Telephone = 'telephone'
}

/** select "proReviewAggregateBoolExpBool_andArgumentsColumns" columns of table "pro_review" */
export enum ProReviewSelectColumnProReviewAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsSpam = 'isSpam'
}

/** select "proReviewAggregateBoolExpBool_orArgumentsColumns" columns of table "pro_review" */
export enum ProReviewSelectColumnProReviewAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsSpam = 'isSpam'
}

/** input type for updating data in table "pro_review" */
export type ProReviewSetInput = {
  addressLocality?: Maybe<Scalars['String']>;
  checkDate?: Maybe<Scalars['timestamptz']>;
  checkerId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  geoCoordinates?: Maybe<Scalars['geometry']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  isSpam?: Maybe<Scalars['Boolean']>;
  lockDate?: Maybe<Scalars['timestamptz']>;
  lockerId?: Maybe<Scalars['Int']>;
  originalProReviewId?: Maybe<Scalars['Int']>;
  privateComment?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['smallint']>;
  ratingType?: Maybe<Scalars['String']>;
  refuseReason?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
  replyDate?: Maybe<Scalars['timestamptz']>;
  replyStatus?: Maybe<Scalars['String']>;
  reviewAspect?: Maybe<Scalars['String']>;
  reviewBody?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ProReviewStddevFields = {
  __typename?: 'ProReviewStddevFields';
  checkerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lockerId?: Maybe<Scalars['Float']>;
  originalProReviewId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_review" */
export type ProReviewStddevOrderBy = {
  checkerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProReviewStddevPopFields = {
  __typename?: 'ProReviewStddevPopFields';
  checkerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lockerId?: Maybe<Scalars['Float']>;
  originalProReviewId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_review" */
export type ProReviewStddevPopOrderBy = {
  checkerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProReviewStddevSampFields = {
  __typename?: 'ProReviewStddevSampFields';
  checkerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lockerId?: Maybe<Scalars['Float']>;
  originalProReviewId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_review" */
export type ProReviewStddevSampOrderBy = {
  checkerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_review" */
export type ProReviewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProReviewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProReviewStreamCursorValueInput = {
  addressLocality?: Maybe<Scalars['String']>;
  checkDate?: Maybe<Scalars['timestamptz']>;
  checkerId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  dateOfWork?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  geoCoordinates?: Maybe<Scalars['geometry']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  isSpam?: Maybe<Scalars['Boolean']>;
  lockDate?: Maybe<Scalars['timestamptz']>;
  lockerId?: Maybe<Scalars['Int']>;
  originalProReviewId?: Maybe<Scalars['Int']>;
  privateComment?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['smallint']>;
  ratingType?: Maybe<Scalars['String']>;
  refuseReason?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
  replyDate?: Maybe<Scalars['timestamptz']>;
  replyStatus?: Maybe<Scalars['String']>;
  reviewAspect?: Maybe<Scalars['String']>;
  reviewBody?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ProReviewSumFields = {
  __typename?: 'ProReviewSumFields';
  checkerId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lockerId?: Maybe<Scalars['Int']>;
  originalProReviewId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "pro_review" */
export type ProReviewSumOrderBy = {
  checkerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
};

/** update columns of table "pro_review" */
export enum ProReviewUpdateColumn {
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  CheckDate = 'checkDate',
  /** column name */
  CheckerId = 'checkerId',
  /** column name */
  Comment = 'comment',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DateOfWork = 'dateOfWork',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GeoCoordinates = 'geoCoordinates',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  IsSpam = 'isSpam',
  /** column name */
  LockDate = 'lockDate',
  /** column name */
  LockerId = 'lockerId',
  /** column name */
  OriginalProReviewId = 'originalProReviewId',
  /** column name */
  PrivateComment = 'privateComment',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingType = 'ratingType',
  /** column name */
  RefuseReason = 'refuseReason',
  /** column name */
  Reply = 'reply',
  /** column name */
  ReplyDate = 'replyDate',
  /** column name */
  ReplyStatus = 'replyStatus',
  /** column name */
  ReviewAspect = 'reviewAspect',
  /** column name */
  ReviewBody = 'reviewBody',
  /** column name */
  Source = 'source',
  /** column name */
  Status = 'status',
  /** column name */
  Telephone = 'telephone'
}

export type ProReviewUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProReviewIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProReviewSetInput>;
  /** filter the rows which have to be updated */
  where: ProReviewBoolExp;
};

/** aggregate variance on columns */
export type ProReviewVarianceFields = {
  __typename?: 'ProReviewVarianceFields';
  checkerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lockerId?: Maybe<Scalars['Float']>;
  originalProReviewId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_review" */
export type ProReviewVarianceOrderBy = {
  checkerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProReviewVarPopFields = {
  __typename?: 'ProReviewVarPopFields';
  checkerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lockerId?: Maybe<Scalars['Float']>;
  originalProReviewId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_review" */
export type ProReviewVarPopOrderBy = {
  checkerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProReviewVarSampFields = {
  __typename?: 'ProReviewVarSampFields';
  checkerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lockerId?: Maybe<Scalars['Float']>;
  originalProReviewId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_review" */
export type ProReviewVarSampOrderBy = {
  checkerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lockerId?: Maybe<OrderBy>;
  originalProReviewId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  rating?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_view" */
export type ProView = {
  __typename?: 'ProView';
  /** An array relationship */
  accounts: Array<AccountProView>;
  /** An aggregate relationship */
  accountsAggregate: AccountProViewAggregate;
  avatarHash?: Maybe<Scalars['String']>;
  averageProjectDays: Scalars['Int'];
  averageQuotationDays: Scalars['Int'];
  averageResponseDays: Scalars['Int'];
  /** An object relationship */
  bilikZone: BilikZone;
  bilikZoneId: Scalars['Int'];
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Scalars['jsonb']>;
  /** (DC2Type:json_array) */
  callTrackingTarget: Scalars['jsonb'];
  /** An array relationship */
  cities: Array<CityProView>;
  /** An aggregate relationship */
  citiesAggregate: CityProViewAggregate;
  /** An array relationship */
  citiesByDistance: Array<ProViewCityDistance>;
  /** An aggregate relationship */
  citiesByDistanceAggregate: ProViewCityDistanceAggregate;
  /** An array relationship */
  colleagues: Array<ProViewProView>;
  /** An aggregate relationship */
  colleaguesAggregate: ProViewProViewAggregate;
  contractConfiguration?: Maybe<Scalars['jsonb']>;
  contractEvents?: Maybe<Scalars['jsonb']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated: Scalars['timestamptz'];
  datePublished?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  googleReviewLink?: Maybe<GoogleReviewLink>;
  googleReviewLinkId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  groupedProInterventions?: Maybe<GroupedProIntervention>;
  hasContact: Scalars['Boolean'];
  hasQuotation: Scalars['Boolean'];
  /** A computed field, executes function "pro_view_holiday_end_date" */
  holidayEndDate?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  isCallAlertActivated: Scalars['Boolean'];
  isCallRecordable: Scalars['Boolean'];
  isCallsDisabled: Scalars['Boolean'];
  isLocalBusiness: Scalars['Boolean'];
  isMainCityCenterExcluded: Scalars['Boolean'];
  isSmsDisabled: Scalars['Boolean'];
  isSolicitationsDisabled: Scalars['Boolean'];
  /** An object relationship */
  mainAccount?: Maybe<Account>;
  mainAccountId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  originalAvatarHash?: Maybe<Scalars['String']>;
  priceComment?: Maybe<Scalars['String']>;
  /** An array relationship */
  proHolidays: Array<ProHoliday>;
  /** An aggregate relationship */
  proHolidaysAggregate: ProHolidayAggregate;
  /** An array relationship */
  proInterventions: Array<ProIntervention>;
  /** An aggregate relationship */
  proInterventionsAggregate: ProInterventionAggregate;
  /** An array relationship */
  proMessages: Array<ProMessage>;
  /** An aggregate relationship */
  proMessagesAggregate: ProMessageAggregate;
  /** An array relationship */
  proPeople: Array<ProViewProPerson>;
  /** An aggregate relationship */
  proPeopleAggregate: ProViewProPersonAggregate;
  /** An object relationship */
  proPostalAddress?: Maybe<ProPostalAddress>;
  /** An object relationship */
  proPresentation: ProPresentation;
  proPresentationId: Scalars['Int'];
  /** An array relationship */
  proPrices: Array<ProPrice>;
  /** An aggregate relationship */
  proPricesAggregate: ProPriceAggregate;
  /** An aggregate relationship */
  queuedSmsAggregate: QueuedSmsAggregate;
  /** An array relationship */
  queued_sms: Array<QueuedSms>;
  /** An array relationship */
  relatedProViews: Array<ProViewProView>;
  /** An aggregate relationship */
  relatedProViewsAggregate: ProViewProViewAggregate;
  /** An array relationship */
  solicitations: Array<Solicitation>;
  /** An aggregate relationship */
  solicitationsAggregate: SolicitationAggregate;
  status: Scalars['String'];
  /** (DC2Type:json_array) */
  telephones?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  trades: Array<TradeProView>;
  /** An aggregate relationship */
  tradesAggregate: TradeProViewAggregate;
};


/** columns and relationships of "pro_view" */
export type ProViewAccountsArgs = {
  distinctOn?: Maybe<Array<AccountProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountProViewOrderBy>>;
  where?: Maybe<AccountProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewAccountsAggregateArgs = {
  distinctOn?: Maybe<Array<AccountProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountProViewOrderBy>>;
  where?: Maybe<AccountProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewCallTrackingForwardArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pro_view" */
export type ProViewCallTrackingTargetArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pro_view" */
export type ProViewCitiesArgs = {
  distinctOn?: Maybe<Array<CityProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityProViewOrderBy>>;
  where?: Maybe<CityProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewCitiesAggregateArgs = {
  distinctOn?: Maybe<Array<CityProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityProViewOrderBy>>;
  where?: Maybe<CityProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewCitiesByDistanceArgs = {
  distinctOn?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewCityDistanceOrderBy>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewCitiesByDistanceAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewCityDistanceOrderBy>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewColleaguesArgs = {
  distinctOn?: Maybe<Array<ProViewProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProViewOrderBy>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewColleaguesAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProViewOrderBy>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewContractConfigurationArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pro_view" */
export type ProViewContractEventsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pro_view" */
export type ProViewProHolidaysArgs = {
  distinctOn?: Maybe<Array<ProHolidaySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProHolidayOrderBy>>;
  where?: Maybe<ProHolidayBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProHolidaysAggregateArgs = {
  distinctOn?: Maybe<Array<ProHolidaySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProHolidayOrderBy>>;
  where?: Maybe<ProHolidayBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProInterventionsArgs = {
  distinctOn?: Maybe<Array<ProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProInterventionOrderBy>>;
  where?: Maybe<ProInterventionBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProInterventionsAggregateArgs = {
  distinctOn?: Maybe<Array<ProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProInterventionOrderBy>>;
  where?: Maybe<ProInterventionBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProMessagesArgs = {
  distinctOn?: Maybe<Array<ProMessageSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMessageOrderBy>>;
  where?: Maybe<ProMessageBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProMessagesAggregateArgs = {
  distinctOn?: Maybe<Array<ProMessageSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMessageOrderBy>>;
  where?: Maybe<ProMessageBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProPeopleArgs = {
  distinctOn?: Maybe<Array<ProViewProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProPersonOrderBy>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProPeopleAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProPersonOrderBy>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProPricesArgs = {
  distinctOn?: Maybe<Array<ProPriceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPriceOrderBy>>;
  where?: Maybe<ProPriceBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewProPricesAggregateArgs = {
  distinctOn?: Maybe<Array<ProPriceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPriceOrderBy>>;
  where?: Maybe<ProPriceBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewQueuedSmsAggregateArgs = {
  distinctOn?: Maybe<Array<QueuedSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueuedSmsOrderBy>>;
  where?: Maybe<QueuedSmsBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewQueuedSmsArgs = {
  distinctOn?: Maybe<Array<QueuedSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueuedSmsOrderBy>>;
  where?: Maybe<QueuedSmsBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewRelatedProViewsArgs = {
  distinctOn?: Maybe<Array<ProViewProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProViewOrderBy>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewRelatedProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProViewOrderBy>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewSolicitationsArgs = {
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewSolicitationsAggregateArgs = {
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewTelephonesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pro_view" */
export type ProViewTradesArgs = {
  distinctOn?: Maybe<Array<TradeProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeProViewOrderBy>>;
  where?: Maybe<TradeProViewBoolExp>;
};


/** columns and relationships of "pro_view" */
export type ProViewTradesAggregateArgs = {
  distinctOn?: Maybe<Array<TradeProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeProViewOrderBy>>;
  where?: Maybe<TradeProViewBoolExp>;
};

/** aggregated selection of "pro_view" */
export type ProViewAggregate = {
  __typename?: 'ProViewAggregate';
  aggregate?: Maybe<ProViewAggregateFields>;
  nodes: Array<ProView>;
};

export type ProViewAggregateBoolExp = {
  bool_and?: Maybe<ProViewAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<ProViewAggregateBoolExpBoolOr>;
  count?: Maybe<ProViewAggregateBoolExpCount>;
};

export type ProViewAggregateBoolExpBoolAnd = {
  arguments: ProViewSelectColumnProViewAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProViewBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProViewAggregateBoolExpBoolOr = {
  arguments: ProViewSelectColumnProViewAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProViewBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProViewAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProViewBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_view" */
export type ProViewAggregateFields = {
  __typename?: 'ProViewAggregateFields';
  avg?: Maybe<ProViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProViewMaxFields>;
  min?: Maybe<ProViewMinFields>;
  stddev?: Maybe<ProViewStddevFields>;
  stddevPop?: Maybe<ProViewStddevPopFields>;
  stddevSamp?: Maybe<ProViewStddevSampFields>;
  sum?: Maybe<ProViewSumFields>;
  varPop?: Maybe<ProViewVarPopFields>;
  varSamp?: Maybe<ProViewVarSampFields>;
  variance?: Maybe<ProViewVarianceFields>;
};


/** aggregate fields of "pro_view" */
export type ProViewAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_view" */
export type ProViewAggregateOrderBy = {
  avg?: Maybe<ProViewAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProViewMaxOrderBy>;
  min?: Maybe<ProViewMinOrderBy>;
  stddev?: Maybe<ProViewStddevOrderBy>;
  stddevPop?: Maybe<ProViewStddevPopOrderBy>;
  stddevSamp?: Maybe<ProViewStddevSampOrderBy>;
  sum?: Maybe<ProViewSumOrderBy>;
  varPop?: Maybe<ProViewVarPopOrderBy>;
  varSamp?: Maybe<ProViewVarSampOrderBy>;
  variance?: Maybe<ProViewVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProViewAppendInput = {
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Scalars['jsonb']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  contractConfiguration?: Maybe<Scalars['jsonb']>;
  contractEvents?: Maybe<Scalars['jsonb']>;
  /** (DC2Type:json_array) */
  telephones?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "pro_view" */
export type ProViewArrRelInsertInput = {
  data: Array<ProViewInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProViewOnConflict>;
};

/** aggregate avg on columns */
export type ProViewAvgFields = {
  __typename?: 'ProViewAvgFields';
  averageProjectDays?: Maybe<Scalars['Float']>;
  averageQuotationDays?: Maybe<Scalars['Float']>;
  averageResponseDays?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
  googleReviewLinkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mainAccountId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_view" */
export type ProViewAvgOrderBy = {
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_view". All fields are combined with a logical 'AND'. */
export type ProViewBoolExp = {
  _and?: Maybe<Array<ProViewBoolExp>>;
  _not?: Maybe<ProViewBoolExp>;
  _or?: Maybe<Array<ProViewBoolExp>>;
  accounts?: Maybe<AccountProViewBoolExp>;
  accountsAggregate?: Maybe<AccountProViewAggregateBoolExp>;
  avatarHash?: Maybe<StringComparisonExp>;
  averageProjectDays?: Maybe<IntComparisonExp>;
  averageQuotationDays?: Maybe<IntComparisonExp>;
  averageResponseDays?: Maybe<IntComparisonExp>;
  bilikZone?: Maybe<BilikZoneBoolExp>;
  bilikZoneId?: Maybe<IntComparisonExp>;
  callTrackingForward?: Maybe<JsonbComparisonExp>;
  callTrackingTarget?: Maybe<JsonbComparisonExp>;
  cities?: Maybe<CityProViewBoolExp>;
  citiesAggregate?: Maybe<CityProViewAggregateBoolExp>;
  citiesByDistance?: Maybe<ProViewCityDistanceBoolExp>;
  citiesByDistanceAggregate?: Maybe<ProViewCityDistanceAggregateBoolExp>;
  colleagues?: Maybe<ProViewProViewBoolExp>;
  colleaguesAggregate?: Maybe<ProViewProViewAggregateBoolExp>;
  contractConfiguration?: Maybe<JsonbComparisonExp>;
  contractEvents?: Maybe<JsonbComparisonExp>;
  dateArchived?: Maybe<TimestamptzComparisonExp>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  datePublished?: Maybe<TimestamptzComparisonExp>;
  googleReviewLink?: Maybe<GoogleReviewLinkBoolExp>;
  googleReviewLinkId?: Maybe<IntComparisonExp>;
  groupedProInterventions?: Maybe<GroupedProInterventionBoolExp>;
  hasContact?: Maybe<BooleanComparisonExp>;
  hasQuotation?: Maybe<BooleanComparisonExp>;
  holidayEndDate?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  isCallAlertActivated?: Maybe<BooleanComparisonExp>;
  isCallRecordable?: Maybe<BooleanComparisonExp>;
  isCallsDisabled?: Maybe<BooleanComparisonExp>;
  isLocalBusiness?: Maybe<BooleanComparisonExp>;
  isMainCityCenterExcluded?: Maybe<BooleanComparisonExp>;
  isSmsDisabled?: Maybe<BooleanComparisonExp>;
  isSolicitationsDisabled?: Maybe<BooleanComparisonExp>;
  mainAccount?: Maybe<AccountBoolExp>;
  mainAccountId?: Maybe<IntComparisonExp>;
  note?: Maybe<StringComparisonExp>;
  openingHours?: Maybe<StringComparisonExp>;
  originalAvatarHash?: Maybe<StringComparisonExp>;
  priceComment?: Maybe<StringComparisonExp>;
  proHolidays?: Maybe<ProHolidayBoolExp>;
  proHolidaysAggregate?: Maybe<ProHolidayAggregateBoolExp>;
  proInterventions?: Maybe<ProInterventionBoolExp>;
  proInterventionsAggregate?: Maybe<ProInterventionAggregateBoolExp>;
  proMessages?: Maybe<ProMessageBoolExp>;
  proMessagesAggregate?: Maybe<ProMessageAggregateBoolExp>;
  proPeople?: Maybe<ProViewProPersonBoolExp>;
  proPeopleAggregate?: Maybe<ProViewProPersonAggregateBoolExp>;
  proPostalAddress?: Maybe<ProPostalAddressBoolExp>;
  proPresentation?: Maybe<ProPresentationBoolExp>;
  proPresentationId?: Maybe<IntComparisonExp>;
  proPrices?: Maybe<ProPriceBoolExp>;
  proPricesAggregate?: Maybe<ProPriceAggregateBoolExp>;
  queued_sms?: Maybe<QueuedSmsBoolExp>;
  queued_smsAggregate?: Maybe<QueuedSmsAggregateBoolExp>;
  relatedProViews?: Maybe<ProViewProViewBoolExp>;
  relatedProViewsAggregate?: Maybe<ProViewProViewAggregateBoolExp>;
  solicitations?: Maybe<SolicitationBoolExp>;
  solicitationsAggregate?: Maybe<SolicitationAggregateBoolExp>;
  status?: Maybe<StringComparisonExp>;
  telephones?: Maybe<JsonbComparisonExp>;
  trades?: Maybe<TradeProViewBoolExp>;
  tradesAggregate?: Maybe<TradeProViewAggregateBoolExp>;
};

/** columns and relationships of "pro_view_city_distance" */
export type ProViewCityDistance = {
  __typename?: 'ProViewCityDistance';
  /** An object relationship */
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  proView?: Maybe<ProView>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "pro_view_city_distance" */
export type ProViewCityDistanceAggregate = {
  __typename?: 'ProViewCityDistanceAggregate';
  aggregate?: Maybe<ProViewCityDistanceAggregateFields>;
  nodes: Array<ProViewCityDistance>;
};

export type ProViewCityDistanceAggregateBoolExp = {
  count?: Maybe<ProViewCityDistanceAggregateBoolExpCount>;
};

export type ProViewCityDistanceAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProViewCityDistanceBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_view_city_distance" */
export type ProViewCityDistanceAggregateFields = {
  __typename?: 'ProViewCityDistanceAggregateFields';
  avg?: Maybe<ProViewCityDistanceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProViewCityDistanceMaxFields>;
  min?: Maybe<ProViewCityDistanceMinFields>;
  stddev?: Maybe<ProViewCityDistanceStddevFields>;
  stddevPop?: Maybe<ProViewCityDistanceStddevPopFields>;
  stddevSamp?: Maybe<ProViewCityDistanceStddevSampFields>;
  sum?: Maybe<ProViewCityDistanceSumFields>;
  varPop?: Maybe<ProViewCityDistanceVarPopFields>;
  varSamp?: Maybe<ProViewCityDistanceVarSampFields>;
  variance?: Maybe<ProViewCityDistanceVarianceFields>;
};


/** aggregate fields of "pro_view_city_distance" */
export type ProViewCityDistanceAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_view_city_distance" */
export type ProViewCityDistanceAggregateOrderBy = {
  avg?: Maybe<ProViewCityDistanceAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProViewCityDistanceMaxOrderBy>;
  min?: Maybe<ProViewCityDistanceMinOrderBy>;
  stddev?: Maybe<ProViewCityDistanceStddevOrderBy>;
  stddevPop?: Maybe<ProViewCityDistanceStddevPopOrderBy>;
  stddevSamp?: Maybe<ProViewCityDistanceStddevSampOrderBy>;
  sum?: Maybe<ProViewCityDistanceSumOrderBy>;
  varPop?: Maybe<ProViewCityDistanceVarPopOrderBy>;
  varSamp?: Maybe<ProViewCityDistanceVarSampOrderBy>;
  variance?: Maybe<ProViewCityDistanceVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_view_city_distance" */
export type ProViewCityDistanceArrRelInsertInput = {
  data: Array<ProViewCityDistanceInsertInput>;
};

/** aggregate avg on columns */
export type ProViewCityDistanceAvgFields = {
  __typename?: 'ProViewCityDistanceAvgFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceAvgOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_view_city_distance". All fields are combined with a logical 'AND'. */
export type ProViewCityDistanceBoolExp = {
  _and?: Maybe<Array<ProViewCityDistanceBoolExp>>;
  _not?: Maybe<ProViewCityDistanceBoolExp>;
  _or?: Maybe<Array<ProViewCityDistanceBoolExp>>;
  city?: Maybe<CityBoolExp>;
  cityId?: Maybe<IntComparisonExp>;
  distance?: Maybe<NumericComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
};

/** input type for inserting data into table "pro_view_city_distance" */
export type ProViewCityDistanceInsertInput = {
  city?: Maybe<CityObjRelInsertInput>;
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['numeric']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProViewCityDistanceMaxFields = {
  __typename?: 'ProViewCityDistanceMaxFields';
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['numeric']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceMaxOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProViewCityDistanceMinFields = {
  __typename?: 'ProViewCityDistanceMinFields';
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['numeric']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceMinOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "pro_view_city_distance". */
export type ProViewCityDistanceOrderBy = {
  city?: Maybe<CityOrderBy>;
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** select columns of table "pro_view_city_distance" */
export enum ProViewCityDistanceSelectColumn {
  /** column name */
  CityId = 'cityId',
  /** column name */
  Distance = 'distance',
  /** column name */
  ProViewId = 'proViewId'
}

/** aggregate stddev on columns */
export type ProViewCityDistanceStddevFields = {
  __typename?: 'ProViewCityDistanceStddevFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceStddevOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProViewCityDistanceStddevPopFields = {
  __typename?: 'ProViewCityDistanceStddevPopFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceStddevPopOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProViewCityDistanceStddevSampFields = {
  __typename?: 'ProViewCityDistanceStddevSampFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceStddevSampOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_view_city_distance" */
export type ProViewCityDistanceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProViewCityDistanceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProViewCityDistanceStreamCursorValueInput = {
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['numeric']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProViewCityDistanceSumFields = {
  __typename?: 'ProViewCityDistanceSumFields';
  cityId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['numeric']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceSumOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProViewCityDistanceVarianceFields = {
  __typename?: 'ProViewCityDistanceVarianceFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceVarianceOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProViewCityDistanceVarPopFields = {
  __typename?: 'ProViewCityDistanceVarPopFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceVarPopOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProViewCityDistanceVarSampFields = {
  __typename?: 'ProViewCityDistanceVarSampFields';
  cityId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_view_city_distance" */
export type ProViewCityDistanceVarSampOrderBy = {
  cityId?: Maybe<OrderBy>;
  distance?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** unique or primary key constraints on table "pro_view" */
export enum ProViewConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_22994Primary = 'idx_22994_primary',
  /** unique or primary key constraint on columns "pro_presentation_id", "bilik_zone_id" */
  Idx_22994ViewUnique = 'idx_22994_view_unique'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProViewDeleteAtPathInput = {
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Array<Scalars['String']>>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Array<Scalars['String']>>;
  contractConfiguration?: Maybe<Array<Scalars['String']>>;
  contractEvents?: Maybe<Array<Scalars['String']>>;
  /** (DC2Type:json_array) */
  telephones?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type ProViewDeleteElemInput = {
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Scalars['Int']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['Int']>;
  contractConfiguration?: Maybe<Scalars['Int']>;
  contractEvents?: Maybe<Scalars['Int']>;
  /** (DC2Type:json_array) */
  telephones?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProViewDeleteKeyInput = {
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['String']>;
  contractConfiguration?: Maybe<Scalars['String']>;
  contractEvents?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  telephones?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "pro_view" */
export type ProViewIncInput = {
  averageProjectDays?: Maybe<Scalars['Int']>;
  averageQuotationDays?: Maybe<Scalars['Int']>;
  averageResponseDays?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  googleReviewLinkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mainAccountId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_view" */
export type ProViewInsertInput = {
  accounts?: Maybe<AccountProViewArrRelInsertInput>;
  avatarHash?: Maybe<Scalars['String']>;
  averageProjectDays?: Maybe<Scalars['Int']>;
  averageQuotationDays?: Maybe<Scalars['Int']>;
  averageResponseDays?: Maybe<Scalars['Int']>;
  bilikZone?: Maybe<BilikZoneObjRelInsertInput>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Scalars['jsonb']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  cities?: Maybe<CityProViewArrRelInsertInput>;
  citiesByDistance?: Maybe<ProViewCityDistanceArrRelInsertInput>;
  colleagues?: Maybe<ProViewProViewArrRelInsertInput>;
  contractConfiguration?: Maybe<Scalars['jsonb']>;
  contractEvents?: Maybe<Scalars['jsonb']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  datePublished?: Maybe<Scalars['timestamptz']>;
  googleReviewLink?: Maybe<GoogleReviewLinkObjRelInsertInput>;
  googleReviewLinkId?: Maybe<Scalars['Int']>;
  groupedProInterventions?: Maybe<GroupedProInterventionObjRelInsertInput>;
  hasContact?: Maybe<Scalars['Boolean']>;
  hasQuotation?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  isCallAlertActivated?: Maybe<Scalars['Boolean']>;
  isCallRecordable?: Maybe<Scalars['Boolean']>;
  isCallsDisabled?: Maybe<Scalars['Boolean']>;
  isLocalBusiness?: Maybe<Scalars['Boolean']>;
  isMainCityCenterExcluded?: Maybe<Scalars['Boolean']>;
  isSmsDisabled?: Maybe<Scalars['Boolean']>;
  isSolicitationsDisabled?: Maybe<Scalars['Boolean']>;
  mainAccount?: Maybe<AccountObjRelInsertInput>;
  mainAccountId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  originalAvatarHash?: Maybe<Scalars['String']>;
  priceComment?: Maybe<Scalars['String']>;
  proHolidays?: Maybe<ProHolidayArrRelInsertInput>;
  proInterventions?: Maybe<ProInterventionArrRelInsertInput>;
  proMessages?: Maybe<ProMessageArrRelInsertInput>;
  proPeople?: Maybe<ProViewProPersonArrRelInsertInput>;
  proPostalAddress?: Maybe<ProPostalAddressObjRelInsertInput>;
  proPresentation?: Maybe<ProPresentationObjRelInsertInput>;
  proPresentationId?: Maybe<Scalars['Int']>;
  proPrices?: Maybe<ProPriceArrRelInsertInput>;
  queued_sms?: Maybe<QueuedSmsArrRelInsertInput>;
  relatedProViews?: Maybe<ProViewProViewArrRelInsertInput>;
  solicitations?: Maybe<SolicitationArrRelInsertInput>;
  status?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  telephones?: Maybe<Scalars['jsonb']>;
  trades?: Maybe<TradeProViewArrRelInsertInput>;
};

/** aggregate max on columns */
export type ProViewMaxFields = {
  __typename?: 'ProViewMaxFields';
  avatarHash?: Maybe<Scalars['String']>;
  averageProjectDays?: Maybe<Scalars['Int']>;
  averageQuotationDays?: Maybe<Scalars['Int']>;
  averageResponseDays?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  datePublished?: Maybe<Scalars['timestamptz']>;
  googleReviewLinkId?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "pro_view_holiday_end_date" */
  holidayEndDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  mainAccountId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  originalAvatarHash?: Maybe<Scalars['String']>;
  priceComment?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pro_view" */
export type ProViewMaxOrderBy = {
  avatarHash?: Maybe<OrderBy>;
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  dateArchived?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  datePublished?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  openingHours?: Maybe<OrderBy>;
  originalAvatarHash?: Maybe<OrderBy>;
  priceComment?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProViewMinFields = {
  __typename?: 'ProViewMinFields';
  avatarHash?: Maybe<Scalars['String']>;
  averageProjectDays?: Maybe<Scalars['Int']>;
  averageQuotationDays?: Maybe<Scalars['Int']>;
  averageResponseDays?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  datePublished?: Maybe<Scalars['timestamptz']>;
  googleReviewLinkId?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "pro_view_holiday_end_date" */
  holidayEndDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  mainAccountId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  originalAvatarHash?: Maybe<Scalars['String']>;
  priceComment?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pro_view" */
export type ProViewMinOrderBy = {
  avatarHash?: Maybe<OrderBy>;
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  dateArchived?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  datePublished?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  openingHours?: Maybe<OrderBy>;
  originalAvatarHash?: Maybe<OrderBy>;
  priceComment?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_view" */
export type ProViewMutationResponse = {
  __typename?: 'ProViewMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProView>;
};

/** input type for inserting object relation for remote table "pro_view" */
export type ProViewObjRelInsertInput = {
  data: ProViewInsertInput;
  /** upsert condition */
  onConflict?: Maybe<ProViewOnConflict>;
};

/** on_conflict condition type for table "pro_view" */
export type ProViewOnConflict = {
  constraint: ProViewConstraint;
  updateColumns: Array<ProViewUpdateColumn>;
  where?: Maybe<ProViewBoolExp>;
};

/** Ordering options when selecting data from "pro_view". */
export type ProViewOrderBy = {
  accountsAggregate?: Maybe<AccountProViewAggregateOrderBy>;
  avatarHash?: Maybe<OrderBy>;
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZone?: Maybe<BilikZoneOrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  callTrackingForward?: Maybe<OrderBy>;
  callTrackingTarget?: Maybe<OrderBy>;
  citiesAggregate?: Maybe<CityProViewAggregateOrderBy>;
  citiesByDistanceAggregate?: Maybe<ProViewCityDistanceAggregateOrderBy>;
  colleaguesAggregate?: Maybe<ProViewProViewAggregateOrderBy>;
  contractConfiguration?: Maybe<OrderBy>;
  contractEvents?: Maybe<OrderBy>;
  dateArchived?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  datePublished?: Maybe<OrderBy>;
  googleReviewLink?: Maybe<GoogleReviewLinkOrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  groupedProInterventions?: Maybe<GroupedProInterventionOrderBy>;
  hasContact?: Maybe<OrderBy>;
  hasQuotation?: Maybe<OrderBy>;
  holidayEndDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isCallAlertActivated?: Maybe<OrderBy>;
  isCallRecordable?: Maybe<OrderBy>;
  isCallsDisabled?: Maybe<OrderBy>;
  isLocalBusiness?: Maybe<OrderBy>;
  isMainCityCenterExcluded?: Maybe<OrderBy>;
  isSmsDisabled?: Maybe<OrderBy>;
  isSolicitationsDisabled?: Maybe<OrderBy>;
  mainAccount?: Maybe<AccountOrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  openingHours?: Maybe<OrderBy>;
  originalAvatarHash?: Maybe<OrderBy>;
  priceComment?: Maybe<OrderBy>;
  proHolidaysAggregate?: Maybe<ProHolidayAggregateOrderBy>;
  proInterventionsAggregate?: Maybe<ProInterventionAggregateOrderBy>;
  proMessagesAggregate?: Maybe<ProMessageAggregateOrderBy>;
  proPeopleAggregate?: Maybe<ProViewProPersonAggregateOrderBy>;
  proPostalAddress?: Maybe<ProPostalAddressOrderBy>;
  proPresentation?: Maybe<ProPresentationOrderBy>;
  proPresentationId?: Maybe<OrderBy>;
  proPricesAggregate?: Maybe<ProPriceAggregateOrderBy>;
  queued_smsAggregate?: Maybe<QueuedSmsAggregateOrderBy>;
  relatedProViewsAggregate?: Maybe<ProViewProViewAggregateOrderBy>;
  solicitationsAggregate?: Maybe<SolicitationAggregateOrderBy>;
  status?: Maybe<OrderBy>;
  telephones?: Maybe<OrderBy>;
  tradesAggregate?: Maybe<TradeProViewAggregateOrderBy>;
};

/** primary key columns input for table: pro_view */
export type ProViewPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProViewPrependInput = {
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Scalars['jsonb']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  contractConfiguration?: Maybe<Scalars['jsonb']>;
  contractEvents?: Maybe<Scalars['jsonb']>;
  /** (DC2Type:json_array) */
  telephones?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "pro_view_pro_person" */
export type ProViewProPerson = {
  __typename?: 'ProViewProPerson';
  isVisible: Scalars['Boolean'];
  position: Scalars['Int'];
  /** An object relationship */
  proPerson: ProPerson;
  proPersonId: Scalars['Int'];
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
};

/** aggregated selection of "pro_view_pro_person" */
export type ProViewProPersonAggregate = {
  __typename?: 'ProViewProPersonAggregate';
  aggregate?: Maybe<ProViewProPersonAggregateFields>;
  nodes: Array<ProViewProPerson>;
};

export type ProViewProPersonAggregateBoolExp = {
  bool_and?: Maybe<ProViewProPersonAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<ProViewProPersonAggregateBoolExpBoolOr>;
  count?: Maybe<ProViewProPersonAggregateBoolExpCount>;
};

export type ProViewProPersonAggregateBoolExpBoolAnd = {
  arguments: ProViewProPersonSelectColumnProViewProPersonAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProViewProPersonBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProViewProPersonAggregateBoolExpBoolOr = {
  arguments: ProViewProPersonSelectColumnProViewProPersonAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProViewProPersonBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ProViewProPersonAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProViewProPersonSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProViewProPersonBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_view_pro_person" */
export type ProViewProPersonAggregateFields = {
  __typename?: 'ProViewProPersonAggregateFields';
  avg?: Maybe<ProViewProPersonAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProViewProPersonMaxFields>;
  min?: Maybe<ProViewProPersonMinFields>;
  stddev?: Maybe<ProViewProPersonStddevFields>;
  stddevPop?: Maybe<ProViewProPersonStddevPopFields>;
  stddevSamp?: Maybe<ProViewProPersonStddevSampFields>;
  sum?: Maybe<ProViewProPersonSumFields>;
  varPop?: Maybe<ProViewProPersonVarPopFields>;
  varSamp?: Maybe<ProViewProPersonVarSampFields>;
  variance?: Maybe<ProViewProPersonVarianceFields>;
};


/** aggregate fields of "pro_view_pro_person" */
export type ProViewProPersonAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProViewProPersonSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_view_pro_person" */
export type ProViewProPersonAggregateOrderBy = {
  avg?: Maybe<ProViewProPersonAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProViewProPersonMaxOrderBy>;
  min?: Maybe<ProViewProPersonMinOrderBy>;
  stddev?: Maybe<ProViewProPersonStddevOrderBy>;
  stddevPop?: Maybe<ProViewProPersonStddevPopOrderBy>;
  stddevSamp?: Maybe<ProViewProPersonStddevSampOrderBy>;
  sum?: Maybe<ProViewProPersonSumOrderBy>;
  varPop?: Maybe<ProViewProPersonVarPopOrderBy>;
  varSamp?: Maybe<ProViewProPersonVarSampOrderBy>;
  variance?: Maybe<ProViewProPersonVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_view_pro_person" */
export type ProViewProPersonArrRelInsertInput = {
  data: Array<ProViewProPersonInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProViewProPersonOnConflict>;
};

/** aggregate avg on columns */
export type ProViewProPersonAvgFields = {
  __typename?: 'ProViewProPersonAvgFields';
  position?: Maybe<Scalars['Float']>;
  proPersonId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_view_pro_person" */
export type ProViewProPersonAvgOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_view_pro_person". All fields are combined with a logical 'AND'. */
export type ProViewProPersonBoolExp = {
  _and?: Maybe<Array<ProViewProPersonBoolExp>>;
  _not?: Maybe<ProViewProPersonBoolExp>;
  _or?: Maybe<Array<ProViewProPersonBoolExp>>;
  isVisible?: Maybe<BooleanComparisonExp>;
  position?: Maybe<IntComparisonExp>;
  proPerson?: Maybe<ProPersonBoolExp>;
  proPersonId?: Maybe<IntComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "pro_view_pro_person" */
export enum ProViewProPersonConstraint {
  /** unique or primary key constraint on columns "pro_view_id", "pro_person_id" */
  ProViewProPersonPkey = 'pro_view_pro_person_pkey'
}

/** input type for incrementing numeric columns in table "pro_view_pro_person" */
export type ProViewProPersonIncInput = {
  position?: Maybe<Scalars['Int']>;
  proPersonId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_view_pro_person" */
export type ProViewProPersonInsertInput = {
  isVisible?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  proPerson?: Maybe<ProPersonObjRelInsertInput>;
  proPersonId?: Maybe<Scalars['Int']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProViewProPersonMaxFields = {
  __typename?: 'ProViewProPersonMaxFields';
  position?: Maybe<Scalars['Int']>;
  proPersonId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pro_view_pro_person" */
export type ProViewProPersonMaxOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProViewProPersonMinFields = {
  __typename?: 'ProViewProPersonMinFields';
  position?: Maybe<Scalars['Int']>;
  proPersonId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pro_view_pro_person" */
export type ProViewProPersonMinOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_view_pro_person" */
export type ProViewProPersonMutationResponse = {
  __typename?: 'ProViewProPersonMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProViewProPerson>;
};

/** on_conflict condition type for table "pro_view_pro_person" */
export type ProViewProPersonOnConflict = {
  constraint: ProViewProPersonConstraint;
  updateColumns: Array<ProViewProPersonUpdateColumn>;
  where?: Maybe<ProViewProPersonBoolExp>;
};

/** Ordering options when selecting data from "pro_view_pro_person". */
export type ProViewProPersonOrderBy = {
  isVisible?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  proPerson?: Maybe<ProPersonOrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_view_pro_person */
export type ProViewProPersonPkColumnsInput = {
  proPersonId: Scalars['Int'];
  proViewId: Scalars['Int'];
};

/** select columns of table "pro_view_pro_person" */
export enum ProViewProPersonSelectColumn {
  /** column name */
  IsVisible = 'isVisible',
  /** column name */
  Position = 'position',
  /** column name */
  ProPersonId = 'proPersonId',
  /** column name */
  ProViewId = 'proViewId'
}

/** select "proViewProPersonAggregateBoolExpBool_andArgumentsColumns" columns of table "pro_view_pro_person" */
export enum ProViewProPersonSelectColumnProViewProPersonAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsVisible = 'isVisible'
}

/** select "proViewProPersonAggregateBoolExpBool_orArgumentsColumns" columns of table "pro_view_pro_person" */
export enum ProViewProPersonSelectColumnProViewProPersonAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsVisible = 'isVisible'
}

/** input type for updating data in table "pro_view_pro_person" */
export type ProViewProPersonSetInput = {
  isVisible?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  proPersonId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProViewProPersonStddevFields = {
  __typename?: 'ProViewProPersonStddevFields';
  position?: Maybe<Scalars['Float']>;
  proPersonId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_view_pro_person" */
export type ProViewProPersonStddevOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProViewProPersonStddevPopFields = {
  __typename?: 'ProViewProPersonStddevPopFields';
  position?: Maybe<Scalars['Float']>;
  proPersonId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_view_pro_person" */
export type ProViewProPersonStddevPopOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProViewProPersonStddevSampFields = {
  __typename?: 'ProViewProPersonStddevSampFields';
  position?: Maybe<Scalars['Float']>;
  proPersonId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_view_pro_person" */
export type ProViewProPersonStddevSampOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_view_pro_person" */
export type ProViewProPersonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProViewProPersonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProViewProPersonStreamCursorValueInput = {
  isVisible?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  proPersonId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProViewProPersonSumFields = {
  __typename?: 'ProViewProPersonSumFields';
  position?: Maybe<Scalars['Int']>;
  proPersonId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_view_pro_person" */
export type ProViewProPersonSumOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "pro_view_pro_person" */
export enum ProViewProPersonUpdateColumn {
  /** column name */
  IsVisible = 'isVisible',
  /** column name */
  Position = 'position',
  /** column name */
  ProPersonId = 'proPersonId',
  /** column name */
  ProViewId = 'proViewId'
}

export type ProViewProPersonUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProViewProPersonIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProViewProPersonSetInput>;
  /** filter the rows which have to be updated */
  where: ProViewProPersonBoolExp;
};

/** aggregate variance on columns */
export type ProViewProPersonVarianceFields = {
  __typename?: 'ProViewProPersonVarianceFields';
  position?: Maybe<Scalars['Float']>;
  proPersonId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_view_pro_person" */
export type ProViewProPersonVarianceOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProViewProPersonVarPopFields = {
  __typename?: 'ProViewProPersonVarPopFields';
  position?: Maybe<Scalars['Float']>;
  proPersonId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_view_pro_person" */
export type ProViewProPersonVarPopOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProViewProPersonVarSampFields = {
  __typename?: 'ProViewProPersonVarSampFields';
  position?: Maybe<Scalars['Float']>;
  proPersonId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_view_pro_person" */
export type ProViewProPersonVarSampOrderBy = {
  position?: Maybe<OrderBy>;
  proPersonId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** columns and relationships of "pro_view_pro_view" */
export type ProViewProView = {
  __typename?: 'ProViewProView';
  /** An object relationship */
  proView: ProView;
  /** An object relationship */
  proViewByProViewSource: ProView;
  proViewSource: Scalars['Int'];
  proViewTarget: Scalars['Int'];
};

/** aggregated selection of "pro_view_pro_view" */
export type ProViewProViewAggregate = {
  __typename?: 'ProViewProViewAggregate';
  aggregate?: Maybe<ProViewProViewAggregateFields>;
  nodes: Array<ProViewProView>;
};

export type ProViewProViewAggregateBoolExp = {
  count?: Maybe<ProViewProViewAggregateBoolExpCount>;
};

export type ProViewProViewAggregateBoolExpCount = {
  arguments?: Maybe<Array<ProViewProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ProViewProViewBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "pro_view_pro_view" */
export type ProViewProViewAggregateFields = {
  __typename?: 'ProViewProViewAggregateFields';
  avg?: Maybe<ProViewProViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProViewProViewMaxFields>;
  min?: Maybe<ProViewProViewMinFields>;
  stddev?: Maybe<ProViewProViewStddevFields>;
  stddevPop?: Maybe<ProViewProViewStddevPopFields>;
  stddevSamp?: Maybe<ProViewProViewStddevSampFields>;
  sum?: Maybe<ProViewProViewSumFields>;
  varPop?: Maybe<ProViewProViewVarPopFields>;
  varSamp?: Maybe<ProViewProViewVarSampFields>;
  variance?: Maybe<ProViewProViewVarianceFields>;
};


/** aggregate fields of "pro_view_pro_view" */
export type ProViewProViewAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProViewProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pro_view_pro_view" */
export type ProViewProViewAggregateOrderBy = {
  avg?: Maybe<ProViewProViewAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProViewProViewMaxOrderBy>;
  min?: Maybe<ProViewProViewMinOrderBy>;
  stddev?: Maybe<ProViewProViewStddevOrderBy>;
  stddevPop?: Maybe<ProViewProViewStddevPopOrderBy>;
  stddevSamp?: Maybe<ProViewProViewStddevSampOrderBy>;
  sum?: Maybe<ProViewProViewSumOrderBy>;
  varPop?: Maybe<ProViewProViewVarPopOrderBy>;
  varSamp?: Maybe<ProViewProViewVarSampOrderBy>;
  variance?: Maybe<ProViewProViewVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pro_view_pro_view" */
export type ProViewProViewArrRelInsertInput = {
  data: Array<ProViewProViewInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ProViewProViewOnConflict>;
};

/** aggregate avg on columns */
export type ProViewProViewAvgFields = {
  __typename?: 'ProViewProViewAvgFields';
  proViewSource?: Maybe<Scalars['Float']>;
  proViewTarget?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pro_view_pro_view" */
export type ProViewProViewAvgOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pro_view_pro_view". All fields are combined with a logical 'AND'. */
export type ProViewProViewBoolExp = {
  _and?: Maybe<Array<ProViewProViewBoolExp>>;
  _not?: Maybe<ProViewProViewBoolExp>;
  _or?: Maybe<Array<ProViewProViewBoolExp>>;
  proView?: Maybe<ProViewBoolExp>;
  proViewByProViewSource?: Maybe<ProViewBoolExp>;
  proViewSource?: Maybe<IntComparisonExp>;
  proViewTarget?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "pro_view_pro_view" */
export enum ProViewProViewConstraint {
  /** unique or primary key constraint on columns "pro_view_source", "pro_view_target" */
  Idx_23009Primary = 'idx_23009_primary'
}

/** input type for incrementing numeric columns in table "pro_view_pro_view" */
export type ProViewProViewIncInput = {
  proViewSource?: Maybe<Scalars['Int']>;
  proViewTarget?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_view_pro_view" */
export type ProViewProViewInsertInput = {
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewByProViewSource?: Maybe<ProViewObjRelInsertInput>;
  proViewSource?: Maybe<Scalars['Int']>;
  proViewTarget?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ProViewProViewMaxFields = {
  __typename?: 'ProViewProViewMaxFields';
  proViewSource?: Maybe<Scalars['Int']>;
  proViewTarget?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pro_view_pro_view" */
export type ProViewProViewMaxOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProViewProViewMinFields = {
  __typename?: 'ProViewProViewMinFields';
  proViewSource?: Maybe<Scalars['Int']>;
  proViewTarget?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pro_view_pro_view" */
export type ProViewProViewMinOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** response of any mutation on the table "pro_view_pro_view" */
export type ProViewProViewMutationResponse = {
  __typename?: 'ProViewProViewMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ProViewProView>;
};

/** on_conflict condition type for table "pro_view_pro_view" */
export type ProViewProViewOnConflict = {
  constraint: ProViewProViewConstraint;
  updateColumns: Array<ProViewProViewUpdateColumn>;
  where?: Maybe<ProViewProViewBoolExp>;
};

/** Ordering options when selecting data from "pro_view_pro_view". */
export type ProViewProViewOrderBy = {
  proView?: Maybe<ProViewOrderBy>;
  proViewByProViewSource?: Maybe<ProViewOrderBy>;
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** primary key columns input for table: pro_view_pro_view */
export type ProViewProViewPkColumnsInput = {
  proViewSource: Scalars['Int'];
  proViewTarget: Scalars['Int'];
};

/** select columns of table "pro_view_pro_view" */
export enum ProViewProViewSelectColumn {
  /** column name */
  ProViewSource = 'proViewSource',
  /** column name */
  ProViewTarget = 'proViewTarget'
}

/** input type for updating data in table "pro_view_pro_view" */
export type ProViewProViewSetInput = {
  proViewSource?: Maybe<Scalars['Int']>;
  proViewTarget?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ProViewProViewStddevFields = {
  __typename?: 'ProViewProViewStddevFields';
  proViewSource?: Maybe<Scalars['Float']>;
  proViewTarget?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_view_pro_view" */
export type ProViewProViewStddevOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProViewProViewStddevPopFields = {
  __typename?: 'ProViewProViewStddevPopFields';
  proViewSource?: Maybe<Scalars['Float']>;
  proViewTarget?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_view_pro_view" */
export type ProViewProViewStddevPopOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProViewProViewStddevSampFields = {
  __typename?: 'ProViewProViewStddevSampFields';
  proViewSource?: Maybe<Scalars['Float']>;
  proViewTarget?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_view_pro_view" */
export type ProViewProViewStddevSampOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_view_pro_view" */
export type ProViewProViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProViewProViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProViewProViewStreamCursorValueInput = {
  proViewSource?: Maybe<Scalars['Int']>;
  proViewTarget?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ProViewProViewSumFields = {
  __typename?: 'ProViewProViewSumFields';
  proViewSource?: Maybe<Scalars['Int']>;
  proViewTarget?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_view_pro_view" */
export type ProViewProViewSumOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** update columns of table "pro_view_pro_view" */
export enum ProViewProViewUpdateColumn {
  /** column name */
  ProViewSource = 'proViewSource',
  /** column name */
  ProViewTarget = 'proViewTarget'
}

export type ProViewProViewUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProViewProViewIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProViewProViewSetInput>;
  /** filter the rows which have to be updated */
  where: ProViewProViewBoolExp;
};

/** aggregate variance on columns */
export type ProViewProViewVarianceFields = {
  __typename?: 'ProViewProViewVarianceFields';
  proViewSource?: Maybe<Scalars['Float']>;
  proViewTarget?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_view_pro_view" */
export type ProViewProViewVarianceOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProViewProViewVarPopFields = {
  __typename?: 'ProViewProViewVarPopFields';
  proViewSource?: Maybe<Scalars['Float']>;
  proViewTarget?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_view_pro_view" */
export type ProViewProViewVarPopOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProViewProViewVarSampFields = {
  __typename?: 'ProViewProViewVarSampFields';
  proViewSource?: Maybe<Scalars['Float']>;
  proViewTarget?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_view_pro_view" */
export type ProViewProViewVarSampOrderBy = {
  proViewSource?: Maybe<OrderBy>;
  proViewTarget?: Maybe<OrderBy>;
};

/** select columns of table "pro_view" */
export enum ProViewSelectColumn {
  /** column name */
  AvatarHash = 'avatarHash',
  /** column name */
  AverageProjectDays = 'averageProjectDays',
  /** column name */
  AverageQuotationDays = 'averageQuotationDays',
  /** column name */
  AverageResponseDays = 'averageResponseDays',
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  CallTrackingForward = 'callTrackingForward',
  /** column name */
  CallTrackingTarget = 'callTrackingTarget',
  /** column name */
  ContractConfiguration = 'contractConfiguration',
  /** column name */
  ContractEvents = 'contractEvents',
  /** column name */
  DateArchived = 'dateArchived',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DatePublished = 'datePublished',
  /** column name */
  GoogleReviewLinkId = 'googleReviewLinkId',
  /** column name */
  HasContact = 'hasContact',
  /** column name */
  HasQuotation = 'hasQuotation',
  /** column name */
  Id = 'id',
  /** column name */
  IsCallAlertActivated = 'isCallAlertActivated',
  /** column name */
  IsCallRecordable = 'isCallRecordable',
  /** column name */
  IsCallsDisabled = 'isCallsDisabled',
  /** column name */
  IsLocalBusiness = 'isLocalBusiness',
  /** column name */
  IsMainCityCenterExcluded = 'isMainCityCenterExcluded',
  /** column name */
  IsSmsDisabled = 'isSmsDisabled',
  /** column name */
  IsSolicitationsDisabled = 'isSolicitationsDisabled',
  /** column name */
  MainAccountId = 'mainAccountId',
  /** column name */
  Note = 'note',
  /** column name */
  OpeningHours = 'openingHours',
  /** column name */
  OriginalAvatarHash = 'originalAvatarHash',
  /** column name */
  PriceComment = 'priceComment',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  Status = 'status',
  /** column name */
  Telephones = 'telephones'
}

/** select "proViewAggregateBoolExpBool_andArgumentsColumns" columns of table "pro_view" */
export enum ProViewSelectColumnProViewAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  HasContact = 'hasContact',
  /** column name */
  HasQuotation = 'hasQuotation',
  /** column name */
  IsCallAlertActivated = 'isCallAlertActivated',
  /** column name */
  IsCallRecordable = 'isCallRecordable',
  /** column name */
  IsCallsDisabled = 'isCallsDisabled',
  /** column name */
  IsLocalBusiness = 'isLocalBusiness',
  /** column name */
  IsMainCityCenterExcluded = 'isMainCityCenterExcluded',
  /** column name */
  IsSmsDisabled = 'isSmsDisabled',
  /** column name */
  IsSolicitationsDisabled = 'isSolicitationsDisabled'
}

/** select "proViewAggregateBoolExpBool_orArgumentsColumns" columns of table "pro_view" */
export enum ProViewSelectColumnProViewAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  HasContact = 'hasContact',
  /** column name */
  HasQuotation = 'hasQuotation',
  /** column name */
  IsCallAlertActivated = 'isCallAlertActivated',
  /** column name */
  IsCallRecordable = 'isCallRecordable',
  /** column name */
  IsCallsDisabled = 'isCallsDisabled',
  /** column name */
  IsLocalBusiness = 'isLocalBusiness',
  /** column name */
  IsMainCityCenterExcluded = 'isMainCityCenterExcluded',
  /** column name */
  IsSmsDisabled = 'isSmsDisabled',
  /** column name */
  IsSolicitationsDisabled = 'isSolicitationsDisabled'
}

/** input type for updating data in table "pro_view" */
export type ProViewSetInput = {
  avatarHash?: Maybe<Scalars['String']>;
  averageProjectDays?: Maybe<Scalars['Int']>;
  averageQuotationDays?: Maybe<Scalars['Int']>;
  averageResponseDays?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Scalars['jsonb']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  contractConfiguration?: Maybe<Scalars['jsonb']>;
  contractEvents?: Maybe<Scalars['jsonb']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  datePublished?: Maybe<Scalars['timestamptz']>;
  googleReviewLinkId?: Maybe<Scalars['Int']>;
  hasContact?: Maybe<Scalars['Boolean']>;
  hasQuotation?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  isCallAlertActivated?: Maybe<Scalars['Boolean']>;
  isCallRecordable?: Maybe<Scalars['Boolean']>;
  isCallsDisabled?: Maybe<Scalars['Boolean']>;
  isLocalBusiness?: Maybe<Scalars['Boolean']>;
  isMainCityCenterExcluded?: Maybe<Scalars['Boolean']>;
  isSmsDisabled?: Maybe<Scalars['Boolean']>;
  isSolicitationsDisabled?: Maybe<Scalars['Boolean']>;
  mainAccountId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  originalAvatarHash?: Maybe<Scalars['String']>;
  priceComment?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  telephones?: Maybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type ProViewStddevFields = {
  __typename?: 'ProViewStddevFields';
  averageProjectDays?: Maybe<Scalars['Float']>;
  averageQuotationDays?: Maybe<Scalars['Float']>;
  averageResponseDays?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
  googleReviewLinkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mainAccountId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pro_view" */
export type ProViewStddevOrderBy = {
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ProViewStddevPopFields = {
  __typename?: 'ProViewStddevPopFields';
  averageProjectDays?: Maybe<Scalars['Float']>;
  averageQuotationDays?: Maybe<Scalars['Float']>;
  averageResponseDays?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
  googleReviewLinkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mainAccountId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "pro_view" */
export type ProViewStddevPopOrderBy = {
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ProViewStddevSampFields = {
  __typename?: 'ProViewStddevSampFields';
  averageProjectDays?: Maybe<Scalars['Float']>;
  averageQuotationDays?: Maybe<Scalars['Float']>;
  averageResponseDays?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
  googleReviewLinkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mainAccountId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "pro_view" */
export type ProViewStddevSampOrderBy = {
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "pro_view" */
export type ProViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProViewStreamCursorValueInput = {
  avatarHash?: Maybe<Scalars['String']>;
  averageProjectDays?: Maybe<Scalars['Int']>;
  averageQuotationDays?: Maybe<Scalars['Int']>;
  averageResponseDays?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  /** (DC2Type:json_array) */
  callTrackingForward?: Maybe<Scalars['jsonb']>;
  /** (DC2Type:json_array) */
  callTrackingTarget?: Maybe<Scalars['jsonb']>;
  contractConfiguration?: Maybe<Scalars['jsonb']>;
  contractEvents?: Maybe<Scalars['jsonb']>;
  dateArchived?: Maybe<Scalars['timestamptz']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  datePublished?: Maybe<Scalars['timestamptz']>;
  googleReviewLinkId?: Maybe<Scalars['Int']>;
  hasContact?: Maybe<Scalars['Boolean']>;
  hasQuotation?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  isCallAlertActivated?: Maybe<Scalars['Boolean']>;
  isCallRecordable?: Maybe<Scalars['Boolean']>;
  isCallsDisabled?: Maybe<Scalars['Boolean']>;
  isLocalBusiness?: Maybe<Scalars['Boolean']>;
  isMainCityCenterExcluded?: Maybe<Scalars['Boolean']>;
  isSmsDisabled?: Maybe<Scalars['Boolean']>;
  isSolicitationsDisabled?: Maybe<Scalars['Boolean']>;
  mainAccountId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  originalAvatarHash?: Maybe<Scalars['String']>;
  priceComment?: Maybe<Scalars['String']>;
  proPresentationId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  telephones?: Maybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type ProViewSumFields = {
  __typename?: 'ProViewSumFields';
  averageProjectDays?: Maybe<Scalars['Int']>;
  averageQuotationDays?: Maybe<Scalars['Int']>;
  averageResponseDays?: Maybe<Scalars['Int']>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  googleReviewLinkId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  mainAccountId?: Maybe<Scalars['Int']>;
  proPresentationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pro_view" */
export type ProViewSumOrderBy = {
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** update columns of table "pro_view" */
export enum ProViewUpdateColumn {
  /** column name */
  AvatarHash = 'avatarHash',
  /** column name */
  AverageProjectDays = 'averageProjectDays',
  /** column name */
  AverageQuotationDays = 'averageQuotationDays',
  /** column name */
  AverageResponseDays = 'averageResponseDays',
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  CallTrackingForward = 'callTrackingForward',
  /** column name */
  CallTrackingTarget = 'callTrackingTarget',
  /** column name */
  ContractConfiguration = 'contractConfiguration',
  /** column name */
  ContractEvents = 'contractEvents',
  /** column name */
  DateArchived = 'dateArchived',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DatePublished = 'datePublished',
  /** column name */
  GoogleReviewLinkId = 'googleReviewLinkId',
  /** column name */
  HasContact = 'hasContact',
  /** column name */
  HasQuotation = 'hasQuotation',
  /** column name */
  Id = 'id',
  /** column name */
  IsCallAlertActivated = 'isCallAlertActivated',
  /** column name */
  IsCallRecordable = 'isCallRecordable',
  /** column name */
  IsCallsDisabled = 'isCallsDisabled',
  /** column name */
  IsLocalBusiness = 'isLocalBusiness',
  /** column name */
  IsMainCityCenterExcluded = 'isMainCityCenterExcluded',
  /** column name */
  IsSmsDisabled = 'isSmsDisabled',
  /** column name */
  IsSolicitationsDisabled = 'isSolicitationsDisabled',
  /** column name */
  MainAccountId = 'mainAccountId',
  /** column name */
  Note = 'note',
  /** column name */
  OpeningHours = 'openingHours',
  /** column name */
  OriginalAvatarHash = 'originalAvatarHash',
  /** column name */
  PriceComment = 'priceComment',
  /** column name */
  ProPresentationId = 'proPresentationId',
  /** column name */
  Status = 'status',
  /** column name */
  Telephones = 'telephones'
}

export type ProViewUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<ProViewAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: Maybe<ProViewDeleteAtPathInput>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _deleteElem?: Maybe<ProViewDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: Maybe<ProViewDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ProViewIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<ProViewPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ProViewSetInput>;
  /** filter the rows which have to be updated */
  where: ProViewBoolExp;
};

/** aggregate variance on columns */
export type ProViewVarianceFields = {
  __typename?: 'ProViewVarianceFields';
  averageProjectDays?: Maybe<Scalars['Float']>;
  averageQuotationDays?: Maybe<Scalars['Float']>;
  averageResponseDays?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
  googleReviewLinkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mainAccountId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pro_view" */
export type ProViewVarianceOrderBy = {
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ProViewVarPopFields = {
  __typename?: 'ProViewVarPopFields';
  averageProjectDays?: Maybe<Scalars['Float']>;
  averageQuotationDays?: Maybe<Scalars['Float']>;
  averageResponseDays?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
  googleReviewLinkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mainAccountId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "pro_view" */
export type ProViewVarPopOrderBy = {
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ProViewVarSampFields = {
  __typename?: 'ProViewVarSampFields';
  averageProjectDays?: Maybe<Scalars['Float']>;
  averageQuotationDays?: Maybe<Scalars['Float']>;
  averageResponseDays?: Maybe<Scalars['Float']>;
  bilikZoneId?: Maybe<Scalars['Float']>;
  googleReviewLinkId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mainAccountId?: Maybe<Scalars['Float']>;
  proPresentationId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "pro_view" */
export type ProViewVarSampOrderBy = {
  averageProjectDays?: Maybe<OrderBy>;
  averageQuotationDays?: Maybe<OrderBy>;
  averageResponseDays?: Maybe<OrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  googleReviewLinkId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mainAccountId?: Maybe<OrderBy>;
  proPresentationId?: Maybe<OrderBy>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  accountAggregate: AccountAggregate;
  /** fetch data from the table: "account" using primary key columns */
  accountByPk?: Maybe<Account>;
  /** fetch data from the table: "account_device" */
  accountDevice: Array<AccountDevice>;
  /** fetch aggregated fields from the table: "account_device" */
  accountDeviceAggregate: AccountDeviceAggregate;
  /** fetch data from the table: "account_device" using primary key columns */
  accountDeviceByPk?: Maybe<AccountDevice>;
  /** fetch data from the table: "account_pro_view" */
  accountProView: Array<AccountProView>;
  /** fetch aggregated fields from the table: "account_pro_view" */
  accountProViewAggregate: AccountProViewAggregate;
  /** fetch data from the table: "account_pro_view" using primary key columns */
  accountProViewByPk?: Maybe<AccountProView>;
  /** fetch data from the table: "action_token" */
  actionToken: Array<ActionToken>;
  /** fetch aggregated fields from the table: "action_token" */
  actionTokenAggregate: ActionTokenAggregate;
  /** fetch data from the table: "action_token" using primary key columns */
  actionTokenByPk?: Maybe<ActionToken>;
  /** fetch data from the table: "autorespond" */
  autorespond: Array<Autorespond>;
  /** fetch aggregated fields from the table: "autorespond" */
  autorespondAggregate: AutorespondAggregate;
  /** fetch data from the table: "autorespond" using primary key columns */
  autorespondByPk?: Maybe<Autorespond>;
  /** fetch data from the table: "bilik_person" */
  bilikPerson: Array<BilikPerson>;
  /** fetch aggregated fields from the table: "bilik_person" */
  bilikPersonAggregate: BilikPersonAggregate;
  /** fetch data from the table: "bilik_person" using primary key columns */
  bilikPersonByPk?: Maybe<BilikPerson>;
  /** fetch data from the table: "bilik_solicitation" */
  bilikSolicitation: Array<BilikSolicitation>;
  /** fetch aggregated fields from the table: "bilik_solicitation" */
  bilikSolicitationAggregate: BilikSolicitationAggregate;
  /** fetch data from the table: "bilik_solicitation" using primary key columns */
  bilikSolicitationByPk?: Maybe<BilikSolicitation>;
  /** fetch data from the table: "bilik_zone" */
  bilikZone: Array<BilikZone>;
  /** fetch aggregated fields from the table: "bilik_zone" */
  bilikZoneAggregate: BilikZoneAggregate;
  /** fetch data from the table: "bilik_zone" using primary key columns */
  bilikZoneByPk?: Maybe<BilikZone>;
  /** fetch data from the table: "bilik_zone_trade_main_pro_count" */
  bilikZoneTradeMainProCount: Array<BilikZoneTradeMainProCount>;
  /** fetch aggregated fields from the table: "bilik_zone_trade_main_pro_count" */
  bilikZoneTradeMainProCountAggregate: BilikZoneTradeMainProCountAggregate;
  /** fetch data from the table: "certification" */
  certification: Array<Certification>;
  /** fetch aggregated fields from the table: "certification" */
  certificationAggregate: CertificationAggregate;
  /** fetch data from the table: "certification" using primary key columns */
  certificationByPk?: Maybe<Certification>;
  /** fetch data from the table: "city" */
  city: Array<City>;
  /** fetch aggregated fields from the table: "city" */
  cityAggregate: CityAggregate;
  /** fetch data from the table: "city" using primary key columns */
  cityByPk?: Maybe<City>;
  /** fetch data from the table: "city_pro_view" */
  cityProView: Array<CityProView>;
  /** fetch aggregated fields from the table: "city_pro_view" */
  cityProViewAggregate: CityProViewAggregate;
  /** fetch data from the table: "city_pro_view" using primary key columns */
  cityProViewByPk?: Maybe<CityProView>;
  /** fetch data from the table: "count_pro_review_by_status" */
  countProReviewByStatus: Array<CountProReviewByStatus>;
  /** fetch aggregated fields from the table: "count_pro_review_by_status" */
  countProReviewByStatusAggregate: CountProReviewByStatusAggregate;
  /** fetch data from the table: "count_pro_review_invitations_by_month_by_pro_presentation" */
  countProReviewInvitationsByMonthByProPresentation: Array<CountProReviewInvitationsByMonthByProPresentation>;
  /** fetch aggregated fields from the table: "count_pro_review_invitations_by_month_by_pro_presentation" */
  countProReviewInvitationsByMonthByProPresentationAggregate: CountProReviewInvitationsByMonthByProPresentationAggregate;
  /** fetch data from the table: "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
  countProReviewsWithStatusOkByMonthByProPresentation: Array<CountProReviewsWithStatusOkByMonthByProPresentation>;
  /** fetch aggregated fields from the table: "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
  countProReviewsWithStatusOkByMonthByProPresentationAggregate: CountProReviewsWithStatusOkByMonthByProPresentationAggregate;
  /** fetch data from the table: "device" */
  device: Array<Device>;
  /** fetch aggregated fields from the table: "device" */
  deviceAggregate: DeviceAggregate;
  /** fetch data from the table: "device" using primary key columns */
  deviceByPk?: Maybe<Device>;
  /** fetch data from the table: "geography_columns" */
  geographyColumns: Array<GeographyColumns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geographyColumnsAggregate: GeographyColumnsAggregate;
  /** fetch data from the table: "geometry_columns" */
  geometryColumns: Array<GeometryColumns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometryColumnsAggregate: GeometryColumnsAggregate;
  /** fetch data from the table: "globally_blocked_identity" */
  globallyBlockedIdentity: Array<GloballyBlockedIdentity>;
  /** fetch aggregated fields from the table: "globally_blocked_identity" */
  globallyBlockedIdentityAggregate: GloballyBlockedIdentityAggregate;
  /** fetch data from the table: "globally_blocked_identity" using primary key columns */
  globallyBlockedIdentityByPk?: Maybe<GloballyBlockedIdentity>;
  /** fetch data from the table: "google_review_link" */
  googleReviewLink: Array<GoogleReviewLink>;
  /** fetch aggregated fields from the table: "google_review_link" */
  googleReviewLinkAggregate: GoogleReviewLinkAggregate;
  /** fetch data from the table: "google_review_link" using primary key columns */
  googleReviewLinkByPk?: Maybe<GoogleReviewLink>;
  /** fetch data from the table: "grouped_pro_intervention" */
  groupedProIntervention: Array<GroupedProIntervention>;
  /** fetch aggregated fields from the table: "grouped_pro_intervention" */
  groupedProInterventionAggregate: GroupedProInterventionAggregate;
  /** fetch data from the table: "manager_bilik_person_bilik_zone" */
  managerBilikPersonBilikZone: Array<ManagerBilikPersonBilikZone>;
  /** fetch aggregated fields from the table: "manager_bilik_person_bilik_zone" */
  managerBilikPersonBilikZoneAggregate: ManagerBilikPersonBilikZoneAggregate;
  /** fetch data from the table: "manager_bilik_person_bilik_zone" using primary key columns */
  managerBilikPersonBilikZoneByPk?: Maybe<ManagerBilikPersonBilikZone>;
  /** fetch data from the table: "member_bilik_person_bilik_zone" */
  memberBilikPersonBilikZone: Array<MemberBilikPersonBilikZone>;
  /** fetch aggregated fields from the table: "member_bilik_person_bilik_zone" */
  memberBilikPersonBilikZoneAggregate: MemberBilikPersonBilikZoneAggregate;
  /** fetch data from the table: "member_bilik_person_bilik_zone" using primary key columns */
  memberBilikPersonBilikZoneByPk?: Maybe<MemberBilikPersonBilikZone>;
  /** fetch data from the table: "note_identity" */
  noteIdentity: Array<NoteIdentity>;
  /** fetch aggregated fields from the table: "note_identity" */
  noteIdentityAggregate: NoteIdentityAggregate;
  /** fetch data from the table: "note_identity" using primary key columns */
  noteIdentityByPk?: Maybe<NoteIdentity>;
  /** fetch data from the table: "open_data_city" */
  openDataCity: Array<OpenDataCity>;
  /** fetch aggregated fields from the table: "open_data_city" */
  openDataCityAggregate: OpenDataCityAggregate;
  /** fetch data from the table: "open_data_city" using primary key columns */
  openDataCityByPk?: Maybe<OpenDataCity>;
  /** fetch data from the table: "open_data_department" */
  openDataDepartment: Array<OpenDataDepartment>;
  /** fetch aggregated fields from the table: "open_data_department" */
  openDataDepartmentAggregate: OpenDataDepartmentAggregate;
  /** fetch data from the table: "open_data_department" using primary key columns */
  openDataDepartmentByPk?: Maybe<OpenDataDepartment>;
  /** fetch data from the table: "open_data_region" */
  openDataRegion: Array<OpenDataRegion>;
  /** fetch aggregated fields from the table: "open_data_region" */
  openDataRegionAggregate: OpenDataRegionAggregate;
  /** fetch data from the table: "open_data_region" using primary key columns */
  openDataRegionByPk?: Maybe<OpenDataRegion>;
  /** fetch data from the table: "private_individual" */
  privateIndividual: Array<PrivateIndividual>;
  /** fetch aggregated fields from the table: "private_individual" */
  privateIndividualAggregate: PrivateIndividualAggregate;
  /** fetch data from the table: "pro_certification" */
  proCertification: Array<ProCertification>;
  /** fetch aggregated fields from the table: "pro_certification" */
  proCertificationAggregate: ProCertificationAggregate;
  /** fetch data from the table: "pro_certification" using primary key columns */
  proCertificationByPk?: Maybe<ProCertification>;
  /** fetch data from the table: "pro_holiday" */
  proHoliday: Array<ProHoliday>;
  /** fetch aggregated fields from the table: "pro_holiday" */
  proHolidayAggregate: ProHolidayAggregate;
  /** fetch data from the table: "pro_holiday" using primary key columns */
  proHolidayByPk?: Maybe<ProHoliday>;
  /** fetch data from the table: "pro_intervention" */
  proIntervention: Array<ProIntervention>;
  /** fetch aggregated fields from the table: "pro_intervention" */
  proInterventionAggregate: ProInterventionAggregate;
  /** fetch data from the table: "pro_intervention" using primary key columns */
  proInterventionByPk?: Maybe<ProIntervention>;
  /** fetch data from the table: "pro_label" */
  proLabel: Array<ProLabel>;
  /** fetch aggregated fields from the table: "pro_label" */
  proLabelAggregate: ProLabelAggregate;
  /** fetch data from the table: "pro_label" using primary key columns */
  proLabelByPk?: Maybe<ProLabel>;
  /** fetch data from the table: "pro_mandate" */
  proMandate: Array<ProMandate>;
  /** fetch aggregated fields from the table: "pro_mandate" */
  proMandateAggregate: ProMandateAggregate;
  /** fetch data from the table: "pro_mandate" using primary key columns */
  proMandateByPk?: Maybe<ProMandate>;
  /** fetch data from the table: "pro_media_object" */
  proMediaObject: Array<ProMediaObject>;
  /** fetch aggregated fields from the table: "pro_media_object" */
  proMediaObjectAggregate: ProMediaObjectAggregate;
  /** fetch data from the table: "pro_media_object" using primary key columns */
  proMediaObjectByPk?: Maybe<ProMediaObject>;
  /** fetch data from the table: "pro_message" */
  proMessage: Array<ProMessage>;
  /** fetch aggregated fields from the table: "pro_message" */
  proMessageAggregate: ProMessageAggregate;
  /** fetch data from the table: "pro_message" using primary key columns */
  proMessageByPk?: Maybe<ProMessage>;
  /** fetch data from the table: "pro_organization" */
  proOrganization: Array<ProOrganization>;
  /** fetch aggregated fields from the table: "pro_organization" */
  proOrganizationAggregate: ProOrganizationAggregate;
  /** fetch data from the table: "pro_organization" using primary key columns */
  proOrganizationByPk?: Maybe<ProOrganization>;
  /** fetch data from the table: "pro_organization_first_date_published" */
  proOrganizationFirstDatePublished: Array<ProOrganizationFirstDatePublished>;
  /** fetch aggregated fields from the table: "pro_organization_first_date_published" */
  proOrganizationFirstDatePublishedAggregate: ProOrganizationFirstDatePublishedAggregate;
  /** fetch data from the table: "pro_person" */
  proPerson: Array<ProPerson>;
  /** fetch aggregated fields from the table: "pro_person" */
  proPersonAggregate: ProPersonAggregate;
  /** fetch data from the table: "pro_person" using primary key columns */
  proPersonByPk?: Maybe<ProPerson>;
  /** fetch data from the table: "pro_postal_address" */
  proPostalAddress: Array<ProPostalAddress>;
  /** fetch aggregated fields from the table: "pro_postal_address" */
  proPostalAddressAggregate: ProPostalAddressAggregate;
  /** fetch data from the table: "pro_postal_address" using primary key columns */
  proPostalAddressByPk?: Maybe<ProPostalAddress>;
  /** fetch data from the table: "pro_presentation" */
  proPresentation: Array<ProPresentation>;
  /** fetch aggregated fields from the table: "pro_presentation" */
  proPresentationAggregate: ProPresentationAggregate;
  /** fetch data from the table: "pro_presentation" using primary key columns */
  proPresentationByPk?: Maybe<ProPresentation>;
  /** fetch data from the table: "pro_price" */
  proPrice: Array<ProPrice>;
  /** fetch aggregated fields from the table: "pro_price" */
  proPriceAggregate: ProPriceAggregate;
  /** fetch data from the table: "pro_price" using primary key columns */
  proPriceByPk?: Maybe<ProPrice>;
  /** fetch data from the table: "pro_review" */
  proReview: Array<ProReview>;
  /** fetch aggregated fields from the table: "pro_review" */
  proReviewAggregate: ProReviewAggregate;
  /** fetch data from the table: "pro_review" using primary key columns */
  proReviewByPk?: Maybe<ProReview>;
  /** fetch data from the table: "pro_review_invitation" */
  proReviewInvitation: Array<ProReviewInvitation>;
  /** fetch aggregated fields from the table: "pro_review_invitation" */
  proReviewInvitationAggregate: ProReviewInvitationAggregate;
  /** fetch data from the table: "pro_review_invitation" using primary key columns */
  proReviewInvitationByPk?: Maybe<ProReviewInvitation>;
  /** fetch data from the table: "pro_view" */
  proView: Array<ProView>;
  /** fetch aggregated fields from the table: "pro_view" */
  proViewAggregate: ProViewAggregate;
  /** fetch data from the table: "pro_view" using primary key columns */
  proViewByPk?: Maybe<ProView>;
  /** fetch data from the table: "pro_view_city_distance" */
  proViewCityDistance: Array<ProViewCityDistance>;
  /** fetch aggregated fields from the table: "pro_view_city_distance" */
  proViewCityDistanceAggregate: ProViewCityDistanceAggregate;
  /** fetch data from the table: "pro_view_pro_person" */
  proViewProPerson: Array<ProViewProPerson>;
  /** fetch aggregated fields from the table: "pro_view_pro_person" */
  proViewProPersonAggregate: ProViewProPersonAggregate;
  /** fetch data from the table: "pro_view_pro_person" using primary key columns */
  proViewProPersonByPk?: Maybe<ProViewProPerson>;
  /** fetch data from the table: "pro_view_pro_view" */
  proViewProView: Array<ProViewProView>;
  /** fetch aggregated fields from the table: "pro_view_pro_view" */
  proViewProViewAggregate: ProViewProViewAggregate;
  /** fetch data from the table: "pro_view_pro_view" using primary key columns */
  proViewProViewByPk?: Maybe<ProViewProView>;
  /** fetch data from the table: "queued_sms" */
  queuedSms: Array<QueuedSms>;
  /** An aggregate relationship */
  queuedSmsAggregate: QueuedSmsAggregate;
  /** fetch data from the table: "queued_sms" using primary key columns */
  queuedSmsByPk?: Maybe<QueuedSms>;
  /** execute function "random_pro_views" which returns "pro_view" */
  randomProViews: Array<ProView>;
  /** execute function "random_pro_views" and query aggregates on result of table type "pro_view" */
  randomProViewsAggregate: ProViewAggregate;
  /** execute function "search_account" which returns "account" */
  searchAccount: Array<Account>;
  /** execute function "search_account" and query aggregates on result of table type "account" */
  searchAccountAggregate: AccountAggregate;
  /** execute function "search_nearest_city" which returns "city" */
  searchNearestCity: Array<City>;
  /** execute function "search_nearest_city" and query aggregates on result of table type "city" */
  searchNearestCityAggregate: CityAggregate;
  /** execute function "search_nearest_pro_review" which returns "pro_review" */
  searchNearestProReview: Array<ProReview>;
  /** execute function "search_nearest_pro_review" and query aggregates on result of table type "pro_review" */
  searchNearestProReviewAggregate: ProReviewAggregate;
  /** execute function "search_nearest_pro_view" which returns "pro_view" */
  searchNearestProView: Array<ProView>;
  /** execute function "search_nearest_pro_view" and query aggregates on result of table type "pro_view" */
  searchNearestProViewAggregate: ProViewAggregate;
  /** execute function "search_pro_person" which returns "pro_person" */
  searchProPerson: Array<ProPerson>;
  /** execute function "search_pro_person" and query aggregates on result of table type "pro_person" */
  searchProPersonAggregate: ProPersonAggregate;
  /** execute function "search_pro_presentation" which returns "pro_presentation" */
  searchProPresentation: Array<ProPresentation>;
  /** execute function "search_pro_presentation" and query aggregates on result of table type "pro_presentation" */
  searchProPresentationAggregate: ProPresentationAggregate;
  /** execute function "search_pro_review" which returns "pro_review" */
  searchProReview: Array<ProReview>;
  /** execute function "search_pro_review" and query aggregates on result of table type "pro_review" */
  searchProReviewAggregate: ProReviewAggregate;
  /** execute function "search_pro_review_invitation" which returns "pro_review_invitation" */
  searchProReviewInvitation: Array<ProReviewInvitation>;
  /** execute function "search_pro_review_invitation" and query aggregates on result of table type "pro_review_invitation" */
  searchProReviewInvitationAggregate: ProReviewInvitationAggregate;
  /** execute function "search_pro_view" which returns "pro_view" */
  searchProView: Array<ProView>;
  /** execute function "search_pro_view" and query aggregates on result of table type "pro_view" */
  searchProViewAggregate: ProViewAggregate;
  /** execute function "search_solicitation" which returns "solicitation" */
  searchSolicitation: Array<Solicitation>;
  /** execute function "search_solicitation" and query aggregates on result of table type "solicitation" */
  searchSolicitationAggregate: SolicitationAggregate;
  /** execute function "search_trade" which returns "trade" */
  searchTrade: Array<Trade>;
  /** execute function "search_trade" and query aggregates on result of table type "trade" */
  searchTradeAggregate: TradeAggregate;
  /** execute function "search_trade_group" which returns "trade_group" */
  searchTradeGroup: Array<TradeGroup>;
  /** execute function "search_trade_group" and query aggregates on result of table type "trade_group" */
  searchTradeGroupAggregate: TradeGroupAggregate;
  /** fetch data from the table: "sitemap_bilik_zone" */
  sitemapBilikZone: Array<SitemapBilikZone>;
  /** fetch aggregated fields from the table: "sitemap_bilik_zone" */
  sitemapBilikZoneAggregate: SitemapBilikZoneAggregate;
  /** fetch data from the table: "sitemap_city" */
  sitemapCity: Array<SitemapCity>;
  /** fetch aggregated fields from the table: "sitemap_city" */
  sitemapCityAggregate: SitemapCityAggregate;
  /** fetch data from the table: "slug_redirect" */
  slugRedirect: Array<SlugRedirect>;
  /** fetch aggregated fields from the table: "slug_redirect" */
  slugRedirectAggregate: SlugRedirectAggregate;
  /** fetch data from the table: "slug_redirect" using primary key columns */
  slugRedirectByPk?: Maybe<SlugRedirect>;
  /** fetch data from the table: "solicitation" */
  solicitation: Array<Solicitation>;
  /** fetch aggregated fields from the table: "solicitation" */
  solicitationAggregate: SolicitationAggregate;
  /** fetch data from the table: "solicitation" using primary key columns */
  solicitationByPk?: Maybe<Solicitation>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatialRefSys: Array<SpatialRefSys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatialRefSysAggregate: SpatialRefSysAggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatialRefSysByPk?: Maybe<SpatialRefSys>;
  /** fetch data from the table: "stop_sms" */
  stopSms: Array<StopSms>;
  /** fetch aggregated fields from the table: "stop_sms" */
  stopSmsAggregate: StopSmsAggregate;
  /** fetch data from the table: "stop_sms" using primary key columns */
  stopSmsByPk?: Maybe<StopSms>;
  /** fetch data from the table: "timeline_private_individual" */
  timelinePrivateIndividual: Array<TimelinePrivateIndividual>;
  /** fetch aggregated fields from the table: "timeline_private_individual" */
  timelinePrivateIndividualAggregate: TimelinePrivateIndividualAggregate;
  /** fetch data from the table: "topology.layer" */
  topologyLayer: Array<TopologyLayer>;
  /** fetch aggregated fields from the table: "topology.layer" */
  topologyLayerAggregate: TopologyLayerAggregate;
  /** fetch data from the table: "topology.layer" using primary key columns */
  topologyLayerByPk?: Maybe<TopologyLayer>;
  /** fetch data from the table: "topology.topology" */
  topologyTopology: Array<TopologyTopology>;
  /** fetch aggregated fields from the table: "topology.topology" */
  topologyTopologyAggregate: TopologyTopologyAggregate;
  /** fetch data from the table: "topology.topology" using primary key columns */
  topologyTopologyByPk?: Maybe<TopologyTopology>;
  /** fetch data from the table: "trade" */
  trade: Array<Trade>;
  /** fetch aggregated fields from the table: "trade" */
  tradeAggregate: TradeAggregate;
  /** fetch data from the table: "trade" using primary key columns */
  tradeByPk?: Maybe<Trade>;
  /** fetch data from the table: "trade_group" */
  tradeGroup: Array<TradeGroup>;
  /** fetch aggregated fields from the table: "trade_group" */
  tradeGroupAggregate: TradeGroupAggregate;
  /** fetch data from the table: "trade_group" using primary key columns */
  tradeGroupByPk?: Maybe<TradeGroup>;
  /** fetch data from the table: "trade_guide" */
  tradeGuide: Array<TradeGuide>;
  /** fetch aggregated fields from the table: "trade_guide" */
  tradeGuideAggregate: TradeGuideAggregate;
  /** fetch data from the table: "trade_guide" using primary key columns */
  tradeGuideByPk?: Maybe<TradeGuide>;
  /** fetch data from the table: "trade_keyword" */
  tradeKeyword: Array<TradeKeyword>;
  /** fetch aggregated fields from the table: "trade_keyword" */
  tradeKeywordAggregate: TradeKeywordAggregate;
  /** fetch data from the table: "trade_keyword" using primary key columns */
  tradeKeywordByPk?: Maybe<TradeKeyword>;
  /** fetch data from the table: "trade_main_pro_count" */
  tradeMainProCount: Array<TradeMainProCount>;
  /** fetch aggregated fields from the table: "trade_main_pro_count" */
  tradeMainProCountAggregate: TradeMainProCountAggregate;
  /** fetch data from the table: "trade_pro_view" */
  tradeProView: Array<TradeProView>;
  /** fetch aggregated fields from the table: "trade_pro_view" */
  tradeProViewAggregate: TradeProViewAggregate;
  /** fetch data from the table: "trade_pro_view" using primary key columns */
  tradeProViewByPk?: Maybe<TradeProView>;
  /** fetch data from the table: "trade_question" */
  tradeQuestion: Array<TradeQuestion>;
  /** fetch aggregated fields from the table: "trade_question" */
  tradeQuestionAggregate: TradeQuestionAggregate;
  /** fetch data from the table: "trade_question" using primary key columns */
  tradeQuestionByPk?: Maybe<TradeQuestion>;
  /** fetch data from the table: "trade_trade_group" */
  tradeTradeGroup: Array<TradeTradeGroup>;
  /** fetch aggregated fields from the table: "trade_trade_group" */
  tradeTradeGroupAggregate: TradeTradeGroupAggregate;
  /** fetch data from the table: "trade_trade_group" using primary key columns */
  tradeTradeGroupByPk?: Maybe<TradeTradeGroup>;
  /** fetch data from the table: "zoho_consumer" */
  zohoConsumer: Array<ZohoConsumer>;
  /** fetch aggregated fields from the table: "zoho_consumer" */
  zohoConsumerAggregate: ZohoConsumerAggregate;
  /** fetch data from the table: "zoho_consumer" using primary key columns */
  zohoConsumerByPk?: Maybe<ZohoConsumer>;
  /** fetch data from the table: "zoho_organization" */
  zohoOrganization: Array<ZohoOrganization>;
  /** fetch aggregated fields from the table: "zoho_organization" */
  zohoOrganizationAggregate: ZohoOrganizationAggregate;
  /** fetch data from the table: "zoho_organization" using primary key columns */
  zohoOrganizationByPk?: Maybe<ZohoOrganization>;
  /** fetch data from the table: "zoho_token" */
  zohoToken: Array<ZohoToken>;
  /** fetch aggregated fields from the table: "zoho_token" */
  zohoTokenAggregate: ZohoTokenAggregate;
  /** fetch data from the table: "zoho_token" using primary key columns */
  zohoTokenByPk?: Maybe<ZohoToken>;
};


export type QueryRootAccountArgs = {
  distinctOn?: Maybe<Array<AccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountOrderBy>>;
  where?: Maybe<AccountBoolExp>;
};


export type QueryRootAccountAggregateArgs = {
  distinctOn?: Maybe<Array<AccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountOrderBy>>;
  where?: Maybe<AccountBoolExp>;
};


export type QueryRootAccountByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootAccountDeviceArgs = {
  distinctOn?: Maybe<Array<AccountDeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountDeviceOrderBy>>;
  where?: Maybe<AccountDeviceBoolExp>;
};


export type QueryRootAccountDeviceAggregateArgs = {
  distinctOn?: Maybe<Array<AccountDeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountDeviceOrderBy>>;
  where?: Maybe<AccountDeviceBoolExp>;
};


export type QueryRootAccountDeviceByPkArgs = {
  accountId: Scalars['Int'];
  deviceId: Scalars['Int'];
};


export type QueryRootAccountProViewArgs = {
  distinctOn?: Maybe<Array<AccountProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountProViewOrderBy>>;
  where?: Maybe<AccountProViewBoolExp>;
};


export type QueryRootAccountProViewAggregateArgs = {
  distinctOn?: Maybe<Array<AccountProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountProViewOrderBy>>;
  where?: Maybe<AccountProViewBoolExp>;
};


export type QueryRootAccountProViewByPkArgs = {
  accountId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


export type QueryRootActionTokenArgs = {
  distinctOn?: Maybe<Array<ActionTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ActionTokenOrderBy>>;
  where?: Maybe<ActionTokenBoolExp>;
};


export type QueryRootActionTokenAggregateArgs = {
  distinctOn?: Maybe<Array<ActionTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ActionTokenOrderBy>>;
  where?: Maybe<ActionTokenBoolExp>;
};


export type QueryRootActionTokenByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootAutorespondArgs = {
  distinctOn?: Maybe<Array<AutorespondSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AutorespondOrderBy>>;
  where?: Maybe<AutorespondBoolExp>;
};


export type QueryRootAutorespondAggregateArgs = {
  distinctOn?: Maybe<Array<AutorespondSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AutorespondOrderBy>>;
  where?: Maybe<AutorespondBoolExp>;
};


export type QueryRootAutorespondByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootBilikPersonArgs = {
  distinctOn?: Maybe<Array<BilikPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikPersonOrderBy>>;
  where?: Maybe<BilikPersonBoolExp>;
};


export type QueryRootBilikPersonAggregateArgs = {
  distinctOn?: Maybe<Array<BilikPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikPersonOrderBy>>;
  where?: Maybe<BilikPersonBoolExp>;
};


export type QueryRootBilikPersonByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootBilikSolicitationArgs = {
  distinctOn?: Maybe<Array<BilikSolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikSolicitationOrderBy>>;
  where?: Maybe<BilikSolicitationBoolExp>;
};


export type QueryRootBilikSolicitationAggregateArgs = {
  distinctOn?: Maybe<Array<BilikSolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikSolicitationOrderBy>>;
  where?: Maybe<BilikSolicitationBoolExp>;
};


export type QueryRootBilikSolicitationByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootBilikZoneArgs = {
  distinctOn?: Maybe<Array<BilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneOrderBy>>;
  where?: Maybe<BilikZoneBoolExp>;
};


export type QueryRootBilikZoneAggregateArgs = {
  distinctOn?: Maybe<Array<BilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneOrderBy>>;
  where?: Maybe<BilikZoneBoolExp>;
};


export type QueryRootBilikZoneByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootBilikZoneTradeMainProCountArgs = {
  distinctOn?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneTradeMainProCountOrderBy>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};


export type QueryRootBilikZoneTradeMainProCountAggregateArgs = {
  distinctOn?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneTradeMainProCountOrderBy>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};


export type QueryRootCertificationArgs = {
  distinctOn?: Maybe<Array<CertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CertificationOrderBy>>;
  where?: Maybe<CertificationBoolExp>;
};


export type QueryRootCertificationAggregateArgs = {
  distinctOn?: Maybe<Array<CertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CertificationOrderBy>>;
  where?: Maybe<CertificationBoolExp>;
};


export type QueryRootCertificationByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootCityArgs = {
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


export type QueryRootCityAggregateArgs = {
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


export type QueryRootCityByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootCityProViewArgs = {
  distinctOn?: Maybe<Array<CityProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityProViewOrderBy>>;
  where?: Maybe<CityProViewBoolExp>;
};


export type QueryRootCityProViewAggregateArgs = {
  distinctOn?: Maybe<Array<CityProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityProViewOrderBy>>;
  where?: Maybe<CityProViewBoolExp>;
};


export type QueryRootCityProViewByPkArgs = {
  cityId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


export type QueryRootCountProReviewByStatusArgs = {
  distinctOn?: Maybe<Array<CountProReviewByStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewByStatusOrderBy>>;
  where?: Maybe<CountProReviewByStatusBoolExp>;
};


export type QueryRootCountProReviewByStatusAggregateArgs = {
  distinctOn?: Maybe<Array<CountProReviewByStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewByStatusOrderBy>>;
  where?: Maybe<CountProReviewByStatusBoolExp>;
};


export type QueryRootCountProReviewInvitationsByMonthByProPresentationArgs = {
  distinctOn?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationOrderBy>>;
  where?: Maybe<CountProReviewInvitationsByMonthByProPresentationBoolExp>;
};


export type QueryRootCountProReviewInvitationsByMonthByProPresentationAggregateArgs = {
  distinctOn?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationOrderBy>>;
  where?: Maybe<CountProReviewInvitationsByMonthByProPresentationBoolExp>;
};


export type QueryRootCountProReviewsWithStatusOkByMonthByProPresentationArgs = {
  distinctOn?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationOrderBy>>;
  where?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationBoolExp>;
};


export type QueryRootCountProReviewsWithStatusOkByMonthByProPresentationAggregateArgs = {
  distinctOn?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationOrderBy>>;
  where?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationBoolExp>;
};


export type QueryRootDeviceArgs = {
  distinctOn?: Maybe<Array<DeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DeviceOrderBy>>;
  where?: Maybe<DeviceBoolExp>;
};


export type QueryRootDeviceAggregateArgs = {
  distinctOn?: Maybe<Array<DeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DeviceOrderBy>>;
  where?: Maybe<DeviceBoolExp>;
};


export type QueryRootDeviceByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootGeographyColumnsArgs = {
  distinctOn?: Maybe<Array<GeographyColumnsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GeographyColumnsOrderBy>>;
  where?: Maybe<GeographyColumnsBoolExp>;
};


export type QueryRootGeographyColumnsAggregateArgs = {
  distinctOn?: Maybe<Array<GeographyColumnsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GeographyColumnsOrderBy>>;
  where?: Maybe<GeographyColumnsBoolExp>;
};


export type QueryRootGeometryColumnsArgs = {
  distinctOn?: Maybe<Array<GeometryColumnsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GeometryColumnsOrderBy>>;
  where?: Maybe<GeometryColumnsBoolExp>;
};


export type QueryRootGeometryColumnsAggregateArgs = {
  distinctOn?: Maybe<Array<GeometryColumnsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GeometryColumnsOrderBy>>;
  where?: Maybe<GeometryColumnsBoolExp>;
};


export type QueryRootGloballyBlockedIdentityArgs = {
  distinctOn?: Maybe<Array<GloballyBlockedIdentitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GloballyBlockedIdentityOrderBy>>;
  where?: Maybe<GloballyBlockedIdentityBoolExp>;
};


export type QueryRootGloballyBlockedIdentityAggregateArgs = {
  distinctOn?: Maybe<Array<GloballyBlockedIdentitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GloballyBlockedIdentityOrderBy>>;
  where?: Maybe<GloballyBlockedIdentityBoolExp>;
};


export type QueryRootGloballyBlockedIdentityByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootGoogleReviewLinkArgs = {
  distinctOn?: Maybe<Array<GoogleReviewLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GoogleReviewLinkOrderBy>>;
  where?: Maybe<GoogleReviewLinkBoolExp>;
};


export type QueryRootGoogleReviewLinkAggregateArgs = {
  distinctOn?: Maybe<Array<GoogleReviewLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GoogleReviewLinkOrderBy>>;
  where?: Maybe<GoogleReviewLinkBoolExp>;
};


export type QueryRootGoogleReviewLinkByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootGroupedProInterventionArgs = {
  distinctOn?: Maybe<Array<GroupedProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GroupedProInterventionOrderBy>>;
  where?: Maybe<GroupedProInterventionBoolExp>;
};


export type QueryRootGroupedProInterventionAggregateArgs = {
  distinctOn?: Maybe<Array<GroupedProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GroupedProInterventionOrderBy>>;
  where?: Maybe<GroupedProInterventionBoolExp>;
};


export type QueryRootManagerBilikPersonBilikZoneArgs = {
  distinctOn?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ManagerBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


export type QueryRootManagerBilikPersonBilikZoneAggregateArgs = {
  distinctOn?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ManagerBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


export type QueryRootManagerBilikPersonBilikZoneByPkArgs = {
  bilikPersonId: Scalars['Int'];
  bilikZoneId: Scalars['Int'];
};


export type QueryRootMemberBilikPersonBilikZoneArgs = {
  distinctOn?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MemberBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


export type QueryRootMemberBilikPersonBilikZoneAggregateArgs = {
  distinctOn?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MemberBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


export type QueryRootMemberBilikPersonBilikZoneByPkArgs = {
  bilikPersonId: Scalars['Int'];
  bilikZoneId: Scalars['Int'];
};


export type QueryRootNoteIdentityArgs = {
  distinctOn?: Maybe<Array<NoteIdentitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<NoteIdentityOrderBy>>;
  where?: Maybe<NoteIdentityBoolExp>;
};


export type QueryRootNoteIdentityAggregateArgs = {
  distinctOn?: Maybe<Array<NoteIdentitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<NoteIdentityOrderBy>>;
  where?: Maybe<NoteIdentityBoolExp>;
};


export type QueryRootNoteIdentityByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootOpenDataCityArgs = {
  distinctOn?: Maybe<Array<OpenDataCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataCityOrderBy>>;
  where?: Maybe<OpenDataCityBoolExp>;
};


export type QueryRootOpenDataCityAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataCityOrderBy>>;
  where?: Maybe<OpenDataCityBoolExp>;
};


export type QueryRootOpenDataCityByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootOpenDataDepartmentArgs = {
  distinctOn?: Maybe<Array<OpenDataDepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataDepartmentOrderBy>>;
  where?: Maybe<OpenDataDepartmentBoolExp>;
};


export type QueryRootOpenDataDepartmentAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataDepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataDepartmentOrderBy>>;
  where?: Maybe<OpenDataDepartmentBoolExp>;
};


export type QueryRootOpenDataDepartmentByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootOpenDataRegionArgs = {
  distinctOn?: Maybe<Array<OpenDataRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataRegionOrderBy>>;
  where?: Maybe<OpenDataRegionBoolExp>;
};


export type QueryRootOpenDataRegionAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataRegionOrderBy>>;
  where?: Maybe<OpenDataRegionBoolExp>;
};


export type QueryRootOpenDataRegionByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootPrivateIndividualArgs = {
  distinctOn?: Maybe<Array<PrivateIndividualSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PrivateIndividualOrderBy>>;
  where?: Maybe<PrivateIndividualBoolExp>;
};


export type QueryRootPrivateIndividualAggregateArgs = {
  distinctOn?: Maybe<Array<PrivateIndividualSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PrivateIndividualOrderBy>>;
  where?: Maybe<PrivateIndividualBoolExp>;
};


export type QueryRootProCertificationArgs = {
  distinctOn?: Maybe<Array<ProCertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProCertificationOrderBy>>;
  where?: Maybe<ProCertificationBoolExp>;
};


export type QueryRootProCertificationAggregateArgs = {
  distinctOn?: Maybe<Array<ProCertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProCertificationOrderBy>>;
  where?: Maybe<ProCertificationBoolExp>;
};


export type QueryRootProCertificationByPkArgs = {
  certificationId: Scalars['Int'];
  proPresentationId: Scalars['Int'];
};


export type QueryRootProHolidayArgs = {
  distinctOn?: Maybe<Array<ProHolidaySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProHolidayOrderBy>>;
  where?: Maybe<ProHolidayBoolExp>;
};


export type QueryRootProHolidayAggregateArgs = {
  distinctOn?: Maybe<Array<ProHolidaySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProHolidayOrderBy>>;
  where?: Maybe<ProHolidayBoolExp>;
};


export type QueryRootProHolidayByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProInterventionArgs = {
  distinctOn?: Maybe<Array<ProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProInterventionOrderBy>>;
  where?: Maybe<ProInterventionBoolExp>;
};


export type QueryRootProInterventionAggregateArgs = {
  distinctOn?: Maybe<Array<ProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProInterventionOrderBy>>;
  where?: Maybe<ProInterventionBoolExp>;
};


export type QueryRootProInterventionByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProLabelArgs = {
  distinctOn?: Maybe<Array<ProLabelSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProLabelOrderBy>>;
  where?: Maybe<ProLabelBoolExp>;
};


export type QueryRootProLabelAggregateArgs = {
  distinctOn?: Maybe<Array<ProLabelSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProLabelOrderBy>>;
  where?: Maybe<ProLabelBoolExp>;
};


export type QueryRootProLabelByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProMandateArgs = {
  distinctOn?: Maybe<Array<ProMandateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMandateOrderBy>>;
  where?: Maybe<ProMandateBoolExp>;
};


export type QueryRootProMandateAggregateArgs = {
  distinctOn?: Maybe<Array<ProMandateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMandateOrderBy>>;
  where?: Maybe<ProMandateBoolExp>;
};


export type QueryRootProMandateByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProMediaObjectArgs = {
  distinctOn?: Maybe<Array<ProMediaObjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMediaObjectOrderBy>>;
  where?: Maybe<ProMediaObjectBoolExp>;
};


export type QueryRootProMediaObjectAggregateArgs = {
  distinctOn?: Maybe<Array<ProMediaObjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMediaObjectOrderBy>>;
  where?: Maybe<ProMediaObjectBoolExp>;
};


export type QueryRootProMediaObjectByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProMessageArgs = {
  distinctOn?: Maybe<Array<ProMessageSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMessageOrderBy>>;
  where?: Maybe<ProMessageBoolExp>;
};


export type QueryRootProMessageAggregateArgs = {
  distinctOn?: Maybe<Array<ProMessageSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMessageOrderBy>>;
  where?: Maybe<ProMessageBoolExp>;
};


export type QueryRootProMessageByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProOrganizationArgs = {
  distinctOn?: Maybe<Array<ProOrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProOrganizationOrderBy>>;
  where?: Maybe<ProOrganizationBoolExp>;
};


export type QueryRootProOrganizationAggregateArgs = {
  distinctOn?: Maybe<Array<ProOrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProOrganizationOrderBy>>;
  where?: Maybe<ProOrganizationBoolExp>;
};


export type QueryRootProOrganizationByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProOrganizationFirstDatePublishedArgs = {
  distinctOn?: Maybe<Array<ProOrganizationFirstDatePublishedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProOrganizationFirstDatePublishedOrderBy>>;
  where?: Maybe<ProOrganizationFirstDatePublishedBoolExp>;
};


export type QueryRootProOrganizationFirstDatePublishedAggregateArgs = {
  distinctOn?: Maybe<Array<ProOrganizationFirstDatePublishedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProOrganizationFirstDatePublishedOrderBy>>;
  where?: Maybe<ProOrganizationFirstDatePublishedBoolExp>;
};


export type QueryRootProPersonArgs = {
  distinctOn?: Maybe<Array<ProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPersonOrderBy>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type QueryRootProPersonAggregateArgs = {
  distinctOn?: Maybe<Array<ProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPersonOrderBy>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type QueryRootProPersonByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProPostalAddressArgs = {
  distinctOn?: Maybe<Array<ProPostalAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPostalAddressOrderBy>>;
  where?: Maybe<ProPostalAddressBoolExp>;
};


export type QueryRootProPostalAddressAggregateArgs = {
  distinctOn?: Maybe<Array<ProPostalAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPostalAddressOrderBy>>;
  where?: Maybe<ProPostalAddressBoolExp>;
};


export type QueryRootProPostalAddressByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProPresentationArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type QueryRootProPresentationAggregateArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type QueryRootProPresentationByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProPriceArgs = {
  distinctOn?: Maybe<Array<ProPriceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPriceOrderBy>>;
  where?: Maybe<ProPriceBoolExp>;
};


export type QueryRootProPriceAggregateArgs = {
  distinctOn?: Maybe<Array<ProPriceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPriceOrderBy>>;
  where?: Maybe<ProPriceBoolExp>;
};


export type QueryRootProPriceByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProReviewArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type QueryRootProReviewAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type QueryRootProReviewByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProReviewInvitationArgs = {
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type QueryRootProReviewInvitationAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type QueryRootProReviewInvitationByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProViewArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type QueryRootProViewAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type QueryRootProViewByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootProViewCityDistanceArgs = {
  distinctOn?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewCityDistanceOrderBy>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


export type QueryRootProViewCityDistanceAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewCityDistanceOrderBy>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


export type QueryRootProViewProPersonArgs = {
  distinctOn?: Maybe<Array<ProViewProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProPersonOrderBy>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};


export type QueryRootProViewProPersonAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProPersonOrderBy>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};


export type QueryRootProViewProPersonByPkArgs = {
  proPersonId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


export type QueryRootProViewProViewArgs = {
  distinctOn?: Maybe<Array<ProViewProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProViewOrderBy>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


export type QueryRootProViewProViewAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProViewOrderBy>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


export type QueryRootProViewProViewByPkArgs = {
  proViewSource: Scalars['Int'];
  proViewTarget: Scalars['Int'];
};


export type QueryRootQueuedSmsArgs = {
  distinctOn?: Maybe<Array<QueuedSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueuedSmsOrderBy>>;
  where?: Maybe<QueuedSmsBoolExp>;
};


export type QueryRootQueuedSmsAggregateArgs = {
  distinctOn?: Maybe<Array<QueuedSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueuedSmsOrderBy>>;
  where?: Maybe<QueuedSmsBoolExp>;
};


export type QueryRootQueuedSmsByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootRandomProViewsArgs = {
  args?: Maybe<RandomProViewsArgs>;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type QueryRootRandomProViewsAggregateArgs = {
  args?: Maybe<RandomProViewsArgs>;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type QueryRootSearchAccountArgs = {
  args: SearchAccountArgs;
  distinctOn?: Maybe<Array<AccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountOrderBy>>;
  where?: Maybe<AccountBoolExp>;
};


export type QueryRootSearchAccountAggregateArgs = {
  args: SearchAccountArgs;
  distinctOn?: Maybe<Array<AccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountOrderBy>>;
  where?: Maybe<AccountBoolExp>;
};


export type QueryRootSearchNearestCityArgs = {
  args: SearchNearestCityArgs;
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


export type QueryRootSearchNearestCityAggregateArgs = {
  args: SearchNearestCityArgs;
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


export type QueryRootSearchNearestProReviewArgs = {
  args: SearchNearestProReviewArgs;
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type QueryRootSearchNearestProReviewAggregateArgs = {
  args: SearchNearestProReviewArgs;
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type QueryRootSearchNearestProViewArgs = {
  args: SearchNearestProViewArgs;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type QueryRootSearchNearestProViewAggregateArgs = {
  args: SearchNearestProViewArgs;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type QueryRootSearchProPersonArgs = {
  args: SearchProPersonArgs;
  distinctOn?: Maybe<Array<ProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPersonOrderBy>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type QueryRootSearchProPersonAggregateArgs = {
  args: SearchProPersonArgs;
  distinctOn?: Maybe<Array<ProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPersonOrderBy>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type QueryRootSearchProPresentationArgs = {
  args: SearchProPresentationArgs;
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type QueryRootSearchProPresentationAggregateArgs = {
  args: SearchProPresentationArgs;
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type QueryRootSearchProReviewArgs = {
  args: SearchProReviewArgs;
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type QueryRootSearchProReviewAggregateArgs = {
  args: SearchProReviewArgs;
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type QueryRootSearchProReviewInvitationArgs = {
  args: SearchProReviewInvitationArgs;
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type QueryRootSearchProReviewInvitationAggregateArgs = {
  args: SearchProReviewInvitationArgs;
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type QueryRootSearchProViewArgs = {
  args: SearchProViewArgs;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type QueryRootSearchProViewAggregateArgs = {
  args: SearchProViewArgs;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type QueryRootSearchSolicitationArgs = {
  args: SearchSolicitationArgs;
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type QueryRootSearchSolicitationAggregateArgs = {
  args: SearchSolicitationArgs;
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type QueryRootSearchTradeArgs = {
  args: SearchTradeArgs;
  distinctOn?: Maybe<Array<TradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeOrderBy>>;
  where?: Maybe<TradeBoolExp>;
};


export type QueryRootSearchTradeAggregateArgs = {
  args: SearchTradeArgs;
  distinctOn?: Maybe<Array<TradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeOrderBy>>;
  where?: Maybe<TradeBoolExp>;
};


export type QueryRootSearchTradeGroupArgs = {
  args: SearchTradeGroupArgs;
  distinctOn?: Maybe<Array<TradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGroupOrderBy>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type QueryRootSearchTradeGroupAggregateArgs = {
  args: SearchTradeGroupArgs;
  distinctOn?: Maybe<Array<TradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGroupOrderBy>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type QueryRootSitemapBilikZoneArgs = {
  distinctOn?: Maybe<Array<SitemapBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapBilikZoneOrderBy>>;
  where?: Maybe<SitemapBilikZoneBoolExp>;
};


export type QueryRootSitemapBilikZoneAggregateArgs = {
  distinctOn?: Maybe<Array<SitemapBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapBilikZoneOrderBy>>;
  where?: Maybe<SitemapBilikZoneBoolExp>;
};


export type QueryRootSitemapCityArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


export type QueryRootSitemapCityAggregateArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


export type QueryRootSlugRedirectArgs = {
  distinctOn?: Maybe<Array<SlugRedirectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SlugRedirectOrderBy>>;
  where?: Maybe<SlugRedirectBoolExp>;
};


export type QueryRootSlugRedirectAggregateArgs = {
  distinctOn?: Maybe<Array<SlugRedirectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SlugRedirectOrderBy>>;
  where?: Maybe<SlugRedirectBoolExp>;
};


export type QueryRootSlugRedirectByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootSolicitationArgs = {
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type QueryRootSolicitationAggregateArgs = {
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type QueryRootSolicitationByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootSpatialRefSysArgs = {
  distinctOn?: Maybe<Array<SpatialRefSysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SpatialRefSysOrderBy>>;
  where?: Maybe<SpatialRefSysBoolExp>;
};


export type QueryRootSpatialRefSysAggregateArgs = {
  distinctOn?: Maybe<Array<SpatialRefSysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SpatialRefSysOrderBy>>;
  where?: Maybe<SpatialRefSysBoolExp>;
};


export type QueryRootSpatialRefSysByPkArgs = {
  srid: Scalars['Int'];
};


export type QueryRootStopSmsArgs = {
  distinctOn?: Maybe<Array<StopSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<StopSmsOrderBy>>;
  where?: Maybe<StopSmsBoolExp>;
};


export type QueryRootStopSmsAggregateArgs = {
  distinctOn?: Maybe<Array<StopSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<StopSmsOrderBy>>;
  where?: Maybe<StopSmsBoolExp>;
};


export type QueryRootStopSmsByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootTimelinePrivateIndividualArgs = {
  distinctOn?: Maybe<Array<TimelinePrivateIndividualSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TimelinePrivateIndividualOrderBy>>;
  where?: Maybe<TimelinePrivateIndividualBoolExp>;
};


export type QueryRootTimelinePrivateIndividualAggregateArgs = {
  distinctOn?: Maybe<Array<TimelinePrivateIndividualSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TimelinePrivateIndividualOrderBy>>;
  where?: Maybe<TimelinePrivateIndividualBoolExp>;
};


export type QueryRootTopologyLayerArgs = {
  distinctOn?: Maybe<Array<TopologyLayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TopologyLayerOrderBy>>;
  where?: Maybe<TopologyLayerBoolExp>;
};


export type QueryRootTopologyLayerAggregateArgs = {
  distinctOn?: Maybe<Array<TopologyLayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TopologyLayerOrderBy>>;
  where?: Maybe<TopologyLayerBoolExp>;
};


export type QueryRootTopologyLayerByPkArgs = {
  layerId: Scalars['Int'];
  topologyId: Scalars['Int'];
};


export type QueryRootTopologyTopologyArgs = {
  distinctOn?: Maybe<Array<TopologyTopologySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TopologyTopologyOrderBy>>;
  where?: Maybe<TopologyTopologyBoolExp>;
};


export type QueryRootTopologyTopologyAggregateArgs = {
  distinctOn?: Maybe<Array<TopologyTopologySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TopologyTopologyOrderBy>>;
  where?: Maybe<TopologyTopologyBoolExp>;
};


export type QueryRootTopologyTopologyByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootTradeArgs = {
  distinctOn?: Maybe<Array<TradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeOrderBy>>;
  where?: Maybe<TradeBoolExp>;
};


export type QueryRootTradeAggregateArgs = {
  distinctOn?: Maybe<Array<TradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeOrderBy>>;
  where?: Maybe<TradeBoolExp>;
};


export type QueryRootTradeByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootTradeGroupArgs = {
  distinctOn?: Maybe<Array<TradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGroupOrderBy>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type QueryRootTradeGroupAggregateArgs = {
  distinctOn?: Maybe<Array<TradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGroupOrderBy>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type QueryRootTradeGroupByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootTradeGuideArgs = {
  distinctOn?: Maybe<Array<TradeGuideSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGuideOrderBy>>;
  where?: Maybe<TradeGuideBoolExp>;
};


export type QueryRootTradeGuideAggregateArgs = {
  distinctOn?: Maybe<Array<TradeGuideSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGuideOrderBy>>;
  where?: Maybe<TradeGuideBoolExp>;
};


export type QueryRootTradeGuideByPkArgs = {
  firstTradeId: Scalars['Int'];
  secondTradeId: Scalars['Int'];
};


export type QueryRootTradeKeywordArgs = {
  distinctOn?: Maybe<Array<TradeKeywordSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeKeywordOrderBy>>;
  where?: Maybe<TradeKeywordBoolExp>;
};


export type QueryRootTradeKeywordAggregateArgs = {
  distinctOn?: Maybe<Array<TradeKeywordSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeKeywordOrderBy>>;
  where?: Maybe<TradeKeywordBoolExp>;
};


export type QueryRootTradeKeywordByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootTradeMainProCountArgs = {
  distinctOn?: Maybe<Array<TradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeMainProCountOrderBy>>;
  where?: Maybe<TradeMainProCountBoolExp>;
};


export type QueryRootTradeMainProCountAggregateArgs = {
  distinctOn?: Maybe<Array<TradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeMainProCountOrderBy>>;
  where?: Maybe<TradeMainProCountBoolExp>;
};


export type QueryRootTradeProViewArgs = {
  distinctOn?: Maybe<Array<TradeProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeProViewOrderBy>>;
  where?: Maybe<TradeProViewBoolExp>;
};


export type QueryRootTradeProViewAggregateArgs = {
  distinctOn?: Maybe<Array<TradeProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeProViewOrderBy>>;
  where?: Maybe<TradeProViewBoolExp>;
};


export type QueryRootTradeProViewByPkArgs = {
  proViewId: Scalars['Int'];
  tradeId: Scalars['Int'];
};


export type QueryRootTradeQuestionArgs = {
  distinctOn?: Maybe<Array<TradeQuestionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeQuestionOrderBy>>;
  where?: Maybe<TradeQuestionBoolExp>;
};


export type QueryRootTradeQuestionAggregateArgs = {
  distinctOn?: Maybe<Array<TradeQuestionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeQuestionOrderBy>>;
  where?: Maybe<TradeQuestionBoolExp>;
};


export type QueryRootTradeQuestionByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootTradeTradeGroupArgs = {
  distinctOn?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeTradeGroupOrderBy>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};


export type QueryRootTradeTradeGroupAggregateArgs = {
  distinctOn?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeTradeGroupOrderBy>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};


export type QueryRootTradeTradeGroupByPkArgs = {
  tradeGroupId: Scalars['Int'];
  tradeId: Scalars['Int'];
};


export type QueryRootZohoConsumerArgs = {
  distinctOn?: Maybe<Array<ZohoConsumerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoConsumerOrderBy>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};


export type QueryRootZohoConsumerAggregateArgs = {
  distinctOn?: Maybe<Array<ZohoConsumerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoConsumerOrderBy>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};


export type QueryRootZohoConsumerByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootZohoOrganizationArgs = {
  distinctOn?: Maybe<Array<ZohoOrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoOrganizationOrderBy>>;
  where?: Maybe<ZohoOrganizationBoolExp>;
};


export type QueryRootZohoOrganizationAggregateArgs = {
  distinctOn?: Maybe<Array<ZohoOrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoOrganizationOrderBy>>;
  where?: Maybe<ZohoOrganizationBoolExp>;
};


export type QueryRootZohoOrganizationByPkArgs = {
  id: Scalars['Int'];
};


export type QueryRootZohoTokenArgs = {
  distinctOn?: Maybe<Array<ZohoTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoTokenOrderBy>>;
  where?: Maybe<ZohoTokenBoolExp>;
};


export type QueryRootZohoTokenAggregateArgs = {
  distinctOn?: Maybe<Array<ZohoTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoTokenOrderBy>>;
  where?: Maybe<ZohoTokenBoolExp>;
};


export type QueryRootZohoTokenByPkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "queued_sms" */
export type QueuedSms = {
  __typename?: 'QueuedSms';
  id: Scalars['Int'];
  proViewId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  pro_view?: Maybe<ProView>;
  sendDate: Scalars['timestamptz'];
  smsFrom: Scalars['String'];
  smsTo: Scalars['String'];
  status: Scalars['String'];
  text: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

/** aggregated selection of "queued_sms" */
export type QueuedSmsAggregate = {
  __typename?: 'QueuedSmsAggregate';
  aggregate?: Maybe<QueuedSmsAggregateFields>;
  nodes: Array<QueuedSms>;
};

export type QueuedSmsAggregateBoolExp = {
  count?: Maybe<QueuedSmsAggregateBoolExpCount>;
};

export type QueuedSmsAggregateBoolExpCount = {
  arguments?: Maybe<Array<QueuedSmsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<QueuedSmsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "queued_sms" */
export type QueuedSmsAggregateFields = {
  __typename?: 'QueuedSmsAggregateFields';
  avg?: Maybe<QueuedSmsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<QueuedSmsMaxFields>;
  min?: Maybe<QueuedSmsMinFields>;
  stddev?: Maybe<QueuedSmsStddevFields>;
  stddevPop?: Maybe<QueuedSmsStddevPopFields>;
  stddevSamp?: Maybe<QueuedSmsStddevSampFields>;
  sum?: Maybe<QueuedSmsSumFields>;
  varPop?: Maybe<QueuedSmsVarPopFields>;
  varSamp?: Maybe<QueuedSmsVarSampFields>;
  variance?: Maybe<QueuedSmsVarianceFields>;
};


/** aggregate fields of "queued_sms" */
export type QueuedSmsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<QueuedSmsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "queued_sms" */
export type QueuedSmsAggregateOrderBy = {
  avg?: Maybe<QueuedSmsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<QueuedSmsMaxOrderBy>;
  min?: Maybe<QueuedSmsMinOrderBy>;
  stddev?: Maybe<QueuedSmsStddevOrderBy>;
  stddevPop?: Maybe<QueuedSmsStddevPopOrderBy>;
  stddevSamp?: Maybe<QueuedSmsStddevSampOrderBy>;
  sum?: Maybe<QueuedSmsSumOrderBy>;
  varPop?: Maybe<QueuedSmsVarPopOrderBy>;
  varSamp?: Maybe<QueuedSmsVarSampOrderBy>;
  variance?: Maybe<QueuedSmsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "queued_sms" */
export type QueuedSmsArrRelInsertInput = {
  data: Array<QueuedSmsInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<QueuedSmsOnConflict>;
};

/** aggregate avg on columns */
export type QueuedSmsAvgFields = {
  __typename?: 'QueuedSmsAvgFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "queued_sms" */
export type QueuedSmsAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "queued_sms". All fields are combined with a logical 'AND'. */
export type QueuedSmsBoolExp = {
  _and?: Maybe<Array<QueuedSmsBoolExp>>;
  _not?: Maybe<QueuedSmsBoolExp>;
  _or?: Maybe<Array<QueuedSmsBoolExp>>;
  id?: Maybe<IntComparisonExp>;
  proViewId?: Maybe<IntComparisonExp>;
  pro_view?: Maybe<ProViewBoolExp>;
  sendDate?: Maybe<TimestamptzComparisonExp>;
  smsFrom?: Maybe<StringComparisonExp>;
  smsTo?: Maybe<StringComparisonExp>;
  status?: Maybe<StringComparisonExp>;
  text?: Maybe<StringComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  uuid?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "queued_sms" */
export enum QueuedSmsConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_23014Primary = 'idx_23014_primary'
}

/** input type for incrementing numeric columns in table "queued_sms" */
export type QueuedSmsIncInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "queued_sms" */
export type QueuedSmsInsertInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  pro_view?: Maybe<ProViewObjRelInsertInput>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsFrom?: Maybe<Scalars['String']>;
  smsTo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type QueuedSmsMaxFields = {
  __typename?: 'QueuedSmsMaxFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsFrom?: Maybe<Scalars['String']>;
  smsTo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "queued_sms" */
export type QueuedSmsMaxOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  sendDate?: Maybe<OrderBy>;
  smsFrom?: Maybe<OrderBy>;
  smsTo?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  text?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type QueuedSmsMinFields = {
  __typename?: 'QueuedSmsMinFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsFrom?: Maybe<Scalars['String']>;
  smsTo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "queued_sms" */
export type QueuedSmsMinOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  sendDate?: Maybe<OrderBy>;
  smsFrom?: Maybe<OrderBy>;
  smsTo?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  text?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** response of any mutation on the table "queued_sms" */
export type QueuedSmsMutationResponse = {
  __typename?: 'QueuedSmsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<QueuedSms>;
};

/** on_conflict condition type for table "queued_sms" */
export type QueuedSmsOnConflict = {
  constraint: QueuedSmsConstraint;
  updateColumns: Array<QueuedSmsUpdateColumn>;
  where?: Maybe<QueuedSmsBoolExp>;
};

/** Ordering options when selecting data from "queued_sms". */
export type QueuedSmsOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  pro_view?: Maybe<ProViewOrderBy>;
  sendDate?: Maybe<OrderBy>;
  smsFrom?: Maybe<OrderBy>;
  smsTo?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  text?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** primary key columns input for table: queued_sms */
export type QueuedSmsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "queued_sms" */
export enum QueuedSmsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  SmsFrom = 'smsFrom',
  /** column name */
  SmsTo = 'smsTo',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "queued_sms" */
export type QueuedSmsSetInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsFrom?: Maybe<Scalars['String']>;
  smsTo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type QueuedSmsStddevFields = {
  __typename?: 'QueuedSmsStddevFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "queued_sms" */
export type QueuedSmsStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type QueuedSmsStddevPopFields = {
  __typename?: 'QueuedSmsStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "queued_sms" */
export type QueuedSmsStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type QueuedSmsStddevSampFields = {
  __typename?: 'QueuedSmsStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "queued_sms" */
export type QueuedSmsStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "queued_sms" */
export type QueuedSmsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: QueuedSmsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type QueuedSmsStreamCursorValueInput = {
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  smsFrom?: Maybe<Scalars['String']>;
  smsTo?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type QueuedSmsSumFields = {
  __typename?: 'QueuedSmsSumFields';
  id?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "queued_sms" */
export type QueuedSmsSumOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "queued_sms" */
export enum QueuedSmsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  SendDate = 'sendDate',
  /** column name */
  SmsFrom = 'smsFrom',
  /** column name */
  SmsTo = 'smsTo',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  Type = 'type',
  /** column name */
  Uuid = 'uuid'
}

export type QueuedSmsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<QueuedSmsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<QueuedSmsSetInput>;
  /** filter the rows which have to be updated */
  where: QueuedSmsBoolExp;
};

/** aggregate variance on columns */
export type QueuedSmsVarianceFields = {
  __typename?: 'QueuedSmsVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "queued_sms" */
export type QueuedSmsVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type QueuedSmsVarPopFields = {
  __typename?: 'QueuedSmsVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "queued_sms" */
export type QueuedSmsVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type QueuedSmsVarSampFields = {
  __typename?: 'QueuedSmsVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "queued_sms" */
export type QueuedSmsVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

export type RandomProViewsArgs = {
  seed?: Maybe<Scalars['float8']>;
};

export type SearchAccountArgs = {
  search?: Maybe<Scalars['String']>;
};

export type SearchNearestCityArgs = {
  location?: Maybe<Scalars['geometry']>;
  max_distance_km?: Maybe<Scalars['Int']>;
};

export type SearchNearestProReviewArgs = {
  location?: Maybe<Scalars['geometry']>;
  max_distance_km?: Maybe<Scalars['Int']>;
};

export type SearchNearestProViewArgs = {
  location?: Maybe<Scalars['geometry']>;
  max_distance_km?: Maybe<Scalars['Int']>;
};

export type SearchProPersonArgs = {
  search?: Maybe<Scalars['String']>;
};

export type SearchProPresentationArgs = {
  search?: Maybe<Scalars['String']>;
};

export type SearchProReviewArgs = {
  search?: Maybe<Scalars['String']>;
};

export type SearchProReviewInvitationArgs = {
  search?: Maybe<Scalars['String']>;
};

export type SearchProViewArgs = {
  search?: Maybe<Scalars['String']>;
};

export type SearchSolicitationArgs = {
  search?: Maybe<Scalars['String']>;
};

export type SearchTradeArgs = {
  search?: Maybe<Scalars['String']>;
};

export type SearchTradeGroupArgs = {
  search?: Maybe<Scalars['String']>;
};

/** columns and relationships of "sitemap_bilik_zone" */
export type SitemapBilikZone = {
  __typename?: 'SitemapBilikZone';
  /** An object relationship */
  bilikZone?: Maybe<BilikZone>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  trade?: Maybe<Trade>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "sitemap_bilik_zone" */
export type SitemapBilikZoneAggregate = {
  __typename?: 'SitemapBilikZoneAggregate';
  aggregate?: Maybe<SitemapBilikZoneAggregateFields>;
  nodes: Array<SitemapBilikZone>;
};

export type SitemapBilikZoneAggregateBoolExp = {
  count?: Maybe<SitemapBilikZoneAggregateBoolExpCount>;
};

export type SitemapBilikZoneAggregateBoolExpCount = {
  arguments?: Maybe<Array<SitemapBilikZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SitemapBilikZoneBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "sitemap_bilik_zone" */
export type SitemapBilikZoneAggregateFields = {
  __typename?: 'SitemapBilikZoneAggregateFields';
  avg?: Maybe<SitemapBilikZoneAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SitemapBilikZoneMaxFields>;
  min?: Maybe<SitemapBilikZoneMinFields>;
  stddev?: Maybe<SitemapBilikZoneStddevFields>;
  stddevPop?: Maybe<SitemapBilikZoneStddevPopFields>;
  stddevSamp?: Maybe<SitemapBilikZoneStddevSampFields>;
  sum?: Maybe<SitemapBilikZoneSumFields>;
  varPop?: Maybe<SitemapBilikZoneVarPopFields>;
  varSamp?: Maybe<SitemapBilikZoneVarSampFields>;
  variance?: Maybe<SitemapBilikZoneVarianceFields>;
};


/** aggregate fields of "sitemap_bilik_zone" */
export type SitemapBilikZoneAggregateFieldsCountArgs = {
  columns?: Maybe<Array<SitemapBilikZoneSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sitemap_bilik_zone" */
export type SitemapBilikZoneAggregateOrderBy = {
  avg?: Maybe<SitemapBilikZoneAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<SitemapBilikZoneMaxOrderBy>;
  min?: Maybe<SitemapBilikZoneMinOrderBy>;
  stddev?: Maybe<SitemapBilikZoneStddevOrderBy>;
  stddevPop?: Maybe<SitemapBilikZoneStddevPopOrderBy>;
  stddevSamp?: Maybe<SitemapBilikZoneStddevSampOrderBy>;
  sum?: Maybe<SitemapBilikZoneSumOrderBy>;
  varPop?: Maybe<SitemapBilikZoneVarPopOrderBy>;
  varSamp?: Maybe<SitemapBilikZoneVarSampOrderBy>;
  variance?: Maybe<SitemapBilikZoneVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "sitemap_bilik_zone" */
export type SitemapBilikZoneArrRelInsertInput = {
  data: Array<SitemapBilikZoneInsertInput>;
};

/** aggregate avg on columns */
export type SitemapBilikZoneAvgFields = {
  __typename?: 'SitemapBilikZoneAvgFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneAvgOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "sitemap_bilik_zone". All fields are combined with a logical 'AND'. */
export type SitemapBilikZoneBoolExp = {
  _and?: Maybe<Array<SitemapBilikZoneBoolExp>>;
  _not?: Maybe<SitemapBilikZoneBoolExp>;
  _or?: Maybe<Array<SitemapBilikZoneBoolExp>>;
  bilikZone?: Maybe<BilikZoneBoolExp>;
  bilikZoneId?: Maybe<IntComparisonExp>;
  trade?: Maybe<TradeBoolExp>;
  tradeId?: Maybe<IntComparisonExp>;
};

/** input type for inserting data into table "sitemap_bilik_zone" */
export type SitemapBilikZoneInsertInput = {
  bilikZone?: Maybe<BilikZoneObjRelInsertInput>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  trade?: Maybe<TradeObjRelInsertInput>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type SitemapBilikZoneMaxFields = {
  __typename?: 'SitemapBilikZoneMaxFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneMaxOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type SitemapBilikZoneMinFields = {
  __typename?: 'SitemapBilikZoneMinFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneMinOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** input type for inserting object relation for remote table "sitemap_bilik_zone" */
export type SitemapBilikZoneObjRelInsertInput = {
  data: SitemapBilikZoneInsertInput;
};

/** Ordering options when selecting data from "sitemap_bilik_zone". */
export type SitemapBilikZoneOrderBy = {
  bilikZone?: Maybe<BilikZoneOrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  trade?: Maybe<TradeOrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** select columns of table "sitemap_bilik_zone" */
export enum SitemapBilikZoneSelectColumn {
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  TradeId = 'tradeId'
}

/** aggregate stddev on columns */
export type SitemapBilikZoneStddevFields = {
  __typename?: 'SitemapBilikZoneStddevFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneStddevOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type SitemapBilikZoneStddevPopFields = {
  __typename?: 'SitemapBilikZoneStddevPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneStddevPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type SitemapBilikZoneStddevSampFields = {
  __typename?: 'SitemapBilikZoneStddevSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneStddevSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "sitemap_bilik_zone" */
export type SitemapBilikZoneStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SitemapBilikZoneStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SitemapBilikZoneStreamCursorValueInput = {
  bilikZoneId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type SitemapBilikZoneSumFields = {
  __typename?: 'SitemapBilikZoneSumFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneSumOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type SitemapBilikZoneVarianceFields = {
  __typename?: 'SitemapBilikZoneVarianceFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneVarianceOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type SitemapBilikZoneVarPopFields = {
  __typename?: 'SitemapBilikZoneVarPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneVarPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type SitemapBilikZoneVarSampFields = {
  __typename?: 'SitemapBilikZoneVarSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "sitemap_bilik_zone" */
export type SitemapBilikZoneVarSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** columns and relationships of "sitemap_city" */
export type SitemapCity = {
  __typename?: 'SitemapCity';
  /** An object relationship */
  bilikZone?: Maybe<BilikZone>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  trade?: Maybe<Trade>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "sitemap_city" */
export type SitemapCityAggregate = {
  __typename?: 'SitemapCityAggregate';
  aggregate?: Maybe<SitemapCityAggregateFields>;
  nodes: Array<SitemapCity>;
};

export type SitemapCityAggregateBoolExp = {
  count?: Maybe<SitemapCityAggregateBoolExpCount>;
};

export type SitemapCityAggregateBoolExpCount = {
  arguments?: Maybe<Array<SitemapCitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SitemapCityBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "sitemap_city" */
export type SitemapCityAggregateFields = {
  __typename?: 'SitemapCityAggregateFields';
  avg?: Maybe<SitemapCityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SitemapCityMaxFields>;
  min?: Maybe<SitemapCityMinFields>;
  stddev?: Maybe<SitemapCityStddevFields>;
  stddevPop?: Maybe<SitemapCityStddevPopFields>;
  stddevSamp?: Maybe<SitemapCityStddevSampFields>;
  sum?: Maybe<SitemapCitySumFields>;
  varPop?: Maybe<SitemapCityVarPopFields>;
  varSamp?: Maybe<SitemapCityVarSampFields>;
  variance?: Maybe<SitemapCityVarianceFields>;
};


/** aggregate fields of "sitemap_city" */
export type SitemapCityAggregateFieldsCountArgs = {
  columns?: Maybe<Array<SitemapCitySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sitemap_city" */
export type SitemapCityAggregateOrderBy = {
  avg?: Maybe<SitemapCityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<SitemapCityMaxOrderBy>;
  min?: Maybe<SitemapCityMinOrderBy>;
  stddev?: Maybe<SitemapCityStddevOrderBy>;
  stddevPop?: Maybe<SitemapCityStddevPopOrderBy>;
  stddevSamp?: Maybe<SitemapCityStddevSampOrderBy>;
  sum?: Maybe<SitemapCitySumOrderBy>;
  varPop?: Maybe<SitemapCityVarPopOrderBy>;
  varSamp?: Maybe<SitemapCityVarSampOrderBy>;
  variance?: Maybe<SitemapCityVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "sitemap_city" */
export type SitemapCityArrRelInsertInput = {
  data: Array<SitemapCityInsertInput>;
};

/** aggregate avg on columns */
export type SitemapCityAvgFields = {
  __typename?: 'SitemapCityAvgFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  cityId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sitemap_city" */
export type SitemapCityAvgOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "sitemap_city". All fields are combined with a logical 'AND'. */
export type SitemapCityBoolExp = {
  _and?: Maybe<Array<SitemapCityBoolExp>>;
  _not?: Maybe<SitemapCityBoolExp>;
  _or?: Maybe<Array<SitemapCityBoolExp>>;
  bilikZone?: Maybe<BilikZoneBoolExp>;
  bilikZoneId?: Maybe<IntComparisonExp>;
  city?: Maybe<CityBoolExp>;
  cityId?: Maybe<IntComparisonExp>;
  count?: Maybe<BigintComparisonExp>;
  trade?: Maybe<TradeBoolExp>;
  tradeId?: Maybe<IntComparisonExp>;
};

/** input type for inserting data into table "sitemap_city" */
export type SitemapCityInsertInput = {
  bilikZone?: Maybe<BilikZoneObjRelInsertInput>;
  bilikZoneId?: Maybe<Scalars['Int']>;
  city?: Maybe<CityObjRelInsertInput>;
  cityId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  trade?: Maybe<TradeObjRelInsertInput>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type SitemapCityMaxFields = {
  __typename?: 'SitemapCityMaxFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "sitemap_city" */
export type SitemapCityMaxOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type SitemapCityMinFields = {
  __typename?: 'SitemapCityMinFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "sitemap_city" */
export type SitemapCityMinOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Ordering options when selecting data from "sitemap_city". */
export type SitemapCityOrderBy = {
  bilikZone?: Maybe<BilikZoneOrderBy>;
  bilikZoneId?: Maybe<OrderBy>;
  city?: Maybe<CityOrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  trade?: Maybe<TradeOrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** select columns of table "sitemap_city" */
export enum SitemapCitySelectColumn {
  /** column name */
  BilikZoneId = 'bilikZoneId',
  /** column name */
  CityId = 'cityId',
  /** column name */
  Count = 'count',
  /** column name */
  TradeId = 'tradeId'
}

/** aggregate stddev on columns */
export type SitemapCityStddevFields = {
  __typename?: 'SitemapCityStddevFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  cityId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sitemap_city" */
export type SitemapCityStddevOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type SitemapCityStddevPopFields = {
  __typename?: 'SitemapCityStddevPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  cityId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "sitemap_city" */
export type SitemapCityStddevPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type SitemapCityStddevSampFields = {
  __typename?: 'SitemapCityStddevSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  cityId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "sitemap_city" */
export type SitemapCityStddevSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "sitemap_city" */
export type SitemapCityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SitemapCityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SitemapCityStreamCursorValueInput = {
  bilikZoneId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type SitemapCitySumFields = {
  __typename?: 'SitemapCitySumFields';
  bilikZoneId?: Maybe<Scalars['Int']>;
  cityId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sitemap_city" */
export type SitemapCitySumOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type SitemapCityVarianceFields = {
  __typename?: 'SitemapCityVarianceFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  cityId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sitemap_city" */
export type SitemapCityVarianceOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type SitemapCityVarPopFields = {
  __typename?: 'SitemapCityVarPopFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  cityId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "sitemap_city" */
export type SitemapCityVarPopOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type SitemapCityVarSampFields = {
  __typename?: 'SitemapCityVarSampFields';
  bilikZoneId?: Maybe<Scalars['Float']>;
  cityId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "sitemap_city" */
export type SitemapCityVarSampOrderBy = {
  bilikZoneId?: Maybe<OrderBy>;
  cityId?: Maybe<OrderBy>;
  count?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** columns and relationships of "slug_redirect" */
export type SlugRedirect = {
  __typename?: 'SlugRedirect';
  fromslug: Scalars['String'];
  id: Scalars['Int'];
  slugtype: Scalars['Int'];
  toslug: Scalars['String'];
};

/** aggregated selection of "slug_redirect" */
export type SlugRedirectAggregate = {
  __typename?: 'SlugRedirectAggregate';
  aggregate?: Maybe<SlugRedirectAggregateFields>;
  nodes: Array<SlugRedirect>;
};

/** aggregate fields of "slug_redirect" */
export type SlugRedirectAggregateFields = {
  __typename?: 'SlugRedirectAggregateFields';
  avg?: Maybe<SlugRedirectAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SlugRedirectMaxFields>;
  min?: Maybe<SlugRedirectMinFields>;
  stddev?: Maybe<SlugRedirectStddevFields>;
  stddevPop?: Maybe<SlugRedirectStddevPopFields>;
  stddevSamp?: Maybe<SlugRedirectStddevSampFields>;
  sum?: Maybe<SlugRedirectSumFields>;
  varPop?: Maybe<SlugRedirectVarPopFields>;
  varSamp?: Maybe<SlugRedirectVarSampFields>;
  variance?: Maybe<SlugRedirectVarianceFields>;
};


/** aggregate fields of "slug_redirect" */
export type SlugRedirectAggregateFieldsCountArgs = {
  columns?: Maybe<Array<SlugRedirectSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SlugRedirectAvgFields = {
  __typename?: 'SlugRedirectAvgFields';
  id?: Maybe<Scalars['Float']>;
  slugtype?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "slug_redirect". All fields are combined with a logical 'AND'. */
export type SlugRedirectBoolExp = {
  _and?: Maybe<Array<SlugRedirectBoolExp>>;
  _not?: Maybe<SlugRedirectBoolExp>;
  _or?: Maybe<Array<SlugRedirectBoolExp>>;
  fromslug?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  slugtype?: Maybe<IntComparisonExp>;
  toslug?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "slug_redirect" */
export enum SlugRedirectConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_23050Primary = 'idx_23050_primary'
}

/** input type for incrementing numeric columns in table "slug_redirect" */
export type SlugRedirectIncInput = {
  id?: Maybe<Scalars['Int']>;
  slugtype?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "slug_redirect" */
export type SlugRedirectInsertInput = {
  fromslug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  slugtype?: Maybe<Scalars['Int']>;
  toslug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SlugRedirectMaxFields = {
  __typename?: 'SlugRedirectMaxFields';
  fromslug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  slugtype?: Maybe<Scalars['Int']>;
  toslug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SlugRedirectMinFields = {
  __typename?: 'SlugRedirectMinFields';
  fromslug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  slugtype?: Maybe<Scalars['Int']>;
  toslug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "slug_redirect" */
export type SlugRedirectMutationResponse = {
  __typename?: 'SlugRedirectMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SlugRedirect>;
};

/** on_conflict condition type for table "slug_redirect" */
export type SlugRedirectOnConflict = {
  constraint: SlugRedirectConstraint;
  updateColumns: Array<SlugRedirectUpdateColumn>;
  where?: Maybe<SlugRedirectBoolExp>;
};

/** Ordering options when selecting data from "slug_redirect". */
export type SlugRedirectOrderBy = {
  fromslug?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  slugtype?: Maybe<OrderBy>;
  toslug?: Maybe<OrderBy>;
};

/** primary key columns input for table: slug_redirect */
export type SlugRedirectPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "slug_redirect" */
export enum SlugRedirectSelectColumn {
  /** column name */
  Fromslug = 'fromslug',
  /** column name */
  Id = 'id',
  /** column name */
  Slugtype = 'slugtype',
  /** column name */
  Toslug = 'toslug'
}

/** input type for updating data in table "slug_redirect" */
export type SlugRedirectSetInput = {
  fromslug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  slugtype?: Maybe<Scalars['Int']>;
  toslug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SlugRedirectStddevFields = {
  __typename?: 'SlugRedirectStddevFields';
  id?: Maybe<Scalars['Float']>;
  slugtype?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type SlugRedirectStddevPopFields = {
  __typename?: 'SlugRedirectStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  slugtype?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type SlugRedirectStddevSampFields = {
  __typename?: 'SlugRedirectStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  slugtype?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "slug_redirect" */
export type SlugRedirectStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SlugRedirectStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SlugRedirectStreamCursorValueInput = {
  fromslug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  slugtype?: Maybe<Scalars['Int']>;
  toslug?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type SlugRedirectSumFields = {
  __typename?: 'SlugRedirectSumFields';
  id?: Maybe<Scalars['Int']>;
  slugtype?: Maybe<Scalars['Int']>;
};

/** update columns of table "slug_redirect" */
export enum SlugRedirectUpdateColumn {
  /** column name */
  Fromslug = 'fromslug',
  /** column name */
  Id = 'id',
  /** column name */
  Slugtype = 'slugtype',
  /** column name */
  Toslug = 'toslug'
}

export type SlugRedirectUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<SlugRedirectIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<SlugRedirectSetInput>;
  /** filter the rows which have to be updated */
  where: SlugRedirectBoolExp;
};

/** aggregate variance on columns */
export type SlugRedirectVarianceFields = {
  __typename?: 'SlugRedirectVarianceFields';
  id?: Maybe<Scalars['Float']>;
  slugtype?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type SlugRedirectVarPopFields = {
  __typename?: 'SlugRedirectVarPopFields';
  id?: Maybe<Scalars['Float']>;
  slugtype?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type SlugRedirectVarSampFields = {
  __typename?: 'SlugRedirectVarSampFields';
  id?: Maybe<Scalars['Float']>;
  slugtype?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintComparisonExp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "solicitation" */
export type Solicitation = {
  __typename?: 'Solicitation';
  addressLocality: Scalars['String'];
  bounceEntity?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  configuration?: Maybe<Scalars['jsonb']>;
  dateCreated: Scalars['timestamptz'];
  declineReason?: Maybe<Scalars['String']>;
  declinedBy?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['Int'];
  ip?: Maybe<Scalars['String']>;
  isAutorespondActive: Scalars['Boolean'];
  isSpam: Scalars['Boolean'];
  lastResponseDate?: Maybe<Scalars['timestamptz']>;
  message: Scalars['String'];
  /** An object relationship */
  proReview?: Maybe<ProReview>;
  proReviewId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  telephone: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
};


/** columns and relationships of "solicitation" */
export type SolicitationConfigurationArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "solicitation" */
export type SolicitationAggregate = {
  __typename?: 'SolicitationAggregate';
  aggregate?: Maybe<SolicitationAggregateFields>;
  nodes: Array<Solicitation>;
};

export type SolicitationAggregateBoolExp = {
  bool_and?: Maybe<SolicitationAggregateBoolExpBoolAnd>;
  bool_or?: Maybe<SolicitationAggregateBoolExpBoolOr>;
  count?: Maybe<SolicitationAggregateBoolExpCount>;
};

export type SolicitationAggregateBoolExpBoolAnd = {
  arguments: SolicitationSelectColumnSolicitationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SolicitationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SolicitationAggregateBoolExpBoolOr = {
  arguments: SolicitationSelectColumnSolicitationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SolicitationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SolicitationAggregateBoolExpCount = {
  arguments?: Maybe<Array<SolicitationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<SolicitationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "solicitation" */
export type SolicitationAggregateFields = {
  __typename?: 'SolicitationAggregateFields';
  avg?: Maybe<SolicitationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SolicitationMaxFields>;
  min?: Maybe<SolicitationMinFields>;
  stddev?: Maybe<SolicitationStddevFields>;
  stddevPop?: Maybe<SolicitationStddevPopFields>;
  stddevSamp?: Maybe<SolicitationStddevSampFields>;
  sum?: Maybe<SolicitationSumFields>;
  varPop?: Maybe<SolicitationVarPopFields>;
  varSamp?: Maybe<SolicitationVarSampFields>;
  variance?: Maybe<SolicitationVarianceFields>;
};


/** aggregate fields of "solicitation" */
export type SolicitationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<SolicitationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "solicitation" */
export type SolicitationAggregateOrderBy = {
  avg?: Maybe<SolicitationAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<SolicitationMaxOrderBy>;
  min?: Maybe<SolicitationMinOrderBy>;
  stddev?: Maybe<SolicitationStddevOrderBy>;
  stddevPop?: Maybe<SolicitationStddevPopOrderBy>;
  stddevSamp?: Maybe<SolicitationStddevSampOrderBy>;
  sum?: Maybe<SolicitationSumOrderBy>;
  varPop?: Maybe<SolicitationVarPopOrderBy>;
  varSamp?: Maybe<SolicitationVarSampOrderBy>;
  variance?: Maybe<SolicitationVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SolicitationAppendInput = {
  /** (DC2Type:json_array) */
  configuration?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "solicitation" */
export type SolicitationArrRelInsertInput = {
  data: Array<SolicitationInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<SolicitationOnConflict>;
};

/** aggregate avg on columns */
export type SolicitationAvgFields = {
  __typename?: 'SolicitationAvgFields';
  id?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "solicitation" */
export type SolicitationAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "solicitation". All fields are combined with a logical 'AND'. */
export type SolicitationBoolExp = {
  _and?: Maybe<Array<SolicitationBoolExp>>;
  _not?: Maybe<SolicitationBoolExp>;
  _or?: Maybe<Array<SolicitationBoolExp>>;
  addressLocality?: Maybe<StringComparisonExp>;
  bounceEntity?: Maybe<StringComparisonExp>;
  configuration?: Maybe<JsonbComparisonExp>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  declineReason?: Maybe<StringComparisonExp>;
  declinedBy?: Maybe<StringComparisonExp>;
  delay?: Maybe<StringComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  familyName?: Maybe<StringComparisonExp>;
  givenName?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  ip?: Maybe<StringComparisonExp>;
  isAutorespondActive?: Maybe<BooleanComparisonExp>;
  isSpam?: Maybe<BooleanComparisonExp>;
  lastResponseDate?: Maybe<TimestamptzComparisonExp>;
  message?: Maybe<StringComparisonExp>;
  proReview?: Maybe<ProReviewBoolExp>;
  proReviewId?: Maybe<IntComparisonExp>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
  subject?: Maybe<StringComparisonExp>;
  telephone?: Maybe<StringComparisonExp>;
  uuid?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "solicitation" */
export enum SolicitationConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_23059Primary = 'idx_23059_primary',
  /** unique or primary key constraint on columns "uuid" */
  Idx_23059Uniq_4fa96783d17f50a6 = 'idx_23059_uniq_4fa96783d17f50a6'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SolicitationDeleteAtPathInput = {
  /** (DC2Type:json_array) */
  configuration?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type SolicitationDeleteElemInput = {
  /** (DC2Type:json_array) */
  configuration?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SolicitationDeleteKeyInput = {
  /** (DC2Type:json_array) */
  configuration?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "solicitation" */
export type SolicitationIncInput = {
  id?: Maybe<Scalars['Int']>;
  proReviewId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "solicitation" */
export type SolicitationInsertInput = {
  addressLocality?: Maybe<Scalars['String']>;
  bounceEntity?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  configuration?: Maybe<Scalars['jsonb']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  declineReason?: Maybe<Scalars['String']>;
  declinedBy?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  isAutorespondActive?: Maybe<Scalars['Boolean']>;
  isSpam?: Maybe<Scalars['Boolean']>;
  lastResponseDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  proReview?: Maybe<ProReviewObjRelInsertInput>;
  proReviewId?: Maybe<Scalars['Int']>;
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SolicitationMaxFields = {
  __typename?: 'SolicitationMaxFields';
  addressLocality?: Maybe<Scalars['String']>;
  bounceEntity?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  declineReason?: Maybe<Scalars['String']>;
  declinedBy?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  lastResponseDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  proReviewId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "solicitation" */
export type SolicitationMaxOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  bounceEntity?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  declineReason?: Maybe<OrderBy>;
  declinedBy?: Maybe<OrderBy>;
  delay?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  lastResponseDate?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  subject?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type SolicitationMinFields = {
  __typename?: 'SolicitationMinFields';
  addressLocality?: Maybe<Scalars['String']>;
  bounceEntity?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  declineReason?: Maybe<Scalars['String']>;
  declinedBy?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  lastResponseDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  proReviewId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "solicitation" */
export type SolicitationMinOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  bounceEntity?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  declineReason?: Maybe<OrderBy>;
  declinedBy?: Maybe<OrderBy>;
  delay?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  lastResponseDate?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
  subject?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** response of any mutation on the table "solicitation" */
export type SolicitationMutationResponse = {
  __typename?: 'SolicitationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Solicitation>;
};

/** on_conflict condition type for table "solicitation" */
export type SolicitationOnConflict = {
  constraint: SolicitationConstraint;
  updateColumns: Array<SolicitationUpdateColumn>;
  where?: Maybe<SolicitationBoolExp>;
};

/** Ordering options when selecting data from "solicitation". */
export type SolicitationOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  bounceEntity?: Maybe<OrderBy>;
  configuration?: Maybe<OrderBy>;
  dateCreated?: Maybe<OrderBy>;
  declineReason?: Maybe<OrderBy>;
  declinedBy?: Maybe<OrderBy>;
  delay?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  ip?: Maybe<OrderBy>;
  isAutorespondActive?: Maybe<OrderBy>;
  isSpam?: Maybe<OrderBy>;
  lastResponseDate?: Maybe<OrderBy>;
  message?: Maybe<OrderBy>;
  proReview?: Maybe<ProReviewOrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
  subject?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
  uuid?: Maybe<OrderBy>;
};

/** primary key columns input for table: solicitation */
export type SolicitationPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SolicitationPrependInput = {
  /** (DC2Type:json_array) */
  configuration?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "solicitation" */
export enum SolicitationSelectColumn {
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  BounceEntity = 'bounceEntity',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DeclineReason = 'declineReason',
  /** column name */
  DeclinedBy = 'declinedBy',
  /** column name */
  Delay = 'delay',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  IsAutorespondActive = 'isAutorespondActive',
  /** column name */
  IsSpam = 'isSpam',
  /** column name */
  LastResponseDate = 'lastResponseDate',
  /** column name */
  Message = 'message',
  /** column name */
  ProReviewId = 'proReviewId',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  Subject = 'subject',
  /** column name */
  Telephone = 'telephone',
  /** column name */
  Uuid = 'uuid'
}

/** select "solicitationAggregateBoolExpBool_andArgumentsColumns" columns of table "solicitation" */
export enum SolicitationSelectColumnSolicitationAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  IsAutorespondActive = 'isAutorespondActive',
  /** column name */
  IsSpam = 'isSpam'
}

/** select "solicitationAggregateBoolExpBool_orArgumentsColumns" columns of table "solicitation" */
export enum SolicitationSelectColumnSolicitationAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  IsAutorespondActive = 'isAutorespondActive',
  /** column name */
  IsSpam = 'isSpam'
}

/** input type for updating data in table "solicitation" */
export type SolicitationSetInput = {
  addressLocality?: Maybe<Scalars['String']>;
  bounceEntity?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  configuration?: Maybe<Scalars['jsonb']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  declineReason?: Maybe<Scalars['String']>;
  declinedBy?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  isAutorespondActive?: Maybe<Scalars['Boolean']>;
  isSpam?: Maybe<Scalars['Boolean']>;
  lastResponseDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  proReviewId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SolicitationStddevFields = {
  __typename?: 'SolicitationStddevFields';
  id?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "solicitation" */
export type SolicitationStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type SolicitationStddevPopFields = {
  __typename?: 'SolicitationStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "solicitation" */
export type SolicitationStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type SolicitationStddevSampFields = {
  __typename?: 'SolicitationStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "solicitation" */
export type SolicitationStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "solicitation" */
export type SolicitationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SolicitationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SolicitationStreamCursorValueInput = {
  addressLocality?: Maybe<Scalars['String']>;
  bounceEntity?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  configuration?: Maybe<Scalars['jsonb']>;
  dateCreated?: Maybe<Scalars['timestamptz']>;
  declineReason?: Maybe<Scalars['String']>;
  declinedBy?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  isAutorespondActive?: Maybe<Scalars['Boolean']>;
  isSpam?: Maybe<Scalars['Boolean']>;
  lastResponseDate?: Maybe<Scalars['timestamptz']>;
  message?: Maybe<Scalars['String']>;
  proReviewId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type SolicitationSumFields = {
  __typename?: 'SolicitationSumFields';
  id?: Maybe<Scalars['Int']>;
  proReviewId?: Maybe<Scalars['Int']>;
  proViewId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "solicitation" */
export type SolicitationSumOrderBy = {
  id?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** update columns of table "solicitation" */
export enum SolicitationUpdateColumn {
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  BounceEntity = 'bounceEntity',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  DeclineReason = 'declineReason',
  /** column name */
  DeclinedBy = 'declinedBy',
  /** column name */
  Delay = 'delay',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  IsAutorespondActive = 'isAutorespondActive',
  /** column name */
  IsSpam = 'isSpam',
  /** column name */
  LastResponseDate = 'lastResponseDate',
  /** column name */
  Message = 'message',
  /** column name */
  ProReviewId = 'proReviewId',
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  Subject = 'subject',
  /** column name */
  Telephone = 'telephone',
  /** column name */
  Uuid = 'uuid'
}

export type SolicitationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<SolicitationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: Maybe<SolicitationDeleteAtPathInput>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _deleteElem?: Maybe<SolicitationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: Maybe<SolicitationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<SolicitationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<SolicitationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<SolicitationSetInput>;
  /** filter the rows which have to be updated */
  where: SolicitationBoolExp;
};

/** aggregate variance on columns */
export type SolicitationVarianceFields = {
  __typename?: 'SolicitationVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "solicitation" */
export type SolicitationVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type SolicitationVarPopFields = {
  __typename?: 'SolicitationVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "solicitation" */
export type SolicitationVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type SolicitationVarSampFields = {
  __typename?: 'SolicitationVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proReviewId?: Maybe<Scalars['Float']>;
  proViewId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "solicitation" */
export type SolicitationVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proReviewId?: Maybe<OrderBy>;
  proViewId?: Maybe<OrderBy>;
};

/** columns and relationships of "spatial_ref_sys" */
export type SpatialRefSys = {
  __typename?: 'SpatialRefSys';
  authName?: Maybe<Scalars['String']>;
  authSrid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid: Scalars['Int'];
  srtext?: Maybe<Scalars['String']>;
};

/** aggregated selection of "spatial_ref_sys" */
export type SpatialRefSysAggregate = {
  __typename?: 'SpatialRefSysAggregate';
  aggregate?: Maybe<SpatialRefSysAggregateFields>;
  nodes: Array<SpatialRefSys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type SpatialRefSysAggregateFields = {
  __typename?: 'SpatialRefSysAggregateFields';
  avg?: Maybe<SpatialRefSysAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SpatialRefSysMaxFields>;
  min?: Maybe<SpatialRefSysMinFields>;
  stddev?: Maybe<SpatialRefSysStddevFields>;
  stddevPop?: Maybe<SpatialRefSysStddevPopFields>;
  stddevSamp?: Maybe<SpatialRefSysStddevSampFields>;
  sum?: Maybe<SpatialRefSysSumFields>;
  varPop?: Maybe<SpatialRefSysVarPopFields>;
  varSamp?: Maybe<SpatialRefSysVarSampFields>;
  variance?: Maybe<SpatialRefSysVarianceFields>;
};


/** aggregate fields of "spatial_ref_sys" */
export type SpatialRefSysAggregateFieldsCountArgs = {
  columns?: Maybe<Array<SpatialRefSysSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type SpatialRefSysAvgFields = {
  __typename?: 'SpatialRefSysAvgFields';
  authSrid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type SpatialRefSysBoolExp = {
  _and?: Maybe<Array<SpatialRefSysBoolExp>>;
  _not?: Maybe<SpatialRefSysBoolExp>;
  _or?: Maybe<Array<SpatialRefSysBoolExp>>;
  authName?: Maybe<StringComparisonExp>;
  authSrid?: Maybe<IntComparisonExp>;
  proj4text?: Maybe<StringComparisonExp>;
  srid?: Maybe<IntComparisonExp>;
  srtext?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum SpatialRefSysConstraint {
  /** unique or primary key constraint on columns "srid" */
  SpatialRefSysPkey = 'spatial_ref_sys_pkey'
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type SpatialRefSysIncInput = {
  authSrid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type SpatialRefSysInsertInput = {
  authName?: Maybe<Scalars['String']>;
  authSrid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SpatialRefSysMaxFields = {
  __typename?: 'SpatialRefSysMaxFields';
  authName?: Maybe<Scalars['String']>;
  authSrid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SpatialRefSysMinFields = {
  __typename?: 'SpatialRefSysMinFields';
  authName?: Maybe<Scalars['String']>;
  authSrid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type SpatialRefSysMutationResponse = {
  __typename?: 'SpatialRefSysMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SpatialRefSys>;
};

/** on_conflict condition type for table "spatial_ref_sys" */
export type SpatialRefSysOnConflict = {
  constraint: SpatialRefSysConstraint;
  updateColumns: Array<SpatialRefSysUpdateColumn>;
  where?: Maybe<SpatialRefSysBoolExp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type SpatialRefSysOrderBy = {
  authName?: Maybe<OrderBy>;
  authSrid?: Maybe<OrderBy>;
  proj4text?: Maybe<OrderBy>;
  srid?: Maybe<OrderBy>;
  srtext?: Maybe<OrderBy>;
};

/** primary key columns input for table: spatial_ref_sys */
export type SpatialRefSysPkColumnsInput = {
  srid: Scalars['Int'];
};

/** select columns of table "spatial_ref_sys" */
export enum SpatialRefSysSelectColumn {
  /** column name */
  AuthName = 'authName',
  /** column name */
  AuthSrid = 'authSrid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

/** input type for updating data in table "spatial_ref_sys" */
export type SpatialRefSysSetInput = {
  authName?: Maybe<Scalars['String']>;
  authSrid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SpatialRefSysStddevFields = {
  __typename?: 'SpatialRefSysStddevFields';
  authSrid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type SpatialRefSysStddevPopFields = {
  __typename?: 'SpatialRefSysStddevPopFields';
  authSrid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type SpatialRefSysStddevSampFields = {
  __typename?: 'SpatialRefSysStddevSampFields';
  authSrid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "spatial_ref_sys" */
export type SpatialRefSysStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SpatialRefSysStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SpatialRefSysStreamCursorValueInput = {
  authName?: Maybe<Scalars['String']>;
  authSrid?: Maybe<Scalars['Int']>;
  proj4text?: Maybe<Scalars['String']>;
  srid?: Maybe<Scalars['Int']>;
  srtext?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type SpatialRefSysSumFields = {
  __typename?: 'SpatialRefSysSumFields';
  authSrid?: Maybe<Scalars['Int']>;
  srid?: Maybe<Scalars['Int']>;
};

/** update columns of table "spatial_ref_sys" */
export enum SpatialRefSysUpdateColumn {
  /** column name */
  AuthName = 'authName',
  /** column name */
  AuthSrid = 'authSrid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

export type SpatialRefSysUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<SpatialRefSysIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<SpatialRefSysSetInput>;
  /** filter the rows which have to be updated */
  where: SpatialRefSysBoolExp;
};

/** aggregate variance on columns */
export type SpatialRefSysVarianceFields = {
  __typename?: 'SpatialRefSysVarianceFields';
  authSrid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type SpatialRefSysVarPopFields = {
  __typename?: 'SpatialRefSysVarPopFields';
  authSrid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type SpatialRefSysVarSampFields = {
  __typename?: 'SpatialRefSysVarSampFields';
  authSrid?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

export type StDWithinGeographyInput = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type StDWithinInput = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

/** columns and relationships of "stop_sms" */
export type StopSms = {
  __typename?: 'StopSms';
  dateCreated: Scalars['timestamptz'];
  id: Scalars['Int'];
  telephone: Scalars['String'];
};

/** aggregated selection of "stop_sms" */
export type StopSmsAggregate = {
  __typename?: 'StopSmsAggregate';
  aggregate?: Maybe<StopSmsAggregateFields>;
  nodes: Array<StopSms>;
};

/** aggregate fields of "stop_sms" */
export type StopSmsAggregateFields = {
  __typename?: 'StopSmsAggregateFields';
  avg?: Maybe<StopSmsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<StopSmsMaxFields>;
  min?: Maybe<StopSmsMinFields>;
  stddev?: Maybe<StopSmsStddevFields>;
  stddevPop?: Maybe<StopSmsStddevPopFields>;
  stddevSamp?: Maybe<StopSmsStddevSampFields>;
  sum?: Maybe<StopSmsSumFields>;
  varPop?: Maybe<StopSmsVarPopFields>;
  varSamp?: Maybe<StopSmsVarSampFields>;
  variance?: Maybe<StopSmsVarianceFields>;
};


/** aggregate fields of "stop_sms" */
export type StopSmsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<StopSmsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type StopSmsAvgFields = {
  __typename?: 'StopSmsAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "stop_sms". All fields are combined with a logical 'AND'. */
export type StopSmsBoolExp = {
  _and?: Maybe<Array<StopSmsBoolExp>>;
  _not?: Maybe<StopSmsBoolExp>;
  _or?: Maybe<Array<StopSmsBoolExp>>;
  dateCreated?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  telephone?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "stop_sms" */
export enum StopSmsConstraint {
  /** unique or primary key constraint on columns "id" */
  StopSmsPkey = 'stop_sms_pkey',
  /** unique or primary key constraint on columns "telephone" */
  StopSmsPkeyUniq = 'stop_sms_pkey_uniq'
}

/** input type for incrementing numeric columns in table "stop_sms" */
export type StopSmsIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "stop_sms" */
export type StopSmsInsertInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type StopSmsMaxFields = {
  __typename?: 'StopSmsMaxFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type StopSmsMinFields = {
  __typename?: 'StopSmsMinFields';
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "stop_sms" */
export type StopSmsMutationResponse = {
  __typename?: 'StopSmsMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<StopSms>;
};

/** on_conflict condition type for table "stop_sms" */
export type StopSmsOnConflict = {
  constraint: StopSmsConstraint;
  updateColumns: Array<StopSmsUpdateColumn>;
  where?: Maybe<StopSmsBoolExp>;
};

/** Ordering options when selecting data from "stop_sms". */
export type StopSmsOrderBy = {
  dateCreated?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** primary key columns input for table: stop_sms */
export type StopSmsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "stop_sms" */
export enum StopSmsSelectColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Id = 'id',
  /** column name */
  Telephone = 'telephone'
}

/** input type for updating data in table "stop_sms" */
export type StopSmsSetInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type StopSmsStddevFields = {
  __typename?: 'StopSmsStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type StopSmsStddevPopFields = {
  __typename?: 'StopSmsStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type StopSmsStddevSampFields = {
  __typename?: 'StopSmsStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "stop_sms" */
export type StopSmsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StopSmsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StopSmsStreamCursorValueInput = {
  dateCreated?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type StopSmsSumFields = {
  __typename?: 'StopSmsSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "stop_sms" */
export enum StopSmsUpdateColumn {
  /** column name */
  DateCreated = 'dateCreated',
  /** column name */
  Id = 'id',
  /** column name */
  Telephone = 'telephone'
}

export type StopSmsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<StopSmsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<StopSmsSetInput>;
  /** filter the rows which have to be updated */
  where: StopSmsBoolExp;
};

/** aggregate variance on columns */
export type StopSmsVarianceFields = {
  __typename?: 'StopSmsVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type StopSmsVarPopFields = {
  __typename?: 'StopSmsVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type StopSmsVarSampFields = {
  __typename?: 'StopSmsVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _isNull?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  accountAggregate: AccountAggregate;
  /** fetch data from the table: "account" using primary key columns */
  accountByPk?: Maybe<Account>;
  /** fetch data from the table: "account_device" */
  accountDevice: Array<AccountDevice>;
  /** fetch aggregated fields from the table: "account_device" */
  accountDeviceAggregate: AccountDeviceAggregate;
  /** fetch data from the table: "account_device" using primary key columns */
  accountDeviceByPk?: Maybe<AccountDevice>;
  /** fetch data from the table in a streaming manner: "account_device" */
  accountDeviceStream: Array<AccountDevice>;
  /** fetch data from the table: "account_pro_view" */
  accountProView: Array<AccountProView>;
  /** fetch aggregated fields from the table: "account_pro_view" */
  accountProViewAggregate: AccountProViewAggregate;
  /** fetch data from the table: "account_pro_view" using primary key columns */
  accountProViewByPk?: Maybe<AccountProView>;
  /** fetch data from the table in a streaming manner: "account_pro_view" */
  accountProViewStream: Array<AccountProView>;
  /** fetch data from the table in a streaming manner: "account" */
  accountStream: Array<Account>;
  /** fetch data from the table: "action_token" */
  actionToken: Array<ActionToken>;
  /** fetch aggregated fields from the table: "action_token" */
  actionTokenAggregate: ActionTokenAggregate;
  /** fetch data from the table: "action_token" using primary key columns */
  actionTokenByPk?: Maybe<ActionToken>;
  /** fetch data from the table in a streaming manner: "action_token" */
  actionTokenStream: Array<ActionToken>;
  /** fetch data from the table: "autorespond" */
  autorespond: Array<Autorespond>;
  /** fetch aggregated fields from the table: "autorespond" */
  autorespondAggregate: AutorespondAggregate;
  /** fetch data from the table: "autorespond" using primary key columns */
  autorespondByPk?: Maybe<Autorespond>;
  /** fetch data from the table in a streaming manner: "autorespond" */
  autorespondStream: Array<Autorespond>;
  /** fetch data from the table: "bilik_person" */
  bilikPerson: Array<BilikPerson>;
  /** fetch aggregated fields from the table: "bilik_person" */
  bilikPersonAggregate: BilikPersonAggregate;
  /** fetch data from the table: "bilik_person" using primary key columns */
  bilikPersonByPk?: Maybe<BilikPerson>;
  /** fetch data from the table in a streaming manner: "bilik_person" */
  bilikPersonStream: Array<BilikPerson>;
  /** fetch data from the table: "bilik_solicitation" */
  bilikSolicitation: Array<BilikSolicitation>;
  /** fetch aggregated fields from the table: "bilik_solicitation" */
  bilikSolicitationAggregate: BilikSolicitationAggregate;
  /** fetch data from the table: "bilik_solicitation" using primary key columns */
  bilikSolicitationByPk?: Maybe<BilikSolicitation>;
  /** fetch data from the table in a streaming manner: "bilik_solicitation" */
  bilikSolicitationStream: Array<BilikSolicitation>;
  /** fetch data from the table: "bilik_zone" */
  bilikZone: Array<BilikZone>;
  /** fetch aggregated fields from the table: "bilik_zone" */
  bilikZoneAggregate: BilikZoneAggregate;
  /** fetch data from the table: "bilik_zone" using primary key columns */
  bilikZoneByPk?: Maybe<BilikZone>;
  /** fetch data from the table in a streaming manner: "bilik_zone" */
  bilikZoneStream: Array<BilikZone>;
  /** fetch data from the table: "bilik_zone_trade_main_pro_count" */
  bilikZoneTradeMainProCount: Array<BilikZoneTradeMainProCount>;
  /** fetch aggregated fields from the table: "bilik_zone_trade_main_pro_count" */
  bilikZoneTradeMainProCountAggregate: BilikZoneTradeMainProCountAggregate;
  /** fetch data from the table in a streaming manner: "bilik_zone_trade_main_pro_count" */
  bilikZoneTradeMainProCountStream: Array<BilikZoneTradeMainProCount>;
  /** fetch data from the table: "certification" */
  certification: Array<Certification>;
  /** fetch aggregated fields from the table: "certification" */
  certificationAggregate: CertificationAggregate;
  /** fetch data from the table: "certification" using primary key columns */
  certificationByPk?: Maybe<Certification>;
  /** fetch data from the table in a streaming manner: "certification" */
  certificationStream: Array<Certification>;
  /** fetch data from the table: "city" */
  city: Array<City>;
  /** fetch aggregated fields from the table: "city" */
  cityAggregate: CityAggregate;
  /** fetch data from the table: "city" using primary key columns */
  cityByPk?: Maybe<City>;
  /** fetch data from the table: "city_pro_view" */
  cityProView: Array<CityProView>;
  /** fetch aggregated fields from the table: "city_pro_view" */
  cityProViewAggregate: CityProViewAggregate;
  /** fetch data from the table: "city_pro_view" using primary key columns */
  cityProViewByPk?: Maybe<CityProView>;
  /** fetch data from the table in a streaming manner: "city_pro_view" */
  cityProViewStream: Array<CityProView>;
  /** fetch data from the table in a streaming manner: "city" */
  cityStream: Array<City>;
  /** fetch data from the table: "count_pro_review_by_status" */
  countProReviewByStatus: Array<CountProReviewByStatus>;
  /** fetch aggregated fields from the table: "count_pro_review_by_status" */
  countProReviewByStatusAggregate: CountProReviewByStatusAggregate;
  /** fetch data from the table in a streaming manner: "count_pro_review_by_status" */
  countProReviewByStatusStream: Array<CountProReviewByStatus>;
  /** fetch data from the table: "count_pro_review_invitations_by_month_by_pro_presentation" */
  countProReviewInvitationsByMonthByProPresentation: Array<CountProReviewInvitationsByMonthByProPresentation>;
  /** fetch aggregated fields from the table: "count_pro_review_invitations_by_month_by_pro_presentation" */
  countProReviewInvitationsByMonthByProPresentationAggregate: CountProReviewInvitationsByMonthByProPresentationAggregate;
  /** fetch data from the table in a streaming manner: "count_pro_review_invitations_by_month_by_pro_presentation" */
  countProReviewInvitationsByMonthByProPresentationStream: Array<CountProReviewInvitationsByMonthByProPresentation>;
  /** fetch data from the table: "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
  countProReviewsWithStatusOkByMonthByProPresentation: Array<CountProReviewsWithStatusOkByMonthByProPresentation>;
  /** fetch aggregated fields from the table: "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
  countProReviewsWithStatusOkByMonthByProPresentationAggregate: CountProReviewsWithStatusOkByMonthByProPresentationAggregate;
  /** fetch data from the table in a streaming manner: "count_pro_reviews_with_status_ok_by_month_by_pro_presentation" */
  countProReviewsWithStatusOkByMonthByProPresentationStream: Array<CountProReviewsWithStatusOkByMonthByProPresentation>;
  /** fetch data from the table: "device" */
  device: Array<Device>;
  /** fetch aggregated fields from the table: "device" */
  deviceAggregate: DeviceAggregate;
  /** fetch data from the table: "device" using primary key columns */
  deviceByPk?: Maybe<Device>;
  /** fetch data from the table in a streaming manner: "device" */
  deviceStream: Array<Device>;
  /** fetch data from the table: "geography_columns" */
  geographyColumns: Array<GeographyColumns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geographyColumnsAggregate: GeographyColumnsAggregate;
  /** fetch data from the table in a streaming manner: "geography_columns" */
  geographyColumnsStream: Array<GeographyColumns>;
  /** fetch data from the table: "geometry_columns" */
  geometryColumns: Array<GeometryColumns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometryColumnsAggregate: GeometryColumnsAggregate;
  /** fetch data from the table in a streaming manner: "geometry_columns" */
  geometryColumnsStream: Array<GeometryColumns>;
  /** fetch data from the table: "globally_blocked_identity" */
  globallyBlockedIdentity: Array<GloballyBlockedIdentity>;
  /** fetch aggregated fields from the table: "globally_blocked_identity" */
  globallyBlockedIdentityAggregate: GloballyBlockedIdentityAggregate;
  /** fetch data from the table: "globally_blocked_identity" using primary key columns */
  globallyBlockedIdentityByPk?: Maybe<GloballyBlockedIdentity>;
  /** fetch data from the table in a streaming manner: "globally_blocked_identity" */
  globallyBlockedIdentityStream: Array<GloballyBlockedIdentity>;
  /** fetch data from the table: "google_review_link" */
  googleReviewLink: Array<GoogleReviewLink>;
  /** fetch aggregated fields from the table: "google_review_link" */
  googleReviewLinkAggregate: GoogleReviewLinkAggregate;
  /** fetch data from the table: "google_review_link" using primary key columns */
  googleReviewLinkByPk?: Maybe<GoogleReviewLink>;
  /** fetch data from the table in a streaming manner: "google_review_link" */
  googleReviewLinkStream: Array<GoogleReviewLink>;
  /** fetch data from the table: "grouped_pro_intervention" */
  groupedProIntervention: Array<GroupedProIntervention>;
  /** fetch aggregated fields from the table: "grouped_pro_intervention" */
  groupedProInterventionAggregate: GroupedProInterventionAggregate;
  /** fetch data from the table in a streaming manner: "grouped_pro_intervention" */
  groupedProInterventionStream: Array<GroupedProIntervention>;
  /** fetch data from the table: "manager_bilik_person_bilik_zone" */
  managerBilikPersonBilikZone: Array<ManagerBilikPersonBilikZone>;
  /** fetch aggregated fields from the table: "manager_bilik_person_bilik_zone" */
  managerBilikPersonBilikZoneAggregate: ManagerBilikPersonBilikZoneAggregate;
  /** fetch data from the table: "manager_bilik_person_bilik_zone" using primary key columns */
  managerBilikPersonBilikZoneByPk?: Maybe<ManagerBilikPersonBilikZone>;
  /** fetch data from the table in a streaming manner: "manager_bilik_person_bilik_zone" */
  managerBilikPersonBilikZoneStream: Array<ManagerBilikPersonBilikZone>;
  /** fetch data from the table: "member_bilik_person_bilik_zone" */
  memberBilikPersonBilikZone: Array<MemberBilikPersonBilikZone>;
  /** fetch aggregated fields from the table: "member_bilik_person_bilik_zone" */
  memberBilikPersonBilikZoneAggregate: MemberBilikPersonBilikZoneAggregate;
  /** fetch data from the table: "member_bilik_person_bilik_zone" using primary key columns */
  memberBilikPersonBilikZoneByPk?: Maybe<MemberBilikPersonBilikZone>;
  /** fetch data from the table in a streaming manner: "member_bilik_person_bilik_zone" */
  memberBilikPersonBilikZoneStream: Array<MemberBilikPersonBilikZone>;
  /** fetch data from the table: "note_identity" */
  noteIdentity: Array<NoteIdentity>;
  /** fetch aggregated fields from the table: "note_identity" */
  noteIdentityAggregate: NoteIdentityAggregate;
  /** fetch data from the table: "note_identity" using primary key columns */
  noteIdentityByPk?: Maybe<NoteIdentity>;
  /** fetch data from the table in a streaming manner: "note_identity" */
  noteIdentityStream: Array<NoteIdentity>;
  /** fetch data from the table: "open_data_city" */
  openDataCity: Array<OpenDataCity>;
  /** fetch aggregated fields from the table: "open_data_city" */
  openDataCityAggregate: OpenDataCityAggregate;
  /** fetch data from the table: "open_data_city" using primary key columns */
  openDataCityByPk?: Maybe<OpenDataCity>;
  /** fetch data from the table in a streaming manner: "open_data_city" */
  openDataCityStream: Array<OpenDataCity>;
  /** fetch data from the table: "open_data_department" */
  openDataDepartment: Array<OpenDataDepartment>;
  /** fetch aggregated fields from the table: "open_data_department" */
  openDataDepartmentAggregate: OpenDataDepartmentAggregate;
  /** fetch data from the table: "open_data_department" using primary key columns */
  openDataDepartmentByPk?: Maybe<OpenDataDepartment>;
  /** fetch data from the table in a streaming manner: "open_data_department" */
  openDataDepartmentStream: Array<OpenDataDepartment>;
  /** fetch data from the table: "open_data_region" */
  openDataRegion: Array<OpenDataRegion>;
  /** fetch aggregated fields from the table: "open_data_region" */
  openDataRegionAggregate: OpenDataRegionAggregate;
  /** fetch data from the table: "open_data_region" using primary key columns */
  openDataRegionByPk?: Maybe<OpenDataRegion>;
  /** fetch data from the table in a streaming manner: "open_data_region" */
  openDataRegionStream: Array<OpenDataRegion>;
  /** fetch data from the table: "private_individual" */
  privateIndividual: Array<PrivateIndividual>;
  /** fetch aggregated fields from the table: "private_individual" */
  privateIndividualAggregate: PrivateIndividualAggregate;
  /** fetch data from the table in a streaming manner: "private_individual" */
  privateIndividualStream: Array<PrivateIndividual>;
  /** fetch data from the table: "pro_certification" */
  proCertification: Array<ProCertification>;
  /** fetch aggregated fields from the table: "pro_certification" */
  proCertificationAggregate: ProCertificationAggregate;
  /** fetch data from the table: "pro_certification" using primary key columns */
  proCertificationByPk?: Maybe<ProCertification>;
  /** fetch data from the table in a streaming manner: "pro_certification" */
  proCertificationStream: Array<ProCertification>;
  /** fetch data from the table: "pro_holiday" */
  proHoliday: Array<ProHoliday>;
  /** fetch aggregated fields from the table: "pro_holiday" */
  proHolidayAggregate: ProHolidayAggregate;
  /** fetch data from the table: "pro_holiday" using primary key columns */
  proHolidayByPk?: Maybe<ProHoliday>;
  /** fetch data from the table in a streaming manner: "pro_holiday" */
  proHolidayStream: Array<ProHoliday>;
  /** fetch data from the table: "pro_intervention" */
  proIntervention: Array<ProIntervention>;
  /** fetch aggregated fields from the table: "pro_intervention" */
  proInterventionAggregate: ProInterventionAggregate;
  /** fetch data from the table: "pro_intervention" using primary key columns */
  proInterventionByPk?: Maybe<ProIntervention>;
  /** fetch data from the table in a streaming manner: "pro_intervention" */
  proInterventionStream: Array<ProIntervention>;
  /** fetch data from the table: "pro_label" */
  proLabel: Array<ProLabel>;
  /** fetch aggregated fields from the table: "pro_label" */
  proLabelAggregate: ProLabelAggregate;
  /** fetch data from the table: "pro_label" using primary key columns */
  proLabelByPk?: Maybe<ProLabel>;
  /** fetch data from the table in a streaming manner: "pro_label" */
  proLabelStream: Array<ProLabel>;
  /** fetch data from the table: "pro_mandate" */
  proMandate: Array<ProMandate>;
  /** fetch aggregated fields from the table: "pro_mandate" */
  proMandateAggregate: ProMandateAggregate;
  /** fetch data from the table: "pro_mandate" using primary key columns */
  proMandateByPk?: Maybe<ProMandate>;
  /** fetch data from the table in a streaming manner: "pro_mandate" */
  proMandateStream: Array<ProMandate>;
  /** fetch data from the table: "pro_media_object" */
  proMediaObject: Array<ProMediaObject>;
  /** fetch aggregated fields from the table: "pro_media_object" */
  proMediaObjectAggregate: ProMediaObjectAggregate;
  /** fetch data from the table: "pro_media_object" using primary key columns */
  proMediaObjectByPk?: Maybe<ProMediaObject>;
  /** fetch data from the table in a streaming manner: "pro_media_object" */
  proMediaObjectStream: Array<ProMediaObject>;
  /** fetch data from the table: "pro_message" */
  proMessage: Array<ProMessage>;
  /** fetch aggregated fields from the table: "pro_message" */
  proMessageAggregate: ProMessageAggregate;
  /** fetch data from the table: "pro_message" using primary key columns */
  proMessageByPk?: Maybe<ProMessage>;
  /** fetch data from the table in a streaming manner: "pro_message" */
  proMessageStream: Array<ProMessage>;
  /** fetch data from the table: "pro_organization" */
  proOrganization: Array<ProOrganization>;
  /** fetch aggregated fields from the table: "pro_organization" */
  proOrganizationAggregate: ProOrganizationAggregate;
  /** fetch data from the table: "pro_organization" using primary key columns */
  proOrganizationByPk?: Maybe<ProOrganization>;
  /** fetch data from the table: "pro_organization_first_date_published" */
  proOrganizationFirstDatePublished: Array<ProOrganizationFirstDatePublished>;
  /** fetch aggregated fields from the table: "pro_organization_first_date_published" */
  proOrganizationFirstDatePublishedAggregate: ProOrganizationFirstDatePublishedAggregate;
  /** fetch data from the table in a streaming manner: "pro_organization_first_date_published" */
  proOrganizationFirstDatePublishedStream: Array<ProOrganizationFirstDatePublished>;
  /** fetch data from the table in a streaming manner: "pro_organization" */
  proOrganizationStream: Array<ProOrganization>;
  /** fetch data from the table: "pro_person" */
  proPerson: Array<ProPerson>;
  /** fetch aggregated fields from the table: "pro_person" */
  proPersonAggregate: ProPersonAggregate;
  /** fetch data from the table: "pro_person" using primary key columns */
  proPersonByPk?: Maybe<ProPerson>;
  /** fetch data from the table in a streaming manner: "pro_person" */
  proPersonStream: Array<ProPerson>;
  /** fetch data from the table: "pro_postal_address" */
  proPostalAddress: Array<ProPostalAddress>;
  /** fetch aggregated fields from the table: "pro_postal_address" */
  proPostalAddressAggregate: ProPostalAddressAggregate;
  /** fetch data from the table: "pro_postal_address" using primary key columns */
  proPostalAddressByPk?: Maybe<ProPostalAddress>;
  /** fetch data from the table in a streaming manner: "pro_postal_address" */
  proPostalAddressStream: Array<ProPostalAddress>;
  /** fetch data from the table: "pro_presentation" */
  proPresentation: Array<ProPresentation>;
  /** fetch aggregated fields from the table: "pro_presentation" */
  proPresentationAggregate: ProPresentationAggregate;
  /** fetch data from the table: "pro_presentation" using primary key columns */
  proPresentationByPk?: Maybe<ProPresentation>;
  /** fetch data from the table in a streaming manner: "pro_presentation" */
  proPresentationStream: Array<ProPresentation>;
  /** fetch data from the table: "pro_price" */
  proPrice: Array<ProPrice>;
  /** fetch aggregated fields from the table: "pro_price" */
  proPriceAggregate: ProPriceAggregate;
  /** fetch data from the table: "pro_price" using primary key columns */
  proPriceByPk?: Maybe<ProPrice>;
  /** fetch data from the table in a streaming manner: "pro_price" */
  proPriceStream: Array<ProPrice>;
  /** fetch data from the table: "pro_review" */
  proReview: Array<ProReview>;
  /** fetch aggregated fields from the table: "pro_review" */
  proReviewAggregate: ProReviewAggregate;
  /** fetch data from the table: "pro_review" using primary key columns */
  proReviewByPk?: Maybe<ProReview>;
  /** fetch data from the table: "pro_review_invitation" */
  proReviewInvitation: Array<ProReviewInvitation>;
  /** fetch aggregated fields from the table: "pro_review_invitation" */
  proReviewInvitationAggregate: ProReviewInvitationAggregate;
  /** fetch data from the table: "pro_review_invitation" using primary key columns */
  proReviewInvitationByPk?: Maybe<ProReviewInvitation>;
  /** fetch data from the table in a streaming manner: "pro_review_invitation" */
  proReviewInvitationStream: Array<ProReviewInvitation>;
  /** fetch data from the table in a streaming manner: "pro_review" */
  proReviewStream: Array<ProReview>;
  /** fetch data from the table: "pro_view" */
  proView: Array<ProView>;
  /** fetch aggregated fields from the table: "pro_view" */
  proViewAggregate: ProViewAggregate;
  /** fetch data from the table: "pro_view" using primary key columns */
  proViewByPk?: Maybe<ProView>;
  /** fetch data from the table: "pro_view_city_distance" */
  proViewCityDistance: Array<ProViewCityDistance>;
  /** fetch aggregated fields from the table: "pro_view_city_distance" */
  proViewCityDistanceAggregate: ProViewCityDistanceAggregate;
  /** fetch data from the table in a streaming manner: "pro_view_city_distance" */
  proViewCityDistanceStream: Array<ProViewCityDistance>;
  /** fetch data from the table: "pro_view_pro_person" */
  proViewProPerson: Array<ProViewProPerson>;
  /** fetch aggregated fields from the table: "pro_view_pro_person" */
  proViewProPersonAggregate: ProViewProPersonAggregate;
  /** fetch data from the table: "pro_view_pro_person" using primary key columns */
  proViewProPersonByPk?: Maybe<ProViewProPerson>;
  /** fetch data from the table in a streaming manner: "pro_view_pro_person" */
  proViewProPersonStream: Array<ProViewProPerson>;
  /** fetch data from the table: "pro_view_pro_view" */
  proViewProView: Array<ProViewProView>;
  /** fetch aggregated fields from the table: "pro_view_pro_view" */
  proViewProViewAggregate: ProViewProViewAggregate;
  /** fetch data from the table: "pro_view_pro_view" using primary key columns */
  proViewProViewByPk?: Maybe<ProViewProView>;
  /** fetch data from the table in a streaming manner: "pro_view_pro_view" */
  proViewProViewStream: Array<ProViewProView>;
  /** fetch data from the table in a streaming manner: "pro_view" */
  proViewStream: Array<ProView>;
  /** fetch data from the table: "queued_sms" */
  queuedSms: Array<QueuedSms>;
  /** An aggregate relationship */
  queuedSmsAggregate: QueuedSmsAggregate;
  /** fetch data from the table: "queued_sms" using primary key columns */
  queuedSmsByPk?: Maybe<QueuedSms>;
  /** fetch data from the table in a streaming manner: "queued_sms" */
  queuedSmsStream: Array<QueuedSms>;
  /** execute function "random_pro_views" which returns "pro_view" */
  randomProViews: Array<ProView>;
  /** execute function "random_pro_views" and query aggregates on result of table type "pro_view" */
  randomProViewsAggregate: ProViewAggregate;
  /** execute function "search_account" which returns "account" */
  searchAccount: Array<Account>;
  /** execute function "search_account" and query aggregates on result of table type "account" */
  searchAccountAggregate: AccountAggregate;
  /** execute function "search_nearest_city" which returns "city" */
  searchNearestCity: Array<City>;
  /** execute function "search_nearest_city" and query aggregates on result of table type "city" */
  searchNearestCityAggregate: CityAggregate;
  /** execute function "search_nearest_pro_review" which returns "pro_review" */
  searchNearestProReview: Array<ProReview>;
  /** execute function "search_nearest_pro_review" and query aggregates on result of table type "pro_review" */
  searchNearestProReviewAggregate: ProReviewAggregate;
  /** execute function "search_nearest_pro_view" which returns "pro_view" */
  searchNearestProView: Array<ProView>;
  /** execute function "search_nearest_pro_view" and query aggregates on result of table type "pro_view" */
  searchNearestProViewAggregate: ProViewAggregate;
  /** execute function "search_pro_person" which returns "pro_person" */
  searchProPerson: Array<ProPerson>;
  /** execute function "search_pro_person" and query aggregates on result of table type "pro_person" */
  searchProPersonAggregate: ProPersonAggregate;
  /** execute function "search_pro_presentation" which returns "pro_presentation" */
  searchProPresentation: Array<ProPresentation>;
  /** execute function "search_pro_presentation" and query aggregates on result of table type "pro_presentation" */
  searchProPresentationAggregate: ProPresentationAggregate;
  /** execute function "search_pro_review" which returns "pro_review" */
  searchProReview: Array<ProReview>;
  /** execute function "search_pro_review" and query aggregates on result of table type "pro_review" */
  searchProReviewAggregate: ProReviewAggregate;
  /** execute function "search_pro_review_invitation" which returns "pro_review_invitation" */
  searchProReviewInvitation: Array<ProReviewInvitation>;
  /** execute function "search_pro_review_invitation" and query aggregates on result of table type "pro_review_invitation" */
  searchProReviewInvitationAggregate: ProReviewInvitationAggregate;
  /** execute function "search_pro_view" which returns "pro_view" */
  searchProView: Array<ProView>;
  /** execute function "search_pro_view" and query aggregates on result of table type "pro_view" */
  searchProViewAggregate: ProViewAggregate;
  /** execute function "search_solicitation" which returns "solicitation" */
  searchSolicitation: Array<Solicitation>;
  /** execute function "search_solicitation" and query aggregates on result of table type "solicitation" */
  searchSolicitationAggregate: SolicitationAggregate;
  /** execute function "search_trade" which returns "trade" */
  searchTrade: Array<Trade>;
  /** execute function "search_trade" and query aggregates on result of table type "trade" */
  searchTradeAggregate: TradeAggregate;
  /** execute function "search_trade_group" which returns "trade_group" */
  searchTradeGroup: Array<TradeGroup>;
  /** execute function "search_trade_group" and query aggregates on result of table type "trade_group" */
  searchTradeGroupAggregate: TradeGroupAggregate;
  /** fetch data from the table: "sitemap_bilik_zone" */
  sitemapBilikZone: Array<SitemapBilikZone>;
  /** fetch aggregated fields from the table: "sitemap_bilik_zone" */
  sitemapBilikZoneAggregate: SitemapBilikZoneAggregate;
  /** fetch data from the table in a streaming manner: "sitemap_bilik_zone" */
  sitemapBilikZoneStream: Array<SitemapBilikZone>;
  /** fetch data from the table: "sitemap_city" */
  sitemapCity: Array<SitemapCity>;
  /** fetch aggregated fields from the table: "sitemap_city" */
  sitemapCityAggregate: SitemapCityAggregate;
  /** fetch data from the table in a streaming manner: "sitemap_city" */
  sitemapCityStream: Array<SitemapCity>;
  /** fetch data from the table: "slug_redirect" */
  slugRedirect: Array<SlugRedirect>;
  /** fetch aggregated fields from the table: "slug_redirect" */
  slugRedirectAggregate: SlugRedirectAggregate;
  /** fetch data from the table: "slug_redirect" using primary key columns */
  slugRedirectByPk?: Maybe<SlugRedirect>;
  /** fetch data from the table in a streaming manner: "slug_redirect" */
  slugRedirectStream: Array<SlugRedirect>;
  /** fetch data from the table: "solicitation" */
  solicitation: Array<Solicitation>;
  /** fetch aggregated fields from the table: "solicitation" */
  solicitationAggregate: SolicitationAggregate;
  /** fetch data from the table: "solicitation" using primary key columns */
  solicitationByPk?: Maybe<Solicitation>;
  /** fetch data from the table in a streaming manner: "solicitation" */
  solicitationStream: Array<Solicitation>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatialRefSys: Array<SpatialRefSys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatialRefSysAggregate: SpatialRefSysAggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatialRefSysByPk?: Maybe<SpatialRefSys>;
  /** fetch data from the table in a streaming manner: "spatial_ref_sys" */
  spatialRefSysStream: Array<SpatialRefSys>;
  /** fetch data from the table: "stop_sms" */
  stopSms: Array<StopSms>;
  /** fetch aggregated fields from the table: "stop_sms" */
  stopSmsAggregate: StopSmsAggregate;
  /** fetch data from the table: "stop_sms" using primary key columns */
  stopSmsByPk?: Maybe<StopSms>;
  /** fetch data from the table in a streaming manner: "stop_sms" */
  stopSmsStream: Array<StopSms>;
  /** fetch data from the table: "timeline_private_individual" */
  timelinePrivateIndividual: Array<TimelinePrivateIndividual>;
  /** fetch aggregated fields from the table: "timeline_private_individual" */
  timelinePrivateIndividualAggregate: TimelinePrivateIndividualAggregate;
  /** fetch data from the table in a streaming manner: "timeline_private_individual" */
  timelinePrivateIndividualStream: Array<TimelinePrivateIndividual>;
  /** fetch data from the table: "topology.layer" */
  topologyLayer: Array<TopologyLayer>;
  /** fetch aggregated fields from the table: "topology.layer" */
  topologyLayerAggregate: TopologyLayerAggregate;
  /** fetch data from the table: "topology.layer" using primary key columns */
  topologyLayerByPk?: Maybe<TopologyLayer>;
  /** fetch data from the table in a streaming manner: "topology.layer" */
  topologyLayerStream: Array<TopologyLayer>;
  /** fetch data from the table: "topology.topology" */
  topologyTopology: Array<TopologyTopology>;
  /** fetch aggregated fields from the table: "topology.topology" */
  topologyTopologyAggregate: TopologyTopologyAggregate;
  /** fetch data from the table: "topology.topology" using primary key columns */
  topologyTopologyByPk?: Maybe<TopologyTopology>;
  /** fetch data from the table in a streaming manner: "topology.topology" */
  topologyTopologyStream: Array<TopologyTopology>;
  /** fetch data from the table: "trade" */
  trade: Array<Trade>;
  /** fetch aggregated fields from the table: "trade" */
  tradeAggregate: TradeAggregate;
  /** fetch data from the table: "trade" using primary key columns */
  tradeByPk?: Maybe<Trade>;
  /** fetch data from the table: "trade_group" */
  tradeGroup: Array<TradeGroup>;
  /** fetch aggregated fields from the table: "trade_group" */
  tradeGroupAggregate: TradeGroupAggregate;
  /** fetch data from the table: "trade_group" using primary key columns */
  tradeGroupByPk?: Maybe<TradeGroup>;
  /** fetch data from the table in a streaming manner: "trade_group" */
  tradeGroupStream: Array<TradeGroup>;
  /** fetch data from the table: "trade_guide" */
  tradeGuide: Array<TradeGuide>;
  /** fetch aggregated fields from the table: "trade_guide" */
  tradeGuideAggregate: TradeGuideAggregate;
  /** fetch data from the table: "trade_guide" using primary key columns */
  tradeGuideByPk?: Maybe<TradeGuide>;
  /** fetch data from the table in a streaming manner: "trade_guide" */
  tradeGuideStream: Array<TradeGuide>;
  /** fetch data from the table: "trade_keyword" */
  tradeKeyword: Array<TradeKeyword>;
  /** fetch aggregated fields from the table: "trade_keyword" */
  tradeKeywordAggregate: TradeKeywordAggregate;
  /** fetch data from the table: "trade_keyword" using primary key columns */
  tradeKeywordByPk?: Maybe<TradeKeyword>;
  /** fetch data from the table in a streaming manner: "trade_keyword" */
  tradeKeywordStream: Array<TradeKeyword>;
  /** fetch data from the table: "trade_main_pro_count" */
  tradeMainProCount: Array<TradeMainProCount>;
  /** fetch aggregated fields from the table: "trade_main_pro_count" */
  tradeMainProCountAggregate: TradeMainProCountAggregate;
  /** fetch data from the table in a streaming manner: "trade_main_pro_count" */
  tradeMainProCountStream: Array<TradeMainProCount>;
  /** fetch data from the table: "trade_pro_view" */
  tradeProView: Array<TradeProView>;
  /** fetch aggregated fields from the table: "trade_pro_view" */
  tradeProViewAggregate: TradeProViewAggregate;
  /** fetch data from the table: "trade_pro_view" using primary key columns */
  tradeProViewByPk?: Maybe<TradeProView>;
  /** fetch data from the table in a streaming manner: "trade_pro_view" */
  tradeProViewStream: Array<TradeProView>;
  /** fetch data from the table: "trade_question" */
  tradeQuestion: Array<TradeQuestion>;
  /** fetch aggregated fields from the table: "trade_question" */
  tradeQuestionAggregate: TradeQuestionAggregate;
  /** fetch data from the table: "trade_question" using primary key columns */
  tradeQuestionByPk?: Maybe<TradeQuestion>;
  /** fetch data from the table in a streaming manner: "trade_question" */
  tradeQuestionStream: Array<TradeQuestion>;
  /** fetch data from the table in a streaming manner: "trade" */
  tradeStream: Array<Trade>;
  /** fetch data from the table: "trade_trade_group" */
  tradeTradeGroup: Array<TradeTradeGroup>;
  /** fetch aggregated fields from the table: "trade_trade_group" */
  tradeTradeGroupAggregate: TradeTradeGroupAggregate;
  /** fetch data from the table: "trade_trade_group" using primary key columns */
  tradeTradeGroupByPk?: Maybe<TradeTradeGroup>;
  /** fetch data from the table in a streaming manner: "trade_trade_group" */
  tradeTradeGroupStream: Array<TradeTradeGroup>;
  /** fetch data from the table: "zoho_consumer" */
  zohoConsumer: Array<ZohoConsumer>;
  /** fetch aggregated fields from the table: "zoho_consumer" */
  zohoConsumerAggregate: ZohoConsumerAggregate;
  /** fetch data from the table: "zoho_consumer" using primary key columns */
  zohoConsumerByPk?: Maybe<ZohoConsumer>;
  /** fetch data from the table in a streaming manner: "zoho_consumer" */
  zohoConsumerStream: Array<ZohoConsumer>;
  /** fetch data from the table: "zoho_organization" */
  zohoOrganization: Array<ZohoOrganization>;
  /** fetch aggregated fields from the table: "zoho_organization" */
  zohoOrganizationAggregate: ZohoOrganizationAggregate;
  /** fetch data from the table: "zoho_organization" using primary key columns */
  zohoOrganizationByPk?: Maybe<ZohoOrganization>;
  /** fetch data from the table in a streaming manner: "zoho_organization" */
  zohoOrganizationStream: Array<ZohoOrganization>;
  /** fetch data from the table: "zoho_token" */
  zohoToken: Array<ZohoToken>;
  /** fetch aggregated fields from the table: "zoho_token" */
  zohoTokenAggregate: ZohoTokenAggregate;
  /** fetch data from the table: "zoho_token" using primary key columns */
  zohoTokenByPk?: Maybe<ZohoToken>;
  /** fetch data from the table in a streaming manner: "zoho_token" */
  zohoTokenStream: Array<ZohoToken>;
};


export type SubscriptionRootAccountArgs = {
  distinctOn?: Maybe<Array<AccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountOrderBy>>;
  where?: Maybe<AccountBoolExp>;
};


export type SubscriptionRootAccountAggregateArgs = {
  distinctOn?: Maybe<Array<AccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountOrderBy>>;
  where?: Maybe<AccountBoolExp>;
};


export type SubscriptionRootAccountByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootAccountDeviceArgs = {
  distinctOn?: Maybe<Array<AccountDeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountDeviceOrderBy>>;
  where?: Maybe<AccountDeviceBoolExp>;
};


export type SubscriptionRootAccountDeviceAggregateArgs = {
  distinctOn?: Maybe<Array<AccountDeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountDeviceOrderBy>>;
  where?: Maybe<AccountDeviceBoolExp>;
};


export type SubscriptionRootAccountDeviceByPkArgs = {
  accountId: Scalars['Int'];
  deviceId: Scalars['Int'];
};


export type SubscriptionRootAccountDeviceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<AccountDeviceStreamCursorInput>>;
  where?: Maybe<AccountDeviceBoolExp>;
};


export type SubscriptionRootAccountProViewArgs = {
  distinctOn?: Maybe<Array<AccountProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountProViewOrderBy>>;
  where?: Maybe<AccountProViewBoolExp>;
};


export type SubscriptionRootAccountProViewAggregateArgs = {
  distinctOn?: Maybe<Array<AccountProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountProViewOrderBy>>;
  where?: Maybe<AccountProViewBoolExp>;
};


export type SubscriptionRootAccountProViewByPkArgs = {
  accountId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


export type SubscriptionRootAccountProViewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<AccountProViewStreamCursorInput>>;
  where?: Maybe<AccountProViewBoolExp>;
};


export type SubscriptionRootAccountStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<AccountStreamCursorInput>>;
  where?: Maybe<AccountBoolExp>;
};


export type SubscriptionRootActionTokenArgs = {
  distinctOn?: Maybe<Array<ActionTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ActionTokenOrderBy>>;
  where?: Maybe<ActionTokenBoolExp>;
};


export type SubscriptionRootActionTokenAggregateArgs = {
  distinctOn?: Maybe<Array<ActionTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ActionTokenOrderBy>>;
  where?: Maybe<ActionTokenBoolExp>;
};


export type SubscriptionRootActionTokenByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootActionTokenStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ActionTokenStreamCursorInput>>;
  where?: Maybe<ActionTokenBoolExp>;
};


export type SubscriptionRootAutorespondArgs = {
  distinctOn?: Maybe<Array<AutorespondSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AutorespondOrderBy>>;
  where?: Maybe<AutorespondBoolExp>;
};


export type SubscriptionRootAutorespondAggregateArgs = {
  distinctOn?: Maybe<Array<AutorespondSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AutorespondOrderBy>>;
  where?: Maybe<AutorespondBoolExp>;
};


export type SubscriptionRootAutorespondByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootAutorespondStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<AutorespondStreamCursorInput>>;
  where?: Maybe<AutorespondBoolExp>;
};


export type SubscriptionRootBilikPersonArgs = {
  distinctOn?: Maybe<Array<BilikPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikPersonOrderBy>>;
  where?: Maybe<BilikPersonBoolExp>;
};


export type SubscriptionRootBilikPersonAggregateArgs = {
  distinctOn?: Maybe<Array<BilikPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikPersonOrderBy>>;
  where?: Maybe<BilikPersonBoolExp>;
};


export type SubscriptionRootBilikPersonByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootBilikPersonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<BilikPersonStreamCursorInput>>;
  where?: Maybe<BilikPersonBoolExp>;
};


export type SubscriptionRootBilikSolicitationArgs = {
  distinctOn?: Maybe<Array<BilikSolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikSolicitationOrderBy>>;
  where?: Maybe<BilikSolicitationBoolExp>;
};


export type SubscriptionRootBilikSolicitationAggregateArgs = {
  distinctOn?: Maybe<Array<BilikSolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikSolicitationOrderBy>>;
  where?: Maybe<BilikSolicitationBoolExp>;
};


export type SubscriptionRootBilikSolicitationByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootBilikSolicitationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<BilikSolicitationStreamCursorInput>>;
  where?: Maybe<BilikSolicitationBoolExp>;
};


export type SubscriptionRootBilikZoneArgs = {
  distinctOn?: Maybe<Array<BilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneOrderBy>>;
  where?: Maybe<BilikZoneBoolExp>;
};


export type SubscriptionRootBilikZoneAggregateArgs = {
  distinctOn?: Maybe<Array<BilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneOrderBy>>;
  where?: Maybe<BilikZoneBoolExp>;
};


export type SubscriptionRootBilikZoneByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootBilikZoneStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<BilikZoneStreamCursorInput>>;
  where?: Maybe<BilikZoneBoolExp>;
};


export type SubscriptionRootBilikZoneTradeMainProCountArgs = {
  distinctOn?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneTradeMainProCountOrderBy>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};


export type SubscriptionRootBilikZoneTradeMainProCountAggregateArgs = {
  distinctOn?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneTradeMainProCountOrderBy>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};


export type SubscriptionRootBilikZoneTradeMainProCountStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<BilikZoneTradeMainProCountStreamCursorInput>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};


export type SubscriptionRootCertificationArgs = {
  distinctOn?: Maybe<Array<CertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CertificationOrderBy>>;
  where?: Maybe<CertificationBoolExp>;
};


export type SubscriptionRootCertificationAggregateArgs = {
  distinctOn?: Maybe<Array<CertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CertificationOrderBy>>;
  where?: Maybe<CertificationBoolExp>;
};


export type SubscriptionRootCertificationByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootCertificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<CertificationStreamCursorInput>>;
  where?: Maybe<CertificationBoolExp>;
};


export type SubscriptionRootCityArgs = {
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


export type SubscriptionRootCityAggregateArgs = {
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


export type SubscriptionRootCityByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootCityProViewArgs = {
  distinctOn?: Maybe<Array<CityProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityProViewOrderBy>>;
  where?: Maybe<CityProViewBoolExp>;
};


export type SubscriptionRootCityProViewAggregateArgs = {
  distinctOn?: Maybe<Array<CityProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityProViewOrderBy>>;
  where?: Maybe<CityProViewBoolExp>;
};


export type SubscriptionRootCityProViewByPkArgs = {
  cityId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


export type SubscriptionRootCityProViewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<CityProViewStreamCursorInput>>;
  where?: Maybe<CityProViewBoolExp>;
};


export type SubscriptionRootCityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<CityStreamCursorInput>>;
  where?: Maybe<CityBoolExp>;
};


export type SubscriptionRootCountProReviewByStatusArgs = {
  distinctOn?: Maybe<Array<CountProReviewByStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewByStatusOrderBy>>;
  where?: Maybe<CountProReviewByStatusBoolExp>;
};


export type SubscriptionRootCountProReviewByStatusAggregateArgs = {
  distinctOn?: Maybe<Array<CountProReviewByStatusSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewByStatusOrderBy>>;
  where?: Maybe<CountProReviewByStatusBoolExp>;
};


export type SubscriptionRootCountProReviewByStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<CountProReviewByStatusStreamCursorInput>>;
  where?: Maybe<CountProReviewByStatusBoolExp>;
};


export type SubscriptionRootCountProReviewInvitationsByMonthByProPresentationArgs = {
  distinctOn?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationOrderBy>>;
  where?: Maybe<CountProReviewInvitationsByMonthByProPresentationBoolExp>;
};


export type SubscriptionRootCountProReviewInvitationsByMonthByProPresentationAggregateArgs = {
  distinctOn?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewInvitationsByMonthByProPresentationOrderBy>>;
  where?: Maybe<CountProReviewInvitationsByMonthByProPresentationBoolExp>;
};


export type SubscriptionRootCountProReviewInvitationsByMonthByProPresentationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<CountProReviewInvitationsByMonthByProPresentationStreamCursorInput>>;
  where?: Maybe<CountProReviewInvitationsByMonthByProPresentationBoolExp>;
};


export type SubscriptionRootCountProReviewsWithStatusOkByMonthByProPresentationArgs = {
  distinctOn?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationOrderBy>>;
  where?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationBoolExp>;
};


export type SubscriptionRootCountProReviewsWithStatusOkByMonthByProPresentationAggregateArgs = {
  distinctOn?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CountProReviewsWithStatusOkByMonthByProPresentationOrderBy>>;
  where?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationBoolExp>;
};


export type SubscriptionRootCountProReviewsWithStatusOkByMonthByProPresentationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<CountProReviewsWithStatusOkByMonthByProPresentationStreamCursorInput>>;
  where?: Maybe<CountProReviewsWithStatusOkByMonthByProPresentationBoolExp>;
};


export type SubscriptionRootDeviceArgs = {
  distinctOn?: Maybe<Array<DeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DeviceOrderBy>>;
  where?: Maybe<DeviceBoolExp>;
};


export type SubscriptionRootDeviceAggregateArgs = {
  distinctOn?: Maybe<Array<DeviceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DeviceOrderBy>>;
  where?: Maybe<DeviceBoolExp>;
};


export type SubscriptionRootDeviceByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootDeviceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<DeviceStreamCursorInput>>;
  where?: Maybe<DeviceBoolExp>;
};


export type SubscriptionRootGeographyColumnsArgs = {
  distinctOn?: Maybe<Array<GeographyColumnsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GeographyColumnsOrderBy>>;
  where?: Maybe<GeographyColumnsBoolExp>;
};


export type SubscriptionRootGeographyColumnsAggregateArgs = {
  distinctOn?: Maybe<Array<GeographyColumnsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GeographyColumnsOrderBy>>;
  where?: Maybe<GeographyColumnsBoolExp>;
};


export type SubscriptionRootGeographyColumnsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<GeographyColumnsStreamCursorInput>>;
  where?: Maybe<GeographyColumnsBoolExp>;
};


export type SubscriptionRootGeometryColumnsArgs = {
  distinctOn?: Maybe<Array<GeometryColumnsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GeometryColumnsOrderBy>>;
  where?: Maybe<GeometryColumnsBoolExp>;
};


export type SubscriptionRootGeometryColumnsAggregateArgs = {
  distinctOn?: Maybe<Array<GeometryColumnsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GeometryColumnsOrderBy>>;
  where?: Maybe<GeometryColumnsBoolExp>;
};


export type SubscriptionRootGeometryColumnsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<GeometryColumnsStreamCursorInput>>;
  where?: Maybe<GeometryColumnsBoolExp>;
};


export type SubscriptionRootGloballyBlockedIdentityArgs = {
  distinctOn?: Maybe<Array<GloballyBlockedIdentitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GloballyBlockedIdentityOrderBy>>;
  where?: Maybe<GloballyBlockedIdentityBoolExp>;
};


export type SubscriptionRootGloballyBlockedIdentityAggregateArgs = {
  distinctOn?: Maybe<Array<GloballyBlockedIdentitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GloballyBlockedIdentityOrderBy>>;
  where?: Maybe<GloballyBlockedIdentityBoolExp>;
};


export type SubscriptionRootGloballyBlockedIdentityByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootGloballyBlockedIdentityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<GloballyBlockedIdentityStreamCursorInput>>;
  where?: Maybe<GloballyBlockedIdentityBoolExp>;
};


export type SubscriptionRootGoogleReviewLinkArgs = {
  distinctOn?: Maybe<Array<GoogleReviewLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GoogleReviewLinkOrderBy>>;
  where?: Maybe<GoogleReviewLinkBoolExp>;
};


export type SubscriptionRootGoogleReviewLinkAggregateArgs = {
  distinctOn?: Maybe<Array<GoogleReviewLinkSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GoogleReviewLinkOrderBy>>;
  where?: Maybe<GoogleReviewLinkBoolExp>;
};


export type SubscriptionRootGoogleReviewLinkByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootGoogleReviewLinkStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<GoogleReviewLinkStreamCursorInput>>;
  where?: Maybe<GoogleReviewLinkBoolExp>;
};


export type SubscriptionRootGroupedProInterventionArgs = {
  distinctOn?: Maybe<Array<GroupedProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GroupedProInterventionOrderBy>>;
  where?: Maybe<GroupedProInterventionBoolExp>;
};


export type SubscriptionRootGroupedProInterventionAggregateArgs = {
  distinctOn?: Maybe<Array<GroupedProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GroupedProInterventionOrderBy>>;
  where?: Maybe<GroupedProInterventionBoolExp>;
};


export type SubscriptionRootGroupedProInterventionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<GroupedProInterventionStreamCursorInput>>;
  where?: Maybe<GroupedProInterventionBoolExp>;
};


export type SubscriptionRootManagerBilikPersonBilikZoneArgs = {
  distinctOn?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ManagerBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


export type SubscriptionRootManagerBilikPersonBilikZoneAggregateArgs = {
  distinctOn?: Maybe<Array<ManagerBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ManagerBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


export type SubscriptionRootManagerBilikPersonBilikZoneByPkArgs = {
  bilikPersonId: Scalars['Int'];
  bilikZoneId: Scalars['Int'];
};


export type SubscriptionRootManagerBilikPersonBilikZoneStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ManagerBilikPersonBilikZoneStreamCursorInput>>;
  where?: Maybe<ManagerBilikPersonBilikZoneBoolExp>;
};


export type SubscriptionRootMemberBilikPersonBilikZoneArgs = {
  distinctOn?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MemberBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


export type SubscriptionRootMemberBilikPersonBilikZoneAggregateArgs = {
  distinctOn?: Maybe<Array<MemberBilikPersonBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MemberBilikPersonBilikZoneOrderBy>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


export type SubscriptionRootMemberBilikPersonBilikZoneByPkArgs = {
  bilikPersonId: Scalars['Int'];
  bilikZoneId: Scalars['Int'];
};


export type SubscriptionRootMemberBilikPersonBilikZoneStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<MemberBilikPersonBilikZoneStreamCursorInput>>;
  where?: Maybe<MemberBilikPersonBilikZoneBoolExp>;
};


export type SubscriptionRootNoteIdentityArgs = {
  distinctOn?: Maybe<Array<NoteIdentitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<NoteIdentityOrderBy>>;
  where?: Maybe<NoteIdentityBoolExp>;
};


export type SubscriptionRootNoteIdentityAggregateArgs = {
  distinctOn?: Maybe<Array<NoteIdentitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<NoteIdentityOrderBy>>;
  where?: Maybe<NoteIdentityBoolExp>;
};


export type SubscriptionRootNoteIdentityByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootNoteIdentityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<NoteIdentityStreamCursorInput>>;
  where?: Maybe<NoteIdentityBoolExp>;
};


export type SubscriptionRootOpenDataCityArgs = {
  distinctOn?: Maybe<Array<OpenDataCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataCityOrderBy>>;
  where?: Maybe<OpenDataCityBoolExp>;
};


export type SubscriptionRootOpenDataCityAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataCityOrderBy>>;
  where?: Maybe<OpenDataCityBoolExp>;
};


export type SubscriptionRootOpenDataCityByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootOpenDataCityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<OpenDataCityStreamCursorInput>>;
  where?: Maybe<OpenDataCityBoolExp>;
};


export type SubscriptionRootOpenDataDepartmentArgs = {
  distinctOn?: Maybe<Array<OpenDataDepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataDepartmentOrderBy>>;
  where?: Maybe<OpenDataDepartmentBoolExp>;
};


export type SubscriptionRootOpenDataDepartmentAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataDepartmentSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataDepartmentOrderBy>>;
  where?: Maybe<OpenDataDepartmentBoolExp>;
};


export type SubscriptionRootOpenDataDepartmentByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootOpenDataDepartmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<OpenDataDepartmentStreamCursorInput>>;
  where?: Maybe<OpenDataDepartmentBoolExp>;
};


export type SubscriptionRootOpenDataRegionArgs = {
  distinctOn?: Maybe<Array<OpenDataRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataRegionOrderBy>>;
  where?: Maybe<OpenDataRegionBoolExp>;
};


export type SubscriptionRootOpenDataRegionAggregateArgs = {
  distinctOn?: Maybe<Array<OpenDataRegionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OpenDataRegionOrderBy>>;
  where?: Maybe<OpenDataRegionBoolExp>;
};


export type SubscriptionRootOpenDataRegionByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootOpenDataRegionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<OpenDataRegionStreamCursorInput>>;
  where?: Maybe<OpenDataRegionBoolExp>;
};


export type SubscriptionRootPrivateIndividualArgs = {
  distinctOn?: Maybe<Array<PrivateIndividualSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PrivateIndividualOrderBy>>;
  where?: Maybe<PrivateIndividualBoolExp>;
};


export type SubscriptionRootPrivateIndividualAggregateArgs = {
  distinctOn?: Maybe<Array<PrivateIndividualSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PrivateIndividualOrderBy>>;
  where?: Maybe<PrivateIndividualBoolExp>;
};


export type SubscriptionRootPrivateIndividualStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<PrivateIndividualStreamCursorInput>>;
  where?: Maybe<PrivateIndividualBoolExp>;
};


export type SubscriptionRootProCertificationArgs = {
  distinctOn?: Maybe<Array<ProCertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProCertificationOrderBy>>;
  where?: Maybe<ProCertificationBoolExp>;
};


export type SubscriptionRootProCertificationAggregateArgs = {
  distinctOn?: Maybe<Array<ProCertificationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProCertificationOrderBy>>;
  where?: Maybe<ProCertificationBoolExp>;
};


export type SubscriptionRootProCertificationByPkArgs = {
  certificationId: Scalars['Int'];
  proPresentationId: Scalars['Int'];
};


export type SubscriptionRootProCertificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProCertificationStreamCursorInput>>;
  where?: Maybe<ProCertificationBoolExp>;
};


export type SubscriptionRootProHolidayArgs = {
  distinctOn?: Maybe<Array<ProHolidaySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProHolidayOrderBy>>;
  where?: Maybe<ProHolidayBoolExp>;
};


export type SubscriptionRootProHolidayAggregateArgs = {
  distinctOn?: Maybe<Array<ProHolidaySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProHolidayOrderBy>>;
  where?: Maybe<ProHolidayBoolExp>;
};


export type SubscriptionRootProHolidayByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProHolidayStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProHolidayStreamCursorInput>>;
  where?: Maybe<ProHolidayBoolExp>;
};


export type SubscriptionRootProInterventionArgs = {
  distinctOn?: Maybe<Array<ProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProInterventionOrderBy>>;
  where?: Maybe<ProInterventionBoolExp>;
};


export type SubscriptionRootProInterventionAggregateArgs = {
  distinctOn?: Maybe<Array<ProInterventionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProInterventionOrderBy>>;
  where?: Maybe<ProInterventionBoolExp>;
};


export type SubscriptionRootProInterventionByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProInterventionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProInterventionStreamCursorInput>>;
  where?: Maybe<ProInterventionBoolExp>;
};


export type SubscriptionRootProLabelArgs = {
  distinctOn?: Maybe<Array<ProLabelSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProLabelOrderBy>>;
  where?: Maybe<ProLabelBoolExp>;
};


export type SubscriptionRootProLabelAggregateArgs = {
  distinctOn?: Maybe<Array<ProLabelSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProLabelOrderBy>>;
  where?: Maybe<ProLabelBoolExp>;
};


export type SubscriptionRootProLabelByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProLabelStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProLabelStreamCursorInput>>;
  where?: Maybe<ProLabelBoolExp>;
};


export type SubscriptionRootProMandateArgs = {
  distinctOn?: Maybe<Array<ProMandateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMandateOrderBy>>;
  where?: Maybe<ProMandateBoolExp>;
};


export type SubscriptionRootProMandateAggregateArgs = {
  distinctOn?: Maybe<Array<ProMandateSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMandateOrderBy>>;
  where?: Maybe<ProMandateBoolExp>;
};


export type SubscriptionRootProMandateByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProMandateStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProMandateStreamCursorInput>>;
  where?: Maybe<ProMandateBoolExp>;
};


export type SubscriptionRootProMediaObjectArgs = {
  distinctOn?: Maybe<Array<ProMediaObjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMediaObjectOrderBy>>;
  where?: Maybe<ProMediaObjectBoolExp>;
};


export type SubscriptionRootProMediaObjectAggregateArgs = {
  distinctOn?: Maybe<Array<ProMediaObjectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMediaObjectOrderBy>>;
  where?: Maybe<ProMediaObjectBoolExp>;
};


export type SubscriptionRootProMediaObjectByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProMediaObjectStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProMediaObjectStreamCursorInput>>;
  where?: Maybe<ProMediaObjectBoolExp>;
};


export type SubscriptionRootProMessageArgs = {
  distinctOn?: Maybe<Array<ProMessageSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMessageOrderBy>>;
  where?: Maybe<ProMessageBoolExp>;
};


export type SubscriptionRootProMessageAggregateArgs = {
  distinctOn?: Maybe<Array<ProMessageSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProMessageOrderBy>>;
  where?: Maybe<ProMessageBoolExp>;
};


export type SubscriptionRootProMessageByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProMessageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProMessageStreamCursorInput>>;
  where?: Maybe<ProMessageBoolExp>;
};


export type SubscriptionRootProOrganizationArgs = {
  distinctOn?: Maybe<Array<ProOrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProOrganizationOrderBy>>;
  where?: Maybe<ProOrganizationBoolExp>;
};


export type SubscriptionRootProOrganizationAggregateArgs = {
  distinctOn?: Maybe<Array<ProOrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProOrganizationOrderBy>>;
  where?: Maybe<ProOrganizationBoolExp>;
};


export type SubscriptionRootProOrganizationByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProOrganizationFirstDatePublishedArgs = {
  distinctOn?: Maybe<Array<ProOrganizationFirstDatePublishedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProOrganizationFirstDatePublishedOrderBy>>;
  where?: Maybe<ProOrganizationFirstDatePublishedBoolExp>;
};


export type SubscriptionRootProOrganizationFirstDatePublishedAggregateArgs = {
  distinctOn?: Maybe<Array<ProOrganizationFirstDatePublishedSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProOrganizationFirstDatePublishedOrderBy>>;
  where?: Maybe<ProOrganizationFirstDatePublishedBoolExp>;
};


export type SubscriptionRootProOrganizationFirstDatePublishedStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProOrganizationFirstDatePublishedStreamCursorInput>>;
  where?: Maybe<ProOrganizationFirstDatePublishedBoolExp>;
};


export type SubscriptionRootProOrganizationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProOrganizationStreamCursorInput>>;
  where?: Maybe<ProOrganizationBoolExp>;
};


export type SubscriptionRootProPersonArgs = {
  distinctOn?: Maybe<Array<ProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPersonOrderBy>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type SubscriptionRootProPersonAggregateArgs = {
  distinctOn?: Maybe<Array<ProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPersonOrderBy>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type SubscriptionRootProPersonByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProPersonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProPersonStreamCursorInput>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type SubscriptionRootProPostalAddressArgs = {
  distinctOn?: Maybe<Array<ProPostalAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPostalAddressOrderBy>>;
  where?: Maybe<ProPostalAddressBoolExp>;
};


export type SubscriptionRootProPostalAddressAggregateArgs = {
  distinctOn?: Maybe<Array<ProPostalAddressSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPostalAddressOrderBy>>;
  where?: Maybe<ProPostalAddressBoolExp>;
};


export type SubscriptionRootProPostalAddressByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProPostalAddressStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProPostalAddressStreamCursorInput>>;
  where?: Maybe<ProPostalAddressBoolExp>;
};


export type SubscriptionRootProPresentationArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type SubscriptionRootProPresentationAggregateArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type SubscriptionRootProPresentationByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProPresentationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProPresentationStreamCursorInput>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type SubscriptionRootProPriceArgs = {
  distinctOn?: Maybe<Array<ProPriceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPriceOrderBy>>;
  where?: Maybe<ProPriceBoolExp>;
};


export type SubscriptionRootProPriceAggregateArgs = {
  distinctOn?: Maybe<Array<ProPriceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPriceOrderBy>>;
  where?: Maybe<ProPriceBoolExp>;
};


export type SubscriptionRootProPriceByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProPriceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProPriceStreamCursorInput>>;
  where?: Maybe<ProPriceBoolExp>;
};


export type SubscriptionRootProReviewArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type SubscriptionRootProReviewAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type SubscriptionRootProReviewByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProReviewInvitationArgs = {
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type SubscriptionRootProReviewInvitationAggregateArgs = {
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type SubscriptionRootProReviewInvitationByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProReviewInvitationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProReviewInvitationStreamCursorInput>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type SubscriptionRootProReviewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProReviewStreamCursorInput>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type SubscriptionRootProViewArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootProViewAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootProViewByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootProViewCityDistanceArgs = {
  distinctOn?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewCityDistanceOrderBy>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


export type SubscriptionRootProViewCityDistanceAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewCityDistanceSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewCityDistanceOrderBy>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


export type SubscriptionRootProViewCityDistanceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProViewCityDistanceStreamCursorInput>>;
  where?: Maybe<ProViewCityDistanceBoolExp>;
};


export type SubscriptionRootProViewProPersonArgs = {
  distinctOn?: Maybe<Array<ProViewProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProPersonOrderBy>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};


export type SubscriptionRootProViewProPersonAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProPersonOrderBy>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};


export type SubscriptionRootProViewProPersonByPkArgs = {
  proPersonId: Scalars['Int'];
  proViewId: Scalars['Int'];
};


export type SubscriptionRootProViewProPersonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProViewProPersonStreamCursorInput>>;
  where?: Maybe<ProViewProPersonBoolExp>;
};


export type SubscriptionRootProViewProViewArgs = {
  distinctOn?: Maybe<Array<ProViewProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProViewOrderBy>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


export type SubscriptionRootProViewProViewAggregateArgs = {
  distinctOn?: Maybe<Array<ProViewProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewProViewOrderBy>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


export type SubscriptionRootProViewProViewByPkArgs = {
  proViewSource: Scalars['Int'];
  proViewTarget: Scalars['Int'];
};


export type SubscriptionRootProViewProViewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProViewProViewStreamCursorInput>>;
  where?: Maybe<ProViewProViewBoolExp>;
};


export type SubscriptionRootProViewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ProViewStreamCursorInput>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootQueuedSmsArgs = {
  distinctOn?: Maybe<Array<QueuedSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueuedSmsOrderBy>>;
  where?: Maybe<QueuedSmsBoolExp>;
};


export type SubscriptionRootQueuedSmsAggregateArgs = {
  distinctOn?: Maybe<Array<QueuedSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<QueuedSmsOrderBy>>;
  where?: Maybe<QueuedSmsBoolExp>;
};


export type SubscriptionRootQueuedSmsByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootQueuedSmsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<QueuedSmsStreamCursorInput>>;
  where?: Maybe<QueuedSmsBoolExp>;
};


export type SubscriptionRootRandomProViewsArgs = {
  args?: Maybe<RandomProViewsArgs>;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootRandomProViewsAggregateArgs = {
  args?: Maybe<RandomProViewsArgs>;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootSearchAccountArgs = {
  args: SearchAccountArgs;
  distinctOn?: Maybe<Array<AccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountOrderBy>>;
  where?: Maybe<AccountBoolExp>;
};


export type SubscriptionRootSearchAccountAggregateArgs = {
  args: SearchAccountArgs;
  distinctOn?: Maybe<Array<AccountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AccountOrderBy>>;
  where?: Maybe<AccountBoolExp>;
};


export type SubscriptionRootSearchNearestCityArgs = {
  args: SearchNearestCityArgs;
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


export type SubscriptionRootSearchNearestCityAggregateArgs = {
  args: SearchNearestCityArgs;
  distinctOn?: Maybe<Array<CitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CityOrderBy>>;
  where?: Maybe<CityBoolExp>;
};


export type SubscriptionRootSearchNearestProReviewArgs = {
  args: SearchNearestProReviewArgs;
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type SubscriptionRootSearchNearestProReviewAggregateArgs = {
  args: SearchNearestProReviewArgs;
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type SubscriptionRootSearchNearestProViewArgs = {
  args: SearchNearestProViewArgs;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootSearchNearestProViewAggregateArgs = {
  args: SearchNearestProViewArgs;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootSearchProPersonArgs = {
  args: SearchProPersonArgs;
  distinctOn?: Maybe<Array<ProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPersonOrderBy>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type SubscriptionRootSearchProPersonAggregateArgs = {
  args: SearchProPersonArgs;
  distinctOn?: Maybe<Array<ProPersonSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPersonOrderBy>>;
  where?: Maybe<ProPersonBoolExp>;
};


export type SubscriptionRootSearchProPresentationArgs = {
  args: SearchProPresentationArgs;
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type SubscriptionRootSearchProPresentationAggregateArgs = {
  args: SearchProPresentationArgs;
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


export type SubscriptionRootSearchProReviewArgs = {
  args: SearchProReviewArgs;
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type SubscriptionRootSearchProReviewAggregateArgs = {
  args: SearchProReviewArgs;
  distinctOn?: Maybe<Array<ProReviewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewOrderBy>>;
  where?: Maybe<ProReviewBoolExp>;
};


export type SubscriptionRootSearchProReviewInvitationArgs = {
  args: SearchProReviewInvitationArgs;
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type SubscriptionRootSearchProReviewInvitationAggregateArgs = {
  args: SearchProReviewInvitationArgs;
  distinctOn?: Maybe<Array<ProReviewInvitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProReviewInvitationOrderBy>>;
  where?: Maybe<ProReviewInvitationBoolExp>;
};


export type SubscriptionRootSearchProViewArgs = {
  args: SearchProViewArgs;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootSearchProViewAggregateArgs = {
  args: SearchProViewArgs;
  distinctOn?: Maybe<Array<ProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProViewOrderBy>>;
  where?: Maybe<ProViewBoolExp>;
};


export type SubscriptionRootSearchSolicitationArgs = {
  args: SearchSolicitationArgs;
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type SubscriptionRootSearchSolicitationAggregateArgs = {
  args: SearchSolicitationArgs;
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type SubscriptionRootSearchTradeArgs = {
  args: SearchTradeArgs;
  distinctOn?: Maybe<Array<TradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeOrderBy>>;
  where?: Maybe<TradeBoolExp>;
};


export type SubscriptionRootSearchTradeAggregateArgs = {
  args: SearchTradeArgs;
  distinctOn?: Maybe<Array<TradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeOrderBy>>;
  where?: Maybe<TradeBoolExp>;
};


export type SubscriptionRootSearchTradeGroupArgs = {
  args: SearchTradeGroupArgs;
  distinctOn?: Maybe<Array<TradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGroupOrderBy>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type SubscriptionRootSearchTradeGroupAggregateArgs = {
  args: SearchTradeGroupArgs;
  distinctOn?: Maybe<Array<TradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGroupOrderBy>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type SubscriptionRootSitemapBilikZoneArgs = {
  distinctOn?: Maybe<Array<SitemapBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapBilikZoneOrderBy>>;
  where?: Maybe<SitemapBilikZoneBoolExp>;
};


export type SubscriptionRootSitemapBilikZoneAggregateArgs = {
  distinctOn?: Maybe<Array<SitemapBilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapBilikZoneOrderBy>>;
  where?: Maybe<SitemapBilikZoneBoolExp>;
};


export type SubscriptionRootSitemapBilikZoneStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<SitemapBilikZoneStreamCursorInput>>;
  where?: Maybe<SitemapBilikZoneBoolExp>;
};


export type SubscriptionRootSitemapCityArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


export type SubscriptionRootSitemapCityAggregateArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


export type SubscriptionRootSitemapCityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<SitemapCityStreamCursorInput>>;
  where?: Maybe<SitemapCityBoolExp>;
};


export type SubscriptionRootSlugRedirectArgs = {
  distinctOn?: Maybe<Array<SlugRedirectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SlugRedirectOrderBy>>;
  where?: Maybe<SlugRedirectBoolExp>;
};


export type SubscriptionRootSlugRedirectAggregateArgs = {
  distinctOn?: Maybe<Array<SlugRedirectSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SlugRedirectOrderBy>>;
  where?: Maybe<SlugRedirectBoolExp>;
};


export type SubscriptionRootSlugRedirectByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootSlugRedirectStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<SlugRedirectStreamCursorInput>>;
  where?: Maybe<SlugRedirectBoolExp>;
};


export type SubscriptionRootSolicitationArgs = {
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type SubscriptionRootSolicitationAggregateArgs = {
  distinctOn?: Maybe<Array<SolicitationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SolicitationOrderBy>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type SubscriptionRootSolicitationByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootSolicitationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<SolicitationStreamCursorInput>>;
  where?: Maybe<SolicitationBoolExp>;
};


export type SubscriptionRootSpatialRefSysArgs = {
  distinctOn?: Maybe<Array<SpatialRefSysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SpatialRefSysOrderBy>>;
  where?: Maybe<SpatialRefSysBoolExp>;
};


export type SubscriptionRootSpatialRefSysAggregateArgs = {
  distinctOn?: Maybe<Array<SpatialRefSysSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SpatialRefSysOrderBy>>;
  where?: Maybe<SpatialRefSysBoolExp>;
};


export type SubscriptionRootSpatialRefSysByPkArgs = {
  srid: Scalars['Int'];
};


export type SubscriptionRootSpatialRefSysStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<SpatialRefSysStreamCursorInput>>;
  where?: Maybe<SpatialRefSysBoolExp>;
};


export type SubscriptionRootStopSmsArgs = {
  distinctOn?: Maybe<Array<StopSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<StopSmsOrderBy>>;
  where?: Maybe<StopSmsBoolExp>;
};


export type SubscriptionRootStopSmsAggregateArgs = {
  distinctOn?: Maybe<Array<StopSmsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<StopSmsOrderBy>>;
  where?: Maybe<StopSmsBoolExp>;
};


export type SubscriptionRootStopSmsByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootStopSmsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<StopSmsStreamCursorInput>>;
  where?: Maybe<StopSmsBoolExp>;
};


export type SubscriptionRootTimelinePrivateIndividualArgs = {
  distinctOn?: Maybe<Array<TimelinePrivateIndividualSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TimelinePrivateIndividualOrderBy>>;
  where?: Maybe<TimelinePrivateIndividualBoolExp>;
};


export type SubscriptionRootTimelinePrivateIndividualAggregateArgs = {
  distinctOn?: Maybe<Array<TimelinePrivateIndividualSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TimelinePrivateIndividualOrderBy>>;
  where?: Maybe<TimelinePrivateIndividualBoolExp>;
};


export type SubscriptionRootTimelinePrivateIndividualStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TimelinePrivateIndividualStreamCursorInput>>;
  where?: Maybe<TimelinePrivateIndividualBoolExp>;
};


export type SubscriptionRootTopologyLayerArgs = {
  distinctOn?: Maybe<Array<TopologyLayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TopologyLayerOrderBy>>;
  where?: Maybe<TopologyLayerBoolExp>;
};


export type SubscriptionRootTopologyLayerAggregateArgs = {
  distinctOn?: Maybe<Array<TopologyLayerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TopologyLayerOrderBy>>;
  where?: Maybe<TopologyLayerBoolExp>;
};


export type SubscriptionRootTopologyLayerByPkArgs = {
  layerId: Scalars['Int'];
  topologyId: Scalars['Int'];
};


export type SubscriptionRootTopologyLayerStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TopologyLayerStreamCursorInput>>;
  where?: Maybe<TopologyLayerBoolExp>;
};


export type SubscriptionRootTopologyTopologyArgs = {
  distinctOn?: Maybe<Array<TopologyTopologySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TopologyTopologyOrderBy>>;
  where?: Maybe<TopologyTopologyBoolExp>;
};


export type SubscriptionRootTopologyTopologyAggregateArgs = {
  distinctOn?: Maybe<Array<TopologyTopologySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TopologyTopologyOrderBy>>;
  where?: Maybe<TopologyTopologyBoolExp>;
};


export type SubscriptionRootTopologyTopologyByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootTopologyTopologyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TopologyTopologyStreamCursorInput>>;
  where?: Maybe<TopologyTopologyBoolExp>;
};


export type SubscriptionRootTradeArgs = {
  distinctOn?: Maybe<Array<TradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeOrderBy>>;
  where?: Maybe<TradeBoolExp>;
};


export type SubscriptionRootTradeAggregateArgs = {
  distinctOn?: Maybe<Array<TradeSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeOrderBy>>;
  where?: Maybe<TradeBoolExp>;
};


export type SubscriptionRootTradeByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootTradeGroupArgs = {
  distinctOn?: Maybe<Array<TradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGroupOrderBy>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type SubscriptionRootTradeGroupAggregateArgs = {
  distinctOn?: Maybe<Array<TradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGroupOrderBy>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type SubscriptionRootTradeGroupByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootTradeGroupStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TradeGroupStreamCursorInput>>;
  where?: Maybe<TradeGroupBoolExp>;
};


export type SubscriptionRootTradeGuideArgs = {
  distinctOn?: Maybe<Array<TradeGuideSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGuideOrderBy>>;
  where?: Maybe<TradeGuideBoolExp>;
};


export type SubscriptionRootTradeGuideAggregateArgs = {
  distinctOn?: Maybe<Array<TradeGuideSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGuideOrderBy>>;
  where?: Maybe<TradeGuideBoolExp>;
};


export type SubscriptionRootTradeGuideByPkArgs = {
  firstTradeId: Scalars['Int'];
  secondTradeId: Scalars['Int'];
};


export type SubscriptionRootTradeGuideStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TradeGuideStreamCursorInput>>;
  where?: Maybe<TradeGuideBoolExp>;
};


export type SubscriptionRootTradeKeywordArgs = {
  distinctOn?: Maybe<Array<TradeKeywordSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeKeywordOrderBy>>;
  where?: Maybe<TradeKeywordBoolExp>;
};


export type SubscriptionRootTradeKeywordAggregateArgs = {
  distinctOn?: Maybe<Array<TradeKeywordSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeKeywordOrderBy>>;
  where?: Maybe<TradeKeywordBoolExp>;
};


export type SubscriptionRootTradeKeywordByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootTradeKeywordStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TradeKeywordStreamCursorInput>>;
  where?: Maybe<TradeKeywordBoolExp>;
};


export type SubscriptionRootTradeMainProCountArgs = {
  distinctOn?: Maybe<Array<TradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeMainProCountOrderBy>>;
  where?: Maybe<TradeMainProCountBoolExp>;
};


export type SubscriptionRootTradeMainProCountAggregateArgs = {
  distinctOn?: Maybe<Array<TradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeMainProCountOrderBy>>;
  where?: Maybe<TradeMainProCountBoolExp>;
};


export type SubscriptionRootTradeMainProCountStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TradeMainProCountStreamCursorInput>>;
  where?: Maybe<TradeMainProCountBoolExp>;
};


export type SubscriptionRootTradeProViewArgs = {
  distinctOn?: Maybe<Array<TradeProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeProViewOrderBy>>;
  where?: Maybe<TradeProViewBoolExp>;
};


export type SubscriptionRootTradeProViewAggregateArgs = {
  distinctOn?: Maybe<Array<TradeProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeProViewOrderBy>>;
  where?: Maybe<TradeProViewBoolExp>;
};


export type SubscriptionRootTradeProViewByPkArgs = {
  proViewId: Scalars['Int'];
  tradeId: Scalars['Int'];
};


export type SubscriptionRootTradeProViewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TradeProViewStreamCursorInput>>;
  where?: Maybe<TradeProViewBoolExp>;
};


export type SubscriptionRootTradeQuestionArgs = {
  distinctOn?: Maybe<Array<TradeQuestionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeQuestionOrderBy>>;
  where?: Maybe<TradeQuestionBoolExp>;
};


export type SubscriptionRootTradeQuestionAggregateArgs = {
  distinctOn?: Maybe<Array<TradeQuestionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeQuestionOrderBy>>;
  where?: Maybe<TradeQuestionBoolExp>;
};


export type SubscriptionRootTradeQuestionByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootTradeQuestionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TradeQuestionStreamCursorInput>>;
  where?: Maybe<TradeQuestionBoolExp>;
};


export type SubscriptionRootTradeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TradeStreamCursorInput>>;
  where?: Maybe<TradeBoolExp>;
};


export type SubscriptionRootTradeTradeGroupArgs = {
  distinctOn?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeTradeGroupOrderBy>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};


export type SubscriptionRootTradeTradeGroupAggregateArgs = {
  distinctOn?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeTradeGroupOrderBy>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};


export type SubscriptionRootTradeTradeGroupByPkArgs = {
  tradeGroupId: Scalars['Int'];
  tradeId: Scalars['Int'];
};


export type SubscriptionRootTradeTradeGroupStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<TradeTradeGroupStreamCursorInput>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};


export type SubscriptionRootZohoConsumerArgs = {
  distinctOn?: Maybe<Array<ZohoConsumerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoConsumerOrderBy>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};


export type SubscriptionRootZohoConsumerAggregateArgs = {
  distinctOn?: Maybe<Array<ZohoConsumerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoConsumerOrderBy>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};


export type SubscriptionRootZohoConsumerByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootZohoConsumerStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ZohoConsumerStreamCursorInput>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};


export type SubscriptionRootZohoOrganizationArgs = {
  distinctOn?: Maybe<Array<ZohoOrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoOrganizationOrderBy>>;
  where?: Maybe<ZohoOrganizationBoolExp>;
};


export type SubscriptionRootZohoOrganizationAggregateArgs = {
  distinctOn?: Maybe<Array<ZohoOrganizationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoOrganizationOrderBy>>;
  where?: Maybe<ZohoOrganizationBoolExp>;
};


export type SubscriptionRootZohoOrganizationByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootZohoOrganizationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ZohoOrganizationStreamCursorInput>>;
  where?: Maybe<ZohoOrganizationBoolExp>;
};


export type SubscriptionRootZohoTokenArgs = {
  distinctOn?: Maybe<Array<ZohoTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoTokenOrderBy>>;
  where?: Maybe<ZohoTokenBoolExp>;
};


export type SubscriptionRootZohoTokenAggregateArgs = {
  distinctOn?: Maybe<Array<ZohoTokenSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoTokenOrderBy>>;
  where?: Maybe<ZohoTokenBoolExp>;
};


export type SubscriptionRootZohoTokenByPkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionRootZohoTokenStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<Maybe<ZohoTokenStreamCursorInput>>;
  where?: Maybe<ZohoTokenBoolExp>;
};

/** columns and relationships of "timeline_private_individual" */
export type TimelinePrivateIndividual = {
  __typename?: 'TimelinePrivateIndividual';
  addressLocality?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregated selection of "timeline_private_individual" */
export type TimelinePrivateIndividualAggregate = {
  __typename?: 'TimelinePrivateIndividualAggregate';
  aggregate?: Maybe<TimelinePrivateIndividualAggregateFields>;
  nodes: Array<TimelinePrivateIndividual>;
};

/** aggregate fields of "timeline_private_individual" */
export type TimelinePrivateIndividualAggregateFields = {
  __typename?: 'TimelinePrivateIndividualAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<TimelinePrivateIndividualMaxFields>;
  min?: Maybe<TimelinePrivateIndividualMinFields>;
};


/** aggregate fields of "timeline_private_individual" */
export type TimelinePrivateIndividualAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TimelinePrivateIndividualSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/**
 * Boolean expression to filter rows from the table "timeline_private_individual".
 * All fields are combined with a logical 'AND'.
 */
export type TimelinePrivateIndividualBoolExp = {
  _and?: Maybe<Array<TimelinePrivateIndividualBoolExp>>;
  _not?: Maybe<TimelinePrivateIndividualBoolExp>;
  _or?: Maybe<Array<TimelinePrivateIndividualBoolExp>>;
  addressLocality?: Maybe<StringComparisonExp>;
  email?: Maybe<StringComparisonExp>;
  familyName?: Maybe<StringComparisonExp>;
  givenName?: Maybe<StringComparisonExp>;
  telephone?: Maybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type TimelinePrivateIndividualMaxFields = {
  __typename?: 'TimelinePrivateIndividualMaxFields';
  addressLocality?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TimelinePrivateIndividualMinFields = {
  __typename?: 'TimelinePrivateIndividualMinFields';
  addressLocality?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "timeline_private_individual". */
export type TimelinePrivateIndividualOrderBy = {
  addressLocality?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  familyName?: Maybe<OrderBy>;
  givenName?: Maybe<OrderBy>;
  telephone?: Maybe<OrderBy>;
};

/** select columns of table "timeline_private_individual" */
export enum TimelinePrivateIndividualSelectColumn {
  /** column name */
  AddressLocality = 'addressLocality',
  /** column name */
  Email = 'email',
  /** column name */
  FamilyName = 'familyName',
  /** column name */
  GivenName = 'givenName',
  /** column name */
  Telephone = 'telephone'
}

/** Streaming cursor of the table "timeline_private_individual" */
export type TimelinePrivateIndividualStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TimelinePrivateIndividualStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TimelinePrivateIndividualStreamCursorValueInput = {
  addressLocality?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _isNull?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "topology.layer" */
export type TopologyLayer = {
  __typename?: 'TopologyLayer';
  childId?: Maybe<Scalars['Int']>;
  featureColumn: Scalars['String'];
  featureType: Scalars['Int'];
  layerId: Scalars['Int'];
  level: Scalars['Int'];
  schemaName: Scalars['String'];
  tableName: Scalars['String'];
  topologyId: Scalars['Int'];
};

/** aggregated selection of "topology.layer" */
export type TopologyLayerAggregate = {
  __typename?: 'TopologyLayerAggregate';
  aggregate?: Maybe<TopologyLayerAggregateFields>;
  nodes: Array<TopologyLayer>;
};

/** aggregate fields of "topology.layer" */
export type TopologyLayerAggregateFields = {
  __typename?: 'TopologyLayerAggregateFields';
  avg?: Maybe<TopologyLayerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TopologyLayerMaxFields>;
  min?: Maybe<TopologyLayerMinFields>;
  stddev?: Maybe<TopologyLayerStddevFields>;
  stddevPop?: Maybe<TopologyLayerStddevPopFields>;
  stddevSamp?: Maybe<TopologyLayerStddevSampFields>;
  sum?: Maybe<TopologyLayerSumFields>;
  varPop?: Maybe<TopologyLayerVarPopFields>;
  varSamp?: Maybe<TopologyLayerVarSampFields>;
  variance?: Maybe<TopologyLayerVarianceFields>;
};


/** aggregate fields of "topology.layer" */
export type TopologyLayerAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TopologyLayerSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TopologyLayerAvgFields = {
  __typename?: 'TopologyLayerAvgFields';
  childId?: Maybe<Scalars['Float']>;
  featureType?: Maybe<Scalars['Float']>;
  layerId?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  topologyId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "topology.layer". All fields are combined with a logical 'AND'. */
export type TopologyLayerBoolExp = {
  _and?: Maybe<Array<TopologyLayerBoolExp>>;
  _not?: Maybe<TopologyLayerBoolExp>;
  _or?: Maybe<Array<TopologyLayerBoolExp>>;
  childId?: Maybe<IntComparisonExp>;
  featureColumn?: Maybe<StringComparisonExp>;
  featureType?: Maybe<IntComparisonExp>;
  layerId?: Maybe<IntComparisonExp>;
  level?: Maybe<IntComparisonExp>;
  schemaName?: Maybe<StringComparisonExp>;
  tableName?: Maybe<StringComparisonExp>;
  topologyId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "topology.layer" */
export enum TopologyLayerConstraint {
  /** unique or primary key constraint on columns "layer_id", "topology_id" */
  LayerPkey = 'layer_pkey',
  /** unique or primary key constraint on columns "feature_column", "schema_name", "table_name" */
  LayerSchemaNameTableNameFeatureColumnKey = 'layer_schema_name_table_name_feature_column_key'
}

/** input type for incrementing numeric columns in table "topology.layer" */
export type TopologyLayerIncInput = {
  childId?: Maybe<Scalars['Int']>;
  featureType?: Maybe<Scalars['Int']>;
  layerId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  topologyId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "topology.layer" */
export type TopologyLayerInsertInput = {
  childId?: Maybe<Scalars['Int']>;
  featureColumn?: Maybe<Scalars['String']>;
  featureType?: Maybe<Scalars['Int']>;
  layerId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  schemaName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  topologyId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TopologyLayerMaxFields = {
  __typename?: 'TopologyLayerMaxFields';
  childId?: Maybe<Scalars['Int']>;
  featureColumn?: Maybe<Scalars['String']>;
  featureType?: Maybe<Scalars['Int']>;
  layerId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  schemaName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  topologyId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TopologyLayerMinFields = {
  __typename?: 'TopologyLayerMinFields';
  childId?: Maybe<Scalars['Int']>;
  featureColumn?: Maybe<Scalars['String']>;
  featureType?: Maybe<Scalars['Int']>;
  layerId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  schemaName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  topologyId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "topology.layer" */
export type TopologyLayerMutationResponse = {
  __typename?: 'TopologyLayerMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TopologyLayer>;
};

/** on_conflict condition type for table "topology.layer" */
export type TopologyLayerOnConflict = {
  constraint: TopologyLayerConstraint;
  updateColumns: Array<TopologyLayerUpdateColumn>;
  where?: Maybe<TopologyLayerBoolExp>;
};

/** Ordering options when selecting data from "topology.layer". */
export type TopologyLayerOrderBy = {
  childId?: Maybe<OrderBy>;
  featureColumn?: Maybe<OrderBy>;
  featureType?: Maybe<OrderBy>;
  layerId?: Maybe<OrderBy>;
  level?: Maybe<OrderBy>;
  schemaName?: Maybe<OrderBy>;
  tableName?: Maybe<OrderBy>;
  topologyId?: Maybe<OrderBy>;
};

/** primary key columns input for table: topology.layer */
export type TopologyLayerPkColumnsInput = {
  layerId: Scalars['Int'];
  topologyId: Scalars['Int'];
};

/** select columns of table "topology.layer" */
export enum TopologyLayerSelectColumn {
  /** column name */
  ChildId = 'childId',
  /** column name */
  FeatureColumn = 'featureColumn',
  /** column name */
  FeatureType = 'featureType',
  /** column name */
  LayerId = 'layerId',
  /** column name */
  Level = 'level',
  /** column name */
  SchemaName = 'schemaName',
  /** column name */
  TableName = 'tableName',
  /** column name */
  TopologyId = 'topologyId'
}

/** input type for updating data in table "topology.layer" */
export type TopologyLayerSetInput = {
  childId?: Maybe<Scalars['Int']>;
  featureColumn?: Maybe<Scalars['String']>;
  featureType?: Maybe<Scalars['Int']>;
  layerId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  schemaName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  topologyId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TopologyLayerStddevFields = {
  __typename?: 'TopologyLayerStddevFields';
  childId?: Maybe<Scalars['Float']>;
  featureType?: Maybe<Scalars['Float']>;
  layerId?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  topologyId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type TopologyLayerStddevPopFields = {
  __typename?: 'TopologyLayerStddevPopFields';
  childId?: Maybe<Scalars['Float']>;
  featureType?: Maybe<Scalars['Float']>;
  layerId?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  topologyId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type TopologyLayerStddevSampFields = {
  __typename?: 'TopologyLayerStddevSampFields';
  childId?: Maybe<Scalars['Float']>;
  featureType?: Maybe<Scalars['Float']>;
  layerId?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  topologyId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "topology_layer" */
export type TopologyLayerStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TopologyLayerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TopologyLayerStreamCursorValueInput = {
  childId?: Maybe<Scalars['Int']>;
  featureColumn?: Maybe<Scalars['String']>;
  featureType?: Maybe<Scalars['Int']>;
  layerId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  schemaName?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  topologyId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TopologyLayerSumFields = {
  __typename?: 'TopologyLayerSumFields';
  childId?: Maybe<Scalars['Int']>;
  featureType?: Maybe<Scalars['Int']>;
  layerId?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  topologyId?: Maybe<Scalars['Int']>;
};

/** update columns of table "topology.layer" */
export enum TopologyLayerUpdateColumn {
  /** column name */
  ChildId = 'childId',
  /** column name */
  FeatureColumn = 'featureColumn',
  /** column name */
  FeatureType = 'featureType',
  /** column name */
  LayerId = 'layerId',
  /** column name */
  Level = 'level',
  /** column name */
  SchemaName = 'schemaName',
  /** column name */
  TableName = 'tableName',
  /** column name */
  TopologyId = 'topologyId'
}

export type TopologyLayerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TopologyLayerIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TopologyLayerSetInput>;
  /** filter the rows which have to be updated */
  where: TopologyLayerBoolExp;
};

/** aggregate variance on columns */
export type TopologyLayerVarianceFields = {
  __typename?: 'TopologyLayerVarianceFields';
  childId?: Maybe<Scalars['Float']>;
  featureType?: Maybe<Scalars['Float']>;
  layerId?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  topologyId?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type TopologyLayerVarPopFields = {
  __typename?: 'TopologyLayerVarPopFields';
  childId?: Maybe<Scalars['Float']>;
  featureType?: Maybe<Scalars['Float']>;
  layerId?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  topologyId?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type TopologyLayerVarSampFields = {
  __typename?: 'TopologyLayerVarSampFields';
  childId?: Maybe<Scalars['Float']>;
  featureType?: Maybe<Scalars['Float']>;
  layerId?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  topologyId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "topology.topology" */
export type TopologyTopology = {
  __typename?: 'TopologyTopology';
  hasz: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  precision: Scalars['float8'];
  srid: Scalars['Int'];
};

/** aggregated selection of "topology.topology" */
export type TopologyTopologyAggregate = {
  __typename?: 'TopologyTopologyAggregate';
  aggregate?: Maybe<TopologyTopologyAggregateFields>;
  nodes: Array<TopologyTopology>;
};

/** aggregate fields of "topology.topology" */
export type TopologyTopologyAggregateFields = {
  __typename?: 'TopologyTopologyAggregateFields';
  avg?: Maybe<TopologyTopologyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TopologyTopologyMaxFields>;
  min?: Maybe<TopologyTopologyMinFields>;
  stddev?: Maybe<TopologyTopologyStddevFields>;
  stddevPop?: Maybe<TopologyTopologyStddevPopFields>;
  stddevSamp?: Maybe<TopologyTopologyStddevSampFields>;
  sum?: Maybe<TopologyTopologySumFields>;
  varPop?: Maybe<TopologyTopologyVarPopFields>;
  varSamp?: Maybe<TopologyTopologyVarSampFields>;
  variance?: Maybe<TopologyTopologyVarianceFields>;
};


/** aggregate fields of "topology.topology" */
export type TopologyTopologyAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TopologyTopologySelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TopologyTopologyAvgFields = {
  __typename?: 'TopologyTopologyAvgFields';
  id?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "topology.topology". All fields are combined with a logical 'AND'. */
export type TopologyTopologyBoolExp = {
  _and?: Maybe<Array<TopologyTopologyBoolExp>>;
  _not?: Maybe<TopologyTopologyBoolExp>;
  _or?: Maybe<Array<TopologyTopologyBoolExp>>;
  hasz?: Maybe<BooleanComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  precision?: Maybe<Float8ComparisonExp>;
  srid?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "topology.topology" */
export enum TopologyTopologyConstraint {
  /** unique or primary key constraint on columns "name" */
  TopologyNameKey = 'topology_name_key',
  /** unique or primary key constraint on columns "id" */
  TopologyPkey = 'topology_pkey'
}

/** input type for incrementing numeric columns in table "topology.topology" */
export type TopologyTopologyIncInput = {
  id?: Maybe<Scalars['Int']>;
  precision?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "topology.topology" */
export type TopologyTopologyInsertInput = {
  hasz?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TopologyTopologyMaxFields = {
  __typename?: 'TopologyTopologyMaxFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TopologyTopologyMinFields = {
  __typename?: 'TopologyTopologyMinFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "topology.topology" */
export type TopologyTopologyMutationResponse = {
  __typename?: 'TopologyTopologyMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TopologyTopology>;
};

/** on_conflict condition type for table "topology.topology" */
export type TopologyTopologyOnConflict = {
  constraint: TopologyTopologyConstraint;
  updateColumns: Array<TopologyTopologyUpdateColumn>;
  where?: Maybe<TopologyTopologyBoolExp>;
};

/** Ordering options when selecting data from "topology.topology". */
export type TopologyTopologyOrderBy = {
  hasz?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  precision?: Maybe<OrderBy>;
  srid?: Maybe<OrderBy>;
};

/** primary key columns input for table: topology.topology */
export type TopologyTopologyPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "topology.topology" */
export enum TopologyTopologySelectColumn {
  /** column name */
  Hasz = 'hasz',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Precision = 'precision',
  /** column name */
  Srid = 'srid'
}

/** input type for updating data in table "topology.topology" */
export type TopologyTopologySetInput = {
  hasz?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TopologyTopologyStddevFields = {
  __typename?: 'TopologyTopologyStddevFields';
  id?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type TopologyTopologyStddevPopFields = {
  __typename?: 'TopologyTopologyStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type TopologyTopologyStddevSampFields = {
  __typename?: 'TopologyTopologyStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "topology_topology" */
export type TopologyTopologyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TopologyTopologyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TopologyTopologyStreamCursorValueInput = {
  hasz?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TopologyTopologySumFields = {
  __typename?: 'TopologyTopologySumFields';
  id?: Maybe<Scalars['Int']>;
  precision?: Maybe<Scalars['float8']>;
  srid?: Maybe<Scalars['Int']>;
};

/** update columns of table "topology.topology" */
export enum TopologyTopologyUpdateColumn {
  /** column name */
  Hasz = 'hasz',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Precision = 'precision',
  /** column name */
  Srid = 'srid'
}

export type TopologyTopologyUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TopologyTopologyIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TopologyTopologySetInput>;
  /** filter the rows which have to be updated */
  where: TopologyTopologyBoolExp;
};

/** aggregate variance on columns */
export type TopologyTopologyVarianceFields = {
  __typename?: 'TopologyTopologyVarianceFields';
  id?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type TopologyTopologyVarPopFields = {
  __typename?: 'TopologyTopologyVarPopFields';
  id?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type TopologyTopologyVarSampFields = {
  __typename?: 'TopologyTopologyVarSampFields';
  id?: Maybe<Scalars['Float']>;
  precision?: Maybe<Scalars['Float']>;
  srid?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "trade" */
export type Trade = {
  __typename?: 'Trade';
  anchor: Scalars['String'];
  /** An array relationship */
  bilikZoneMainProCount: Array<BilikZoneTradeMainProCount>;
  /** An aggregate relationship */
  bilikZoneMainProCountAggregate: BilikZoneTradeMainProCountAggregate;
  /** An object relationship */
  bilikZoneSitemap?: Maybe<SitemapBilikZone>;
  /** An array relationship */
  citySitemap: Array<SitemapCity>;
  /** An aggregate relationship */
  citySitemapAggregate: SitemapCityAggregate;
  id: Scalars['Int'];
  isAnonymised: Scalars['Boolean'];
  isCallRecordable: Scalars['Boolean'];
  isRgeFilterable: Scalars['Boolean'];
  label: Scalars['String'];
  /** An array relationship */
  mainTradeProPresentation: Array<ProPresentation>;
  /** An aggregate relationship */
  mainTradeProPresentationAggregate: ProPresentationAggregate;
  metaDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  name: Scalars['String'];
  pageSubtitle: Scalars['String'];
  pageTitle: Scalars['String'];
  pageTitleDirectory?: Maybe<Scalars['String']>;
  pageTitleVariant: Scalars['String'];
  /** An array relationship */
  secondaryTradeProPresentation: Array<ProPresentation>;
  /** An aggregate relationship */
  secondaryTradeProPresentationAggregate: ProPresentationAggregate;
  slug: Scalars['String'];
  /** An array relationship */
  tradeGroups: Array<TradeTradeGroup>;
  /** An aggregate relationship */
  tradeGroupsAggregate: TradeTradeGroupAggregate;
  /** An array relationship */
  tradeGuidesByFirstTrade: Array<TradeGuide>;
  /** An aggregate relationship */
  tradeGuidesByFirstTradeAggregate: TradeGuideAggregate;
  /** An array relationship */
  tradeGuidesBySecondTrade: Array<TradeGuide>;
  /** An aggregate relationship */
  tradeGuidesBySecondTradeAggregate: TradeGuideAggregate;
  /** An array relationship */
  tradeKeywords: Array<TradeKeyword>;
  /** An aggregate relationship */
  tradeKeywordsAggregate: TradeKeywordAggregate;
  /** An array relationship */
  tradeProViews: Array<TradeProView>;
  /** An aggregate relationship */
  tradeProViewsAggregate: TradeProViewAggregate;
  /** An array relationship */
  tradeQuestions: Array<TradeQuestion>;
  /** An aggregate relationship */
  tradeQuestionsAggregate: TradeQuestionAggregate;
};


/** columns and relationships of "trade" */
export type TradeBilikZoneMainProCountArgs = {
  distinctOn?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneTradeMainProCountOrderBy>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeBilikZoneMainProCountAggregateArgs = {
  distinctOn?: Maybe<Array<BilikZoneTradeMainProCountSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneTradeMainProCountOrderBy>>;
  where?: Maybe<BilikZoneTradeMainProCountBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeCitySitemapArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeCitySitemapAggregateArgs = {
  distinctOn?: Maybe<Array<SitemapCitySelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SitemapCityOrderBy>>;
  where?: Maybe<SitemapCityBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeMainTradeProPresentationArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeMainTradeProPresentationAggregateArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeSecondaryTradeProPresentationArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeSecondaryTradeProPresentationAggregateArgs = {
  distinctOn?: Maybe<Array<ProPresentationSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ProPresentationOrderBy>>;
  where?: Maybe<ProPresentationBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeGroupsArgs = {
  distinctOn?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeTradeGroupOrderBy>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeGroupsAggregateArgs = {
  distinctOn?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeTradeGroupOrderBy>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeGuidesByFirstTradeArgs = {
  distinctOn?: Maybe<Array<TradeGuideSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGuideOrderBy>>;
  where?: Maybe<TradeGuideBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeGuidesByFirstTradeAggregateArgs = {
  distinctOn?: Maybe<Array<TradeGuideSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGuideOrderBy>>;
  where?: Maybe<TradeGuideBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeGuidesBySecondTradeArgs = {
  distinctOn?: Maybe<Array<TradeGuideSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGuideOrderBy>>;
  where?: Maybe<TradeGuideBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeGuidesBySecondTradeAggregateArgs = {
  distinctOn?: Maybe<Array<TradeGuideSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeGuideOrderBy>>;
  where?: Maybe<TradeGuideBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeKeywordsArgs = {
  distinctOn?: Maybe<Array<TradeKeywordSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeKeywordOrderBy>>;
  where?: Maybe<TradeKeywordBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeKeywordsAggregateArgs = {
  distinctOn?: Maybe<Array<TradeKeywordSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeKeywordOrderBy>>;
  where?: Maybe<TradeKeywordBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeProViewsArgs = {
  distinctOn?: Maybe<Array<TradeProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeProViewOrderBy>>;
  where?: Maybe<TradeProViewBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeProViewsAggregateArgs = {
  distinctOn?: Maybe<Array<TradeProViewSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeProViewOrderBy>>;
  where?: Maybe<TradeProViewBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeQuestionsArgs = {
  distinctOn?: Maybe<Array<TradeQuestionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeQuestionOrderBy>>;
  where?: Maybe<TradeQuestionBoolExp>;
};


/** columns and relationships of "trade" */
export type TradeTradeQuestionsAggregateArgs = {
  distinctOn?: Maybe<Array<TradeQuestionSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeQuestionOrderBy>>;
  where?: Maybe<TradeQuestionBoolExp>;
};

export type TradeAggregate = {
  __typename?: 'TradeAggregate';
  aggregate?: Maybe<TradeAggregateFields>;
  nodes: Array<Trade>;
};

/** aggregate fields of "trade" */
export type TradeAggregateFields = {
  __typename?: 'TradeAggregateFields';
  avg?: Maybe<TradeAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TradeMaxFields>;
  min?: Maybe<TradeMinFields>;
  stddev?: Maybe<TradeStddevFields>;
  stddevPop?: Maybe<TradeStddevPopFields>;
  stddevSamp?: Maybe<TradeStddevSampFields>;
  sum?: Maybe<TradeSumFields>;
  varPop?: Maybe<TradeVarPopFields>;
  varSamp?: Maybe<TradeVarSampFields>;
  variance?: Maybe<TradeVarianceFields>;
};


/** aggregate fields of "trade" */
export type TradeAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TradeSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TradeAvgFields = {
  __typename?: 'TradeAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "trade". All fields are combined with a logical 'AND'. */
export type TradeBoolExp = {
  _and?: Maybe<Array<TradeBoolExp>>;
  _not?: Maybe<TradeBoolExp>;
  _or?: Maybe<Array<TradeBoolExp>>;
  anchor?: Maybe<StringComparisonExp>;
  bilikZoneMainProCount?: Maybe<BilikZoneTradeMainProCountBoolExp>;
  bilikZoneMainProCountAggregate?: Maybe<BilikZoneTradeMainProCountAggregateBoolExp>;
  bilikZoneSitemap?: Maybe<SitemapBilikZoneBoolExp>;
  citySitemap?: Maybe<SitemapCityBoolExp>;
  citySitemapAggregate?: Maybe<SitemapCityAggregateBoolExp>;
  id?: Maybe<IntComparisonExp>;
  isAnonymised?: Maybe<BooleanComparisonExp>;
  isCallRecordable?: Maybe<BooleanComparisonExp>;
  isRgeFilterable?: Maybe<BooleanComparisonExp>;
  label?: Maybe<StringComparisonExp>;
  mainTradeProPresentation?: Maybe<ProPresentationBoolExp>;
  mainTradeProPresentationAggregate?: Maybe<ProPresentationAggregateBoolExp>;
  metaDescription?: Maybe<StringComparisonExp>;
  metaTitle?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  pageSubtitle?: Maybe<StringComparisonExp>;
  pageTitle?: Maybe<StringComparisonExp>;
  pageTitleDirectory?: Maybe<StringComparisonExp>;
  pageTitleVariant?: Maybe<StringComparisonExp>;
  secondaryTradeProPresentation?: Maybe<ProPresentationBoolExp>;
  secondaryTradeProPresentationAggregate?: Maybe<ProPresentationAggregateBoolExp>;
  slug?: Maybe<StringComparisonExp>;
  tradeGroups?: Maybe<TradeTradeGroupBoolExp>;
  tradeGroupsAggregate?: Maybe<TradeTradeGroupAggregateBoolExp>;
  tradeGuidesByFirstTrade?: Maybe<TradeGuideBoolExp>;
  tradeGuidesByFirstTradeAggregate?: Maybe<TradeGuideAggregateBoolExp>;
  tradeGuidesBySecondTrade?: Maybe<TradeGuideBoolExp>;
  tradeGuidesBySecondTradeAggregate?: Maybe<TradeGuideAggregateBoolExp>;
  tradeKeywords?: Maybe<TradeKeywordBoolExp>;
  tradeKeywordsAggregate?: Maybe<TradeKeywordAggregateBoolExp>;
  tradeProViews?: Maybe<TradeProViewBoolExp>;
  tradeProViewsAggregate?: Maybe<TradeProViewAggregateBoolExp>;
  tradeQuestions?: Maybe<TradeQuestionBoolExp>;
  tradeQuestionsAggregate?: Maybe<TradeQuestionAggregateBoolExp>;
};

/** unique or primary key constraints on table "trade" */
export enum TradeConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_23077Primary = 'idx_23077_primary',
  /** unique or primary key constraint on columns "slug" */
  Idx_23077Uniq_7e1a4366989d9b62 = 'idx_23077_uniq_7e1a4366989d9b62'
}

/** columns and relationships of "trade_group" */
export type TradeGroup = {
  __typename?: 'TradeGroup';
  iconName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  position: Scalars['Int'];
  /** An array relationship */
  tradeTradeGroups: Array<TradeTradeGroup>;
  /** An aggregate relationship */
  tradeTradeGroupsAggregate: TradeTradeGroupAggregate;
};


/** columns and relationships of "trade_group" */
export type TradeGroupTradeTradeGroupsArgs = {
  distinctOn?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeTradeGroupOrderBy>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};


/** columns and relationships of "trade_group" */
export type TradeGroupTradeTradeGroupsAggregateArgs = {
  distinctOn?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TradeTradeGroupOrderBy>>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};

export type TradeGroupAggregate = {
  __typename?: 'TradeGroupAggregate';
  aggregate?: Maybe<TradeGroupAggregateFields>;
  nodes: Array<TradeGroup>;
};

/** aggregate fields of "trade_group" */
export type TradeGroupAggregateFields = {
  __typename?: 'TradeGroupAggregateFields';
  avg?: Maybe<TradeGroupAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TradeGroupMaxFields>;
  min?: Maybe<TradeGroupMinFields>;
  stddev?: Maybe<TradeGroupStddevFields>;
  stddevPop?: Maybe<TradeGroupStddevPopFields>;
  stddevSamp?: Maybe<TradeGroupStddevSampFields>;
  sum?: Maybe<TradeGroupSumFields>;
  varPop?: Maybe<TradeGroupVarPopFields>;
  varSamp?: Maybe<TradeGroupVarSampFields>;
  variance?: Maybe<TradeGroupVarianceFields>;
};


/** aggregate fields of "trade_group" */
export type TradeGroupAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TradeGroupSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TradeGroupAvgFields = {
  __typename?: 'TradeGroupAvgFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "trade_group". All fields are combined with a logical 'AND'. */
export type TradeGroupBoolExp = {
  _and?: Maybe<Array<TradeGroupBoolExp>>;
  _not?: Maybe<TradeGroupBoolExp>;
  _or?: Maybe<Array<TradeGroupBoolExp>>;
  iconName?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  position?: Maybe<IntComparisonExp>;
  tradeTradeGroups?: Maybe<TradeTradeGroupBoolExp>;
  tradeTradeGroupsAggregate?: Maybe<TradeTradeGroupAggregateBoolExp>;
};

/** unique or primary key constraints on table "trade_group" */
export enum TradeGroupConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_23086Primary = 'idx_23086_primary'
}

/** input type for incrementing numeric columns in table "trade_group" */
export type TradeGroupIncInput = {
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade_group" */
export type TradeGroupInsertInput = {
  iconName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  tradeTradeGroups?: Maybe<TradeTradeGroupArrRelInsertInput>;
};

/** aggregate max on columns */
export type TradeGroupMaxFields = {
  __typename?: 'TradeGroupMaxFields';
  iconName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TradeGroupMinFields = {
  __typename?: 'TradeGroupMinFields';
  iconName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "trade_group" */
export type TradeGroupMutationResponse = {
  __typename?: 'TradeGroupMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TradeGroup>;
};

/** input type for inserting object relation for remote table "trade_group" */
export type TradeGroupObjRelInsertInput = {
  data: TradeGroupInsertInput;
  /** upsert condition */
  onConflict?: Maybe<TradeGroupOnConflict>;
};

/** on_conflict condition type for table "trade_group" */
export type TradeGroupOnConflict = {
  constraint: TradeGroupConstraint;
  updateColumns: Array<TradeGroupUpdateColumn>;
  where?: Maybe<TradeGroupBoolExp>;
};

/** Ordering options when selecting data from "trade_group". */
export type TradeGroupOrderBy = {
  iconName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  tradeTradeGroupsAggregate?: Maybe<TradeTradeGroupAggregateOrderBy>;
};

/** primary key columns input for table: trade_group */
export type TradeGroupPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "trade_group" */
export enum TradeGroupSelectColumn {
  /** column name */
  IconName = 'iconName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

/** input type for updating data in table "trade_group" */
export type TradeGroupSetInput = {
  iconName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TradeGroupStddevFields = {
  __typename?: 'TradeGroupStddevFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type TradeGroupStddevPopFields = {
  __typename?: 'TradeGroupStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type TradeGroupStddevSampFields = {
  __typename?: 'TradeGroupStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "trade_group" */
export type TradeGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeGroupStreamCursorValueInput = {
  iconName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TradeGroupSumFields = {
  __typename?: 'TradeGroupSumFields';
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

/** update columns of table "trade_group" */
export enum TradeGroupUpdateColumn {
  /** column name */
  IconName = 'iconName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position'
}

export type TradeGroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TradeGroupIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TradeGroupSetInput>;
  /** filter the rows which have to be updated */
  where: TradeGroupBoolExp;
};

/** aggregate variance on columns */
export type TradeGroupVarianceFields = {
  __typename?: 'TradeGroupVarianceFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type TradeGroupVarPopFields = {
  __typename?: 'TradeGroupVarPopFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type TradeGroupVarSampFields = {
  __typename?: 'TradeGroupVarSampFields';
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "trade_guide" */
export type TradeGuide = {
  __typename?: 'TradeGuide';
  /** An object relationship */
  firstTrade: Trade;
  firstTradeId: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  secondTrade: Trade;
  secondTradeId: Scalars['Int'];
};

/** aggregated selection of "trade_guide" */
export type TradeGuideAggregate = {
  __typename?: 'TradeGuideAggregate';
  aggregate?: Maybe<TradeGuideAggregateFields>;
  nodes: Array<TradeGuide>;
};

export type TradeGuideAggregateBoolExp = {
  count?: Maybe<TradeGuideAggregateBoolExpCount>;
};

export type TradeGuideAggregateBoolExpCount = {
  arguments?: Maybe<Array<TradeGuideSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TradeGuideBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "trade_guide" */
export type TradeGuideAggregateFields = {
  __typename?: 'TradeGuideAggregateFields';
  avg?: Maybe<TradeGuideAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TradeGuideMaxFields>;
  min?: Maybe<TradeGuideMinFields>;
  stddev?: Maybe<TradeGuideStddevFields>;
  stddevPop?: Maybe<TradeGuideStddevPopFields>;
  stddevSamp?: Maybe<TradeGuideStddevSampFields>;
  sum?: Maybe<TradeGuideSumFields>;
  varPop?: Maybe<TradeGuideVarPopFields>;
  varSamp?: Maybe<TradeGuideVarSampFields>;
  variance?: Maybe<TradeGuideVarianceFields>;
};


/** aggregate fields of "trade_guide" */
export type TradeGuideAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TradeGuideSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_guide" */
export type TradeGuideAggregateOrderBy = {
  avg?: Maybe<TradeGuideAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<TradeGuideMaxOrderBy>;
  min?: Maybe<TradeGuideMinOrderBy>;
  stddev?: Maybe<TradeGuideStddevOrderBy>;
  stddevPop?: Maybe<TradeGuideStddevPopOrderBy>;
  stddevSamp?: Maybe<TradeGuideStddevSampOrderBy>;
  sum?: Maybe<TradeGuideSumOrderBy>;
  varPop?: Maybe<TradeGuideVarPopOrderBy>;
  varSamp?: Maybe<TradeGuideVarSampOrderBy>;
  variance?: Maybe<TradeGuideVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "trade_guide" */
export type TradeGuideArrRelInsertInput = {
  data: Array<TradeGuideInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<TradeGuideOnConflict>;
};

/** aggregate avg on columns */
export type TradeGuideAvgFields = {
  __typename?: 'TradeGuideAvgFields';
  firstTradeId?: Maybe<Scalars['Float']>;
  secondTradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_guide" */
export type TradeGuideAvgOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "trade_guide". All fields are combined with a logical 'AND'. */
export type TradeGuideBoolExp = {
  _and?: Maybe<Array<TradeGuideBoolExp>>;
  _not?: Maybe<TradeGuideBoolExp>;
  _or?: Maybe<Array<TradeGuideBoolExp>>;
  firstTrade?: Maybe<TradeBoolExp>;
  firstTradeId?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  secondTrade?: Maybe<TradeBoolExp>;
  secondTradeId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "trade_guide" */
export enum TradeGuideConstraint {
  /** unique or primary key constraint on columns "first_trade_id", "second_trade_id" */
  TradeGuidePkey = 'trade_guide_pkey'
}

/** input type for incrementing numeric columns in table "trade_guide" */
export type TradeGuideIncInput = {
  firstTradeId?: Maybe<Scalars['Int']>;
  secondTradeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade_guide" */
export type TradeGuideInsertInput = {
  firstTrade?: Maybe<TradeObjRelInsertInput>;
  firstTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  secondTrade?: Maybe<TradeObjRelInsertInput>;
  secondTradeId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TradeGuideMaxFields = {
  __typename?: 'TradeGuideMaxFields';
  firstTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  secondTradeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "trade_guide" */
export type TradeGuideMaxOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type TradeGuideMinFields = {
  __typename?: 'TradeGuideMinFields';
  firstTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  secondTradeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "trade_guide" */
export type TradeGuideMinOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "trade_guide" */
export type TradeGuideMutationResponse = {
  __typename?: 'TradeGuideMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TradeGuide>;
};

/** on_conflict condition type for table "trade_guide" */
export type TradeGuideOnConflict = {
  constraint: TradeGuideConstraint;
  updateColumns: Array<TradeGuideUpdateColumn>;
  where?: Maybe<TradeGuideBoolExp>;
};

/** Ordering options when selecting data from "trade_guide". */
export type TradeGuideOrderBy = {
  firstTrade?: Maybe<TradeOrderBy>;
  firstTradeId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  secondTrade?: Maybe<TradeOrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** primary key columns input for table: trade_guide */
export type TradeGuidePkColumnsInput = {
  firstTradeId: Scalars['Int'];
  secondTradeId: Scalars['Int'];
};

/** select columns of table "trade_guide" */
export enum TradeGuideSelectColumn {
  /** column name */
  FirstTradeId = 'firstTradeId',
  /** column name */
  Name = 'name',
  /** column name */
  SecondTradeId = 'secondTradeId'
}

/** input type for updating data in table "trade_guide" */
export type TradeGuideSetInput = {
  firstTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  secondTradeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TradeGuideStddevFields = {
  __typename?: 'TradeGuideStddevFields';
  firstTradeId?: Maybe<Scalars['Float']>;
  secondTradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_guide" */
export type TradeGuideStddevOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TradeGuideStddevPopFields = {
  __typename?: 'TradeGuideStddevPopFields';
  firstTradeId?: Maybe<Scalars['Float']>;
  secondTradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "trade_guide" */
export type TradeGuideStddevPopOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TradeGuideStddevSampFields = {
  __typename?: 'TradeGuideStddevSampFields';
  firstTradeId?: Maybe<Scalars['Float']>;
  secondTradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "trade_guide" */
export type TradeGuideStddevSampOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "trade_guide" */
export type TradeGuideStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeGuideStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeGuideStreamCursorValueInput = {
  firstTradeId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  secondTradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TradeGuideSumFields = {
  __typename?: 'TradeGuideSumFields';
  firstTradeId?: Maybe<Scalars['Int']>;
  secondTradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade_guide" */
export type TradeGuideSumOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** update columns of table "trade_guide" */
export enum TradeGuideUpdateColumn {
  /** column name */
  FirstTradeId = 'firstTradeId',
  /** column name */
  Name = 'name',
  /** column name */
  SecondTradeId = 'secondTradeId'
}

export type TradeGuideUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TradeGuideIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TradeGuideSetInput>;
  /** filter the rows which have to be updated */
  where: TradeGuideBoolExp;
};

/** aggregate variance on columns */
export type TradeGuideVarianceFields = {
  __typename?: 'TradeGuideVarianceFields';
  firstTradeId?: Maybe<Scalars['Float']>;
  secondTradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_guide" */
export type TradeGuideVarianceOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type TradeGuideVarPopFields = {
  __typename?: 'TradeGuideVarPopFields';
  firstTradeId?: Maybe<Scalars['Float']>;
  secondTradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "trade_guide" */
export type TradeGuideVarPopOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TradeGuideVarSampFields = {
  __typename?: 'TradeGuideVarSampFields';
  firstTradeId?: Maybe<Scalars['Float']>;
  secondTradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "trade_guide" */
export type TradeGuideVarSampOrderBy = {
  firstTradeId?: Maybe<OrderBy>;
  secondTradeId?: Maybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "trade" */
export type TradeIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade" */
export type TradeInsertInput = {
  anchor?: Maybe<Scalars['String']>;
  bilikZoneMainProCount?: Maybe<BilikZoneTradeMainProCountArrRelInsertInput>;
  bilikZoneSitemap?: Maybe<SitemapBilikZoneObjRelInsertInput>;
  citySitemap?: Maybe<SitemapCityArrRelInsertInput>;
  id?: Maybe<Scalars['Int']>;
  isAnonymised?: Maybe<Scalars['Boolean']>;
  isCallRecordable?: Maybe<Scalars['Boolean']>;
  isRgeFilterable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  mainTradeProPresentation?: Maybe<ProPresentationArrRelInsertInput>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pageSubtitle?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  pageTitleDirectory?: Maybe<Scalars['String']>;
  pageTitleVariant?: Maybe<Scalars['String']>;
  secondaryTradeProPresentation?: Maybe<ProPresentationArrRelInsertInput>;
  slug?: Maybe<Scalars['String']>;
  tradeGroups?: Maybe<TradeTradeGroupArrRelInsertInput>;
  tradeGuidesByFirstTrade?: Maybe<TradeGuideArrRelInsertInput>;
  tradeGuidesBySecondTrade?: Maybe<TradeGuideArrRelInsertInput>;
  tradeKeywords?: Maybe<TradeKeywordArrRelInsertInput>;
  tradeProViews?: Maybe<TradeProViewArrRelInsertInput>;
  tradeQuestions?: Maybe<TradeQuestionArrRelInsertInput>;
};

/** columns and relationships of "trade_keyword" */
export type TradeKeyword = {
  __typename?: 'TradeKeyword';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  trade: Trade;
  tradeId: Scalars['Int'];
};

/** aggregated selection of "trade_keyword" */
export type TradeKeywordAggregate = {
  __typename?: 'TradeKeywordAggregate';
  aggregate?: Maybe<TradeKeywordAggregateFields>;
  nodes: Array<TradeKeyword>;
};

export type TradeKeywordAggregateBoolExp = {
  count?: Maybe<TradeKeywordAggregateBoolExpCount>;
};

export type TradeKeywordAggregateBoolExpCount = {
  arguments?: Maybe<Array<TradeKeywordSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TradeKeywordBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "trade_keyword" */
export type TradeKeywordAggregateFields = {
  __typename?: 'TradeKeywordAggregateFields';
  avg?: Maybe<TradeKeywordAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TradeKeywordMaxFields>;
  min?: Maybe<TradeKeywordMinFields>;
  stddev?: Maybe<TradeKeywordStddevFields>;
  stddevPop?: Maybe<TradeKeywordStddevPopFields>;
  stddevSamp?: Maybe<TradeKeywordStddevSampFields>;
  sum?: Maybe<TradeKeywordSumFields>;
  varPop?: Maybe<TradeKeywordVarPopFields>;
  varSamp?: Maybe<TradeKeywordVarSampFields>;
  variance?: Maybe<TradeKeywordVarianceFields>;
};


/** aggregate fields of "trade_keyword" */
export type TradeKeywordAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TradeKeywordSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_keyword" */
export type TradeKeywordAggregateOrderBy = {
  avg?: Maybe<TradeKeywordAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<TradeKeywordMaxOrderBy>;
  min?: Maybe<TradeKeywordMinOrderBy>;
  stddev?: Maybe<TradeKeywordStddevOrderBy>;
  stddevPop?: Maybe<TradeKeywordStddevPopOrderBy>;
  stddevSamp?: Maybe<TradeKeywordStddevSampOrderBy>;
  sum?: Maybe<TradeKeywordSumOrderBy>;
  varPop?: Maybe<TradeKeywordVarPopOrderBy>;
  varSamp?: Maybe<TradeKeywordVarSampOrderBy>;
  variance?: Maybe<TradeKeywordVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "trade_keyword" */
export type TradeKeywordArrRelInsertInput = {
  data: Array<TradeKeywordInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<TradeKeywordOnConflict>;
};

/** aggregate avg on columns */
export type TradeKeywordAvgFields = {
  __typename?: 'TradeKeywordAvgFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_keyword" */
export type TradeKeywordAvgOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "trade_keyword". All fields are combined with a logical 'AND'. */
export type TradeKeywordBoolExp = {
  _and?: Maybe<Array<TradeKeywordBoolExp>>;
  _not?: Maybe<TradeKeywordBoolExp>;
  _or?: Maybe<Array<TradeKeywordBoolExp>>;
  id?: Maybe<IntComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  trade?: Maybe<TradeBoolExp>;
  tradeId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "trade_keyword" */
export enum TradeKeywordConstraint {
  /** unique or primary key constraint on columns "id" */
  KeywordPkey = 'keyword_pkey'
}

/** input type for incrementing numeric columns in table "trade_keyword" */
export type TradeKeywordIncInput = {
  id?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade_keyword" */
export type TradeKeywordInsertInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  trade?: Maybe<TradeObjRelInsertInput>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TradeKeywordMaxFields = {
  __typename?: 'TradeKeywordMaxFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "trade_keyword" */
export type TradeKeywordMaxOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type TradeKeywordMinFields = {
  __typename?: 'TradeKeywordMinFields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "trade_keyword" */
export type TradeKeywordMinOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "trade_keyword" */
export type TradeKeywordMutationResponse = {
  __typename?: 'TradeKeywordMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TradeKeyword>;
};

/** on_conflict condition type for table "trade_keyword" */
export type TradeKeywordOnConflict = {
  constraint: TradeKeywordConstraint;
  updateColumns: Array<TradeKeywordUpdateColumn>;
  where?: Maybe<TradeKeywordBoolExp>;
};

/** Ordering options when selecting data from "trade_keyword". */
export type TradeKeywordOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  trade?: Maybe<TradeOrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** primary key columns input for table: trade_keyword */
export type TradeKeywordPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "trade_keyword" */
export enum TradeKeywordSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TradeId = 'tradeId'
}

/** input type for updating data in table "trade_keyword" */
export type TradeKeywordSetInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TradeKeywordStddevFields = {
  __typename?: 'TradeKeywordStddevFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_keyword" */
export type TradeKeywordStddevOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TradeKeywordStddevPopFields = {
  __typename?: 'TradeKeywordStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "trade_keyword" */
export type TradeKeywordStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TradeKeywordStddevSampFields = {
  __typename?: 'TradeKeywordStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "trade_keyword" */
export type TradeKeywordStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "trade_keyword" */
export type TradeKeywordStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeKeywordStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeKeywordStreamCursorValueInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TradeKeywordSumFields = {
  __typename?: 'TradeKeywordSumFields';
  id?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade_keyword" */
export type TradeKeywordSumOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** update columns of table "trade_keyword" */
export enum TradeKeywordUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TradeId = 'tradeId'
}

export type TradeKeywordUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TradeKeywordIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TradeKeywordSetInput>;
  /** filter the rows which have to be updated */
  where: TradeKeywordBoolExp;
};

/** aggregate variance on columns */
export type TradeKeywordVarianceFields = {
  __typename?: 'TradeKeywordVarianceFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_keyword" */
export type TradeKeywordVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type TradeKeywordVarPopFields = {
  __typename?: 'TradeKeywordVarPopFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "trade_keyword" */
export type TradeKeywordVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TradeKeywordVarSampFields = {
  __typename?: 'TradeKeywordVarSampFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "trade_keyword" */
export type TradeKeywordVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** columns and relationships of "trade_main_pro_count" */
export type TradeMainProCount = {
  __typename?: 'TradeMainProCount';
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  trade?: Maybe<Trade>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "trade_main_pro_count" */
export type TradeMainProCountAggregate = {
  __typename?: 'TradeMainProCountAggregate';
  aggregate?: Maybe<TradeMainProCountAggregateFields>;
  nodes: Array<TradeMainProCount>;
};

/** aggregate fields of "trade_main_pro_count" */
export type TradeMainProCountAggregateFields = {
  __typename?: 'TradeMainProCountAggregateFields';
  avg?: Maybe<TradeMainProCountAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TradeMainProCountMaxFields>;
  min?: Maybe<TradeMainProCountMinFields>;
  stddev?: Maybe<TradeMainProCountStddevFields>;
  stddevPop?: Maybe<TradeMainProCountStddevPopFields>;
  stddevSamp?: Maybe<TradeMainProCountStddevSampFields>;
  sum?: Maybe<TradeMainProCountSumFields>;
  varPop?: Maybe<TradeMainProCountVarPopFields>;
  varSamp?: Maybe<TradeMainProCountVarSampFields>;
  variance?: Maybe<TradeMainProCountVarianceFields>;
};


/** aggregate fields of "trade_main_pro_count" */
export type TradeMainProCountAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TradeMainProCountSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TradeMainProCountAvgFields = {
  __typename?: 'TradeMainProCountAvgFields';
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "trade_main_pro_count". All fields are combined with a logical 'AND'. */
export type TradeMainProCountBoolExp = {
  _and?: Maybe<Array<TradeMainProCountBoolExp>>;
  _not?: Maybe<TradeMainProCountBoolExp>;
  _or?: Maybe<Array<TradeMainProCountBoolExp>>;
  count?: Maybe<BigintComparisonExp>;
  trade?: Maybe<TradeBoolExp>;
  tradeId?: Maybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type TradeMainProCountMaxFields = {
  __typename?: 'TradeMainProCountMaxFields';
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type TradeMainProCountMinFields = {
  __typename?: 'TradeMainProCountMinFields';
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "trade_main_pro_count". */
export type TradeMainProCountOrderBy = {
  count?: Maybe<OrderBy>;
  trade?: Maybe<TradeOrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** select columns of table "trade_main_pro_count" */
export enum TradeMainProCountSelectColumn {
  /** column name */
  Count = 'count',
  /** column name */
  TradeId = 'tradeId'
}

/** aggregate stddev on columns */
export type TradeMainProCountStddevFields = {
  __typename?: 'TradeMainProCountStddevFields';
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type TradeMainProCountStddevPopFields = {
  __typename?: 'TradeMainProCountStddevPopFields';
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type TradeMainProCountStddevSampFields = {
  __typename?: 'TradeMainProCountStddevSampFields';
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "trade_main_pro_count" */
export type TradeMainProCountStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeMainProCountStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeMainProCountStreamCursorValueInput = {
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TradeMainProCountSumFields = {
  __typename?: 'TradeMainProCountSumFields';
  count?: Maybe<Scalars['bigint']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate variance on columns */
export type TradeMainProCountVarianceFields = {
  __typename?: 'TradeMainProCountVarianceFields';
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type TradeMainProCountVarPopFields = {
  __typename?: 'TradeMainProCountVarPopFields';
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type TradeMainProCountVarSampFields = {
  __typename?: 'TradeMainProCountVarSampFields';
  count?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type TradeMaxFields = {
  __typename?: 'TradeMaxFields';
  anchor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pageSubtitle?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  pageTitleDirectory?: Maybe<Scalars['String']>;
  pageTitleVariant?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type TradeMinFields = {
  __typename?: 'TradeMinFields';
  anchor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pageSubtitle?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  pageTitleDirectory?: Maybe<Scalars['String']>;
  pageTitleVariant?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "trade" */
export type TradeMutationResponse = {
  __typename?: 'TradeMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trade>;
};

/** input type for inserting object relation for remote table "trade" */
export type TradeObjRelInsertInput = {
  data: TradeInsertInput;
  /** upsert condition */
  onConflict?: Maybe<TradeOnConflict>;
};

/** on_conflict condition type for table "trade" */
export type TradeOnConflict = {
  constraint: TradeConstraint;
  updateColumns: Array<TradeUpdateColumn>;
  where?: Maybe<TradeBoolExp>;
};

/** Ordering options when selecting data from "trade". */
export type TradeOrderBy = {
  anchor?: Maybe<OrderBy>;
  bilikZoneMainProCountAggregate?: Maybe<BilikZoneTradeMainProCountAggregateOrderBy>;
  bilikZoneSitemap?: Maybe<SitemapBilikZoneOrderBy>;
  citySitemapAggregate?: Maybe<SitemapCityAggregateOrderBy>;
  id?: Maybe<OrderBy>;
  isAnonymised?: Maybe<OrderBy>;
  isCallRecordable?: Maybe<OrderBy>;
  isRgeFilterable?: Maybe<OrderBy>;
  label?: Maybe<OrderBy>;
  mainTradeProPresentationAggregate?: Maybe<ProPresentationAggregateOrderBy>;
  metaDescription?: Maybe<OrderBy>;
  metaTitle?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  pageSubtitle?: Maybe<OrderBy>;
  pageTitle?: Maybe<OrderBy>;
  pageTitleDirectory?: Maybe<OrderBy>;
  pageTitleVariant?: Maybe<OrderBy>;
  secondaryTradeProPresentationAggregate?: Maybe<ProPresentationAggregateOrderBy>;
  slug?: Maybe<OrderBy>;
  tradeGroupsAggregate?: Maybe<TradeTradeGroupAggregateOrderBy>;
  tradeGuidesByFirstTradeAggregate?: Maybe<TradeGuideAggregateOrderBy>;
  tradeGuidesBySecondTradeAggregate?: Maybe<TradeGuideAggregateOrderBy>;
  tradeKeywordsAggregate?: Maybe<TradeKeywordAggregateOrderBy>;
  tradeProViewsAggregate?: Maybe<TradeProViewAggregateOrderBy>;
  tradeQuestionsAggregate?: Maybe<TradeQuestionAggregateOrderBy>;
};

/** primary key columns input for table: trade */
export type TradePkColumnsInput = {
  id: Scalars['Int'];
};

/** columns and relationships of "trade_pro_view" */
export type TradeProView = {
  __typename?: 'TradeProView';
  /** An object relationship */
  proView: ProView;
  proViewId: Scalars['Int'];
  /** An object relationship */
  trade: Trade;
  tradeId: Scalars['Int'];
};

/** aggregated selection of "trade_pro_view" */
export type TradeProViewAggregate = {
  __typename?: 'TradeProViewAggregate';
  aggregate?: Maybe<TradeProViewAggregateFields>;
  nodes: Array<TradeProView>;
};

export type TradeProViewAggregateBoolExp = {
  count?: Maybe<TradeProViewAggregateBoolExpCount>;
};

export type TradeProViewAggregateBoolExpCount = {
  arguments?: Maybe<Array<TradeProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TradeProViewBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "trade_pro_view" */
export type TradeProViewAggregateFields = {
  __typename?: 'TradeProViewAggregateFields';
  avg?: Maybe<TradeProViewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TradeProViewMaxFields>;
  min?: Maybe<TradeProViewMinFields>;
  stddev?: Maybe<TradeProViewStddevFields>;
  stddevPop?: Maybe<TradeProViewStddevPopFields>;
  stddevSamp?: Maybe<TradeProViewStddevSampFields>;
  sum?: Maybe<TradeProViewSumFields>;
  varPop?: Maybe<TradeProViewVarPopFields>;
  varSamp?: Maybe<TradeProViewVarSampFields>;
  variance?: Maybe<TradeProViewVarianceFields>;
};


/** aggregate fields of "trade_pro_view" */
export type TradeProViewAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TradeProViewSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_pro_view" */
export type TradeProViewAggregateOrderBy = {
  avg?: Maybe<TradeProViewAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<TradeProViewMaxOrderBy>;
  min?: Maybe<TradeProViewMinOrderBy>;
  stddev?: Maybe<TradeProViewStddevOrderBy>;
  stddevPop?: Maybe<TradeProViewStddevPopOrderBy>;
  stddevSamp?: Maybe<TradeProViewStddevSampOrderBy>;
  sum?: Maybe<TradeProViewSumOrderBy>;
  varPop?: Maybe<TradeProViewVarPopOrderBy>;
  varSamp?: Maybe<TradeProViewVarSampOrderBy>;
  variance?: Maybe<TradeProViewVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "trade_pro_view" */
export type TradeProViewArrRelInsertInput = {
  data: Array<TradeProViewInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<TradeProViewOnConflict>;
};

/** aggregate avg on columns */
export type TradeProViewAvgFields = {
  __typename?: 'TradeProViewAvgFields';
  proViewId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_pro_view" */
export type TradeProViewAvgOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "trade_pro_view". All fields are combined with a logical 'AND'. */
export type TradeProViewBoolExp = {
  _and?: Maybe<Array<TradeProViewBoolExp>>;
  _not?: Maybe<TradeProViewBoolExp>;
  _or?: Maybe<Array<TradeProViewBoolExp>>;
  proView?: Maybe<ProViewBoolExp>;
  proViewId?: Maybe<IntComparisonExp>;
  trade?: Maybe<TradeBoolExp>;
  tradeId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "trade_pro_view" */
export enum TradeProViewConstraint {
  /** unique or primary key constraint on columns "pro_view_id", "trade_id" */
  Idx_23093Primary = 'idx_23093_primary'
}

/** input type for incrementing numeric columns in table "trade_pro_view" */
export type TradeProViewIncInput = {
  proViewId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade_pro_view" */
export type TradeProViewInsertInput = {
  proView?: Maybe<ProViewObjRelInsertInput>;
  proViewId?: Maybe<Scalars['Int']>;
  trade?: Maybe<TradeObjRelInsertInput>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TradeProViewMaxFields = {
  __typename?: 'TradeProViewMaxFields';
  proViewId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "trade_pro_view" */
export type TradeProViewMaxOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type TradeProViewMinFields = {
  __typename?: 'TradeProViewMinFields';
  proViewId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "trade_pro_view" */
export type TradeProViewMinOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "trade_pro_view" */
export type TradeProViewMutationResponse = {
  __typename?: 'TradeProViewMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TradeProView>;
};

/** on_conflict condition type for table "trade_pro_view" */
export type TradeProViewOnConflict = {
  constraint: TradeProViewConstraint;
  updateColumns: Array<TradeProViewUpdateColumn>;
  where?: Maybe<TradeProViewBoolExp>;
};

/** Ordering options when selecting data from "trade_pro_view". */
export type TradeProViewOrderBy = {
  proView?: Maybe<ProViewOrderBy>;
  proViewId?: Maybe<OrderBy>;
  trade?: Maybe<TradeOrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** primary key columns input for table: trade_pro_view */
export type TradeProViewPkColumnsInput = {
  proViewId: Scalars['Int'];
  tradeId: Scalars['Int'];
};

/** select columns of table "trade_pro_view" */
export enum TradeProViewSelectColumn {
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  TradeId = 'tradeId'
}

/** input type for updating data in table "trade_pro_view" */
export type TradeProViewSetInput = {
  proViewId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TradeProViewStddevFields = {
  __typename?: 'TradeProViewStddevFields';
  proViewId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_pro_view" */
export type TradeProViewStddevOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TradeProViewStddevPopFields = {
  __typename?: 'TradeProViewStddevPopFields';
  proViewId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "trade_pro_view" */
export type TradeProViewStddevPopOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TradeProViewStddevSampFields = {
  __typename?: 'TradeProViewStddevSampFields';
  proViewId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "trade_pro_view" */
export type TradeProViewStddevSampOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "trade_pro_view" */
export type TradeProViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeProViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeProViewStreamCursorValueInput = {
  proViewId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TradeProViewSumFields = {
  __typename?: 'TradeProViewSumFields';
  proViewId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade_pro_view" */
export type TradeProViewSumOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** update columns of table "trade_pro_view" */
export enum TradeProViewUpdateColumn {
  /** column name */
  ProViewId = 'proViewId',
  /** column name */
  TradeId = 'tradeId'
}

export type TradeProViewUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TradeProViewIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TradeProViewSetInput>;
  /** filter the rows which have to be updated */
  where: TradeProViewBoolExp;
};

/** aggregate variance on columns */
export type TradeProViewVarianceFields = {
  __typename?: 'TradeProViewVarianceFields';
  proViewId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_pro_view" */
export type TradeProViewVarianceOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type TradeProViewVarPopFields = {
  __typename?: 'TradeProViewVarPopFields';
  proViewId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "trade_pro_view" */
export type TradeProViewVarPopOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TradeProViewVarSampFields = {
  __typename?: 'TradeProViewVarSampFields';
  proViewId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "trade_pro_view" */
export type TradeProViewVarSampOrderBy = {
  proViewId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** columns and relationships of "trade_question" */
export type TradeQuestion = {
  __typename?: 'TradeQuestion';
  answer: Scalars['String'];
  id: Scalars['Int'];
  question: Scalars['String'];
  /** An object relationship */
  trade: Trade;
  tradeId: Scalars['Int'];
};

/** aggregated selection of "trade_question" */
export type TradeQuestionAggregate = {
  __typename?: 'TradeQuestionAggregate';
  aggregate?: Maybe<TradeQuestionAggregateFields>;
  nodes: Array<TradeQuestion>;
};

export type TradeQuestionAggregateBoolExp = {
  count?: Maybe<TradeQuestionAggregateBoolExpCount>;
};

export type TradeQuestionAggregateBoolExpCount = {
  arguments?: Maybe<Array<TradeQuestionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TradeQuestionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "trade_question" */
export type TradeQuestionAggregateFields = {
  __typename?: 'TradeQuestionAggregateFields';
  avg?: Maybe<TradeQuestionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TradeQuestionMaxFields>;
  min?: Maybe<TradeQuestionMinFields>;
  stddev?: Maybe<TradeQuestionStddevFields>;
  stddevPop?: Maybe<TradeQuestionStddevPopFields>;
  stddevSamp?: Maybe<TradeQuestionStddevSampFields>;
  sum?: Maybe<TradeQuestionSumFields>;
  varPop?: Maybe<TradeQuestionVarPopFields>;
  varSamp?: Maybe<TradeQuestionVarSampFields>;
  variance?: Maybe<TradeQuestionVarianceFields>;
};


/** aggregate fields of "trade_question" */
export type TradeQuestionAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TradeQuestionSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_question" */
export type TradeQuestionAggregateOrderBy = {
  avg?: Maybe<TradeQuestionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<TradeQuestionMaxOrderBy>;
  min?: Maybe<TradeQuestionMinOrderBy>;
  stddev?: Maybe<TradeQuestionStddevOrderBy>;
  stddevPop?: Maybe<TradeQuestionStddevPopOrderBy>;
  stddevSamp?: Maybe<TradeQuestionStddevSampOrderBy>;
  sum?: Maybe<TradeQuestionSumOrderBy>;
  varPop?: Maybe<TradeQuestionVarPopOrderBy>;
  varSamp?: Maybe<TradeQuestionVarSampOrderBy>;
  variance?: Maybe<TradeQuestionVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "trade_question" */
export type TradeQuestionArrRelInsertInput = {
  data: Array<TradeQuestionInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<TradeQuestionOnConflict>;
};

/** aggregate avg on columns */
export type TradeQuestionAvgFields = {
  __typename?: 'TradeQuestionAvgFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_question" */
export type TradeQuestionAvgOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "trade_question". All fields are combined with a logical 'AND'. */
export type TradeQuestionBoolExp = {
  _and?: Maybe<Array<TradeQuestionBoolExp>>;
  _not?: Maybe<TradeQuestionBoolExp>;
  _or?: Maybe<Array<TradeQuestionBoolExp>>;
  answer?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  question?: Maybe<StringComparisonExp>;
  trade?: Maybe<TradeBoolExp>;
  tradeId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "trade_question" */
export enum TradeQuestionConstraint {
  /** unique or primary key constraint on columns "id" */
  TradeQuestionPkey = 'trade_question_pkey'
}

/** input type for incrementing numeric columns in table "trade_question" */
export type TradeQuestionIncInput = {
  id?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade_question" */
export type TradeQuestionInsertInput = {
  answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  trade?: Maybe<TradeObjRelInsertInput>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TradeQuestionMaxFields = {
  __typename?: 'TradeQuestionMaxFields';
  answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "trade_question" */
export type TradeQuestionMaxOrderBy = {
  answer?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  question?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type TradeQuestionMinFields = {
  __typename?: 'TradeQuestionMinFields';
  answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "trade_question" */
export type TradeQuestionMinOrderBy = {
  answer?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  question?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "trade_question" */
export type TradeQuestionMutationResponse = {
  __typename?: 'TradeQuestionMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TradeQuestion>;
};

/** on_conflict condition type for table "trade_question" */
export type TradeQuestionOnConflict = {
  constraint: TradeQuestionConstraint;
  updateColumns: Array<TradeQuestionUpdateColumn>;
  where?: Maybe<TradeQuestionBoolExp>;
};

/** Ordering options when selecting data from "trade_question". */
export type TradeQuestionOrderBy = {
  answer?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  question?: Maybe<OrderBy>;
  trade?: Maybe<TradeOrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** primary key columns input for table: trade_question */
export type TradeQuestionPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "trade_question" */
export enum TradeQuestionSelectColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  TradeId = 'tradeId'
}

/** input type for updating data in table "trade_question" */
export type TradeQuestionSetInput = {
  answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TradeQuestionStddevFields = {
  __typename?: 'TradeQuestionStddevFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_question" */
export type TradeQuestionStddevOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TradeQuestionStddevPopFields = {
  __typename?: 'TradeQuestionStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "trade_question" */
export type TradeQuestionStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TradeQuestionStddevSampFields = {
  __typename?: 'TradeQuestionStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "trade_question" */
export type TradeQuestionStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "trade_question" */
export type TradeQuestionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeQuestionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeQuestionStreamCursorValueInput = {
  answer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TradeQuestionSumFields = {
  __typename?: 'TradeQuestionSumFields';
  id?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade_question" */
export type TradeQuestionSumOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** update columns of table "trade_question" */
export enum TradeQuestionUpdateColumn {
  /** column name */
  Answer = 'answer',
  /** column name */
  Id = 'id',
  /** column name */
  Question = 'question',
  /** column name */
  TradeId = 'tradeId'
}

export type TradeQuestionUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TradeQuestionIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TradeQuestionSetInput>;
  /** filter the rows which have to be updated */
  where: TradeQuestionBoolExp;
};

/** aggregate variance on columns */
export type TradeQuestionVarianceFields = {
  __typename?: 'TradeQuestionVarianceFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_question" */
export type TradeQuestionVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type TradeQuestionVarPopFields = {
  __typename?: 'TradeQuestionVarPopFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "trade_question" */
export type TradeQuestionVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TradeQuestionVarSampFields = {
  __typename?: 'TradeQuestionVarSampFields';
  id?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "trade_question" */
export type TradeQuestionVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** select columns of table "trade" */
export enum TradeSelectColumn {
  /** column name */
  Anchor = 'anchor',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymised = 'isAnonymised',
  /** column name */
  IsCallRecordable = 'isCallRecordable',
  /** column name */
  IsRgeFilterable = 'isRgeFilterable',
  /** column name */
  Label = 'label',
  /** column name */
  MetaDescription = 'metaDescription',
  /** column name */
  MetaTitle = 'metaTitle',
  /** column name */
  Name = 'name',
  /** column name */
  PageSubtitle = 'pageSubtitle',
  /** column name */
  PageTitle = 'pageTitle',
  /** column name */
  PageTitleDirectory = 'pageTitleDirectory',
  /** column name */
  PageTitleVariant = 'pageTitleVariant',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "trade" */
export type TradeSetInput = {
  anchor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isAnonymised?: Maybe<Scalars['Boolean']>;
  isCallRecordable?: Maybe<Scalars['Boolean']>;
  isRgeFilterable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pageSubtitle?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  pageTitleDirectory?: Maybe<Scalars['String']>;
  pageTitleVariant?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type TradeStddevFields = {
  __typename?: 'TradeStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type TradeStddevPopFields = {
  __typename?: 'TradeStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type TradeStddevSampFields = {
  __typename?: 'TradeStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "trade" */
export type TradeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeStreamCursorValueInput = {
  anchor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isAnonymised?: Maybe<Scalars['Boolean']>;
  isCallRecordable?: Maybe<Scalars['Boolean']>;
  isRgeFilterable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pageSubtitle?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  pageTitleDirectory?: Maybe<Scalars['String']>;
  pageTitleVariant?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type TradeSumFields = {
  __typename?: 'TradeSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "trade_trade_group" */
export type TradeTradeGroup = {
  __typename?: 'TradeTradeGroup';
  /** An object relationship */
  trade: Trade;
  /** An object relationship */
  tradeGroup: TradeGroup;
  tradeGroupId: Scalars['Int'];
  tradeId: Scalars['Int'];
};

/** aggregated selection of "trade_trade_group" */
export type TradeTradeGroupAggregate = {
  __typename?: 'TradeTradeGroupAggregate';
  aggregate?: Maybe<TradeTradeGroupAggregateFields>;
  nodes: Array<TradeTradeGroup>;
};

export type TradeTradeGroupAggregateBoolExp = {
  count?: Maybe<TradeTradeGroupAggregateBoolExpCount>;
};

export type TradeTradeGroupAggregateBoolExpCount = {
  arguments?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<TradeTradeGroupBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "trade_trade_group" */
export type TradeTradeGroupAggregateFields = {
  __typename?: 'TradeTradeGroupAggregateFields';
  avg?: Maybe<TradeTradeGroupAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TradeTradeGroupMaxFields>;
  min?: Maybe<TradeTradeGroupMinFields>;
  stddev?: Maybe<TradeTradeGroupStddevFields>;
  stddevPop?: Maybe<TradeTradeGroupStddevPopFields>;
  stddevSamp?: Maybe<TradeTradeGroupStddevSampFields>;
  sum?: Maybe<TradeTradeGroupSumFields>;
  varPop?: Maybe<TradeTradeGroupVarPopFields>;
  varSamp?: Maybe<TradeTradeGroupVarSampFields>;
  variance?: Maybe<TradeTradeGroupVarianceFields>;
};


/** aggregate fields of "trade_trade_group" */
export type TradeTradeGroupAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TradeTradeGroupSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trade_trade_group" */
export type TradeTradeGroupAggregateOrderBy = {
  avg?: Maybe<TradeTradeGroupAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<TradeTradeGroupMaxOrderBy>;
  min?: Maybe<TradeTradeGroupMinOrderBy>;
  stddev?: Maybe<TradeTradeGroupStddevOrderBy>;
  stddevPop?: Maybe<TradeTradeGroupStddevPopOrderBy>;
  stddevSamp?: Maybe<TradeTradeGroupStddevSampOrderBy>;
  sum?: Maybe<TradeTradeGroupSumOrderBy>;
  varPop?: Maybe<TradeTradeGroupVarPopOrderBy>;
  varSamp?: Maybe<TradeTradeGroupVarSampOrderBy>;
  variance?: Maybe<TradeTradeGroupVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "trade_trade_group" */
export type TradeTradeGroupArrRelInsertInput = {
  data: Array<TradeTradeGroupInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<TradeTradeGroupOnConflict>;
};

/** aggregate avg on columns */
export type TradeTradeGroupAvgFields = {
  __typename?: 'TradeTradeGroupAvgFields';
  tradeGroupId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "trade_trade_group" */
export type TradeTradeGroupAvgOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "trade_trade_group". All fields are combined with a logical 'AND'. */
export type TradeTradeGroupBoolExp = {
  _and?: Maybe<Array<TradeTradeGroupBoolExp>>;
  _not?: Maybe<TradeTradeGroupBoolExp>;
  _or?: Maybe<Array<TradeTradeGroupBoolExp>>;
  trade?: Maybe<TradeBoolExp>;
  tradeGroup?: Maybe<TradeGroupBoolExp>;
  tradeGroupId?: Maybe<IntComparisonExp>;
  tradeId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "trade_trade_group" */
export enum TradeTradeGroupConstraint {
  /** unique or primary key constraint on columns "trade_group_id", "trade_id" */
  Idx_23096Primary = 'idx_23096_primary'
}

/** input type for incrementing numeric columns in table "trade_trade_group" */
export type TradeTradeGroupIncInput = {
  tradeGroupId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "trade_trade_group" */
export type TradeTradeGroupInsertInput = {
  trade?: Maybe<TradeObjRelInsertInput>;
  tradeGroup?: Maybe<TradeGroupObjRelInsertInput>;
  tradeGroupId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type TradeTradeGroupMaxFields = {
  __typename?: 'TradeTradeGroupMaxFields';
  tradeGroupId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "trade_trade_group" */
export type TradeTradeGroupMaxOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type TradeTradeGroupMinFields = {
  __typename?: 'TradeTradeGroupMinFields';
  tradeGroupId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "trade_trade_group" */
export type TradeTradeGroupMinOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "trade_trade_group" */
export type TradeTradeGroupMutationResponse = {
  __typename?: 'TradeTradeGroupMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TradeTradeGroup>;
};

/** on_conflict condition type for table "trade_trade_group" */
export type TradeTradeGroupOnConflict = {
  constraint: TradeTradeGroupConstraint;
  updateColumns: Array<TradeTradeGroupUpdateColumn>;
  where?: Maybe<TradeTradeGroupBoolExp>;
};

/** Ordering options when selecting data from "trade_trade_group". */
export type TradeTradeGroupOrderBy = {
  trade?: Maybe<TradeOrderBy>;
  tradeGroup?: Maybe<TradeGroupOrderBy>;
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** primary key columns input for table: trade_trade_group */
export type TradeTradeGroupPkColumnsInput = {
  tradeGroupId: Scalars['Int'];
  tradeId: Scalars['Int'];
};

/** select columns of table "trade_trade_group" */
export enum TradeTradeGroupSelectColumn {
  /** column name */
  TradeGroupId = 'tradeGroupId',
  /** column name */
  TradeId = 'tradeId'
}

/** input type for updating data in table "trade_trade_group" */
export type TradeTradeGroupSetInput = {
  tradeGroupId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type TradeTradeGroupStddevFields = {
  __typename?: 'TradeTradeGroupStddevFields';
  tradeGroupId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "trade_trade_group" */
export type TradeTradeGroupStddevOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type TradeTradeGroupStddevPopFields = {
  __typename?: 'TradeTradeGroupStddevPopFields';
  tradeGroupId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "trade_trade_group" */
export type TradeTradeGroupStddevPopOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type TradeTradeGroupStddevSampFields = {
  __typename?: 'TradeTradeGroupStddevSampFields';
  tradeGroupId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "trade_trade_group" */
export type TradeTradeGroupStddevSampOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "trade_trade_group" */
export type TradeTradeGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TradeTradeGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TradeTradeGroupStreamCursorValueInput = {
  tradeGroupId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type TradeTradeGroupSumFields = {
  __typename?: 'TradeTradeGroupSumFields';
  tradeGroupId?: Maybe<Scalars['Int']>;
  tradeId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "trade_trade_group" */
export type TradeTradeGroupSumOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** update columns of table "trade_trade_group" */
export enum TradeTradeGroupUpdateColumn {
  /** column name */
  TradeGroupId = 'tradeGroupId',
  /** column name */
  TradeId = 'tradeId'
}

export type TradeTradeGroupUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TradeTradeGroupIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TradeTradeGroupSetInput>;
  /** filter the rows which have to be updated */
  where: TradeTradeGroupBoolExp;
};

/** aggregate variance on columns */
export type TradeTradeGroupVarianceFields = {
  __typename?: 'TradeTradeGroupVarianceFields';
  tradeGroupId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "trade_trade_group" */
export type TradeTradeGroupVarianceOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type TradeTradeGroupVarPopFields = {
  __typename?: 'TradeTradeGroupVarPopFields';
  tradeGroupId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "trade_trade_group" */
export type TradeTradeGroupVarPopOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type TradeTradeGroupVarSampFields = {
  __typename?: 'TradeTradeGroupVarSampFields';
  tradeGroupId?: Maybe<Scalars['Float']>;
  tradeId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "trade_trade_group" */
export type TradeTradeGroupVarSampOrderBy = {
  tradeGroupId?: Maybe<OrderBy>;
  tradeId?: Maybe<OrderBy>;
};

/** update columns of table "trade" */
export enum TradeUpdateColumn {
  /** column name */
  Anchor = 'anchor',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymised = 'isAnonymised',
  /** column name */
  IsCallRecordable = 'isCallRecordable',
  /** column name */
  IsRgeFilterable = 'isRgeFilterable',
  /** column name */
  Label = 'label',
  /** column name */
  MetaDescription = 'metaDescription',
  /** column name */
  MetaTitle = 'metaTitle',
  /** column name */
  Name = 'name',
  /** column name */
  PageSubtitle = 'pageSubtitle',
  /** column name */
  PageTitle = 'pageTitle',
  /** column name */
  PageTitleDirectory = 'pageTitleDirectory',
  /** column name */
  PageTitleVariant = 'pageTitleVariant',
  /** column name */
  Slug = 'slug'
}

export type TradeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<TradeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<TradeSetInput>;
  /** filter the rows which have to be updated */
  where: TradeBoolExp;
};

/** aggregate variance on columns */
export type TradeVarianceFields = {
  __typename?: 'TradeVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type TradeVarPopFields = {
  __typename?: 'TradeVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type TradeVarSampFields = {
  __typename?: 'TradeVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoho_consumer" */
export type ZohoConsumer = {
  __typename?: 'ZohoConsumer';
  consumerId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  proOrganization: ProOrganization;
  proOrganizationId: Scalars['Int'];
  /** An object relationship */
  zohoOrganization: ZohoOrganization;
  zohoOrganizationId: Scalars['Int'];
};

/** aggregated selection of "zoho_consumer" */
export type ZohoConsumerAggregate = {
  __typename?: 'ZohoConsumerAggregate';
  aggregate?: Maybe<ZohoConsumerAggregateFields>;
  nodes: Array<ZohoConsumer>;
};

export type ZohoConsumerAggregateBoolExp = {
  count?: Maybe<ZohoConsumerAggregateBoolExpCount>;
};

export type ZohoConsumerAggregateBoolExpCount = {
  arguments?: Maybe<Array<ZohoConsumerSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ZohoConsumerBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "zoho_consumer" */
export type ZohoConsumerAggregateFields = {
  __typename?: 'ZohoConsumerAggregateFields';
  avg?: Maybe<ZohoConsumerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ZohoConsumerMaxFields>;
  min?: Maybe<ZohoConsumerMinFields>;
  stddev?: Maybe<ZohoConsumerStddevFields>;
  stddevPop?: Maybe<ZohoConsumerStddevPopFields>;
  stddevSamp?: Maybe<ZohoConsumerStddevSampFields>;
  sum?: Maybe<ZohoConsumerSumFields>;
  varPop?: Maybe<ZohoConsumerVarPopFields>;
  varSamp?: Maybe<ZohoConsumerVarSampFields>;
  variance?: Maybe<ZohoConsumerVarianceFields>;
};


/** aggregate fields of "zoho_consumer" */
export type ZohoConsumerAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ZohoConsumerSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoho_consumer" */
export type ZohoConsumerAggregateOrderBy = {
  avg?: Maybe<ZohoConsumerAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ZohoConsumerMaxOrderBy>;
  min?: Maybe<ZohoConsumerMinOrderBy>;
  stddev?: Maybe<ZohoConsumerStddevOrderBy>;
  stddevPop?: Maybe<ZohoConsumerStddevPopOrderBy>;
  stddevSamp?: Maybe<ZohoConsumerStddevSampOrderBy>;
  sum?: Maybe<ZohoConsumerSumOrderBy>;
  varPop?: Maybe<ZohoConsumerVarPopOrderBy>;
  varSamp?: Maybe<ZohoConsumerVarSampOrderBy>;
  variance?: Maybe<ZohoConsumerVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "zoho_consumer" */
export type ZohoConsumerArrRelInsertInput = {
  data: Array<ZohoConsumerInsertInput>;
  /** upsert condition */
  onConflict?: Maybe<ZohoConsumerOnConflict>;
};

/** aggregate avg on columns */
export type ZohoConsumerAvgFields = {
  __typename?: 'ZohoConsumerAvgFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zoho_consumer" */
export type ZohoConsumerAvgOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "zoho_consumer". All fields are combined with a logical 'AND'. */
export type ZohoConsumerBoolExp = {
  _and?: Maybe<Array<ZohoConsumerBoolExp>>;
  _not?: Maybe<ZohoConsumerBoolExp>;
  _or?: Maybe<Array<ZohoConsumerBoolExp>>;
  consumerId?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  proOrganization?: Maybe<ProOrganizationBoolExp>;
  proOrganizationId?: Maybe<IntComparisonExp>;
  zohoOrganization?: Maybe<ZohoOrganizationBoolExp>;
  zohoOrganizationId?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "zoho_consumer" */
export enum ZohoConsumerConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_23101Primary = 'idx_23101_primary'
}

/** input type for incrementing numeric columns in table "zoho_consumer" */
export type ZohoConsumerIncInput = {
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoho_consumer" */
export type ZohoConsumerInsertInput = {
  consumerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganization?: Maybe<ProOrganizationObjRelInsertInput>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganization?: Maybe<ZohoOrganizationObjRelInsertInput>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ZohoConsumerMaxFields = {
  __typename?: 'ZohoConsumerMaxFields';
  consumerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "zoho_consumer" */
export type ZohoConsumerMaxOrderBy = {
  consumerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ZohoConsumerMinFields = {
  __typename?: 'ZohoConsumerMinFields';
  consumerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "zoho_consumer" */
export type ZohoConsumerMinOrderBy = {
  consumerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** response of any mutation on the table "zoho_consumer" */
export type ZohoConsumerMutationResponse = {
  __typename?: 'ZohoConsumerMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZohoConsumer>;
};

/** on_conflict condition type for table "zoho_consumer" */
export type ZohoConsumerOnConflict = {
  constraint: ZohoConsumerConstraint;
  updateColumns: Array<ZohoConsumerUpdateColumn>;
  where?: Maybe<ZohoConsumerBoolExp>;
};

/** Ordering options when selecting data from "zoho_consumer". */
export type ZohoConsumerOrderBy = {
  consumerId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  proOrganization?: Maybe<ProOrganizationOrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganization?: Maybe<ZohoOrganizationOrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** primary key columns input for table: zoho_consumer */
export type ZohoConsumerPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "zoho_consumer" */
export enum ZohoConsumerSelectColumn {
  /** column name */
  ConsumerId = 'consumerId',
  /** column name */
  Id = 'id',
  /** column name */
  ProOrganizationId = 'proOrganizationId',
  /** column name */
  ZohoOrganizationId = 'zohoOrganizationId'
}

/** input type for updating data in table "zoho_consumer" */
export type ZohoConsumerSetInput = {
  consumerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ZohoConsumerStddevFields = {
  __typename?: 'ZohoConsumerStddevFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zoho_consumer" */
export type ZohoConsumerStddevOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type ZohoConsumerStddevPopFields = {
  __typename?: 'ZohoConsumerStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "zoho_consumer" */
export type ZohoConsumerStddevPopOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type ZohoConsumerStddevSampFields = {
  __typename?: 'ZohoConsumerStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "zoho_consumer" */
export type ZohoConsumerStddevSampOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** Streaming cursor of the table "zoho_consumer" */
export type ZohoConsumerStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZohoConsumerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZohoConsumerStreamCursorValueInput = {
  consumerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type ZohoConsumerSumFields = {
  __typename?: 'ZohoConsumerSumFields';
  id?: Maybe<Scalars['Int']>;
  proOrganizationId?: Maybe<Scalars['Int']>;
  zohoOrganizationId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "zoho_consumer" */
export type ZohoConsumerSumOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** update columns of table "zoho_consumer" */
export enum ZohoConsumerUpdateColumn {
  /** column name */
  ConsumerId = 'consumerId',
  /** column name */
  Id = 'id',
  /** column name */
  ProOrganizationId = 'proOrganizationId',
  /** column name */
  ZohoOrganizationId = 'zohoOrganizationId'
}

export type ZohoConsumerUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ZohoConsumerIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ZohoConsumerSetInput>;
  /** filter the rows which have to be updated */
  where: ZohoConsumerBoolExp;
};

/** aggregate variance on columns */
export type ZohoConsumerVarianceFields = {
  __typename?: 'ZohoConsumerVarianceFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zoho_consumer" */
export type ZohoConsumerVarianceOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate varPop on columns */
export type ZohoConsumerVarPopFields = {
  __typename?: 'ZohoConsumerVarPopFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "zoho_consumer" */
export type ZohoConsumerVarPopOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type ZohoConsumerVarSampFields = {
  __typename?: 'ZohoConsumerVarSampFields';
  id?: Maybe<Scalars['Float']>;
  proOrganizationId?: Maybe<Scalars['Float']>;
  zohoOrganizationId?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "zoho_consumer" */
export type ZohoConsumerVarSampOrderBy = {
  id?: Maybe<OrderBy>;
  proOrganizationId?: Maybe<OrderBy>;
  zohoOrganizationId?: Maybe<OrderBy>;
};

/** columns and relationships of "zoho_organization" */
export type ZohoOrganization = {
  __typename?: 'ZohoOrganization';
  address?: Maybe<Scalars['String']>;
  bankId: Scalars['String'];
  bic?: Maybe<Scalars['String']>;
  /** An array relationship */
  bilikZones: Array<BilikZone>;
  /** An aggregate relationship */
  bilikZonesAggregate: BilikZoneAggregate;
  iban?: Maybe<Scalars['String']>;
  ics?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  legalRepresentative?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  shareCapital?: Maybe<Scalars['String']>;
  tvaTaxId: Scalars['String'];
  /** An array relationship */
  zohoConsumers: Array<ZohoConsumer>;
  /** An aggregate relationship */
  zohoConsumersAggregate: ZohoConsumerAggregate;
  /** (DC2Type:json_array) */
  zoneReportingTag: Scalars['jsonb'];
};


/** columns and relationships of "zoho_organization" */
export type ZohoOrganizationBilikZonesArgs = {
  distinctOn?: Maybe<Array<BilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneOrderBy>>;
  where?: Maybe<BilikZoneBoolExp>;
};


/** columns and relationships of "zoho_organization" */
export type ZohoOrganizationBilikZonesAggregateArgs = {
  distinctOn?: Maybe<Array<BilikZoneSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BilikZoneOrderBy>>;
  where?: Maybe<BilikZoneBoolExp>;
};


/** columns and relationships of "zoho_organization" */
export type ZohoOrganizationZohoConsumersArgs = {
  distinctOn?: Maybe<Array<ZohoConsumerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoConsumerOrderBy>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};


/** columns and relationships of "zoho_organization" */
export type ZohoOrganizationZohoConsumersAggregateArgs = {
  distinctOn?: Maybe<Array<ZohoConsumerSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ZohoConsumerOrderBy>>;
  where?: Maybe<ZohoConsumerBoolExp>;
};


/** columns and relationships of "zoho_organization" */
export type ZohoOrganizationZoneReportingTagArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zoho_organization" */
export type ZohoOrganizationAggregate = {
  __typename?: 'ZohoOrganizationAggregate';
  aggregate?: Maybe<ZohoOrganizationAggregateFields>;
  nodes: Array<ZohoOrganization>;
};

/** aggregate fields of "zoho_organization" */
export type ZohoOrganizationAggregateFields = {
  __typename?: 'ZohoOrganizationAggregateFields';
  avg?: Maybe<ZohoOrganizationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ZohoOrganizationMaxFields>;
  min?: Maybe<ZohoOrganizationMinFields>;
  stddev?: Maybe<ZohoOrganizationStddevFields>;
  stddevPop?: Maybe<ZohoOrganizationStddevPopFields>;
  stddevSamp?: Maybe<ZohoOrganizationStddevSampFields>;
  sum?: Maybe<ZohoOrganizationSumFields>;
  varPop?: Maybe<ZohoOrganizationVarPopFields>;
  varSamp?: Maybe<ZohoOrganizationVarSampFields>;
  variance?: Maybe<ZohoOrganizationVarianceFields>;
};


/** aggregate fields of "zoho_organization" */
export type ZohoOrganizationAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ZohoOrganizationSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZohoOrganizationAppendInput = {
  /** (DC2Type:json_array) */
  zoneReportingTag?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type ZohoOrganizationAvgFields = {
  __typename?: 'ZohoOrganizationAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoho_organization". All fields are combined with a logical 'AND'. */
export type ZohoOrganizationBoolExp = {
  _and?: Maybe<Array<ZohoOrganizationBoolExp>>;
  _not?: Maybe<ZohoOrganizationBoolExp>;
  _or?: Maybe<Array<ZohoOrganizationBoolExp>>;
  address?: Maybe<StringComparisonExp>;
  bankId?: Maybe<StringComparisonExp>;
  bic?: Maybe<StringComparisonExp>;
  bilikZones?: Maybe<BilikZoneBoolExp>;
  bilikZonesAggregate?: Maybe<BilikZoneAggregateBoolExp>;
  iban?: Maybe<StringComparisonExp>;
  ics?: Maybe<StringComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  legalRepresentative?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  organizationId?: Maybe<StringComparisonExp>;
  shareCapital?: Maybe<StringComparisonExp>;
  tvaTaxId?: Maybe<StringComparisonExp>;
  zohoConsumers?: Maybe<ZohoConsumerBoolExp>;
  zohoConsumersAggregate?: Maybe<ZohoConsumerAggregateBoolExp>;
  zoneReportingTag?: Maybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "zoho_organization" */
export enum ZohoOrganizationConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_23107Primary = 'idx_23107_primary'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZohoOrganizationDeleteAtPathInput = {
  /** (DC2Type:json_array) */
  zoneReportingTag?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type ZohoOrganizationDeleteElemInput = {
  /** (DC2Type:json_array) */
  zoneReportingTag?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZohoOrganizationDeleteKeyInput = {
  /** (DC2Type:json_array) */
  zoneReportingTag?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "zoho_organization" */
export type ZohoOrganizationIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoho_organization" */
export type ZohoOrganizationInsertInput = {
  address?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bilikZones?: Maybe<BilikZoneArrRelInsertInput>;
  iban?: Maybe<Scalars['String']>;
  ics?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  legalRepresentative?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  shareCapital?: Maybe<Scalars['String']>;
  tvaTaxId?: Maybe<Scalars['String']>;
  zohoConsumers?: Maybe<ZohoConsumerArrRelInsertInput>;
  /** (DC2Type:json_array) */
  zoneReportingTag?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type ZohoOrganizationMaxFields = {
  __typename?: 'ZohoOrganizationMaxFields';
  address?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ics?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  legalRepresentative?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  shareCapital?: Maybe<Scalars['String']>;
  tvaTaxId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ZohoOrganizationMinFields = {
  __typename?: 'ZohoOrganizationMinFields';
  address?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ics?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  legalRepresentative?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  shareCapital?: Maybe<Scalars['String']>;
  tvaTaxId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zoho_organization" */
export type ZohoOrganizationMutationResponse = {
  __typename?: 'ZohoOrganizationMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZohoOrganization>;
};

/** input type for inserting object relation for remote table "zoho_organization" */
export type ZohoOrganizationObjRelInsertInput = {
  data: ZohoOrganizationInsertInput;
  /** upsert condition */
  onConflict?: Maybe<ZohoOrganizationOnConflict>;
};

/** on_conflict condition type for table "zoho_organization" */
export type ZohoOrganizationOnConflict = {
  constraint: ZohoOrganizationConstraint;
  updateColumns: Array<ZohoOrganizationUpdateColumn>;
  where?: Maybe<ZohoOrganizationBoolExp>;
};

/** Ordering options when selecting data from "zoho_organization". */
export type ZohoOrganizationOrderBy = {
  address?: Maybe<OrderBy>;
  bankId?: Maybe<OrderBy>;
  bic?: Maybe<OrderBy>;
  bilikZonesAggregate?: Maybe<BilikZoneAggregateOrderBy>;
  iban?: Maybe<OrderBy>;
  ics?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  legalRepresentative?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  organizationId?: Maybe<OrderBy>;
  shareCapital?: Maybe<OrderBy>;
  tvaTaxId?: Maybe<OrderBy>;
  zohoConsumersAggregate?: Maybe<ZohoConsumerAggregateOrderBy>;
  zoneReportingTag?: Maybe<OrderBy>;
};

/** primary key columns input for table: zoho_organization */
export type ZohoOrganizationPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZohoOrganizationPrependInput = {
  /** (DC2Type:json_array) */
  zoneReportingTag?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "zoho_organization" */
export enum ZohoOrganizationSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  BankId = 'bankId',
  /** column name */
  Bic = 'bic',
  /** column name */
  Iban = 'iban',
  /** column name */
  Ics = 'ics',
  /** column name */
  Id = 'id',
  /** column name */
  LegalRepresentative = 'legalRepresentative',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  ShareCapital = 'shareCapital',
  /** column name */
  TvaTaxId = 'tvaTaxId',
  /** column name */
  ZoneReportingTag = 'zoneReportingTag'
}

/** input type for updating data in table "zoho_organization" */
export type ZohoOrganizationSetInput = {
  address?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ics?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  legalRepresentative?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  shareCapital?: Maybe<Scalars['String']>;
  tvaTaxId?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  zoneReportingTag?: Maybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type ZohoOrganizationStddevFields = {
  __typename?: 'ZohoOrganizationStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ZohoOrganizationStddevPopFields = {
  __typename?: 'ZohoOrganizationStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ZohoOrganizationStddevSampFields = {
  __typename?: 'ZohoOrganizationStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoho_organization" */
export type ZohoOrganizationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZohoOrganizationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZohoOrganizationStreamCursorValueInput = {
  address?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  ics?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  legalRepresentative?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  shareCapital?: Maybe<Scalars['String']>;
  tvaTaxId?: Maybe<Scalars['String']>;
  /** (DC2Type:json_array) */
  zoneReportingTag?: Maybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type ZohoOrganizationSumFields = {
  __typename?: 'ZohoOrganizationSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoho_organization" */
export enum ZohoOrganizationUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  BankId = 'bankId',
  /** column name */
  Bic = 'bic',
  /** column name */
  Iban = 'iban',
  /** column name */
  Ics = 'ics',
  /** column name */
  Id = 'id',
  /** column name */
  LegalRepresentative = 'legalRepresentative',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  ShareCapital = 'shareCapital',
  /** column name */
  TvaTaxId = 'tvaTaxId',
  /** column name */
  ZoneReportingTag = 'zoneReportingTag'
}

export type ZohoOrganizationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<ZohoOrganizationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: Maybe<ZohoOrganizationDeleteAtPathInput>;
  /**
   * delete the array element with specified index (negative integers count from
   * the end). throws an error if top level container is not an array
   */
  _deleteElem?: Maybe<ZohoOrganizationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: Maybe<ZohoOrganizationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ZohoOrganizationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<ZohoOrganizationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ZohoOrganizationSetInput>;
  /** filter the rows which have to be updated */
  where: ZohoOrganizationBoolExp;
};

/** aggregate variance on columns */
export type ZohoOrganizationVarianceFields = {
  __typename?: 'ZohoOrganizationVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type ZohoOrganizationVarPopFields = {
  __typename?: 'ZohoOrganizationVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ZohoOrganizationVarSampFields = {
  __typename?: 'ZohoOrganizationVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zoho_token" */
export type ZohoToken = {
  __typename?: 'ZohoToken';
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  refreshToken?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zoho_token" */
export type ZohoTokenAggregate = {
  __typename?: 'ZohoTokenAggregate';
  aggregate?: Maybe<ZohoTokenAggregateFields>;
  nodes: Array<ZohoToken>;
};

/** aggregate fields of "zoho_token" */
export type ZohoTokenAggregateFields = {
  __typename?: 'ZohoTokenAggregateFields';
  avg?: Maybe<ZohoTokenAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ZohoTokenMaxFields>;
  min?: Maybe<ZohoTokenMinFields>;
  stddev?: Maybe<ZohoTokenStddevFields>;
  stddevPop?: Maybe<ZohoTokenStddevPopFields>;
  stddevSamp?: Maybe<ZohoTokenStddevSampFields>;
  sum?: Maybe<ZohoTokenSumFields>;
  varPop?: Maybe<ZohoTokenVarPopFields>;
  varSamp?: Maybe<ZohoTokenVarSampFields>;
  variance?: Maybe<ZohoTokenVarianceFields>;
};


/** aggregate fields of "zoho_token" */
export type ZohoTokenAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ZohoTokenSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ZohoTokenAvgFields = {
  __typename?: 'ZohoTokenAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zoho_token". All fields are combined with a logical 'AND'. */
export type ZohoTokenBoolExp = {
  _and?: Maybe<Array<ZohoTokenBoolExp>>;
  _not?: Maybe<ZohoTokenBoolExp>;
  _or?: Maybe<Array<ZohoTokenBoolExp>>;
  accessToken?: Maybe<StringComparisonExp>;
  clientId?: Maybe<StringComparisonExp>;
  clientSecret?: Maybe<StringComparisonExp>;
  expirationDate?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<IntComparisonExp>;
  refreshToken?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "zoho_token" */
export enum ZohoTokenConstraint {
  /** unique or primary key constraint on columns "id" */
  Idx_23116Primary = 'idx_23116_primary'
}

/** input type for incrementing numeric columns in table "zoho_token" */
export type ZohoTokenIncInput = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoho_token" */
export type ZohoTokenInsertInput = {
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ZohoTokenMaxFields = {
  __typename?: 'ZohoTokenMaxFields';
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type ZohoTokenMinFields = {
  __typename?: 'ZohoTokenMinFields';
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "zoho_token" */
export type ZohoTokenMutationResponse = {
  __typename?: 'ZohoTokenMutationResponse';
  /** number of rows affected by the mutation */
  affectedRows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ZohoToken>;
};

/** on_conflict condition type for table "zoho_token" */
export type ZohoTokenOnConflict = {
  constraint: ZohoTokenConstraint;
  updateColumns: Array<ZohoTokenUpdateColumn>;
  where?: Maybe<ZohoTokenBoolExp>;
};

/** Ordering options when selecting data from "zoho_token". */
export type ZohoTokenOrderBy = {
  accessToken?: Maybe<OrderBy>;
  clientId?: Maybe<OrderBy>;
  clientSecret?: Maybe<OrderBy>;
  expirationDate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  refreshToken?: Maybe<OrderBy>;
};

/** primary key columns input for table: zoho_token */
export type ZohoTokenPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "zoho_token" */
export enum ZohoTokenSelectColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientSecret = 'clientSecret',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refreshToken'
}

/** input type for updating data in table "zoho_token" */
export type ZohoTokenSetInput = {
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type ZohoTokenStddevFields = {
  __typename?: 'ZohoTokenStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type ZohoTokenStddevPopFields = {
  __typename?: 'ZohoTokenStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type ZohoTokenStddevSampFields = {
  __typename?: 'ZohoTokenStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zoho_token" */
export type ZohoTokenStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ZohoTokenStreamCursorValueInput;
  /** cursor ordering */
  ordering?: Maybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZohoTokenStreamCursorValueInput = {
  accessToken?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type ZohoTokenSumFields = {
  __typename?: 'ZohoTokenSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "zoho_token" */
export enum ZohoTokenUpdateColumn {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientSecret = 'clientSecret',
  /** column name */
  ExpirationDate = 'expirationDate',
  /** column name */
  Id = 'id',
  /** column name */
  RefreshToken = 'refreshToken'
}

export type ZohoTokenUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<ZohoTokenIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<ZohoTokenSetInput>;
  /** filter the rows which have to be updated */
  where: ZohoTokenBoolExp;
};

/** aggregate variance on columns */
export type ZohoTokenVarianceFields = {
  __typename?: 'ZohoTokenVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varPop on columns */
export type ZohoTokenVarPopFields = {
  __typename?: 'ZohoTokenVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type ZohoTokenVarSampFields = {
  __typename?: 'ZohoTokenVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

export type CountProReviewInvitationsByMonthByProPresentationQueryVariables = Exact<{
  proPresentationId: Scalars['Int'];
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
}>;


export type CountProReviewInvitationsByMonthByProPresentationQuery = (
  { __typename?: 'query_root' }
  & { countProReviewInvitationsByMonthByProPresentation: Array<(
    { __typename?: 'CountProReviewInvitationsByMonthByProPresentation' }
    & Pick<CountProReviewInvitationsByMonthByProPresentation, 'month' | 'count'>
  )> }
);

export type CountProReviewsWithStatusOkByMonthByProPresentationQueryVariables = Exact<{
  proPresentationId: Scalars['Int'];
  startDate: Scalars['timestamptz'];
  endDate: Scalars['timestamptz'];
}>;


export type CountProReviewsWithStatusOkByMonthByProPresentationQuery = (
  { __typename?: 'query_root' }
  & { countProReviewsWithStatusOkByMonthByProPresentation: Array<(
    { __typename?: 'CountProReviewsWithStatusOkByMonthByProPresentation' }
    & Pick<CountProReviewsWithStatusOkByMonthByProPresentation, 'month' | 'count'>
  )> }
);

export type CountSolicitationsByMonthByProViewQueryVariables = Exact<{
  before: Scalars['timestamptz'];
  after: Scalars['timestamptz'];
  proViewId: Scalars['Int'];
}>;


export type CountSolicitationsByMonthByProViewQuery = (
  { __typename?: 'query_root' }
  & { solicitation: Array<(
    { __typename?: 'Solicitation' }
    & Pick<Solicitation, 'dateCreated' | 'email'>
  )> }
);


export const CountProReviewInvitationsByMonthByProPresentationDocument = gql`
    query countProReviewInvitationsByMonthByProPresentation($proPresentationId: Int!, $startDate: timestamptz!, $endDate: timestamptz!) {
  countProReviewInvitationsByMonthByProPresentation(where: {proPresentationId: {_eq: $proPresentationId}, month: {_gte: $startDate, _lte: $endDate}}) {
    month
    count
  }
}
    `;

/**
 * __useCountProReviewInvitationsByMonthByProPresentationQuery__
 *
 * To run a query within a React component, call `useCountProReviewInvitationsByMonthByProPresentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountProReviewInvitationsByMonthByProPresentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountProReviewInvitationsByMonthByProPresentationQuery({
 *   variables: {
 *      proPresentationId: // value for 'proPresentationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCountProReviewInvitationsByMonthByProPresentationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CountProReviewInvitationsByMonthByProPresentationQuery, CountProReviewInvitationsByMonthByProPresentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CountProReviewInvitationsByMonthByProPresentationQuery, CountProReviewInvitationsByMonthByProPresentationQueryVariables>(CountProReviewInvitationsByMonthByProPresentationDocument, options);
      }
export function useCountProReviewInvitationsByMonthByProPresentationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountProReviewInvitationsByMonthByProPresentationQuery, CountProReviewInvitationsByMonthByProPresentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CountProReviewInvitationsByMonthByProPresentationQuery, CountProReviewInvitationsByMonthByProPresentationQueryVariables>(CountProReviewInvitationsByMonthByProPresentationDocument, options);
        }
export type CountProReviewInvitationsByMonthByProPresentationQueryHookResult = ReturnType<typeof useCountProReviewInvitationsByMonthByProPresentationQuery>;
export type CountProReviewInvitationsByMonthByProPresentationLazyQueryHookResult = ReturnType<typeof useCountProReviewInvitationsByMonthByProPresentationLazyQuery>;
export type CountProReviewInvitationsByMonthByProPresentationQueryResult = ApolloReactCommon.QueryResult<CountProReviewInvitationsByMonthByProPresentationQuery, CountProReviewInvitationsByMonthByProPresentationQueryVariables>;
export const CountProReviewsWithStatusOkByMonthByProPresentationDocument = gql`
    query countProReviewsWithStatusOkByMonthByProPresentation($proPresentationId: Int!, $startDate: timestamptz!, $endDate: timestamptz!) {
  countProReviewsWithStatusOkByMonthByProPresentation(where: {proPresentationId: {_eq: $proPresentationId}, month: {_gte: $startDate, _lte: $endDate}}) {
    month
    count
  }
}
    `;

/**
 * __useCountProReviewsWithStatusOkByMonthByProPresentationQuery__
 *
 * To run a query within a React component, call `useCountProReviewsWithStatusOkByMonthByProPresentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountProReviewsWithStatusOkByMonthByProPresentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountProReviewsWithStatusOkByMonthByProPresentationQuery({
 *   variables: {
 *      proPresentationId: // value for 'proPresentationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCountProReviewsWithStatusOkByMonthByProPresentationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CountProReviewsWithStatusOkByMonthByProPresentationQuery, CountProReviewsWithStatusOkByMonthByProPresentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CountProReviewsWithStatusOkByMonthByProPresentationQuery, CountProReviewsWithStatusOkByMonthByProPresentationQueryVariables>(CountProReviewsWithStatusOkByMonthByProPresentationDocument, options);
      }
export function useCountProReviewsWithStatusOkByMonthByProPresentationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountProReviewsWithStatusOkByMonthByProPresentationQuery, CountProReviewsWithStatusOkByMonthByProPresentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CountProReviewsWithStatusOkByMonthByProPresentationQuery, CountProReviewsWithStatusOkByMonthByProPresentationQueryVariables>(CountProReviewsWithStatusOkByMonthByProPresentationDocument, options);
        }
export type CountProReviewsWithStatusOkByMonthByProPresentationQueryHookResult = ReturnType<typeof useCountProReviewsWithStatusOkByMonthByProPresentationQuery>;
export type CountProReviewsWithStatusOkByMonthByProPresentationLazyQueryHookResult = ReturnType<typeof useCountProReviewsWithStatusOkByMonthByProPresentationLazyQuery>;
export type CountProReviewsWithStatusOkByMonthByProPresentationQueryResult = ApolloReactCommon.QueryResult<CountProReviewsWithStatusOkByMonthByProPresentationQuery, CountProReviewsWithStatusOkByMonthByProPresentationQueryVariables>;
export const CountSolicitationsByMonthByProViewQueryDocument = gql`
    query countSolicitationsByMonthByProViewQuery($before: timestamptz!, $after: timestamptz!, $proViewId: Int!) {
  solicitation(where: {dateCreated: {_gte: $before, _lte: $after}, proViewId: {_eq: $proViewId}}) {
    dateCreated
    email
  }
}
    `;

/**
 * __useCountSolicitationsByMonthByProViewQuery__
 *
 * To run a query within a React component, call `useCountSolicitationsByMonthByProViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountSolicitationsByMonthByProViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountSolicitationsByMonthByProViewQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      proViewId: // value for 'proViewId'
 *   },
 * });
 */
export function useCountSolicitationsByMonthByProViewQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CountSolicitationsByMonthByProViewQuery, CountSolicitationsByMonthByProViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CountSolicitationsByMonthByProViewQuery, CountSolicitationsByMonthByProViewQueryVariables>(CountSolicitationsByMonthByProViewQueryDocument, options);
      }
export function useCountSolicitationsByMonthByProViewQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountSolicitationsByMonthByProViewQuery, CountSolicitationsByMonthByProViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CountSolicitationsByMonthByProViewQuery, CountSolicitationsByMonthByProViewQueryVariables>(CountSolicitationsByMonthByProViewQueryDocument, options);
        }
export type CountSolicitationsByMonthByProViewQueryHookResult = ReturnType<typeof useCountSolicitationsByMonthByProViewQuery>;
export type CountSolicitationsByMonthByProViewQueryLazyQueryHookResult = ReturnType<typeof useCountSolicitationsByMonthByProViewQueryLazyQuery>;
export type CountSolicitationsByMonthByProViewQueryQueryResult = ApolloReactCommon.QueryResult<CountSolicitationsByMonthByProViewQuery, CountSolicitationsByMonthByProViewQueryVariables>;