import Yup from 'yup';

export default () =>
  Yup.object().shape({
    startDate: Yup.date().nullable().required('Date de début requise !'),
    endDate: Yup.date()
      .when('startDate', (startDate, schema) => {
        if (startDate) {
          return schema.min(
            startDate,
            'La date de fin doit être supérieure à la date de début !',
          );
        }
      })
      .nullable()
      .required('Date de fin requise !'),
  });
