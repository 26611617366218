import { BgTertiary, BgWhite } from 'components/Bg/Bg';
import {
  Breadcrumb,
  Container,
  Header,
  Loader,
  Responsive,
  Step,
} from 'semantic-ui-react';
import React, { Fragment, useEffect } from 'react';
import { ToastError, ToastSuccess } from 'util/toast';
import { pathOr, pluck } from 'ramda';

import EditOne from './EditOne/EditOne';
import ProHolidayFormWithFormik from '../Form/ProHolidayFormWithFormik';
import { Query } from 'react-apollo';
import TableContainer from '../../containers/TableContainer';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { subYears } from 'date-fns';

const ProHolidaysPage = ({ selectedProView, history, location }) => {
  // We're trying to retrieve the edit parameter.
  const { proHolidayId, extraParam } = useParams();

  // Trigger toast if needed (from location state)
  useEffect(() => {
    if (pathOr(false, ['state', 'toast'], location)) {
      switch (location.state.toast.type) {
        case 'error':
          ToastError(location.state.toast.title, location.state.toast.message);
          break;
        case 'success':
        default:
          ToastSuccess(
            location.state.toast.title,
            location.state.toast.message,
          );
          break;
      }
    }
  }, [location]);

  // If the URI contains something like:
  // http://pro.devbilik.loc/conges/20995
  if (proHolidayId !== undefined || extraParam !== undefined) {
    return (
      <EditOne
        selectedProView={selectedProView}
        proHolidayId={proHolidayId || extraParam}
        history={history}
      />
    );
  }

  // Classic page
  return (
    <Query
      query={gql`
        query proHolidays($proViewId: Int!) {
          proHoliday(
            where: {
              proView: { id: { _eq: $proViewId } }
              endDate: { _gte: "${subYears(new Date(), 2).toISOString()}" }
            }
            orderBy: { startDate: DESC }
          ) {
            id
            startDate
            endDate
          }
        }
      `}
      variables={{
        proViewId: selectedProView.id,
      }}
      fetchPolicy="cache-and-network"
    >
      {({ error, data, loading, refetch }) => {
        if (error) return `Error! ${error.message}`;
        if (!data || loading) return <Loader active inline />;

        return (
          <Fragment>
            <BgTertiary breadcrumb>
              <Container textAlign="right">
                <Breadcrumb
                  icon="right angle"
                  sections={[
                    {
                      key: selectedProView.proPresentation.name,
                      content: selectedProView.proPresentation.name,
                    },
                    {
                      key: selectedProView.bilikZone.name,
                      content: selectedProView.bilikZone.name,
                    },
                    {
                      key: 'holidays',
                      content: 'Congés',
                    },
                  ]}
                />
              </Container>
              <Header as="h1" textAlign="center">
                Gestion des congés
              </Header>
            </BgTertiary>
            {/* Stepper */}
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
              <BgWhite
                section
                style={{
                  paddingBottom: '0',
                }}
              >
                <Container textAlign="center">
                  <Step.Group stackable="tablet" ordered>
                    <Step>
                      <Step.Content>
                        <Step.Description>
                          Je renseigne mes périodes de congés
                        </Step.Description>
                      </Step.Content>
                    </Step>
                    <Step>
                      <Step.Content>
                        <Step.Description>
                          Le message « en congé du ... au ... »
                          <br />
                          sera visible sur ta fiche au 1er jour du congé.
                        </Step.Description>
                      </Step.Content>
                    </Step>
                    <Step>
                      <Step.Content>
                        <Step.Description>
                          Le message disparaitra à l&apos;issue de la période
                          saisie
                        </Step.Description>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                </Container>
              </BgWhite>
            </Responsive>

            <BgWhite section>
              <Container text>
                <ProHolidayFormWithFormik
                  proViewId={selectedProView.id}
                  onSuccess={() => {
                    refetch();
                    ToastSuccess('Succès', 'Le congé a bien été ajouté !');
                  }}
                  onError={(e, isUnauthorized = false) => {
                    ToastError(
                      'Erreur',
                      isUnauthorized
                        ? `Vous ne pouvez pas ajouter de congé. Si vous êtes administrateur passez par la fiche du professionnel.`
                        : "Une erreur s'est produite veuillez réessayer plus tard",
                    );
                  }}
                  submitButton="Ajouter"
                />
              </Container>
            </BgWhite>

            <BgTertiary section>
              <Container>
                <TableContainer
                  refetch={refetch}
                  proHolidays={data.proHoliday}
                  proViewId={selectedProView.id}
                />
              </Container>
            </BgTertiary>
          </Fragment>
        );
      }}
    </Query>
  );
};

export default ProHolidaysPage;
