import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Observable,
} from 'apollo-boost';
import React, { useState, useEffect } from 'react';
import {
  setIsLogged,
  setJwt,
  setRefreshToken,
  setTokenExp,
  getJwt,
  isTokenExpired,
  getRefreshToken,
} from 'util/auth';
import { keycloak } from 'util/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ApolloProvider } from 'react-apollo';
import AppLayout from 'components/AppLayout/AppLayout';
import { HttpLink } from 'apollo-link-http';
import { ToastContainer } from 'react-toastify';
import env from 'env';
import { Loader } from 'semantic-ui-react';
import jsonwebtoken from 'jsonwebtoken';
import { onError } from 'apollo-link-error';
import { useKeycloak } from '@react-keycloak/web';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { withClientState } from 'apollo-link-state';
import { ToastError } from './util/toast';
import styled from 'styled-components';

const envColor = () => {
  switch (env.ENVIRONMENT) {
    case 'development':
      return 'green !important';
    case 'e2e':
      return 'fuchsia !important';
    case 'staging':
      return 'orange !important';
    default:
      return '#357ca5';
  }
};

const envName = () => {
  switch (env.ENVIRONMENT) {
    case 'development':
      return 'Development';
    case 'e2e':
      return 'E2e';
    case 'staging':
      return 'Staging';
    default:
      return '';
  }
};

const EnvBanner = styled.div`
  display: block;
  height: 50px;
  position: sticky;
  background: ${envColor()};
  top: 0;
  left: 0;
  z-index: 5000;

  &:before {
    content: '${envName()}';
    color: #fff;
    line-height: 50px;
    vertical-align: middle;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    text-align: center;
  }
`;

class App extends React.Component {
  constructor() {
    super();
    // Init cache
    const cache = new InMemoryCache();

    // Get jsonwebtoken token
    const token = getJwt();

    const requestLink = new ApolloLink(
      (operation, forward) =>
        new Observable((observer) => {
          let handle;
          Promise.resolve(operation)
            .then((subOperation) => {
              const accessToken = getJwt();
              if (accessToken) {
                subOperation.setContext({
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                });
              }
            })
            .then(() => {
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }); //handle ends here
            })
            .catch(observer.error.bind(observer));

          return () => {
            if (handle) handle.unsubscribe();
          };
        }),
    );

    const refreshLink = new TokenRefreshLink({
      isTokenValidOrUndefined: () => isTokenExpired(),
      fetchAccessToken: () => {
        const params = new URLSearchParams();
        params.append('grant_type', 'refresh_token');
        params.append('client_id', env.KEYCLOAK_CLIENT_ID);
        params.append('refresh_token', getRefreshToken() ?? '');

        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
        };

        return fetch(
          `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/token`,
          {
            method: 'POST',
            headers,
            body: params,
          },
        );
      },
      handleFetch: (token) => {
        const decodedToken = jsonwebtoken.decode(token);

        setJwt(token);
        setTokenExp(decodedToken.exp);
      },
      handleResponse: () => async (response) => {
        const parsedResponse = await response.json();
        setRefreshToken(parsedResponse['refresh_token']);

        return {
          access_token: parsedResponse['access_token'],
        };
      },
      handleError: (err) => {
        ToastError(
          'Error',
          "Impossible de rafraichir le token d'identification, essayer de vous reconnecter pour pouvoir continuer à utiliser le site",
        );
        console.warn('Your refresh token is invalid. Try to relogin');
        console.error(err);
      },
    });

    const httpLink = new HttpLink({
      uri: `${env.API_URL}/backend/v1/graphql`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    });

    this.apolloClient = new ApolloClient({
      shouldBatch: true,
      cache,
      resolvers: {},
      link: ApolloLink.from([
        withClientState({
          cache,
          defaults: {
            email: null,
            selectedProView: null,
          },
        }),

        // Error link
        onError(({ operation, graphQLErrors, networkError }) => {
          const context = operation.getContext();
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
              window.console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              ),
            );
          }
          if (networkError) {
            window.console.log(`[Network error]: ${networkError}`);
            if (context && context.response && context.response.status) {
              if (
                context.response.status === 403 ||
                context.response.status === 401
              ) {
                setIsLogged(context.cache, false);
              }
            }
          }
        }),
        refreshLink,
        requestLink,
        httpLink,
      ]),
    });

    setIsLogged(this.apolloClient, !!token);
  }

  handleAuthentication() {
    if (!keycloak.authenticated) {
      keycloak.login({ scope: 'offline_access' });
    }
  }

  render() {
    // If the current URL is an action token.
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={async (event) => {
          if (event === 'onReady') {
            this.handleAuthentication();
          }
          // keycloak.logout();
          if (
            event === 'onAuthSuccess' &&
            keycloak.token &&
            keycloak.refreshToken &&
            keycloak.tokenParsed &&
            keycloak.tokenParsed.exp
          ) {
            // get local storage
            setJwt(keycloak.token);
            setRefreshToken(keycloak.refreshToken);
            setTokenExp(keycloak.tokenParsed.exp);
          }
        }}
        LoadingComponent={<Loader />}
      >
        <ToastContainer
          position="top-right"
          autoClose={5000}
          pauseOnHover
          hideProgressBar
        />
        <ApolloProvider client={this.apolloClient}>
          {env.ENVIRONMENT !== 'production' ? <EnvBanner /> : null}
          <CheckLogin client={this.apolloClient} />
        </ApolloProvider>
      </ReactKeycloakProvider>
    );
  }
}

const CheckLogin = ({ client }) => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak.tokenParsed) {
      if (keycloak.tokenParsed['email']) {
        const urlParams = new URLSearchParams(window.location.search);
        const impersonatedEmail = jsonwebtoken.decode(urlParams.get('token'))
          ?.email;

        client.writeData({
          data: {
            isLogged: true,
            email: impersonatedEmail ?? keycloak.tokenParsed['email'],
          },
        });
        // if admin is already connected
      } else if (keycloak.tokenParsed.resource_access['admin']) {
        ToastError(
          `Tu es connecté en tant qu'administrateur, déconnecte-toi de l'espace admin pour pouvoir accéder à l'espace pro`,
          <a style={{ color: 'white' }} href={env.ADMIN_URL}>
            Clique ici pour accéder à l&apos;admin
          </a>,
        );
      }
    }
  }, [keycloak.tokenParsed]);

  return <AppLayout />;
};

export default App;
