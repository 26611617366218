import { BgTertiary, BgWhite } from 'components/Bg/Bg';
import { Breadcrumb, Container, Form, Grid, Header } from 'semantic-ui-react';
import React, { Component, Fragment } from 'react';

import DatePicker from 'components/DatePicker/DatePicker';
import TableContainer from '../../containers/TableContainer';
import { subMonths } from 'date-fns/esm';

class SmsReceivedPage extends Component {
  now = new Date();

  state = {
    startDate: subMonths(this.now, 1),
    endDate: this.now,
  };

  render() {
    const { selectedProView } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <Fragment>
        <BgTertiary breadcrumb>
          <Container>
            <Container textAlign="right">
              <Breadcrumb
                icon="right angle"
                sections={[
                  {
                    key: selectedProView.proPresentation.name,
                    content: selectedProView.proPresentation.name,
                  },
                  {
                    key: selectedProView.bilikZone.name,
                    content: selectedProView.bilikZone.name,
                  },
                  { key: 'Sms', content: 'Sms' },
                ]}
              />
            </Container>
            <Header as="h1" textAlign="center">
              SMS reçus
            </Header>
            <Grid textAlign="center">
              <Form>
                <Form.Group widths="equal" style={{ padding: '25px 0px' }}>
                  <Form.Field>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        if (date) {
                          this.setState({
                            startDate: date.setHours(0),
                          });
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Form.Field>
                  <Form.Field>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => {
                        if (date) {
                          this.setState({ endDate: date.setHours(0) });
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid>
          </Container>
        </BgTertiary>
        <BgWhite section>
          <Container>
            <TableContainer
              startDate={startDate}
              endDate={endDate}
              proViewId={selectedProView.id}
            />
          </Container>
        </BgWhite>
      </Fragment>
    );
  }
}

export default SmsReceivedPage;
