import { apiClient } from 'api-client';
import { differenceInDays } from 'date-fns';
import { pathOr } from 'ramda';
import React from 'react';
import { Button, Form, FormTextArea, Modal } from 'semantic-ui-react';
import { formatTelephone } from 'util/telephone';
import { ToastError, ToastSuccess } from 'util/toast';

const ExpiredSolicitationDeclineModal = ({
  setDeclineModal,
  declineModal,
  daysUntilSolicitationCreation,
}) => {
  const solicitation = declineModal.solicitation;
  return (
    <Modal
      closeOnEscape={false}
      closeIcon
      closeOnDimmerClick={false}
      onClose={() =>
        setDeclineModal({
          open: false,
          solicitation: null,
          touched: false,
          declineReason: '',
        })
      }
      open={declineModal.open}
    >
      <Modal.Header>Décliner la demande</Modal.Header>
      <Modal.Content>
        La demande de {solicitation.givenName} {solicitation.familyName} a été
        faite il y a {daysUntilSolicitationCreation} jours, elle est trop
        vieille pour être déclinée via la plateforme (max 30j).
        <br />
        <br />
        <strong>
          Nous t&apos;invitons vivement à informer {solicitation.givenName}{' '}
          {solicitation.familyName} de ta décision
        </strong>{' '}
        <ul>
          <li>
            soit par téléphone au {formatTelephone(solicitation.telephone)}
          </li>
          <li>
            soit par email{' '}
            <a
              href={`mailto:${solicitation.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {solicitation.email}
            </a>
          </li>
        </ul>
      </Modal.Content>
      <Modal.Actions>
        <Button
          compact
          onClick={() => {
            setDeclineModal({
              open: false,
              solicitation: null,
              touched: false,
              declineReason: '',
            });
          }}
        >
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const SolicitationDeclineModal = ({
  setDeclineModal,
  setLoading,
  declineModal,
  isLoading,
  refetch,
}) => {
  return (
    <Modal
      closeOnEscape={false}
      closeIcon
      closeOnDimmerClick={false}
      onClose={() =>
        setDeclineModal({
          open: false,
          solicitation: null,
          touched: false,
          declineReason: '',
        })
      }
      open={declineModal.open}
    >
      <Modal.Header>Décliner la demande</Modal.Header>
      <Modal.Content>
        <Form>
          <FormTextArea
            onBlur={() =>
              setDeclineModal({
                open: true,
                touched: true,
                solicitation: declineModal.solicitation,
                declineReason: declineModal.declineReason,
              })
            }
            error={declineModal.touched && !declineModal.declineReason}
            value={declineModal.declineReason}
            onChange={(event, { value }) => {
              setDeclineModal({
                open: true,
                declineReason: value,
                solicitation: declineModal.solicitation,
                touched: true,
              });
            }}
            required
            placeholder="Je ne souhaite pas donner de suite car..."
            label="Indiquez nous la raison (information transmise au particulier)"
          />
          <i>
            Attention : Merci de soigner votre réponse car elle est transmise au
            particulier
          </i>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          compact
          onClick={() => {
            setDeclineModal({
              open: false,
              solicitation: null,
              touched: false,
              declineReason: '',
            });
          }}
        >
          Annuler
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading}
          compact
          color="orange"
          onClick={async () => {
            setLoading(true);
            if (declineModal.declineReason) {
              await apiClient
                .post(
                  `/form/contact-pro/decline/pro/${declineModal.solicitation.uuid}`,
                  {
                    declineReason: declineModal.declineReason,
                  },
                )
                .then((res) => {
                  if (res.status !== 201) {
                    ToastError('Erreur', `HTTP Error ${res.status}`);
                  } else {
                    refetch();
                    setDeclineModal({
                      touched: false,
                      declineReason: '',
                      open: false,
                      solicitation: null,
                    });
                    ToastSuccess('Succès', 'Demande par mail décliné !');
                    setLoading(false);
                  }
                })
                .catch((error) => {
                  ToastError('Erreur', `HTTP Error ${error.message}`);
                });
            } else {
              setDeclineModal({
                open: true,
                touched: true,
                solicitation: declineModal.solicitation,
                declineReason: declineModal.declineReason,
              });
            }
          }}
        >
          Decliner
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const DeclineModal = ({
  setDeclineModal,
  setLoading,
  declineModal,
  isLoading,
  refetch,
}) => {
  if (pathOr(false, ['solicitation', 'dateCreated'], declineModal)) {
    const daysUntilSolicitationCreation = differenceInDays(
      new Date(),
      new Date(declineModal.solicitation.dateCreated),
    );

    if (daysUntilSolicitationCreation > 30) {
      return ExpiredSolicitationDeclineModal({
        setDeclineModal,
        setLoading,
        declineModal,
        isLoading,
        daysUntilSolicitationCreation,
      });
    } else {
      return SolicitationDeclineModal({
        setDeclineModal,
        setLoading,
        declineModal,
        isLoading,
        refetch,
      });
    }
  }

  return null;
};
