import {
  Grid,
  Icon,
  List,
  Loader,
  Message,
  Responsive,
  Table,
} from 'semantic-ui-react';
import React, { Component } from 'react';

import { filter } from 'ramda';
import { formatDateTime } from '../../../util/locale';

class TableContainer extends Component {
  render() {
    const { proReviewInvitations } = this.props;

    if (!proReviewInvitations) {
      return <Loader active inline />;
    }

    if (proReviewInvitations.length) {
      const total = proReviewInvitations.length;
      const totalResponse = filter((elem) => (elem.proReview ? true : false))(
        proReviewInvitations,
      ).length;

      return (
        <React.Fragment>
          <p style={{ fontWeight: 'bold' }}>
            {totalResponse} avis réalisé sur {total} particuliers relancés
          </p>
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Table striped basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Prénom / Nom</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" width={2}>
                    Avis réalisé ?
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {proReviewInvitations.map((proReviewInvitation) => (
                  <Table.Row key={proReviewInvitation.id}>
                    <Table.Cell>
                      {formatDateTime(proReviewInvitation.dateCreated)}
                    </Table.Cell>
                    <Table.Cell>
                      {proReviewInvitation.givenName}{' '}
                      {proReviewInvitation.familyName}
                    </Table.Cell>
                    <Table.Cell>{proReviewInvitation.email}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {proReviewInvitation.proReview ? (
                        <Icon color="green" name="check" />
                      ) : (
                        <Icon color="red" name="times" />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Responsive>
          <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Grid columns={2} textAlign="center">
              <Grid.Column>
                <Icon color="green" name="check" />
                Avis réalisé
              </Grid.Column>
              <Grid.Column>
                <Icon color="red" name="times" />
                Avis non réalisé
              </Grid.Column>
            </Grid>
            <List celled relaxed>
              {proReviewInvitations.map((proReviewInvitation) => (
                <List.Item key={proReviewInvitation.id}>
                  {proReviewInvitation.proReview ? (
                    <List.Icon
                      verticalAlign="middle"
                      color="green"
                      name="check"
                    />
                  ) : (
                    <List.Icon
                      verticalAlign="middle"
                      color="red"
                      name="times"
                    />
                  )}
                  <List.Content>
                    <List.Header>
                      {proReviewInvitation.givenName}{' '}
                      {proReviewInvitation.familyName}
                    </List.Header>
                    <List.Description>
                      {proReviewInvitation.email}
                    </List.Description>
                    <List.Description style={{ marginTop: '3px' }}>
                      Le {formatDateTime(proReviewInvitation.dateCreated)}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Responsive>
        </React.Fragment>
      );
    }

    return <Message>Aucun particulier relancé</Message>;
  }
}

export default TableContainer;
