import env from 'env';

export const getJwt = () => localStorage.getItem('token');
export const setJwt = (jwt) => localStorage.setItem('token', jwt);
export const getTokenExp = () =>
  Number(localStorage.getItem('keycloakTokenExp'));
export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const setTokenExp = (tokenExp) =>
  localStorage.setItem('keycloakTokenExp', tokenExp.toString());
export const setRefreshToken = (refreshToken) =>
  localStorage.setItem('refreshToken', refreshToken);

export const isTokenExpired = () => getTokenExp() > Date.now() - 1000;

export const setIsLogged = (apolloClient, status) => {
  const isLogged = !!status;

  apolloClient.writeData({ data: { isLogged } });

  if (!isLogged) {
    localStorage.removeItem('token');
  }
};
