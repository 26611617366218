import { BgTertiary, BgWhite } from 'components/Bg/Bg';
import gql from 'graphql-tag';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import { Breadcrumb, Container, Header, Loader } from 'semantic-ui-react';
import DeleteProHolidayButtonContainer from '../../../containers/DeleteProHolidayButtonContainer';
import ProHolidayFormWithFormik from '../../Form/ProHolidayFormWithFormik';

const EditOne = ({ selectedProView, proHolidayId, history }) => {
  return (
    <React.Fragment>
      <BgTertiary breadcrumb>
        <Container textAlign="right">
          <Breadcrumb
            icon="right angle"
            // sections={[
            //   {
            //     key: selectedProView.proPresentation.name,
            //     content: selectedProView.proPresentation.name,
            //   },
            //   {
            //     key: selectedProView.bilikZone.name,
            //     content: selectedProView.bilikZone.name,
            //   },
            //   {
            //     key: "holidays",
            //     content: "Congés",
            //   },
            // ]}
          />
        </Container>
        <Header as="h1" textAlign="center">
          Modifier le congé préprogrammé
        </Header>
      </BgTertiary>
      <BgWhite section>
        <Container text>
          {/* TODO: Faire un check que l'ID de cet proHoliday
             est bien sur une proView dont le pro peut avoir accès.
          */}
          <Query
            query={gql`
              query getHoliday($id: Int!) {
                proHoliday(where: { id: { _eq: $id } }) {
                  startDate
                  endDate
                  id
                }
              }
            `}
            variables={{
              id: Number(proHolidayId),
            }}
          >
            {({ error, data, loading }) => {
              if (error) return `Error! ${error.message}`;
              if (loading && (!data || !data.proHoliday)) {
                return <Loader active inline />;
              }

              // If there is no proHoliday from the givenId, we redirect to the list page
              if (!data || !data.proHoliday) {
                history.push('/conges');
                return null;
              }

              const proHoliday = data.proHoliday[0];

              return (
                <Fragment>
                  <ProHolidayFormWithFormik
                    formData={proHoliday}
                    proViewId={selectedProView.id}
                    submitButton="Valider"
                    actions={
                      <DeleteProHolidayButtonContainer
                        style={{
                          maxHeight: '50px',
                          marginTop: '28px',
                          lineHeight: '1rem',
                        }}
                        proHolidayId={proHoliday.id}
                        onSuccess={() => {
                          history.push({
                            pathname: '/conges',
                            state: {
                              toast: {
                                type: 'success',
                                title: 'Succès',
                                message: 'Le congé a bien été supprimé !',
                              },
                            },
                          });
                        }}
                      />
                    }
                    onSuccess={() => {
                      history.push({
                        pathname: '/conges',
                        state: {
                          toast: {
                            type: 'success',
                            title: 'Succès',
                            message: 'Le congé a bien été modifié !',
                          },
                        },
                      });
                    }}
                    onError={(error) => {
                      history.push({
                        pathname: '/conges',
                        state: {
                          toast: {
                            type: 'error',
                            title: 'Erreur',
                            message:
                              "Une erreur s'est produite, veuillez réessayer plus tard",
                          },
                        },
                      });
                    }}
                  />
                </Fragment>
              );
            }}
          </Query>
        </Container>
      </BgWhite>
    </React.Fragment>
  );
};

export default EditOne;
