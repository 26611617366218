import { ResponsiveBar } from '@nivo/bar';
import { apiClient } from 'api-client';
import {
  endOfMonth,
  startOfMonth,
  format,
  eachMonthOfInterval,
} from 'date-fns';
import { groupBy, map, toPairs, uniq, filter } from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  Loader,
  Message,
  Statistic,
} from 'semantic-ui-react';
import { string } from 'yup';
import { ToastError } from '../../../util/toast';

const StatsSmsReceivedContainer = ({ startDate, endDate, proViewId }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiClient
      .post(`/event/pro-sms-received-by-month`, {
        proViewId: proViewId,
        after: startDate,
        before: endDate,
        isFirstContactExcluded: true,
        isSpamExcluded: true,
      })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        ToastError('Erreur', "Impossible d'afficher les stats d'sms");
        setLoading(false);
      });
  }, [startDate, endDate, proViewId]);

  if (isLoading) {
    return (
      <div style={{ height: '576px' }}>
        <Loader active>Chargement...</Loader>
      </div>
    );
  }

  // Format data
  const months = eachMonthOfInterval({
    start: startOfMonth(new Date(startDate)),
    end: endOfMonth(new Date(endDate)),
  });

  const chartData = months.map((month) => {
    const monthString = format(month, 'MM/yyyy');

    const monthData = data.find(
      (data) => format(new Date(data.month), 'MM/yyyy') === monthString,
    );

    if (monthData?.count) {
      return {
        Date: monthString,
        dateISO: format(month, 'yyyy-MM'),
        'Sms uniques': monthData.uniqueCount,
        Sms: monthData.count,
      };
    } else {
      return {
        Date: monthString,
        dateISO: format(month, 'yyyy-MM'),
        'Sms uniques': 0,
        Sms: 0,
      };
    }
  });

  chartData.sort((a, b) => a.dateISO.localeCompare(b.dateISO));

  const totalCount = data.reduce(
    (accumulator, value) => accumulator + value.count,
    0,
  );

  const uniqTotalCount = data.reduce(
    (accumulator, value) => accumulator + value.uniqueCount,
    0,
  );

  if (totalCount === 0) {
    return (
      <Container fluid style={{ height: '576px' }}>
        <Header as="h2">Sms</Header>
        <Message>Pas de stats</Message>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Header as="h2">Sms</Header>
      <Statistic.Group size="tiny">
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#A87CFF' }}>
            {totalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#A87CFF', fontWeight: 'bold' }}>
            Sms
          </Statistic.Label>
        </Statistic>
        <Statistic horizontal>
          <Statistic.Value style={{ color: '#5500FF' }}>
            {uniqTotalCount}
          </Statistic.Value>
          <Statistic.Label style={{ color: '#5500FF', fontWeight: 'bold' }}>
            Sms uniques
          </Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <div style={{ height: 500 }}>
        <ResponsiveBar
          colors={['#A87CFF', '#5500FF']}
          groupMode="grouped"
          data={chartData}
          keys={['Sms', 'Sms uniques']}
          indexBy="Date"
          margin={{
            top: 30,
            bottom: 60,
            left: 25,
          }}
          padding={0.2}
          innerPadding={7}
          enableLabel={false}
          axisBottom={{
            tickRotation: 45,
          }}
        />
      </div>
    </Container>
  );
};
export default StatsSmsReceivedContainer;
