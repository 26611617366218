// Window variables are generated in the docker entrypoint.sh
const env = {
  SITE_URL: process.env.REACT_APP_SITE_URL || window.SITE_URL,
  API_URL: process.env.REACT_APP_API_URL || window.API_URL,
  ADMIN_URL: process.env.REACT_APP_ADMIN_URL || window.ADMIN_URL,
  KEYCLOAK_AUTH_URL:
    process.env.REACT_APP_KEYCLOAK_AUTH_URL || window.KEYCLOAK_AUTH_URL,
  KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM || window.KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID:
    process.env.REACT_APP_KEYCLOAK_CLIENT_ID || window.KEYCLOAK_CLIENT_ID,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || window.ENVIRONMENT,
};

export default env;

export const apiFormUrl = `${env.API_URL}/form`;
