import { apiClient } from 'api-client';
import { withFormik } from 'formik';
import { pathOr } from 'ramda';
import ProHolidayForm from './Form';
import validationSchema from './Validation';

const ProHolidayFormWithFormik = withFormik({
  validationSchema: validationSchema(),
  validateOnBlur: false,
  enableReinitialize: true,
  mapPropsToValues: ({ formData }) => ({
    id: pathOr(null, ['id'], formData),
    startDate: pathOr(null, ['startDate'], formData),
    endDate: pathOr(null, ['endDate'], formData),
  }),
  handleSubmit: async (
    values,
    { props: { proViewId, onError, onSuccess }, setSubmitting, resetForm },
  ) => {
    return apiClient
      .post(`/form/pro-holiday`, {
        proViewId,
        proHolidayId: values.id,
        ...values,
        namespace: 'pro',
        action: values.id ? 'update' : 'create',
      })
      .then(() => {
        setSubmitting(false);
        resetForm();
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((e) => {
        setSubmitting(false);
        if (e.response.status === 401) {
          onError(e, true);
        } else if (onError) {
          onError(e);
        }
      });
  },
})(ProHolidayForm);

export default ProHolidayFormWithFormik;
