import {
  Button,
  Dropdown,
  Icon,
  List,
  Loader,
  Message,
  Responsive,
  Table,
} from 'semantic-ui-react';
import React from 'react';
import env from 'env';
import { formatDateTime, formatDate } from '../../../util/locale';
import { apiClient } from 'api-client';
import { ToastError } from 'util/toast';
import { formatTelephone } from 'util/telephone';

// create a function to download the image
const downloadFile = async (url, fileName) => {
  try {
    const response = await apiClient.get(url, {
      responseType: 'blob',
    });
    // create a blob from the response
    const blob = new Blob([response.data], {
      type: response.data.type,
    });
    // create a link to download the image
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    // this will open the image in a new tab offering the user to preview the image
    // my problem is that the fileName is not set
    // window.open(link.href);
    link.download = fileName;
    link.click();
    // remove the link
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    ToastError(
      'Erreur',
      "Une erreur s'est produite lors du téléchargement du fichier.",
    );
  }
};

const TableContainer = ({
  proPresentationId,
  proPresentationName,
  proReviews,
}) => {
  if (!proReviews) {
    return <Loader active inline />;
  }

  if (proReviews) {
    const total = proReviews.length;

    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <p style={{ fontWeight: 'bold' }}>{total} avis obtenus</p>

          {/* TODO : won't work till export avis branch is merged */}
          <React.Fragment>
            <Button
              style={{
                // position: 'relative',
                float: 'right',
                // add margin to the button
                margin: '20px 0',
              }}
              disabled={total < 10}
              onClick={async () => {
                await downloadFile(
                  `${env.API_URL}/form/review-pro/export/${proPresentationId}`,
                  `attestation-avis-${proPresentationName}-${formatDate(
                    new Date(),
                    'dd-MM-yyyy',
                  )}.pdf`,
                );
              }}
            >
              <Icon name="download" />
              {total >= 10 ? (
                <>Exporter en PDF</>
              ) : (
                <>
                  Attestation d&apos;avis en format pdf disponible ici à partir
                  de 10 avis !
                </>
              )}
            </Button>
          </React.Fragment>
        </div>

        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Table striped basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Coordonnées</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Contenu</Table.HeaderCell>
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {proReviews.map((proReview) => (
                <Table.Row key={proReview.id} verticalAlign="top">
                  <Table.Cell>
                    {proReview.givenName} {proReview.familyName}
                    {proReview.telephone &&
                    !['null', 'NULL'].includes(proReview.telephone) ? (
                      <React.Fragment>
                        <br />
                        {formatTelephone(proReview.telephone)}
                      </React.Fragment>
                    ) : (
                      <>
                        <br />
                        Téléphone non renseigné
                      </>
                    )}
                    {proReview.addressLocality ? (
                      <React.Fragment>
                        <br />
                        {proReview.addressLocality}
                      </React.Fragment>
                    ) : null}
                    {proReview.email ? (
                      <React.Fragment>
                        <br />
                        {
                          <a href={`mailto:${proReview.email}`}>
                            {proReview.email}
                          </a>
                        }
                      </React.Fragment>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateTime(proReview.dateCreated)}
                  </Table.Cell>

                  <Table.Cell>
                    <b>Sujet : </b> {proReview.reviewAspect}
                    <br />
                    <b>Contenu : </b>
                    {proReview.reviewBody}
                    <br />
                    {proReview.privateComment ? (
                      <React.Fragment>
                        <b>Commentaire privé : </b>
                        {proReview.privateComment}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {proReview.reply ? (
                      <React.Fragment>
                        <b>Réponse : </b>
                        {proReview.reply}
                        <br />
                      </React.Fragment>
                    ) : null}
                    {proReview.rating ? (
                      <React.Fragment>
                        <b>Note : </b>
                        {proReview.rating}
                        <br />
                      </React.Fragment>
                    ) : null}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {proReview.rating ? (
                      <Dropdown
                        icon="triangle down"
                        compact
                        button
                        basic
                        className="icon primary"
                        direction="left"
                        text="Partager"
                        color="orange"
                        style={{
                          width: '130px',
                          textAlign: 'center',
                        }}
                      >
                        <Dropdown.Menu
                          style={{
                            marginTop: '10px',
                            width: '230px',
                          }}
                        >
                          <Dropdown.Item
                            icon="download"
                            text="Format Story"
                            onClick={async () =>
                              await downloadFile(
                                `${env.API_URL}/form/review-pro/generate-image/${proReview.id}`,
                                `avis-5-etoiles-story-${proReview.familyName.toUpperCase()}.png`,
                              )
                            }
                          />
                          <Dropdown.Item
                            icon="download"
                            text="Format Story Anonyme"
                            onClick={async () =>
                              await downloadFile(
                                `${env.API_URL}/form/review-pro/generate-image/${proReview.id}/true`,
                                `avis-5-etoiles-story-${proReview.familyName.toUpperCase()}-anonyme.png`,
                              )
                            }
                          />{' '}
                          <Dropdown.Item
                            icon="download"
                            text="Format Carré"
                            onClick={async () =>
                              await downloadFile(
                                `${env.API_URL}/form/review-pro/generate-image/${proReview.id}/false/true`,
                                `avis-5-etoiles-carre-${proReview.familyName.toUpperCase()}-anonyme.png`,
                              )
                            }
                          />
                          <Dropdown.Item
                            icon="download"
                            text="Format Carré Anonyme"
                            onClick={async () =>
                              await downloadFile(
                                `${env.API_URL}/form/review-pro/generate-image/${proReview.id}/true/true`,
                                `avis-5-etoiles-carre-${proReview.familyName.toUpperCase()}-anonyme.png`,
                              )
                            }
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <List celled relaxed>
            {proReviews.map((proReview) => (
              <List.Item key={proReview.id}>
                <List.Content>
                  <List.Header>
                    {proReview.givenName} {proReview.familyName}
                  </List.Header>
                  <List.Description>
                    <b>Sujet :</b> {proReview.reviewAspect}
                    <br />
                    <b>Contenu :</b>
                    {proReview.reviewBody}
                    <br />
                    <b>Note :</b> {proReview.rating ?? 'Non noté'}
                  </List.Description>
                  <List.Description style={{ marginTop: '3px' }}>
                    Le {formatDateTime(proReview.dateCreated)}
                  </List.Description>
                  <List.Description style={{ marginTop: '8px' }}>
                    {proReview.rating ? (
                      <Dropdown
                        icon="triangle down"
                        compact
                        button
                        basic
                        className="icon primary"
                        direction="left"
                        text="Partager"
                        color="orange"
                        style={{
                          width: '130px',
                          textAlign: 'center',
                        }}
                      >
                        <Dropdown.Menu
                          style={{
                            marginTop: '10px',
                            width: '230px',
                            marginRight: '-100px',
                          }}
                        >
                          <Dropdown.Item
                            icon="download"
                            text="Format Story"
                            onClick={async () =>
                              await downloadFile(
                                `${env.API_URL}/form/review-pro/generate-image/${proReview.id}`,
                                `avis-5-etoiles-story-${proReview.familyName.toUpperCase()}.png`,
                              )
                            }
                          />
                          <Dropdown.Item
                            icon="download"
                            text="Format Story Anonyme"
                            onClick={async () =>
                              await downloadFile(
                                `${env.API_URL}/form/review-pro/generate-image/${proReview.id}/true`,
                                `avis-5-etoiles-story-${proReview.familyName.toUpperCase()}-anonyme.png`,
                              )
                            }
                          />{' '}
                          <Dropdown.Item
                            icon="download"
                            text="Format Carré"
                            onClick={async () =>
                              await downloadFile(
                                `${env.API_URL}/form/review-pro/generate-image/${proReview.id}/false/true`,
                                `avis-5-etoiles-carre-${proReview.familyName.toUpperCase()}-anonyme.png`,
                              )
                            }
                          />
                          <Dropdown.Item
                            icon="download"
                            text="Format Carré Anonyme"
                            onClick={async () =>
                              await downloadFile(
                                `${env.API_URL}/form/review-pro/generate-image/${proReview.id}/true/true`,
                                `avis-5-etoiles-carre-${proReview.familyName.toUpperCase()}-anonyme.png`,
                              )
                            }
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Responsive>
      </React.Fragment>
    );
  }

  return <Message>Aucun avis</Message>;
};

export default TableContainer;
