import { Query } from 'react-apollo';
import React from 'react';
import gql from 'graphql-tag';
import { setIsLogged } from '../util/auth';

const withCurrentAccount = (Component) => (props) => (
  <Query
    query={gql`
      {
        email @client
        selectedProView @client {
          id
          avatarHash
          proPeople {
            proPerson {
              givenName
              familyName
            }
          }
          proPostalAddress {
            street
            postalCode
            city
            displayOption
          }
          proPresentation {
            slug
            id
            name
            proOrganizationId
            mainTrade {
              name
              slug
            }
          }
          bilikZone {
            name
            slug
          }
        }
      }
    `}
    fetchPolicy="cache-only"
  >
    {({ client, loading, error, data }) => {
      // No user ? => logout
      if (!loading && (!data || !data.email)) {
        setIsLogged(client, false);
        return null;
      }
      if (!data || !data.email) return 'loading...';
      if (error) return `Error! ${error.message}`;

      const { selectedProView } = data;

      // NULL

      return (
        <Query
          fetchPolicy="cache-first"
          query={gql`
            query account($email: String!) {
              account(where: { email: { _eq: $email } }) {
                id
                email
              }
            }
          `}
          variables={{ email: data.email }}
        >
          {({ client, loading, error, data }) => {
            // No user ? => logout
            if (loading) return 'loading...';

            if (!loading && !data?.account) {
              setIsLogged(client, false);
              return null;
            }

            if (error) return `Error! ${error.message}`;

            return (
              <Component
                {...props}
                currentAccount={data.account[0]}
                selectedProView={selectedProView}
              />
            );
          }}
        </Query>
      );
    }}
  </Query>
);

export default withCurrentAccount;
