import { apiClient } from 'api-client';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { List, Loader, Message, Responsive, Table } from 'semantic-ui-react';
import { formatDateTime } from 'util/locale';
import { formatTelephone } from 'util/telephone';
import { ToastError } from 'util/toast';

const TableContainer = ({ startDate, endDate, proViewId }) => {
  const [data, setData] = useState({ sms: null });

  useEffect(() => {
    apiClient
      .post('/event/pro-sms', {
        proViewId: proViewId,
        orderByDate: 'DESC',
        after: startOfDay(new Date(startDate)),
        before: endOfDay(new Date(endDate)),
        isSpamExcluded: true,
        isFirstContactExcluded: true,
        skip: 0,
        limit: 1000,
      })
      .then((response) => {
        setData({ sms: response.data });
      })
      .catch(() => {
        ToastError('Erreur', "Impossible d'afficher le journal des sms");
      });
  }, [startDate, endDate, proViewId]);

  if (!data || !data.sms) {
    return <Loader active inline />;
  }

  if (data.sms.length) {
    return (
      <React.Fragment>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Table striped basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Numéro</Table.HeaderCell>
                <Table.HeaderCell>Message</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.sms.map((sms, i) => (
                <Table.Row key={sms._id}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{formatDateTime(sms.date)}</Table.Cell>
                  <Table.Cell>
                    {formatTelephone(sms.initiator.pi?.telephone)}
                  </Table.Cell>
                  <Table.Cell>
                    {sms.data.vonage?.text ?? sms.data.twilio?.Body}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <List celled>
            {data.sms.map((sms) => (
              <List.Item key={sms._id}>
                <List.Content floated="right">
                  {formatDateTime(sms.date)}
                </List.Content>
                <a href={`tel:${sms.initiator.pi?.telephone}`}>
                  {formatTelephone(sms.initiator.pi?.telephone)}
                </a>
                <br />
                {sms.data.vonage?.text ?? sms.data.twilio?.Body}
              </List.Item>
            ))}
          </List>
        </Responsive>
      </React.Fragment>
    );
  }

  return (
    <Message>
      <p>Aucun SMS reçu sur la période sélectionée</p>
    </Message>
  );
};

export default TableContainer;
