import DatePicker from 'components/DatePicker/DatePicker';
import { FastField } from 'formik';
import { pathOr } from 'ramda';
import React, { Fragment } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Icon,
  Label,
  Responsive,
} from 'semantic-ui-react';

const checkErrors = (errors, touched, field) => {
  if (pathOr(false, [field], errors) && pathOr(false, [field], touched)) {
    return errors[field];
  }
  return false;
};

const Submit = ({ isSubmitting, submitButton, ...rest }) => (
  <Button
    {...rest}
    as={Button}
    style={{
      maxHeight: '50px',
      marginTop: '28px',
      lineHeight: '1rem',
    }}
    primary
    type="submit"
    disabled={isSubmitting}
  >
    {submitButton ? submitButton : 'Envoyer'}
  </Button>
);

const ProHolidayForm = ({
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  handleReset,
  handleSubmit,
  values,
  errors,
  touched,
  submitButton,
  actions,
}) => (
  <Form onReset={handleReset} onSubmit={handleSubmit}>
    <FormGroup widths="equal">
      <FastField name="startDate">
        {({ field }) => {
          return (
            <Fragment>
              <Form.Field error={!!checkErrors(errors, touched, 'startDate')}>
                <label style={{ lineHeight: '25px' }}>
                  <Icon name="calendar alternate" />
                  Date de début
                </label>
                <DatePicker
                  id="startDate"
                  selected={
                    values.startDate ? new Date(values.startDate) : null
                  }
                  onChange={(date) => {
                    if (date) {
                      setFieldValue(`startDate`, date);
                      // setFieldTouched so the validator knows we change the value
                      // And so it can trigger the validator again
                      setFieldTouched(`startDate`);
                    } else {
                      setFieldValue(`startDate`, '');
                      setFieldTouched(`startDate`);
                    }
                  }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="jj/mm/aaaa"
                />
                {checkErrors(errors, touched, 'startDate') ? (
                  <Label prompt>{errors.startDate}</Label>
                ) : null}
              </Form.Field>
            </Fragment>
          );
        }}
      </FastField>
      <FastField name="endDate">
        {({ field }) => {
          return (
            <Fragment>
              <Form.Field error={!!checkErrors(errors, touched, 'endDate')}>
                <label style={{ lineHeight: '25px' }}>
                  <Icon name="calendar alternate" />
                  Date de fin
                </label>
                <DatePicker
                  id="endDate"
                  selected={values.endDate ? new Date(values.endDate) : null}
                  onChange={(date) => {
                    if (date) {
                      setFieldValue('endDate', date);
                      setFieldTouched('endDate');
                    } else {
                      setFieldValue('endDate', '');
                      setFieldTouched('endDate');
                    }
                  }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="jj/mm/aaaa"
                />
                {checkErrors(errors, touched, 'endDate') ? (
                  <Label prompt>{errors.endDate}</Label>
                ) : null}
              </Form.Field>
            </Fragment>
          );
        }}
      </FastField>
      <Responsive
        as={Submit}
        submitButton={submitButton}
        minWidth={Responsive.onlyTablet.minWidth}
      />
      <Responsive
        as={Submit}
        submitButton={submitButton}
        {...Responsive.onlyMobile}
        fluid
      />
      {actions ? actions : null}
    </FormGroup>
  </Form>
);

export default ProHolidayForm;
