import { BgTertiary, BgWhite } from 'components/Bg/Bg';
import React from 'react';
import { Breadcrumb, Container, Header } from 'semantic-ui-react';
import InvoicesContainer from '../containers/InvoicesContainer';

const InvoicesPage = (props) => {
  const { selectedProView } = props;

  return (
    <React.Fragment>
      <BgTertiary>
        <Container textAlign="right">
          <Breadcrumb
            icon="right angle"
            sections={[
              {
                key: selectedProView.proPresentation.name,
                content: selectedProView.proPresentation.name,
              },
              {
                key: selectedProView.bilikZone.name,
                content: selectedProView.bilikZone.name,
              },
              { key: 'Factures', content: 'Factures' },
            ]}
          />
        </Container>
        <Header as="h2" textAlign="center">
          Liste des factures
        </Header>
      </BgTertiary>
      <BgWhite section>
        <Container>
          <InvoicesContainer proViewId={selectedProView.id} />
        </Container>
      </BgWhite>
    </React.Fragment>
  );
};

export default InvoicesPage;
