import {
  Container,
  Header,
  Loader,
  Statistic,
  Message,
} from 'semantic-ui-react';

import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { endOfMonth } from 'date-fns/esm';
import { populateTotalCountByMonthForStats } from './stats-helper';
import { startOfMonth } from 'date-fns';
import { useCountProReviewInvitationsByMonthByProPresentationQuery } from 'generated/graphql';

export function StatsProReviewInvitationsContainer(props) {
  const { startDate, endDate, proPresentationId } = props;
  const {
    error,
    loading,
    data,
  } = useCountProReviewInvitationsByMonthByProPresentationQuery({
    variables: {
      proPresentationId,
      startDate: startOfMonth(startDate),
      endDate: endOfMonth(endDate),
    },
    fetchPolicy: 'cache-first',
  });

  if (error) return `Error! ${error.message}`;

  if (loading) {
    return (
      <div style={{ height: '576px' }}>
        <Loader active>Chargement...</Loader>
      </div>
    );
  }

  if (data) {
    const {
      totalCount,
      graphDatas,
      maxHeight,
    } = populateTotalCountByMonthForStats(
      data.countProReviewInvitationsByMonthByProPresentation,
      startOfMonth(startDate),
      endOfMonth(endDate),
    );
    if (totalCount <= 0) {
      return (
        <Container fluid style={{ height: '576px' }}>
          <Header as="h2">Relances d&apos;avis</Header>
          <Message>Pas de stats</Message>
        </Container>
      );
    } else {
      return (
        <Container fluid>
          <Header as="h2">Relances d&apos;avis</Header>
          <Statistic horizontal size="tiny">
            <Statistic.Value style={{ color: '#9BC53D' }}>
              {totalCount}
            </Statistic.Value>
            <Statistic.Label style={{ color: '#9BC53D', fontWeight: 'bold' }}>
              Relances d&apos;avis
            </Statistic.Label>
          </Statistic>
          <div style={{ height: 500 }}>
            <ResponsiveBar
              colors={['#9BC53D']}
              groupMode="grouped"
              data={graphDatas}
              keys={['count']}
              indexBy="month"
              margin={{
                top: 30,
                bottom: 60,
                left: 25,
              }}
              axisLeft={{
                tickValues: maxHeight,
              }}
              padding={0.2}
              innerPadding={7}
              enableLabel={false}
              axisBottom={{
                tickRotation: 45,
              }}
            />
          </div>
        </Container>
      );
    }
  }
}

export default StatsProReviewInvitationsContainer;
