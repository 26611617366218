import { FunctionComponent, useCallback, useState } from 'react';
import React from 'react';
import env from 'env';
import { Label } from 'semantic-ui-react';
import { apiClient } from 'api-client';
import { getJwt } from 'util/auth';

interface CallRecordingProps {
  conversationUuid?: string;
  duration: number;
}

export const CallRecording: FunctionComponent<CallRecordingProps> = ({
  conversationUuid,
  duration,
}) => {
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handlePlay = useCallback(async () => {
    if (!conversationUuid || Number(duration) === 0) {
      setError('Indisponible');
      return;
    }

    const baseUrl = `${env.API_URL}/attachment/object-by-key-token`;
    const key = encodeURIComponent(`call-recordings/${conversationUuid}`);
    const audioUrl = `${baseUrl}?key=${key}&token=${getJwt()}`;

    apiClient
      .head(audioUrl)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setAudioUrl(audioUrl);
          return;
        }
        setError('Indisponible');
      })
      .catch(() => {
        setError('Indisponible');
      });
  }, []);

  return (
    <div>
      {audioUrl ? (
        <audio controls>
          <source src={audioUrl} type="audio/mp3" />
          Votre navigateur ne supporte pas le lecteur audio.
        </audio>
      ) : null}
      {!audioUrl && !error ? (
        <button
          style={{
            padding: 6,
          }}
          className="ui button"
          onClick={handlePlay}
        >
          <i className="play icon" style={{ margin: 0 }} />
        </button>
      ) : null}
      {error ? <Label>{error}</Label> : null}
    </div>
  );
};
