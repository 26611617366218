import { fr } from 'date-fns/locale';
import React, { Fragment } from 'react';
import { default as DatePickerUi } from 'react-datepicker';
import { Responsive } from 'semantic-ui-react';
import styled from 'styled-components';
import { formatDate } from 'util/locale';

const ButtonField = styled.button`
  color: #00647b;
  background: #fff;
  border: 2px solid #b7b7b7;
  border-radius: 2px;
  text-align: left;
  line-height: 23px;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Sc', sans-serif, Arial;
  padding: 11px;
  width: 100%;

  &:focus {
    outline: 0;
    border-color: #909090;
  }
`;

const DatePicker = (props) => (
  <Fragment>
    <Responsive {...Responsive.onlyMobile}>
      <DatePickerUi
        {...props}
        locale={fr}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport',
          },
        }}
        customInput={
          // Button rendering to fit picker in mobile (avoid keyboard displaying)
          <ButtonField className="button-field" type="button">
            {props.selected ? (
              formatDate(props.selected)
            ) : props.placeholderText ? (
              <span style={{ color: '#999' }}>{props.placeholderText}</span>
            ) : null}
          </ButtonField>
        }
      />
    </Responsive>
    <Responsive minWidth={Responsive.onlyMobile.maxWidth}>
      <DatePickerUi {...props} locale={fr} />
    </Responsive>
  </Fragment>
);

export default DatePicker;
