import Axios from 'axios';
import Keycloak from 'keycloak-js';
import env from 'env';
import { setTokenExp, setRefreshToken, getRefreshToken, setJwt } from './auth';

const keycloakConfig = {
  url: env.KEYCLOAK_AUTH_URL,
  realm: env.KEYCLOAK_REALM,
  clientId: env.KEYCLOAK_CLIENT_ID,
};

export const keycloak = Keycloak(keycloakConfig);

const fetchKeycloakTokenData = async () => {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('client_id', env.KEYCLOAK_CLIENT_ID);
  params.append('refresh_token', getRefreshToken() ?? '');

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  try {
    const newTokenResponse = await Axios.post(
      `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/token`,
      params,
      config,
    );
    return {
      access_token: newTokenResponse.data.access_token,
      token_exp: newTokenResponse.data.expires_in,
      refresh_token: newTokenResponse.data.refresh_token,
    };
  } catch (err) {
    console.error(err);
  }
};

export const updateKeycloakTokens = async () => {
  const newKeycloakTokenData = await fetchKeycloakTokenData();
  if (newKeycloakTokenData) {
    setJwt(newKeycloakTokenData.access_token);
    setTokenExp(newKeycloakTokenData.token_exp);
    setRefreshToken(newKeycloakTokenData.refresh_token);

    return true;
  }

  return false;
};
