import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Image, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

const Link = styled(RouterLink)`
  padding: 6px 15px;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
`;

const MenuBrandItem = ({ to = null }) => (
  <Menu.Item fitted>
    <Link to={to ? to : '/'}>
      <Image height={30} src={process.env.PUBLIC_URL + '/bilik.png'} />
      Espace client
    </Link>
  </Menu.Item>
);

export default MenuBrandItem;
