import { apiClient } from 'api-client';
import { BgTertiary, BgWhite } from 'components/Bg/Bg';
import { subDays } from 'date-fns';
import env from 'env';
import { withFormik } from 'formik';
import gql from 'graphql-tag';
import { pluck, propSatisfies } from 'ramda';
import React, { Fragment, useState } from 'react';
import { Query } from 'react-apollo';
import {
  Breadcrumb,
  Button,
  Container,
  Grid,
  Header,
  Loader,
  Message,
  Modal,
  Responsive,
  Select,
  Step,
} from 'semantic-ui-react';
import { ToastError, ToastSuccess } from 'util/toast';
import { formatDateTime } from '../../../../util/locale';
import TableContainer from '../../containers/TableContainer';
import ProReviewInvitationsForm from '../Form/Form';
import validationSchema from '../Form/Validation';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

const ResponsiveContainer = styled(Container)`
  @media only screen and (min-width: 992px) {
    width: 33% !important;
  }
`;

const MailOne = ({ proPresentation }) => (
  <Fragment>
    <p>Bonjour [Prénom du client] [Nom du client],</p>
    <p>
      Suite aux prestations réalisées pour vous,{' '}
      <strong>je vous invite à me faire un retour</strong>.
    </p>
    <p>
      Comme je vous l&apos;ai peut-être expliqué, je fais partie d&apos;un
      réseau professionnel appelé Bilik.
    </p>
    <p>
      Il est important pour moi d&apos;avoir votre témoignage sur mon travail,
      car d&apos;une part, c&apos;est une source d&apos;amélioration de mon
      service et d&apos;autre part, cela permet à d&apos;éventuels nouveaux
      clients d&apos;être renseignés sur ma manière de travailler.
    </p>
    <p>
      Pour cela, c&apos;est très simple, suivez ce lien qui vous conduit
      directement au formulaire d&apos;évaluation qui est publié et contrôlé par
      Bilik :<br />
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${env.SITE_URL}/${proPresentation.proViews[0].bilikZone.slug}/${proPresentation.mainTrade.slug}/${proPresentation.slug}/avis`}
      >
        {env.SITE_URL}/{proPresentation.proViews[0].bilikZone.slug}/
        {proPresentation.mainTrade.slug}/{proPresentation.slug}/avis
      </a>
    </p>
    <p>Merci d&apos;avance.</p>
    <p>Cordialement,</p>
    <p>
      {[
        ...new Map(
          proPresentation.proViews
            .map((proView) =>
              proView.proPeople.map((item) => item.proPerson),
            )[0]
            .map((item) => [item['givenName'] && item['familyName'], item]),
        ).values(),
      ].map((proPerson) => (
        <>
          {proPerson.givenName} {proPerson.familyName}
          <br />
        </>
      ))}
      {proPresentation.name}
    </p>
    <br />
    <br />
    <p>Message transmis via Bilik</p>
  </Fragment>
);

const MailTwo = ({ proPresentation }) => (
  <Fragment>
    <p>Bonjour [Prénom du client] [Nom du client],</p>
    <p>Il semblerait que votre appréciation ne figure pas encore sur Bilik.</p>
    <p>
      Nous nous permettons de vous solliciter une nouvelle fois car il est
      important que vous nous donniez votre avis sur Bilik, le réseau de
      professionnels dont nous faisons partie.
    </p>
    <p>
      C&apos;est très simple, pour cela il vous suffit de cliquer sur le lien
      ci-dessous et de rédiger un commentaire libre (aucune inscription ne vous
      sera demandée) :
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${env.SITE_URL}/${proPresentation.proViews[0].bilikZone.slug}/${proPresentation.mainTrade.slug}/${proPresentation.slug}/avis`}
      >
        {env.SITE_URL}/{proPresentation.proViews[0].bilikZone.slug}/
        {proPresentation.mainTrade.slug}/{proPresentation.slug}/avis
      </a>
    </p>
    <p>Merci d&apos;avance.</p>
    <p>Cordialement,</p>
    <p>
      {[
        ...new Map(
          proPresentation.proViews
            .map((proView) =>
              proView.proPeople.map((item) => item.proPerson),
            )[0]
            .map((item) => [item['givenName'] && item['familyName'], item]),
        ).values(),
      ].map((proPerson) => (
        <>
          {proPerson.givenName} {proPerson.familyName}
          <br />
        </>
      ))}
      {proPresentation.name}
    </p>
    <br />
    <br />
    <p>Message transmis via Bilik</p>
  </Fragment>
);

const ProReviewInvitationsPage = ({
  currentAccount,
  selectedProPresentation,
  displayProReviewInvitations = true,
}) => {
  const [submitCount, setSubmitCount] = useState(0);
  const [message, setMessage] = useState(null);
  const [
    isProReviewInvitationsModalOpen,
    setIsProReviewInvitationsModalOpen,
  ] = useState(false);
  const [
    proReviewInvitationsModalMetadata,
    setProReviewInvitationsModalMetadata,
  ] = useState(null);
  const [isProReviewsModalOpen, setIsProReviewsModalOpen] = useState(false);
  const [proReviewsModalMetadata, setProReviewsModalMetadata] = useState(null);
  const [selectedProPresentationId, setSelectedProPresentationId] = useState();
  const [
    selectProPresentationValue,
    setSelectProPresentationValue,
  ] = useState();
  const [errorSelectNeeded, setErrorSelectNeeded] = useState(false);

  if (!selectedProPresentationId) {
    return (
      <BgTertiary section>
        <Header as="h1" textAlign="center">
          Demande d&apos;avis
        </Header>
        <Container fluid textAlign="center">
          <Query
            query={gql`
              query accountProPresentation($id: Int!) {
                proPresentation(
                  where: {
                    proViews: { accounts: { account: { id: { _eq: $id } } } }
                  }
                ) {
                  id
                  name
                  mainTrade {
                    id
                    name
                  }
                  proViews(where: { status: { _in: ["published", "draft"] } }) {
                    id
                    bilikZone {
                      id
                      name
                    }
                    accounts {
                      account {
                        id
                      }
                    }
                  }
                }
              }
            `}
            variables={{ id: currentAccount.id }}
          >
            {({ loading, error, data }) => {
              if (error) {
                console.error(error);
                return <p>Une erreur est survenue</p>;
              }

              if (loading && !data?.proPresentation) {
                return <Loader inline active />;
              }

              const proPresentations = data.proPresentation;

              if (!loading && proPresentations.length === 1) {
                setSelectedProPresentationId(proPresentations[0].id);
              }

              const options = proPresentations.map((proPresentation) => {
                return {
                  text: `${proPresentation.name} / ${proPresentation.mainTrade.name}`,
                  key: proPresentation.id,
                  value: proPresentation.id,
                };
              });

              return (
                <ResponsiveContainer>
                  <Grid style={{ marginTop: '12px' }}>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <Select
                        fluid
                        placeholder="Sélectionnez la cible des avis..."
                        options={options}
                        value={selectProPresentationValue}
                        onChange={(event, { value }) => {
                          setSelectProPresentationValue(value);
                          setErrorSelectNeeded(false);
                        }}
                        error={errorSelectNeeded}
                      />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      {' '}
                      <Button
                        primary
                        fluid
                        onClick={(event) => {
                          if (selectProPresentationValue) {
                            setSelectedProPresentationId(
                              selectProPresentationValue,
                            );
                          } else {
                            setErrorSelectNeeded(true);
                          }
                        }}
                      >
                        Valider
                      </Button>
                    </Grid.Column>
                  </Grid>
                </ResponsiveContainer>
              );
            }}
          </Query>
        </Container>
      </BgTertiary>
    );
  }

  return (
    <Query
      query={gql`
        query($proPresentation: Int!, $proOrganizationId: Int!) {
          proPresentation: proPresentationByPk(id: $proPresentation) {
            id
            slug
            name
            proViews {
              id
              bilikZone {
                id
                slug
              }
            }
            mainTrade {
              id
              slug
              name
            }
          }
          q1: proReviewInvitation(
            where: { proPresentation: { id: { _eq: $proPresentation } } }
            orderBy: { dateCreated: DESC }
          ) {
            id
            dateCreated
            givenName
            familyName
            email
            proReview {
              id
            }
          }
          q2: proReview(
            where: {
              proPresentation: {
                proOrganizationId: { _eq: $proOrganizationId }
              }
              status: { _eq: "ok" }
            }
          ) {
            id
            dateCreated
            givenName
            familyName
            email
            proPresentation {
              slug
              mainTrade {
                slug
              }
              proViews {
                bilikZone {
                  slug
                }
              }
            }
          }
        }
      `}
      variables={{
        proPresentation: selectedProPresentationId,
        proOrganizationId: selectedProPresentation.proOrganizationId ?? 0, // Hack to prevent graphql error when pro hasn't ProOrganization
      }}
    >
      {({ error, data, refetch }) => {
        if (error) return `Error! ${error.message}`;
        if (!data || !data.q1 || !data.q2) return <Loader active inline />;

        return (
          <Fragment>
            <BgTertiary breadcrumb>
              <Container textAlign="right">
                <Breadcrumb
                  icon="right angle"
                  sections={[
                    {
                      key: data.proPresentation.name,
                      content: data.proPresentation.name,
                    },
                    {
                      key: data.proPresentation.mainTrade.name,
                      content: data.proPresentation.mainTrade.name,
                    },
                    { key: "Demande d'avis", content: "Demande d'avis" },
                  ]}
                />
              </Container>
              <Header as="h1" textAlign="center">
                Demande d&apos;avis
              </Header>
            </BgTertiary>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
              <BgWhite
                section
                style={{
                  paddingBottom: '0',
                }}
              >
                <Container textAlign="center">
                  <Step.Group stackable="tablet" ordered>
                    <Step>
                      <Step.Content>
                        <Step.Description>
                          Saisissez les noms et adresses email
                          <br /> de vos derniers clients
                        </Step.Description>
                      </Step.Content>
                    </Step>
                    <Step>
                      <Step.Content>
                        <Step.Description>
                          Un mail avec le lien direct vers votre
                          <br />
                          formulaire d&apos;évaluation est envoyé
                          <br />
                          automatiquement à chaque client
                        </Step.Description>
                      </Step.Content>
                    </Step>
                    <Step>
                      <Step.Content>
                        <Step.Description>
                          15 jours plus tard un mail de relance
                          <br />
                          est adressé au client n&apos;ayant pas fait
                          <br />
                          de retour.
                        </Step.Description>
                      </Step.Content>
                    </Step>
                  </Step.Group>
                  <p>
                    Vous pouvez également télécharger le formulaire en version
                    papier{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`${env.API_URL}/form/review-invitation/generate-paper-review-invitation/${selectedProPresentationId}`}
                    >
                      en cliquant ICI
                    </a>
                  </p>
                  <p>
                    Prévisualiser le{' '}
                    <Modal
                      trigger={<a href="#/">mail envoyé</a>}
                      closeOnEscape={false}
                      closeOnDimmerClick={false}
                      closeIcon
                    >
                      <Header
                        icon="envelope"
                        content="OBJET : BESOIN DE VOTRE RETOUR"
                      />
                      <Modal.Content>
                        <MailInfosContainer
                          proPresentationId={selectedProPresentationId}
                          Component={MailOne}
                        />
                      </Modal.Content>
                    </Modal>
                    . Si votre client n&apos;a pas fait d&apos;avis dans les 15
                    jours, il est relancé par ce{' '}
                    <Modal trigger={<a href="#/">deuxième mail</a>} closeIcon>
                      <Header
                        icon="envelope"
                        content="OBJET : RELANCE - Pouvez-vous nous laisser un commentaire"
                      />
                      <Modal.Content>
                        <MailInfosContainer
                          proPresentationId={selectedProPresentationId}
                          Component={MailTwo}
                        />
                      </Modal.Content>
                    </Modal>
                    . ET c&apos;est tout !
                  </p>
                  {message !== null ? (
                    <CustomMessage
                      type={message.type}
                      message={message.message}
                    />
                  ) : null}
                </Container>
              </BgWhite>
            </Responsive>
            <BgWhite section>
              <Container>
                <CreateProReviewInvitationFormWithFormik
                  proPresentation={data.proPresentation}
                  onSuccess={() => {
                    setSubmitCount(submitCount + 1);
                    refetch();
                  }}
                  setMessage={setMessage}
                  proReviewInvitations={data.q1}
                  proReviews={data.q2}
                  triggerProReviewInvitationsModal={(
                    processSave,
                    alreadyInvited,
                    setSubmitting,
                  ) => {
                    setProReviewInvitationsModalMetadata({
                      processSave,
                      alreadyInvited,
                      setSubmitting,
                    });

                    setIsProReviewInvitationsModalOpen(true);
                  }}
                  triggerProReviewsModal={(
                    processSave,
                    alreadyGivenReview,
                    setSubmitting,
                  ) => {
                    setProReviewsModalMetadata({
                      processSave,
                      alreadyGivenReview,
                      setSubmitting,
                    });

                    setIsProReviewsModalOpen(true);
                  }}
                />
                <WarnProReviewInvitationsModal
                  proReviewInvitationsModalMetadata={
                    proReviewInvitationsModalMetadata
                  }
                  setIsOpen={setIsProReviewInvitationsModalOpen}
                  isOpen={isProReviewInvitationsModalOpen}
                />
                <WarnProReviewsModal
                  proReviewsModalMetadata={proReviewsModalMetadata}
                  setIsOpen={setIsProReviewsModalOpen}
                  isOpen={isProReviewsModalOpen}
                />
              </Container>
            </BgWhite>
            {displayProReviewInvitations ? (
              <BgTertiary section>
                <Container>
                  <Header as="h1" textAlign="center">
                    Clients déjà relancés
                  </Header>
                  <TableContainer proReviewInvitations={data.q1} />
                </Container>
              </BgTertiary>
            ) : null}
          </Fragment>
        );
      }}
    </Query>
  );
};

const CustomMessage = ({ type, message }) => {
  const style = type === 'error' ? { marginBottom: '1em' } : {};

  return (
    <Message
      style={style}
      error={type === 'error'}
      success={type === 'success'}
      content={message}
    />
  );
};

const MailInfosContainer = ({ proPresentationId, Component }) => (
  <Query
    fetchPolicy="cache-first"
    query={gql`
      query fetchExtraProInfos($id: Int) {
        proPresentation(where: { id: { _eq: $id } }) {
          id
          name
          slug
          mainTrade {
            id
            slug
          }
          proViews {
            bilikZone {
              id
              slug
              name
              streetAddress
              postalCode
              addressLocality
              telephone
              genericEmail
            }
            proPeople(
              orderBy: { position: DESC }
              where: { isVisible: { _eq: true } }
            ) {
              proPerson {
                id
                givenName
                familyName
              }
            }
          }
        }
      }
    `}
    variables={{
      id: proPresentationId,
    }}
  >
    {({ error, data }) => {
      if (error) return `Error! ${error.message}`;
      if (!data || !data.proPresentation) {
        return <Loader active inline />;
      }

      return <Component proPresentation={data.proPresentation[0]} />;
    }}
  </Query>
);

const CreateProReviewInvitationFormWithFormik = withFormik({
  validationSchema: validationSchema(),
  mapPropsToValues: () => ({
    proReviewInvitations: [
      {
        givenName: '',
        familyName: '',
        email: '',
      },
    ],
    proReviews: [
      {
        givenName: '',
        familyName: '',
        email: '',
      },
    ],
  }),
  handleSubmit: async (
    values,
    {
      props: {
        proPresentation,
        onSuccess,
        setMessage,
        proReviewInvitations,
        proReviews,
        triggerProReviewsModal,
        triggerProReviewInvitationsModal,
      },
      setSubmitting,
      resetForm,
    },
  ) => {
    const processSave = async () => {
      return Promise.all(
        values.proReviewInvitations.map(async (proReviewInvitation) => {
          return apiClient.post(`/form/review-invitation`, {
            proPresentation: proPresentation.id,
            ...proReviewInvitation,
            namespace: 'pro',
          });
        }),
      )
        .then((response) => {
          ReactGA.event({
            action: 'pro_review_invitation_form_submission',
            category: 'Review Invitation',
            value: response.slice(-1).pop(),
          });

          ToastSuccess('Succès', 'Les clients ont bien été relancés');
          onSuccess();
          setSubmitting(false);
          resetForm();
        })
        .catch((err) => {
          console.error(err);
          ToastError(
            'Erreur',
            "Une erreur s'est produite, veuillez réesayer plus tard",
          );
          setSubmitting(false);
        });
    };

    const askInviteEmail = pluck('email', values.proReviewInvitations);

    const alreadyInvited = proReviewInvitations.filter(
      (proReviewInvitation) => {
        if (
          propSatisfies(
            (mail) => askInviteEmail.indexOf(mail) !== -1,
            'email',
            proReviewInvitation,
          )
        ) {
          return true;
        }
        return false;
      },
      [],
    );

    const alreadyGivenReview = proReviews.filter((proReview) => {
      if (
        propSatisfies(
          (mail) => askInviteEmail.indexOf(mail) !== -1,
          'email',
          proReview,
        )
      ) {
        return true;
      }
      return false;
    }, []);

    if (alreadyGivenReview.length > 0) {
      triggerProReviewsModal(processSave, alreadyGivenReview, setSubmitting);
    } else if (alreadyInvited.length > 0) {
      triggerProReviewInvitationsModal(
        processSave,
        alreadyInvited,
        setSubmitting,
      );
    } else {
      await processSave();
    }

    setSubmitting(false);
  },
})(ProReviewInvitationsForm);

const WarnProReviewInvitationsModal = ({
  proReviewInvitationsModalMetadata,
  isOpen,
  setIsOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
    proReviewInvitationsModalMetadata.setSubmitting(false);
  }, [proReviewInvitationsModalMetadata, setIsOpen]);

  const handleAccept = React.useCallback(
    async (processSomething) => {
      await processSomething();
      setIsLoading(false);
      setIsOpen(false);
    },
    [setIsOpen],
  );

  if (proReviewInvitationsModalMetadata !== null) {
    return (
      <Modal open={isOpen} onClose={handleClose} closeIcon>
        <Header icon="send" content="Attention" />
        <Modal.Content>
          Tu as déjà demandé à{' '}
          {proReviewInvitationsModalMetadata.alreadyInvited.length === 1
            ? `ce client`
            : `ces clients`}{' '}
          de te faire un avis :
          <ul>
            {proReviewInvitationsModalMetadata.alreadyInvited.map(
              (proReview, i) => {
                return (
                  <li key={proReview.email + i}>
                    {proReview.givenName} {proReview.familyName} &nbsp;
                    {proReview.email} invité le{' '}
                    {formatDateTime(proReview.dateCreated)}
                  </li>
                );
              },
            )}
          </ul>
          Es-tu sûr de vouloir{' '}
          {proReviewInvitationsModalMetadata.alreadyInvited.length === 1
            ? `le`
            : `les`}{' '}
          relancer de nouveau ?
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleClose()}>Modifier</Button>
          <Button
            color="orange"
            disabled={isLoading}
            onClick={async (event) => {
              setIsLoading(true);
              event.preventDefault();
              await handleAccept(proReviewInvitationsModalMetadata.processSave);
            }}
          >
            Envoyer
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

const WarnProReviewsModal = ({
  proReviewsModalMetadata,
  isOpen,
  setIsOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
    proReviewsModalMetadata.setSubmitting(false);
  }, [proReviewsModalMetadata, setIsOpen]);

  const handleAccept = React.useCallback(
    async (processSomething) => {
      await processSomething();
      setIsLoading(false);
      setIsOpen(false);
    },
    [setIsOpen],
  );

  if (proReviewsModalMetadata !== null) {
    return (
      <Modal open={isOpen} onClose={handleClose} closeIcon>
        <Header icon="send" content="Attention" />
        <Modal.Content>
          Attention :
          <ul>
            {proReviewsModalMetadata.alreadyGivenReview.map((proReview, i) => {
              return (
                <li key={proReview.email + i}>
                  Le client {proReview.givenName} {proReview.familyName}&nbsp;(
                  {proReview.email}) a déja fait un avis le{' '}
                  {formatDateTime(proReview.dateCreated)}{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${env.SITE_URL}/presentations/${proReview.proPresentation.proViews[0].id}-${proReview.proPresentation.slug}.html#reviews`}
                  >
                    (Voir l&apos;avis)
                  </a>
                </li>
              );
            })}
          </ul>
          Es-tu sûr de vouloir le relancer de nouveau ?
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => handleClose()}>Modifier</Button>
          <Button
            color="orange"
            disabled={isLoading}
            onClick={async (event) => {
              setIsLoading(true);
              event.preventDefault();
              await handleAccept(proReviewsModalMetadata.processSave);
            }}
          >
            Envoyer
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
};

export default ProReviewInvitationsPage;
