import { FastField, FieldArray } from 'formik';
import { pathOr } from 'ramda';
import React, { Fragment, useCallback } from 'react';
import {
  Button,
  Divider,
  Form,
  FormGroup,
  FormInput,
  Grid,
  Icon,
} from 'semantic-ui-react';
import Mailcheck from 'mailcheck';

const domains = [
  'msn.com',
  'bellsouth.net',
  'telus.net',
  'comcast.net',
  'optusnet.com.au',
  'earthlink.net',
  'qq.com',
  'sky.com',
  'icloud.com',
  'mac.com',
  'sympatico.ca',
  'googlemail.com',
  'att.net',
  'xtra.co.nz',
  'web.de',
  'cox.net',
  'gmail.com',
  'ymail.com',
  'aim.com',
  'rogers.com',
  'verizon.net',
  'rocketmail.com',
  'google.com',
  'optonline.net',
  'sbcglobal.net',
  'aol.com',
  'me.com',
  'btinternet.com',
  'charter.net',
  'shaw.ca',
];
domains.push('orange.fr', 'free.fr');

const emptyProReviewInvitation = {
  givenName: '',
  familyName: '',
  email: '',
};

const checkErrors = (errors, touched, index, path) => {
  if (
    pathOr(false, ['proReviewInvitations', [index], path], errors) &&
    pathOr(false, ['proReviewInvitations', [index], path], touched)
  ) {
    return errors.proReviewInvitations[index][path];
  }
  return false;
};

const ProReviewInvitationForm = ({
  index,
  errors,
  arrayHelpers,
  touched,
  setFieldValue,
}) => {
  const [emailSuggestion, setEmailSuggestion] = React.useState('');

  const handleEmailChange = useCallback(
    (event, { value }) => {
      setFieldValue(`proReviewInvitations.${index}.email`, value);

      Mailcheck.run({
        domains,
        email: value,
        suggested: function (suggestion) {
          setEmailSuggestion(suggestion.full);
        },
        empty: function (element) {
          setEmailSuggestion('');
        },
      });
    },
    [index],
  );

  const confirmEmailChanging = useCallback(() => {
    if (emailSuggestion) {
      setFieldValue(`proReviewInvitations.${index}.email`, emailSuggestion);
    }
    setEmailSuggestion('');
  }, [emailSuggestion, index]);

  return (
    <Fragment key={index}>
      <FormGroup widths="equal">
        <FastField
          errors={errors}
          touched={touched}
          name={`proReviewInvitations.${index}.givenName`}
        >
          {({ field }) => (
            <Fragment>
              <FormInput
                {...field}
                placeholder="Prénom, M. ou Mme"
                fluid
                icon="user"
                iconPosition="left"
                errors={errors}
                touched={touched}
                error={checkErrors(errors, touched, index, 'givenName')}
              />
            </Fragment>
          )}
        </FastField>
        <FastField name={`proReviewInvitations.${index}.familyName`}>
          {({ field }) => (
            <Fragment>
              <FormInput
                {...field}
                placeholder="Nom"
                fluid
                icon="user"
                iconPosition="left"
                error={checkErrors(errors, touched, index, 'familyName')}
              />
            </Fragment>
          )}
        </FastField>
        <FastField name={`proReviewInvitations.${index}.email`}>
          {({ field }) => (
            <FormInput
              {...field}
              placeholder="Email"
              fluid
              icon="envelope"
              iconPosition="left"
              error={checkErrors(errors, touched, index, 'email')}
              onChange={handleEmailChange}
            />
          )}
        </FastField>
        {arrayHelpers.form.values.proReviewInvitations.length !== 1 ? (
          <Button
            type="button"
            style={{ maxHeight: '50px' }}
            icon
            compact
            onClick={() => arrayHelpers.remove(index)}
          >
            <Icon name="trash" />
          </Button>
        ) : null}
      </FormGroup>
      {emailSuggestion && (
        <p>
          Voulez-vous dire{' '}
          <a onClick={confirmEmailChanging} style={{ cursor: 'pointer' }}>
            {emailSuggestion}
          </a>{' '}
          ?
        </p>
      )}
      <Divider />
    </Fragment>
  );
};
const ProReviewInvitationsForm = ({
  values,
  isSubmitting,
  handleReset,
  handleSubmit,
  setFieldValue,
  errors,
  touched,
}) => {
  return (
    <Form onReset={handleReset} onSubmit={handleSubmit}>
      <FieldArray
        name="proReviewInvitations"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <Fragment>
            {values.proReviewInvitations.map((proReviewInvitation, index) => (
              <div key={index}>
                <ProReviewInvitationForm
                  errors={errors}
                  key={index}
                  arrayHelpers={arrayHelpers}
                  index={index}
                  setFieldValue={setFieldValue}
                  touched={touched}
                />
              </div>
            ))}

            <Grid>
              <Grid.Column width={8}>
                <Button primary type="submit" disabled={isSubmitting}>
                  Envoyer
                </Button>
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <Button
                  style={{ marginRight: '-3px' }}
                  icon
                  type="button"
                  onClick={() => arrayHelpers.push(emptyProReviewInvitation)}
                >
                  <Icon name="add" />
                </Button>
              </Grid.Column>
            </Grid>
          </Fragment>
        )}
      />
    </Form>
  );
};

export default ProReviewInvitationsForm;
