import { get } from 'lodash';
import Yup from 'yup';
Yup.addMethod(Yup.array, 'unique', function (message, { path, parent }) {
  return this.test('unique', message, function (list) {
    const mapper = (x) => get(x, path);
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({ path: `${parent}.[${idx}].${path}`, message });
  });
});

export default () =>
  Yup.object().shape({
    proReviewInvitations: Yup.array(
      Yup.object().shape({
        email: Yup.string()
          .email('Adresse email invalide !')
          .max(255)
          .required('Adresse email requise !'),
        givenName: Yup.string().max(255).required('Prénom requis !'),
        familyName: Yup.string().max(255).required('Nom requis !'),
        dateOfWork: Yup.date('Format de date invalide !'),
      }),
    )
      .unique(`Cet email est déjà renseigné`, {
        path: 'email',
        parent: 'proReviewInvitations',
      })
      .required('Missing proReviewInvitation')
      .min(1, 'At least one invitation is required'),
  });
