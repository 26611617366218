import { apiClient } from 'api-client';
import { CallRecording } from 'components/CallRecording/call-recording';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { path, pathOr } from 'ramda';
import React, { Fragment, useEffect, useState } from 'react';
import {
  List,
  Loader,
  Message,
  Responsive,
  Table,
  Popup,
  Icon,
  Label,
} from 'semantic-ui-react';
import { formatDateTime } from 'util/locale';
import { formatTelephone } from 'util/telephone';
import { ToastError } from 'util/toast';

const TableContainer = ({
  startDate,
  endDate,
  proViewId,
  globallyBlockedIdentity,
}) => {
  const [data, setData] = useState({ calls: null });

  useEffect(() => {
    apiClient
      .post('/event/calls', {
        proViewId: proViewId,
        orderByDate: 'DESC',
        after: startOfDay(new Date(startDate)),
        before: endOfDay(new Date(endDate)),
        skip: 0,
        limit: 1000,
      })
      .then((response) => {
        setData({ calls: response.data });
      })
      .catch(() => {
        ToastError('Erreur', "Impossible d'afficher le journal d'appel");
      });
  }, [startDate, endDate, proViewId]);

  if (!data || !data.calls) {
    return <Loader active inline />;
  }

  if (data.calls.length) {
    const getTelephone = (call) => {
      switch (call.version) {
        case 0:
          return call.data.callrCall.cliNumber;
        case 1:
          return call.data.callrCall.cli_number;
        default:
          return call.initiator.pi.telephone;
      }
    };

    const getDuration = (call) => {
      switch (call.version) {
        case 0:
          return call.data.callrCall.durationAnswered;
        case 1:
          return call.data.callrCall.duration_answered;
        case 2:
        case 3:
          return path(['data', 'twilio', 'CallDuration'], call)
            ? call.data.twilio.CallDuration
            : call.data.twilio.Duration;
        default:
          return call.data.vonage.duration;
      }
    };

    const isGloballyBlocked = (call) => {
      if (!globallyBlockedIdentity) {
        return false;
      }
      return globallyBlockedIdentity.some(
        (value) => value.identifier.split(':')[1] === getTelephone(call),
      );
    };

    return (
      <React.Fragment>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Table striped basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Numéro</Table.HeaderCell>
                <Table.HeaderCell>Durée de l&apos;appel</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Enregistrement</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.calls.map((call, i) => (
                <Table.Row key={call._id}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{formatDateTime(call.date)}</Table.Cell>
                  <Table.Cell>
                    {formatTelephone(getTelephone(call))}
                    &nbsp; &nbsp;
                    {isGloballyBlocked(call) ? (
                      <Popup
                        position="top center"
                        trigger={<Icon color="red" name="dont" />}
                        content="Bloqué"
                      />
                    ) : null}
                  </Table.Cell>
                  <Table.Cell>
                    {Math.floor(getDuration(call) / 60)} min{' '}
                    {getDuration(call) % 60} sec
                  </Table.Cell>
                  <Table.Cell>
                    {pathOr(false, ['data', 'twilio', 'Forwarded'], call)
                      ? 'Appel redirigé'
                      : null}
                    {pathOr(false, ['data', 'vonage', 'forwarded'], call)
                      ? 'Appel redirigé'
                      : null}
                  </Table.Cell>
                  <Table.Cell>
                    <CallRecording
                      conversationUuid={call.data.vonage?.conversation_uuid}
                      duration={getDuration(call)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <List celled>
            {data.calls.map((call) => (
              <List.Item key={call._id}>
                <List.Content floated="right">
                  {formatDateTime(call.date)}
                </List.Content>
                <a href={`tel:${getTelephone(call)}`}>
                  {formatTelephone(getTelephone(call))}
                </a>
                <br />
                {Math.floor(getDuration(call) / 60)} min{' '}
                {getDuration(call) % 60} sec
                {pathOr(false, ['data', 'twilio', 'Forwarded'], call) ? (
                  <Fragment>
                    <br />
                    Appel redirigé
                  </Fragment>
                ) : null}
                {pathOr(call, ['data', 'vonage', 'forwarded'], false) ? (
                  <Fragment>
                    <br />
                    Appel redirigé
                  </Fragment>
                ) : null}
                <div
                  style={{
                    marginTop: '10px',
                    marginBottom: '5px',
                  }}
                >
                  <CallRecording
                    conversationUuid={call.data.vonage?.conversation_uuid}
                    duration={getDuration(call)}
                  />
                </div>
              </List.Item>
            ))}
          </List>
        </Responsive>
      </React.Fragment>
    );
  }

  return (
    <Message>
      <p>Aucun appel sur la période sélectionée</p>
    </Message>
  );
};

export default TableContainer;
