import React, { Fragment, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Message,
  Modal,
  Responsive,
  Table,
} from 'semantic-ui-react';
import { ToastSuccess } from 'util/toast';
import { formatDate } from '../../../util/locale';
import ProHolidayFormWithFormik from '../components/Form/ProHolidayFormWithFormik';
import DeleteProHolidayButtonContainer from './DeleteProHolidayButtonContainer';
import { subDays } from 'date-fns';

const UpcomingProHolidaysContainer = ({
  upcomingProHolidays,
  refetch,
  proViewId,
}) => {
  const [editModal, setEditModal] = useState({
    open: false,
    upcomingProHolidays: null,
  });

  if (upcomingProHolidays && upcomingProHolidays.length > 0) {
    return (
      <Fragment>
        <Responsive
          as={Table}
          minWidth={Responsive.onlyTablet.minWidth}
          striped
          basic="very"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Congés à venir / en cours</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {upcomingProHolidays.map((proHoliday) => (
              <Table.Row key={proHoliday.id}>
                <Table.Cell>
                  Du {formatDate(proHoliday.startDate)} au{' '}
                  {formatDate(proHoliday.endDate)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Button
                    onClick={() => setEditModal({ open: true, proHoliday })}
                    compact
                  >
                    <Icon name="pencil" />
                    Modifier
                  </Button>
                  <DeleteProHolidayButtonContainer
                    compact
                    proHolidayId={proHoliday.id}
                    onSuccess={() => {
                      refetch();
                      ToastSuccess('Succès', 'Le congé a bien été supprimé !');
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <Header textAlign="center" style={{ fontSize: '22px' }}>
            Congés en cours / à venir
          </Header>
          <Divider section style={{ marginBottom: '15px' }} />
          {upcomingProHolidays.map((proHoliday) => (
            <Fragment key={proHoliday.id}>
              <Grid>
                <Grid.Column width={10} verticalAlign="middle">
                  <p>
                    {formatDate(proHoliday.startDate)} au{' '}
                    {formatDate(proHoliday.endDate)}
                  </p>
                </Grid.Column>
                <Grid.Column
                  width={6}
                  style={{ padding: '15px 15px 15px 0' }}
                  verticalAlign="middle"
                  textAlign="right"
                >
                  <Button
                    icon
                    onClick={() => setEditModal({ open: true, proHoliday })}
                    compact
                  >
                    <Icon name="pencil" />
                  </Button>
                  <DeleteProHolidayButtonContainer
                    icon
                    compact
                    proHolidayId={proHoliday.id}
                    onSuccess={() => {
                      refetch();
                      ToastSuccess('Succès', 'Le congé a bien été supprimé !');
                    }}
                  />
                </Grid.Column>
              </Grid>
              <Divider />
            </Fragment>
          ))}
        </Responsive>
        <Modal
          closeOnEscape={false}
          closeIcon
          closeOnDimmerClick={false}
          onClose={() => setEditModal({ open: false })}
          open={editModal.open}
        >
          <Modal.Header>Modification</Modal.Header>
          <Modal.Content>
            <ProHolidayFormWithFormik
              formData={editModal.proHoliday}
              proViewId={proViewId}
              onSuccess={async () => {
                await refetch();
                ToastSuccess('Succès', 'Le congé a bien été modfié !');
                setEditModal({ open: false });
              }}
              onError={async () => {
                await refetch();
                setEditModal({
                  open: true,
                  proHoliday: editModal.proHoliday,
                  errorMessage:
                    "Une erreur s'est produite, veuillez réessayer plus tard",
                });
              }}
              submitButton="Enregistrer"
            />
            {editModal.errorMessage ? (
              <Message error>{editModal.errorMessage}</Message>
            ) : null}
          </Modal.Content>
        </Modal>
      </Fragment>
    );
  }
};

const ExpiredProHolidaysContainer = ({ expiredProHolidays }) => {
  if (expiredProHolidays && expiredProHolidays.length > 0) {
    return (
      <Fragment>
        <Responsive
          as={Table}
          minWidth={Responsive.onlyTablet.minWidth}
          striped
          basic="very"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Congés expirés</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {expiredProHolidays.map((proHoliday) => (
              <Table.Row key={proHoliday.id}>
                <Table.Cell>
                  Du {formatDate(proHoliday.startDate)} au{' '}
                  {formatDate(proHoliday.endDate)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <Header textAlign="center" style={{ fontSize: '22px' }}>
            Congés expirés
          </Header>
          <Divider section style={{ marginBottom: '15px' }} />
          {expiredProHolidays.map((proHoliday) => (
            <Fragment key={proHoliday.id}>
              <Grid>
                <Grid.Column width={10} verticalAlign="middle">
                  <p>
                    {formatDate(proHoliday.startDate)} au{' '}
                    {formatDate(proHoliday.endDate)}
                  </p>
                </Grid.Column>
              </Grid>
              <Divider />
            </Fragment>
          ))}
        </Responsive>
      </Fragment>
    );
  }
};

const TableContainer = ({ proHolidays, refetch, proViewId }) => {
  if (proHolidays && proHolidays.length > 0) {
    const expiredProHolidays = proHolidays.filter(
      (proHoliday) => new Date(proHoliday.endDate) < subDays(new Date(), 1),
    );
    const upcomingProHolidays = proHolidays.filter(
      (proHoliday) => new Date(proHoliday.endDate) > subDays(new Date(), 1),
    );

    if (
      upcomingProHolidays &&
      upcomingProHolidays.length > 0 &&
      expiredProHolidays &&
      expiredProHolidays.length > 0
    ) {
      return (
        <>
          <UpcomingProHolidaysContainer
            refetch={refetch}
            upcomingProHolidays={upcomingProHolidays}
            proViewId={proViewId}
          ></UpcomingProHolidaysContainer>
          <ExpiredProHolidaysContainer
            expiredProHolidays={expiredProHolidays}
          ></ExpiredProHolidaysContainer>
        </>
      );
    } else if (upcomingProHolidays && upcomingProHolidays.length > 0) {
      return (
        <UpcomingProHolidaysContainer
          refetch={refetch}
          upcomingProHolidays={upcomingProHolidays}
          proViewId={proViewId}
        ></UpcomingProHolidaysContainer>
      );
    } else if (expiredProHolidays && expiredProHolidays.length > 0) {
      return (
        <ExpiredProHolidaysContainer
          expiredProHolidays={expiredProHolidays}
        ></ExpiredProHolidaysContainer>
      );
    }
  }

  return (
    <Message>
      <Message.Header>Aucun congé programmé</Message.Header>
      Vous pouvez en ajouter un en utilisant le formulaire ci-dessus.
    </Message>
  );
};

export default TableContainer;
