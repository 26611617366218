import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import env from 'env';
import jwt from 'jsonwebtoken';
import { getJwt, getRefreshToken, setJwt, setTokenExp } from 'util/auth';
import { keycloak } from 'util/keycloak';

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('client_id', env.KEYCLOAK_CLIENT_ID);
  params.append('refresh_token', getRefreshToken() ?? '');

  return axios
    .post(
      `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/token`,
      params,
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      },
    )
    .then((tokenRefreshResponse) => {
      const data = tokenRefreshResponse.data;

      const decodedToken = jwt.decode(data.access_token) as { exp: number };

      setJwt(data.access_token);
      setTokenExp(decodedToken.exp);

      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + data.access_token;

      return Promise.resolve();
    });
};

export const apiClient = axios.create({ baseURL: env.API_URL });

// Automatically add authorization header
apiClient.interceptors.request.use((config) => {
  const token = getJwt();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

createAuthRefreshInterceptor(apiClient, refreshAuthLogic, {
  statusCodes: [401, 403],
});
