import { apiClient } from 'api-client';
import { ZohoInvoice } from 'interfaces/zoho-invoices';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Loader, Responsive, Table } from 'semantic-ui-react';
import { formatDate } from 'util/locale';
import { InvoiceStatus } from '../components/InvoiceStatus';
import { ToastError } from 'util/toast';

type InvoicesContainerProps = {
  proViewId: string;
};

export const InvoicesContainer: FunctionComponent<InvoicesContainerProps> = ({
  proViewId,
}) => {
  const [invoices, setInvoices] = useState<ZohoInvoice[]>([]);
  const [paginationContext, setPaginationContext] = useState({
    pageNumber: 1,
    hasNext: true,
    isLoading: false,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasMorePage, setHasMorePage] = useState(false);

  const fetchInvoices = async ({ pageNumber }: { pageNumber: number }) => {
    const endpointString = `/zoho/invoice/pro?${new URLSearchParams({
      page_number: pageNumber.toString(),
    })}`;
    const response = await apiClient
      .get<{
        invoices: any[];
        hasMorePage: boolean;
      }>(endpointString)
      .catch((error) => {
        if (error.response.status === 403) {
          ToastError(
            'Erreur',
            `Vous ne pouvez pas afficher les factures. Si vous êtes administrateur passez par la fiche du professionnel.`,
          );
        }
        return { data: { invoices: [], hasMorePage: false } };
      });

    return response.data;
  };

  const refreshInvoices = async (oldInvoices?: any[]) => {
    const response = await fetchInvoices({
      pageNumber: paginationContext.pageNumber,
    });

    setInvoices([...(oldInvoices || []), ...response.invoices]);
    setHasMorePage(response.hasMorePage);
    setIsLoaded(true);
    setPaginationContext({ ...paginationContext, isLoading: false });
  };

  useEffect(() => {
    setInvoices([]);
    setPaginationContext({
      ...paginationContext,
      pageNumber: 1,
      isLoading: false,
    });
    refreshInvoices();
  }, [proViewId]);

  useEffect(() => {
    if (invoices.length === 0) {
      return;
    }
    refreshInvoices(invoices);
  }, [paginationContext.pageNumber]);

  if (!isLoaded) {
    return (
      <Loader
        size="big"
        active
        inline="centered"
        content="Chargement des factures..."
      />
    );
  } else {
    return (
      <Fragment>
        <Table striped basic="very">
          <Responsive
            as={Table.Header}
            minWidth={Responsive.onlyTablet.minWidth}
          >
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>N° facture</Table.HeaderCell>
              <Table.HeaderCell>Montant HT</Table.HeaderCell>
              <Table.HeaderCell>Montant TTC</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Télécharger</Table.HeaderCell>
            </Table.Row>
          </Responsive>
          <Table.Body>
            {invoices.map((invoice) => {
              // We could have done better for downloading but since we have multiple
              // Zoho organization, it's a mess.
              // const CInvoiceID = invoice.invoice_url
              //   .split('CInvoiceID=')[1]
              //   .trim();
              if (invoice.status !== 'draft') {
                return (
                  <Fragment key={invoice.invoice_id}>
                    <Table.Row>
                      <Table.Cell>{formatDate(invoice.date)}</Table.Cell>
                      <Table.Cell>{invoice.invoice_number}</Table.Cell>
                      <Table.Cell>
                        {Math.ceil(invoice.total / 1.2)}€ HT
                      </Table.Cell>
                      <Table.Cell>{invoice.total}€ TTC</Table.Cell>
                      <Table.Cell>
                        <InvoiceStatus status={invoice.status} />
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          target="_blank"
                          href={`${invoice.invoice_url}`}
                          icon="download"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Fragment>
                );
              }
            })}
          </Table.Body>
        </Table>
        {hasMorePage ? (
          <Button
            floated="right"
            onClick={(): void => {
              setPaginationContext({
                ...paginationContext,
                pageNumber: paginationContext.pageNumber + 1,
                isLoading: true,
              });
            }}
            disabled={paginationContext.isLoading}
            content={'Voir plus'}
            loading={paginationContext.isLoading}
          />
        ) : null}
      </Fragment>
    );
  }
};

export default InvoicesContainer;
