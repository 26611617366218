import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
} from 'date-fns';
import { identity, map, times } from 'ramda';

export const populateTotalCountByMonthForStats = (
  datas,
  startDate,
  endDate,
) => {
  const monthsData = [];
  const graphDatas = [];
  let maxLength = -1;
  let total = 0;

  const numberOfMonthBetween = differenceInMonths(
    endOfMonth(endDate),
    startOfMonth(startDate),
  );
  const monthsNumber = times(identity, numberOfMonthBetween + 1);

  // Each months between startDate and EndDate
  const months = map((month) => addMonths(startDate, month), monthsNumber);

  months.map((month) => {
    const doesExist = datas.find((value) => {
      const valueDate = new Date(value.month);
      const monthDate = new Date(month);
      return isSameMonth(valueDate, monthDate);
    });

    if (!doesExist) {
      graphDatas.push({
        month: format(month, 'MM/yyyy'),
        count: 0,
        date: month,
      });
    } else {
      graphDatas.push({
        date: doesExist.month,
        count: doesExist.count,
        month: format(new Date(doesExist.month), 'MM/yyyy'),
      });

      maxLength = doesExist.count > maxLength ? doesExist.count : maxLength;
      total += doesExist.count;
    }
  });

  return { totalCount: total, maxHeight: maxLength, graphDatas };
};
