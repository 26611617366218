import { BgTertiary, BgWhite } from 'components/Bg/Bg';
import gql from 'graphql-tag';
import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import {
  Breadcrumb,
  Container,
  Grid,
  Header,
  Loader,
  Message,
} from 'semantic-ui-react';
import TableContainer from '../../containers/TableContainer';

const ProReviewsPage = ({ selectedProView }) => {
  return (
    <Query
      query={gql`
        query($proPresentationId: Int!) {
          proPresentation: proPresentationByPk(id: $proPresentationId) {
            id
            slug
            name
            proViews {
              id
              bilikZone {
                id
                slug
              }
            }
            mainTrade {
              id
              slug
              name
            }
          }
          proReviews: proReview(
            orderBy: { dateCreated: DESC }
            where: {
              proPresentationId: { _eq: $proPresentationId }
              isSpam: { _eq: false }
              status: { _in: ["ok"] }
            }
          ) {
            id
            givenName
            familyName
            dateCreated
            email
            rating
            addressLocality
            telephone
            reviewAspect
            reviewBody
            reply
            privateComment
            rating
          }
        }
      `}
      variables={{
        proPresentationId: selectedProView.proPresentation.id,
      }}
    >
      {({ error, data }) => {
        if (error) return <Message error content={error.message} />;
        if (!data) return <Loader active inline />;

        return (
          <Fragment>
            <BgTertiary breadcrumb>
              <Container textAlign="right">
                <Breadcrumb
                  icon="right angle"
                  sections={[
                    {
                      key: data.proPresentation.name,
                      content: data.proPresentation.name,
                    },
                    {
                      key: data.proPresentation.mainTrade.name,
                      content: data.proPresentation.mainTrade.name,
                    },
                    { key: 'Avis publiés', content: 'Avis publiés' },
                  ]}
                />
              </Container>
              <Header as="h1" textAlign="center">
                Avis publiés
              </Header>
            </BgTertiary>
            <BgWhite section>
              <Container>
                <Grid>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <TableContainer
                      proReviews={data.proReviews}
                      proPresentationId={selectedProView.proPresentation.id}
                      proPresentationName={data.proPresentation.name}
                    />
                  </Grid.Column>
                </Grid>
              </Container>
            </BgWhite>
          </Fragment>
        );
      }}
    </Query>
  );
};

export default ProReviewsPage;
