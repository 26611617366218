import {
  Container,
  Header,
  Loader,
  Statistic,
  Message,
} from 'semantic-ui-react';
import {
  addMonths,
  compareAsc,
  differenceInMonths,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
} from 'date-fns';

import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useCountSolicitationsByMonthByProViewQuery } from 'generated/graphql';
import { map, uniqBy } from 'ramda';

export function StatsSolicitationsContainer(props) {
  const { startDate, endDate, proViewId } = props;

  const { loading, error, data } = useCountSolicitationsByMonthByProViewQuery({
    variables: {
      proViewId,
      before: startOfMonth(startDate),
      after: endOfMonth(endDate),
    },
    fetchPolicy: 'cache-first',
  });

  if (error) return `Error! ${error.message}`;

  if (loading) {
    return (
      <div style={{ height: '576px' }}>
        <Loader active>Chargement...</Loader>
      </div>
    );
  }

  const graphData = [];

  if (data) {
    const numberOfMonthBetween = differenceInMonths(
      endOfMonth(endDate),
      startOfMonth(startDate),
    );

    const monthsNumber = Array.from(Array(numberOfMonthBetween + 2).keys());

    // Each months between startDate and EndDate
    const months = map((month) => addMonths(startDate, month), monthsNumber);

    // Create object of date for empty month
    months.pop();
    months.forEach((month) => {
      const doesExist = data.solicitation.filter((value) => {
        if (isSameMonth(new Date(value.dateCreated), new Date(month)))
          return value;
      });

      if (!doesExist) {
        graphData.push({
          month: format(month, 'MM/yyyy'),
          mails: 0,
          'mails uniques': 0,
          date: month,
        });
      } else {
        graphData.push({
          date: month,
          mails: doesExist.length,
          'mails uniques': uniqBy((value) => value.email, doesExist).length,
          month: format(new Date(month), 'MM/yyyy'),
        });
      }
    });
    //Sorting data
    graphData.sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)));
  }

  const totalCount = graphData
    ?.map((value) => value.mails)
    .reduce((acc, value) => acc + value);

  const uniqTotalCount = graphData
    ?.map((value) => value['mails uniques'])
    .reduce((acc, value) => acc + value);

  if (totalCount <= 0) {
    return (
      <Container fluid style={{ height: '576px' }}>
        <Header as="h2">Mails</Header>
        <Message>Pas de stats</Message>
      </Container>
    );
  } else {
    return (
      <Container fluid>
        <Header as="h2">Mails</Header>
        <Statistic.Group size="tiny">
          <Statistic horizontal>
            <Statistic.Value style={{ color: '#FA7921' }}>
              {totalCount}
            </Statistic.Value>
            <Statistic.Label style={{ color: '#FA7921', fontWeight: 'bold' }}>
              Demandes mails
            </Statistic.Label>
          </Statistic>
          <Statistic horizontal>
            <Statistic.Value style={{ color: '#CB5200' }}>
              {uniqTotalCount}
            </Statistic.Value>
            <Statistic.Label style={{ color: '#CB5200', fontWeight: 'bold' }}>
              Demandes mails uniques
            </Statistic.Label>
          </Statistic>
        </Statistic.Group>
        <div style={{ height: 500 }}>
          <ResponsiveBar
            colors={['#FA7921', '#CB5200']}
            groupMode="grouped"
            data={graphData}
            keys={['mails', 'mails uniques']}
            indexBy="month"
            margin={{
              top: 30,
              bottom: 60,
              left: 25,
            }}
            padding={0.2}
            innerPadding={7}
            enableLabel={false}
            axisLeft={{
              tickValues: graphData.length,
            }}
            axisBottom={{
              tickRotation: 45,
            }}
          />
        </div>
      </Container>
    );
  }
}

export default StatsSolicitationsContainer;
