import InvoicesPage from 'pages/invoices/components/Page';
import CdrPage from 'pages/cdr/components/Page/Page';
import EmailsPage from 'pages/emails/components/Page/Page';
import ProReviewInvitationsPage from 'pages/pro-review-invitations/components/Page/Page';
import StatsPage from 'pages/stats/components/Page/Page';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProReviewsPage from 'pages/pro-reviews/components/Page/Page';
import ProHolidaysPage from './pages/pro-holidays/components/Page/Page';
import WidgetsPage from './pages/widgets/Page/Page';
import SmsReceivedPage from 'pages/sms-received/components/Page/Page';

const Routes = ({ selectedProView, currentAccount }) => {
  return (
    <Switch>
      {/* Statistiques */}
      <Route
        exact
        path="/"
        render={() => <StatsPage selectedProView={selectedProView} />}
      />

      {/* Appels */}
      <Route
        exact
        path="/appels"
        render={() => <CdrPage selectedProView={selectedProView} />}
      />

      {/* Mails */}
      <Route
        exact
        path="/mails"
        render={() => <EmailsPage selectedProView={selectedProView} />}
      />

      {/* Relances */}
      <Route
        exact
        path="/relances"
        render={() => (
          <ProReviewInvitationsPage
            selectedProPresentation={selectedProView.proPresentation}
            currentAccount={currentAccount}
          />
        )}
      />

      {/* Relances */}
      <Route
        exact
        path="/avis"
        render={() => (
          <ProReviewsPage
            selectedProView={selectedProView}
            currentAccount={currentAccount}
          />
        )}
      />

      {/* Sms Received*/}
      <Route
        exact
        path="/sms"
        render={() => (
          <SmsReceivedPage
            selectedProView={selectedProView}
            currentAccount={currentAccount}
          />
        )}
      />

      {/* Factures */}
      <Route
        exact
        path="/factures"
        render={() => <InvoicesPage selectedProView={selectedProView} />}
      />

      {/* Gestion des congés */}
      <Route
        exact
        path="/conges/:proHolidayId?"
        render={(props) => (
          <ProHolidaysPage
            {...props}
            currentAccount={currentAccount}
            selectedProView={selectedProView}
          />
        )}
      />

      {/* Widgets */}
      <Route
        exact
        path="/widgets"
        render={(props) => (
          <WidgetsPage {...props} selectedProView={selectedProView} />
        )}
      />

      {/* 404 */}
      <Route
        path="*"
        render={() => <StatsPage selectedProView={selectedProView} />}
      />
    </Switch>
  );
};
export default Routes;
