import styled from 'styled-components';

const BgWhite = styled.div`
  width: 100%;
  padding: ${(props) => (props.section ? '57px 0' : '0')};
`;

const BgTertiary = styled.div`
  width: 100%;
  background: #f1f1f1;
  padding: ${(props) => (props.section ? '57px 0' : '0')};
`;

export { BgWhite, BgTertiary };
