import { BgTertiary, BgWhite } from 'components/Bg/Bg';
import env from 'env';
import React, { Fragment, useState, useMemo, useRef } from 'react';
import {
  Breadcrumb,
  Checkbox,
  Container,
  Divider,
  Form,
  FormField as FormFieldUi,
  Header,
  Icon,
  Select,
  Accordion,
  Message,
} from 'semantic-ui-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyButton, CopyRefButton } from 'components/CopyButton/CopyButton';

const WidgetsPage = ({ selectedProView }) => {
  const [oldWidget, setOldWidget] = useState({
    size: 'medium',
    link: true,
    static: false,
    text: false,
    message: false,
  });

  const [accordionState, setAccordionState] = useState({ activeIndex: 1 });

  const [isDarkModeIframe, setIsDarkModeIframe] = useState(false);

  const oldWidgetRef = useRef(null);
  const signatureRef = useRef(null);

  const oldWidgetSize = useMemo(() => {
    switch (oldWidget.size) {
      case 'small':
        return '100px';
      case 'medium':
        return '150px';
      case 'large':
        return '250px';
      default:
        return '150px';
    }
  }, [oldWidget.size]);

  const proReviewLink = useMemo(() => {
    return `${env.SITE_URL}/pro/fiche/${selectedProView.id}/widget#reviews`;
  }, [selectedProView.id]);

  const signatureImageSrc = useMemo(() => {
    return `${env.SITE_URL}/widget/${selectedProView.proPresentation.id}/99px/default/signature`;
  }, [selectedProView.proPresentation.id, oldWidget.static]);

  const widgetImageSrc = useMemo(() => {
    return `${env.SITE_URL}/widget/${
      selectedProView.proPresentation.id
    }/${oldWidgetSize}/${oldWidget.static ? 'static' : ''}`;
  }, [selectedProView.proPresentation.id, oldWidget.static]);

  const iframeSrc = useMemo(() => {
    const darkModeSearchParam = isDarkModeIframe ? '?darkMode=true' : '';
    return `${env.SITE_URL}/widgets/carousel-avis/${selectedProView.proPresentation.id}${darkModeSearchParam}`;
  }, [selectedProView.proPresentation.id, isDarkModeIframe]);

  const tradeLink = useMemo(() => {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${env.SITE_URL}/${selectedProView.bilikZone.slug}/${selectedProView.proPresentation.mainTrade.slug}?utm_source=widget&utm_medium=textlink`}
      >
        {selectedProView.proPresentation.mainTrade.name} à{' '}
        {selectedProView.bilikZone.name}
      </a>
    );
  }, [selectedProView]);

  const proReviewFormLinkText = `${env.SITE_URL}/avis?pro=${selectedProView.proPresentation.id}`;
  const proReviewsLinkText = `${env.SITE_URL}/pro/fiche/${selectedProView.id}#reviews`;
  const proPageLinkText = `${env.SITE_URL}/pro/fiche/${selectedProView.id}`;

  const proReviewFormLink = (
    <a
      href={`${env.SITE_URL}/avis?pro=${selectedProView.proPresentation.id}`}
      className="anchor"
    >
      {proReviewFormLinkText}
    </a>
  );

  const proReviewsLink = (
    <a
      href={`${env.SITE_URL}/pro/fiche/${selectedProView.id}#reviews`}
      className="anchor"
    >
      {proReviewsLinkText}
    </a>
  );

  const proPageLink = (
    <a
      href={`${env.SITE_URL}/pro/fiche/${selectedProView.id}`}
      className="anchor"
    >
      {proPageLinkText}
    </a>
  );

  const htmlCodeIframe = `
  <iframe
    title="Widget avis Bilik"
    src="${iframeSrc}"
    loading="lazy"
    style="border: none; position: relative; display: block; overflow: hidden;
    height: 348px; width: 100%; background: transparent;"
    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
    scrolling="no">
  </iframe>
  `;

  const htmlCodeExample = `<!DOCTYPE html>
  <html>
  <head>
    <title>Exemple d'intégration d'une iframe</title>
    <style>
      .container {
        max-width: 1280px;
        margin: 0 auto;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <!-- Insérer la balise iframe ici -->
    </div>
  </body>
  </html>
  `;

  const htmlCodeSignature = `
  <p>Cordialement,</p>
  <p>
    <b>
      ${selectedProView.proPeople
        .map(({ proPerson }) => {
          return `${proPerson.givenName} ${proPerson.familyName}`;
        })
        .join(', ')}
    </b>
  </p>
  <p>
    ${selectedProView.proPresentation.name}
    <br />
    ${selectedProView.proPostalAddress.street}
    <br />
    ${selectedProView.proPostalAddress.postalCode} ${
    selectedProView.proPostalAddress.city
  }
  </p>
  <p>PS : Retrouvez tous nos avis sur Bilik :
    <a href="${proReviewsLinkText}" class="anchor">
      ${proReviewsLinkText}
    </a>
  </p>
  <p>
    PPS: A la fin de chaque prestation il est important pour moi d'avoir votre témoignage sur notre travail. Pour cela, c'est très simple, suivez ce lien
    <a href="${proReviewFormLinkText}" class="anchor">
      ${proReviewFormLinkText}
    </a>
  </p>
  <a href="${env.SITE_URL}/pro/fiche/${selectedProView.id}"
  class="anchor"
  target="_blank" rel="noopener noreferrer">
    <img alt="Voir page pro sur Bilik"
    title="Voir page pro sur Bilik"
    style="width: 99px; height: 139px;"
    src="${signatureImageSrc}" />
  </a>
  `;

  const htmlCodeOldWidget = `
  ${
    oldWidget.text
      ? `
  <p>
    Voir <a href="${proReviewLink}">mes avis</a> sur Bilik
  </p>
  `
      : `
  <a href="${proReviewLink}" target="_blank" rel="noopener noreferrer">
    <img alt="Voir les avis sur Bilik"
    title="Voir les avis sur Bilik"
    style="width: ${oldWidgetSize}"
    src="${widgetImageSrc}" />
  </a>
  `
  }${
    oldWidget.link
      ? `
  <br />
  ${
    oldWidget.size === 'large'
      ? '<p>Retrouvez notre entreprise dans la rubrique'
      : ''
  }
  <a target="_blank"
    rel="noopener noreferrer"
    href="${env.SITE_URL}/${selectedProView.bilikZone.slug}/${
          selectedProView.proPresentation.mainTrade.slug
        }?utm_source=widget&utm_medium=textlink">
    ${selectedProView.proPresentation.mainTrade.name} à ${
          selectedProView.bilikZone.name
        }
  </a>
    ${oldWidget.size === 'large' ? ` sur Bilik </p>` : ''}
  `
      : ''
  }
  `;

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = accordionState;
    const newIndex = activeIndex === index ? -1 : index;

    setAccordionState({ activeIndex: newIndex });
  };

  return (
    <Fragment>
      <BgTertiary>
        <Container>
          <Container textAlign="right">
            <Breadcrumb
              icon="right angle"
              sections={[
                {
                  key: selectedProView.proPresentation.name,
                  content: selectedProView.proPresentation.name,
                },
                {
                  key: selectedProView.bilikZone.name,
                  content: selectedProView.bilikZone.name,
                },
                { key: 'Widgets', content: 'Widgets' },
              ]}
            />
          </Container>
          <Header as="h1" textAlign="center">
            Widgets avis Bilik
          </Header>
        </Container>
      </BgTertiary>
      <BgWhite section>
        <Container>
          <h2>Carrousel</h2>
          <Checkbox
            label="Mode sombre"
            checked={isDarkModeIframe}
            onChange={() => {
              setIsDarkModeIframe(!isDarkModeIframe);
            }}
          />
          <Divider hidden />
          <div
            style={{
              backgroundColor: isDarkModeIframe ? '#000' : '#fff',
            }}
          >
            <iframe
              title="Widget avis Bilik"
              src={`${iframeSrc}`}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              loading="lazy"
              style={{
                position: 'relative',
                display: 'block',
                border: 'none',
                overflow: 'hidden',
                width: '100%',
                height: '348px',
                background: 'transparent',
              }}
              scrolling="no"
            />
          </div>
          <Divider hidden />
          <p>
            Copiez-collez ce code HTML à l&apos;endroit où vous souhaitez
            l&apos;afficher dans votre site internet.
          </p>

          <SyntaxHighlighter language="html" style={oneDark}>
            {htmlCodeIframe}
          </SyntaxHighlighter>

          <CopyButton text={'Copier le code HTML'} selection={htmlCodeIframe} />

          <Accordion activeIndex={0}>
            <Accordion.Title
              active={accordionState.activeIndex === 0}
              index={0}
              onClick={handleAccordionClick}
            >
              <Icon name="dropdown" />
              Exemple d&apos;intégration
            </Accordion.Title>
            <Accordion.Content active={accordionState.activeIndex === 0}>
              <SyntaxHighlighter language="html" style={oneDark}>
                {htmlCodeExample}
              </SyntaxHighlighter>
            </Accordion.Content>
          </Accordion>

          <Divider />
          <h2>Liens</h2>
          <p>Lien court vers le formulaire d&apos;avis : {proReviewFormLink}</p>
          <CopyButton
            text={'Copier le lien'}
            selection={proReviewFormLinkText}
          />
          <p>Lien court vers vos avis : {proReviewsLink}</p>
          <CopyButton text={'Copier le lien'} selection={proReviewsLinkText} />
          <p>lien court vers fiche : {proPageLink}</p>
          <CopyButton text={'Copier le lien'} selection={proPageLinkText} />
          <Divider />
          <h2>Signature</h2>
          <style>
            {`
              .anchor {
                color: #15c;
                text-decoration: underline;
                cursor: pointer;
              }

              .anchor:hover {
                color: #15c;
                text-decoration: underline;
              }
            `}
          </style>
          <div
            style={{
              color: '#000',
              fontFamily: 'Sans Serif, Helvetica, sans-serif',
              fontSize: '12px',
            }}
          >
            <CopyRefButton
              text={'Copier la signature'}
              elementToCopyRef={signatureRef.current}
            />
            <Divider hidden />

            {/* Do not remove this div, it is used to copy the signature correctly */}
            <div>
              <div ref={signatureRef}>
                <p>Cordialement,</p>
                <p>
                  <b>
                    {selectedProView.proPeople
                      .map(({ proPerson }) => {
                        return `${proPerson.givenName} ${proPerson.familyName}`;
                      })
                      .join(', ')}
                  </b>
                </p>
                <p>
                  {selectedProView.proPresentation.name}
                  <br />
                  {selectedProView.proPostalAddress.street}
                  <br />
                  {selectedProView.proPostalAddress.postalCode}{' '}
                  {selectedProView.proPostalAddress.city}
                </p>
                <p>PS : Retrouvez tous nos avis sur Bilik : {proReviewsLink}</p>
                <p>
                  PPS: A la fin de chaque prestation il est important pour moi
                  d&apos;avoir votre témoignage sur notre travail. Pour cela,
                  c&apos;est très simple, suivez ce lien {proReviewFormLink}
                </p>
                <a
                  href={`${env.SITE_URL}/pro/fiche/${selectedProView.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="anchor"
                >
                  <img
                    alt="Voir page pro sur Bilik"
                    title="Voir page pro sur Bilik"
                    style={{ width: '99px', height: '139px' }}
                    src={signatureImageSrc}
                  />
                </a>
              </div>
            </div>

            <Divider hidden />
            <SyntaxHighlighter language="html" style={oneDark}>
              {htmlCodeSignature}
            </SyntaxHighlighter>
            <CopyButton
              text={'Copier le code HTML'}
              selection={htmlCodeSignature}
            />
          </div>

          <h2>Ancien widget</h2>
          <p>
            Ce widget Bilik est dynamique en fonction de votre nombre
            d&apos;avis publiés. Il sera mis à jour pour chaque nouvel avis.
          </p>
          <br />
          <Form>
            <Form.Group>
              <FormFieldUi>
                <Select
                  options={[
                    { key: 'small', value: 'small', text: 'Petit' },
                    { key: 'medium', value: 'medium', text: 'Moyen' },
                    { key: 'large', value: 'large', text: 'Grand' },
                  ]}
                  value={oldWidget.size}
                  onChange={(event, { value }) => {
                    setOldWidget({
                      size: value,
                      link: value !== 'small',
                      static: oldWidget.static,
                      text: oldWidget.text,
                    });
                  }}
                />
              </FormFieldUi>
              <FormFieldUi style={{ marginTop: '6px' }}>
                <Checkbox
                  label="Afficher le lien vers la catégorie"
                  checked={oldWidget.link}
                  onChange={() =>
                    setOldWidget({
                      link: !oldWidget.link,
                      size: oldWidget.size,
                      static: oldWidget.static,
                      text: oldWidget.text,
                    })
                  }
                />
              </FormFieldUi>
              <FormFieldUi style={{ marginTop: '6px' }}>
                <Checkbox
                  label="Créer un lien statique"
                  checked={oldWidget.text ? false : oldWidget.static}
                  onChange={() =>
                    setOldWidget({
                      link: oldWidget.link,
                      size: oldWidget.size,
                      static: !oldWidget.static,
                      text: oldWidget.text,
                      message: !oldWidget.message,
                    })
                  }
                />
              </FormFieldUi>
              <FormFieldUi style={{ marginTop: '6px' }}>
                <Checkbox
                  label="Créer une signature textuelle"
                  checked={oldWidget.text}
                  onChange={() => {
                    setOldWidget({
                      link: oldWidget.link,
                      size: oldWidget.size,
                      static: oldWidget.static,
                      text: !oldWidget.text,
                      message: !oldWidget.message,
                    });
                  }}
                />
              </FormFieldUi>
            </Form.Group>
          </Form>
          {oldWidget.message || oldWidget.text ? (
            <Message>
              à utiliser lorsque la version dynamique ne fonctionne pas (utile
              surtout pour les serveurs mails Orange)
            </Message>
          ) : null}
          <Divider hidden />
          <CopyRefButton
            text={'Copier le Widget'}
            elementToCopyRef={oldWidgetRef.current}
          />
          <div
            style={{
              width: oldWidgetSize,
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <div ref={oldWidgetRef}>
              {oldWidget.text ? (
                <>
                  <p>
                    Voir <a href={proReviewLink}>mes avis</a> sur Bilik
                  </p>
                </>
              ) : (
                <a
                  href={`${env.SITE_URL}/pro/fiche/${selectedProView.id}/widget#reviews`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="Voir les avis sur Bilik"
                    title="Voir les avis sur Bilik"
                    style={{ width: oldWidgetSize }}
                    src={widgetImageSrc}
                  />
                </a>
              )}
              <br />
              {oldWidget.link ? (
                <Fragment>
                  {oldWidget.size === 'large' ? (
                    <p>
                      Retrouvez notre entreprise dans la rubrique {tradeLink}{' '}
                      sur Bilik
                    </p>
                  ) : (
                    tradeLink
                  )}
                </Fragment>
              ) : null}
            </div>
          </div>
          <Divider hidden />
          <SyntaxHighlighter language="html" style={oneDark}>
            {htmlCodeOldWidget}
          </SyntaxHighlighter>
          <CopyButton
            text={'Copier le code HTML'}
            selection={htmlCodeOldWidget}
          />
          <Container text style={{ marginTop: '12px' }}>
            <p>
              Vous pouvez également télécharger le formulaire de droit à
              l&apos;image en cliquant&nbsp;
              <a href="/pdf/droit_image.pdf" download>
                ICI
              </a>
            </p>
          </Container>
        </Container>
      </BgWhite>
    </Fragment>
  );
};

export default WidgetsPage;
