import { BgTertiary, BgWhite } from 'components/Bg/Bg';
import DatePicker from 'components/DatePicker/DatePicker';
import { subMonths } from 'date-fns';
import React, { Component, Fragment } from 'react';
import {
  Breadcrumb,
  Container,
  Form,
  Grid,
  Header,
  Message,
} from 'semantic-ui-react';
import StatsCallsContainer from '../../containers/StatsCallsContainer';
import StatsProReviewInvitationsContainer from '../../containers/StatsProReviewInvitationsContainer';
import StatsProReviewsContainer from '../../containers/StatsProReviewsContainer';
import StatsSolicitationsContainer from '../../containers/StatsSolicitationsContainer';
import StatsSmsReceivedContainer from '../../containers/StatsSmsReceivedContainer';

class StatsPage extends Component {
  now = new Date();

  state = {
    startDate: subMonths(this.now, 2),
    endDate: this.now,
  };

  render() {
    const { startDate, endDate, showLimitWarn } = this.state;
    const { selectedProView } = this.props;

    return (
      <Fragment>
        <BgTertiary breadcrumb>
          <Container>
            <Container textAlign="right">
              <Breadcrumb
                icon="right angle"
                sections={[
                  {
                    key: selectedProView.proPresentation.name,
                    content: selectedProView.proPresentation.name,
                  },
                  {
                    key: selectedProView.bilikZone.name,
                    content: selectedProView.bilikZone.name,
                  },
                  { key: 'Statistiques', content: 'Statistiques' },
                ]}
              />
            </Container>
            <Header as="h1" textAlign="center">
              Statistiques
            </Header>
            {showLimitWarn ? (
              <Message warning>
                Attention: La limite de sélection est de trois ans, nous avons
                ajustés la sélection.
              </Message>
            ) : null}
            <Grid textAlign="center">
              <Form>
                <Form.Group widths="equal" style={{ padding: '25px 0px' }}>
                  <Form.Field>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        // Interchange date if newDate > endDate
                        if (date > endDate) {
                          this.setState({ startDate: endDate, endDate: date });
                        } else {
                          this.setState({ startDate: date });
                        }
                      }}
                      maxDate={new Date()}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </Form.Field>
                  <Form.Field>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => {
                        // Interchange date if newDate < startDate
                        if (date < startDate) {
                          this.setState({
                            endDate: startDate,
                            startDate: date,
                          });
                        } else {
                          this.setState({
                            endDate: date,
                          });
                        }
                      }}
                      maxDate={new Date()}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid>
          </Container>
        </BgTertiary>
        <BgWhite section>
          <Container>
            <Grid doubling columns={2}>
              <Grid.Column>
                <StatsCallsContainer
                  startDate={startDate}
                  endDate={endDate}
                  proViewId={selectedProView.id}
                />
              </Grid.Column>
              <Grid.Column>
                <StatsSolicitationsContainer
                  startDate={startDate}
                  endDate={endDate}
                  proViewId={selectedProView.id}
                />
              </Grid.Column>
              <Grid.Column>
                <StatsSmsReceivedContainer
                  startDate={startDate}
                  endDate={endDate}
                  proViewId={selectedProView.id}
                />
              </Grid.Column>
              <Grid.Column>
                <StatsProReviewsContainer
                  startDate={startDate}
                  endDate={endDate}
                  proPresentationId={selectedProView.proPresentation.id}
                />
              </Grid.Column>
              <Grid.Column>
                <StatsProReviewInvitationsContainer
                  startDate={startDate}
                  endDate={endDate}
                  proPresentationId={selectedProView.proPresentation.id}
                />
              </Grid.Column>
            </Grid>
          </Container>
        </BgWhite>
      </Fragment>
    );
  }
}

export default StatsPage;
