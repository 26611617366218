import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import React, { Fragment } from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { setIsLogged } from '../../util/auth';

const SelectProViewContainer = ({
  selectedProView,
  accountId,
  display = null,
  setSidebarOpen = null,
  text = '',
  icon = null,
}) => (
  <Query
    fetchPolicy="cache-and-network"
    query={gql`
      query proPresentation($accountId: Int!) {
        proPresentation(
          where: {
            _and: [
              { proViews: { accounts: { accountId: { _eq: $accountId } } } }
              { proViews: { status: { _in: ["published", "draft"] } } }
            ]
          }
        ) {
          id
          name
          slug
          proViews(
            where: {
              _and: [
                { accounts: { accountId: { _eq: $accountId } } }
                { status: { _in: ["published", "draft"] } }
              ]
            }
          ) {
            id
            status
            avatarHash
            proPeople {
              proPerson {
                givenName
                familyName
              }
            }
            proPostalAddress {
              street
              postalCode
              city
              displayOption
            }
            proPresentation {
              id
              slug
              name
              proOrganizationId
              mainTrade {
                id
                slug
                name
              }
            }
            bilikZone {
              id
              name
              slug
            }
          }
        }
      }
    `}
    variables={{ accountId }}
  >
    {({ client, loading, error, data }) => {
      if (error) {
        console.error(error);
        return null;
      }

      // No user ? => logout
      if (!loading && (!data || !data.proPresentation)) {
        setIsLogged(client, false);
        return null;
      }

      if (!data || !data.proPresentation) return 'loading...';

      // First is default, if none selected
      if (!selectedProView && data.proPresentation.length > 0) {
        setTimeout(() => {
          client.writeData({
            data: {
              selectedProView: data.proPresentation[0].proViews[0],
            },
          });
        }, 1);
      }

      // If the Pro Person has multiple Pro Presentations,
      // or at least one Pro Presentation with multiple Pro Views,
      // display the drop down menu.
      if (
        data.proPresentation.length > 1 ||
        data.proPresentation[0]?.proViews.length > 1
      ) {
        if (display && display === 'dropdown') {
          return (
            <Dropdown item text={text} icon={icon}>
              <Dropdown.Menu>
                {data.proPresentation.map((proPresentation, index) => (
                  <Fragment key={index}>
                    <Dropdown.Header>{proPresentation.name}</Dropdown.Header>

                    {proPresentation.proViews.map((proView) => (
                      <Dropdown.Item
                        active={
                          selectedProView
                            ? proView.id === selectedProView.id
                            : false
                        }
                        onClick={() => {
                          client.writeData({
                            data: {
                              selectedProView: proView,
                            },
                          });
                        }}
                        key={index + '.' + proView.id}
                      >
                        {proView.bilikZone.name} -{' '}
                        {proView.proPresentation.mainTrade.name}
                      </Dropdown.Item>
                    ))}
                    {index < data.proPresentation.length - 1 ? (
                      <Dropdown.Divider />
                    ) : null}
                  </Fragment>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          );
        }

        return (
          <Fragment>
            {data.proPresentation.map((proPresentation, index) => (
              <Fragment key={index}>
                <Menu.Item header style={{ fontSize: '17px' }}>
                  {proPresentation.name}
                </Menu.Item>
                {proPresentation.proViews.map((proView) => (
                  <Menu.Item
                    onClick={() => {
                      client.writeData({
                        data: {
                          selectedProView: proView,
                        },
                      });
                      setSidebarOpen(false);
                    }}
                    key={index + '.' + proView.id}
                  >
                    <Icon name="map marker alternate" />
                    {proView.bilikZone.name} -{' '}
                    {proView.proPresentation.mainTrade.name}
                  </Menu.Item>
                ))}
              </Fragment>
            ))}
            ;
          </Fragment>
        );
      }

      return null;
    }}
  </Query>
);

export default SelectProViewContainer;
